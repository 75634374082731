import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles, { getContrastColorModifierClasses } from '../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      background: theme.palette.background.default,
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
      position: 'relative',
      flex: 0,
    },
    body: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignContent: 'center',
    },
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      [theme.breakpoints.up('lg')]: {
        maxWidth: `${theme.spacing(115)} !important`,
      },
      position: 'relative',
    },
    stepsHeader: {
      background: `linear-gradient(180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 85%,
        rgba(255, 255, 255, 0) 100%
      )`,
      position: 'sticky',
      top: 0,
      zIndex: 2,
    },
    formsContainer: {
    },
    descriptionBox: {
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
      minHeight: theme.spacing(8),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    descriptionText: {
      ...getContrastColorModifierClasses('color'),
    },
    formBox: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      paddingBottom: theme.spacing(15),
    },
    navigateBar: {
      position: 'fixed',
      bottom: 0,
      [theme.breakpoints.up('lg')]: {
        width: `${theme.spacing(115)} !important`,
      },
      [theme.breakpoints.up('xs')]: {
        width: `calc(100% - ${theme.spacing(5)})`,
        margin: `auto ${theme.spacing(2.5)}`,
      },
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      background: `linear-gradient(0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 85%,
        rgba(255, 255, 255, 0) 100%
      )`,
      zIndex: 1,
    },
    dialogButton: {
      width: 100,
    },
    shaking: {
      animation: '$formShakingAnimation 0.5s',
      animationIterationCount: '1',
    },
    '@keyframes formShakingAnimation': {
      '0%': { transform: 'translate(0, 0)' },
      '15%': { transform: 'translate(-4px, 0)' },
      '30%': { transform: 'translate(6px, 0)' },
      '45%': { transform: 'translate(-4px, 0)' },
      '60%': { transform: 'translate(6px, 0)' },
      '100%': { transform: 'translate(0, 0)' },
    },
  });
};

export default styles;
