import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';

import {
  openGlossaryBubble,
} from '../../main/actions';

import {
  setSearchKeyword,
  toggleGptChat,
} from '../../search/actions';

import {
  triggerNotification,
} from '../../notifier/actions';

import {
  openEntityBubble,
  closeEntityBubble,
} from '../../relationChart/actions';

import {
  openSourceBubble,
  closeSourceBubble,
  duplicateChatItemChart,
  removeChatItemChart,
  requestUpdateChatItemChart,
  undoChatItemChartChange,
  requestTableToChart,
  toggleSourceCitation,
  toggleChatItemTable,
  setSelectedSideBarTab,
} from '../actions';

import GptAnswerView from './GptAnswerView';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.search,
    ...state.gpt,
    ...state.relationChart,
    networkStates: {
      ...state.search.networkStates,
      ...state.gpt.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    openGlossaryBubble,
    setSearchKeyword,
    toggleGptChat,
    openSourceBubble,
    closeSourceBubble,
    duplicateChatItemChart,
    removeChatItemChart,
    requestUpdateChatItemChart,
    undoChatItemChartChange,
    openEntityBubble,
    closeEntityBubble,
    triggerNotification,
    requestTableToChart,
    toggleSourceCitation,
    toggleChatItemTable,
    setSelectedSideBarTab,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GptAnswerView);
