import { createAction } from '@reduxjs/toolkit';
import {
  OpenEntityBubbleArgs,
  RelationChartActions,
  RequestRelationDataArgs,
  SetRelationDataArgs,
  UpdateInvisibleEntityIdsArgs,
} from './types';

export const requestRelationData = (
  createAction<RequestRelationDataArgs>(RelationChartActions.RELATION_DATA_REQUEST)
);
export const setRelationData = (
  createAction<SetRelationDataArgs>(RelationChartActions.RELATION_DATA_SET)
);
export const openEntityBubble = (
  createAction<OpenEntityBubbleArgs>(RelationChartActions.ENTITY_BUBBLE_OPEN)
);
export const closeEntityBubble = (
  createAction(RelationChartActions.ENTITY_BUBBLE_CLOSE)
);
export const toggleEntityBubbleShowMore = (
  createAction(RelationChartActions.ENTITY_BUBBLE_SHOWMORE_TOGGLE)
);
export const updateInvisibleEntityIds = (
  createAction<UpdateInvisibleEntityIdsArgs>(RelationChartActions.INVISIBLE_ENTITY_IDS_UPDATE)
);
