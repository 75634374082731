import React, { CSSProperties, useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';

import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import {
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';

import styles from './PromptList.styles';
import { PropsFromRedux } from './container';
import { useCommonClasses } from '../../../../theme/commonStyles';
import { ChatPromptItem } from '../../gpt/types';
import PromptInfoPopup from '../../gpt/promptInfoPopup';

const useStyles = makeStyles(styles);

interface Props extends PropsFromRedux {
  chatFilter?: string;
  onEditItem?: (item: ChatPromptItem) => void;
}

const PromptList: React.FC<Props> = ({
  chatPromptItems,
  chatFilter = '',
  openMessageBox,
  requestDeleteChatPrompt,
  requestChatResponse,
  user,
  networkStates: {
    chatResponseRequest,
  },
  onEditItem,
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const theme = useTheme();

  const [menuButton, setMenuButton] = useState<HTMLButtonElement>();
  const [menuPromptItem, setMenuPromptItem] = useState<ChatPromptItem>();
  const [isPromptPopupOpen, setPromptPopupOpen] = useState(false);

  const renderItemText = (text: string) => {
    const matches: [number, number][] = match(text || '', chatFilter);
    const parts = parse(text || '', matches);

    return parts.map((part, index) => (
      <span
        key={index}
        style={{
          fontWeight: part.highlight ? 700 : undefined,
          color: part.highlight ? theme.palette.grey[900] : theme.palette.grey[700],
        }}
      >
        {part.text}
      </span>
    ));
  };

  const renderPromptTitle = (chatPromptItem: ChatPromptItem) => {
    const text = chatPromptItem.prompt;

    return renderItemText(text);
  };

  const userOwnPrompts = (chatPromptItems || [])
    .filter((prompt) => (
      prompt.user === user?.pk
      && (
        !chatFilter
        || prompt.prompt.toLowerCase().split(' ')
          .some((p) => p.startsWith(chatFilter.toLowerCase()))
      )
    ));

  const row = useCallback(({ index, style }: { index: number, style: CSSProperties }) => {
    const item = userOwnPrompts[index];
    if (!item) {
      return <div style={style} />;
    }

    return (
      <div key={index} style={style}>
        <Paper
          classes={commonCss.panels.leafPanel}
          className={classes.tabContentItem}
          onClick={() => {
            if (chatResponseRequest.isRequesting) {
              return;
            }

            requestChatResponse({
              input: item.prompt,
            });
          }}
        >
          <div className={classes.tabContentItemText}>
            {renderPromptTitle(item)}
          </div>
          <div className={classes.tabContentControlBox}>
            <IconButton
              onClick={(event) => {
                setMenuButton(event.currentTarget);
                setMenuPromptItem(item);

                event.stopPropagation();
                event.preventDefault();
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        </Paper>
        <Divider className={classes.tabContentItemDivider} />
      </div>
    );
  }, [chatFilter, chatPromptItems]);

  return (
    <div className={classes.root}>
      <AutoSizer>
        {({ height, width }: { width?: number, height?: number }) => (
          <FixedSizeList
            width={width || 0}
            height={height || 0}
            itemCount={userOwnPrompts.length}
            itemSize={64}
            className={classes.virtualizedContainer}
          >
            {row}
          </FixedSizeList>
        )}
      </AutoSizer>
      {menuButton && menuPromptItem && (
        <Menu
          open={!!menuButton}
          anchorEl={menuButton}
          onClose={() => {
            setMenuButton(undefined);
            setMenuPromptItem(undefined);
          }}
        >
          <MenuItem
            onClick={() => {
              setMenuButton(undefined);
              setPromptPopupOpen(true);
              if (onEditItem) {
                onEditItem(menuPromptItem);
              }
            }}
          >
            <Typography variant="body2">
              Edit
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setMenuButton(undefined);

              openMessageBox({
                content: (
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <Typography variant="body1">
                      Are you sure you want to delete the following prompt?
                    </Typography>
                    <Typography variant="body2">
                      <strong>
                        "{menuPromptItem.prompt}"
                      </strong>
                    </Typography>
                  </Box>
                ),
                dismissText: 'No',
                colorSet: 'tertiary',
                commands: [{
                  title: 'Yes',
                  action: () => {
                    requestDeleteChatPrompt({
                      prompt: menuPromptItem,
                    });
                  },
                }],
              });
            }}
          >
            <Typography variant="body2" color="error">
              Delete
            </Typography>
          </MenuItem>
        </Menu>
      )}
      {isPromptPopupOpen && (
        <PromptInfoPopup
          open={isPromptPopupOpen}
          onClose={() => {
            setPromptPopupOpen(false);
          }}
          prompt={menuPromptItem}
        />
      )}
    </div>
  );
};

export default PromptList;
