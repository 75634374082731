import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';
import {
  updateInvestingParams,
  toggleDataInfoKeySelection,
  requestMarketData,
  requestMarketCountries,
  setMarketView,
  selectMarketCountry,
  setMarketItems,
  setMarketEcoIndicator,
} from '../actions';

import MarketReportDetails from './MarketReportDetails';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.router,
    ...state.report,
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    updateInvestingParams,
    toggleDataInfoKeySelection,
    requestMarketData,
    requestMarketCountries,
    setMarketView,
    selectMarketCountry,
    setMarketItems,
    setMarketEcoIndicator,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MarketReportDetails);
