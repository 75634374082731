/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
} from '@mui/material';
import classNames from 'classnames';
import { Converter } from 'showdown';
import UFuzzy from '@leeoniya/ufuzzy';
import { fuzzy } from 'fast-fuzzy';
import removeMarkdown from 'remove-markdown';
import { rMerge } from 'ranges-merge';

import { ReactComponent as OpenLinkIcon } from '../../../../assets/svg/openLinkIcon.svg';

import useCommonProps from '../../../../theme/commonProps';
import { useCommonClasses } from '../../../../theme/commonStyles';

import { determineContentFormat } from '../gptUtils';
import styles from './BrowserView.styles';

interface Props {
  url?: string;
  content?: string;
  className?: string;
  format?: string;
  isLoading?: boolean;
  highlight?: string;
  highlightTimestamp?: Date;
  hasError?: boolean;
  onFormatChange?: (format: string) => void;
}

interface WordIndexesInfo {
  word: string;
  indexes: number[];
}

const useStyles = makeStyles(styles);
const markdownConverter = new Converter();

const splitWords = (text: string) => {
  const matchGrapheme = /\p{Grapheme_Base}\p{Grapheme_Extend}|\p{Grapheme_Base}/gu;
  const matchPunctuation = /\p{Punctuation}|\p{White_Space}/ug;

  const words: string[] = [];

  text.split(/\n|\r\n/).forEach((v) => {
    const graphs = v.match(matchGrapheme);
    const puncts = v.match(matchPunctuation) || [];

    let word = '';
    const items = [];

    (graphs || []).forEach((g) => {
      const char = g;
      if (puncts.length > 0 && char === puncts[0]) {
        words.push(word);
        items.push({ type: 'w', value: `${word}` });
        word = '';
        items.push({ type: 't', value: `${g}` });
        puncts.shift();
      } else {
        word += char;
      }
    });

    if (word) {
      words.push(word);
      items.push({ type: 'w', value: `${word}` });
    }
  });

  return words.filter((w) => !!w);
};

const removeDateString = (text: string) => {
  const updated = text
    .replace(/(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec){1} [1-3]{0,1}[0-9]{0,1}, [0-9]{4}/g, '')
    .replace(/[0-9]+ (day|month|year)(s){0,1} ago — /g, '');

  return updated;
};

const highlightByWords = (textContent: string, s: string) => {
  const MAX_CHAIN_DISTANCE = 10;

  const words = splitWords(s);
  let pureText = textContent.replace(/<[^>]*>/g, '').trim();

  const wordIndexes = words.map((word) => ({
    word,
    indexes: Array.from(
      pureText.matchAll(new RegExp(word, 'gi')),
    ).map((a) => a.index).filter((i): i is number => i !== undefined),
  })).filter((wi) => wi.indexes.length > 0);

  // Remove all indexes from previous words which when plus with length of word are
  // greater than any of current indexes.
  const normalizedWordIndexes = wordIndexes.reduce((accWordIndexes, current) => {
    if (accWordIndexes.length === 0) {
      return [current];
    }

    return [
      ...accWordIndexes.map((wi, accIndex) => ({
        ...wi,
        indexes: wi.indexes.reduce((arr, indice) => {
          if (arr.length === 1) {
            const prevWi = accIndex > 0 && accWordIndexes.at(accIndex - 1);

            if (!prevWi || indice > prevWi.indexes[0]) {
              return arr;
            }
          }

          const isAccIndexTheLast = (accIndex === accWordIndexes.length - 1);
          const endIndice = indice + wi.word.length;

          // Is previous endIndex <= any of current indexes.
          const isEndIndexLessThanAny = current.indexes.some((ci) => (
            endIndice <= ci
          ));

          // Is previous endIndex < every of current indexes - MAX_CHAIN_DISTANCE
          const isEndIndexToLessThanAll = current.indexes.every((ci) => (
            endIndice < ci - MAX_CHAIN_DISTANCE
          ));

          const shouldIncludeInArray = isEndIndexLessThanAny && !isEndIndexToLessThanAll;
          if (shouldIncludeInArray) {
            return arr;
          }

          return arr.filter((index) => index !== indice);
        }, wi.indexes),
      })),
      current,
    ];
  }, [] as WordIndexesInfo[]);

  const stopIndex = normalizedWordIndexes.findIndex((wi) => wi.indexes.length === 0);
  const finalWordIndexes = stopIndex > 0
    ? normalizedWordIndexes.slice(0, stopIndex)
    : normalizedWordIndexes;

  const finalWord = finalWordIndexes.map((wi) => wi.word).join(' ');
  const fuzzyScore = fuzzy(s, finalWord);

  const matchScore = finalWordIndexes.length / words.length;

  if (matchScore < 0.5 || fuzzyScore < 0.65) {
    return undefined;
  }

  const lastWordIndexes = finalWordIndexes.at(finalWordIndexes.length - 1);
  const beforeLastWordIndexes = finalWordIndexes.at(finalWordIndexes.length - 2);
  const beforeLastWordIndex = beforeLastWordIndexes?.indexes?.at(0);

  const startIndex = finalWordIndexes.at(0)?.indexes?.at(0);
  const endIndex = lastWordIndexes && beforeLastWordIndex && (
    (lastWordIndexes.indexes?.find(
      (i) => i >= beforeLastWordIndex + (beforeLastWordIndexes?.word.length || 0),
    ) || 0) + lastWordIndexes.word.length
  );

  if (startIndex === undefined || endIndex === undefined || endIndex < startIndex) {
    return undefined;
  }

  const highlightedRanges = Array.from(
    textContent
      .matchAll(/<span class="snip-marker">(.*?)<\/span>/g),
  ).map((h) => ([
    h.index,
    h.at(1)?.length || h.index,
  ]) as [number, number]);

  const ranges = rMerge([
    ...highlightedRanges,
    [startIndex, endIndex],
  ]);

  ranges?.reverse().forEach((range) => {
    pureText = `${
      pureText.substring(0, range[0])
    }<span class="snip-marker">${
      pureText.substring(range[0], range[1])
    }</span>${
      pureText.substring(range[1])
    }`;
  });

  return pureText;
};

const fixMarkdownDoc = (document?: Document) => {
  if (!document) {
    return document;
  }

  const paragraphs = document.querySelectorAll('p');
  paragraphs.forEach((p) => {
    const newP = p;
    const prevElem = p.previousElementSibling;
    if (prevElem?.tagName === 'P') {
      // p.remove();
      return;
    }

    const nextElemHtmls = [];
    let nextElem = p.nextElementSibling;

    while (nextElem?.tagName === 'P') {
      nextElemHtmls.push(nextElem.innerHTML);
      nextElem = nextElem.nextElementSibling;
    }

    // console.log(nextElemHtmls);

    // TODO: This is still not working... Need reconsidered.
    // newP.innerHTML = `${p.innerHTML}${nextElemHtmls.join('<br/>\n')}`;
  });

  return document;
};

const BrowserView: React.FC<Props> = ({
  url,
  content,
  className,
  format,
  isLoading,
  highlight,
  highlightTimestamp,
  onFormatChange,
  hasError,
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const commonProps = useCommonProps();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [currentDesireFormat, setCurrentDesireFormat] = useState(format);
  const [isFrameLoading, setIsFrameLoading] = useState(false);

  const defaultFormat = url && determineContentFormat(url);
  const currentFormat = currentDesireFormat || format || defaultFormat;
  const currentContent = `${content || ''}`;

  const getSnips = () => {
    const snips = (
      format === 'html'
        ? (highlight || '')
          .split(/\||\. |·/g)
          .filter((s) => !!s)
        : (highlight || '')
          .split(/\n|\. /g)
          .filter((s) => !!s && Number.isNaN(Number(s)))
    )
      .map((snip) => String(removeMarkdown(snip)))
      .flatMap((h) => h.split('\n').map((s) => s.trim()))
      .filter((snip) => snip.length > 20)
      .map((h) => (
        removeDateString(h
          .replaceAll('...', '')
          .replaceAll('..', '')
          .replaceAll('. ', '')
          .replaceAll(' , ', ', '))
          .replaceAll('— ', '')
          .replaceAll('; ', '\n')
          .replaceAll('_', ' ')
          .replaceAll('**', ' ')
      ).trim());

    return snips;
  };

  const handleFrameLoaded = () => {
    const snips = getSnips();
    const document = format === 'markdown'
      ? fixMarkdownDoc(iframeRef.current?.contentWindow?.document)
      : iframeRef.current?.contentWindow?.document;

    const head = document?.head || document?.getElementsByTagName('head')[0];
    const commonStyle = document?.createElement('style');

    if (head && commonStyle) {
      head.appendChild(commonStyle);

      commonStyle.appendChild(
        document.createTextNode(`
          body {
            overflow-x: hidden;
          }

          body::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }

          body::-webkit-scrollbar-thumb {
            background-color: #aaa;
            border-left: 0px solid transparent;
            border-right: 0px solid transparent;
            background-clip: padding-box;
            border-radius: 4px;
            transition: all 0.3s ease-out;
          }

          body::-webkit-scrollbar-thumb:hover {
            background-color: #999;
          }

          .snip-marker {
            background: yellow;
          }
        `),
      );
    }

    const body = document?.body;
    if (body) {
      body.dir = 'auto';
    }

    const paragraphs = document?.querySelectorAll('p, div:empty');
    const leafElems = Array.from(
      (document?.querySelectorAll('body div, body li, h1, h2, h3, h4, h5, h6') || []),
    ).filter((elem) => {
      if (elem.querySelectorAll('script').length > 0) {
        return false;
      }

      const inlineChildren = Array
        .from(elem.children)
        .filter((child) => {
          const hasScript = child.querySelectorAll('script').length > 0;
          return (
            window.getComputedStyle(child).display === 'inline'
            && child.tagName !== 'SCRIPT'
            && !hasScript
          );
        });

      return elem.children.length === 0 || inlineChildren.length > 0;
    });

    const allElements = Array.from(paragraphs || []).concat(leafElems);

    allElements?.forEach((elem) => {
      const toModify = elem;
      let textContent = (toModify.textContent || '')
        .replaceAll('\u00A0', ' ')
        .replaceAll(' , ', ', ')
        .replaceAll(' ,', ', ')
        .replaceAll('  ', ' ')
        .replaceAll('“', '')
        .replaceAll('”', '')
        .replaceAll('_', ' ')
        .trim();

      const uf = new UFuzzy();
      snips.forEach((s) => {
        // 1 Try extract match.
        let pureText = textContent.replace(/<[^>]*>/g, '').trim();
        const foundExactMatch = pureText.includes(s);

        if (foundExactMatch) {
          // We can't just replace pureText.
          // We need to check if there is previously highlight and re-apply it.
          const highlightedTexts = Array.from(
            textContent
              .matchAll(/<span class="snip-marker">(.*?)<\/span>/g),
          ).flatMap((items) => Array.from(new Set(items.map((h) => h.replace(/<[^>]*>/g, '').trim()))));

          highlightedTexts.forEach((highlightedText) => {
            if (s.includes(highlightedText)) {
              return;
            }

            pureText = pureText.replace(
              highlightedText,
              `<span class="snip-marker">${highlightedText}</span>`,
            );
          });

          textContent = pureText.replace(s, `<span class="snip-marker">${s}</span>`);

          return;
        }

        // 2 Try words split roughtly match.
        const highlightedContent = highlightByWords(textContent, s);
        if (highlightedContent) {
          textContent = highlightedContent;

          return;
        }

        // 3 Try fuzzy match.
        const r = uf.filter([textContent], s);
        if (!r) {
          // If r is null here, mean that it can't be searched anyway.
          return;
        }

        const rInfo = uf.info(r, [textContent], s);
        const startIndex = rInfo?.start?.at(0);
        const chars = rInfo?.chars?.at(0);

        if (startIndex === undefined || chars === undefined) {
          return;
        }

        const endIndex = startIndex + chars;

        const highlightedRanges = Array.from(
          textContent
            .matchAll(/<span class="snip-marker">(.*?)<\/span>/g),
        ).map((h) => ([
          h.index,
          h.at(1)?.length || h.index,
        ]) as [number, number]);

        const ranges = rMerge([
          ...highlightedRanges,
          [startIndex, endIndex],
        ]);

        ranges?.reverse().forEach((range) => {
          pureText = `${
            pureText.substring(0, range[0])
          }<span class="snip-marker">${
            pureText.substring(range[0], range[1])
          }</span>${
            pureText.substring(range[1])
          }`;
        });
      });

      toModify.innerHTML = textContent;
    });

    // remove fixed element and remove overflow-hidden.
    const elems = document?.querySelectorAll('*');
    elems?.forEach((elem) => {
      const toModify = elem;
      const style = window.getComputedStyle(elem);

      if (style?.position === 'fixed') {
        elem.remove();
      }

      if (style?.overflow === 'hidden') {
        (toModify as HTMLDivElement).style.overflow = 'auto';
      }
    });

    // scroll into the first marker.
    const markers = Array
      .from(document?.querySelectorAll('span.snip-marker') || [])
      .filter((m) => !!m.textContent && m.textContent?.length > 30);

    const firstMarker = markers.at(0);

    if (firstMarker) {
      firstMarker.scrollIntoView({
        block: 'center',
        inline: 'center',
        behavior: 'smooth',
      });
    }

    setIsFrameLoading(false);
  };

  const getHighlightedMarkdown = (markdown: string) => {
    const snips = getSnips();
    const paragraphs = markdown.split('\n');

    const highlights = paragraphs.map((paragraph) => {
      const ranges: [number, number][] = [];
      const textContent = (paragraph || '')
        .replaceAll('\u00A0', ' ')
        .replaceAll(' , ', ', ')
        .replaceAll(' ,', ', ')
        .replaceAll('  ', ' ')
        .replaceAll('“', '')
        .replaceAll('”', '')
        .replaceAll('_', ' ')
        .trim();

      const uf = new UFuzzy();
      snips.forEach((s) => {
        const pureText = textContent.replace(/<[^>]*>/g, '').trim();
        const foundExactMatch = pureText.includes(s);

        if (foundExactMatch) {
          const startIndex = pureText.indexOf(s);
          const endIndex = startIndex + s.length;
          ranges.push([startIndex, endIndex]);
          return;
        }

        const highlightedContent = highlightByWords(textContent, s);
        if (highlightedContent) {
          const pureContent = highlightedContent.replace(/<[^>]*>/g, '').trim();
          const startIndex = pureText.indexOf(pureContent);
          const endIndex = startIndex + pureContent.length;
          ranges.push([startIndex, endIndex]);
          return;
        }

        const r = uf.filter([textContent], s);
        if (!r) {
          // If r is null here, mean that it can't be searched anyway.
          return;
        }

        const rInfo = uf.info(r, [textContent], s);
        const startIndex = rInfo?.start?.at(0);
        const chars = rInfo?.chars?.at(0);

        if (startIndex === undefined || chars === undefined) {
          return;
        }

        const endIndex = startIndex + chars;
        ranges.push([startIndex, endIndex]);
      });

      if (ranges.length > 0) {
        const mergedRanges = rMerge(ranges);

        if (paragraph.includes('## ')) {
          return paragraph;
        }

        let highlighted = paragraph;
        mergedRanges?.reverse()?.forEach((range) => {
          highlighted = [
            '## ',
            highlighted.substring(0, range[0]),
            '<span style="background-color: yellow">',
            highlighted.substring(range[0], range[1]).replaceAll('##', ''),
            '</span>\n',
            highlighted.substring(range[1]),
          ].join('');
        });

        return highlighted;
      }

      return paragraph;
    });

    return highlights.join('\n');
  };

  const toggleFormat = (desireFormat: string) => {
    if (onFormatChange) {
      setCurrentDesireFormat(desireFormat);
      onFormatChange(desireFormat);
    }
  };

  useEffect(() => {
    if (!hasError && format !== 'mindmap') {
      setIsFrameLoading(true);
    }

    setCurrentDesireFormat(format);
    handleFrameLoaded();
  }, [
    format,
    highlight,
    highlightTimestamp,
    content,
    hasError,
  ]);

  const renderContentModeButtons = () => {
    if (!url) {
      return undefined;
    }

    return (
      <Box className={classes.contentModeButtonsContainer}>
        {defaultFormat === 'html' && (
          <Button
            classes={commonCss.buttons.roundButton}
            className={
              classNames(
                classes.contentModeButtons,
                currentFormat === 'html' ? 'active' : undefined,
              )
            }
            size="small"
            onClick={() => toggleFormat('html')}
            disabled={isLoading || isFrameLoading}
          >
            HTML
          </Button>
        )}
        <Button
          classes={commonCss.buttons.roundButton}
          className={
            classNames(
              classes.contentModeButtons,
              currentFormat === 'markdown' ? 'active' : undefined,
            )
          }
          size="small"
          onClick={() => toggleFormat('markdown')}
          disabled={isLoading || isFrameLoading}
        >
          Markdown
        </Button>
        <Button
          classes={commonCss.buttons.roundButton}
          className={
            classNames(
              classes.contentModeButtons,
              currentFormat === 'mindmap' ? 'active' : undefined,
            )
          }
          size="small"
          onClick={() => toggleFormat('mindmap')}
          disabled={isLoading || isFrameLoading}
        >
          Mind Map
        </Button>
      </Box>
    );
  };

  return (
    <Box
      className={classNames(
        classes.root,
        className,
      )}
    >
      <Box display="flex" flex={0} flexDirection="row" columnGap={0.5}>
        <Box flex={1}>
          <TextField
            {...commonProps.textField({
              color: 'tertiary',
              readOnly: true,
            })}
            variant="outlined"
            size="small"
            fullWidth
            classes={{
              root: classes.urlInputBox,
            }}
            value={url || ''}
            InputProps={{
              endAdornment: renderContentModeButtons(),
            }}
          />
        </Box>
        <Box display="flex" flex={0} alignItems="center">
          <IconButton
            onClick={() => {
              if (url) {
                window.open(url, `id-${url}`);
              }
            }}
          >
            <OpenLinkIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" position="relative" flexDirection="row" flex={1} marginTop={2}>
        {isLoading ? (
          <Box className={classes.progressContainer}>
            <CircularProgress color="inherit" size={40} />
          </Box>
        ) : (
          <>
            {format === 'html' && (
              <iframe
                ref={iframeRef}
                srcDoc={currentContent}
                className={classes.innerFrame}
                sandbox="allow-same-origin"
                onLoad={() => handleFrameLoaded()}
              />
            )}
            {format === 'markdown' && (
              <iframe
                ref={iframeRef}
                srcDoc={markdownConverter.makeHtml(currentContent || '')}
                className={classes.innerFrame}
                sandbox="allow-same-origin"
                onLoad={() => handleFrameLoaded()}
              />
            )}
            {format === 'mindmap' && (
              <iframe
                srcDoc={`
                  <!DOCTYPE html>
                  <html>
                    <meta charset="UTF-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <head>
                      <style>
                        html, body {
                          height: 100%;
                          margin: 0;
                        }
                        .markmap {
                          width: 100%;
                          height: 100%;
                        }
                      </style>
                      <script src="https://cdn.jsdelivr.net/npm/markmap-autoloader@0.16"></script>
                    </head>
                    <body>
                      <div class="markmap">
                        <script type="text/template">
                          ---
                          markmap:
                            maxWidth: 200
                            colorFreezeLevel: 2
                            color: ["#625ebf", "#bf6181", "#4e95aa", "#3074d5", "#8e89ff", "#fca2c1", "#a2d8e8", "#3c8cfd"]

                          ---

                          ${(
                            getHighlightedMarkdown(currentContent)
                              .split('\n').map((line) => `                          ${line}`).join('\n')
                          )}
                        </script>
                      </div>
                    </body>
                  </html>
                `}
                className={classes.innerFrame}
              />
            )}
            {isFrameLoading && (
              <Box className={classes.progressContainer}>
                <CircularProgress color="inherit" size={40} />
              </Box>
            )}
            {hasError && (
              <div
                className={
                  classNames(
                    classes.innerFrame,
                    'hasError',
                  )
                }
              >
                <div className={classes.errorText}>
                  An error occurs. Please try again later.
                </div>
              </div>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default BrowserView;
