import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../../theme/commonStyles';
import { sourceRefColor } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      width: '100%',
      padding: `0 ${theme.spacing(2)}`,
      boxSizing: 'border-box',
      '&.isShowingMore > p': {
        display: 'block',
      },
      '& > p': {
        fontSize: '0.95rem !important',
        color: `${theme.palette.grey[700]}`,
        display: '-webkit-box',
        wordBreak: 'break-all',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
      },
    },
    content: {
      '& > span': {
        display: 'block',
        '&:not(:first-child)': {
          marginTop: theme.spacing(2),
        },
      },
    },
    showMoreButton: {
      fontSize: '0.714rem !important',
      fontWeight: 400,
      cursor: 'pointer',
      color: `${sourceRefColor} !important`,
      textDecorationColor: `${sourceRefColor} !important`,
    },
  });
};

export default styles;
