import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';
import {
  updateInvestingParams,
  toggleDataInfoKeySelection,
} from '../actions';

import ScoreReportChart from './ScoreReportChart';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.router,
    ...state.report,
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    updateInvestingParams,
    toggleDataInfoKeySelection,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ScoreReportChart);
