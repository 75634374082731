import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Link,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import {
  FilterAlt as FilterIcon,
} from '@mui/icons-material';

import { useCommonClasses } from '../../../theme/commonStyles';
import { ReactComponent as SearchIcon } from '../../../assets/svg/searchIcon.svg';
import { ReactComponent as HistoryIcon } from '../../../assets/svg/historyIcon.svg';
import { ReactComponent as MarketSearchIcon } from '../../../assets/svg/searchMarketIcon.svg';
import { ReactComponent as GlossarySearchIcon } from '../../../assets/svg/searchGlossaryIcon.svg';
import { ReactComponent as GptSearchIcon } from '../../../assets/svg/searchGptIcon.svg';

import useCommonProps from '../../../theme/commonProps';

import routes from '../main/routes';

import { PropsFromRedux } from './container';
import CompanySearchFilter from './companySearchFilter';
import { CompanyItem } from './types';

import VisitHistory from './visitHistory';

import styles from './Search.styles';
import { CompanyData } from '../report/types';
import MarketResults from './marketResults';
import GlossaryResults from './glossaryResults';
import GptResults from './gptResults';
import createPerformanceUtils from '../../../core/utils/performanceUtils';
import HelpPanel from './helpPanel';
import GptSuggestPanel from './gptSuggestPanel';
import { isWebUrl } from '../../../core/utils/validationUtils';
import CompanyList from './companyList';
import CompanyGroupResults from './companyGroupResults';

interface Props extends PropsFromRedux {
  useGptPortal?: boolean;
  minimized?: boolean;
}

const useStyles = makeStyles(styles);

const MINIMUM_COMPANY_AUTOCOMPLETE_KEYWORD = 2;
const MINIMUM_MARKET_KEYWORDS = 4;
export const SEARCH_ITEM_HEIGHT = 135;
export const SEARCH_ITEM_GAP = 16;
export const SEARCH_ITEM_TOTAL_HEIGHT = SEARCH_ITEM_HEIGHT + SEARCH_ITEM_GAP;
export const PAGE_SIZE = 4;

const Search: React.FC<Props> = ({
  requestCompanyItems,
  requestMarketInquiryItems,
  requestGlossaryItems,
  requestGptSearch,
  validationInfo,
  networkStates: {
    companySearchRequest,
    marketInquiryRequest,
    glossaryItemsRequest,
    gptSearchRequest,
  },
  filter: {
    isOpen,
    countries,
    industries,
    minFoundingYears,
    verticals,
  },
  companyResultItems,
  companyResultGroup,
  companyResultAdditionals,
  openFilterEdit,
  closeFilterEdit,
  clearAllFilters,
  keyword: searchKeyword,
  setSearchKeyword,
  userExtraInfo,
  openMessageBox,
  openedReports,
  openCompanyReport,
  isGptSearch,
  isGptChat,
  toggleGptSearch,
  toggleGptChat,
  abortSearchRequest,
  gptSearchResults,
  useGptPortal,
  minimized,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const commonProps = useCommonProps();
  const theme = useTheme();
  const navigate = useNavigate();
  const [
    isShowingMoreAdditionalResults,
    setShowingMoreAdditionalResults,
  ] = useState(false);

  const searchItems = companyResultItems || [];
  const isSearchable = validationInfo?.isSearchable || false;
  const isRequesting = companySearchRequest.isRequesting
    || marketInquiryRequest.isRequesting
    || glossaryItemsRequest.isRequesting
    || gptSearchRequest.isRequesting;

  const hasFilters = !!(
    isOpen
    || countries?.length
    || industries?.length
    || verticals?.length
    || minFoundingYears
  );

  const hasResults = searchItems.length > 0 || (
    companyResultGroup && !Array.isArray(companyResultGroup)
  );

  const isSpecialSearch = searchKeyword?.startsWith(':')
    || (searchKeyword || '').trim().startsWith('"')
    || (searchKeyword || '').trim().endsWith('?');

  const isHistoryMode = searchKeyword === ':history';
  const isHelpMode = searchKeyword === '?';
  const isQuestionMode = !isHistoryMode && searchKeyword.length > 1 && searchKeyword.trim().endsWith('?');
  const isGlossaryMode = !isHistoryMode && (
    searchKeyword.trim().startsWith('"')
  );

  const shouldSearchGlossary = isGlossaryMode && searchKeyword.trim().endsWith('"');

  const minKeyword = isQuestionMode
    ? MINIMUM_MARKET_KEYWORDS
    : MINIMUM_COMPANY_AUTOCOMPLETE_KEYWORD;

  const hasMinKeywords = searchKeyword.length >= minKeyword;

  const performanceUtils = createPerformanceUtils();

  useEffect(() => {
    setShowingMoreAdditionalResults(false);
  }, [companyResultItems]);

  useEffect(() => {
    if (isGptSearch) {
      return;
    }

    const doRequest = async (search: string) => {
      await performanceUtils.forLast(search, 500);

      if (!isSpecialSearch) {
        requestCompanyItems({
          keyword: search,
          minKeyword: MINIMUM_COMPANY_AUTOCOMPLETE_KEYWORD,
        });

        return;
      }

      if (isQuestionMode) {
        requestMarketInquiryItems({
          question: search,
          minKeyword: MINIMUM_MARKET_KEYWORDS,
        });

        return;
      }

      if (shouldSearchGlossary) {
        requestGlossaryItems({
          phrase: search.replaceAll('"', ''),
        });
      }
    };

    abortSearchRequest();
    void doRequest(searchKeyword);
  }, [
    searchKeyword,
    requestCompanyItems,
    isSpecialSearch,
  ]);

  const getSearchPlaceholder = () => {
    if (!isGptSearch) {
      return `Search for any Startup${!minimized ? ' (Type ? for more options)' : ''}`;
    }

    return 'Ask anything and hit Enter';
  };

  const openReport = (companyItem: CompanyItem, report?: string) => {
    const companyData = {
      orgLogo: companyItem.logo,
      orgName: companyItem.name,
      orgKeywords: (companyItem.keywords || '').split(','),
      orgCountry: companyItem.countryIso,
      orgDesc: companyItem.description,
      orgFoundingYear: companyItem.foundingYear,
      orgIndustry: companyItem.industry,
      orgVertical: companyItem.vertical,
      orgState: companyItem.city,
    } as CompanyData;

    openCompanyReport({
      companyData,
      companyId: String(companyItem.id),
      hasScore: companyItem.report,
      report,
    });
  };

  const validateAndOpenReport = (companyItem: CompanyItem, report?: string) => {
    const creditLeft = (
      (userExtraInfo?.results.at(0)?.credits || 0)
      - (userExtraInfo?.results.at(0)?.creditsUsed || 0)
    );

    const isOpenedReport = companyItem.historyReport || !!openedReports[String(companyItem.id)];

    if (!isOpenedReport && creditLeft <= 0) {
      openMessageBox({
        content: 'Insufficient credits, please purchase a credit package to open the report.',
        dismissText: 'OK',
        colorSet: 'secondary',
      });

      return;
    }

    if (!isOpenedReport) {
      const creditToBeDeducted = 1;
      openMessageBox({
        content: (
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="body1" className={classes.creditDialogHeader}>
              Credits left: {creditLeft}
            </Typography>
            <Typography variant="body1" className={classes.creditDialogContent}>
              Opening this report will deduct<br/>
              <span className="credit">{creditToBeDeducted}</span>
              {' credit. Please confirm.'}
            </Typography>
          </Box>
        ),
        dismissText: 'Cancel',
        colorSet: 'secondary',
        commands: [{
          title: 'OK',
          action: () => {
            openReport(companyItem, report);
          },
        }],
      });

      return;
    }

    openReport(companyItem, report);
  };

  const getSearchBarColorSet = () => {
    if (isGptSearch) {
      return 'gpt';
    }

    if (isHistoryMode) {
      return 'info';
    }

    if (isQuestionMode) {
      return 'market';
    }

    if (isGlossaryMode) {
      return 'glossary';
    }

    return 'secondary';
  };

  const determineExpensiveSearch = () => {
    if (!searchKeyword.trim()) {
      return;
    }

    if (isGptSearch) {
      requestGptSearch({
        question: searchKeyword,
      });

      return;
    }

    if (isWebUrl(searchKeyword) && !hasResults && !companySearchRequest.isRequesting) {
      requestCompanyItems({
        keyword: searchKeyword,
        shouldAddCompanyUrl: true,
      });
    }
  };

  const performSetSearchKeyword = (keyword: string) => {
    const doSetSearchKeyword = async (search: string) => {
      await performanceUtils.forLast(search, 100);

      setSearchKeyword({
        keyword,
      });
    };

    void doSetSearchKeyword(searchKeyword);
  };

  const renderNoOptions = () => (
    <>
      {!isSearchable && (
        <Box className={classes.placeholder} display="flex" alignItems="center">
          <Typography variant="body2" color="grey">
            {validationInfo?.message}
          </Typography>
        </Box>
      )}
      {isSearchable && (
        <Box className={classes.placeholder} display="flex" alignItems="center">
          <Typography variant="body2" color="grey">
            No Organization Found
          </Typography>
        </Box>
      )}
    </>
  );

  const renderLoading = () => (
    <Box className={classes.placeholder} display="flex" alignItems="center">
      <Box marginRight={1}>
        <CircularProgress color="secondary" size={12} />
      </Box>
      <Typography variant="body2" color="grey">
        Loading...
      </Typography>
    </Box>
  );

  const getSearchIcon = () => {
    if (isGptSearch) {
      return (
        <GptSearchIcon
          className={classes.searchIcon}
        />
      );
    }

    if (isHistoryMode) {
      return (
        <HistoryIcon
          className={classes.searchIcon}
        />
      );
    }

    if (isQuestionMode) {
      return (
        <MarketSearchIcon
          className={classes.searchIcon}
        />
      );
    }

    if (isGlossaryMode) {
      return (
        <GlossarySearchIcon
          className={classes.searchIcon}
        />
      );
    }

    return (
      <SearchIcon
        className={classes.searchIcon}
      />
    );
  };

  return (
    <div
      className={
        classNames(
          classes.root,
          minimized && 'minimized',
        )
      }
    >
      <Container
          className={
            classNames(
              classes.searchPanel,
              searchKeyword && 'collapsed',
              isSpecialSearch && 'collapsed',
              isGptSearch && 'collapsed',
              minimized && 'semiCollapsed',
            )
          }
      >
        <div
          className={
            classNames(
              classes.searchBox,
              minimized && 'minimized',
            )
          }
        >
          <Autocomplete
            classes={{
              root: classNames(
                classes.searchBoxAutocomplete,
                minimized && 'minimized',
              ),
            }}
            color="secondary"
            fullWidth
            options={[]}
            disableClearable
            noOptionsText={renderNoOptions()}
            loadingText={renderLoading()}
            loading={isRequesting}
            autoComplete
            autoSelect
            freeSolo
            autoHighlight={false}
            popupIcon={undefined}
            open={false}
            value={searchKeyword || ''}
            renderInput={(params) => (
              <TextField
                {...commonProps.textField({
                  color: getSearchBarColorSet(),
                })}
                sx={ minimized ? {
                  ...commonProps.textField({
                    color: getSearchBarColorSet(),
                  }).sx,
                  paddingLeft: '0 !important',
                  paddingRight: '0 !important',
                } : commonProps.textField({
                  color: getSearchBarColorSet(),
                }).sx}
                {...params}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  className: classNames(
                    classes.searchBoxInput,
                    minimized && 'minimized',
                  ),
                  autoComplete: 'off',
                  placeholder: getSearchPlaceholder(),
                  maxLength: 500,
                  styles: {
                    paddingRight: '0 !important',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  className: classNames(
                    classes.searchBoxTextField,
                    gptSearchRequest.isRequesting && 'gptSearching',
                    companySearchRequest.isRequesting && companySearchRequest.extra === 'expensive' && 'companyAdding',
                  ),
                  classes: commonCss.fields.leafTextField,
                  autoComplete: 'off',
                  startAdornment: getSearchIcon(),
                  endAdornment: isRequesting && (
                    <CircularProgress size={24} color="primary" />
                  ),
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    determineExpensiveSearch();
                  }
                }}
                helperText={isGptSearch && (
                  <span className={classes.poweredBy}>
                    Powered by GPT3
                  </span>
                )}
              />
            )}
            onInputChange={(_, val) => performSetSearchKeyword(val)}
            onFocus={() => {
              if (useGptPortal) {
                toggleGptChat({
                  isGptChat: false,
                });
              }
            }}
          />
          {!minimized && (
            <>
              {(!isSpecialSearch || isGptSearch) && (
                <Box
                  marginTop={1.5}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  {(!hasResults || isGptSearch) && (
                    <FormControlLabel
                      label="Ask VAL"
                      classes={{
                        root: classes.gptSwitch,
                        label: classes.gptSwitchLabel,
                      }}
                      control={
                        <Switch
                          {...commonProps.switchControl({
                            color: 'market',
                          })}
                          color="primary"
                        />
                      }
                      checked={isGptSearch || isGptChat}
                      onChange={(_, checked) => {
                        if (useGptPortal) {
                          toggleGptChat({
                            isGptChat: checked,
                          });

                          return;
                        }

                        toggleGptSearch({
                          isGptSearch: checked,
                        });
                      }}
                    />
                  )}
                  {!isGptSearch && (hasResults || hasFilters) && hasMinKeywords && (
                    <Button
                      className={
                        classNames(
                          classes.filterButton,
                          'secondary',
                        )
                      }
                      classes={commonCss.buttons.generalButton}
                      variant={isOpen || hasFilters ? 'contained' : 'outlined'}
                      color={isOpen || hasFilters ? 'secondary' : 'info'}
                      onClick={() => (isOpen ? closeFilterEdit() : openFilterEdit())}
                    >
                      <FilterIcon />
                      Filter
                    </Button>
                  )}
                  {!isGptSearch && (
                    <>
                      <Box
                        flex={1}
                        position="relative"
                        overflow="hidden"
                        paddingTop={0.25}
                        marginLeft={theme.spacing(-0.35)}
                        paddingLeft={theme.spacing(0.35)}
                      >
                        <AnimatePresence>
                          {isOpen && hasMinKeywords && (hasResults || hasFilters) && (
                            <motion.div
                              className={classes.filterBox}
                              initial={{
                                x: '-25%',
                                opacity: 0,
                              }}
                              animate={{
                                x: 0,
                                opacity: 1,
                              }}
                              exit={{
                                x: '-25%',
                                opacity: 0,
                              }}
                              transition={{
                                ease: 'easeIn',
                              }}
                            >
                              <CompanySearchFilter />
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </Box>
                      <Box
                        width={hasResults ? 56 : undefined}
                      >
                        <Typography variant="body1" color="info">
                          {(!hasMinKeywords || !(hasResults || hasFilters)) && (
                            <Box
                              component="span"
                              className={classes.linkButtons}
                              marginRight={1}
                            >
                              or
                            </Box>
                          )}
                          {!hasResults && !hasFilters && (
                            <Link
                              component="button"
                              className={classes.linkButtons}
                              onClick={() => navigate(`.${routes.manualForm}`)}
                            >
                              Manual Form
                            </Link>
                          )}
                          {hasFilters && (
                            <Link
                              component="button"
                              className={
                                classNames(
                                  classes.clearAllButton,
                                  classes.linkButtons,
                                )
                              }
                              onClick={() => clearAllFilters()}
                            >
                              Clear All
                            </Link>
                          )}
                        </Typography>
                      </Box>
                    </>
                  )}
                  {isGptSearch && (
                    <Box
                      flex={1}
                      position="relative"
                      paddingTop={0.25}
                      marginLeft={theme.spacing(-0.35)}
                      paddingLeft={theme.spacing(0.35)}
                    >
                      <AnimatePresence>
                        {(!searchKeyword && !gptSearchResults) && (
                          <motion.div
                            className={classes.gptSearchDescBox}
                            initial={{
                              y: '25%',
                              opacity: 0,
                            }}
                            animate={{
                              y: 0,
                              opacity: 1,
                            }}
                            exit={{
                              y: '25%',
                              opacity: 0,
                            }}
                            transition={{
                              ease: 'easeOut',
                            }}
                          >
                            <Typography variant="body2" className={classes.gptSearchDesc}>
                              Unlock limitless search capabilities with our integration
                              of GPT-3 technology. Our models analyzes the query and answer
                              to deliver relevant context on companies and markets.
                              We tweaked the model with zero randomness to provide more accurate
                              information. Unlike a chatbot, each query is treated independently
                              for improved results. However, please note that it may occasionally
                              generate incorrect information and currently has limited knowledge
                              of events after 2021.
                            </Typography>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </Box>
                  )}
                </Box>
              )}
              {((!isGptSearch) || isHelpMode) && (
                <>
                  {searchKeyword.length > 0
                    && ((!hasResults && !isSpecialSearch) || !hasMinKeywords)
                    && !isRequesting && (
                    <motion.div
                      initial={{
                        y: 50,
                        opacity: 0,
                      }}
                      animate={{
                        y: 0,
                        opacity: 1,
                        transition: {
                          delay: 1,
                        },
                      }}
                      exit={{
                        y: 50,
                        opacity: 0,
                      }}
                      className={classes.noResultBox}
                    >
                      {hasMinKeywords && (
                        <Typography variant="h6" className={classes.noResultContent}>
                          Sorry, no results found for "<strong>{`${searchKeyword}`}</strong>"
                        </Typography>
                      )}
                      {searchKeyword.length > 0 && !hasMinKeywords && (
                        <Typography variant="h6" className={classes.noResultContent}>
                          Type at least {minKeyword} characters
                        </Typography>
                      )}
                      <HelpPanel />
                    </motion.div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Container>
      {!minimized && (
        <>
          {!hasResults
            && !isGptSearch
            && (
              searchKeyword.length === 0
              || isHistoryMode
            )
            && (
              <VisitHistory
                onDataClick={(companyItem) => validateAndOpenReport(companyItem)}
                onSubReportClick={(companyItem, report) => (
                  validateAndOpenReport(companyItem, report)
                )}
              />
            )}
          {isQuestionMode && !isGptSearch && (
            <MarketResults />
          )}
          {isGlossaryMode && !isGptSearch && (
            <GlossaryResults />
          )}
          <AnimatePresence>
            {isGptSearch && !gptSearchResults && !searchKeyword && (
              <motion.div
                initial={{
                  y: 50,
                  opacity: 0,
                }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                  },
                }}
                exit={{
                  y: 50,
                  opacity: 0,
                }}
              >
                <Box marginTop={10}>
                  <GptSuggestPanel />
                </Box>
              </motion.div>
            )}
          </AnimatePresence>
          {isGptSearch && (searchKeyword || gptSearchResults) && (
            <GptResults
              onCompanyItemClick={(companyItem) => validateAndOpenReport(companyItem)}
              onCompanySubReportClick={(companyItem, report) => (
                validateAndOpenReport(companyItem, report)
              )}
            />
          )}
          {hasResults && !isSpecialSearch && !isGptSearch && (
            <>
              <AnimatePresence>
                {searchItems.length > 0 && !isShowingMoreAdditionalResults && (
                  <motion.div
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                      },
                    }}
                    exit={{
                      opacity: 0,
                    }}
                  >
                    <CompanyList
                      companyItems={searchItems}
                      onDataClick={(companyItem) => validateAndOpenReport(companyItem)}
                      onSubReportClick={
                        (companyItem, report) => validateAndOpenReport(companyItem, report)
                      }
                    />
                  </motion.div>
                )}
              </AnimatePresence>
              {companyResultGroup && (
                <CompanyGroupResults
                  onCompanyItemClick={(companyItem) => validateAndOpenReport(companyItem)}
                  onCompanySubReportClick={(companyItem, report) => (
                    validateAndOpenReport(companyItem, report)
                  )}
                />
              )}
              {companyResultAdditionals && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                >
                  <Box marginBottom={3}>
                    <CompanyGroupResults
                      onCompanyItemClick={(companyItem) => validateAndOpenReport(companyItem)}
                      onCompanySubReportClick={(companyItem, report) => (
                        validateAndOpenReport(companyItem, report)
                      )}
                      onShowMore={() => setShowingMoreAdditionalResults(true)}
                      onShowLess={() => setShowingMoreAdditionalResults(false)}
                    />
                  </Box>
                </motion.div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Search;
