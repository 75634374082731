import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';

import {
  requestCreateChatPrompt,
  requestUpdateChatPrompt,
} from '../actions';

import PromptInfoPopup from './PromptInfoPopup';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.router,
    ...state.session,
    ...state.autocomplete,
    ...state.search,
    ...state.gpt,
    networkStates: {
      ...state.gpt.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    requestCreateChatPrompt,
    requestUpdateChatPrompt,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PromptInfoPopup);
