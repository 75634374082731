import React from 'react';
import { Action } from 'redux';
import { makeStyles } from '@mui/styles';
import {
  Close as CloseIcon,
} from '@mui/icons-material';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
} from '@mui/material';

import styles from './PopupDialog.styles';
import { useCommonClasses } from '../../theme/commonStyles';

type Props = {
  children?: React.ReactNode | React.ReactNode[];
  open?: boolean;
  closeButton?: boolean;
  closeAction?: () => Action;
  onEnterKey?: () => void;
  renderCommandActions?: () => React.ReactNode | React.ReactNode[];
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  classes?: Partial<Record<'root' | 'content' | 'footer' | 'backdrop', string>>;
};

const useStyles = makeStyles(styles);
const PopupDialog: React.FC<Props> = ({
  children,
  open,
  closeButton,
  closeAction,
  onEnterKey,
  renderCommandActions,
  maxWidth,
  classes,
}: Props) => {
  const innerClasses = {
    ...useStyles(),
    ...classes,
  } as Record<string, string>;

  const commonCss = useCommonClasses();

  return (
    <Dialog
      classes={commonCss.dialogs.generalDialog}
      open={open || false}
      maxWidth={maxWidth}
      BackdropProps={{
        classes: {
          root: innerClasses.backdrop,
        },
      }}
      onKeyDown={(event) => {
        if (event.key === 'Escape' && closeAction) {
          closeAction();
        }
      }}
      onKeyUp={(event) => {
        if (event.key === 'Enter' && onEnterKey) {
          onEnterKey();
          event.stopPropagation();
          event.preventDefault();
        }
      }}
    >
      {closeButton && (
        <IconButton
          aria-label="Close"
          className={innerClasses.closeButton}
          onClick={() => closeAction && closeAction()}
          color="inherit"
          sx={{
            position: 'absolute',
            right: 6,
            top: 6,
          }}
        >
          <CloseIcon color="inherit" />
        </IconButton>
      )}
      <DialogContent classes={{
        root: classes?.content,
      }}>
        {children}
      </DialogContent>
      {renderCommandActions && (
        <DialogActions classes={{ root: innerClasses?.footer || innerClasses.footer }}>
          <Grid container justifyContent="flex-end">
            {renderCommandActions()}
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default PopupDialog;
