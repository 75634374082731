import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, useTheme } from '@mui/material';
import Color from 'color';
import classNames from 'classnames';
import { useResizeDetector } from 'react-resize-detector';
import CountUp from 'react-countup';

import { DistributionsData } from '../../app/features/report/types';
import { createGaussianKdes, isNullOrUndefined } from '../../core/utils/dataUtils';
import { useCommonClasses } from '../../theme/commonStyles';
import { easeOut } from '../../core/utils/animationUtils';
import SplineChart from '../splineChart/SplineChart';

import styles from './ScoreChartPanel.styles';

type Props = {
  title: React.ReactNode;
  score?: number,
  remark?: string,
  distributionsData?: DistributionsData;
  animationDuration?: number;
  xAxisStartLabel?: string;
  xAxisEndLabel?: string;
  chartHeight?: number;
  colorSet?: string;
  showSubBanner?: boolean;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
};

const DEAULT_ANIMATION_DURATION = 300;

const useStyles = makeStyles(styles);

const ScoreChartPanel:React.FC<Props> = ({
  title,
  score,
  remark,
  distributionsData,
  animationDuration = DEAULT_ANIMATION_DURATION,
  xAxisStartLabel = '0%',
  xAxisEndLabel = '100%',
  chartHeight,
  colorSet,
  showSubBanner = true,
  onMouseEnter,
  onMouseLeave,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const theme = useTheme();
  const [currentScore, setCurrentScore] = useState(0);
  const [prevScore, setPrevScore] = useState(0);
  const [isMouseEnter, setIsMouseEnter] = useState(false);

  const { distributions } = distributionsData || {};
  const data = distributions
    ? createGaussianKdes(distributions || [])
    : Array.from({ length: 100 }).map((_, i) => [i, 0]);

  const [chartData, setChartData] = useState(data);

  const { width, height, ref } = useResizeDetector();

  const refinedScore = isNullOrUndefined(score) ? 0 : (score || 0);
  const pinX = (width || 0) * (refinedScore / 100);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (distributionsData) {
        setChartData(data);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [distributionsData]);

  useEffect(() => {
    if (currentScore !== refinedScore) {
      setPrevScore(currentScore);
      setCurrentScore(refinedScore);
    }
  }, [refinedScore, currentScore]);

  const handleMouseEnter:React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (onMouseEnter) {
      onMouseEnter(event);
    }

    setIsMouseEnter(true);
  };

  const handleMouseLeave:React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (onMouseLeave) {
      onMouseLeave(event);
    }

    setIsMouseEnter(false);
  };

  const countFormatter = useCallback(
    (num: number) => `${num.toFixed(1)}%`
      .split('.')
      .map((n) => `<span>${n}</span>`)
      .join('.'),
    [],
  );

  return (
    <div className={classes.root}>
      {typeof title === 'string' ? (
        <Typography variant="h2">
          {title}
        </Typography>
      ) : title}
      <div
        className={classes.chartContainer}
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <SplineChart
          data={chartData}
          width={width || 0}
          height={chartHeight || height || 0}
          gradientStops={colorSet === 'profileScore' ? [{
            0: 0,
            1: Color(theme.palette.secondary.main).alpha(0).toString(),
          }, {
            0: 1,
            1: theme.palette.secondary.main,
          }] : undefined}
          highlight={isMouseEnter}
        />
        <div className={commonCss.charts.chartXAxis}>
          <Typography variant="body2" fontSize="inherit" color="inherit">
            {xAxisStartLabel}
          </Typography>
          <Typography variant="body2" fontSize="inherit" color="inherit">
            {xAxisEndLabel}
          </Typography>
        </div>
        {score && (
          <div className={classes.scorePinContainer}>
            <div
              className={classes.scorePinLine}
              style={{
                transform: `translate(${pinX.toFixed(0)}px, 0px)`,
                transitionDelay: '0.5s',
              }}
            >
              <div className={classes.scoreBannerContainer}>
                <div
                  className={
                    classNames(
                      commonCss.banners.chartPinSubBanner,
                      classes.scorePinSubBanner,
                    )
                  }
                  style={{
                    visibility: showSubBanner ? 'visible' : 'hidden',
                  }}
                >
                  <Typography color="secondary" variant="body2">
                    {remark}
                  </Typography>
                </div>
                <div
                  className={
                    classNames(
                      commonCss.banners.chartPinBanner,
                      classes.scorePinBanner,
                      colorSet === 'profileScore' ? 'secondary' : 'investing',
                      colorSet === 'profileScore' ? 'contrast' : '',
                      isMouseEnter && 'highlight',
                    )
                  }
                >
                  <CountUp
                    startVal={prevScore}
                    end={currentScore}
                    duration={animationDuration / 1000}
                    decimals={1}
                    useEasing
                    delay={0.5}
                    easingFn={easeOut}
                    formattingFn={countFormatter}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScoreChartPanel;
