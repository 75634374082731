import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    dotPulse: {
      position: 'relative',
      left: '-9999px',
      width: '10px',
      height: '10px',
      borderRadius: '5px',
      backgroundColor: '#9880ff',
      color: '#9880ff',
      boxShadow: '9999px 0 0 -5px',
      animation: '$dotPulse 1.5s infinite linear',
      animationDelay: '0.25s',
      '&:before': {
        content: '""',
        display: 'inline-block',
        position: 'absolute',
        top: 0,
        width: '10px',
        height: '10px',
        borderRadius: '5px',
        backgroundColor: '#9880ff',
        color: '#9880ff',
        boxShadow: '9984px 0 0 -5px',
        animation: '$dotPulseBefore 1.5s infinite linear',
        animationDelay: 0,
      },
      '&:after': {
        content: '""',
        display: 'inline-block',
        position: 'absolute',
        top: 0,
        width: '10px',
        height: '10px',
        borderRadius: '5px',
        backgroundColor: '#9880ff',
        color: '#9880ff',
        boxShadow: '10014px 0 0 -5px',
        animation: '$dotPulseAfter 1.5s infinite linear',
        animationDelay: '0.5s',
      },
    },
    '@keyframes dotPulseBefore': {
      '0%': {
        boxShadow: '9984px 0 0 -5px',
      },
      '30%': {
        boxShadow: '9984px 0 0 2px',
      },
      '60%, 100%': {
        boxShadow: '9984px 0 0 -5px',
      },
    },
    '@keyframes dotPulse': {
      '0%': {
        boxShadow: '9999px 0 0 -5px',
      },
      '30%': {
        boxShadow: '9999px 0 0 2px',
      },
      '60%, 100%': {
        boxShadow: '9999px 0 0 -5px',
      },
    },
    '@keyframes dotPulseAfter': {
      '0%': {
        boxShadow: '10014px 0 0 -5px',
      },
      '30%': {
        boxShadow: '10014px 0 0 2px',
      },
      '60%, 100%': {
        boxShadow: '10014px 0 0 -5px',
      },
    },
  });
};

export default styles;
