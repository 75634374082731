import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    chartContainer: {
      display: 'flex',
      flex: 1,
      height: theme.spacing(6),
      marginTop: theme.spacing(1),
      alignItems: 'center',
      cursor: 'pointer',
    },
    valueLine: {
      height: 1,
      borderTop: `1.5px dashed ${theme.palette.secondary.main}`,
    },
    restLine: {
      flex: 1,
      height: 1,
      borderTop: `0.5px solid ${theme.palette.secondary.main}`,
    },
  });
};

export default styles;
