import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../../theme/commonStyles';
import { otherContrastColorSets, otherLightColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      boxSizing: 'border-box',
    },
    title: {
      fontWeight: '100 !important',
    },
    subTitle: {
      fontSize: '1.285rem !important',
      fontWeight: 'normal !important',
      color: `${otherContrastColorSets[0]} !important`,
    },
    selectedMarketsContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      position: 'relative',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(8),
      maxHeight: theme.spacing(17),
    },
    selectedMarkets: {
    },
    selectedMarketItem: {
      backgroundColor: 'transparent !important',
      '&.highlight': {
        color: theme.palette.common.white,
        '&.primary': {
          backgroundColor: `${otherContrastColorSets[0]} !important`,
        },
        '&.secondary': {
          backgroundColor: `${otherContrastColorSets[1]} !important`,
        },
        '&.tertiary': {
          backgroundColor: `${otherContrastColorSets[2]} !important`,
        },
        '&.quaternary': {
          backgroundColor: `${otherContrastColorSets[3]} !important`,
        },
      },
      '&.hover': {
        color: theme.palette.common.white,
        '&.primary': {
          backgroundColor: `${otherLightColorSets[0]} !important`,
        },
        '&.secondary': {
          backgroundColor: `${otherLightColorSets[1]} !important`,
        },
        '&.tertiary': {
          backgroundColor: `${otherLightColorSets[2]} !important`,
        },
        '&.quaternary': {
          backgroundColor: `${otherLightColorSets[3]} !important`,
        },
      },
    },
    selectedMarketItemText: {
      fontWeight: 'normal !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&.highlight': {
        color: theme.palette.common.white,
      },
    },
    selectedMarketItemDeleteIcon: {
      fontSize: '18px !important',
      marginRight: '4px !important',
      '&.MuiSvgIcon-root.contrast.highlight': {
        color: `${theme.palette.common.white} !important`,
      },
    },
    marketListDivider: {
      border: 'none',
      borderBottom: `0.5px solid ${theme.palette.grey[500]} !important`,
    },
    selectableMarketTitle: {
      fontSize: '1.285rem !important',
      fontWeight: 'normal !important',
      color: `${theme.palette.grey[500]} !important`,
      flex: 0,
    },
    selectableMarketsContainer: {
      display: 'flex',
      flex: 1,
      position: 'relative',
      marginTop: theme.spacing(1),
    },
    selectableMarkets: {
      position: 'absolute',
      left: 0,
      right: 0,
      height: '100%',
      overflow: 'auto',
      paddingBottom: theme.spacing(2),
    },
    selectableMarketItem: {
      backgroundColor: 'transparent !important',
      borderColor: `${theme.palette.grey[300]} !important`,
      color: `${theme.palette.grey[300]} !important`,
    },
    selectableMarketItemText: {
      fontWeight: 'normal !important',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    selectableMarketItemAddIcon: {
      color: `${theme.palette.grey[100]} !important`,
      fontSize: '18px !important',
      marginLeft: '4px !important',
      marginRight: '-8px !important',
    },
    aggregateLabel: {
      fontSize: '1.285rem !important',
      fontWeight: 'normal !important',
    },
  });
};

export default styles;
