import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Box,
  Pagination,
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames';

import { otherColorSets } from '../../../../theme/palette';

import { CompanyItem } from '../types';
import { getStackItemAnimationProps } from '../searchUtils';
import { PAGE_SIZE, SEARCH_ITEM_TOTAL_HEIGHT } from '../Search';
import CompanyItemCard from '../companyItemCard';

import { PropsFromRedux } from './container';
import styles from './CompanyList.styles';

interface Props extends PropsFromRedux {
  companyItems?: CompanyItem[];
  onDataClick?: (companyItem: CompanyItem) => void;
  onSubReportClick?: (companyItem: CompanyItem, report: string) => void;
  showScore?: boolean;
  showKeywords?: boolean;
  isRelatedCompanies?: boolean;
}

interface ResultSpin {
  isForward: boolean;
}

const useStyles = makeStyles(styles);

const CompanyList: React.FC<Props> = ({
  companyItems,
  onDataClick,
  onSubReportClick,
  showScore,
  showKeywords = true,
  isRelatedCompanies,
}: Props) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isAnimating, setIsAnimating] = useState(false);
  const [resultSpin, setResultSpin] = useState<ResultSpin>();
  const [currentResultItems, setCurrentResultItems] = useState<CompanyItem[]>();
  const [postponeResultItems, setPostponeResultItems] = useState<CompanyItem[]>();

  const totalPages = Math.ceil((companyItems || []).length / PAGE_SIZE);
  const startIndex = Math.max((currentPage || 0) - 1, 0) * PAGE_SIZE;
  const finalSearchItems = (companyItems || []).slice(startIndex, startIndex + PAGE_SIZE);
  const prevPage = useRef(currentPage);
  const isMouseWheelProcessing = useRef<boolean>(false);

  useEffect(() => {
    const isResultSpinForword = (
      (currentPage || 0) >= (prevPage.current || 0)
    );

    setResultSpin({
      isForward: isResultSpinForword,
    });

    if (currentPage !== prevPage.current) {
      prevPage.current = currentPage;
    }

    // This will refresh the resultSpin attributes on list items exit.
    setCurrentResultItems([...currentResultItems || []]);
  }, [currentPage, prevPage, companyItems]);

  useEffect(() => {
    if (isAnimating) {
      setPostponeResultItems([...finalSearchItems]);
      return;
    }

    setCurrentResultItems([...finalSearchItems]);
    setPostponeResultItems(undefined);
  }, [resultSpin]);

  useEffect(() => {
    if (!isAnimating && postponeResultItems) {
      setCurrentResultItems(postponeResultItems);
      setPostponeResultItems(undefined);
    }
  }, [isAnimating, postponeResultItems]);

  const onMouseWheel = (deltaY: number) => {
    if (isRelatedCompanies) {
      return;
    }

    if (isMouseWheelProcessing.current) {
      return;
    }

    isMouseWheelProcessing.current = true;

    if (deltaY > 0) {
      const page = Math.min(totalPages || 0, (currentPage || 0) + 1);
      setCurrentPage(page);
    }

    if (deltaY < 0) {
      const page = Math.max(1, (currentPage || 0) - 1);
      setCurrentPage(page);
    }

    const timeOutHandler = setTimeout(() => {
      isMouseWheelProcessing.current = false;
      clearTimeout(timeOutHandler);
    }, 1200);
  };

  return (
    <div className={classes.root}>
      <Container>
        <Box
          className={classes.resultPanel}
          onWheel={(event) => onMouseWheel(event.deltaY)}
        >
          <Box
            className={
              classNames(
                classes.searchResults,
                (companyItems || []).length === 1 ? 'singleResult' : undefined,
              )
            }
          >
            <AnimatePresence>
              {(currentResultItems || []).map((item, index) => (
                <Box key={`${item.id}`} marginBottom={2}>
                  <motion.div
                    onAnimationStart={() => setIsAnimating(true)}
                    onAnimationComplete={() => setIsAnimating(false)}
                    {...getStackItemAnimationProps({
                      isForward: resultSpin?.isForward,
                      itemHeight: SEARCH_ITEM_TOTAL_HEIGHT,
                      itemIndex: index,
                      pageSize: PAGE_SIZE,
                    })}
                  >
                    <CompanyItemCard
                      companyItem={item}
                      onDataClick={onDataClick}
                      onSubReportClick={onSubReportClick}
                      showScore={showScore}
                      showKeywords={showKeywords}
                      isRelatedCompany={isRelatedCompanies}
                    />
                  </motion.div>
                </Box>
              ))}
            </AnimatePresence>
          </Box>
        </Box>
        {(totalPages || 0) > 0 && (companyItems || []).length > 1 && (
          <Box
            className={classes.paginationContainer}
            style={{
              position: isRelatedCompanies ? 'static' : undefined,
            }}
          >
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              onChange={(_, page) => setCurrentPage(page)}
              sx={{
                '& .MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: 'transparent',
                  color: otherColorSets[1],
                  textDecoration: 'underline',
                },
              }}
            />
          </Box>
        )}
      </Container>
    </div>
  );
};

export default CompanyList;
