import { ICountry } from 'country-state-city';
import NetworkState from '../../../core/types/network';

export enum ReportActions {
  REPORT_INITIALIZE = '@@report/REPORT_INITIALIZE',
  COMPANY_DATA_REQUEST = '@@report/COMPANY_DATA_REQUEST',
  COMPANY_DATA_SET = '@@report/COMPANY_DATA_SET',
  INVESTOR_DATA_REQUEST = '@@report/INVESTOR_DATA_REQUEST',
  INVESTOR_DATA_SET = '@@report/INVESTOR_DATA_SET',
  COMPETITORS_DATA_REQUEST = '@@report/COMPETITORS_DATA_REQUEST',
  COMPETITORS_DATA_SET = '@@report/COMPETITORS_DATA_SET',
  DISTRIBUITIONS_DATA_REQUEST = '@@report/DISTRIBUITIONS_DATA_REQUEST',
  DISTRIBUITIONS_DATA_SET = '@@report/DISTRIBUITIONS_DATA_SET',
  MARKET_DATA_REQUEST = '@@report/MARKET_DATA_REQUEST',
  MARKET_DATA_SET = '@@report/MARKET_DATA_SET',
  MARKET_COUNTRIES_REQUEST = '@@report/MARKET_COUNTRIES_REQUEST',
  MARKET_COUNTRIES_SET = '@@report/MARKET_COUNTRIES_SET',
  INVESTING_PARAMS_UPDATE = '@@report/INVESTING_PARAMS_UPDATE',
  DATA_KEY_SELECTION_TOGGLE = '@@report/DATA_KEY_SELECTION_TOGGLE',
  MARKET_ITEM_ADD = '@@report/MARKET_ITEM_ADD',
  MARKET_ITEM_REMOVE = '@@report/MARKET_ITEM_REMOVE',
  MARKET_ITEMS_SET = '@@report/MARKET_ITEMS_SET',
  MARKET_AGGREGATE_SET = '@@report/MARKET_AGGREGATE_SET',
  MARKET_VIEW_SET = '@@report/MARKET_VIEW_SET',
  MARKET_COUNTRY_SELECT = '@@report/MARKET_COUNTRY_SELECT',
  MARKET_ECO_INDICATOR_SET = '@@report/MARKET_ECO_INDICATOR_SET',
  MARKET_HIGHLIGHT_SET = '@@report/MARKET_HIGHLIGHT_SET',
  COMPETITOR_HIGHLIGHT_SET = '@@report/COMPETITOR_HIGHLIGHT_SET',
  COMPETITOR_SELECT = '@@report/COMPETITOR_SELECT',
  LANDSCAPE_STATUS_SET = '@@report/LANDSCAPE_STATUS_SET',
  LANDSCAPE_IS_FUNDED_SET = '@@report/LANDSCAPE_IS_FUNDED_SET',
}

export type Page = 'main'
| 'onePager'
| 'profileScore'
| 'successScore'
| 'market'
| 'ranking'
| 'landscape'
| 'webTraffic'
| 'technologies';

export type DistributionModelKey = 'Model-C1'
| 'Model-C4'
| string;

export type MarketReportView = 'marketSize'
| 'businesses'
| 'employees'
| 'totalWages';

export type LandscapeStatus = 'operating'
| 'exit'
| 'closed';

export enum InvestorType {
  vc = 1,
  hub = 2,
  corporate = 3,
  firm = 4,
  individual = 5,
}

export enum InvestorExp {
  firstTime = 1,
  professional = 2,
  capitalist = 3,
}

export enum InvestorGeography {
  domestic = 1,
  foreign = 0,
}

export type ReportRoutes = Record<Page, string>;

export interface DistributionsData {
  distributions: number[];
}

export interface CompanyData {
  orgLogo: string[];
  orgName: string;
  orgDesc?: string;
  orgKeywords?: string[];
  orgCountry?: string;
  orgState?: string;
  orgIndustry?: string;
  orgVertical?: string;
  orgFoundingYear?: number;
  orgAge?: number;
  overallScore?: number;
  vcLikeable?: number;
  remark?: string;
  raisedEq: boolean;
  domain?: string[];
  facebook?: string[];
  linkedin?: string[];
  crunchbase?: string[];
  twitter?: string[];
}

export interface FounderData {
  female: number;
  experience: number;
  noJobs: number;
  orgsFounded: number;
  wasCeo: number;
  faamg: number;
  hasEduc: number;
  degrees: number;
  scienceDegree: number;
  top10School: number;
  mba: number;
  phD: number;
  score: number;
  vcLikeable: number;
  name: string;
  firstName: string;
  lastName: string;
  title: string;
  linkedin: string;
  workAlumni: boolean;
  schoolAlumni: boolean;
  classAlumni: boolean;
  index: number;
  founderPic?: string;
}

export interface InvestorData {
  overallScore?: number;
}

export interface GroupImportanceData {
  experience: number;
  education: number;
  team: number;
  company: number;
  investor?: number;
}

export interface FeatureImportanceData {
  gender: number;
  workingExperience: number;
  priorJobs: number;
  orgsFounded: number;
  wasCeo: number;
  workedAtFaamg: number;
  degreeCount: number;
  scienceDegree: number;
  topUniversity: number;
  mba: number;
  phD: number;
  classAlumni: number;
  schoolAlumni: number;
  jobAlumni: number;
  stateProvince: number;
  industry: number;
  vertical: number;
  foundingYear: number;
  teamSize: number;
  investingAmount?: number;
  investingExperience?: number;
  investorType?: number;
  geography?: number;
}

export interface CompanyReportInfo {
  companyData: CompanyData;
  founders: FounderData[];
  groupImportance: GroupImportanceData;
  featureImportance: FeatureImportanceData;
  tooltips: {
    c8Experience?: string;
    c6Export?: string;
    c5StartInvesting?: string;
    c4FounderScore?: string;
    c21Degrees?: string;
    c1Description?: string;
    c24Mba?: string;
    c23TopUniversity?: string;
    c3Vclikeable?: string;
    c14Schoolalumni?: string;
    c18CompaniesFounded?: string;
    c17PriorKobs?: string;
    c25Phd?: string;
    c10Company?: string;
    c2CompanyScore?: string;
    c16WorkingExperience?: string;
    c9Education?: string;
    c20WorkedFaamg?: string;
    c26Location?: string;
    c7Team?: string;
    c12Gender?: string;
    c11Teamsize?: string;
    c22ScienceDegree?: string;
    c15Classalumni?: string;
    c19WasCeo?: string;
    c29Vertical?: string;
    c28Industry?: string;
    c13Workalumni?: string;
    c27Foundingyear?: string;
  };
}

export interface InvestorReportInfo {
  investorData: InvestorData;
  groupImportance: GroupImportanceData;
  featureImportance: FeatureImportanceData;
  tooltips: {
    i16WorkingExperience?: string;
    i12Gender?: string;
    i8Experience?: string;
    i33Amount?: string;
    i29Vertical?: string;
    i20WorkedFaamg?: string;
    i7Team?: string;
    i17PriorJobs?: string;
    i9Education?: string;
    i27Foundingyear?: string;
    i24Mba?: string;
    i18CompaniesFounded?: string;
    i30Companyage?: string;
    i37Goback?: string;
    i34InvestorType?: string;
    i21Degrees?: string;
    i22ScienceDegree?: string;
    i23TopUniversity?: string;
    i35Geography?: string;
    i13Workalumni?: string;
    i11Teamsize?: string;
    i10Company?: string;
    i36Investingexp?: string;
    i32Investor?: string;
    i26Location?: string;
    i19WasCeo?: string;
    i14Schoolalumni?: string;
    i15Classalumni?: string;
    i31PredictionScore?: string;
    i28Industry?: string;
    i25Phd?: string;
  };
  round: number;
  valuation: number;
}

export interface InvestingParams {
  maxAmount?: number;
  amount?: number;
  investorType?: InvestorType;
  investorExp?: InvestorExp;
  geography?: InvestorGeography;
}

export interface MarketReportInfo {
  countryCode: Record<string, string>;
  stat: Record<string, string>;
  subCategory: Record<string, string>;
  chartData: Record<string, Record<string, number>>;
  highlights: Record<string, string>;
}

export interface MarketCountryInfo {
  countryCode: string;
  countryGdp: Record<string, number>;
  inflation: Record<string, number>;
  countryGdpChange: Record<string, number>;
  gdpPerCapitaChange: Record<string, number>;
  marketCapListedChange: Record<string, number>;
  country?: ICountry;
}

export interface MarketEconomicIndicators {
  gdp: boolean;
  gdpPerCap: boolean;
  inflation: boolean;
  marketCap: boolean;
}

export interface CompetitorCompanyInfo {
  id: string;
  city: string;
  country: string;
  countryIso: string;
  foundingYear: number;
  calcStatus: string;
  hadFunding: boolean;
  totalFundingUsd?: number;
  numFundingRounds: number;
  numInvestors: number;
  patentsGranted: number;
  trademarksRegistered: number;
  builtwithNumTechnologiesUsed: number;
  semrushVisitsLatestMonth: number;
  semrushVisitsMomPct: number;
  name: string;
  description: string;
  keywords: string;
  industry: string;
  vertical: string;
  score?: number;
  domain?: string[];
  logo?: string[];
  linkedin?: string[];
  crunchbase?: string[];
  facebook?: string[];
  twitter?: string[];
  referralSites?: number;
  historyReport?: boolean;
}

export interface CompetitorsReportInfo {
  target: CompetitorCompanyInfo[];
  top5: CompetitorCompanyInfo[];
  operatingAll: CompetitorCompanyInfo[];
  operatingFunded: CompetitorCompanyInfo[];
  exitAll: CompetitorCompanyInfo[];
  exitFunded: CompetitorCompanyInfo[];
  closedAll: CompetitorCompanyInfo[];
  closedFunded: CompetitorCompanyInfo[];
}

export interface CompetitorHighlightInfo {
  competitor?: CompetitorCompanyInfo;
  isPersist?: boolean;
  commonKeywords?: string[];
  commonDescKeywords?: string[];
}

export interface InitializeReportArgs {
  companyId: string;
}

export interface RequestCompanyDataArgs {
  id: string;
}

export interface SetCompanyDataArgs {
  companyReport?: CompanyReportInfo;
  error?: Error;
}

export interface RequestInvestorDataArgs {
  formId?: string;
  amountRaised?: number;
  investorType?: number;
  investorExp?: number;
  isUsInvestor?: number;
}

export interface SetInvestorDataArgs {
  investorReport?: InvestorReportInfo;
  error?: Error;
}

export interface RequestDistributionsDataArgs {
  model: DistributionModelKey;
}

export interface SetDistributionsDataArgs {
  model: DistributionModelKey;
  data?: DistributionsData;
  error?: Error;
}

export interface RequestMarketDataArgs {
  desc: string;
  country?: string;
}

export interface SetMarketDataArgs {
  marketReport?: MarketReportInfo;
  error?: Error;
}

export interface RequestCompetitorsDataArgs {
  id: string;
}

export interface SetCompetitorsDataArgs {
  competitorsReport?: CompetitorsReportInfo;
  error?: Error;
}

export interface UpdateInvestingParamsArgs {
  path: keyof InvestingParams;
  value?: unknown;
}

export interface ToggleDataInfoKeySelectionArgs {
  dataInfoKey: string;
}

export interface AddMarketItemArgs {
  market: string;
}

export interface RemoveMarketItemArgs {
  market: string;
}

export interface SetMarketItemsArgs {
  markets: string[];
}

export interface SetAggregateArgs {
  isAggregate: boolean;
}

export interface SetMarketViewArgs {
  marketView: MarketReportView;
}

export interface SetMarketCountriesArgs {
  marketCountryInfos?: MarketCountryInfo[];
  error?: Error;
}

export interface SelectMarketCountryArgs {
  marketCountry?: MarketCountryInfo;
  desc?: string;
}

export interface SetMarketEcoIndicatorArgs {
  key: keyof MarketEconomicIndicators;
  value: boolean;
}

export interface SetMarketHighlightArgs {
  marketItem?: string;
  isPermanent?: boolean;
}

export interface SetCompetitorHighlightArgs {
  competitor?: CompetitorCompanyInfo;
  isPersist?: boolean;
}

export interface SelectCompetitorArgs {
  competitor: CompetitorCompanyInfo;
}

export interface SetLandscapeStatusArgs {
  status: LandscapeStatus;
}

export interface SetLandscapeIsFundedArgs {
  isFunded: boolean;
}

export interface ReportState {
  networkStates: {
    companyDataRequest: NetworkState;
    investorDataRequest: NetworkState;
    distributionsDataRequest: NetworkState;
    marketDataRequest: NetworkState;
    marketCountriesRequest: NetworkState;
    competitorsDataRequest: NetworkState;
  };
  formId?: string;
  companyReport?: CompanyReportInfo;
  investorReport?: InvestorReportInfo;
  distributionsInfo?: Record<DistributionModelKey, DistributionsData | undefined>;
  investingParams?: InvestingParams;
  marketReport?: MarketReportInfo;
  marketCountries?: MarketCountryInfo[];
  marketEconomicIndicators: MarketEconomicIndicators;
  selectedDataInfoKeys: string[];
  selectedMarketItems: string[];
  isAggregateMarketItems: boolean;
  selectedMarketView: MarketReportView;
  selectedMarketCountry?: MarketCountryInfo;
  selectedHighlightMarket?: string;
  hoveringHighlightMarket?: string;
  competitorsReport?: CompetitorsReportInfo;
  selectedCompetitors: CompetitorCompanyInfo[];
  highlightingCompetitor?: CompetitorHighlightInfo;
  landscapeStatus: LandscapeStatus;
  landscapeIsFunded: boolean;
}
