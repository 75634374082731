import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Route,
  Routes,
  matchRoutes,
  useLocation,
} from 'react-router-dom';

import {
  Box,
} from '@mui/material';

import reportRoutes from '../reportRoutes';
import { PropsFromRedux } from './container';
import styles from './ScoreReportDetails.styles';
import FounderInfoPanel from '../founderInfoPanel';
import ProfileScoreReport from '../profileScoreReport';
import SuccessScoreReport from '../successScoreReport';
import CompanyScoreReport from '../companyScoreReport';
import InvestingPanel from '../investingPanel';

const useStyles = makeStyles(styles);

interface Props extends PropsFromRedux {
  currentDataInfoKey?: string;
  setCurrentDataInfoKey: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ScoreReportDetails: React.FC<Props> = ({
  companyReport,
  investorReport,
  selectedDataInfoKeys,
  toggleDataInfoKeySelection,
  currentDataInfoKey,
  setCurrentDataInfoKey,
  investingParams,
  updateInvestingParams,
}: Props) => {
  const classes = useStyles();
  const location = useLocation();

  const routes = [{ path: '/reports/:id/:section' }];
  const matches = matchRoutes(routes, location);
  const [{ params }] = matches || [{ params: undefined }];
  const section = params?.section;

  return (
    <div className={classes.root}>
      <Routes>
        {[
          reportRoutes.profileScore,
          reportRoutes.successScore,
        ].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <Box className={classes.founderInfoContainer}>
                <FounderInfoPanel
                  section={section}
                  founders={companyReport?.founders}
                  groupImportance={
                    section === 'profileScore'
                      ? companyReport?.groupImportance
                      : investorReport?.groupImportance
                  }
                  onDataMouseEnter={(dataInfoKey) => setCurrentDataInfoKey(dataInfoKey)}
                  onDataMouseLeave={() => setCurrentDataInfoKey(undefined)}
                  onDataMouseClick={
                    (dataInfoKey) => toggleDataInfoKeySelection({ dataInfoKey })
                  }
                  selectedDataInfoKeys={selectedDataInfoKeys}
                />
              </Box>
            }
          />
        ))}
      </Routes>
      <Box className={classes.reportDetailsBody}>
        <Routes>
          <Route
            path={reportRoutes.profileScore}
            element={
              <ProfileScoreReport
                companyReportInfo={companyReport}
                onDataMouseEnter={(dataInfoKey) => setCurrentDataInfoKey(dataInfoKey)}
                onDataMouseLeave={() => setCurrentDataInfoKey(undefined)}
                onDataMouseClick={
                  (dataInfoKey) => toggleDataInfoKeySelection({ dataInfoKey })
                }
                currentDataInfoKey={currentDataInfoKey}
                selectedDataInfoKeys={selectedDataInfoKeys}
              />
            }
          />
          <Route
            path={reportRoutes.successScore}
            element={
              <SuccessScoreReport
                investorReportInfo={investorReport}
                onDataMouseEnter={(dataInfoKey) => setCurrentDataInfoKey(dataInfoKey)}
                onDataMouseLeave={() => setCurrentDataInfoKey(undefined)}
                onDataMouseClick={
                  (dataInfoKey) => toggleDataInfoKeySelection({ dataInfoKey })
                }
                currentDataInfoKey={currentDataInfoKey}
                selectedDataInfoKeys={selectedDataInfoKeys}
              />
            }
          />
        </Routes>
      </Box>
      <Box>
        <CompanyScoreReport
          reportSection={section}
          companyReportInfo={companyReport}
          investorReportInfo={investorReport}
          onDataMouseEnter={(dataInfoKey) => setCurrentDataInfoKey(dataInfoKey)}
          onDataMouseLeave={() => setCurrentDataInfoKey(undefined)}
          onDataMouseClick={
            (dataInfoKey) => toggleDataInfoKeySelection({ dataInfoKey })
          }
          currentDataInfoKey={currentDataInfoKey}
          selectedDataInfoKeys={selectedDataInfoKeys}
        />
      </Box>
      <Routes>
        <Route
          path={reportRoutes.successScore}
          element={
            <InvestingPanel
              investorReportInfo={investorReport}
              investingParams={investingParams}
              updateInvestingParams={updateInvestingParams}
              onDataMouseEnter={(dataInfoKey) => setCurrentDataInfoKey(dataInfoKey)}
              onDataMouseLeave={() => setCurrentDataInfoKey(undefined)}
              onDataMouseClick={
                (dataInfoKey) => toggleDataInfoKeySelection({ dataInfoKey })
              }
              currentDataInfoKey={currentDataInfoKey}
              selectedDataInfoKeys={selectedDataInfoKeys}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default ScoreReportDetails;
