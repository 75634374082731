import {
  all,
  fork,
  takeEvery,
  put,
  take,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { performApiGet } from '../../../core/utils/sagaUtils';
import {
  AutocompleteActions,
  AutocompleteItem,
  RequestAutocompleteItemsArgs,
  AbortAutocompleteItemsRequestArgs,
} from './types';
import { setAutocompleteItems } from './actions';

const autocompleteAbortControllers: Record<string, AbortController> = {};

function* requestAutocompleteItemsFlow() {
  yield takeEvery(
    AutocompleteActions.ITEMS_REQUEST,
    function* _(act: PayloadAction<RequestAutocompleteItemsArgs>) {
      if (autocompleteAbortControllers[act.payload.storageKey]) {
        const controller = autocompleteAbortControllers[act.payload.storageKey];
        controller.abort();

        yield take(AutocompleteActions.ITEMS_SET);
        yield put(act);
        return;
      }

      const minKeyword = act.payload.minKeyword || 0;
      if ((act.payload.keyword?.length || 0) < minKeyword) {
        return;
      }

      const abortController = new AbortController();
      autocompleteAbortControllers[act.payload.storageKey] = abortController;

      yield fork(() => performApiGet<AutocompleteItem[]>(
        act.payload.apiPath,
        {
          params: {
            q: act.payload.keyword,
          },
          onResult: function* __({ result, error }) {
            yield put(setAutocompleteItems({
              storageKey: act.payload.storageKey,
              items: result,
              error,
            }));

            delete autocompleteAbortControllers[act.payload.storageKey];
          },
        },
        {
          signal: abortController.signal,
        },
      ));
    },
  );
}

function* abortAutocompleteItemsRequestFlow() {
  yield takeEvery(
    AutocompleteActions.ITEMS_REQUEST_ABORT,
    (act: PayloadAction<AbortAutocompleteItemsRequestArgs>) => {
      const abortController = autocompleteAbortControllers[act.payload.storageKey];
      if (abortController) {
        abortController.abort();
      }
    },
  );
}

export default function* autocompleteSaga() {
  yield all([
    requestAutocompleteItemsFlow(),
    abortAutocompleteItemsRequestFlow(),
  ]);
}
