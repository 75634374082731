import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles, { getContrastColorModifierClasses } from '../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      zIndex: 10000,
    },
    backdrop: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      pointerEvents: 'all',
      zIndex: 100,
    },
    bubble: {
      position: 'fixed',
      zIndex: 1000,
      padding: theme.spacing(2),
      background: 'rgba(255, 255, 255, 0.69)',
      border: '1px solid #a7a7a7;',
      display: 'inline-flex',
      minWidth: 130,
      maxWidth: 456,
      ...getContrastColorModifierClasses('borderColor'),
      ...getContrastColorModifierClasses('borderTopColor', ' $arrow:before'),
      backdropFilter: 'blur(6px)',
      boxShadow: '0 6px 9px 0 rgba(0, 0, 0, 0.16)',

      '&.flipVertical': {
        ...getContrastColorModifierClasses('borderBottomColor', ' $arrow:before'),
      },

      '&.flipVertical $arrow': {
        borderTop: 'none',
        bottom: 'auto',
        borderBottom: '40px solid rgba(0, 0, 0, 0.2)',
        top: -25,

        '&:before': {
          borderTop: 'none',
          bottom: 'auto',

          borderBottom: '23px solid #a7a7a7',
          top: 2.5,
        },

        '&:after': {
          borderTop: 'none',
          bottom: 'auto',

          borderBottom: `21px solid ${theme.palette.common.white}`,
          top: 4.5,
        },
      },
    },
    arrow: {
      borderTop: '40px solid rgba(0, 0, 0, 0.2)',
      bottom: -25,
      position: 'absolute',
      borderTopLeftRadius: 15,
      zIndex: 5,

      '&:before': {
        borderRight: '43px solid transparent',
        borderTop: '23px solid #a7a7a7',
        bottom: 3,
        content: '""',
        position: 'absolute',
        left: 25,
      },

      '&:after': {
        borderRight: '41px solid transparent',
        borderTop: `21px solid ${theme.palette.common.white}`,
        bottom: 5,
        content: '""',
        position: 'absolute',
        left: 26,
      },
    },
    body: {
      display: 'flex',
      flex: 1,
    },
  });
};

export default styles;
