import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loginPanel: {
      zIndex: 1,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    lockIcon: {
      marginTop: theme.spacing(2),
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
  });
};

export default styles;
