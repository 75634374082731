import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import { otherLightColorSets } from '../../../theme/palette';

export const OUTER_SPACING = 70;
export const INNER_SPACING = 40;
export const INTERNAL_SPACING = 60;

const styles = (theme: Theme): StyleRules<{}, string> => createStyles({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    paddingLeft: OUTER_SPACING,
    paddingRight: OUTER_SPACING,
    minWidth: theme.breakpoints.values.lg,
    [theme.breakpoints.down('xl')]: {
      paddingLeft: OUTER_SPACING - 20,
      paddingRight: OUTER_SPACING - 20,
    },
  },
  companyInfoPanel: {
    paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: `${theme.spacing(2.5)} !important`,
    transition: 'all ease-out 0.3s !important',
    marginLeft: theme.spacing(-10),
    paddingLeft: theme.spacing(10),
  },
  competitorInfoPanel: {
    paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: `${theme.spacing(2.5)} !important`,
    transition: 'all ease-out 0.3s !important',
    marginLeft: theme.spacing(4.5),
    paddingLeft: theme.spacing(5),
    marginRight: theme.spacing(-8.5),
    marginTop: -1,
    backgroundColor: `${otherLightColorSets[5]} !important`,
  },
  selectedCompetitorsPanel: {
    paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: `${theme.spacing(2.5)} !important`,
    transition: 'all ease-out 0.3s !important',
    marginTop: -1,
    marginLeft: theme.spacing(-10),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(8),
    height: 'calc(100vh - 730px + 1px)',
    display: 'flex',
    alignItems: 'center',
  },
  cameraButton: {
    '&[disabled] > svg': {
      opacity: 0.2,
    },
  },
  reportDetails: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',

    '& > div:first-child': {
      marginRight: -OUTER_SPACING,
      [theme.breakpoints.down('xl')]: {
        marginRight: -(OUTER_SPACING - 20),
      },
    },
  },
  dataInfoContainer: {
    position: 'fixed',
    bottom: 0,
    width: 'calc((100% / 3) - 4px)',
    [theme.breakpoints.down('xl')]: {
      width: 'calc((100% / 3))',
    },
    zIndex: 10,
    left: 0,
    minWidth: theme.spacing(49),
  },
  dataInfoPanelLeft: {
    padding: `${theme.spacing(4)} ${OUTER_SPACING}px ${theme.spacing(4)} ${OUTER_SPACING}px`,
    borderBottom: 'none !important',
    [theme.breakpoints.down('xl')]: {
      paddingLeft: (OUTER_SPACING - 20),
      paddingRight: (OUTER_SPACING - 20),
    },
  },
  dataInfoPanelRight: {
    padding: `${theme.spacing(4)} ${theme.spacing(5)} ${theme.spacing(4)} ${theme.spacing(7)}`,
  },
  footerLogo: {
    position: 'fixed',
    right: theme.spacing(3),
    bottom: theme.spacing(0),
    zIndex: 1,
  },
  marketAnalysisButton: {
    whiteSpace: 'nowrap',
  },
  creditDialogHeader: {
    fontSize: '1.285rem !important',
    fontWeight: '500 !important',
    color: `${theme.palette.grey[100]} !important`,
    textAlign: 'center',
  },
  creditDialogContent: {
    fontSize: '1.285rem !important',
    fontWeight: '500 !important',
    color: `${theme.palette.grey[900]} !important`,
    textAlign: 'center',
    '& .credit': {
      color: theme.palette.secondary.main,
    },
  },
  networkChartContainer: {
    width: `calc(100% - ${theme.spacing(6)})`,
    height: 'calc(100vh - 95px)',
    marginLeft: theme.spacing(7),
  },
});

export default styles;
