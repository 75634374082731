import React from 'react';

import { makeStyles } from '@mui/styles';
import {
  Box,
  IconButton,
  Typography,
} from '@mui/material';

import {
  Close as DeleteIcon,
} from '@mui/icons-material';
import classNames from 'classnames';

import { ReactComponent as DefaultCompanyIcon } from '../../../../assets/svg/defaultCompanyIcon.svg';
import { useCommonClasses } from '../../../../theme/commonStyles';
import { PropsFromRedux } from './container';
import styles from './SelectedCompetitorsPanel.styles';
import { CompetitorCompanyInfo } from '../types';

interface Props extends PropsFromRedux {
  allowDelete?: boolean;
  useTop5?: boolean;
  allowOpenReport?: boolean;
  onCompetitorInfoOpen?: (competitorData: CompetitorCompanyInfo) => void;
}

const useStyles = makeStyles(styles);

const SelectedCompetitiorsPanel: React.FC<Props> = ({
  selectedCompetitors,
  allowDelete,
  selectCompetitor,
  competitorsReport,
  useTop5,
  allowOpenReport,
  onCompetitorInfoOpen,
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();

  const allCompetitors = useTop5
    ? competitorsReport?.top5 || []
    : selectedCompetitors;

  return (
    <div className={classes.root}>
      {allCompetitors.map((competitor) => (
        <Box
          key={competitor.id}
          className={classNames(
            classes.item,
            allowOpenReport && 'navigable',
          )}
          onClick={() => {
            if (onCompetitorInfoOpen) {
              onCompetitorInfoOpen(competitor);
            }
          }}
        >
          <div className={classes.logoContainer}>
            {competitor.logo?.at(0) ? (
              <Box
                className={commonCss.boxes.imageBox}
                component="object"
                data={competitor.logo?.at(0)}
                type="image/png"
                width={60}
                height={60}
              >
                <DefaultCompanyIcon />
              </Box>
            ) : (
              <Box
                className={commonCss.boxes.imageBox}
                width={60}
                height={60}
              >
                <DefaultCompanyIcon />
              </Box>
            )}
          </div>
          <Typography variant="body2" className={classes.label}>
            {competitor.name}
          </Typography>
          {allowDelete && (
            <Box className={classes.deleteButtonBox}>
              <IconButton
                className={classes.deleteButton}
                onClick={() => selectCompetitor({ competitor })}
                size="small"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
      ))}
    </div>
  );
};

export default SelectedCompetitiorsPanel;
