import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import styles from './ImageLoader.styles';

const useStyles = makeStyles(styles);

type Props = {
  src: string;
  maxWidth: number;
  maxHeight: number;
};

const ImageLoader = ({
  src,
  maxWidth,
  maxHeight,
}: Props) => {
  const classes = useStyles();

  if (src.toLowerCase().includes('//example.com/')) {
    return (
      <span />
    );
  }

  // const lowerCase = src.toLowerCase();
  // const [finalSrc, setFinalSrc] = useState<string>();

  // const isImageSupported = (
  //   lowerCase.endsWith('.jpg')
  //   || lowerCase.endsWith('.jpeg')
  //   || lowerCase.endsWith('.png')
  //   || lowerCase.endsWith('.webp')
  //   || lowerCase.endsWith('.gif')
  //   || lowerCase.endsWith('.jfif')
  // );

  // useEffect(() => {
  //   if (
  //     lowerCase.endsWith('.jpg')
  //     || lowerCase.endsWith('.jpeg')
  //     || lowerCase.endsWith('.png')
  //     || lowerCase.endsWith('.webp')
  //     || lowerCase.endsWith('.gif')
  //     || lowerCase.endsWith('.jfif')
  //   ) {
  //     setFinalSrc(src);
  //     return;
  //   }

  //   setFinalSrc(undefined);
  // }, [src]);

  return (
    <Box
      className={classes.root}
      component="img"
      src={src}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
    />
  );
};

export default ImageLoader;
