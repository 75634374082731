import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';

import { setIsCancelling } from '../actions';
import StepsHeader from './StepsHeader';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.manualForm,
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    setIsCancelling,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StepsHeader);
