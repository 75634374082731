import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../../theme/commonStyles';
import { otherContrastColorSets, otherAltLightColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flexDirection: 'column',
      color: `${theme.palette.grey[400]} !important`,
    },
    container: {
      width: 760,
      margin: 'auto',
    },
    title: {
      color: `${otherContrastColorSets[2]} !important`,
      marginLeft: `${theme.spacing(2)} !important`,
      marginBottom: `${theme.spacing(2)} !important`,
      fontSize: '0.785rem !important',
      fontWeight: 'normal !important',
    },
    questionItem: {
      borderColor: `${otherContrastColorSets[2]} !important`,
      marginBottom: `${theme.spacing(1)} !important`,
      justifyContent: 'flex-start !important',
      color: `${theme.palette.grey[900]} !important`,
      fontWeight: '300 !important',
      '&:hover': {
        backgroundColor: `${otherAltLightColorSets[2]} !important`,
      },
    },
  });
};

export default styles;
