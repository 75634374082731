import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useResizeDetector } from 'react-resize-detector';

import styles from './TinyBarChart.styles';

type Props = {
  percent?: number;
  colorClass?: string;
  animationDuration?: number;
};

const useStyles = makeStyles(styles);

const TinyBarChart: React.FC<Props> = ({
  percent,
  colorClass,
  animationDuration = 1000,
}) => {
  const classes = useStyles();
  const [barWidth, setBarWidth] = useState(0);
  const { width, ref } = useResizeDetector();
  const containerWidth = Math.max((width || 0));

  useEffect(() => {
    if (containerWidth > 0) {
      const allowWidth = Math.max(0, (containerWidth || 0));
      const normalizedWidth = allowWidth * ((percent || 0) / 100);

      setBarWidth(normalizedWidth);
    }
  }, [containerWidth, percent]);

  return (
    <div className={classes.root} ref={ref}>
      <motion.div
        className={
          classNames(
            classes.percentBar,
            colorClass,
          )
        }
        initial={{
          width: 0,
        }}
        animate={{
          width: barWidth,
          transition: {
            duration: (animationDuration / 1000),
          },
        }}
      />
    </div>
  );
};

export default TinyBarChart;
