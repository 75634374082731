import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  fork,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import config from '../../../config';
import { performApiPost } from '../../../core/utils/sagaUtils';
import { ApplicationState } from '../../types';
import { requestChatHistory } from '../gpt/actions';
import { GptActions, GptChatItem } from '../gpt/types';
import { setRelationData } from './actions';
import {
  RelationChartActions,
  RelationData,
  RequestRelationDataArgs,
  SetRelationDataArgs,
} from './types';

function* requestRelationDataFlow() {
  yield takeEvery(
    RelationChartActions.RELATION_DATA_REQUEST,
    function* _(act: PayloadAction<RequestRelationDataArgs>) {
      if (act.payload.text) {
        yield fork(() => performApiPost<RelationData>(
          config.api.requestNetworkGraph,
          {
            params: act.payload.conversationId ? {
              conversation_id: act.payload.conversationId,
            } : undefined,
            payload: act.payload.text as unknown as Record<string, unknown>,
            onResult: ({ result, error }) => put(setRelationData({
              data: result,
              error,
            })),
          },
        ));
      }

      if (act.payload.companyId) {
        yield fork(() => performApiPost<RelationData>(
          config.api.requestCompanyNetworkGraph,
          {
            params: {
              org_id: act.payload.companyId,
            },
            onResult: ({ result, error }) => put(setRelationData({
              data: result,
              error,
            })),
          },
        ));
      }
    },
  );
}

function* chatResponseRequestFlow() {
  yield takeEvery(
    GptActions.CHAT_RESPONSE_REQUEST,
    function* _() {
      const chats = (
        yield select((appState: ApplicationState) => appState.gpt.chats)
      ) as GptChatItem[];

      if (chats.length <= 2) {
        yield put(setRelationData({
          data: undefined,
          error: undefined,
        }));
      }
    },
  );
}

function* newTaskFlow() {
  yield takeEvery(
    GptActions.CHAT_CLEAN_REQUEST,
    function* _() {
      yield put(setRelationData({
        data: undefined,
      }));
    },
  );
}

function* relationDataSetFlow() {
  yield takeEvery(
    RelationChartActions.RELATION_DATA_SET,
    function* _(act: PayloadAction<SetRelationDataArgs>) {
      if (!act.payload.data?.nodes || !act.payload.data?.relationships) {
        return;
      }

      yield put(requestChatHistory());
    },
  );
}

export default function* relationChartSaga() {
  yield all([
    newTaskFlow(),
    requestRelationDataFlow(),
    chatResponseRequestFlow(),
    relationDataSetFlow(),
  ]);
}
