import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Typography,
} from '@mui/material';

import CountUp from 'react-countup';
import classNames from 'classnames';
import useMeasure from 'react-use-measure';

import { LinearGauge, ScoreChartPanel } from '../../../../components';
import { useCommonClasses } from '../../../../theme/commonStyles';
import { easeOut } from '../../../../core/utils/animationUtils';

import { DistributionModelKey } from '../types';
import {
  AMOUNT_1M,
  getAmountDisplayWithSymbol,
  getScoreAsPercentage,
} from '../reportUtils';

import { PropsFromRedux } from './container';
import styles from './ScoreReportChart.styles';

interface Props extends PropsFromRedux {
  setCurrentDataInfoKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  height?: number;
  currentDistributionModel?: DistributionModelKey;
  section?: string;
}

const SCORE_CHART_LABEL_HEIGHT_VARIANT = 140;
const CHART_MARGIN_TOP = 16;

const useStyles = makeStyles(styles);

const ScoreReportChart: React.FC<Props> = ({
  setCurrentDataInfoKey,
  distributionsInfo,
  height,
  currentDistributionModel,
  companyReport,
  investorReport,
  section,
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const [scoreGaugeRef, scoreGaugeBounds] = useMeasure();

  const getScoreChartContainerHeight = () => (
    height && scoreGaugeBounds
      ? (
        height
          - scoreGaugeBounds.height
          - CHART_MARGIN_TOP
      )
      : 0
  );

  const getScoreChartHeight = () => (
    getScoreChartContainerHeight() - SCORE_CHART_LABEL_HEIGHT_VARIANT
  );

  const getEstimatedValuationDisplay = useCallback((amount: number) => {
    if (amount >= AMOUNT_1M) {
      return getAmountDisplayWithSymbol(amount, {
        decimals: 2,
        capitalized: false,
        smallUnit: false,
        symbol: '$',
      });
    }

    return getAmountDisplayWithSymbol(amount, {
      decimals: 0,
      capitalized: false,
      smallUnit: false,
      symbol: '$',
    });
  }, []);

  return (
    <Box className={classes.root} height={height}>
      {height && (
        <Box
          className={classes.scoreChart}
          height={getScoreChartContainerHeight()}
          marginTop={CHART_MARGIN_TOP / 8}
        >
          <ScoreChartPanel
            title={
              <Typography
                variant="h2"
                className={classes.scoreChartTitle}
              >
                {section === 'profileScore' ? 'Profile Score' : 'Likelihood to Exit'}
              </Typography>
            }
            distributionsData={distributionsInfo?.[currentDistributionModel || '']}
            score={getScoreAsPercentage(
              section === 'profileScore'
                ? companyReport?.companyData.overallScore
                : investorReport?.investorData.overallScore,
            )}
            remark={companyReport?.companyData.remark}
            animationDuration={1500}
            chartHeight={
              getScoreChartHeight()
            }
            colorSet={section}
            showSubBanner={section === 'profileScore'}
            onMouseEnter={() => (
              setCurrentDataInfoKey(section === 'profileScore'
                ? 'c2CompanyScore'
                : 'i31PredictionScore')
            )}
            onMouseLeave={() => setCurrentDataInfoKey(undefined)}
          />
        </Box>
      )}
      <Box ref={scoreGaugeRef} paddingTop={3} paddingBottom={2}>
        {section === 'profileScore' && (
          <Box>
            <LinearGauge
              title={
                <Typography
                  variant="h2"
                  className={classes.vcLikeableChartTitle}
                >
                  VC Likeable
                </Typography>
              }
              value={companyReport?.companyData.vcLikeable || 0}
              onMouseEnter={() => (
                setCurrentDataInfoKey('c3Vclikeable')
              )}
              onMouseLeave={() => setCurrentDataInfoKey(undefined)}
            />
          </Box>
        )}
        {section === 'successScore' && (
          <Box display="flex" alignItems="center" height="80px">
            <Typography
              variant="h2"
              className={classes.estimatedValuationTitle}
            >
              Estimated Valuation
            </Typography>
            <Box
              marginLeft={2}
              className={
                classNames(
                  commonCss.banners.investBanner,
                )
              }
            >
              <Typography
                variant="h2"
                className={
                  classNames(
                    classes.estimatedValuationValue,
                  )
                }
              >
                <CountUp
                  end={(investorReport?.valuation || 0) * AMOUNT_1M}
                  duration={1}
                  decimals={2}
                  useEasing
                  easingFn={easeOut}
                  formattingFn={getEstimatedValuationDisplay}
                />
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ScoreReportChart;
