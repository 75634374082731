import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      ...commonStyles.mediumRoundedBorder,
      display: 'flex',
      fontWeight: 300,
      borderBottomLeftRadius: 0,
      background: theme.palette.common.white,
      padding: theme.spacing(3),
      height: theme.spacing(15),
      alignItems: 'center',
    },
    innerContent: {
      color: theme.palette.grey[900],
    },
  });
};

export default styles;
