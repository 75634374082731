import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';

import getCommonStyles from '../../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 300,
    },
    title: {
      fontWeight: '400 !important',
      fontSize: '1.2rem !important',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    divider: {
      borderColor: `${Color(theme.palette.common.black).alpha(0.25).toString()} !important`,
      zIndex: 1,
      '&.top': {
        marginTop: 'max(calc((100vh - 860px) / 7), 8px) !important',
        marginBottom: `${theme.spacing(2)} !important`,
      },
    },
    investSlider: {
      width: '100%',
      height: theme.spacing(2),
      display: 'block',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: -8,
        width: '100%',
        height: 24,
        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
      },
    },
    investSliderMarkLabel: {
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.palette.grey[500],
      marginBottom: theme.spacing(2),
    },
    reportGroupRow: {
      marginTop: 0,
      marginBottom: 0,
    },
    reportRow: {
      cursor: 'pointer',
    },
    button: {
      '&.MuiButton-contained': {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
      },
    },
    selectorDropDownIcon: {
      position: 'absolute',
      height: 12,
      right: 9,
      top: 10,
    },
  });
};

export default styles;
