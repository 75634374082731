import { createAction } from '@reduxjs/toolkit';
import {
  MainActions,
  OpenGlossaryBubbleArgs,
  OpenMessageBoxArgs,
  OpenReportArgs as OpenCompanyReportArgs,
  RequestGlossaryItemsArgs,
  RequestSubmitContactFormArgs,
  RequestSubscribeArgs,
  SetContactFormSubmittedArgs,
  SetGlossaryItemsArgs,
  SetSubsribedArgs,
} from './types';

export const openMessageBox = createAction<OpenMessageBoxArgs>(MainActions.MESSAGE_BOX_OPEN);
export const closeMessageBox = createAction(MainActions.MESSAGE_BOX_CLOSE);
export const openFeedbackForm = createAction(MainActions.FEEDBACK_FORM_OPEN);
export const closeFeedbackForm = createAction(MainActions.FEEDBACK_FORM_CLOSE);
export const openContactForm = createAction(MainActions.CONTACT_FORM_OPEN);
export const closeContactForm = createAction(MainActions.CONTACT_FORM_CLOSE);
export const requestSubmitContactForm = (
  createAction<RequestSubmitContactFormArgs>(MainActions.CONTACT_FORM_SUBMIT_REQUEST)
);
export const setContactFormSubmitted = (
  createAction<SetContactFormSubmittedArgs>(MainActions.CONTACT_FORM_SUBMITTED_SET)
);
export const requestSubscribe = (
  createAction<RequestSubscribeArgs>(MainActions.SUBSCRIBE_REQUEST)
);
export const setSubscribed = (
  createAction<SetSubsribedArgs>(MainActions.SUBSCRIBED_SET)
);
export const openCompanyReport = (
  createAction<OpenCompanyReportArgs>(MainActions.COMPANY_REPORT_OPEN)
);
export const openGlossaryBubble = (
  createAction<OpenGlossaryBubbleArgs>(MainActions.GLOSSARY_BUBBLE_OPEN)
);
export const closeGlossaryBubble = (
  createAction(MainActions.GLOSSARY_BUBBLE_CLOSE)
);
export const requestGlossaryItems = (
  createAction<RequestGlossaryItemsArgs>(MainActions.GLOSSARY_ITEMS_REQUEST)
);
export const setGlossaryItems = (
  createAction<SetGlossaryItemsArgs>(MainActions.GLOSSARY_ITEMS_SET)
);
