class HttpError extends Error {
  statusCode?: number = undefined;

  constructor(code: number, message?: string, extras?: unknown) {
    super(message);
    if (arguments.length >= 3 && extras) {
      Object.assign(this, extras);
    }
    this.name = message || String(code);
    this.statusCode = code;
  }
}

export default HttpError;
