import palette from './palette.module.css';

export const primary = {
  50: palette.primary50,
  100: palette.primary100,
  200: palette.primary200,
  300: palette.primary300,
  400: palette.primary400,
  500: palette.primary500,
  600: palette.primary600,
  700: palette.primary700,
  800: palette.primary800,
  900: palette.primary900,
  A100: palette.primaryA100,
  A200: palette.primaryA200,
  A400: palette.primaryA400,
  A700: palette.primaryA700,
  contrastDefaultColor: 'dark',
};

export const secondary = {
  50: palette.secondary50,
  100: palette.secondary100,
  200: palette.secondary200,
  300: palette.secondary300,
  400: palette.secondary400,
  500: palette.secondary500,
  600: palette.secondary600,
  700: palette.secondary700,
  800: palette.secondary800,
  900: palette.secondary900,
  A100: palette.secondaryA100,
  A200: palette.secondaryA200,
  A400: palette.secondaryA400,
  A700: palette.secondaryA700,
  contrastDefaultColor: 'dark',
};

export const grey = {
  50: palette.grey50,
  100: palette.grey100,
  200: palette.grey200,
  300: palette.grey300,
  400: palette.grey400,
  500: palette.grey500,
  600: palette.grey600,
  700: palette.grey700,
  800: palette.grey800,
  900: palette.grey900,
  A100: palette.greyA100,
  A200: palette.greyA200,
  A400: palette.greyA400,
  A700: palette.greyA700,
  contrastDefaultColor: 'dark',
};

export const otherColorSets = {
  0: palette.primary500,
  1: palette.alt0,
  2: palette.alt1,
  3: palette.alt2,
  4: palette.alt3,
  5: palette.alt4,
  6: palette.alt5,
} as Record<number, string>;

export const otherContrastColorSets = {
  0: palette.contrast0,
  1: palette.secondary500,
  2: palette.contrast1,
  3: palette.contrast2,
  4: palette.contrast3,
  5: palette.contrast4,
  6: palette.contrast5,
} as Record<number, string>;

export const otherLightColorSets = {
  0: palette.light0,
  1: palette.light1,
  2: palette.light2,
  3: palette.light3,
  4: palette.light4,
  5: palette.light5,
  6: palette.light6,
} as Record<number, string>;

export const otherBorderColorSets = {
  0: palette.border0,
  1: palette.border1,
  2: palette.border2,
  3: palette.border3,
};

export const otherAltLightColorSets = {
  0: palette.altLight0,
  1: palette.altLight1,
  2: palette.altLight2,
  3: palette.altLight3,
};

export const sourceRefColor = palette.sourceColor;
export const sourceRefContrastColor = palette.sourceContrastColor;
export const sourceRefLightColor = palette.sourceLightColor;

export const {
  black,
  white,
  defaultTextColor,
  contrastTextColor,
} = palette;
