import React, { ReactElement, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

type Props = {
  token?: string;
  redirectPath: string;
  children?: ReactElement;
};

const ProtectedRoute: React.FC<Props> = ({
  token,
  redirectPath,
  children,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(redirectPath, { replace: true });
    }
  }, [token, redirectPath]);

  return token ? (children || <Outlet />) : <div />;
};

export default ProtectedRoute;
