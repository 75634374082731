import { createReducer } from '@reduxjs/toolkit';
import getCountryIso2 from 'country-iso-3-to-2';
import { removeStopwords, eng } from 'stopword';

import { getCountryByIsoCode } from '../../../core/utils/countryUtils';
import { getCommonWords } from '../../../core/utils/stringUtils';
import {
  addMarketItem,
  initializeReport,
  removeMarketItem,
  requestCompanyData,
  requestCompetitorsData,
  requestDistributionsData,
  requestInvestorData,
  requestMarketData,
  setMarketAggregate,
  setCompanyData,
  setDistributionsData,
  setInvestorData,
  setMarketData,
  toggleDataInfoKeySelection,
  updateInvestingParams,
  setMarketView,
  requestMarketCountries,
  setMarketCountries,
  selectMarketCountry,
  setMarketItems,
  setMarketEcoIndicator,
  setMarketHighlight,
  setCompetitorsData,
  setCompetitorHighlight,
  setLandscapeStatus,
  setLandscapeIsFunded,
  selectCompetitor,
} from './actions';
import {
  AMOUNT_15M,
  AMOUNT_1M,
  AMOUNT_25K,
} from './reportUtils';
import {
  InvestingParams,
  MarketCountryInfo,
  ReportState,
} from './types';

const initialState: ReportState = {
  networkStates: {
    companyDataRequest: { isRequesting: false },
    investorDataRequest: { isRequesting: false },
    distributionsDataRequest: { isRequesting: false },
    marketDataRequest: { isRequesting: false },
    marketCountriesRequest: { isRequesting: false },
    competitorsDataRequest: { isRequesting: false },
  },
  investingParams: {
    investorExp: 1,
    investorType: 1,
    geography: 1,
  },
  selectedDataInfoKeys: [],
  selectedMarketItems: [],
  isAggregateMarketItems: false,
  selectedMarketView: 'marketSize',
  marketEconomicIndicators: {
    gdp: true,
    gdpPerCap: false,
    inflation: false,
    marketCap: false,
  },
  selectedCompetitors: [],
  landscapeStatus: 'operating',
  landscapeIsFunded: false,
};

const reportReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(initializeReport, (_, action) => ({
      ...initialState,
      formId: action.payload.companyId,
    }))
    .addCase(requestCompanyData, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        companyDataRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setCompanyData, (state, action) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        companyDataRequest: {
          ...state.networkStates.companyDataRequest,
          isRequesting: false,
          lastError: action.payload.error,
        },
      },
      companyReport: action.payload.companyReport,
    }))
    .addCase(requestInvestorData, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        investorDataRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setInvestorData, (state, action) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        investorDataRequest: {
          ...state.networkStates.investorDataRequest,
          isRequesting: false,
          lastError: action.payload.error,
        },
      },
      investorReport: action.payload.investorReport,
    }))
    .addCase(requestDistributionsData, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        distributionsDataRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setDistributionsData, (state, action) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        distributionsDataRequest: {
          isRequesting: false,
          lastError: action.payload.error,
        },
      },
      distributionsInfo: {
        ...state.distributionsInfo,
        [action.payload.model]: action.payload.data,
      },
    }))
    .addCase(requestMarketData, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        marketDataRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setMarketData, (state, action) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        marketDataRequest: {
          isRequesting: false,
          lastError: action.payload.error,
        },
      },
      marketReport: action.payload.marketReport,
      selectedMarketItems: [],
      selectedMarketView: 'marketSize',
    }))
    .addCase(requestMarketCountries, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        marketCountriesRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setMarketCountries, (state, action) => {
      const globalCountryInfo = {
        currency: 'USD',
        flag: '',
        isoCode: '',
        latitude: '',
        longitude: '',
        phonecode: '',
        name: 'Global',
      };

      const marketCountries = (
        (action.payload.marketCountryInfos || []).map((countryInfo) => {
          const isoCode = getCountryIso2(countryInfo.countryCode);
          return {
            ...countryInfo,
            country: isoCode ? getCountryByIsoCode(isoCode) : undefined,
          };
        }).sort((a, b) => (a.country?.name || '').localeCompare(b.country?.name || ''))
          .filter((market) => !!market.country)
      );

      const worldCountryItem = action.payload.marketCountryInfos?.find(
        (countryInfo) => countryInfo.countryCode === 'WLD',
      );

      const updatedState = ({
        ...state,
        networkStates: {
          ...state.networkStates,
          marketCountriesRequest: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
        marketCountries: [
          ...marketCountries,
          {
            ...worldCountryItem,
            country: globalCountryInfo,
          },
        ] as MarketCountryInfo[],
      });

      return updatedState;
    })
    .addCase(requestCompetitorsData, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        competitorsDataRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setCompetitorsData, (state, action) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        competitorsDataRequest: {
          isRequesting: false,
          lastError: action.payload.error,
        },
      },
      competitorsReport: action.payload.competitorsReport,
      selectedCompetitors: action.payload.competitorsReport?.top5 || [],
    }))
    .addCase(updateInvestingParams, (state, action) => {
      const investingParams = {
        ...state.investingParams,
        [action.payload.path]: action.payload.value,
      } as InvestingParams;

      if (action.payload.path === 'maxAmount') {
        if (action.payload.value === AMOUNT_1M) {
          investingParams.amount = AMOUNT_25K;
        }
        if (action.payload.value === AMOUNT_15M) {
          investingParams.amount = AMOUNT_1M;
        }
      }

      return ({
        ...state,
        investingParams,
      });
    })
    .addCase(toggleDataInfoKeySelection, (state, action) => {
      const { selectedDataInfoKeys } = state;
      if (selectedDataInfoKeys.includes(action.payload.dataInfoKey)) {
        return {
          ...state,
          selectedDataInfoKeys: selectedDataInfoKeys.filter(
            (dataKey) => dataKey !== action.payload.dataInfoKey,
          ),
        };
      }

      return ({
        ...state,
        selectedDataInfoKeys: [
          ...selectedDataInfoKeys,
          action.payload.dataInfoKey,
        ],
      });
    })
    .addCase(addMarketItem, (state, action) => ({
      ...state,
      selectedMarketItems: [
        ...state.selectedMarketItems,
        action.payload.market,
      ],
    }))
    .addCase(removeMarketItem, (state, action) => {
      const { selectedMarketItems } = state;
      const updatedMarketItems = selectedMarketItems.filter(
        (market) => market !== action.payload.market,
      );

      return {
        ...state,
        selectedMarketItems: updatedMarketItems,
        isAggregateMarketItems: updatedMarketItems.length < 2
          ? false
          : state.isAggregateMarketItems,
        selectedHighlightMarket: state.selectedHighlightMarket === action.payload.market
          ? undefined
          : state.selectedHighlightMarket,
        hoveringHighlightMarket: state.hoveringHighlightMarket === action.payload.market
          ? undefined
          : state.hoveringHighlightMarket,
      };
    })
    .addCase(setMarketItems, (state, action) => ({
      ...state,
      selectedMarketItems: action.payload.markets,
      isAggregateMarketItems: action.payload.markets.length < 2
        ? false
        : state.isAggregateMarketItems,
    }))
    .addCase(setMarketAggregate, (state, action) => ({
      ...state,
      isAggregateMarketItems: action.payload.isAggregate,
    }))
    .addCase(setMarketView, (state, action) => ({
      ...state,
      selectedMarketView: action.payload.marketView,
    }))
    .addCase(selectMarketCountry, (state, action) => ({
      ...state,
      selectedMarketCountry: action.payload.marketCountry,
    }))
    .addCase(setMarketEcoIndicator, (state, action) => ({
      ...state,
      marketEconomicIndicators: {
        ...state.marketEconomicIndicators,
        [action.payload.key]: action.payload.value,
      },
    }))
    .addCase(setMarketHighlight, (state, action) => ({
      ...state,
      selectedHighlightMarket: action.payload.isPermanent
        ? action.payload.marketItem
        : state.selectedHighlightMarket,
      hoveringHighlightMarket: !action.payload.isPermanent
        ? action.payload.marketItem
        : state.hoveringHighlightMarket,
    }))
    .addCase(setCompetitorHighlight, (state, action) => {
      if (state.highlightingCompetitor?.isPersist) {
        if (!action.payload.isPersist) {
          return state;
        }
      }

      const targetDesc = state.companyReport?.companyData?.orgDesc || '';
      const targetKeywords = (state.competitorsReport?.target?.at(0)?.keywords || '').split(',');
      const competitorDesc = action.payload.competitor?.description || '';
      const competitorKeywords = (action.payload.competitor?.keywords || '').split(',');

      const commonDescKeywords = removeStopwords(
        getCommonWords(targetDesc.split(' '), competitorDesc.split(' ')),
        eng,
      );

      const commonKeywords = getCommonWords(targetKeywords, competitorKeywords);

      return ({
        ...state,
        highlightingCompetitor: {
          competitor: action.payload.competitor,
          isPersist: action.payload.competitor ? action.payload.isPersist : undefined,
          commonDescKeywords,
          commonKeywords,
        },
      });
    })
    .addCase(selectCompetitor, (state, action) => {
      const existingCompetitorIndex = state.selectedCompetitors.findIndex(
        (c) => c.id === action.payload.competitor?.id,
      );

      if (existingCompetitorIndex >= 0) {
        return {
          ...state,
          selectedCompetitors: state.selectedCompetitors.filter(
            (_, i) => i !== existingCompetitorIndex,
          ),
        };
      }

      return {
        ...state,
        selectedCompetitors: [
          ...state.selectedCompetitors,
          action.payload.competitor,
        ],
      };
    })
    .addCase(setLandscapeStatus, (state, action) => ({
      ...state,
      landscapeStatus: action.payload.status,
    }))
    .addCase(setLandscapeIsFunded, (state, action) => ({
      ...state,
      landscapeIsFunded: action.payload.isFunded,
    }));
});

export default reportReducer;
