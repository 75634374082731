import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
    },
    block: {
      alignItems: 'center',
      display: 'flex',
      height: 17,
    },
    dot: {
      backgroundColor: theme.palette.grey[300],
      animation: '$mercuryTypingAnimation 1.5s infinite ease-in-out',
      borderRadius: 2,
      display: 'inline-block',
      height: 4,
      marginRight: 2,
      width: 4,
      '&:nth-child(1)': {
        animationDelay: '200ms',
      },
      '&:nth-child(2)': {
        animationDelay: '300ms',
      },
      '&:nth-child(3)': {
        animationDelay: '400ms',
      },
    },
    '@keyframes mercuryTypingAnimation': {
      '0%': { transform: 'translateY(0px)' },
      '28%': { transform: 'translateY(-5px)' },
      '44%': { transform: 'translateY(0px)' },
    },
  });
};

export default styles;
