import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 300,
      width: theme.spacing(40),
    },
    searchKeywordBox: {
      padding: `${theme.spacing(1)} !important`,
    },
    countryNameText: {
      marginLeft: theme.spacing(1),
    },
  });
};

export default styles;
