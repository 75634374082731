import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';
import getCommonStyles from '../../../../theme/commonStyles';
import {
  otherAltLightColorSets,
  otherColorSets,
  otherContrastColorSets,
  otherLightColorSets,
  sourceRefColor,
} from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      width: '100%',
      '& p': {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
        marginBlockStart: 0,
        marginBlockEnd: 0,
      },
      '& *': {
        // fontWeight: 'normal !important',
        color: `${theme.palette.grey[900]}`,
      },
      '& > table': {
        width: '100%',
        fontWeight: 'normal !important',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      '& > table, th, td': {
        borderCollapse: 'collapse',
        border: `1px solid ${theme.palette.grey[100]}`,
      },
      '& th': {
        fontWeight: '400 !important',
      },
      '& th, td': {
        padding: theme.spacing(1),
      },
    },
    markdown: {
      // whiteSpace: 'pre-wrap',
      fontSize: '1.0714rem !important',
      fontWeight: 'normal !important',
      lineHeight: 1.75,

      '& p:not(.MuiTablePagination-selectLabel):not(.MuiTablePagination-displayedRows):not(:first-child), & table:not(:first-child)': {
        marginTop: theme.spacing(1),
        width: '100%',
      },
      '& table': {
        borderSpacing: 0,
        width: '100%',
      },
      '& table > thead > tr > th': {
        backgroundColor: otherLightColorSets[2],
      },
      '& table tr:first-child th:not(:first-child)': {
        borderLeftWidth: 0,
      },
      '& table th': {
        borderColor: 'rgb(224, 224, 224)',
        fontSize: '14px',
        // color: `${otherContrastColorSets[2]} !important`,
        fontWeight: '500 !important',
        backgroundColor: `${theme.palette.common.white} !important`,
        textAlign: 'left',
      },
      '& table td': {
        borderTopWidth: 0,
        borderColor: 'rgb(224, 224, 224)',
        fontSize: '14px',
        backgroundColor: `${theme.palette.common.white} !important`,
      },
      '& table td:not(:first-child)': {
        borderLeftWidth: 0,
      },
      '& table tr:first-child th:first-child': {
        borderTopLeftRadius: theme.spacing(1.5),
      },
      '& table tr:first-child th:last-child': {
        borderTopRightRadius: theme.spacing(1.5),
      },
      '& table tr:last-child td:first-child': {
        borderBottomLeftRadius: theme.spacing(1.5),
      },
      '& table tr:last-child td:last-child': {
        borderBottomRightRadius: theme.spacing(1.5),
      },
      '& .katex-display>.katex>.katex-html': {
        textAlign: 'left',
      },
    },
    stepMarkdown: {
      // whiteSpace: 'pre-wrap',
      fontSize: '12px !important',
      fontWeight: '300 !important',
      color: `${theme.palette.grey[500]} !important`,
      wordWrap: 'break-word',

      '&:not(.showFull)': {
        display: '-webkit-box',
        wordBreak: 'break-all',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
      },

      '& p:not(:first-child), & table:not(:first-child)': {
        marginTop: theme.spacing(1),
      },

      '& *, & th': {
        fontSize: '12px !important',
        fontWeight: '300 !important',
        color: `${theme.palette.grey[500]} !important`,
      },

      '& .MuiDataGrid-toolbarContainer': {
        display: 'none !important',
      },

      '& .MuiCheckbox-root': {
        display: 'none !important',
      },

      '& .MuiTablePagination-selectLabel': {
        marginTop: '0 !important',
      },

      '& .MuiTablePagination-displayedRows': {
        marginTop: '0 !important',
      },

      '& hr': {
        display: 'none',
      },
    },
    sourceHighlight: {
      color: `${otherColorSets[2]} !important`,
      // verticalAlign: 'super',
      fontSize: '0.9rem !important',
      fontWeight: '500 !important',
      cursor: 'pointer',
      width: 18,
      height: 18,
      borderRadius: 4,
      backgroundColor: otherAltLightColorSets[2],
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.3s ease-out',
      textDecoration: 'none !important',
      marginBottom: '0 !important',
      minWidth: '18px !important',
      '&:hover:not(.noEvent)': {
        backgroundColor: Color(otherAltLightColorSets[2]).darken(0.25).toString(),
      },
      '&.noEvent': {
        cursor: 'default',
      },
    },
    sourceBubbleTitle: {
      marginLeft: `${theme.spacing(0.5)} !important`,
      fontWeight: 'bold !important',
      color: `${theme.palette.grey[700]} !important`,
      fontSize: '0.928rem !important',
      lineHeight: 1.23,
      textDecoration: 'underline',
      textDecorationColor: `${theme.palette.grey[700]} !important`,
    },
    sourceBubbleContent: {
      marginTop: `${theme.spacing(1)} !important`,
      marginLeft: `${theme.spacing(0.75)} !important`,
      '& span': {
        fontWeight: '300 !important',
        color: `${theme.palette.grey[700]} !important`,
        fontSize: '0.857rem !important',
        lineHeight: 1.42,
      },
      '& a': {
        color: `${sourceRefColor} !important`,
      },
    },
    sourceBubbleFavIcon: {
      width: theme.spacing(2),
      verticalAlign: 'center',
      marginRight: theme.spacing(0.5),
      filter: 'grayscale(100%)',
    },
    sourceBubbleDomain: {
      color: `${theme.palette.grey[300]} !important`,
      fontSize: '0.714rem !important',
      letterSpacing: '0.04px',
      fontWeight: 'normal !important',
      display: 'inline-block',
      verticalAlign: 'center',
    },
    chartBox: {
      display: 'block',
      '&:not(:first-child)': {
        marginTop: theme.spacing(2),
      },
    },
    entityHighlight: {
      cursor: 'pointer',
      display: 'inline-block',
      '&.Other': {
        color: 'inherit',
        textDecorationColor: `${theme.palette.grey[100]} !important`,
      },
      '&.Location': {
        color: 'inherit',
        textDecorationColor: `${otherContrastColorSets[3]} !important`,
      },
      '&.Person': {
        color: 'inherit',
        textDecorationColor: `${otherContrastColorSets[2]} !important`,
      },
      '&.Industry': {
        color: 'inherit',
        textDecorationColor: `${theme.palette.primary.dark} !important`,
      },
      '&.Organization': {
        color: 'inherit',
        textDecorationColor: `${theme.palette.secondary.dark} !important`,
      },
      '&.School': {
        color: 'inherit',
        textDecorationColor: `${theme.palette.secondary.dark} !important`,
      },
      textDecorationThickness: '1.3px !important',
      textUnderlineOffset: '2px !important',
    },
    showMoreStepContent: {
      fontSize: 12,
      textDecoration: 'none !important',
      cursor: 'pointer !important',
      '&:hover': {
        textDecoration: 'underline !important',
        textDecorationColor: otherContrastColorSets[2],
      },
    },
    codeBlockHeader: {
      padding: `4px ${theme.spacing(2)}`,
      background: 'rgb(250, 250, 250)',
      borderBottom: `1px solid ${theme.palette.grey[50]}`,
      '& + div': {
        marginTop: '0 !important',
      },
      '& .title': {
        fontWeight: 'bold',
      },
    },
    copyCodeLink: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 4,
      color: `${theme.palette.grey[500]} !important`,
      textDecorationColor: `${theme.palette.grey[500]} !important`,
      textDecoration: 'none !important',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline !important',
      },
    },
    tableSourceTitle: {
      color: `${theme.palette.grey[300]} !important`,
    },
    generalCodeBlock: {
      whiteSpace: 'normal',
    },
    requestingChart: {
      height: 378,
      border: '1px solid rgb(224, 224, 224)',
      borderRadius: theme.spacing(1.5),
      display: 'flex',
      marginTop: 45,
      background: theme.palette.common.white,
      alignItems: 'center',
      justifyContent: 'center',
      '& svg > *': {
        color: `${otherContrastColorSets[2]} !important`,
      },
    },
    showMeButtonContainer: {
      position: 'absolute',
      right: `${theme.spacing(1.5)} !important`,
      bottom: `${theme.spacing(1.5)} !important`,
    },
    showMeButton: {
      height: '20px !important',
      borderRadius: `${theme.spacing(1)} !important`,
      backgroundColor: '#e7e7f6 !important',
      color: `${otherContrastColorSets[2]} !important`,
      '&:hover': {
        backgroundColor: `${Color('#e7e7f6').darken(0.05).toString()} !important`,
      },
      '& .carretDownIconPath': {
        stroke: `${otherContrastColorSets[2]} !important`,
      },
    },
  });
};

export default styles;
