import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';

import {
  requestGptModels,
  updateSettings,
} from '../actions';

import SettingsView from './SettingsView';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.gpt,
    ...state.session,
    networkStates: {
      ...state.gpt.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    requestGptModels,
    updateSettings,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SettingsView);
