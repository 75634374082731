import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    scoreChart: {
      width: '100%',
    },
    scoreChartTitle: {
      fontWeight: '100 !important',
    },
    vcLikeableChartTitle: {
      fontWeight: '100 !important',
    },
    estimatedValuationTitle: {
      fontSize: '2.5rem !important',
      fontWeight: '100 !important',
      [theme.breakpoints.down('xl')]: {
        fontSize: '1.7rem !important',
      },
    },
    estimatedValuationValue: {
      fontSize: '2.5rem !important',
      fontWeight: '400 !important',
      padding: `${theme.spacing(0.5)} 0`,
      width: '140px !important',
      textAlign: 'center',
      [theme.breakpoints.down('xl')]: {
        fontSize: '2rem !important',
        width: '120px !important',
      },
    },
  });
};

export default styles;
