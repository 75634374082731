import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import { getCustomScrollBarCss } from '../../../../core/utils/uxUtils';
import getCommonStyles from '../../../../theme/commonStyles';
import { otherContrastColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    expander: {
      display: 'flex',
      width: '100%',
      flex: 0,
    },
    expanderContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
      // backgroundColor: otherAltLightColorSets[2],
      borderLeft: `1px solid ${theme.palette.grey[50]}`,
      '&.highlight': {
        borderLeft: `1px solid ${otherContrastColorSets[2]}`,
      },
      '& button': {
        color: `${otherContrastColorSets[2]} !important`,
        textTransform: 'uppercase',
        justifyContent: 'flex-start',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      '& svg': {
        color: theme.palette.grey[50],
      },
      '& button:hover svg': {
        color: 'inherit',
      },
    },
    gptCaretUpIcon: {
      width: theme.spacing(2),
      transition: 'all ease-out 0.3s',
      transform: 'rotate(180deg)',
      '&.rotate': {
        transform: 'rotate(90deg)',
      },
      '& #svgCaretUpIcon': {
        stroke: `${otherContrastColorSets[2]} !important`,
      },
    },
    label: {
      fontSize: '1.142rem !important',
      fontWeight: 'normal !important',
      color: `${theme.palette.grey[900]} !important`,
    },
    temperatureSlider: {
      width: '100%',
      height: 1,
      display: 'block',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: -5,
        width: '100%',
        height: 12,
        borderLeft: `1px solid ${otherContrastColorSets[2]}`,
        borderRight: `1px solid ${otherContrastColorSets[2]}`,
      },
    },
    temperatureSliderMarkLabel: {
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.palette.grey[500],
      marginBottom: theme.spacing(2),
    },
    settingSectionContainer: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      overflowY: 'auto',
      overflowX: 'hidden',
      height: 0,
      ...getCustomScrollBarCss(),
    },
    gptModelLabel: {
      width: '100%',
      '& .MuiTypography-root': {
        color: `${theme.palette.grey[900]} !important`,
        fontWeight: 'normal',
      },
      '& .modelName': {
        [theme.breakpoints.up(1800)]: {
          fontSize: '1.142rem !important',
        },
      },
      '& .modelOwner': {
        color: '#a5a5a5 !important',
      },
      '& .modelRate': {
        color: `${otherContrastColorSets[2]} !important`,
      },
      '&.selected': {
        backgroundColor: '#f6f6f6 !important',

        '& .modelName': {
          fontWeight: 'bold',
        },
      },
    },
    gptModelOptionLabel: {
      fontSize: '0.9rem !important',
      fontWeight: 'normal',
      color: `${theme.palette.grey[500]} !important`,
    },
    modelSuffixBadge: {
      height: '12px !important',
      backgroundColor: '#B9D5FD !important',
      border: 'none !important',
      '& .MuiChip-label': {
        padding: '0 5px !important',
        fontSize: '0.7rem !important',
        color: `${otherContrastColorSets[1]} !important`,
      },
    },
    sectionHeader: {
      fontSize: '1.1rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 1.7,
      marginRight: theme.spacing(1),
    },
    sectionHeaderAdditional: {
      color: theme.palette.grey[300],
      display: 'inline-block',
      marginLeft: theme.spacing(2),
      fontSize: '1.142rem',
      fontWeight: 500,
      letterSpacing: 'normal',
      textTransform: 'none',
    },
  });
};

export default styles;
