import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';

import {
  requestCompanyItems,
  openFilterEdit,
  closeFilterEdit,
  updateCountriesFilter,
  updateIndustriesFilter,
  updateVerticalsFilter,
  updateMinFoundingYearsFilter,
} from '../actions';

import CompanySearchFilter from './CompanySearchFilter';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.search,
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    requestCompanyItems,
    openFilterEdit,
    closeFilterEdit,
    updateCountriesFilter,
    updateIndustriesFilter,
    updateVerticalsFilter,
    updateMinFoundingYearsFilter,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CompanySearchFilter);
