import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';
import getCommonStyles, { getContrastColorModifierClasses } from '../../../../theme/commonStyles';
import { otherColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flexDirection: 'column',
      color: `${theme.palette.grey[400]} !important`,
    },
    helpPanelContainer: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(-15),
      marginRight: theme.spacing(-15),
    },
    helpPanel: {
      height: 400,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: theme.spacing(2),
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      ...getContrastColorModifierClasses('borderColor'),
    },
    helpHeader: {
      color: `${theme.palette.grey[900]} !important`,
    },
    helpPanelTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...getContrastColorModifierClasses('color'),
      ...getContrastColorModifierClasses('color', ' p.MuiTypography-root'),
      '& p.MuiTypography-root': {
        fontSize: '1.285rem',
        fontWeight: 'normal',
      },
    },
    helpPanelRole: {
      marginTop: `${theme.spacing(1)} !important`,
      fontWeight: 'normal !important',
      color: `${theme.palette.grey[300]} !important`,
    },
    industryExample: {
      textDecoration: 'underline',
      textDecorationColor: Color(otherColorSets[0]).alpha(0.5).toString(),
      textDecorationThickness: 1,
      cursor: 'pointer',
    },
    glossaryExampleHeader: {
      color: `${theme.palette.grey[900]} !important`,
    },
    glossaryExampleContainer: {
      display: 'flex !important',
      flexWrap: 'wrap',
      padding: '0px !important',
    },
    glossaryExample: {
      marginRight: '5px',
      textDecoration: 'underline',
      textDecorationColor: Color(otherColorSets[3]).alpha(0.5).toString(),
      textDecorationThickness: 1,
      cursor: 'pointer',
    },
    otherItemLink: {
      color: `${theme.palette.grey[900]} !important`,
      textDecorationColor: `${theme.palette.grey[900]} !important`,
      textDecorationThickness: '0.5px !important',
      textDecorationWidth: '0.5px !important',
      cursor: 'pointer',
      '&:hover': {
        textDecorationThickness: '1px !important',
        textDecorationWidth: '1px !important',
      },
    },
  });
};

export default styles;
