import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
    content: {
      minWidth: theme.spacing(48),
    },
    title: {
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    closeButton: {
      background: 'red',
    },
  });
};

export default styles;
