import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../../theme/commonStyles';
import dimensions from '../../../../theme/dimensions';
import {
  otherAltLightColorSets,
  otherColorSets,
  otherContrastColorSets,
  sourceRefLightColor,
} from '../../../../theme/palette';

const PORTAL_WIDTH = dimensions.gptPortal.width;
const CHAT_WIDTH = PORTAL_WIDTH - 200;

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    '@global': {
      '[contenteditable=true]': {
        '&:empty': {
          display: 'inline-block',
          '&:before': {
            content: 'attr(placeholder)',
            color: `${theme.palette.grey[100]} !important`,
            // pointerEvents: 'none',
          },
        },
      },
    },
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    gptChatItem: {
      flex: 1,
      marginLeft: theme.spacing(1),
      padding: `${theme.spacing(0)} ${theme.spacing(7)} ${theme.spacing(0)} ${theme.spacing(1)}`,
      '&.error': {
        fontStyle: 'italic',
        color: theme.palette.error.main,
      },
      fontWeight: '300 !important',
      maxWidth: CHAT_WIDTH,
      '&.task': {
        paddingTop: theme.spacing(2),
      },
      '&.steps': {
        paddingTop: theme.spacing(2),
      },
    },
    taskInput: {
      display: 'flex',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      '& > div:first-child': {
        flex: 0,
        flexBasis: theme.spacing(2),
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: '50%',
        width: theme.spacing(2),
        height: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.928rem',
        fontWeight: 'normal',
        color: otherColorSets[2],
        marginTop: theme.spacing(0.25),
        marginRight: theme.spacing(1),
      },
      '& > div:last-child': {
        display: 'flex',
        flex: 1,
        paddingLeft: theme.spacing(1),
        color: otherColorSets[2],
        borderLeft: `1px solid ${theme.palette.grey[100]}`,
        fontSize: '1.0714rem',
        fontWeight: 'normal',
        paddingBottom: theme.spacing(3),
      },
    },
    userQuestion: {
      fontSize: '1.0714rem !important',
      fontWeight: '400 !important',
      color: `${theme.palette.grey[900]} !important`,
    },
    gptChatItemBotAnswer: {
      position: 'relative',
      display: 'flex',
      minHeight: theme.spacing(5),
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'center',
    },
    gptChatItemUserQuestion: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: 12,
      backgroundColor: otherAltLightColorSets[2],
      padding: '10px 12px',
    },
    gptChatItemBotToolsBox: {
      flex: 0,
      flexBasis: 140,
      display: 'flex',
      justifyContent: 'flex-end',
      '& svg': {
        color: theme.palette.grey[50],
      },
      '& button:hover svg': {
        color: 'inherit',
      },
    },
    gptChatItemUserToolsBox: {
      flex: 0,
      flexBasis: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      '& svg': {
        color: theme.palette.grey[50],
      },
      '& button:hover svg': {
        color: 'inherit',
      },
    },
    objective: {
      fontSize: '1.214rem !important',
      fontWeight: '500 !important',
      color: `${theme.palette.grey[900]} !important`,
      marginTop: '3px !important',
      marginBottom: `${theme.spacing(3)} !important`,
      flex: 0,
    },
    objectiveInput: {
      fontSize: '1.214rem !important',
      fontWeight: '500 !important',
      color: `${otherColorSets[2]} !important`,
      marginRight: theme.spacing(1),

      '&::before': {
        borderBottom: 'none !important',
      },
      '&::after': {
        borderBottom: 'none !important',
      },
    },
    objectiveInputElement: {
      maxWidth: '100%',
    },
    expander: {
      display: 'flex',
      marginLeft: theme.spacing(2),
      width: 'calc(100% - 72px)',
    },
    taskStep: {
      color: `${theme.palette.grey[100]} !important`,
      marginTop: '4px !important',
      fontWeight: 500,
      flex: 0,
    },
    taskStepTail: {
      flex: 1,
      borderRight: `1.5px solid ${otherColorSets[2]}`,
      width: 20,
      minHeight: 20,
    },
    taskGroup: {
      marginBottom: theme.spacing(5),
      '&.readOnly': {
        marginBottom: theme.spacing(0),
      },
    },
    taskTool: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 4,
      flex: 0,
      borderRadius: 10,
      border: `1px solid ${theme.palette.grey[50]}`,
      padding: '2px 8px !important',
      whiteSpace: 'nowrap',
      fontSize: '1rem',
      fontWeight: 'normal',
      color: theme.palette.grey[900],
    },
    taskInputArea: {
      marginTop: theme.spacing(-0.5),
      fontWeight: '400 !important',
      width: '100%',
      fontSize: '1.0714rem !important',
      color: `${theme.palette.grey[900]} !important`,
      display: 'block',
      padding: '4px 0 2px 0',
      '&:focus': {
        outline: 'none',
      },
      '& *': {
        color: `${theme.palette.grey[900]} !important`,
        fontStyle: 'normal !important',
      },
      '& > .bracket': {
        color: `${otherContrastColorSets[2]} !important`,
        fontStyle: 'italic !important',
        fontWeight: '300 !important',
        position: 'relative',
        '&.url': {
          display: 'block',
          fontStyle: 'normal !important',
          maxWidth: 500,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          verticalAlign: 'middle',
          paddingBottom: 2,
        },
      },
      '&.editMode': {
        borderBottom: `1px dashed ${theme.palette.grey[100]} !important`,
      },
    },
    taskTail: {
      flex: 1,
      borderRight: `1px dashed ${theme.palette.grey[50]}`,
      width: 20,
      minHeight: 20,
    },
    addItemLink: {
      color: `${theme.palette.grey[100]} !important`,
      textDecoration: 'none !important',
      textDecorationColor: `${theme.palette.grey[100]} !important`,
      '&:hover': {
        textDecoration: 'underline !important',
      },
      cursor: 'pointer',
    },
    taskItem: {
      marginLeft: theme.spacing(-11),
      paddingLeft: theme.spacing(11),
      position: 'relative',
      '& > $taskItemButtonBox': {
        position: 'absolute',
        marginTop: theme.spacing(0.5),
        marginLeft: theme.spacing(-3.25),
        zIndex: 500,
      },
      '&:hover': {
        '& > $taskItemButtonBox': {
          opacity: 1,
        },
      },
    },
    taskItemButtonBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      borderRadius: '50%',
      border: `1px solid ${theme.palette.grey[300]}`,
      boxSizing: 'border-box',
      width: 20,
      height: 20,
      cursor: 'pointer',
      opacity: 0,
      transition: 'all 0.3s ease-out',
      '&.progressIndicator': {
        opacity: 1,
        color: otherColorSets[2],
        cursor: 'default',
        zIndex: 0,
      },
      '&.finishedIndicator': {
        opacity: 1,
        color: otherColorSets[2],
        cursor: 'default',
        border: `1.5px solid ${otherContrastColorSets[2]}`,
        zIndex: 0,
      },
    },
    taskItemButton: {
      display: 'flex',
      '& .MuiSvgIcon-root': {
        fontSize: '12px !important',
        lineHeight: 1,
        color: otherColorSets[2],
      },
    },
    executeOnlyDivider: {
      marginTop: `${theme.spacing(2)} !important`,
    },
    executeButton: {
      width: 136,
      color: `${theme.palette.grey[900]} !important`,
      border: `1px solid ${otherContrastColorSets[1]} !important`,
      backgroundColor: `${sourceRefLightColor} !important`,
      '&:hover, &:focus': {
        color: `${theme.palette.common.white} !important`,
        backgroundColor: `${otherColorSets[2]} !important`,
      },
    },
  });
};

export default styles;
