import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 300,
      boxSizing: 'border-box',
    },
    chartContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '100%',
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
    barContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    bar: {
      height: 25,
      width: 0,
    },
    label: {
      marginLeft: `${theme.spacing(1)} !important`,
      color: `${theme.palette.grey[900]} !important`,
      fontSize: '1.2rem !important',
    },
  });
};

export default styles;
