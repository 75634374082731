/* eslint-disable no-param-reassign */
import { Node, Edge, distance } from '@sayari/trellis';
import { Layout as collideLayout } from '@sayari/trellis/layout/collide';

import { ChartLegendInfo, ClusterLayoutType, GraphData } from '../types';
import { findNodeIdWithMostConnections } from './layoutUtils';
import radialLayout from './radialLayout';
import hierarchyLayout from './hierarchyLayout';

const NODE_PADDING = 5;
const DEFAULT_RADIUS = 18;

const getCollidedNodes = (
  subgraphNodes: Node[],
  subgraphEdges: Edge[],
  nodePadding: number,
  clusterLayoutType?: ClusterLayoutType,
) => {
  const rawNodeId = findNodeIdWithMostConnections(subgraphEdges);

  if (clusterLayoutType === 'radial') {
    return radialLayout(rawNodeId, {
      nodes: subgraphNodes,
      edges: subgraphEdges,
      options: {
        bfs: true,
      },
    }).nodes;
  }

  if (clusterLayoutType === 'hierarchy') {
    return hierarchyLayout()(rawNodeId, {
      nodes: subgraphNodes,
      edges: subgraphEdges,
      options: {
        size: [180, 180],
      },
    }).nodes;
  }

  return collideLayout()({
    nodes: subgraphNodes,
    edges: [],
    options: {
      nodePadding,
      tick: 5,
    },
  }).nodes;
};

const clustersLayout = <N extends Node, E extends Edge>(
  graph: GraphData<N, E>,
  legendData?: ChartLegendInfo[],
  clusterLayoutType?: ClusterLayoutType,
) => {
  const { nodes, edges, options } = graph;
  const [clusterPadding, collidePadding] = (
    options?.size || [NODE_PADDING, NODE_PADDING]
  );

  const groupedNodes = (legendData || []).map((legendItem, index) => {
    const subgraphNodes = nodes.filter((node) => (
      legendItem.subcategories?.includes((node as unknown as Record<string, string>).entityType)
    )).map((subNode) => ({
      ...subNode,
      style: {
        ...subNode.style,
        icon: {
          ...subNode.style?.icon,
          scale: 0.17,
        },
      },
    }));

    const subgraphEdges = edges.filter(
      (edge) => (
        subgraphNodes.some((node: { id: string; }) => node.id === edge.source)
        && subgraphNodes.some((node: { id: string; }) => node.id === edge.target)
      ),
    );

    const collidedNodes = subgraphEdges.length === 0
      ? (
        collideLayout()({
          nodes: subgraphNodes as Node[],
          edges: [],
          options: {
            nodePadding: collidePadding,
            tick: 5,
          },
        }).nodes
      )
      : getCollidedNodes(
        subgraphNodes as Node[],
        subgraphEdges,
        collidePadding,
        clusterLayoutType,
      );

    const r = (collidedNodes || []).map(
      ({ x = 0, y = 0, radius = 0 }) => distance(x, y, 0, 0) + radius,
    ).reduce(
      (maxDistance: number, d: number) => Math.max(maxDistance, d),
      DEFAULT_RADIUS,
    ) + 20;

    return {
      id: index.toString(),
      radius: r,
      count: collidedNodes.length,
      label: legendItem.labelPlural.toUpperCase(),
      style: {
        ...legendItem.style,
        color: legendItem.style.color,
      },
      subgraph: {
        nodes: collidedNodes,
        edges: subgraphEdges,
      },
    };
  });

  const finalGroupedNodes = collideLayout()({
    nodes: groupedNodes,
    edges: [],
    options: {
      nodePadding: clusterPadding,
      tick: 5,
    },
  }).nodes;

  const allNodes = [] as Node[];
  const allEdges = [] as Edge[];

  finalGroupedNodes.forEach((group) => {
    group.subgraph.nodes.forEach((node) => {
      if (node.x !== undefined) {
        node.x += (group as unknown as { x: number }).x;
      }

      if (node.y !== undefined) {
        node.y += (group as unknown as { y: number }).y;
      }

      node.style = group.style;
      node.radius = DEFAULT_RADIUS;
    });

    allNodes.push(...group.subgraph.nodes);
    allEdges.push(...group.subgraph.edges);
  });

  return {
    nodes: [...allNodes],
    edges: [...allEdges],
  };
};

export default clustersLayout;
