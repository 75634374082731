import { SplineChartData } from '../../components/splineChart/types';

export function isNullOrUndefined(data?: unknown) {
  return (data === null || data === undefined);
}

export function chunk(input: Array<unknown>, size: number) {
  return Array.from({ length: Math.ceil(input.length / size) }, (_, i) => (
    input.slice(i * size, i * size + size)
  ));
}

export const gaussianKde = (xi: number, x: number) => (
  (1 / Math.sqrt(2 * Math.PI)) * Math.exp(((xi - x) ** 2) / -2)
);

export function createGaussianKdes(rawData: number[]): SplineChartData {
  const rawDataPercents = rawData.map((x) => x * 100);
  const range = 100;
  const startPoint = 0;

  const xiData = Array.from({ length: range }).map((_, i) => (
    startPoint + i
  ));

  const n = rawDataPercents.length;

  const data = xiData.map((_, i) => {
    const tmp = rawDataPercents.reduce((sum, __, j) => (
      sum + gaussianKde(xiData[i], rawDataPercents[j])
    ), 0);

    return [xiData[i], (1 / n) * tmp];
  });

  return data;
}

export function createChunks<T>(items: T[], chunkSize: number) {
  return items.reduce((chunks, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    const updatedChunks = [...chunks];

    if (!chunks[chunkIndex]) {
      updatedChunks[chunkIndex] = [];
    }

    updatedChunks[chunkIndex].push(item);

    return updatedChunks;
  }, [] as T[][]);
}

export function assignToIndex<T>(arr: T[], index: number, value: T) {
  const outputArray = [...arr];

  // Extend the array if the index is beyond the current length
  if (index >= arr.length) {
    outputArray.length = index + 1;
  }

  // Assign the value to the specified index
  outputArray[index] = value;

  return outputArray;
}

export function getAncestorOf<T>(data: T, parentProp: keyof T): T {
  if (!data[parentProp]) {
    return data;
  }

  return getAncestorOf(data[parentProp] as T, parentProp);
}
