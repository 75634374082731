import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles, {
  FOUNDER_AVATAR_SIZE,
} from '../../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      position: 'relative',
      '&.small': {
        height: 30,
        width: 60,
        display: 'flex',
      },
    },
    moreInfoWrapper: {
      width: `calc(${FOUNDER_AVATAR_SIZE}px - ${theme.spacing(1.25)})`,
      height: `calc(${FOUNDER_AVATAR_SIZE}px - ${theme.spacing(1.25)})`,
      [theme.breakpoints.down('xl')]: {
        width: `calc(${FOUNDER_AVATAR_SIZE}px - ${theme.spacing(2.25)})`,
        height: `calc(${FOUNDER_AVATAR_SIZE}px - ${theme.spacing(2.25)})`,
      },
      borderRadius: '50%',
      position: 'absolute',
      top: 5,
      left: 5,
      overflow: 'hidden',
    },
    moreInfo: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: 'all ease-out 0.2s',
      background: 'rgba(0, 0, 0, 0.75)',
      opacity: 0,
      zIndex: 1,
      '&.open': {
        opacity: 1,
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contactButton: {
      marginLeft: `${theme.spacing(-0.25)} !important`,
      marginRight: `${theme.spacing(-0.25)} !important`,
      color: `${theme.palette.grey[50]} !important`,
      '&.Mui-disabled': {
        color: `${theme.palette.grey[300]} !important`,
      },
      '&:hover': {
        color: `${theme.palette.common.white} !important`,
      },
    },
    smallContactButton: {
      height: 16,
      transform: 'scale(0.7)',
      transformOrigin: '0 0',
      color: `${theme.palette.grey[100]} !important`,
      '&.Mui-disabled': {
        color: `${theme.palette.grey[300]} !important`,
      },
      '&:hover': {
        color: `${theme.palette.grey[600]} !important`,
      },
    },
    smallAvatar: {
      marginTop: -2,
    },
  });
};

export default styles;
