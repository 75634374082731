import { createAction } from '@reduxjs/toolkit';
import {
  SessionActions,
  RequestLoginArgs,
  SetLoginArgs,
  SetLoggedOutArgs,
  RequestUserExtraArgs,
  SetUserExtraArgs,
} from './types';

export const initializeSession = (
  createAction(SessionActions.SESSION_INITIALIZE)
);
export const requestCsrf = (
  createAction(SessionActions.CSRF_REQUEST)
);
export const setCsrf = (
  createAction(SessionActions.CSRF_SET)
);
export const requestLogin = (
  createAction<RequestLoginArgs>(SessionActions.LOGIN_REQUEST)
);
export const setLogin = (
  createAction<SetLoginArgs>(SessionActions.LOGIN_SET)
);
export const requestLogout = (
  createAction(SessionActions.LOGOUT_REQUEST)
);
export const setLoggedOut = (
  createAction<SetLoggedOutArgs>(SessionActions.LOGOUT_SET)
);
export const requestUserExtra = (
  createAction<RequestUserExtraArgs>(SessionActions.USER_EXTRA_REQUEST)
);
export const setUserExtra = (
  createAction<SetUserExtraArgs>(SessionActions.USER_EXTRA_SET)
);
