import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';
import getCommonStyles from '../../../theme/commonStyles';
import dimensions from '../../../theme/dimensions';
import { otherColorSets } from '../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflow: 'hidden',
      '&.minimized': {
        flexBasis: theme.spacing(12),
        flex: 0,
      },
    },
    searchPanel: {
      maxWidth: 'inherit !important',
      paddingTop: theme.spacing(10),
      transition: 'all ease-out 0.5s',
      position: 'relative',
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(1),
      // background: `
      //   linear-gradient(180deg,
      //     ${theme.palette.background.paper} 0%,
      //     ${theme.palette.background.paper} 85%,
      //     ${Color(theme.palette.background.paper).alpha(0).toString()} 100%);
      // `,
      zIndex: 100,
      '&.collapsed': {
        paddingTop: theme.spacing(2),
      },
      '&.semiCollapsed': {
        paddingTop: theme.spacing(5),
      },
    },
    searchBox: {
      position: 'relative',
      transition: 'all ease-out 0.5s',
      width: 850,
      marginLeft: 'auto',
      marginRight: 'auto',
      '&.minimized': {
        overflow: 'hidden',
        height: theme.spacing(8),
        width: '100%',
      },
    },
    searchBoxAutocomplete: {
      transition: 'all ease-out 0.3s',
      '&.minimized': {
        width: `${dimensions.gptSearchBox.width}px !important`,
      },
    },
    searchBoxTextField: {
      background: theme.palette.common.white,
      padding: '0 !important',
      paddingRight: `${theme.spacing(1.75)} !important`,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      '&.gptSearching:before': {
        content: '""',
        position: 'absolute',
        left: -2,
        top: -2,
        background: 'linear-gradient(45deg, #0081c0, #3b7bbe, #6273b7, #736eb2, #796cb1, #ed789f, #0081c0, #3b7bbe, #6273b7, #736eb2, #796cb1, #ed789f)',
        borderRadius: '28px 28px 28px 0',
        backgroundSize: '400%',
        width: 'calc(100% + 4px)',
        height: 'calc(100% + 4px)',
        zIndex: 0,
        animation: '$steamAnimation 15s linear infinite',
        filter: 'blur(5px)',
      },
      '&.gptSearching:after': {
        content: '""',
        position: 'absolute',
        left: 2,
        top: 2,
        background: '#fff',
        borderRadius: '28px 28px 28px 0',
        backgroundSize: '400%',
        width: 'calc(100% - 4px)',
        height: 'calc(100% - 4px)',
        zIndex: 0,
        animation: '$steamAnimation 15s linear infinite',
      },
      '&.companyAdding:before': {
        content: '""',
        position: 'absolute',
        left: -2,
        top: -2,
        background: `linear-gradient(45deg, ${otherColorSets[1]}, #fff)`,
        borderRadius: '28px 28px 28px 0',
        backgroundSize: '400%',
        width: 'calc(100% + 4px)',
        height: 'calc(100% + 4px)',
        zIndex: 0,
        animation: '$steamAnimation 15s linear infinite',
        filter: 'blur(5px)',
      },
      '&.companyAdding:after': {
        content: '""',
        position: 'absolute',
        left: 2,
        top: 2,
        background: '#fff',
        borderRadius: '28px 28px 28px 0',
        backgroundSize: '400%',
        width: 'calc(100% - 4px)',
        height: 'calc(100% - 4px)',
        zIndex: 0,
        animation: '$steamAnimation 15s linear infinite',
      },
    },
    '@keyframes steamAnimation': {
      '0%': {
        backgroundPosition: '0 0',
      },
      '50%': {
        backgroundPosition: '400% 0',
      },
      '100%': {
        backgroundPosition: '0 0',
      },
    },
    searchBoxInput: {
      paddingTop: `${theme.spacing(1.25)} !important`,
      paddingBottom: `${theme.spacing(1.25)} !important`,
      marginLeft: `${theme.spacing(2)} !important`,
      marginRight: `${theme.spacing(2)} !important`,
      height: '2.5rem !important',
      color: `${theme.palette.grey[900]} !important`,
      '&.minimized': {
        paddingTop: `${theme.spacing(0.5)} !important`,
        paddingBottom: `${theme.spacing(0.5)} !important`,
        marginRight: '0 !important',
        paddingRight: '0 !important',
        paddingLeft: '0 !important',
        fontSize: '1.1rem',
        '&.MuiOutlinedInput-input': {
          paddingLeft: '0 !important',
        },
      },
    },
    searchIcon: {
      marginLeft: theme.spacing(3),
      color: theme.palette.grey[500],
      width: theme.spacing(3),
    },
    filterButton: {
      paddingLeft: `${theme.spacing(1.5)} !important`,
      paddingRight: `${theme.spacing(1.5)} !important`,
      zIndex: 1,
    },
    clearAllButton: {
      paddingLeft: `${theme.spacing(0)} !important`,
    },
    linkButtons: {
      display: 'inline-block',
      color: `${theme.palette.grey[300]} !important`,
      textDecorationColor: `${theme.palette.grey[300]} !important`,
      fontSize: '1.1rem',
      fontWeight: 300,
      fontFamily: 'Roboto',
    },
    filterBox: {
      flex: 1,
      '& p': {
        fontWeight: '300 !important',
      },
      overflow: 'hidden',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      zIndex: 0,
    },
    resultPanel: {
      marginTop: theme.spacing(2),
    },
    searchResults: {
      position: 'relative',
      minHeight: (135 + 16) * 4 - 16,
    },
    noResultBox: {
      marginTop: theme.spacing(6),
    },
    noResultContent: {
      fontSize: '1.4285rem !important',
      fontWeight: 'normal !important',
      color: `${theme.palette.grey[300]} !important`,
    },
    paginationContainer: {
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
      height: 80,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      background: `
        linear-gradient(0deg,
          ${theme.palette.background.paper} 0%,
          ${theme.palette.background.paper} 85%,
          ${Color(theme.palette.background.paper).alpha(0).toString()} 100%);
      `,
      zIndex: 100,
    },
    creditDialogHeader: {
      fontSize: '1.285rem !important',
      fontWeight: '500 !important',
      color: `${theme.palette.grey[100]} !important`,
      textAlign: 'center',
    },
    creditDialogContent: {
      fontSize: '1.285rem !important',
      fontWeight: '500 !important',
      color: `${theme.palette.grey[900]} !important`,
      textAlign: 'center',
      '& .credit': {
        color: theme.palette.secondary.main,
      },
    },
    poweredBy: {
      fontSize: '0.428rem !important',
      color: `${theme.palette.grey[900]} !important`,
      fontWeight: '300 !important',
      marginTop: `${theme.spacing(0.25)} !important`,
      marginLeft: `${theme.spacing(-1.75)} !important`,
    },
    gptSwitch: {
      marginLeft: `${theme.spacing(-0.5)} !important`,
      marginTop: theme.spacing(-1),
    },
    gptSwitchLabel: {
      fontSize: '1.285rem !important',
      fontWeight: 'normal !important',
      color: `${theme.palette.grey[500]} !important`,
    },
    gptSearchDescBox: {
      marginLeft: theme.spacing(1.5),
      marginTop: theme.spacing(-1.5),
      marginRight: theme.spacing(1),
    },
    gptSearchDesc: {
      fontSize: '0.785rem !important',
      fontWeight: '300 !important',
      color: `${theme.palette.grey[900]} !important`,
    },
  });
};

export default styles;
