const config = {
  backendUrl: process.env.REACT_APP_API_URL || 'http://localhost:9001',
  jinaKey: process.env.REACT_APP_JINA_KEY || 'jina_2e49df5126ab4ac3b3e8e7e43430e00fVHE_NAW-_RykC72lL8kRToJZEiF4',
  api: {
    setCsrf: 'api/set-csrf',
    login: 'api/login/',
    logout: 'api/logout/',
    getCompanyAutocomplete: 'api/orgac/',
    getCompanySearch: 'api/search/',
    getMarketInfo: 'api/market_info/',
    getDictionary: 'api/dictionary/',
    getMarketGpt: 'api/market_gpt/',
    getCompanyReportData: 'api/companydata',
    getFormInfoData: 'api/getforminfo',
    getScoreModelReportData: 'api/scoremodel',
    getInvestorReportData: 'api/investordata',
    getDistributionsData: 'api/distdata',
    getMarketReportData: 'api/market-report/',
    getMarketCountriesData: 'api/market-report-countries/',
    getCompetitorsReportData: 'api/competitiondata',
    getUserExtra: 'api/userconf/',
    getUserHistory: 'api/user_history/',
    subscribe: 'api/subscribe/',
    contactus: 'api/contactus/',
    requestPvyParams: 'api/pvyparams/',
    saveCompanyData: 'api/company/',
    saveFounderData: 'api/founder/',
    uploadImage: 'api/image/',
    requestLinkedInProfile: 'api/liprofile/',
    addOrgExtSrc: 'api/add_org_ext_src/',
    postGptChatPrompt: 'api/chatbot/',
    getGptChatHistory: 'api/chathistory_v2/',
    getGptChatPrompt: 'api/prompt/',
    getSiteExamples: 'api/siteexample/',
    cleanGptChat: 'api/cleanchatbot/',
    postGptEventSource: 'streamlogs/',
    postTaskerSum: 'api/tasker-sum/',
    taskTemplates: 'media/tm_templates.json',
    postChartUpdate: 'api/update-graph/',
    requestNetworkGraph: 'api/network-graph/',
    requestCompanyNetworkGraph: 'api/org-network-graph/',
    getChatStreamLog: 'ws/chat_streamlog',
    getPlanExecuteOnly: 'ws/execute_only',
    postTableToGraph: 'api/table-to-graph/',
    getAiModel: 'api/aimodel/',
    postFollowUpQuestions: 'api/follow-up-questions/',
    getLocalSourcePdf: 'ws/source_pdf',
  },
  externalUrl: {
    jina: 'https://r.jina.ai/',
  },
};

export default config;
