import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';
import classNames from 'classnames';
import CountUp from 'react-countup';

import { easeOut } from '../../core/utils/animationUtils';
import { useCommonClasses } from '../../theme/commonStyles';
import styles from './LinearGauge.styles';

type Props = {
  title: React.ReactNode;
  value: number;
  xAxisStartLabel?: string;
  xAxisEndLabel?: string;
  animationDuration?: number;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
};

const useStyles = makeStyles(styles);
const PERCENT_GAUGE_WIDTH = 100;

const LinearGauge: React.FC<Props> = ({
  title,
  value,
  xAxisStartLabel,
  xAxisEndLabel,
  animationDuration = 1000,
  onMouseEnter,
  onMouseLeave,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const [valueLineWidth, setValueLineWidth] = useState(0);
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const { width, ref } = useResizeDetector();
  const containerWidth = width || 0;

  useEffect(() => {
    if (containerWidth > 0) {
      setTimeout(() => {
        const normalizedWidth = value * ((width || 0) - PERCENT_GAUGE_WIDTH);
        setValueLineWidth(normalizedWidth);
      }, 500);
    }
  }, [containerWidth]);

  const handleMouseEnter:React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (onMouseEnter) {
      onMouseEnter(event);
    }

    setIsMouseEnter(true);
  };

  const handleMouseLeave:React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (onMouseLeave) {
      onMouseLeave(event);
    }

    setIsMouseEnter(false);
  };

  const countFormatter = useCallback(
    (num: number) => `${num.toFixed(1)}%`
      .split('.')
      .map((n) => `<span>${n}</span>`)
      .join('.'),
    [],
  );

  const percentValue = value * 100;

  return (
    <div className={classes.root}>
      {typeof title === 'string' ? (
        <Typography variant="h2">
          {title}
        </Typography>
      ) : title}
      <div
        className={classes.chartContainer}
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {containerWidth ? (
          <>
            <div
              className={classes.valueLine}
              style={{
                transition: `width ease-out ${animationDuration}ms`,
                width: valueLineWidth,
              }}
            />
            <div
              className={
                classNames(
                  commonCss.charts.linearGaugeBox,
                  'secondary',
                  isMouseEnter && 'highlight',
                )
              }
              style={{ width: PERCENT_GAUGE_WIDTH }}
            >
              <CountUp
                end={percentValue}
                duration={animationDuration / 1000}
                decimals={1}
                useEasing
                delay={0.5}
                easingFn={easeOut}
                formattingFn={countFormatter}
              />
            </div>
            <div
              className={classes.restLine}
            />
          </>
        ) : (
          <div />
        )}
      </div>
      <div
        className={
          classNames(
            commonCss.charts.chartXAxis,
            'noLine',
          )
        }
      >
        <Typography variant="body2" fontSize="inherit" color="inherit">
          {xAxisStartLabel}
        </Typography>
        <Typography variant="body2" fontSize="inherit" color="inherit">
          {xAxisEndLabel}
        </Typography>
      </div>
    </div>
  );
};

export default LinearGauge;
