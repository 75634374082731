import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import { otherContrastColorSets } from '../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => createStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },
  messageBoxBackdrop: {
    transition: 'all ease-out 0.3s',
  },
  messageBoxBackdropBlur: {
    backdropFilter: 'blur(6px)',
  },
  messageBoxButton: {
    width: 150,
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  contactFormTitle: {
    fontSize: '1.285rem !important',
    fontWeight: '500 !important',
    letterSpacing: 0.18,
    lineHeight: 1.39,
    color: `${theme.palette.grey[900]} !important`,
  },
  glossaryDot: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[100],
    marginLeft: 2,
    marginRight: 2,
    transition: 'all 0.3s ease-out',
    '&.active': {
      backgroundColor: otherContrastColorSets[3],
      width: 6,
      height: 6,
    },
  },
  glossaryBubbleText: {
    color: `${theme.palette.grey[500]} !important`,
    fontWeight: '300 !important',
  },
  glossaryBubbleHighlight: {
    color: `${otherContrastColorSets[3]} !important`,
    textDecorationColor: `${otherContrastColorSets[3]} !important`,
    cursor: 'pointer',
  },
});

export default styles;
