import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Typography,
  Box,
  Link,
} from '@mui/material';
import classNames from 'classnames';

import { ReactComponent as DefaultCompanyIcon } from '../../../../assets/svg/defaultCompanyIcon.svg';
import { ReactComponent as CaretRightIcon } from '../../../../assets/svg/caretRightIcon.svg';
import { useCommonClasses } from '../../../../theme/commonStyles';
import { CompanyItem } from '../types';
import CompanyList from '../companyList';
import { PropsFromRedux } from './container';
import styles from './VisitHistory.styles';

interface Props extends PropsFromRedux {
  onDataClick?: (companyItem: CompanyItem) => void;
  onSubReportClick?: (companyItem: CompanyItem, report: string) => void;
}

const useStyles = makeStyles(styles);

const VisitHistory: React.FC<Props> = ({
  requestHistoryItems,
  setSearchKeyword,
  historyItems,
  keyword,
  onDataClick,
  onSubReportClick,
  networkStates: {
    historyItemsRequest,
  },
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();

  const isFullHistory = keyword === ':history';

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!historyItemsRequest.isRequesting) {
        requestHistoryItems();
      }
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [requestHistoryItems, historyItemsRequest.isRequesting]);

  return (
    <div className={classes.root}>
      {!isFullHistory && (historyItems || []).length > 0 && (
        <Container className={classes.recentContainer}>
          <Typography className={classes.recentTitle} variant="body1">
            Recents
          </Typography>
          {historyItems && (
            <Box className={classes.recentItemsContainer}>
              {historyItems.slice(0, 7).map((historyItem) => (
                <div
                  key={historyItem.id}
                  className={classes.recentItem}
                  onClick={() => onDataClick && onDataClick(historyItem)}
                >
                  {historyItem.logo?.at(0) ? (
                    <Box
                      className={
                        classNames(
                          commonCss.boxes.imageBox,
                          'logo',
                        )
                      }
                      component="object"
                      data={historyItem.logo?.at(0)}
                      type="image/png"
                      width={60}
                      height={60}
                    >
                      <DefaultCompanyIcon />
                    </Box>
                  ) : (
                    <Box
                      className={
                        classNames(
                          commonCss.boxes.imageBox,
                          'logo',
                        )
                      }
                      width={60}
                      height={60}
                    >
                      <DefaultCompanyIcon />
                    </Box>
                  )}
                  <Box marginTop={1} maxWidth={80}>
                    <Typography variant="body2" textAlign="center" className="name">
                      {historyItem.name}
                    </Typography>
                  </Box>
                </div>
              ))}
            </Box>
          )}
          {(historyItems || []).length > 0 && (
            <Box marginTop={1} display="flex" justifyContent="flex-end" width="87%">
              <Link
                component="button"
                className={classes.linkButtons}
                onClick={() => setSearchKeyword({
                  keyword: ':history',
                })}
              >
                Full History
                <Box component="span" marginLeft={0.5} paddingTop={0.25}>
                  <CaretRightIcon />
                </Box>
              </Link>
            </Box>
          )}
        </Container>
      )}
      {isFullHistory && (
        <CompanyList
          companyItems={historyItems}
          onDataClick={onDataClick}
          onSubReportClick={onSubReportClick}
          showScore
        />
      )}
    </div>
  );
};

export default VisitHistory;
