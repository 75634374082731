import { ReactNode, Ref } from 'react';
import {
  AutocompleteRenderInputParams,
  SwitchProps,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  useTheme,
} from '@mui/material';
import { DatePickerSlotsComponent } from '@mui/x-date-pickers/DatePicker/DatePicker';
import classNames from 'classnames';
import Color from 'color';

import { ReactComponent as PickerDropDownIcon } from '../assets/svg/arrowDownIcon.svg';
import { useCommonClasses } from './commonStyles';
import { otherColorSets, otherContrastColorSets, otherLightColorSets } from './palette';

export type SupportedColor = 'secondary'
| 'error'
| 'primary'
| 'info'
| 'success'
| 'tertiary'
| 'quaternary'
| 'warning'
| 'market'
| 'glossary'
| 'gpt'
| 'utility';

type FieldCommonParams = {
  label?: ReactNode;
  color?: SupportedColor;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  inputRef?: Ref<HTMLInputElement | null>;
  readOnly?: boolean;
  popperSx?: SxProps<Theme>;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  size?: 'small' | 'medium';
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
};

type TextFieldParams = FieldCommonParams & {
  max?: number;
  min?: number;
  maxLength?: number;
};

const useCommonProps = () => {
  const theme = useTheme();
  const commonCss = useCommonClasses();

  const colorIndexMap = {
    primary: 0,
    secondary: 1,
    tertiary: 2,
    quaternary: 3,
  } as Record<string, number>;

  const getFieldLabelColor = (color: string) => {
    const index = colorIndexMap[color];
    const colorSets = otherContrastColorSets;
    return colorSets[index];
  };

  const getFieldBorderColor = (color: string) => {
    if (color === 'info') {
      return theme.palette.grey[500];
    }

    if (color === 'market') {
      return otherContrastColorSets[0];
    }

    if (color === 'glossary') {
      return otherContrastColorSets[3];
    }

    if (color === 'gpt') {
      return 'transparent';
    }

    if (color === 'utility') {
      return theme.palette.grey[300];
    }

    const index = colorIndexMap[color];
    const colorSets = otherColorSets;
    return colorSets[index];
  };

  const getHoverBackgroundColor = (color: string) => {
    if (color === 'info') {
      return theme.palette.grey[50];
    }

    if (color === 'market') {
      return otherLightColorSets[0];
    }

    if (color === 'glossary') {
      return otherLightColorSets[3];
    }

    if (color === 'gpt') {
      return 'transparent';
    }

    if (color === 'utility') {
      return theme.palette.grey[50];
    }

    const index = colorIndexMap[color];
    const colorSets = otherLightColorSets;
    return colorSets[index];
  };

  const getTextFieldSx = (color: string) => {
    const borderAppearance = {} as Record<string, unknown>;
    if (color === 'gpt') {
      borderAppearance.border = '1px solid transparent';
      borderAppearance.borderColor = 'transparent !important';
      borderAppearance.borderWidth = '1px !important';
      borderAppearance.backgroundImage = 'linear-gradient(to right, #fff, #fff), linear-gradient(to right, #0081c0 0%, #3b7bbe 7%, #6273b7 19%, #736eb2 31%, #796cb1 40%, #ed789f 86%)';
      borderAppearance.backgroundClip = 'padding-box, border-box';
      borderAppearance.backgroundOrigin = 'padding-box, border-box';
      borderAppearance.zIndex = 0;
    }

    return ({
      '& .optional-text': {
        fontSize: '1rem',
      },
      '& label.Mui-focused': {
        top: 0,
        color: getFieldLabelColor(color),
      },
      '& label.MuiInputLabel-root': {
        color: theme.palette.grey[400],
      },
      '& label.MuiInputLabel-shrink': {
        color: getFieldLabelColor(color),
        '& .optional-text': {
          display: 'none',
        },
        '&+.MuiInputBase-root .optional-text': {
          display: 'none',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${Color(getFieldBorderColor(color)).alpha(0.5).toString()} !important`,
        ...borderAppearance,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: `${Color(getFieldBorderColor(color)).alpha(0.5).toString()} !important`,
        ...borderAppearance,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `${Color(getFieldBorderColor(color)).alpha(0.5).toString()} !important`,
        ...borderAppearance,
      },
      '& .MuiTouchRipple-root': {
        color: getFieldBorderColor(color),
      },
      '& .MuiInputLabel-root': {
        marginLeft: '5px',
      },
      '& svg': {
        zIndex: 1,
      },
      '& .MuiOutlinedInput-input': {
        paddingLeft: '18px !important',
        zIndex: 1,
      },
      '& .MuiInputBase-inputMultiline': {
        paddingLeft: '6px !important',
        zIndex: 1,
      },
      '& legend': {
        marginLeft: '5px !important',
      },
      '& .MuiCircularProgress-root': {
        zIndex: 1,
      },
    });
  };

  const getSwitchTrackBackground = (color: string) => {
    const index = colorIndexMap[color];

    if (color === 'gpt') {
      return `
        linear-gradient(to right, #0081c0 0%, #3b7bbe 7%, #6273b7 19%, #736eb2 31%, #796cb1 40%, #ed789f 86%)
      `;
    }

    if (color === 'market') {
      return `linear-gradient(
        to right,
        #fca2c1 0%,
        #7469fe 33%,
        #3c8cfd 68%
      )`;
    }

    return `linear-gradient(
      to right,
      ${otherColorSets[index]} 0%,
      ${otherContrastColorSets[index]} 104%
    )`;
  };

  const textField = ({
    label,
    color = 'secondary',
    readOnly,
    inputRef,
    required,
    onKeyDown,
    size,
    min,
    max,
    maxLength,
    endAdornment,
    startAdornment,
  }: TextFieldParams) => ({
    label: label ? (
      <>
        <span>{label}</span>
        {!required && !readOnly && (
          <span className="optional-text">{' (optional)'}</span>
        )}
        <span></span>
      </>
    ) : '',
    variant: 'outlined',
    InputProps: {
      className: classNames(!readOnly && 'opaque', color),
      classes: commonCss.fields.leafTextField,
      readOnly,
      endAdornment,
      startAdornment,
      autoComplete: 'off',
    },
    inputProps: {
      min,
      max,
      maxLength,
      dir: 'auto',
    },
    sx: {
      ...getTextFieldSx(color),
    },
    inputRef,
    onKeyDown,
    size,
  } as TextFieldProps);

  const autocomplete = ({
    label,
    color = 'secondary',
    readOnly,
    required,
    error,
    helperText,
    inputRef,
    onKeyDown,
    size,
    startAdornment,
  }: FieldCommonParams) => ({
    renderInput: (params: AutocompleteRenderInputParams) => (
      <TextField
        {...textField({
          label,
          color,
          inputRef,
          readOnly,
          required,
          onKeyDown,
          size,
        })}
        InputProps={{
          ...params.InputProps,
          ...textField({ label, color }).InputProps,
          ...startAdornment && {
            startAdornment,
          },
          endAdornment: params.InputProps.endAdornment,
          autoComplete: 'off',
        }}
        inputProps={{
          ...params.inputProps,
          ...textField({ label, color }).inputProps,
        }}
        label={label}
        fullWidth
        variant="outlined"
        error={error}
        sx={{
          '& label': {
            top: 0,
          },
          ...getTextFieldSx(color),
          '& .MuiAutocomplete-endAdornment > .MuiIconButton-root': {
            color: 'rgba(0, 0, 0, 0.25)',
          },
          '& .MuiAutocomplete-endAdornment > .MuiIconButton-root:hover': {
            backgroundColor: `${Color(getFieldBorderColor(color)).alpha(0.25).toString()} !important`,
          },
          '& .MuiOutlinedInput-input': {
            paddingLeft: '10px !important',
            paddingRight: '24px !important',
          },
        }}
        helperText={helperText}
      />
    ),
    ListboxProps: {
      className: classNames(commonCss.fields.listBox, color),
    },
    popupIcon: <PickerDropDownIcon width={15} height={15} style={{ paddingTop: 4, margin: 4 }} />,
  });

  const multiAutocomplete = ({
    label,
    color = 'secondary',
    readOnly,
    required,
    error,
    helperText,
    inputRef,
    onKeyDown,
  }: FieldCommonParams) => ({
    ...autocomplete({
      label,
      color,
      readOnly,
      required,
      error,
      helperText,
      inputRef,
      onKeyDown,
    }),
    multiple: true,
    classes: {
      tag: classNames(
        commonCss.chips.tagItem.root,
        commonCss.chips.inputTagItem.root,
        'leaf',
        'outline',
        color,
        'bolder',
      ),
    },
    sx: {
      '& .MuiInputBase-root': {
        height: '86px',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        overflow: 'hidden',
      },
      '& .MuiAutocomplete-endAdornment': {
        top: theme.spacing(1.5),
      },
      '& .MuiAutocomplete-endAdornment > .MuiIconButton-root:hover': {
        backgroundColor: `${Color(getFieldBorderColor(color)).alpha(0.25).toString()} !important`,
      },
      '& .MuiInputBase-input': {
        marginTop: '-3px',
        flex: 1,
      },
      ...getTextFieldSx(color),
      '& .MuiAutocomplete-input': {
        paddingLeft: '0px !important',
        '&:first-child': {
          paddingLeft: '10px !important',
        },
      },
    },
    ListboxProps: {
      className: classNames(commonCss.fields.listBox, color),
    },
  });

  const formControlLabel = ({
    label,
    color = 'secondary',
  }: FieldCommonParams) => ({
    label,
    sx: {
      '& .MuiTypography-root': {
        fontSize: '1rem',
      },
      '& .MuiSvgIcon-root': {
        color: `${Color(getFieldBorderColor(color)).alpha(0.5).toString()} !important`,
      },
      '& .Mui-checked .MuiSvgIcon-root': {
        color: `${Color(getFieldBorderColor(color)).alpha(1).toString()} !important`,
      },
      '& .Mui-disabled .MuiSvgIcon-root': {
        color: `${Color(getFieldBorderColor(color)).alpha(0.25).toString()} !important`,
      },
      '& .MuiRadio-root:hover': {
        backgroundColor: `${Color(getHoverBackgroundColor(color)).toString()} !important`,
      },
    },
  });

  const yearPicker = ({
    label,
    color = 'secondary',
    readOnly,
    required,
    error,
    helperText,
    inputRef,
    popperSx,
  }: FieldCommonParams) => ({
    views: ['year'] as ('year' | 'day' | 'month')[],
    openTo: 'year' as ('year' | 'day' | 'month'),
    renderInput: (params: TextFieldProps) => (
      <TextField
        {...textField({ color, required })}
        {...params}
        label={label}
        InputProps={{
          ...textField({ label }).InputProps,
          ...params.InputProps,
          readOnly,
        }}
        inputProps={{
          ...params.inputProps,
          placeholder: '',
          maxLength: 4,
          ref: inputRef,
        }}
        fullWidth
        sx={{
          ...getTextFieldSx(color),
          '& .MuiInputAdornment-root': {
            marginRight: theme.spacing(0.5),
            '& .MuiIconButton-root': {
              color: 'rgba(0, 0, 0, 0.25)',
              width: 28,
              height: 28,
            },
            '& > .MuiIconButton-root:hover': {
              backgroundColor: `${Color(getFieldBorderColor(color)).alpha(0.25).toString()} !important`,
            },
          },
        }}
        error={error}
        helperText={helperText}
      />
    ),
    PopperProps: {
      className: classNames(
        commonCss.fields.yearPickerPopper,
        color,
      ),
      sx: popperSx,
    },
    components: {
      OpenPickerIcon: (props) => <PickerDropDownIcon {...props} />,
    } as Partial<DatePickerSlotsComponent>,
  });

  const switchControl = ({
    color,
  }: FieldCommonParams) => ({
    sx: {
      '.MuiSwitch-switchBase': {
        marginTop: '7px',
        width: 24.9,
        height: 24.9,
        border: `0.5px solid ${theme.palette.grey[500]}`,
        backgroundColor: theme.palette.common.white,
        marginLeft: theme.spacing(0.5),
        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
        '&.Mui-checked': {
          marginLeft: theme.spacing(1),
          '&+.MuiSwitch-track': {
            opacity: 1,
            backgroundImage: getSwitchTrackBackground(color as string),
          },
        },
      },
      '.MuiSwitch-track': {
        width: 40.4,
        height: 15.6,
        borderRadius: theme.spacing(0.5),
        border: `0.5px solid ${theme.palette.grey[500]}`,
        backgroundColor: theme.palette.common.white,
      },
      '.MuiSwitch-thumb': {
        display: 'none',
      },
      '.MuiTouchRipple-root': {
        backgroundColor: theme.palette.common.white,
      },
    },
  } as SwitchProps);

  return {
    textField,
    autocomplete,
    multiAutocomplete,
    formControlLabel,
    yearPicker,
    switchControl,
  };
};

export default useCommonProps;
