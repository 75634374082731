export enum NotifierActions {
  NOTIFICATION_TRIGGER = '@@notifier/NOTIFICATION_TRIGGER',
  NOTIFICATION_REMOVE = '@@notifier/NOTIFICATION_REMOVE',
  NOTIFICATION_DISPLAYED_SET = '@@notifier/NOTIFICATION_DISPLAYED_SET',
}

export type NotificationType = 'investmentUpdate'
| 'general'
| 'val'
| 'error';

export interface NotificationInfo<T extends unknown> {
  key: string;
  type: NotificationType;
  payload?: T;
  isDisplayed: boolean;
  delay?: number;
}

export interface TriggerNotificationArgs {
  notification: NotificationInfo<unknown>;
}

export interface RemoveNotificationArgs {
  key: string;
}

export interface SetNotificationDisplayedArgs {
  key: string;
}

export interface NotifierState {
  notifications: NotificationInfo<unknown>[];
}
