import React, { Component } from 'react';
import {
  TableBodyProps,
  TableCellProps,
  TableHeadProps,
  TableRowProps,
} from '@mui/material';
import { Edge, Node } from '@sayari/trellis';
import { ExtraProps } from 'react-markdown';

export function elementContainsSelection(element: Element) {
  const selection = window.getSelection();
  const selectedText = selection?.toString();

  if (!selectedText) {
    return false;
  }

  const rangeCount = selection?.rangeCount || 0;
  if (rangeCount > 0 && selection) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rangeCount; ++i) {
      if (!element.contains(selection.getRangeAt(i).commonAncestorContainer)) {
        return false;
      }
    }
    return true;
  }
  return false;
}

export function isMouseOnElement(element: Element) {
  const elements = document.querySelectorAll(':hover');
  return Array.from(elements).includes(element);
}

export function getCustomScrollBarCss(
  mode: 'vertical' | 'horizontal' = 'vertical',
  options?: {
    center?: boolean,
    hideByDefault?: boolean,
  },
) {
  const borderStart = options?.center ? 4 : 0;
  const borderEnd = options?.center ? 4 : 0;
  const hideByDefault = options?.hideByDefault ?? false;

  return {
    '&::-webkit-scrollbar': {
      width: 8,
      height: 8,
      borderTop: mode === 'horizontal' ? '0.5px solid #aaa' : undefined,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: hideByDefault ? 'rgba(170, 170, 170, 0)' : '#aaa',
      borderLeft: mode === 'vertical' ? `${borderStart}px solid transparent` : undefined,
      borderTop: mode === 'horizontal' ? `${borderStart}px solid transparent` : undefined,
      borderRight: mode === 'vertical' ? `${borderEnd}px solid transparent` : undefined,
      borderBottom: mode === 'horizontal' ? `${borderEnd}px solid transparent` : undefined,
      backgroundClip: 'padding-box',
      borderRadius: 4,
      '&:hover': {
        backgroundColor: '#999',
      },
      transition: 'all 0.3s ease-out',
    },
  };
}

export function getTableHeaderName(node: Component<TableHeadProps<'thead', {}>, {}, any>) {
  if (Array.isArray(node) && typeof node.at(0) === 'string') {
    return node.at(0) as string;
  }

  return String(node.props.children);
}

export function getTableHeaders(
  props: React.ClassAttributes<HTMLTableElement>
  & React.TableHTMLAttributes<HTMLTableElement> & ExtraProps,
) {
  const children = (props?.children || []);
  if (!Array.isArray(children)) {
    return [];
  }

  const thead = (children as React.Component<TableHeadProps>[]).at(0);
  const firstHeadTr = Array.isArray(thead?.props.children)
    ? (thead?.props.children as React.Component<TableRowProps>[]).at(0)
    : thead?.props.children as unknown as React.Component<TableRowProps>;

  const headers = Array.from(
    Array.isArray(firstHeadTr?.props.children)
      ? firstHeadTr?.props.children as React.Component<TableHeadProps>[]
      : [firstHeadTr?.props.children] as unknown as React.Component<TableHeadProps>[],
  ).map((elem, index) => ({
    id: index,
    name: getTableHeaderName(elem),
    nodes: elem.props.children,
  }));

  return headers || [];
}

export function getTableCellValue(cell: React.ReactNode) {
  return cell;
}

export function getTableRows(
  props: React.ClassAttributes<HTMLTableElement>
  & React.TableHTMLAttributes<HTMLTableElement> & ExtraProps,
) {
  const children = (props?.children || []);
  if (!Array.isArray(children)) {
    return [];
  }

  const tbody = (children as React.Component<TableBodyProps>[]).at(1);

  const subNodes = Array.isArray(tbody?.props?.children || [])
    ? tbody?.props?.children
    : [tbody?.props?.children];

  const bodyTrs = (subNodes as React.Component<TableRowProps>[]) || [];

  const rows = bodyTrs.map((row) => (
    Array.isArray(row.props.children)
      ? row.props.children as React.Component<TableCellProps>[]
      : [row.props.children] as unknown as React.Component<TableCellProps>[]
  ).reduce((obj, cell, index) => ({
    ...obj,
    [index]: getTableCellValue(cell.props.children),
  }), {}));

  return rows;
}

export function determineColumnType<T>(rows: T[], field: string) {
  const isNumber = rows.every((row) => (
    !Number.isNaN(Number((row as Record<string, unknown>)[field]))
  ));

  if (isNumber && field !== '0') {
    // Non: Always treated as string as per Itay.
    return 'string'; // 'number';
  }

  return 'string';
}

export function determineColumnSize<T>(
  header: string,
  rows: T[],
  field: string,
  font?: string,
) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const minCellWidth = 120;
  const maxCellWidth = 5000;

  if (context) {
    context.font = font || getComputedStyle(document.body).font;
    const maxTextWidth = rows.reduce((maxWidth, row) => {
      const value = String((row as Record<string, unknown>)[field]);
      const { width } = context.measureText(value);
      const finalWidth = Math.min(maxCellWidth, width);

      if (maxWidth < finalWidth) {
        return finalWidth;
      }

      return maxWidth;
    }, 0);

    const headerWidth = Math.max(minCellWidth, context.measureText(header).width);
    return Math.max(maxTextWidth, headerWidth);
  }

  return Math.max(minCellWidth, header.length * 10);
}

export const getShortNodeLabel = (node: Node) => {
  const { label } = node;
  const refinedLabel = Array.isArray(label)
    ? label.join(' ')
    : label;

  const words = (refinedLabel || '').split(' ');
  const totalLength = words.join('').length;

  if (totalLength > 30) {
    return `${words.slice(0, 3).join(' ')}...`;
  }

  return label;
};

export const getShortRelationLabel = (relation: Edge) => {
  const { label } = relation;
  const refinedLabel = Array.isArray(label)
    ? label.join(' ')
    : label;

  const words = (refinedLabel || '').split(' ');
  const totalLength = words.join('').length;

  if (totalLength > 30) {
    return `${words.slice(0, 3).join(' ')}...`;
  }

  return label;
};

export const getRelatedCompanyLogo = (logo: string | string[], size: number) => {
  const finalLogo = (typeof logo === 'string')
    ? logo
    : logo.at(0);

  if (!finalLogo) {
    return undefined;
  }

  return finalLogo.replace(/[w|h]+_[0-9]+/g, (s: string) => {
    if (s.startsWith('w')) {
      return `w_${String(size)}`;
    }

    return `h_${String(size)}`;
  });
};
