import NetworkState from '../../../core/types/network';

export enum ManualFormActions {
  MANUAL_FORM_INITIALIZE = '@@manualForm/MANUAL_FORM_INITIALIZE',
  MANUAL_FORM_FIELD_UPDATE = '@@manualForm/MANUAL_FORM_FIELD_UPDATE',
  AVAILABLE_KEYWORDS_SET = '@@manualForm/AVAILABLE_KEYWORDS_SET',
  STEP_SET = '@@manualForm/STEP_SET',
  PVY_PARAMS_REQUEST = '@@manualForm/PVY_PARAMS_REQUEST',
  PVY_PARAMS_SET = '@@manualForm/PVY_PARAMS_SET',
  COMPANY_SAVE_REQUEST = '@@manualForm/COMPANY_SAVE_REQUEST',
  COMPANY_SAVED_SET = '@@manualForm/COMPANY_SAVED_SET',
  FOUNDER_SAVE_REQUEST = '@@manualForm/FOUNDER_SAVE_REQUEST',
  FOUNDER_SAVED_SET = '@@manualForm/FOUNDER_SAVED_SET',
  MANUAL_FORM_SAVE_INITIATE = '@@manualForm/MANUAL_FORM_SAVE_INITIATE',
  MANUAL_FORM_SAVED_SET = '@@manualForm/MANUAL_FORM_SAVED_SET',
  IMAGE_UPLOAD_REQUEST = '@@manualForm/IMAGE_UPLOAD_REQUEST',
  IMAGE_UPLOADED_SET = '@@manualForm/IMAGE_UPLOADED_SET',
  LINKED_IN_PROFILE_REQUEST = '@@manualForm/LINKED_IN_PROFILE_REQUEST',
  LINKED_IN_PROFILE_SET = '@@manualForm/LINKED_IN_PROFILE_SET',
  IS_CANCELLING_SET = '@@manualForm/IS_CANCELLING_SET',
  FOCUSED_DATA_KEY_SET = '@@report/FOCUSED_DATA_KEY_SET',
  HOVERED_DATA_KEY_SET = '@@report/HOVERED_DATA_KEY_SET',
}

export interface CrunchbaseCategory {
  name: string;
}

export interface ManualFormData {
  formId?: number;
  companyInfo?: CompanyInfo;
  founderInfos?: (FounderInfo | undefined)[];
}

export interface CompanyInfo {
  id?: number;
  photoUrl?: string;
  yearFounded?: number;
  compIndustry?: string;
  compVertical?: string;
  compState?: string;
  compCountry?: string;
  compName?: string;
  compFinanceRound?: boolean;
  compDesc?: string
  compKwords?: string;
  website?: string;
  featureNames?: string;
  featureData?: string;
  compLogo?: string;
  form?: number;
}

export interface FounderInfo {
  id?: number;
  photoUrl?: string;
  founderLastName?: string;
  founderFirstName?: string;
  founderEmail?: string;
  linkedinProf?: string;
  title?: string;
  isFemale?: boolean;
  workExp?: number;
  numPriorJobs?: number;
  numCompFounded?: number;
  wasCeo?: boolean;
  wasFaamg?: boolean;
  hasHighEdu?: boolean;
  numOfAcDeg?: number;
  hasScienceDeg?: boolean;
  isTop10Uni?: boolean;
  hasMbaDeg?: boolean;
  hasPhdDeg?: boolean;
  workAlumni?: boolean;
  schoolAlumni?: boolean;
  classAlumni?: boolean;
  index?: number;
  founderPic?: string;
  form?: number;
}

export interface LinkedInProfileInfo {
  isFounder?: boolean;
  lastName?: string;
  firstName?: string;
  title?: string;
  isFemale?: boolean;
  workExp?: number;
  numPriorJobs?: number;
  numCompFounded?: number;
  wasCeo?: boolean;
  wasFaamg?: boolean;
  hasHighEdu?: boolean;
  numOfAcDeg?: number;
  hasScienceDeg?: boolean;
  isTop10Uni?: boolean;
  hasMbaDeg?: boolean;
  hasPhdDeg?: boolean;
}

export interface ManualFormValidationInfo {
  company?: Record<keyof CompanyInfo, string[]>;
  founders?: Record<keyof FounderInfo, string[]>[];
  interactions?: {
    workAlumni: string[],
    schoolAlumni: string[],
  };
}

export interface PvyParamsInfo {
  id: number;
}

export interface UploadedImageInfo {
  id: number;
  img: string;
}

export interface UpdateManualFormFieldArgs {
  manualForm: ManualFormData;
  path: keyof ManualFormData;
  value: unknown;
}

export interface SetAvailableKeywordsArgs {
  keywords: string[];
}

export interface SetStepArgs {
  step: number;
}

export interface RequestPvyParamsArgs {
  numOfFounders: number;
}

export interface SetPvyParamsArgs {
  pvyParams?: PvyParamsInfo;
  error?: Error;
}

export interface RequestImageUploadArgs {
  file: File;
}

export interface SetImageUploadedArgs {
  image?: UploadedImageInfo;
  error?: Error;
}

export interface RequestCompanySaveArgs {
  companyInfo: CompanyInfo;
}

export interface SetCompanySavedArgs {
  companyInfo?: CompanyInfo;
  error?: Error;
}

export interface RequestFounderSaveArgs {
  founderInfo: FounderInfo;
}

export interface SetFounderSavedArgs {
  founderInfo?: FounderInfo;
  error?: Error;
}

export interface SetManualFormSavedArgs {
  manualForm?: ManualFormData;
  error?: Error;
}

export interface RequestLinkedInProfileArgs {
  founderIndex: number;
  linkedInUrl: string;
  yearFound: number;
  orgName: string;
}

export interface SetLinkedInProfileArgs {
  founderIndex: number;
  linkedInProfile?: LinkedInProfileInfo;
  error?: Error;
}

export interface SetIsCancellingArgs {
  isCancelling: boolean;
}

export interface SetFocusedDataInfoKeyArgs {
  dataInfoKey?: string;
}

export interface SetHoveredDataInfoKeyArgs {
  dataInfoKey?: string;
}

export interface ManualFormState {
  networkStates: {
    linkedInProfileRequest: NetworkState;
    saveRequest: NetworkState;
  };
  currentStep: number;
  manualFormData?: ManualFormData;
  availableKeywords?: string[];
  validationInfo?: ManualFormValidationInfo;
  isSuccessfullySaved: boolean;
  isCancelling: boolean;
  focusedDataInfoKey?: string;
  hoveredDataInfoKey?: string;
}
