import { createReducer } from '@reduxjs/toolkit';
import {
  openMessageBox,
  closeMessageBox,
  openFeedbackForm,
  closeFeedbackForm,
  openContactForm,
  closeContactForm,
  requestSubmitContactForm,
  setContactFormSubmitted,
  requestSubscribe,
  setSubscribed,
  openCompanyReport,
  openGlossaryBubble,
  closeGlossaryBubble,
  requestGlossaryItems,
  setGlossaryItems,
} from './actions';

import { MainState } from './types';

const initialState: MainState = {
  networkStates: {
    contactFormSubmitRequest: { isRequesting: false },
    subscribeRequest: { isRequesting: false },
    glossaryItemsRequest: { isRequesting: false },
  },
  isMessageBoxOpen: false,
  isFeedbackFormOpen: false,
  isContactFormOpen: false,
  openedReports: {},
  glossaryBuble: {
    isOpen: false,
  },
};

const mainReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(openMessageBox, (state, action) => ({
      ...state,
      isMessageBoxOpen: true,
      messageBoxInfo: action.payload,
    }))
    .addCase(closeMessageBox, (state) => ({
      ...state,
      isMessageBoxOpen: false,
    }))
    .addCase(openFeedbackForm, (state) => ({
      ...state,
      isFeedbackFormOpen: true,
    }))
    .addCase(closeFeedbackForm, (state) => ({
      ...state,
      isFeedbackFormOpen: false,
    }))
    .addCase(openContactForm, (state) => ({
      ...state,
      isContactFormOpen: true,
    }))
    .addCase(closeContactForm, (state) => ({
      ...state,
      isContactFormOpen: false,
    }))
    .addCase(requestSubmitContactForm, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        contactFormSubmitRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setContactFormSubmitted, (state, act) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        contactFormSubmitRequest: {
          isRequesting: false,
          lastError: act.payload.error,
        },
      },
    }))
    .addCase(requestSubscribe, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        subscribeRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setSubscribed, (state, act) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        subscribeRequest: {
          isRequesting: false,
          lastError: act.payload.error,
        },
      },
    }))
    .addCase(openCompanyReport, (state, act) => ({
      ...state,
      openedReports: {
        [act.payload.companyId]: true,
      },
    }))
    .addCase(openGlossaryBubble, (state, act) => ({
      ...state,
      glossaryBuble: {
        isOpen: true,
        originX: act.payload.originX,
        originY: act.payload.originY,
        phrase: act.payload.phrase,
      },
    }))
    .addCase(closeGlossaryBubble, (state) => {
      if (state.glossaryBuble.isOpen) {
        return ({
          ...state,
          glossaryBuble: {
            isOpen: false,
          },
        });
      }

      return state;
    })
    .addCase(requestGlossaryItems, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        glossaryItemsRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setGlossaryItems, (state, act) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        glossaryItemsRequest: {
          isRequesting: false,
          lastError: act.payload.error,
        },
      },
      glossaryBuble: {
        ...state.glossaryBuble,
        glossaryItems: act.payload.items,
      },
    }));
});

export default mainReducer;
