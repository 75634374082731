export const spacing = 8;

export default {
  navMenu: {
    width: spacing * 4,
  },
  gptPortal: {
    width: 1200,
  },
  gptSearchBox: {
    width: 264,
  },
};
