import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';
import getCommonStyles from '../../../../theme/commonStyles';
import { otherColorSets, otherContrastColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      width: '100%',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    urlInputBox: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '12px !important',
      },
      '& .MuiInputBase-root': {
        // outline: `0.5px solid ${Color(otherColorSets[2]).toString()}`,
        transition: 'all 0.3s ease-out',
        border: `1px solid ${theme.palette.grey[100]}`,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiInputBase-root.Mui-focused, .MuiInputBase-root:hover': {
        // outline: `0.5px solid ${Color(otherContrastColorSets[2]).darken(0.1).toString()}`,
        borderColor: Color(otherContrastColorSets[2]).darken(0.1).toString(),
      },
    },
    contentModeButtonsContainer: {
      display: 'flex',
      columnGap: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(-1),
      marginTop: 5,
      marginBottom: 5,
    },
    contentModeButtons: {
      borderRadius: `${theme.spacing(1)} !important`,
      backgroundColor: '#e7e7f6 !important',
      fontSize: '0.85rem !important',
      color: `${otherContrastColorSets[2]} !important`,
      whiteSpace: 'nowrap',
      '&:not(.active):hover': {
        backgroundColor: `${Color('#e7e7f6').darken(0.05).toString()} !important`,
      },
      '& .carretDownIconPath': {
        stroke: `${otherContrastColorSets[2]} !important`,
      },
      '&.active': {
        backgroundColor: `${otherColorSets[2]} !important`,
        color: `${theme.palette.common.white} !important`,
      },
    },
    progressContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      color: otherColorSets[2],
      border: `1px solid ${otherColorSets[2]}`,
      borderRadius: theme.spacing(1.5),
    },
    frameProgressContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    innerFrame: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '100%',
      width: '100%',
      border: `1px solid ${theme.palette.grey[100]}`,
      transition: 'all 0.3s ease-out',
      borderRadius: theme.spacing(1.5),
      overflow: 'auto',
      '&.markdown': {
        padding: theme.spacing(2),
      },
      '&:hover': {
        border: `1px solid ${otherColorSets[2]}`,
      },
      '&.hasError': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    errorText: {
      color: theme.palette.error.main,
    },
  });
};

export default styles;
