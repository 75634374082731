import React, {
  useEffect,
  useRef,
  useState,
  lazy,
  Suspense,
} from 'react';
import { makeStyles } from '@mui/styles';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import { Country, State } from 'country-state-city';
import jsonpath from 'jsonpath';
import { getYear } from 'date-fns';

import { ReactComponent as UploadImageIcon } from '../../../../assets/svg/uploadImageIcon.svg';
import { useCommonClasses } from '../../../../theme/commonStyles';
import useCommonProps from '../../../../theme/commonProps';
import { toBase64 } from '../../../../core/utils/imageUtils';
import createPerformanceUtils from '../../../../core/utils/performanceUtils';
import {
  getKeywordsFieldProps,
  getUpDownEndAdornment,
  getNumberFieldProps,
} from '../../../../core/utils/fieldUtils';

import { CompanyInfo, ManualFormData } from '../types';
import {
  COMPANY_FOUNDING_YEARS_MIN,
  COMPANY_NAME_MAX_LENGTH,
  getFirstError,
  hasErrorInfo,
} from '../manualFormUtils';
import { PropsFromRedux } from './container';
import styles from './CompanyForm.styles';

const Flag = lazy(() => import('react-world-flags'));

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);

const MAX_KEYWORDS = 8;
const MIN_KEYWORD_LENGTH = 3;

const performanceUtils = createPerformanceUtils();

const CompanyForm: React.FC<Props> = ({
  availableKeywords,
  manualFormData,
  updateManualFormField,
  validationInfo,
  setFocusedDataInfoKey,
  setHoveredDataInfoKey,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();
  const commonProps = useCommonProps();

  const allCountries = Country.getAllCountries();
  const [formData, setFormData] = useState(manualFormData?.companyInfo || {} as CompanyInfo);
  const uploadImageInputFile = useRef<HTMLInputElement>(null);

  const updateStore = async (updatedFormData: CompanyInfo) => {
    const manualForm = manualFormData || {} as ManualFormData;

    await performanceUtils.forLast(updatedFormData, 500);
    updateManualFormField({
      manualForm,
      path: 'companyInfo',
      value: updatedFormData,
    });
  };

  const updateCompanyInfoField = (path: keyof CompanyInfo, value: unknown) => {
    const innerFormData = {
      ...formData,
    } as CompanyInfo;

    jsonpath.value(innerFormData, `$.${path}`, value);

    if (path === 'compCountry') {
      updateCompanyInfoField('compState', undefined);
    }

    if (path === 'photoUrl') {
      updateCompanyInfoField('compLogo', undefined);
    }

    setFormData(innerFormData);

    void updateStore(innerFormData);
  };

  const handleImageFileSelected = async (files: FileList | null) => {
    if (!files?.length) {
      return;
    }

    const base64 = await toBase64(files[0]) as string;
    updateCompanyInfoField('photoUrl', base64);
  };

  const selectedCountry = allCountries.find((c) => c.isoCode === formData.compCountry);
  const states = State.getStatesOfCountry(formData.compCountry);
  const selectedState = states.find((s) => s.isoCode === formData.compState);
  const selectedKeywords = formData.compKwords?.split(',');
  const textInputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    setFormData(manualFormData?.companyInfo || {});
  }, [manualFormData?.companyInfo]);

  useEffect(() => {
    const errorInputs = textInputRefs.current.filter((input) => !!input);
    const firstErrorInput = errorInputs.length > 0 ? errorInputs[0] : undefined;
    firstErrorInput?.focus();
  }, [validationInfo]);

  return (
    <Paper
      className={classNames(classes.root, 'secondary', 'light')}
      classes={commonCss.panels.leafPanel}
      elevation={0}
    >
      <Grid container>
        <Grid item container xs={2} justifyContent="flex-start">
          <Button
            classes={commonCss.buttons.generalButton}
            variant="outlined"
            className={
              classNames(
                classes.companyLogo,
                commonCss.boxes.imageBox,
                'leaf',
                'secondary',
              )
            }
            onClick={() => uploadImageInputFile.current?.click()}
            onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'compLogo' })}
            onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
            onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'compLogo' })}
            onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
          >
            {formData?.photoUrl ? (
              <Box
                className={classes.companyLogo}
                component="img"
                src={formData?.photoUrl}
              />
            ) : (
              <Box>
                <Box className={classes.uploadImageIcon}>
                  <UploadImageIcon />
                </Box>
                <Typography
                  variant="body1"
                  color="inherit"
                  className={classes.uploadLogoText}
                >
                  Upload Logo
                </Typography>
              </Box>
            )}
          </Button>
          <input
            type="file"
            ref={uploadImageInputFile}
            style={{ display: 'none' }}
            accept=".jpg,.jpeg,.png"
            onChange={(event) => {
              void handleImageFileSelected(event.currentTarget.files);
            }}
          />
        </Grid>
        <Grid item xs={10}>
          <Grid container justifyContent="flex-start">
            <Grid item xs={4}>
              <TextField
                {...commonProps.textField({
                  label: 'Startup Name',
                  inputRef: (
                    hasErrorInfo(validationInfo?.company?.compName)
                      ? ((r) => { textInputRefs.current[0] = r; })
                      : undefined
                  ),
                  required: true,
                  maxLength: COMPANY_NAME_MAX_LENGTH,
                })}
                fullWidth
                autoFocus
                value={formData?.compName || ''}
                onChange={(event) => updateCompanyInfoField('compName', event.currentTarget.value)}
                error={hasErrorInfo(validationInfo?.company?.compName)}
                helperText={getFirstError(validationInfo?.company?.compName)}
                onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'compName' })}
                onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'compName' })}
                onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" marginTop={1.625}>
            <Grid item xs={4}>
              <TextField
                {...commonProps.textField({
                  label: 'Founding Year',
                  inputRef: (
                    hasErrorInfo(validationInfo?.company?.yearFounded)
                      ? ((r) => { textInputRefs.current[0] = r; })
                      : undefined
                  ),
                  required: true,
                  error: hasErrorInfo(validationInfo?.company?.yearFounded),
                  helperText: getFirstError(validationInfo?.company?.yearFounded),
                  endAdornment: getUpDownEndAdornment({
                    onUp: () => updateCompanyInfoField(
                      'yearFounded',
                      Number(formData?.yearFounded || COMPANY_FOUNDING_YEARS_MIN) + 1,
                    ),
                    onDown: () => updateCompanyInfoField(
                      'yearFounded',
                      Number(formData?.yearFounded || COMPANY_FOUNDING_YEARS_MIN) - 1,
                    ),
                  }),
                  min: COMPANY_FOUNDING_YEARS_MIN,
                  max: getYear(new Date()),
                })}
                {...getNumberFieldProps({ maxLength: 4 })}
                fullWidth
                type="number"
                value={formData?.yearFounded || ''}
                onChange={(event) => updateCompanyInfoField('yearFounded', event.currentTarget.value)}
                error={hasErrorInfo(validationInfo?.company?.yearFounded)}
                helperText={getFirstError(validationInfo?.company?.yearFounded)}
                onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'yearFounded' })}
                onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'yearFounded' })}
                onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box marginTop={2}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12}>
            <TextField
              {...commonProps.textField({ label: 'Website' })}
              fullWidth
              onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'website' })}
              onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
              onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'website' })}
              onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              {...commonProps.autocomplete({
                label: 'Choose Country',
                required: true,
                error: hasErrorInfo(validationInfo?.company?.compCountry),
                helperText: getFirstError(validationInfo?.company?.compCountry),
                inputRef: (
                  hasErrorInfo(validationInfo?.company?.compCountry)
                    ? ((r) => { textInputRefs.current[2] = r; })
                    : undefined
                ),
              })}
              options={allCountries}
              renderOption={(props, country) => (
                <li {...props}>
                  <Suspense>
                    <Flag
                      code={country.isoCode}
                      width={theme.spacing(3)}
                      style={{ marginRight: theme.spacing(0.5) }}
                    />
                  </Suspense>
                  {country.name}
                </li>
              )}
              getOptionLabel={(option) => option.name}
              fullWidth
              value={selectedCountry || null}
              onChange={(_, country) => updateCompanyInfoField('compCountry', country?.isoCode)}
              onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'compCountry' })}
              onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
              onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'compCountry' })}
              onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              {...commonProps.autocomplete({ label: 'Choose State/Province' })}
              className={(!selectedCountry || !states?.length) ? classes.fieldDisabled : undefined}
              options={states || []}
              getOptionLabel={(option) => option.name}
              fullWidth
              disabled={!selectedCountry || !states?.length}
              value={selectedState || null}
              onChange={(_, state) => updateCompanyInfoField('compState', state?.isoCode)}
              onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'compState' })}
              onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
              onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'compState' })}
              onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...commonProps.textField({
                label: 'Description',
                inputRef: (
                  hasErrorInfo(validationInfo?.company?.compDesc)
                    ? ((r) => { textInputRefs.current[3] = r; })
                    : undefined
                ),
                required: true,
              })}
              fullWidth
              multiline
              rows={3}
              value={formData.compDesc || ''}
              onChange={(event) => updateCompanyInfoField('compDesc', event.currentTarget.value)}
              error={hasErrorInfo(validationInfo?.company?.compDesc)}
              helperText={getFirstError(validationInfo?.company?.compDesc)}
              onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'compDesc' })}
              onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
              onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'compDesc' })}
              onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              className={classes.companyKeywords}
              {...commonProps.multiAutocomplete({
                label: 'Keywords',
                onKeyDown: (event) => {
                  const inputValue = (event.target as HTMLInputElement).value;
                  if (event.key === 'Enter' && inputValue.trim().length < MIN_KEYWORD_LENGTH) {
                    event.stopPropagation();
                    return false;
                  }

                  return true;
                },
              })}
              options={availableKeywords || []}
              fullWidth
              value={selectedKeywords || []}
              onChange={(_, keywords) => (
                updateCompanyInfoField('compKwords', (keywords as string[]).join(',') || undefined)
              )}
              getOptionDisabled={() => (selectedKeywords?.length || 0) >= MAX_KEYWORDS}
              freeSolo
              autoSelect
              filterSelectedOptions
              autoComplete
              {...getKeywordsFieldProps({
                keywords: selectedKeywords,
                maxKeywords: MAX_KEYWORDS,
                minKeywordLength: MIN_KEYWORD_LENGTH,
              })}
              onInputChange={(event, newInputValue) => {
                if (newInputValue.endsWith(',')) {
                  (event.target as HTMLInputElement).blur();
                  (event.target as HTMLInputElement).focus();
                }
              }}
              onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'compKwords' })}
              onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
              onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'compKwords' })}
              onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default CompanyForm;
