import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router';

import { useCommonClasses } from '../../../theme/commonStyles';
import {
  getFilledFounders,
  getTooltipLabel,
  hasErrorInStep,
  isTeamRelevant,
} from './manualFormUtils';

import StepsHeader from './stepsHeader';
import CompanyForm from './companyForm';
import { PropsFromRedux } from './container';
import FoundersForm from './foundersForm';
import TeamForm from './teamForm';
import styles from './ManualForm.styles';
import routes from '../main/routes';

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);
const TRANSITION_DISTANCE = 24;

const ManualForm: React.FC<Props> = ({
  currentStep,
  initializeManualForm,
  setStep,
  validationInfo,
  manualFormData,
  initiateManualFormSave,
  networkStates: {
    saveRequest,
  },
  isSuccessfullySaved,
  isCancelling,
  setIsCancelling,
  focusedDataInfoKey,
  hoveredDataInfoKey,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();
  const [isShaking, setShaking] = useState(false);
  const prevStepRef = useRef<number>(-1);
  const colorSetMaps = ['secondary', 'tertiary', 'primary'];
  const colorSet = colorSetMaps[currentStep];
  const filledFounders = getFilledFounders(manualFormData);
  const navigate = useNavigate();

  useEffect(() => {
    initializeManualForm();
  }, [initializeManualForm]);

  useEffect(() => {
    prevStepRef.current = currentStep;
  }, [currentStep]);

  useEffect(() => {
    if (!hasErrorInStep(currentStep, validationInfo)) {
      return () => {};
    }

    setShaking(true);
    const timeOutRef = setTimeout(() => {
      setShaking(false);
    }, 500);

    return () => {
      clearTimeout(timeOutRef);
    };
  }, [validationInfo]);

  useEffect(() => {
    if (isSuccessfullySaved && manualFormData?.formId) {
      navigate(`${routes.reports}/${manualFormData?.formId}/?score=true&m=true`);
      initializeManualForm();
    }
  }, [isSuccessfullySaved]);

  const shouldCancelOnPrev = () => (
    currentStep === 0
  );

  const shouldSaveOnNext = () => (
    currentStep === 2 || (currentStep === 1 && !isTeamRelevant(manualFormData))
  );

  const closeForm = () => {
    navigate('..');
    initializeManualForm();
  };

  const proceedPrev = () => {
    if (!shouldCancelOnPrev()) {
      setStep({ step: currentStep - 1 });
      return;
    }

    setIsCancelling({ isCancelling: true });
  };

  const proceedNext = () => {
    if (!shouldSaveOnNext()) {
      setStep({ step: currentStep + 1 });
      return;
    }

    initiateManualFormSave();
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Box className={classes.stepsHeader}>
          <StepsHeader />
        </Box>
        <Box
          position="relative"
          className={classNames(
            classes.formsContainer,
            isShaking ? classes.shaking : undefined,
          )}
        >
          <AnimatePresence>
            {currentStep === 0 && (
              <motion.div
                className={classes.formBox}
                initial={{
                  opacity: 0,
                  x: currentStep >= prevStepRef.current
                    ? TRANSITION_DISTANCE : -TRANSITION_DISTANCE,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                }}
                exit={{
                  opacity: 0,
                }}
              >
                <CompanyForm />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {currentStep === 1 && (
              <motion.div
                className={classes.formBox}
                initial={{
                  opacity: 0,
                  x: currentStep >= prevStepRef.current
                    ? TRANSITION_DISTANCE : -TRANSITION_DISTANCE,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                }}
                exit={{
                  opacity: 0,
                }}
              >
                <FoundersForm />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {currentStep === 2 && (
              <motion.div
                className={classes.formBox}
                initial={{
                  opacity: 0,
                  x: currentStep >= prevStepRef.current
                    ? TRANSITION_DISTANCE : -TRANSITION_DISTANCE,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                }}
                exit={{
                  opacity: 0,
                }}
              >
                <TeamForm />
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
        <Box className={classes.navigateBar}>
          <Grid container spacing={2}>
            <Grid item container xs={2} alignItems="center">
              <Button
                variant="outlined"
                className={colorSet}
                classes={commonCss.buttons.roundButton}
                fullWidth
                onClick={() => proceedPrev()}
              >
                {shouldCancelOnPrev()
                  ? 'Cancel'
                  : (
                    <>
                      <ChevronLeftIcon />
                      Back
                    </>
                  )
                }
              </Button>
            </Grid>
            <Grid item xs={8}>
              <div
                className={
                  classNames(
                    commonCss.panels.leafPanel.root,
                    classes.descriptionBox,
                  )}
              >
                <div
                  className={
                    classNames(
                      classes.descriptionText,
                      colorSet,
                    )
                  }
                >
                  <AnimatePresence>
                    {(hoveredDataInfoKey || focusedDataInfoKey) ? (
                      <motion.div
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{
                          opacity: 0,
                        }}
                      >
                        {getTooltipLabel(hoveredDataInfoKey || focusedDataInfoKey)}
                      </motion.div>
                    ) : (
                      <div>
                        {getTooltipLabel()}
                      </div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </Grid>
            <Grid item container xs={2} alignItems="center">
              <Button
                variant="contained"
                className={colorSet}
                classes={commonCss.buttons.roundButton}
                fullWidth
                onClick={() => proceedNext()}
                disabled={shouldSaveOnNext() && (
                  filledFounders.length === 0
                  || saveRequest.isRequesting
                )}
              >
                {shouldSaveOnNext()
                  ? (
                    <>
                      {saveRequest.isRequesting && (
                        <CircularProgress
                          color="inherit"
                          size={12}
                          sx={{ marginRight: theme.spacing(1) }}
                        />
                      )}
                      Finish
                    </>
                  )
                  : (
                    <>
                      Next
                      <ChevronRightIcon />
                    </>
                  )
                }
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Dialog
        open={isCancelling}
        onClose={() => setIsCancelling({ isCancelling: false })}
        classes={commonCss.dialogs.generalDialog}
      >
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to cancel this form?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            classes={commonCss.buttons.roundButton}
            className={classNames(classes.dialogButton, colorSet)}
            onClick={() => closeForm()}
          >
            OK
          </Button>
          <Button
            variant="outlined"
            classes={commonCss.buttons.roundButton}
            className={classes.dialogButton}
            color="info"
            onClick={() => setIsCancelling({ isCancelling: false })}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManualForm;
