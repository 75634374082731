import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import CountUp from 'react-countup';

import {
  FiberManualRecord as CheckedIcon,
} from '@mui/icons-material';

import classNames from 'classnames';

import { easeOut } from '../../../../core/utils/animationUtils';
import FeatureBarChart from '../../../../components/featureBarChart/FeatureBarChart';
import { useCommonClasses } from '../../../../theme/commonStyles';
import {
  getMaxScorePercent,
  normalizePercentage,
} from '../reportUtils';
import { CompanyReportInfo, FounderData } from '../types';
import styles from './ProfileScoreReport.styles';
import { INTERNAL_SPACING } from '../Reports.styles';

type Props = {
  companyReportInfo?: CompanyReportInfo;
  currentDataInfoKey?: string;
  selectedDataInfoKeys?: string[];
  onDataMouseEnter?: (dataInfoKey: string) => void;
  onDataMouseLeave?: () => void;
  onDataMouseClick?: (dataInfoKey: string) => void;
};

const useStyles = makeStyles(styles);

const ProfileScoreReport: React.FC<Props> = ({
  companyReportInfo,
  currentDataInfoKey,
  onDataMouseEnter,
  onDataMouseLeave,
  onDataMouseClick,
  selectedDataInfoKeys,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const [hoveringDataInfoKey, setHoveringDataInfoKey] = useState<string>();

  const { founders, featureImportance } = companyReportInfo || {};

  const visibleFounders = Array.from({ length: 3 })
    .fill(undefined).map((_, index) => (founders || []).at(index));

  const maxPercent = getMaxScorePercent(featureImportance);

  const renderText = (text: number) => (
    <Typography
      classes={{ root: classes.valueLabel }}
      variant="body2"
      fontSize="1.2rem"
      fontWeight={300}
    >
      <CountUp
        end={text || 0}
        duration={1}
        decimals={0}
        useEasing
        delay={0.5}
        easingFn={easeOut}
      />
    </Typography>
  );

  const renderCheckIcon = (isChecked: boolean | number) => (
    isChecked
      ? (
        <Box className={classes.checkedIconDecorate}>
          <CheckedIcon color="inherit" fontSize="small" />
        </Box>
      )
      : (
        <Box className={classes.uncheckedIconDecorate} />
      )
  );

  const renderBarChart = (
    value?: number,
    colorClass?: string,
    shouldHighlight?: boolean,
  ) => {
    const normalizedValue = normalizePercentage(value || 0, maxPercent);
    return (
      <FeatureBarChart
        value={value}
        normalizedValue={normalizedValue}
        colorClass={colorClass}
        modifierClass={shouldHighlight ? 'contrast' : undefined}
      />
    );
  };

  const fieldSets = [{
    key: 'team',
    colorClass: 'primary',
    parentDataInfoKey: 'c7Team',
    fields: [{
      title: 'Team Size',
      dataInfoKey: 'c11Teamsize',
      render: () => (
        renderCheckIcon(true)
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.teamSize, 'primary', shouldHighlight)
      ),
    }, {
      title: 'Work Alumni',
      dataInfoKey: 'c13Workalumni',
      render: (founder: FounderData) => (
        renderCheckIcon(founder.workAlumni)
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.jobAlumni, 'primary', shouldHighlight)
      ),
    }, {
      title: 'School Alumni',
      dataInfoKey: 'c14Schoolalumni',
      render: (founder: FounderData) => (
        renderCheckIcon(founder.schoolAlumni)
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.schoolAlumni, 'primary', shouldHighlight)
      ),
    }],
  }, {
    key: 'experience',
    colorClass: 'tertiary',
    parentDataInfoKey: 'c8Experience',
    fields: [{
      title: 'Working Experience',
      dataInfoKey: 'c16WorkingExperience',
      render: (founder: FounderData) => (
        renderText(founder.experience)
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.workingExperience, 'tertiary', shouldHighlight)
      ),
    }, {
      title: 'No. of Prior Jobs',
      dataInfoKey: 'c17PriorJobs',
      render: (founder: FounderData) => (
        renderText(founder.noJobs)
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.priorJobs, 'tertiary', shouldHighlight)
      ),
    }, {
      title: 'Companies Founded',
      dataInfoKey: 'c18CompaniesFounded',
      render: (founder: FounderData) => (
        renderText(founder.orgsFounded)
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.orgsFounded, 'tertiary', shouldHighlight)
      ),
    }, {
      title: 'Was CEO',
      dataInfoKey: 'c19WasCeo',
      render: (founder: FounderData) => (
        renderCheckIcon(founder.wasCeo)
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.wasCeo, 'tertiary', shouldHighlight)
      ),
    }, {
      title: 'Worked at FAAMG',
      dataInfoKey: 'c20WorkedFaamg',
      render: (founder: FounderData) => (
        renderCheckIcon(founder.faamg)
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.workedAtFaamg, 'tertiary', shouldHighlight)
      ),
    }],
  }, {
    key: 'education',
    colorClass: 'quaternary',
    parentDataInfoKey: 'c9Education',
    fields: [{
      title: 'No. of Degrees',
      dataInfoKey: 'c21Degrees',
      render: (founder: FounderData) => (
        renderText(founder.degrees)
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.degreeCount, 'quaternary', shouldHighlight)
      ),
    }, {
      title: 'Has a Science degree',
      dataInfoKey: 'c22ScienceDegree',
      render: (founder: FounderData) => (
        renderCheckIcon(founder.scienceDegree)
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.scienceDegree, 'quaternary', shouldHighlight)
      ),
    }, {
      title: 'Top University',
      dataInfoKey: 'c23TopUniversity',
      render: (founder: FounderData) => (
        renderCheckIcon(founder.top10School)
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.topUniversity, 'quaternary', shouldHighlight)
      ),
    }, {
      title: 'Has MBA',
      dataInfoKey: 'c24Mba',
      render: (founder: FounderData) => (
        renderCheckIcon(founder.mba)
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.mba, 'quaternary', shouldHighlight)
      ),
    }, {
      title: 'Has Ph.D.',
      dataInfoKey: 'c25Phd',
      render: (founder: FounderData) => (
        renderCheckIcon(founder.phD)
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.phD, 'quaternary', shouldHighlight)
      ),
    }],
  }];

  const handleMouseEnter = (dataInfoKey: string) => {
    if (onDataMouseEnter) {
      onDataMouseEnter(dataInfoKey);
    }

    setHoveringDataInfoKey(dataInfoKey);
  };

  const handleMouseLeave = () => {
    if (onDataMouseLeave) {
      onDataMouseLeave();
    }

    setHoveringDataInfoKey(undefined);
  };

  const handleMouseClick = (dataInfoKey: string) => {
    if (onDataMouseClick) {
      onDataMouseClick(dataInfoKey);
    }
  };

  return (
    <div className={classes.root}>
      {fieldSets.map((fieldSet) => (
        <React.Fragment key={fieldSet.key}>
          <Divider className={classes.divider} />
          <Box
            className={
              classNames(
                classes.reportGroupRow,
                fieldSet.colorClass,
                commonCss.reports.row,
                currentDataInfoKey === fieldSet.parentDataInfoKey && 'active',
                selectedDataInfoKeys?.includes(fieldSet.parentDataInfoKey) && 'active',
              )
            }
          >
            {fieldSet.fields.map((field) => (
              <Box
                key={field.title}
                onClick={() => handleMouseClick(field.dataInfoKey)}
                onMouseEnter={() => handleMouseEnter(field.dataInfoKey)}
                onMouseLeave={() => handleMouseLeave()}
                className={
                  classNames(
                    classes.reportRow,
                    commonCss.reports.row,
                    commonCss.reports.rowHover,
                    fieldSet.colorClass,
                    selectedDataInfoKeys?.includes(field.dataInfoKey) && 'active',
                  )
                }
              >
                <Grid
                  container
                  columnSpacing={{ xl: INTERNAL_SPACING / 8, xs: 5 }}
                >
                  <Grid item container xs={12} md={6}>
                    <Grid container>
                      <Grid item container xs={3} xl={4} alignItems="center">
                        <Typography
                          variant="body2"
                          color="inherit"
                          classes={{
                            root: classNames(
                              commonCss.reports.rowTitle,
                              fieldSet.colorClass,
                              classes.rowTitle,
                            ),
                          }}
                        >
                          {field.title}
                        </Typography>
                      </Grid>
                      <Grid item container xs={9} xl={8} columnSpacing={2} alignItems="center">
                        {visibleFounders?.map((founder, index) => (
                          <Grid key={index} item xs={4}>
                            {founder && (
                              <Box
                                display="flex"
                                className={
                                  classNames(
                                    classes.profileItem,
                                    commonCss.reports.rowElement,
                                    fieldSet.colorClass,
                                    selectedDataInfoKeys?.includes(field.dataInfoKey) && 'contrast',
                                  )
                                }
                                justifyContent="center"
                              >
                                {field.render(founder)}
                              </Box>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={6} flexDirection="column" justifyContent="center">
                    <Box>
                      {field.renderChart(
                        field.dataInfoKey === hoveringDataInfoKey
                        || currentDataInfoKey === fieldSet.parentDataInfoKey
                        || selectedDataInfoKeys?.includes(field.dataInfoKey),
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProfileScoreReport;
