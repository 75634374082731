import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import { useResizeDetector } from 'react-resize-detector';
import CountUp from 'react-countup';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { ReactComponent as TrendDownIcon } from '../../../../assets/svg/trendDownIcon.svg';
import { ReactComponent as TrendUpIcon } from '../../../../assets/svg/trendUpIcon.svg';
import { ReactComponent as TrendSwingIcon } from '../../../../assets/svg/trendSwingIcon.svg';
import { otherColorSets, otherContrastColorSets } from '../../../../theme/palette';
import { useCommonClasses } from '../../../../theme/commonStyles';
import { easeOut } from '../../../../core/utils/animationUtils';

import { MarketInquiryItem } from '../types';
import { PropsFromRedux } from './container';
import styles from './MarketItemCard.styles';
import { getAmountDisplayWithSymbol, AMOUNT_1M } from '../../report/reportUtils';

interface Props extends PropsFromRedux {
  marketItem: MarketInquiryItem;
  expanded?: boolean;
  showHighlights?: boolean;
  chartHeight?: number;
  onDataClick?: (marketItem: MarketInquiryItem) => void;
}

const HIGHLIGHT_TITLE_ROW_HEIGHT = 24;
const DEFAULT_CHART_HEIGHT = 200;

const useStyles = makeStyles(styles);

const MarketItemCard: React.FC<Props> = ({
  marketItem,
  expanded: isExpanded,
  showHighlights = true,
  chartHeight = DEFAULT_CHART_HEIGHT,
  onDataClick,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const {
    width: chartAreaWidth,
    height: chartAreaHeight,
    ref: chartAreaRef,
  } = useResizeDetector();

  const {
    height: highlightTitleHeight,
    ref: highlightTitleRef,
  } = useResizeDetector();

  const getCagrs = (yearBack: number) => {
    const chartDataByYear = marketItem.chartData;

    const latestYear = Object.keys(chartDataByYear)
      .reduce((latest, val) => (Number(val) > Number(latest) ? val : latest), '0');

    const prevYear = Number(latestYear);
    const prevYearKey = String(prevYear);
    const prevYearValue = chartDataByYear[prevYearKey];

    const backYear = prevYear - yearBack;
    const backYearKey = String(backYear);
    const backYearValue = chartDataByYear?.[backYearKey];

    return (((prevYearValue / backYearValue) ** (1 / yearBack)) - 1) * 100;
  };

  const isCurrencyView = (
    marketItem.stat === 'Market Size Statistics'
    || marketItem.stat === 'Wage Statistics'
  );

  const getGrowthTrendIcon = () => {
    const y3 = getCagrs(3);
    const y5 = getCagrs(5);
    const y10 = getCagrs(10);

    if (y3 >= y5 && y5 >= y10) {
      return <TrendUpIcon style={{ fill: otherContrastColorSets[0] }} />;
    }

    if (y3 <= y5 && y5 <= y10) {
      return <TrendDownIcon style={{ fill: otherContrastColorSets[0] }} />;
    }

    return <TrendSwingIcon style={{ fill: otherContrastColorSets[0] }} />;
  };

  const trendSets = [{
    title: 'Growth Trend',
    values: getGrowthTrendIcon(),
    mode: 'trend',
  }, {
    title: '10y-CAGR',
    values: getCagrs(10),
    mode: 'value',
  }, {
    title: '5y-CAGR',
    values: getCagrs(5),
    mode: 'value',
  }, {
    title: '3y-CAGR',
    values: getCagrs(3),
    mode: 'value',
  }];

  const sizeChartSeries = [{
    name: marketItem.chartTitle,
    type: 'column',
    color: otherColorSets[0],
    className: 'primary',
    data: Object.values(marketItem.chartData),
  }];

  const isSizeReady = chartAreaWidth && chartAreaHeight;
  const chartLabels = Object.keys(marketItem.chartData);

  const calcChartHeight = isSizeReady && (chartHeight || chartAreaHeight)
    - (highlightTitleHeight && highlightTitleHeight > HIGHLIGHT_TITLE_ROW_HEIGHT
      ? (highlightTitleHeight - HIGHLIGHT_TITLE_ROW_HEIGHT)
      : 0
    );

  const chartOptions = isSizeReady ? {
    chart: {
      width: chartAreaWidth,
      height: Math.round(calcChartHeight || DEFAULT_CHART_HEIGHT),
      type: 'column',
      backgroundColor: 'transparent',
      spacing: [10, 0, 0, 0],
    },
    legend: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: chartLabels,
      crosshair: true,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: '',
      },
      labels: {
        formatter: ((ctx) => getAmountDisplayWithSymbol(
          Number(ctx.value || 0) * (isCurrencyView ? AMOUNT_1M : 1),
          {
            capitalized: false,
            smallUnit: false,
            symbol: isCurrencyView ? '$' : '',
          },
        )),
      },
      tickAmount: 6,
    },
    tooltip: {
      formatter: (tooltip) => {
        const amount = (tooltip.chart.hoverPoint?.y || 0);
        const formattedAmount = getAmountDisplayWithSymbol(
          amount * (isCurrencyView ? AMOUNT_1M : 1),
          {
            smallUnit: false,
            symbol: isCurrencyView ? '$' : '',
            capitalized: true,
            decimals: 2,
          },
        );

        return `${tooltip.chart.hoverSeries?.name || ''}: ${formattedAmount}`;
      },
    },
    plotOptions: {
      series: {
        // borderRadiusTopLeft: 5,
        // borderRadiusTopRight: 5,
        enableMouseTracking: true,
        states: {
          hover: {
            enabled: true,
            lineWidth: 3,
          },
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        pointStart: 0,
        animation: {
          duration: 1000,
        },
      } as unknown as Highcharts.PlotSeriesOptions,
    },
    accessibility: {
      enabled: false,
    },
    series: sizeChartSeries,
  } as Highcharts.Options : undefined;

  return (
    <div
      className={
        classNames(
          classes.root,
          isExpanded && 'active',
          'MarketItemCard-root',
        )
      }
      onClick={() => onDataClick && onDataClick(marketItem)}
    >
      <Typography
        variant="h4"
        className={
          classNames(
            classes.title,
            'MarketItemCard-title',
          )
        }
      >
        {marketItem.answer}
      </Typography>
      <Box
        className={classNames(
          classes.details,
          isExpanded && 'active',
          'MarketItemCard-details',
        )}
      >
        <Grid container columnSpacing={2}>
          {showHighlights && (
            <Grid item xs={5}>
              <Typography variant="body1" className={classes.highlightTitle}>
                Market Highlights
              </Typography>
              {marketItem.highlights.map((highlight, index) => (
                <Typography key={index} variant="body2" className={classes.highlightItem}>
                  {`- ${highlight}`}
                </Typography>
              ))}
            </Grid>
          )}
          <Grid item container xs={showHighlights ? 7 : 12} justifyItems="stretch">
            <div
              className={
                classNames(
                  classes.chartWrapper,
                  'MarketItemCard-chartWrapper',
                )
              }
            >
              <div
                className={
                  classNames(
                    classes.chartHeader,
                    'MarketItemCard-chartHeader',
                  )
                }
              >
                {isExpanded && (
                  <Grid container>
                    {trendSets.map((trendSet, index) => (
                      <Grid item container xs={index === 0 ? 3.2 : 2.93} key={trendSet.title} alignItems="center">
                        <Typography variant="body2">
                          {trendSet.title}
                        </Typography>
                        <Box
                          className={
                            classNames(
                              commonCss.boxes.dataBox,
                              classes.trendItem,
                              'leaf',
                              'MarketItemCard-dataBox',
                            )
                          }
                        >
                          {trendSet.mode === 'value' && (
                            <Typography
                              variant="body2"
                              className={
                                classNames(
                                  classes.trendItemText,
                                )
                              }
                            >
                              <CountUp
                                end={trendSet.values as number || 0}
                                duration={0.5}
                                decimals={2}
                                useEasing
                                easingFn={easeOut}
                              />
                              %
                            </Typography>
                          )}
                          {trendSet.mode === 'trend' && (
                            trendSet.values
                          )}
                        </Box>

                      </Grid>
                    ))}
                  </Grid>
                )}
              </div>
              <div className={classes.chartContainer}>
                <div
                  className={
                    classNames(
                      classes.chartArea,
                      'MarketItemCard-chartArea',
                    )
                  }
                  ref={chartAreaRef}
                >
                  {chartOptions && isExpanded && (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={chartOptions}
                    />
                  )}
                </div>
              </div>
              <Typography
                variant="body1"
                className={
                  classNames(
                    classes.highlightTitle,
                    'MarketItemCard-chartTitle',
                  )
                }
                ref={highlightTitleRef}
              >
                {marketItem.chartTitle}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default MarketItemCard;
