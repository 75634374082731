/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

export const easeOut = (t: number, b: number, c: number, d: number) => (
  (-c * (t /= d) * (t - 2) + b)
);

export const easeInQuad = (t: number, b: number, c: number, d: number) => (
  c * (t /= d) * t + b
);
