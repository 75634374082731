import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';
import {
  updateManualFormField,
  requestLinkedInProfile,
  setFocusedDataInfoKey,
  setHoveredDataInfoKey,
} from '../actions';

import FoundersForm from './FoundersForm';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.manualForm,
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    updateManualFormField,
    requestLinkedInProfile,
    setFocusedDataInfoKey,
    setHoveredDataInfoKey,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FoundersForm);
