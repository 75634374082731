import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';

import {
  triggerNotification,
} from '../../notifier/actions';

import CodeBlockView from './CodeBlockView';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.search,
    ...state.gpt,
    ...state.relationChart,
    networkStates: {
      ...state.search.networkStates,
      ...state.gpt.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    triggerNotification,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CodeBlockView);
