import { Buffer } from 'buffer';
import config from '../../../config';

import { getCountryName } from '../../../core/utils/countryUtils';
import { isNullOrUndefined } from '../../../core/utils/dataUtils';
import {
  CompanyData,
  CompanyReportInfo,
  CompetitorCompanyInfo,
  FounderData,
  InvestingParams,
  MarketReportInfo,
} from './types';

export const AMOUNT_1K = 1000;
export const AMOUNT_25K = 25000;
export const AMOUNT_1M = 1000000;
export const AMOUNT_15M = 15000000;
export const AMOUNT_1B = 1000 * AMOUNT_1M;
export const AMOUNT_1T = 1000 * AMOUNT_1B;

export const getCompanyDataApiUrl = () => {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.get('score') === 'true') {
    if (searchParams.get('m') === 'true') {
      return config.api.getFormInfoData;
    }

    return config.api.getScoreModelReportData;
  }

  return config.api.getCompanyReportData;
};

export const getFullName = (founder?: FounderData) => (
  `${founder?.firstName || ''} ${founder?.lastName || ''}`
);

export const getMaxScorePercent = (scoreData?: unknown) => {
  const fi = (scoreData || {}) as Record<string, number>;
  const allPercentProps = Object.keys(fi).filter((key) => key !== 'sumScore');
  const maxPercent = allPercentProps.reduce(
    (max, key) => Math.max(max, fi[key] || 0),
    0,
  );

  return maxPercent;
};

export const normalizePercentage = (originalPercent: number, maxPercent: number) => (
  (originalPercent / maxPercent) * 100
);

export const getScoreAsPercentage = (score?: number) => (
  !isNullOrUndefined(score)
    ? Number(((score || 0) * 100).toFixed(2))
    : undefined
);

export const getEstimatedValuationValue = (investingParams?: InvestingParams) => {
  const { amount, maxAmount } = investingParams || {};
  if ((maxAmount || 0) <= AMOUNT_1M) {
    return (amount || 0) * 3;
  }

  return (amount || 0) * 4;
};

export const getFormattedUnit = (
  unit: string,
  capitalized: boolean = false,
  smallUnit: boolean = true,
) => {
  const formattedUnit = capitalized ? unit.toUpperCase() : unit.toLowerCase();
  if (smallUnit) {
    return `<small>${formattedUnit}</small>`;
  }

  return formattedUnit;
};

export const getAmountInfo = (
  amount?: number,
  options?: {
    capitalized: boolean;
    smallUnit: boolean;
    decimals?: number;
  },
) => {
  const capitalized = isNullOrUndefined(options?.capitalized) ? false : options?.capitalized;
  const smallUnit = isNullOrUndefined(options?.smallUnit) ? true : options?.smallUnit;
  const decimals = isNullOrUndefined(options?.decimals) ? 0 : options?.decimals;

  const value = amount || 0;
  if (value >= AMOUNT_1K && value < AMOUNT_1M) {
    return {
      amount: (value / AMOUNT_1K).toFixed(decimals),
      unit: getFormattedUnit('k', capitalized, smallUnit),
    };
  }

  if (value >= AMOUNT_1T) {
    return {
      amount: (value / AMOUNT_1T).toFixed(decimals),
      unit: getFormattedUnit('t', capitalized, smallUnit),
    };
  }

  if (value >= AMOUNT_1B) {
    return {
      amount: (value / AMOUNT_1B).toFixed(decimals),
      unit: getFormattedUnit('b', capitalized, smallUnit),
    };
  }

  if (value >= AMOUNT_1M) {
    return {
      amount: (value / AMOUNT_1M).toFixed(decimals),
      unit: getFormattedUnit('m', capitalized, smallUnit),
    };
  }

  return {
    amount: value.toFixed(0),
    unit: undefined,
  };
};

export const getAmountDisplayInDollars = (amount?: number) => {
  const amountInfo = getAmountInfo(amount, {
    capitalized: true,
    smallUnit: false,
    decimals: 2,
  });

  return (
    `$${amountInfo.amount}${amountInfo.unit || ''}`
  );
};

export const getAmountDisplayWithSymbol = (
  amount?: number,
  options?: {
    capitalized: boolean;
    smallUnit: boolean;
    symbol?: string;
    decimals?: number;
  },
) => {
  const symbol = isNullOrUndefined(options?.symbol) ? '$' : (options?.symbol || '');
  const amountInfo = getAmountInfo(amount, options);

  return (
    `${symbol}${amountInfo.amount}${amountInfo.unit || ''}`
  );
};

export const getCompanyLocation = (companyData?: CompanyData) => {
  const countryName = companyData?.orgCountry
    && getCountryName(companyData?.orgCountry);

  return [countryName].filter((ent) => !!ent).join(', ');
};

export const getCompetitorLocation = (competitorData?: CompetitorCompanyInfo) => {
  const countryName = competitorData?.countryIso
    && getCountryName(competitorData?.countryIso);

  return [countryName].filter((ent) => !!ent).join(', ');
};

export const extractSelectableMarkets = (marketReport?: MarketReportInfo) => {
  const subCategory = marketReport?.subCategory || {};
  const keys = Object.keys(subCategory);

  return Array.from(new Set(keys.map((key) => subCategory[key])));
};

export const refineMarketReport = (marketReport?: MarketReportInfo) => {
  const subCategory = marketReport?.subCategory || {};
  const keys = Object.keys(subCategory);

  return keys.map((key) => ({
    subCategory: marketReport?.subCategory[key],
    stat: marketReport?.stat[key],
    chartData: marketReport?.chartData[key],
  }));
};

export const getCommonReportParams = (companyReport?: CompanyReportInfo) => {
  const { orgDesc } = companyReport?.companyData || {};
  const search = new URLSearchParams(window.location.search);
  const score = search.get('score');
  const manualForm = search.get('m');
  const cinfo = search.get('cinfo');
  const companyDataJson = cinfo && Buffer.from(cinfo, 'base64').toString('utf8');
  const companyData: CompanyData = companyDataJson && JSON.parse(companyDataJson);

  const commonReportParams = companyData
    ? new URLSearchParams({
      desc: Buffer.from(companyData.orgDesc || '').toString('base64'),
      cinfo: cinfo || '',
    }).toString()
    : (
      orgDesc && new URLSearchParams({
        desc: Buffer.from(orgDesc).toString('base64'),
        ...(score === 'true' && {
          score: 'true',
        }),
        ...(manualForm === 'true' && {
          m: 'true',
        }),
      }).toString()
    );

  return commonReportParams;
};
