import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import CountUp from 'react-countup';

import { easeOut } from '../../../../core/utils/animationUtils';

import { useCommonClasses } from '../../../../theme/commonStyles';
import FounderAvatar from '../founderAvatar';
import FeatureImportance from '../featureImportance';

import { FounderData, GroupImportanceData } from '../types';

import styles from './FounderInfoPanel.styles';
import { INTERNAL_SPACING } from '../Reports.styles';

type Props = {
  section?: string;
  founders?: FounderData[];
  groupImportance?: GroupImportanceData;
  selectedDataInfoKeys?: string[];
  onDataMouseEnter?: (dataInfoKey: string) => void;
  onDataMouseLeave?: () => void;
  onDataMouseClick?: (dataInfoKey: string) => void;
};

type InfoItem = {
  title: string;
  dataInfoKey: string;
  render: (founder: FounderData) => JSX.Element;
};

const useStyles = makeStyles(styles);

const FounderInfoPanel: React.FC<Props> = ({
  section,
  founders,
  groupImportance,
  selectedDataInfoKeys,
  onDataMouseEnter,
  onDataMouseLeave,
  onDataMouseClick,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();

  const visibleFounders = Array.from({ length: 3 })
    .fill(undefined).map((_, index) => (founders || []).at(index));

  const fields = [section !== 'successScore' ? {
    title: 'Title',
    dataInfoKey: 'c4FounderScore',
    render: (founder: FounderData) => (
      <Typography variant="caption" textAlign="center" className={classes.value}>
        {(founder.title || '').split('/').join(' / ')}
      </Typography>
    ),
  } : undefined, {
    title: 'Founder Score',
    dataInfoKey: 'c4FounderScore',
    render: (founder: FounderData) => (
      <div
        className={
          classNames(
            commonCss.banners.leafBanner,
            classes.leafBanner,
          )
        }
      >
        <Typography variant="h6" color="inherit" textAlign="center">
          <CountUp
            end={founder.score * 100}
            duration={1}
            decimals={0}
            useEasing
            delay={0.5}
            easingFn={easeOut}
          />
          <small>%</small>
        </Typography>
      </div>
    ),
  }, {
    title: 'VC Likeable',
    dataInfoKey: 'c3Vclikeable',
    render: (founder: FounderData) => (
      <div
        className={
          classNames(
            commonCss.banners.leafBanner,
            classes.leafBanner,
            'outline',
          )
        }
      >
        <Typography variant="h6" color="inherit" textAlign="center">
          <CountUp
            end={founder.vcLikeable * 100}
            duration={1}
            decimals={0}
            useEasing
            delay={0.5}
            easingFn={easeOut}
          />
          <small>%</small>
        </Typography>
      </div>
    ),
  }].filter((item): item is InfoItem => !!item);

  return (
    <div className={classes.root}>
      <Box>
        <Grid container columnSpacing={{ xl: INTERNAL_SPACING / 8, xs: 5 }}>
          <Grid item xs={12} md={6}>
            <Grid container marginBottom={1}>
              <Grid item xs={3} xl={4}>

              </Grid>
              <Grid item xs={9} xl={8}>
                <Grid container columnSpacing={2}>
                  {(visibleFounders || []).map((founder, index) => (
                    <Grid
                      key={index}
                      item
                      container
                      xs={4}
                      flexDirection="column"
                    >
                      {founder && (
                        <>
                          <Box className={classes.founderBox}>
                            <div className={classes.founderDecorate} />
                          </Box>
                          <Box alignSelf="center">
                            <FounderAvatar founder={founder} />
                          </Box>
                        </>
                      )}
                    </Grid>
                  ))}
                </Grid>
                <Grid container columnSpacing={2}>
                  {(visibleFounders || []).map((founder, index) => (
                    <Grid item key={index} xs={4}>
                      <Box className={classes.founderItem} marginTop={1}>
                        {[
                          founder?.firstName,
                          founder?.lastName,
                        ].map((part, partIndex) => (
                          <Typography
                            key={partIndex}
                            align="center"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            className={classes.title}
                          >
                            {part}
                          </Typography>
                        ))}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            {fields.map((field) => (
              <Box
                key={field.title}
                onMouseEnter={() => onDataMouseEnter && onDataMouseEnter(field.dataInfoKey)}
                onMouseLeave={() => onDataMouseLeave && onDataMouseLeave()}
                className={
                  classNames(
                    classes.reportRow,
                    commonCss.reports.row,
                    commonCss.reports.rowHover,
                    'grey',
                  )
                }
              >
                <Grid container>
                  <Grid item container xs={3} xl={4} alignItems="center">
                    <Box className={classes.rowTitle}>
                      <Typography variant="body1" lineHeight={1.2} className={classes.title}>
                        {field.title}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item container xs={9} xl={8} columnSpacing={2}>
                    {(visibleFounders || []).map((founder, index) => (
                      <Grid key={index} item xs={4}>
                        {founder && (
                          <Box
                            display="flex"
                            className={classes.founderValue}
                            justifyContent="center"
                          >
                            {field.render(founder)}
                          </Box>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
          >
            <Box
              marginTop={3}
              display="flex"
              flex="1"
              justifyContent="center"
              flexDirection="column"
              paddingTop={theme.spacing(4)}
              paddingBottom={theme.spacing(1)}
            >
              <FeatureImportance
                groupImportance={groupImportance}
                selectedDataInfoKeys={selectedDataInfoKeys}
                onDataMouseEnter={onDataMouseEnter}
                onDataMouseLeave={onDataMouseLeave}
                onDataMouseClick={onDataMouseClick}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default FounderInfoPanel;
