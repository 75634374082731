import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';
import {
  updateManualFormField,
  setHoveredDataInfoKey,
  setFocusedDataInfoKey,
} from '../actions';

import TeamForm from './TeamForm';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.manualForm,
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    updateManualFormField,
    setHoveredDataInfoKey,
    setFocusedDataInfoKey,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TeamForm);
