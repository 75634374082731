import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../types';

import {
  openMessageBox,
  openCompanyReport,
} from '../main/actions';

import {
  requestRelationData,
} from '../relationChart/actions';

import {
  initializeReport,
  updateInvestingParams,
  toggleDataInfoKeySelection,
} from './actions';

import Reports from './Reports';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.router,
    ...state.report,
    ...state.session,
    ...state.main,
    networkStates: {
      ...state.report.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    initializeReport,
    updateInvestingParams,
    toggleDataInfoKeySelection,
    openMessageBox,
    openCompanyReport,
    requestRelationData,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Reports);
