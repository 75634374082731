import {
  ChipClasses,
  Theme,
} from '@mui/material';
import { makeStyles, StyleRules } from '@mui/styles';
import classNames from 'classnames';
import Color from 'color';
import { CSSProperties } from 'react';
import {
  otherColorSets,
  otherContrastColorSets,
  otherLightColorSets,
  sourceRefContrastColor,
} from './palette';

export const ROUND_PANEL_RADIUS = 10;
export const ROUND_PANEL_PADDING = 4;
export const STROKE_DEFAULT_COLOR_STRETCH = 500;
export const FOUNDER_AVATAR_SIZE = 84;
export const USER_AVATAR_SIZE = 24;

const getCommonStyles = (theme: Theme) => ({
  '@global': {
    'input[type=\'number\']': {
      '-moz-appearance': 'textfield',
      appearance: 'textfield',
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  menuItemIcon: {
    border: `1px solid ${theme.palette.grey[400]} !important`,
    color: `${theme.palette.common.white} !important`,
    stroke: `${theme.palette.grey[400]} !important`,
  },
  circularIconButton: {
    borderRadius: '50%',
  },
  leafIcon: {
    borderRadius: `${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(5)} 0`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  panel: {
    backgroundColor: theme.palette.background.paper,
    border: `0.5px solid ${theme.palette.grey[STROKE_DEFAULT_COLOR_STRETCH]} !important`,
    padding: theme.spacing(ROUND_PANEL_PADDING),
  },
  largeRoundedBorder: {
    border: `0.5px solid ${theme.palette.grey[STROKE_DEFAULT_COLOR_STRETCH]} !important`,
    borderRadius: theme.spacing(5),
  },
  mediumRoundedBorder: {
    border: `0.5px solid ${theme.palette.grey[STROKE_DEFAULT_COLOR_STRETCH]} !important`,
    borderRadius: theme.spacing(3),
  },
  smallRoundedBorder: {
    border: `0.5px solid ${theme.palette.grey[STROKE_DEFAULT_COLOR_STRETCH]} !important`,
    borderRadius: theme.spacing(1),
  },
});

const investingTextStyle = {
  backgroundImage: `linear-gradient(
    to right,
    ${otherColorSets[0]} -4%,
    ${otherColorSets[2]} 50%,
    ${otherColorSets[1]} 104%
  );`,
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
  width: 100,
};

const pvalyouTextStyle = {
  backgroundImage: `linear-gradient(
    to right,
    #326ab7 0%,
    #6f4ed8 27%,
    #ff5996 64%,
    #ff609a 67%,
    #ff73a7 72%,
    #ff93bb 80%
  )`,
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
  width: 150,
  color: `${otherColorSets[0]} !important`,
};

export const getColorModifierClasses = (
  prop: string,
  selector: string = '',
  moreStyles: CSSProperties = {},
) => ({
  [`&.primary${selector}`]: {
    [prop]: `${otherColorSets[0]} !important`,
    ...moreStyles,
    '&.contrast': {
      [prop]: `${otherContrastColorSets[0]} !important`,
    },
    '&.light': {
      [prop]: `${otherLightColorSets[0]} !important`,
    },
  },
  [`&.secondary${selector}`]: {
    [prop]: `${otherColorSets[1]} !important`,
    ...moreStyles,
    '&.contrast': {
      [prop]: `${otherContrastColorSets[1]} !important`,
    },
    '&.light': {
      [prop]: `${otherLightColorSets[1]} !important`,
    },
  },
  [`&.tertiary${selector}`]: {
    [prop]: `${otherColorSets[2]} !important`,
    ...moreStyles,
    '&.contrast': {
      [prop]: `${otherContrastColorSets[2]} !important`,
    },
    '&.light': {
      [prop]: `${otherLightColorSets[2]} !important`,
    },
  },
  [`&.quaternary${selector}`]: {
    [prop]: `${otherColorSets[3]} !important`,
    ...moreStyles,
    '&.contrast': {
      [prop]: `${otherContrastColorSets[3]} !important`,
    },
    '&.light': {
      [prop]: `${otherLightColorSets[3]} !important`,
    },
  },
  [`&.investing${selector}`]: {
    [prop]: `linear-gradient(
      to right,
      ${otherColorSets[0]} -4%,
      ${otherColorSets[2]} 50%,
      ${otherColorSets[1]} 104%
    )`,
    ...moreStyles,
  },
  [`&.pvalyou${selector}`]: {
    [prop]: `linear-gradient(
      to right,
      #326ab7 0%,
      #6f4ed8 27%,
      #ff5996 64%,
      #ff609a 67%,
      #ff73a7 72%,
      #ff93bb 80%,
      #ffbfd7 88%,
      #fff7fa 98%,
      #fff 100%
    )`,
    ...moreStyles,
  },
  [`&.market${selector}`]: {
    [prop]: `linear-gradient(
      to right,
      ${otherContrastColorSets[0]} 0%,
      ${otherColorSets[0]} 104%
    )`,
    ...moreStyles,
  },
  [`&.ranking${selector}`]: {
    [prop]: `linear-gradient(
      to right,
      ${otherContrastColorSets[5]} 0%,
      ${otherColorSets[5]} 104%
    )`,
    '&.flip': {
      [prop]: `linear-gradient(
        to left,
        ${otherContrastColorSets[5]} 0%,
        ${otherColorSets[5]} 104%
      )`,
    },
    ...moreStyles,
  },
  [`&.landscape${selector}`]: {
    [prop]: `linear-gradient(
      to right,
      ${otherContrastColorSets[4]} 0%,
      ${otherColorSets[4]} 104%
    )`,
    '&.flip': {
      [prop]: `linear-gradient(
        to left,
        ${otherContrastColorSets[4]} -100%,
        ${otherColorSets[4]} 0%
      )`,
    },
    ...moreStyles,
  },
  [`&.utility${selector}`]: {
    [prop]: `${otherColorSets[6]} !important`,
    ...moreStyles,
    '&.contrast': {
      [prop]: `${otherContrastColorSets[6]} !important`,
    },
    '&.light': {
      [prop]: `${otherLightColorSets[6]} !important`,
    },
  },
  [`&.grey${selector}`]: {
    [prop]: Color('#000').alpha(0.5).toString(),
    ...moreStyles,
  },
  [`&.onePager${selector}`]: {
    [prop]: '#fff',
  },
});

export const getListItemHoverColorModifierClasses = (
  prop: string,
  selector: string = '',
  moreStyles: CSSProperties = {},
) => ({
  [`&.primary${selector}`]: {
    [prop]: `${Color(otherLightColorSets[0]).darken(0.1).toString()} !important`,
    ...moreStyles,
  },
  [`&.secondary${selector}`]: {
    [prop]: `${Color(otherLightColorSets[1]).darken(0.1).toString()} !important`,
    ...moreStyles,
  },
  [`&.tertiary${selector}`]: {
    [prop]: `${Color(otherLightColorSets[2]).darken(0.1).toString()} !important`,
    ...moreStyles,
  },
  [`&.quaternary${selector}`]: {
    [prop]: `${Color(otherLightColorSets[3]).darken(0.1).toString()} !important`,
    ...moreStyles,
  },
  [`&.investing${selector}`]: {
    [prop]: `linear-gradient(
      to right,
      ${otherColorSets[0]} -4%,
      ${otherColorSets[2]} 50%,
      ${otherColorSets[1]} 104%
    )`,
    ...moreStyles,
  },
  [`&.pvalyou${selector}`]: {
    [prop]: `linear-gradient(
      to right,
      #326ab7 0%,
      #6f4ed8 27%,
      #ff5996 64%,
      #ff609a 67%,
      #ff73a7 72%,
      #ff93bb 80%,
      #ffbfd7 88%,
      #fff7fa 98%,
      #fff 100%
    )`,
    ...moreStyles,
  },
  [`&.grey${selector}`]: {
    [prop]: Color('#000').alpha(0.1).toString(),
    ...moreStyles,
  },
});

export const getLightColorModifierClasses = (
  prop: string,
  selector: string = '',
  moreStyles: CSSProperties = {},
) => ({
  [`&.primary${selector}`]: {
    [prop]: `${otherLightColorSets[0]} !important`,
    ...moreStyles,
  },
  [`&.secondary${selector}`]: {
    [prop]: `${otherLightColorSets[1]} !important`,
    ...moreStyles,
  },
  [`&.tertiary${selector}`]: {
    [prop]: `${otherLightColorSets[2]} !important`,
    ...moreStyles,
  },
  [`&.quaternary${selector}`]: {
    [prop]: `${otherLightColorSets[3]} !important`,
    ...moreStyles,
  },
  [`&.investing${selector}`]: {
    [prop]: `linear-gradient(
      to right,
      ${otherColorSets[0]} -4%,
      ${otherColorSets[2]} 50%,
      ${otherColorSets[1]} 104%
    )`,
    ...moreStyles,
  },
  [`&.pvalyou${selector}`]: {
    backgroundImage: `linear-gradient(
      to right,
      rgba(50,106,183, 0.1) 0%,
      rgba(11,78,216, 0.1) 27%,
      rgba(255,89,150, 0.1) 64%,
      rgba(255,96,154, 0.1) 67%,
      rgba(255,115,167, 0.1) 72%,
      rgba(255,147,187, 0.1) 80%
    )`,
    ...moreStyles,
  },
  [`&.utility${selector}`]: {
    [prop]: `${otherLightColorSets[6]} !important`,
    ...moreStyles,
  },
  [`&.grey${selector}`]: {
    [prop]: Color('#000').alpha(0.1).toString(),
    ...moreStyles,
  },
});

export const getContrastColorModifierClasses = (
  prop: string,
  selector: string = '',
) => ({
  [`&.primary${selector}`]: {
    [prop]: `${otherContrastColorSets[0]} !important`,
  },
  [`&.secondary${selector}`]: {
    [prop]: `${otherContrastColorSets[1]} !important`,
  },
  [`&.tertiary${selector}`]: {
    [prop]: `${otherContrastColorSets[2]} !important`,
  },
  [`&.quaternary${selector}`]: {
    [prop]: `${otherContrastColorSets[3]} !important`,
  },
  [`&.investing${selector}`]: investingTextStyle,
  [`&.pvalyou${selector}`]: pvalyouTextStyle,
  [`&.source${selector}`]: {
    [prop]: `${sourceRefContrastColor} !important`,
  },
  [`&.utility${selector}`]: {
    [prop]: `${otherContrastColorSets[6]} !important`,
  },
  [`&.grey${selector}`]: {
    [prop]: `${Color('#000').alpha(0.5).toString()} !important`,
  },
});

export const getShadowModifierClasses = (selector: string) => ({
  [`&.primary${selector}`]: {
    boxShadow: `0 6px 9px ${Color(otherColorSets[0]).alpha(0.25).toString()}`,
  },
  [`&.secondary${selector}`]: {
    boxShadow: `0 6px 9px ${Color(otherColorSets[1]).alpha(0.25).toString()}`,
  },
  [`&.tertiary${selector}`]: {
    boxShadow: `0 6px 9px ${Color(otherColorSets[2]).alpha(0.25).toString()}`,
  },
  [`&.quaternary${selector}`]: {
    boxShadow: `0 6px 9px ${Color(otherColorSets[3]).alpha(0.25).toString()}`,
  },
  [`&.investing${selector}`]: {
    boxShadow: `0 6px 9px ${Color(otherColorSets[2]).alpha(0.25).toString()}`,
  },
  [`&.grey${selector}`]: {
    boxShadow: `0 6px 9px ${Color('#000').alpha(0.25).toString()}`,
  },
});

const muiStyles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);

  return {
    headerDecorate: {
      display: 'flex',
      height: theme.spacing(12),
      borderLeft: commonStyles.largeRoundedBorder.border,
      borderBottom: commonStyles.largeRoundedBorder.border,
      borderRadius: `0 0 0 ${theme.spacing(ROUND_PANEL_RADIUS)} !important`,
      boxShadow: '0 3px 10px 0 rgba(152, 152, 152, 0.16)',
      backgroundColor: '#fdfdfd',
    },
    menuIconButton: {
      ...commonStyles.circularIconButton,
      ...commonStyles.menuItemIcon,
      width: theme.spacing(5),
      height: theme.spacing(5),
      '&.collapsed': {
        border: 'none !important',
        stroke: 'none !important',
        width: theme.spacing(4),
        height: theme.spacing(4),
      },
    },
    navMenuItem: {
      color: `${theme.palette.common.black} !important`,
      '&.active': {
        background: Color(theme.palette.common.black).alpha(0.5).toString(),
      },
    },
    navMenuIcon: {
      ...commonStyles.leafIcon,
      ...commonStyles.menuItemIcon,
      fontSize: theme.spacing(1),
      transition: 'background ease-out 0.1s, all ease-out 0.3s',
      width: 50,
      height: 25.5,
      '& > svg': {
        fontSize: theme.spacing(2),
      },
      '&.active': {
        border: `1px solid ${theme.palette.common.white} !important`,
        color: `${theme.palette.common.white} !important`,
        stroke: `${theme.palette.common.white} !important`,
      },
      '&.collapsed.active': {
        width: '7px !important',
        height: '7px !important',
        padding: 0,
      },
      '&.collapsed': {
        width: '7px !important',
        height: '7px !important',
        padding: 0,
      },
      ...getColorModifierClasses('background'),
    },
    navMenuText: {
      marginLeft: theme.spacing(1.5),
    },
    navMenuTypography: {
      color: `${theme.palette.grey[400]} !important`,
      fontSize: '1rem !important',
      '&.active': {
        color: `${theme.palette.common.white} !important`,
      },
    },
    panel: {
      ...commonStyles.panel,
      '&.topLeftRound': {
        borderRadius: `${theme.spacing(ROUND_PANEL_RADIUS)} 0 0 0 !important`,
      },
      '&.topRightRound': {
        borderRadius: `0 ${theme.spacing(ROUND_PANEL_RADIUS)} 0 0 !important`,
      },
      '&.bottomLeftRound': {
        borderRadius: `0 0 0 ${theme.spacing(ROUND_PANEL_RADIUS)} !important`,
      },
      '&.bottomRightRound': {
        borderRadius: `0 0 ${theme.spacing(ROUND_PANEL_RADIUS)} 0 !important`,
      },
      '&.leaf': {
        borderRadius: `
          ${theme.spacing(ROUND_PANEL_RADIUS / 2.5)}
          ${theme.spacing(ROUND_PANEL_RADIUS / 2.5)}
          ${theme.spacing(ROUND_PANEL_RADIUS / 2.5)}
          0
        `,
      },
      '&.round': {
        borderRadius: theme.spacing(ROUND_PANEL_RADIUS / 2.5),
      },
      '&.dense': {
        padding: theme.spacing(ROUND_PANEL_PADDING / 1.5),
      },
      ...getColorModifierClasses('borderColor', undefined),
      ...getColorModifierClasses('background', undefined),
    },
    textField: {
      '& input': {
        paddingTop: theme.spacing(1.75),
        paddingBottom: theme.spacing(1.75),
      },
      '&.opaque': {
        backgroundColor: `${theme.palette.common.white} !important`,
      },
      '&.leaf': {
        borderRadius: `
          ${theme.spacing(3.5)}
          ${theme.spacing(3.5)}
          ${theme.spacing(3.5)}
          0
        `,
      },
    },
    textFieldSizeSmall: {
      '& input': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    textFieldLabel: {
      marginLeft: `${theme.spacing(1)} !important`,
    },
    imageBox: {
      ...commonStyles.smallRoundedBorder,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      border: `0.5px solid ${theme.palette.grey[100]}`,
      backgroundColor: `${theme.palette.common.white} !important`,
      borderRadius: 5,
      padding: 5,
      '&.leaf': {
        borderRadius: `
          ${theme.spacing(3.5)}
          ${theme.spacing(3.5)}
          ${theme.spacing(3.5)}
          0
        `,
      },
      ...getColorModifierClasses('color', ' .MuiSvgIcon-root'),
    },
    dataBox: {
      ...commonStyles.smallRoundedBorder,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      border: `0.5px solid ${theme.palette.grey[50]}`,
      backgroundColor: `${theme.palette.common.white} !important`,
      borderRadius: 5,
      '&.leaf': {
        borderRadius: `
          ${theme.spacing(3.5)}
          ${theme.spacing(3.5)}
          ${theme.spacing(3.5)}
          0
        `,
      },
      '&.flip': {
        borderRadius: `${theme.spacing(3.5)} ${theme.spacing(3.5)} 0 ${theme.spacing(3.5)}`,
      },
      ...getColorModifierClasses('borderColor'),
      ...getContrastColorModifierClasses('color', '.MuiBox-root'),
    },
    tagItem: {
      height: `${theme.spacing(3.25)} !important`,
      margin: `${theme.spacing(0.5)} ${theme.spacing(1)} ${theme.spacing(0.5)} 0 !important`,
      backgroundColor: `${theme.palette.common.white} !important`,
      color: `${theme.palette.grey[900]} !important`,
      '&.leaf': {
        borderRadius: `
          ${theme.spacing(5)}
          ${theme.spacing(5)}
          ${theme.spacing(5)}
          0
        `,
      },
      '&.outline': {
        backgroundColor: 'transparent !important',
        borderWidth: '1px !important',
        borderStyle: 'solid',
      },
      '&.bolder': {
        fontWeight: 400,
      },
      ...getColorModifierClasses('borderColor', '.MuiButtonBase-root'),
      ...getColorModifierClasses('color'),
      ...getColorModifierClasses('color', ' .MuiSvgIcon-root'),
    },
    tagItemDeleteIcon: {
      marginRight: '2px !important',
      ...getColorModifierClasses('color'),
    },
    inputTagItem: {
      '& .MuiChip-deleteIcon': {
        fontSize: '15px !important',
      },
    },
    chartXAxis: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(0.25),
      borderBottom: `1px solid ${theme.palette.grey[500]}`,
      paddingBottom: theme.spacing(0.5),
      '& > *': {
        fontSize: '1.2rem !important',
        color: `${theme.palette.grey[100]} !important`,
      },
      '&.noLine': {
        borderBottom: 'none',
      },
    },
    chartPinBanner: {
      borderRadius: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)} 0`,
      background: theme.palette.secondary.main,
      padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
      transition: 'all ease-out 0.3s',
      '&.flip': {
        borderRadius: `${theme.spacing(3)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
      },
      ...getColorModifierClasses('background'),
      ...getShadowModifierClasses('.highlight'),
    },
    chartPinSubBanner: {
      borderRadius: `${theme.spacing(3)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
      background: theme.palette.common.white,
      border: `1px solid ${theme.palette.secondary.main}`,
      padding: theme.spacing(0.5),
      '&.flip': {
        borderRadius: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)} 0`,
      },
    },
    horizontalBarChart: {
      width: 0,
      height: theme.spacing(3),
      '&.collaped': {
        height: theme.spacing(1.25),
      },
      transition: 'all ease-out 0.3s',
      ...getColorModifierClasses('background'),
    },
    horizontalBarPinLine: {
      width: 1,
      height: 'calc(50% - 16px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '&.long': {
        height: 'calc(100% - 40px)',
      },
      borderLeftWidth: 1,
      borderLeftStyle: 'dotted',
      marginTop: theme.spacing(3),
      ...getColorModifierClasses('borderLeftColor'),
    },
    chartDataTitle: {
      fontSize: '1.2rem !important',
      fontWeight: '400 !important',
      ...getContrastColorModifierClasses('color'),
    },
    reportRowElement: {
      fontSize: '1rem',
      transition: 'all ease-out 0.3s',
      ...getColorModifierClasses('color'),
      ...getContrastColorModifierClasses('borderColor', '>div::after'),
    },
    reportRowTitle: {
      fontSize: '1rem',
      ...getContrastColorModifierClasses('color'),
    },
    reportRow: {
      position: 'relative',
      zIndex: 1,
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        opacity: 0,
        zIndex: 0,
        transition: 'all ease-out 0.2s',
      },
      ...getColorModifierClasses('background', '::before', { opacity: 0.0, pointerEvents: 'none' }),
      ...getColorModifierClasses('background', '.active::before', { opacity: 0.25, pointerEvents: 'none' }),
    },
    reportRowHover: {
      ...getColorModifierClasses('background', '::before', { opacity: 0.0, pointerEvents: 'none' }),
      ...getColorModifierClasses('background', ':hover::before', { opacity: 0.25, pointerEvents: 'none' }),
    },
    linearGaugeBox: {
      borderRadius: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)} 0`,
      background: theme.palette.common.white,
      border: `1.5px dashed ${theme.palette.secondary.main}`,
      padding: theme.spacing(0.5),
      boxSizing: 'border-box',
      textAlign: 'center',
      fontSize: '1.875rem',
      color: theme.palette.secondary.main,
      transition: 'all ease-out 0.2s',
      ...getShadowModifierClasses('.highlight'),
      '& > span > span:not(:first-child)': {
        fontSize: '1.5rem !important',
      },
    },
    founderAvatar: {
      width: `${FOUNDER_AVATAR_SIZE}px !important`,
      height: `${FOUNDER_AVATAR_SIZE}px !important`,
      [theme.breakpoints.down('xl')]: {
        width: `${FOUNDER_AVATAR_SIZE - 8}px !important`,
        height: `${FOUNDER_AVATAR_SIZE - 8}px !important`,
      },
      border: `4px solid ${theme.palette.common.white}`,
      outline: `0.5px solid ${theme.palette.grey[500]}`,
      backgroundColor: `${theme.palette.common.white} !important`,
      '&.small': {
        transform: 'scale(0.4)',
        transformOrigin: '0 0',
      },
    },
    founderAvatarFallback: {
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    userAvatar: {
      width: `${USER_AVATAR_SIZE}px !important`,
      height: `${USER_AVATAR_SIZE}px !important`,
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    userAvatarFallback: {
      backgroundColor: theme.palette.secondary.main,
    },
    leafBanner: {
      ...commonStyles.leafIcon,
      backgroundColor: `${theme.palette.grey[700]} !important`,
      color: theme.palette.common.white,
      fontSize: '1.1rem',
      '&.outline': {
        backgroundColor: `${theme.palette.common.white} !important`,
        color: theme.palette.grey[700],
        border: `1.5px dashed ${theme.palette.grey[700]}`,
      },
    },
    investBanner: {
      ...commonStyles.leafIcon,
      border: `2px dotted ${theme.palette.common.white}`,
      background: `
        linear-gradient(to right, ${theme.palette.common.white}, ${theme.palette.common.white}), 
        linear-gradient(to right, ${otherColorSets[0]} , ${otherColorSets[1]});
      `,
      backgroundClip: 'padding-box, border-box',
      backgroundOrigin: 'padding-box, border-box',
      '& > *': investingTextStyle,
      [theme.breakpoints.down('xl')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      },
    },
    strokedButton: {
      borderRadius: `${theme.spacing(4)} !important`,
      border: `3px solid ${theme.palette.common.white} !important`,
      outline: `1px solid ${theme.palette.grey[200]} !important`,
      margin: '-2px !important',
      fontWeight: '400 !important',
      ...getColorModifierClasses('background'),
    },
    roundedButton: {
      borderRadius: `${theme.spacing(5)} !important`,
      fontWeight: '400 !important',
      '&.leaf': {
        borderRadius: `
          ${theme.spacing(5)}
          ${theme.spacing(5)}
          ${theme.spacing(5)}
          0 !important
        `,
      },
      '&.opaque': {
        backgroundColor: `${theme.palette.common.white} !important`,
        '&:hover': {
          backgroundColor: `${theme.palette.grey[100]} !important`,
        },
      },
      '&.Mui-disabled': {
        opacity: 0.5,
      },
      ...getColorModifierClasses('background', '.MuiButton-contained'),
      ...getContrastColorModifierClasses('background', '.MuiButton-contained:hover'),
      ...getColorModifierClasses('borderColor', '.MuiButton-outlined'),
      ...getColorModifierClasses('color', '.MuiButton-outlined.MuiButtonBase-root'),
      ...getLightColorModifierClasses('background', '.MuiButton-outlined:hover'),
    },
    transparentButton: {
      ...getColorModifierClasses('color', ' .MuiTouchRipple-root'),
    },
    circularButton: {
      borderRadius: '50% !important',
      borderWidth: '1px !important',
      borderStyle: 'solid !important',
      '&.opaque': {
        backgroundColor: `${theme.palette.common.white} !important`,
      },
      ...getColorModifierClasses('color'),
      ...getLightColorModifierClasses('backgroundColor', ':hover'),
    },
    borderedButton: {
      borderRadius: `${theme.spacing(4)} !important`,
      border: `1px solid ${theme.palette.grey[200]} !important`,
      color: `${theme.palette.grey[500]} !important`,
      fontWeight: '300 !important',
      '&.opaque': {
        backgroundColor: `${theme.palette.common.white} !important`,
      },
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      '&.leaf': {
        borderRadius: `
          ${theme.spacing(4)}
          ${theme.spacing(4)}
          ${theme.spacing(4)}
          0 !important
        `,
      },
    },
    mediumButton: {
      fontSize: '1rem !important',
      padding: `${theme.spacing(0.25)} ${theme.spacing(0.25)} !important`,
    },
    smallButton: {
      fontSize: '0.8rem !important',
      padding: `0 ${theme.spacing(0.25)} !important`,
    },
    sliderThumb: {
      marginTop: `-${theme.spacing(1.75)}`,
      borderRadius: `${theme.spacing(4)} !important`,
      border: `3px solid ${theme.palette.common.white} !important`,
      outline: `1px solid ${theme.palette.grey[200]} !important`,
      color: theme.palette.common.white,
      fontSize: '1.1rem',
      fontWeight: 400,
      padding: `${theme.spacing(0.25)} ${theme.spacing(1.5)} !important`,
      cursor: 'pointer',
      minWidth: theme.spacing(10),
      textAlign: 'center',
      textTransform: 'uppercase',
      ...getColorModifierClasses('background'),
      '&.thin': {
        outline: 'none !important',
        padding: `2px ${theme.spacing(0.5)} !important`,
        minWidth: theme.spacing(5),
        marginTop: `-${theme.spacing(1)}`,
        height: 18,
        lineHeight: 0.9,
        fontSize: '1rem',
        ...getContrastColorModifierClasses('color'),
      },
      '&.outline': {
        backgroundColor: `${theme.palette.common.white} !important`,
        borderWidth: '1px !important',
        ...getContrastColorModifierClasses('borderColor'),
      },
    },
    sliderTrack: {
      height: theme.spacing(0.75),
      cursor: 'pointer',
      ...getColorModifierClasses('background'),
      '&.thin': {
        height: 1,
      },
    },
    notificationBar: {
      ...getContrastColorModifierClasses('color'),
      ...getColorModifierClasses('background'),
      '&.error': {
        color: `${theme.palette.common.white} !important`,
        background: `${theme.palette.error.main} !important`,
      },
    },
    yearPickerPopper: {
      '& .PrivatePickersYear-root > button': {
        fontWeight: 300,
      },
      '& .PrivatePickersYear-root > button.Mui-disabled': {
        backgroundColor: 'transparent',
        color: theme.palette.grey[500],
        cursor: 'default',
      },
      ...getColorModifierClasses('backgroundColor', ' .PrivatePickersYear-root > button.Mui-selected'),
    },
    listBox: {
      ...getListItemHoverColorModifierClasses('backgroundColor', ' .MuiAutocomplete-option.Mui-focused'),
      ...getColorModifierClasses(
        'backgroundColor',
        ' .MuiAutocomplete-option[aria-selected=true]',
        { color: theme.palette.common.white, opacity: 0.75 },
      ),
    },
    tabs: {
      minHeight: `${theme.spacing(5)} !important`,
      '& .MuiTab-root': {
        borderBottom: `3px solid ${theme.palette.grey[50]}`,
        marginLeft: 4,
        marginRight: 4,
        padding: '4px 8px !important',
        minHeight: theme.spacing(5),
        color: theme.palette.grey[300],
        fontWeight: 500,
        '&.Mui-selected': {
          color: theme.palette.grey[900],
        },
      },
    },
    tabIndicator: {
      ...getColorModifierClasses('background'),
      height: '6px !important',
    },
  };
};

const useStyles = makeStyles(muiStyles);

export const useCommonClasses = () => {
  const classes = useStyles();

  return {
    navMenu: {
      menuIconButton: {
        root: classes.menuIconButton,
      },
      menuItem: {
        root: classes.navMenuItem,
      },
      menuIconBox: classes.navMenuIcon,
      menuText: {
        root: classes.navMenuText,
      },
      menuTypography: {
        root: classes.navMenuTypography,
      },
    },
    panels: {
      bottomRightRoundPanel: {
        root: classNames(
          classes.panel,
          'bottomRightRound',
        ),
      },
      bottomLeftRoundPanel: {
        root: classNames(
          classes.panel,
          'bottomLeftRound',
        ),
      },
      topRightRoundPanel: {
        root: classNames(
          classes.panel,
          'topRightRound',
        ),
      },
      topLeftRoundPanel: {
        root: classNames(
          classes.panel,
          'topLeftRound',
        ),
      },
      leafPanel: {
        root: classNames(
          classes.panel,
          'leaf',
        ),
      },
      roundPanel: {
        root: classNames(
          classes.panel,
          'round',
        ),
      },
    },
    boxes: {
      imageBox: classes.imageBox,
      dataBox: classes.dataBox,
    },
    chips: {
      tagItem: {
        root: classes.tagItem,
        deleteIcon: classes.tagItemDeleteIcon,
      } as ChipClasses,
      inputTagItem: {
        root: classes.inputTagItem,
      },
    },
    banners: {
      chartPinBanner: classes.chartPinBanner,
      chartPinSubBanner: classes.chartPinSubBanner,
      leafBanner: classes.leafBanner,
      investBanner: classes.investBanner,
    },
    charts: {
      chartXAxis: classes.chartXAxis,
      linearGaugeBox: classes.linearGaugeBox,
      horizontalBarChart: classes.horizontalBarChart,
      horizontalBarPinLine: classes.horizontalBarPinLine,
      dataTitle: classes.chartDataTitle,
    },
    reports: {
      rowElement: classes.reportRowElement,
      rowTitle: classes.reportRowTitle,
      row: classes.reportRow,
      rowHover: classes.reportRowHover,
    },
    avatars: {
      founder: {
        root: classes.founderAvatar,
        fallback: classes.founderAvatarFallback,
      },
      user: {
        root: classes.userAvatar,
        fallback: classes.userAvatarFallback,
      },
    },
    decorates: {
      reportHeader: classes.headerDecorate,
    },
    buttons: {
      leafButton: {
        root: classNames(
          classes.roundedButton,
          'leaf',
        ),
      },
      generalButton: {
        contained: classes.strokedButton,
        outlined: classes.borderedButton,
        sizeMedium: classes.mediumButton,
        sizeSmall: classes.smallButton,
      },
      roundButton: {
        root: classNames(
          classes.roundedButton,
        ),
      },
      circularButton: {
        root: classNames(
          classes.circularButton,
        ),
      },
      transparentButton: {
        root: classNames(
          classes.transparentButton,
        ),
      },
    },
    sliders: {
      labeledSlider: {
        thumb: classes.sliderThumb,
        track: classes.sliderTrack,
      },
    },
    notifications: {
      snackBar: classes.notificationBar,
    },
    fields: {
      leafTextField: {
        root: classNames(
          classes.textField,
          'leaf',
        ),
        sizeSmall: classes.textFieldSizeSmall,
        label: classes.textFieldLabel,
      },
      yearPickerPopper: classes.yearPickerPopper,
      listBox: classes.listBox,
    },
    dialogs: {
      generalDialog: {
        paper: classNames(
          classes.panel,
          'leaf',
          'dense',
        ),
      },
    },
    tabs: {
      root: classes.tabs,
      indicator: classes.tabIndicator,
    },
  };
};

export default getCommonStyles;
