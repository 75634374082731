import { Buffer } from 'buffer';
import {
  call,
  put,
} from 'redux-saga/effects';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { eventChannel, END } from 'redux-saga';

import {
  getBackendUrl,
  httpPost,
  httpGet,
  AdditionalOptions,
  httpPut,
  httpDelete,
} from '../apiService';

import { isNullOrUndefined } from './dataUtils';
import HttpError from '../types/errors';
import { notifyHttpUnauthorized, notifyNoCredit } from '../actions';
import { isBase64Content } from './stringUtils';

export type PerformApiArgs<T> = {
  params?: Record<string, string | string[] | undefined>;
  payload?: Record<string, unknown>;
  onResult?: (args: { result?: T, error?: Error }) => void;
};

function createUrlSearchParams(
  givenParams: Record<string, string | string[] | undefined> | undefined,
) {
  const params = givenParams;

  if (params) {
    Object.keys(params).forEach((key) => (
      isNullOrUndefined(params[key]) && delete params[key]
    ));
  }

  const urlSearchParams = new URLSearchParams({
    ...{},
    ...(!!params && params),
  });

  const browserSearchParams = new URLSearchParams(window.location.search);
  const neighborhoodId = browserSearchParams.get('neighborhood');

  if (neighborhoodId) {
    urlSearchParams.append('neighborhoodId', neighborhoodId);
  }

  return urlSearchParams;
}

export function* performApiGet<T>(
  path: string,
  args?: PerformApiArgs<T>,
  options?: RequestInit & AdditionalOptions,
) {
  try {
    const backendUrl = getBackendUrl();
    const params = args && args.params;

    const urlSearchParams = createUrlSearchParams(params);

    if (options?.useFullUrlAsPath) {
      urlSearchParams.delete('r');
    }

    const queryString = Array.from(urlSearchParams.entries()).length > 0
      ? `?${urlSearchParams.toString()}`
      : '';

    const url = options?.useFullUrlAsPath
      ? `${path}${queryString}`
      : `${backendUrl}/${path}${queryString}`;

    const data = (yield call(httpGet, url, options)) as Record<string, unknown>;

    const result = (typeof data === 'object' && !options?.useOriginalResponse)
      ? camelcaseKeys(data, { deep: true }) as unknown as T
      : data as unknown as T;

    if (args && args.onResult) {
      yield args.onResult({ result });
    }
  } catch (error) {
    if (error instanceof HttpError && error.statusCode === 401) {
      yield put(notifyHttpUnauthorized());
    }

    if (error instanceof HttpError && error.statusCode === 403) {
      yield put(notifyNoCredit());
    }

    if (args && args.onResult) {
      yield args.onResult({ error: error as Error });
    }
  }
}

function parsePayloadBody<T>(args?: PerformApiArgs<T>, options?: RequestInit & AdditionalOptions) {
  const isMultipart = options?.useMultiPartsFormData || false;

  if (typeof args?.payload === 'string') {
    return args.payload;
  }

  if (!isMultipart) {
    return args && args.payload
      ? JSON.stringify(
        options?.useOriginalPayload
          ? args.payload
          : snakecaseKeys(args.payload, { deep: true }),
      )
      : undefined;
  }

  const data = new FormData();

  return args && args.payload
    ? Object.keys(args.payload).reduce((formData, key) => {
      formData.append(key, args.payload![key] as Blob);
      return formData;
    }, data)
    : undefined;
}

export function* performApiPost<T>(
  path: string,
  args?: PerformApiArgs<T>,
  options?: RequestInit & AdditionalOptions,
) {
  try {
    const backendUrl = getBackendUrl();
    const params = args && args.params;

    const urlSearchParams = createUrlSearchParams(params);

    if (options?.useFullUrlAsPath) {
      urlSearchParams.delete('r');
    }

    const queryString = Array.from(urlSearchParams.entries()).length > 0
      ? `?${urlSearchParams.toString()}`
      : '';

    const url = options?.useFullUrlAsPath
      ? `${path}${queryString}`
      : `${backendUrl}/${path}${queryString}`;

    const body = parsePayloadBody(args, options);

    const data = (
      yield call(
        httpPost,
        url,
        body,
        options,
      )
    ) as Record<string, unknown>;

    const result = (typeof data === 'object' && !options?.useOriginalResponse)
      ? camelcaseKeys(data, { deep: true }) as unknown as T
      : data as unknown as T;

    if (args && args.onResult) {
      yield args.onResult({ result });
    }
  } catch (error) {
    if (error instanceof HttpError && error.statusCode === 401) {
      yield put(notifyHttpUnauthorized());
    }

    if (args && args.onResult) {
      yield args.onResult({ error: error as Error });
    }
  }
}

export function* performApiPut<T>(
  path: string,
  args?: PerformApiArgs<T>,
  options?: RequestInit & AdditionalOptions,
) {
  try {
    const backendUrl = getBackendUrl();
    const params = args && args.params;

    const urlSearchParams = createUrlSearchParams(params);

    if (options?.useFullUrlAsPath) {
      urlSearchParams.delete('r');
    }

    const queryString = Array.from(urlSearchParams.entries()).length > 0
      ? `?${urlSearchParams.toString()}`
      : '';

    const url = options?.useFullUrlAsPath
      ? `${path}${queryString}`
      : `${backendUrl}/${path}${queryString}`;

    const body = parsePayloadBody(args, options);

    const data = (
      yield call(
        httpPut,
        url,
        body,
        options,
      )
    ) as Record<string, unknown>;

    const result = (typeof data === 'object' && !options?.useOriginalResponse)
      ? camelcaseKeys(data, { deep: true }) as unknown as T
      : data as unknown as T;

    if (args && args.onResult) {
      yield args.onResult({ result });
    }
  } catch (error) {
    if (error instanceof HttpError && error.statusCode === 401) {
      yield put(notifyHttpUnauthorized());
    }

    if (args && args.onResult) {
      yield args.onResult({ error: error as Error });
    }
  }
}

export function* performApiDelete<T>(
  path: string,
  args?: PerformApiArgs<T>,
  options?: RequestInit & AdditionalOptions,
) {
  try {
    const backendUrl = getBackendUrl();
    const params = args && args.params;

    const urlSearchParams = createUrlSearchParams(params);

    if (options?.useFullUrlAsPath) {
      urlSearchParams.delete('r');
    }

    const queryString = Array.from(urlSearchParams.entries()).length > 0
      ? `?${urlSearchParams.toString()}`
      : '';

    const url = options?.useFullUrlAsPath
      ? `${path}${queryString}`
      : `${backendUrl}/${path}${queryString}`;

    const data = (yield call(httpDelete, url, options)) as Record<string, unknown>;

    const result = (typeof data === 'object' && !options?.useOriginalResponse)
      ? camelcaseKeys(data, { deep: true }) as unknown as T
      : data as unknown as T;

    if (args && args.onResult) {
      yield args.onResult({ result });
    }
  } catch (error) {
    if (error instanceof HttpError && error.statusCode === 401) {
      yield put(notifyHttpUnauthorized());
    }

    if (error instanceof HttpError && error.statusCode === 403) {
      yield put(notifyNoCredit());
    }

    if (args && args.onResult) {
      yield args.onResult({ error: error as Error });
    }
  }
}

export function subscribeTestStreamEvent(testStream: { data: string }[]) {
  const ongoingTestStreams = [...testStream];
  return eventChannel((emit) => {
    const emitterTask = () => {
      for (let i = 0; i < 100; i += 1) {
        const chunk = ongoingTestStreams.shift();

        if (!chunk) {
          emit({
            type: 'close',
          } as MessageEvent);
          return;
        }

        const base64Data = isBase64Content(String(chunk.data))
          ? String(chunk.data)
          : undefined;

        const buffer = base64Data ? Buffer.from(base64Data, 'base64') : undefined;
        const jsonData = buffer ? buffer.toString('utf8') : '{}';

        const json = JSON.parse(jsonData);
        if (json.path === '/logs/JsonOutputParser/end_time') {
          emit({
            type: 'message',
            data: chunk.data,
          });

          setTimeout(emitterTask, 3000);
          return;
        }

        if ('/tasks' in json) {
          emit({
            type: 'message',
            data: chunk.data,
          });

          setTimeout(emitterTask, 1000);
          return;
        }

        emit({
          type: 'message',
          data: chunk.data,
        });
      }

      setTimeout(emitterTask, 1);
    };

    setTimeout(() => {
      emitterTask();
    }, 1);

    return () => {
      emit(END);
    };
  });
}

export function subscribeServerEvent(url: string, testStream?: { data: string }[]) {
  if (testStream) {
    const channel = subscribeTestStreamEvent(testStream);
    return {
      channel,
      eventSource: undefined,
    };
  }

  const eventSource = new EventSource(url);

  const channel = eventChannel((emit) => {
    const onOpen = (ev: Event) => {
      emit(ev);
    };

    const onMessage = (ev: MessageEvent) => {
      emit(ev);
    };

    const onError = (ev: Event) => {
      eventSource.close();
      emit(ev);
    };

    eventSource.addEventListener('open', onOpen);
    eventSource.addEventListener('message', onMessage);
    eventSource.addEventListener('error', onError);

    return () => {
      eventSource.removeEventListener('open', onOpen);
      eventSource.removeEventListener('message', onMessage);
      eventSource.removeEventListener('error', onError);
      eventSource.close();
      emit(END);
    };
  });

  return {
    channel,
    eventSource,
  };
}

export function createIntervalChannel(interval: number) {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      emitter({ type: 'INTERVAL' });
    }, interval);

    return () => {
      clearInterval(iv);
    };
  });
}
