import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { ReactComponent as DefaultCompanyIcon } from '../../../../assets/svg/defaultCompanyIcon.svg';
import { ReactComponent as CaretRightIcon } from '../../../../assets/svg/caretRightIcon.svg';
import { ReactComponent as CaretLeftIcon } from '../../../../assets/svg/caretLeftIcon.svg';
import { useCommonClasses } from '../../../../theme/commonStyles';
import { CompanyItem, CompanyItemsGroup } from '../types';
import CompanyList from '../companyList';
import { PropsFromRedux } from './container';
import styles from './CompanyGroupResults.styles';

interface Props extends PropsFromRedux {
  onCompanyItemClick?: (companyItem: CompanyItem) => void;
  onCompanySubReportClick?: (companyItem: CompanyItem, report: string) => void;
  onShowMore?: () => void;
  onShowLess?: () => void;
}

const useStyles = makeStyles(styles);

const CompanyGroupResults: React.FC<Props> = ({
  companyResultGroup,
  companyResultAdditionals,
  onCompanyItemClick,
  onCompanySubReportClick,
  onShowMore,
  onShowLess,
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const [showMore, setShowMore] = useState<string>();

  const resultGroup = companyResultGroup || (
    typeof companyResultAdditionals === 'object'
      ? companyResultAdditionals as CompanyItemsGroup
      : undefined
  );

  const renderCompanyItems = (groupName: string, companies?: CompanyItem[]) => {
    if (showMore && showMore !== groupName) {
      return <div />;
    }

    return (companies || []).length > 0 && (
      <Box marginTop={showMore ? 0 : 1}>
        <Box className={classes.sectionHeader}>
          <Typography
            variant="h6"
            className={
              classNames(
                classes.sectionHeaderText,
                'secondary',
              )
            }
          >
            {groupName}
          </Typography>
          <Divider
            className={
              classNames(
                classes.sectionHeaderDivider,
                'secondary',
              )
            }
          />
          <Button
            variant="outlined"
            size="small"
            className={
              classNames(
                classes.sectionHeaderButton,
                'secondary',
              )
            }
            onClick={() => {
              setShowMore(!showMore ? groupName : undefined);
              if (!showMore && onShowMore) {
                onShowMore();
              }

              if (showMore && onShowLess) {
                onShowLess();
              }
            }}
          >
            {showMore && (
              <Box marginRight={1} display="flex" alignItems="center">
                <CaretLeftIcon />
              </Box>
            )}
            Show {!showMore ? 'more' : 'less'}
            {!showMore && (
              <Box marginLeft={1} display="flex" alignItems="center">
                <CaretRightIcon />
              </Box>
            )}
          </Button>
        </Box>
        {!showMore && (
          <Box className={classes.companyItemsContainer}>
            {(companies || []).slice(0, 8).map((company) => (
              <div
                key={company.id}
                className={
                  classNames(
                    classes.companyItem,
                  )
                }
                onClick={() => onCompanyItemClick && onCompanyItemClick(company)}
              >
                {company.logo?.at(0) ? (
                  <Box
                    className={
                      classNames(
                        commonCss.boxes.imageBox,
                        'logo',
                      )
                    }
                    component="object"
                    data={company.logo?.at(0)}
                    type="image/png"
                    width={75}
                    height={75}
                  >
                    <DefaultCompanyIcon />
                  </Box>
                ) : (
                  <Box
                    className={
                      classNames(
                        commonCss.boxes.imageBox,
                        'logo',
                      )
                    }
                    width={75}
                    height={75}
                  >
                    <DefaultCompanyIcon />
                  </Box>
                )}
                <Box marginTop={1} maxWidth={80}>
                  <Typography variant="body2" textAlign="center" className="name">
                    {company.name}
                  </Typography>
                </Box>
              </div>
            ))}
          </Box>
        )}
        {showMore && (
          <CompanyList
            companyItems={companies}
            onDataClick={onCompanyItemClick}
            onSubReportClick={onCompanySubReportClick}
          />
        )}
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <Container>
        <Box
          className={
            classNames(
              classes.resultPanel,
            )
          }
          marginTop={0}
        >
          <Box className={classes.resultBody}>
            <motion.div
              initial={{
                x: -100,
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0,
                },
              }}
            >
              {renderCompanyItems('Most Similar', resultGroup?.score)}
            </motion.div>
            <motion.div
              initial={{
                x: -100,
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.5,
                },
              }}
            >
              {renderCompanyItems('Most Popular', resultGroup?.popular)}
            </motion.div>
            <motion.div
              initial={{
                x: -100,
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 1,
                },
              }}
            >
              {renderCompanyItems('Most Funded', resultGroup?.founded)}
            </motion.div>
            <motion.div
              initial={{
                x: -100,
                opacity: 0,
              }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 1.5,
                },
              }}
            >
              {renderCompanyItems('Most Employees', resultGroup?.size)}
            </motion.div>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default CompanyGroupResults;
