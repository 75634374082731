import React from 'react';
import { IconButton } from '@mui/material';

import { ReactComponent as DownIcon } from '../../assets/svg/arrowDownIcon.svg';
import { ReactComponent as UpIcon } from '../../assets/svg/arrowUpIcon.svg';
import { isNullOrUndefined } from './dataUtils';

export interface GetKeywordFieldPropsArgs {
  keywords?: unknown[],
  maxKeywords?: number,
  minKeywordLength?: number,
}

export interface GetUpDownEndAdornmentArgs {
  onUp?: () => void;
  onDown?: () => void;
  minValue?: number;
  maxValue?: number;
  currentValue?: number;
  dense?: boolean;
}

export interface GetNumberFieldPropsArgs {
  onUp?: () => void;
  onDown?: () => void;
  maxLength?: number;
  dense?: boolean;
}

export const getUpDownEndAdornment = ({
  onUp,
  onDown,
  minValue,
  maxValue,
  currentValue,
  dense,
}: GetUpDownEndAdornmentArgs) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      marginRight: dense ? -5 : undefined,
    }}
  >
    <IconButton
      size="small"
      style={{
        padding: 0,
        height: 12,
        marginBottom: dense ? -3 : undefined,
      }}
      onClick={() => onUp && onUp()}
      disabled={!isNullOrUndefined(currentValue)
        && !isNullOrUndefined(maxValue)
        && currentValue! >= maxValue!
      }
    >
      <UpIcon fontSize="small" />
    </IconButton>
    <IconButton size="small"
      style={{
        padding: 0,
        height: 12,
        marginBottom: dense ? -3 : undefined,
      }}
      onClick={() => onDown && onDown()}
      disabled={!isNullOrUndefined(currentValue)
        && !isNullOrUndefined(minValue)
        && currentValue! <= minValue!
      }
    >
      <DownIcon fontSize="small" />
    </IconButton>
  </div>
);

export const getNumberFieldProps = ({
  onUp,
  onDown,
  maxLength,
}: GetNumberFieldPropsArgs) => ({
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;
    const input = (event.target as HTMLInputElement);
    const currentText = input.value;
    const selectLength = Math.abs(
      (input.selectionEnd || 0) - (input.selectionStart || 0),
    );

    if (key === 'Backspace'
      || key === 'Tab'
      || key === 'ArrowLeft'
      || key === 'ArrowRight'
      || key === 'ArrowUp'
      || key === 'ArrowDown'
    ) {
      return;
    }

    if (!/[0-9]/g.test(key)) {
      event.stopPropagation();
      event.preventDefault();
    }

    if ((currentText.length - selectLength) >= (maxLength || Number.MAX_SAFE_INTEGER)) {
      event.stopPropagation();
      event.preventDefault();
    }
  },
  onKeyUp: (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;
    if (key === 'ArrowUp' && onUp) {
      onUp();
    }

    if (key === 'ArrowDown' && onDown) {
      onDown();
    }
  },
});

export const getKeywordsFieldProps = ({
  keywords,
  maxKeywords,
  minKeywordLength,
}: GetKeywordFieldPropsArgs) => ({
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
    const inputValue = (event.target as HTMLInputElement).value;
    const separatorKey = event.key === ',';
    if (separatorKey && inputValue.trim().length < (minKeywordLength || 0)) {
      event.stopPropagation();
      event.preventDefault();
    }

    if ((keywords?.length || 0) >= (maxKeywords || Number.MAX_SAFE_INTEGER)) {
      event.stopPropagation();
      event.preventDefault();
    }
  },
});
