import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { v4 } from 'uuid';

import { Buffer } from 'buffer';

import config from '../../../config';

import { performApiGet } from '../../../core/utils/sagaUtils';
import { ApplicationState } from '../../types';
import {
  setNotificationDisplayed,
  triggerNotification,
} from '../notifier/actions';

import { requestUserExtra } from '../session/actions';
import { NotificationInfo } from '../notifier/types';
import { User } from '../session/types';

import {
  requestCompanyData,
  requestCompetitorsData,
  requestDistributionsData,
  requestInvestorData,
  requestMarketCountries,
  requestMarketData,
  setCompanyData,
  setCompetitorsData,
  setDistributionsData,
  setInvestorData,
  setMarketCountries,
  setMarketData,
} from './actions';

import {
  CompanyData,
  CompanyReportInfo,
  CompetitorsReportInfo,
  DistributionsData,
  InitializeReportArgs,
  InvestorReportInfo,
  MarketCountryInfo,
  MarketReportInfo,
  ReportActions,
  ReportState,
  RequestCompanyDataArgs,
  RequestCompetitorsDataArgs,
  RequestDistributionsDataArgs,
  RequestInvestorDataArgs,
  RequestMarketDataArgs,
  SelectMarketCountryArgs,
} from './types';
import { getCompanyDataApiUrl } from './reportUtils';

function* initializeFlow() {
  yield takeEvery(
    ReportActions.REPORT_INITIALIZE,
    function* _(act: PayloadAction<InitializeReportArgs>) {
      const reportState: ReportState = (
        yield select((state: ApplicationState) => state.report)
      );

      const user: User = (
        yield select((state: ApplicationState) => state.session.user)
      );

      yield put(requestUserExtra({
        user: String(user.pk),
      }));

      yield put(requestCompanyData({ id: act.payload.companyId }));
      yield put(requestInvestorData({ formId: act.payload.companyId }));
      yield put(requestDistributionsData({ model: 'Model-C1' }));
      yield put(requestDistributionsData({ model: 'Model-C4' }));

      if (!reportState.networkStates.marketCountriesRequest.isRequesting) {
        yield put(requestMarketCountries());
      }

      yield put(requestCompetitorsData({ id: act.payload.companyId }));
    },
  );
}

function* requestCompanyDataFlow() {
  yield takeEvery(
    ReportActions.COMPANY_DATA_REQUEST,
    function* _(act: PayloadAction<RequestCompanyDataArgs>) {
      const apiUrl = getCompanyDataApiUrl();
      const search = new URLSearchParams(window.location.search);
      const cinfo = search.get('cinfo');
      const companyDataJson = cinfo && Buffer.from(cinfo, 'base64').toString('utf8');
      const companyData: CompanyData = companyDataJson && JSON.parse(companyDataJson);

      if (companyData) {
        yield put(setCompanyData({
          companyReport: {
            companyData,
          } as unknown as CompanyReportInfo,
        }));

        return;
      }

      yield fork(() => performApiGet<CompanyReportInfo>(
        `${apiUrl}/${act.payload.id}/`,
        {
          onResult: ({ result, error }) => put(setCompanyData({
            companyReport: result,
            error,
          })),
        },
      ));
    },
  );
}

function* requestInvestorDataFlow() {
  yield takeEvery(
    ReportActions.INVESTOR_DATA_REQUEST,
    function* _(act: PayloadAction<RequestInvestorDataArgs>) {
      const {
        formId,
        amountRaised,
        investorExp,
        investorType,
        isUsInvestor,
      } = act.payload;

      const key = v4();

      const notificationInfo = {
        key,
        type: 'investmentUpdate',
        isDisplayed: false,
        payload: amountRaised,
      } as NotificationInfo<unknown>;

      yield put(triggerNotification({
        notification: notificationInfo,
      }));

      const search = new URLSearchParams(window.location.search);
      const isForm = !!search.get('m');

      yield fork(() => performApiGet<InvestorReportInfo>(
        `${config.api.getInvestorReportData}/${isForm ? 'form/' : ''}${formId || ''}/`,
        {
          params: {
            amount_raised: amountRaised ? String(amountRaised) : '',
            investor_exp: investorExp ? String(investorExp) : '1',
            investor_type: investorType ? String(investorType) : '1',
            is_us_investor: isUsInvestor !== undefined ? String(isUsInvestor) : '1',
          },
          onResult: function* __({ result, error }) {
            yield put(setInvestorData({
              investorReport: result,
              error,
            }));

            yield put(setNotificationDisplayed({
              key: notificationInfo.key,
            }));
          },
        },
      ));
    },
  );
}

function* requestDistributionsDataFlow() {
  yield takeEvery(
    ReportActions.DISTRIBUITIONS_DATA_REQUEST,
    function* _(act: PayloadAction<RequestDistributionsDataArgs>) {
      yield fork(() => performApiGet<DistributionsData>(
        `${config.api.getDistributionsData}/${act.payload.model}/`,
        {
          onResult: ({ result, error }) => put(setDistributionsData({
            model: act.payload.model,
            data: result,
            error,
          })),
        },
      ));
    },
  );
}

function* requestMarketDataFlow() {
  yield takeLatest(
    ReportActions.MARKET_DATA_REQUEST,
    function* _(act: PayloadAction<RequestMarketDataArgs>) {
      yield fork(() => performApiGet<MarketReportInfo>(
        config.api.getMarketReportData,
        {
          params: {
            desc: act.payload.desc,
            country: act.payload.country,
          },
          onResult: ({ result, error }) => put(setMarketData({
            marketReport: result,
            error,
          })),
        },
      ));
    },
  );
}

function* requestMarketCountriesDataFlow() {
  yield takeLatest(
    ReportActions.MARKET_COUNTRIES_REQUEST,
    function* _() {
      yield fork(() => performApiGet<MarketCountryInfo[]>(
        config.api.getMarketCountriesData,
        {
          onResult: ({ result, error }) => put(setMarketCountries({
            marketCountryInfos: result,
            error,
          })),
        },
      ));
    },
  );
}

function* requestCompetitorsDataFlow() {
  yield takeEvery(
    ReportActions.COMPETITORS_DATA_REQUEST,
    function* _(act: PayloadAction<RequestCompetitorsDataArgs>) {
      const search = new URLSearchParams(window.location.search);
      const isForm = !!search.get('m');

      yield fork(() => performApiGet<CompetitorsReportInfo>(
        `${config.api.getCompetitorsReportData}/${isForm ? 'form/' : ''}${act.payload.id}/`,
        {
          onResult: ({ result, error }) => put(setCompetitorsData({
            competitorsReport: result,
            error,
          })),
        },
      ));
    },
  );
}

function* investingParamsUpdatedFlow() {
  yield takeEvery(
    ReportActions.INVESTING_PARAMS_UPDATE,
    function* _() {
      const report = (
        yield select((state: ApplicationState) => state.report)
      ) as ReportState;

      const { formId, investingParams } = report;
      const {
        amount,
        investorExp,
        investorType,
        geography,
      } = investingParams || {};

      yield put(requestInvestorData({
        formId,
        amountRaised: amount,
        investorExp,
        investorType,
        isUsInvestor: geography,
      }));
    },
  );
}

function* marketCountrySelectedFlow() {
  yield takeEvery(
    ReportActions.MARKET_COUNTRY_SELECT,
    function* _(act: PayloadAction<SelectMarketCountryArgs>) {
      const searchParams = new URLSearchParams(window.location.search);
      const base64Path = searchParams.get('desc');
      const desc = act.payload.desc
        || (base64Path && Buffer.from(base64Path, 'base64').toString('utf8'));

      if (!desc) {
        return;
      }

      yield put(requestMarketData({
        desc,
        country: act.payload.marketCountry?.countryCode,
      }));
    },
  );
}

export default function* reportSaga() {
  yield all([
    initializeFlow(),
    requestCompanyDataFlow(),
    requestInvestorDataFlow(),
    requestDistributionsDataFlow(),
    investingParamsUpdatedFlow(),
    requestMarketDataFlow(),
    requestMarketCountriesDataFlow(),
    marketCountrySelectedFlow(),
    requestCompetitorsDataFlow(),
  ]);
}
