import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';
import {
  requestCompetitorsData,
  setLandscapeStatus,
  setLandscapeIsFunded,
  setCompetitorHighlight,
  selectCompetitor,
} from '../actions';

import LandscapeReportDetails from './LandscapeReportDetails';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.router,
    ...state.report,
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    requestCompetitorsData,
    setLandscapeStatus,
    setLandscapeIsFunded,
    setCompetitorHighlight,
    selectCompetitor,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LandscapeReportDetails);
