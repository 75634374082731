import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import CountUp from 'react-countup';

import { GroupImportanceData } from '../types';
import { useCommonClasses } from '../../../../theme/commonStyles';
import { isNullOrUndefined } from '../../../../core/utils/dataUtils';
import { easeOut } from '../../../../core/utils/animationUtils';
import styles from './FeatureImportance.styles';

interface ChartItem {
  title: string;
  colorClass: string;
  chartWidth: number;
  value: number;
  dataInfoKey: string;
}

type Props = {
  groupImportance?: GroupImportanceData;
  selectedDataInfoKeys?: string[];
  onDataMouseEnter?: (dataInfoKey: string) => void;
  onDataMouseLeave?: () => void;
  onDataMouseClick?: (dataInfoKey: string) => void;
};

const useStyles = makeStyles(styles);

const FeatureImportance: React.FC<Props> = ({
  groupImportance,
  selectedDataInfoKeys,
  onDataMouseEnter,
  onDataMouseLeave,
  onDataMouseClick,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();

  const totalPercent = [
    groupImportance?.team || 0,
    groupImportance?.experience || 0,
    groupImportance?.education || 0,
    groupImportance?.company || 0,
    groupImportance?.investor || 0,
  ].reduce((sum, value) => sum + value, 0) || 100;

  const isInvestorFeature = !!groupImportance?.investor;
  const prefix = isInvestorFeature ? 'i' : 'c';
  const [hoveringChartItem, setHoveringChartItem] = useState<ChartItem>();

  const chartItems = [{
    title: 'Team',
    colorClass: 'primary',
    chartWidth: ((groupImportance?.team || 0) / totalPercent) * 100,
    value: groupImportance?.team || 0,
    dataInfoKey: `${prefix}7Team`,
  }, {
    title: 'Experience',
    colorClass: 'tertiary',
    chartWidth: ((groupImportance?.experience || 0) / totalPercent) * 100,
    value: groupImportance?.experience || 0,
    dataInfoKey: `${prefix}8Experience`,
  }, {
    title: 'Education',
    colorClass: 'quaternary',
    chartWidth: ((groupImportance?.education || 0) / totalPercent) * 100,
    value: groupImportance?.education || 0,
    dataInfoKey: `${prefix}9Education`,
  }, {
    title: 'Company',
    colorClass: 'secondary',
    chartWidth: ((groupImportance?.company || 0) / totalPercent) * 100,
    value: groupImportance?.company || 0,
    dataInfoKey: `${prefix}10Company`,
  }, {
    title: groupImportance?.investor ? 'Investor' : '',
    colorClass: 'investing',
    chartWidth: ((groupImportance?.investor || 0) / totalPercent) * 100,
    value: groupImportance?.investor,
    dataInfoKey: 'i32Investor',
  }].filter((item): item is ChartItem => !!item);

  const handleMouseEnter = (chartItem: ChartItem) => {
    if (onDataMouseEnter) {
      onDataMouseEnter(chartItem.dataInfoKey);
    }

    setHoveringChartItem(chartItem);
  };

  const handleMouseLeave = () => {
    if (onDataMouseLeave) {
      onDataMouseLeave();
    }

    setHoveringChartItem(undefined);
  };

  const handleMouseClick = (chartItem: ChartItem) => {
    if (onDataMouseClick) {
      onDataMouseClick(chartItem.dataInfoKey);
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        Feature Importance
      </Typography>
      <div className={classes.chartContainer}>
        {chartItems.map((chartItem, index) => (
          <React.Fragment key={chartItem.title}>
            <div
              className={
                classNames(
                  commonCss.charts.horizontalBarPinLine,
                  chartItem.colorClass,
                  index % 2 !== 0 && 'long',
                )
              }
              style={{
                display: !isNullOrUndefined(chartItem.value) ? 'flex' : 'none',
              }}
            >
              <Typography
                variant="body1"
                className={
                  classNames(
                    commonCss.charts.dataTitle,
                    classes.percentLabel,
                    chartItem.colorClass,
                  )
                }
              >
                <CountUp
                  end={chartItem.value || 0}
                  duration={1}
                  decimals={0}
                  useEasing
                  easingFn={easeOut}
                />
                %
              </Typography>
              <Typography
                variant="body1"
                className={
                  classNames(
                    commonCss.charts.dataTitle,
                    classes.itemTitle,
                    chartItem.colorClass,
                  )
                }
              >
                {
                  !isNullOrUndefined(chartItem.value)
                    ? chartItem.title
                    : ''
                }
              </Typography>
            </div>
            <div
              className={
                classNames(
                  classes.barSection,
                  selectedDataInfoKeys?.includes(chartItem.dataInfoKey) && 'active',
                )
              }
              style={{
                width: `${chartItem.chartWidth}%`,
              }}
              onClick={() => handleMouseClick(chartItem)}
              onMouseEnter={() => handleMouseEnter(chartItem)}
              onMouseLeave={() => handleMouseLeave()}
            >
              <div
                className={
                  classNames(
                    classes.bar,
                    commonCss.charts.horizontalBarChart,
                    chartItem.colorClass,
                    'collaped',
                    hoveringChartItem?.title === chartItem.title && 'contrast',
                    selectedDataInfoKeys?.includes(chartItem.dataInfoKey) && 'contrast',
                  )
                }
                style={{
                  width: '100%',
                }}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default FeatureImportance;
