/* eslint-disable @typescript-eslint/quotes */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import { v4 } from 'uuid';
import { AnimatePresence, motion } from 'framer-motion';

import {
  Add as AddIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

import { useResizeDetector } from 'react-resize-detector';

import { ReactComponent as FullLogo } from '../../../../assets/svg/fullLogo.svg';
import { ReactComponent as DoubleRightArrowsIcon } from '../../../../assets/svg/doubleRightArrowsIcon.svg';
import { ReactComponent as RelationChartIcon } from '../../../../assets/svg/relationChartIcon.svg';
import { ReactComponent as SettingsIcon } from '../../../../assets/svg/settingsIcon.svg';
import { ReactComponent as BrowserIcon } from '../../../../assets/svg/browserIcon.svg';
import { validateEmail } from '../../../../core/utils/validationUtils';
import { useCommonClasses } from '../../../../theme/commonStyles';
import useCommonProps from '../../../../theme/commonProps';
import { otherColorSets, otherContrastColorSets } from '../../../../theme/palette';
import Search from '../../search';
import GptChatView from '../../gpt/gptChatView';
import PromptInfoPopup from '../../gpt/promptInfoPopup';
import {
  ChatPromptItem,
  GptChatItem,
} from '../../gpt/types';

import RelationChart from '../../relationChart';

import { PropsFromRedux } from './container';
import styles from './GptPortal.styles';
import SettingsView from '../../gpt/settingsView';
import ChatHistory from '../chatHistory';
import PromptList from '../promptList';
import BrowserView from '../../gpt/browserView';
import PdfView from '../../gpt/pdfView';

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);

const GptPortal: React.FC<Props> = ({
  keyword: searchKeyword,
  setSearchKeyword,
  isGptSearch,
  toggleGptSearch,
  openFeedbackForm,
  triggerNotification,
  openContactForm,
  requestSubscribe,
  isGptChat,
  requestChatClean,
  chats,
  requestChatHistory,
  requestChatPrompts,
  requestSiteExamples,
  chatStreaming,
  chatState,
  user,
  networkStates: {
    chatItemChartUpdateRequest,
    jinaContentRequest,
    localSourceRequest,
  },
  isSideBarOpen,
  setSideBarOpen,
  relationData,
  isSideBarClosedOnce,
  selectedSideBarTab,
  setSelectedSideBarTab,
  jinaContent,
  jinaContentFormat,
  localSource,
  sourceCitation,
  requestJinaContent,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();
  const commonProps = useCommonProps();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const [subscribeActive, setSubscribeActive] = useState<boolean>(false);
  const [subscribeEmail, setSubscribeEmail] = useState<string>('');
  const [isShaking, setShaking] = useState(false);
  const [chatFilter, setChatFilter] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>('chats');
  const [isPromptPopupOpen, setPromptPopupOpen] = useState(false);
  const [editingPromptItem, setEditingPromptItem] = useState<ChatPromptItem>();
  const [hideChatView, setHideChatView] = useState(false);
  const [isGraphSideBarSelectedOnce, setGraphSideBarSelectedOnce] = useState(false);
  const [isSidePanelReady, setIsSidePanelReady] = useState(false);

  const gptChatPanelRef = useRef<HTMLDivElement>(null);
  const prevChatItemsRef = useRef<GptChatItem[]>();
  const { ref: chatContentRef } = useResizeDetector();

  const hasNoChats = (chats || []).length === 0;
  const isSidePanelCollapse = !isSideBarOpen;
  const hasRelationData = (relationData?.nodes || []).length > 0;

  useEffect(() => {
    requestChatHistory();
    if (user?.pk) {
      requestChatPrompts({ user: user.pk });
    }
    requestSiteExamples();
  }, []);

  useEffect(() => {
    if (!isSideBarOpen) {
      setIsSidePanelReady(false);
    } else {
      setTimeout(() => {
        setIsSidePanelReady(true);
      }, 500);
    }
  }, [isSideBarOpen]);

  useEffect(() => {
    if (!chatState) {
      return;
    }

    if (chatState === 'reset') {
      setTimeout(() => {
        if (gptChatPanelRef.current) {
          gptChatPanelRef.current.scrollTo({
            top: 0,
          });
        }
      }, 10);

      return;
    }

    if (chatState === 'followUp') {
      setTimeout(() => {
        if (gptChatPanelRef.current) {
          gptChatPanelRef.current.scrollTo({
            top: gptChatPanelRef.current.scrollHeight,
          });
        }
      }, 10);

      return;
    }

    const currentChats = chats;
    const prevChats = prevChatItemsRef.current || [];
    const prevChatsWithoutFlag = prevChats.map((chat) => ({
      ...chat,
      isShowingSteps: undefined,
    }));

    const prevSignature = JSON.stringify(prevChatsWithoutFlag);

    const currentChatsWithoutFlag = currentChats.map((chat) => ({
      ...chat,
      isShowingSteps: undefined,
    }));

    const currentSignature = JSON.stringify(currentChatsWithoutFlag);

    if (prevSignature === currentSignature) {
      return;
    }

    prevChatItemsRef.current = currentChats;

    if (gptChatPanelRef.current && chatState !== 'editing') {
      gptChatPanelRef.current.scrollTo({
        top: gptChatPanelRef.current.scrollHeight,
      });
    }
  }, [chats, chatState]);

  useEffect(() => {
    if (gptChatPanelRef.current) {
      gptChatPanelRef.current.scrollTo({
        top: gptChatPanelRef.current.scrollHeight,
      });
    }
  }, [chatStreaming]);

  useEffect(() => {
    const scrollTop = gptChatPanelRef.current?.scrollTop;

    setTimeout(() => {
      gptChatPanelRef.current?.scrollTo({
        top: scrollTop,
      });
    });
  }, [chatItemChartUpdateRequest]);

  useEffect(() => {
    if (hasNoChats) {
      setSelectedSideBarTab({ tabName: 'settings' });
    }
  }, [chats]);

  useEffect(() => {
    if (hasRelationData && !isSideBarClosedOnce) {
      setSideBarOpen({ isOpen: true });

      if (!isGraphSideBarSelectedOnce) {
        setSelectedSideBarTab({ tabName: 'graph' });
        setGraphSideBarSelectedOnce(true);
      }
    }

    if (!hasRelationData && selectedSideBarTab === 'graph') {
      setSelectedSideBarTab({ tabName: 'settings' });
    }

    if (!sourceCitation && (
      selectedSideBarTab === 'browser'
      || selectedSideBarTab === 'pdf'
    )) {
      setSelectedSideBarTab({ tabName: 'settings' });
    }
  }, [relationData]);

  const validateAndSubscribe = () => {
    if (!subscribeEmail) {
      triggerNotification({
        notification: {
          key: v4(),
          type: 'general',
          payload: 'Please enter email address',
          isDisplayed: false,
          delay: 5000,
        },
      });

      return;
    }

    if (!validateEmail(subscribeEmail)) {
      setShaking(true);
      const timeOutRef = setTimeout(() => {
        setShaking(false);
        clearTimeout(timeOutRef);
      }, 500);

      return;
    }

    requestSubscribe({
      email: subscribeEmail,
    });

    setSubscribeActive(false);
    setSubscribeEmail('');
  };

  const getSearchLabel = () => {
    if (selectedTab === 'chats') {
      return 'Search in chat messages';
    }

    if (selectedTab === 'prompts') {
      return 'Search in prompts';
    }

    if (selectedTab === 'media') {
      return 'Search in media';
    }

    return '';
  };

  const toggleSidePanel = () => {
    setSideBarOpen({
      isOpen: !isSideBarOpen,
    });

    if (isSmallScreen) {
      setHideChatView(true);
      setTimeout(() => {
        setHideChatView(false);
      }, 0);
    }
  };

  return (
    <div className={classes.root}>
      <Box
        component="div"
        className={
          classNames(
            classes.logoBox,
            searchKeyword && 'collapsed',
            (isGptSearch || isGptChat) && 'collapsed',
            isGptChat && 'smallLogo',
          )
        }
        onClick={() => {
          if (isGptSearch) {
            toggleGptSearch({ isGptSearch: false });
          } else {
            setSearchKeyword({
              keyword: '',
            });
          }
        }}
      >
        <FullLogo />
      </Box>
      <div
        className={
          classNames(
            classes.searchContainer,
            isGptChat && 'chatMode',
          )
        }
      >
        <Search
          useGptPortal
          minimized={isGptChat}
        />
      </div>
      {!searchKeyword && !isGptSearch && !isGptChat && (
        <div className={classes.feedbackBar}>
          <Link
            component="button"
            color="secondary"
            variant="body2"
            className={classes.feedbackLink}
            onClick={() => openFeedbackForm()}
          >
            Leave us a Feedback
          </Link>
          <Box marginTop={3} display="flex" alignItems="center">
            <Box
              component="div"
              className={classes.subscribeContainer}
              onMouseEnter={() => setSubscribeActive(true)}
              onMouseLeave={() => setSubscribeActive(false)}
            >
              <TextField
                {...commonProps.textField({
                  label: 'Enter email for updates',
                  color: 'info',
                  required: true,
                })}
                size="small"
                className={
                  classNames(
                    classes.subscribeInput,
                    subscribeActive && 'active',
                    isShaking && classes.shaking,
                  )
                }
                style={{
                  position: 'absolute',
                }}
                value={subscribeEmail}
                error={!subscribeEmail || !validateEmail(subscribeEmail)}
                helperText={(subscribeEmail && !validateEmail(subscribeEmail)) && 'Please enter a valid email address'}
                onChange={(event) => setSubscribeEmail(event.target.value.trim())}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    validateAndSubscribe();
                  }
                }}
              />
              <Button
                variant="contained"
                color="info"
                classes={commonCss.buttons.roundButton}
                className={
                  classNames(
                    classes.subscribeButton,
                    'info',
                    subscribeActive && 'active',
                  )
                }
                onClick={() => validateAndSubscribe()}
              >
                Subscribe
              </Button>
            </Box>
            <Box marginLeft={1}>
              <Typography className={classes.contactUsLink}>
                or
              </Typography>
              <Link
                component="button"
                className={classes.contactUsLink}
                onClick={() => openContactForm()}
              >
                Contact us
              </Link>
            </Box>
          </Box>
        </div>
      )}
      <AnimatePresence>
        {isGptChat && (
          <Container className={classes.gptContainer}>
            <motion.div
              className={classes.gptMenuPanel}
              initial={{
                y: 50,
                opacity: 0,
              }}
              animate={{
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.5,
                },
              }}
              exit={{
                y: 50,
                opacity: 0,
              }}
            >
              <Tabs
                className={classes.gptTabs}
                classes={{
                  ...commonCss.tabs,
                  indicator: classNames(
                    commonCss.tabs.indicator,
                    'tertiary',
                  ),
                }}
                value={selectedTab}
                textColor="inherit"
                onChange={(_, value) => setSelectedTab(value as string)}
              >
                <Tab label="Chats" value="chats" />
                <Tab label="Prompts" value="prompts" />
              </Tabs>
              <Box marginTop={2} flex={1} display="flex" flexDirection="column">
                <Button
                  className="tertiary"
                  classes={commonCss.buttons.leafButton}
                  fullWidth
                  onClick={() => {
                    if (selectedTab === 'chats') {
                      requestChatClean();
                    }

                    if (selectedTab === 'prompts') {
                      setPromptPopupOpen(true);
                    }
                  }}
                >
                  <AddIcon fontSize="small" />
                  <Box marginLeft={0.5} alignItems="center" textTransform="capitalize">
                    New {(
                      selectedTab.endsWith('s')
                        ? selectedTab.substring(0, selectedTab.length - 1)
                        : selectedTab
                      )}
                  </Box>
                </Button>
                <Box marginTop={1}>
                  <TextField
                    label={getSearchLabel()}
                    fullWidth
                    variant="standard"
                    sx={{
                      '& label.Mui-focused': {
                        top: 0,
                        color: otherContrastColorSets[2],
                      },
                      '& label.MuiInputLabel-root': {
                        color: theme.palette.grey[400],
                      },
                      '& label.MuiInputLabel-shrink': {
                        color: otherContrastColorSets[2],
                        '& .optional-text': {
                          display: 'none',
                        },
                        '&+.MuiInputBase-root .optional-text': {
                          display: 'none',
                        },
                      },
                      '& .MuiInput-root:after': {
                        borderBottomColor: otherColorSets[2],
                      },
                    }}
                    value={chatFilter}
                    onChange={(event) => setChatFilter(event.target.value)}
                    InputProps={{
                      endAdornment: chatFilter ? (
                        <IconButton
                          size="small"
                          onClick={() => setChatFilter('')}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      ) : undefined,
                    }}
                  />
                </Box>
                {selectedTab === 'chats' && (
                  <Box marginTop={2} className={classes.tabContentContainer}>
                    <ChatHistory chatFilter={chatFilter} />
                  </Box>
                )}
                {selectedTab === 'prompts' && (
                  <Box marginTop={2} className={classes.tabContentContainer}>
                    <PromptList
                      chatFilter={chatFilter}
                      onEditItem={(item) => setEditingPromptItem(item)}
                    />
                  </Box>
                )}
              </Box>
            </motion.div>
            <motion.div
              className={
                classNames(
                  classes.gptChatPanel,
                  isSidePanelCollapse && 'sidePanelCollapsed',
                )
              }
              ref={gptChatPanelRef}
              initial={{
                y: 50,
                opacity: 0,
              }}
              animate={{
                y: 0,
                opacity: 1,
                transition: {
                  delay: 1,
                },
              }}
              exit={{
                y: 50,
                opacity: 0,
              }}
            >
              <div ref={chatContentRef} style={{ display: 'flex', flex: 1 }}>
                <Container
                  maxWidth="md"
                  className={
                    classNames(
                      classes.gptChatViewWrapper,
                      chats?.length > 0 && !isSidePanelCollapse && 'chatSelected',
                      isSidePanelCollapse ? 'sidePanelCollapsed' : undefined,
                      hasNoChats && 'noChats',
                    )
                  }
                >
                  {!hideChatView && (
                    <GptChatView />
                  )}
                </Container>
              </div>
            </motion.div>
            <div
              className={classNames(
                classes.sidePanel,
                hasNoChats && 'noChats',
                selectedSideBarTab === 'browser' && 'browserView',
                selectedSideBarTab === 'pdf' && 'pdfView',
              )}
              style={{
                width: (isSidePanelCollapse) ? 0 : undefined,
              }}
            >
              {(!isSidePanelCollapse) && (
                <Box
                  className={
                    classNames(
                      classes.sidePanelTabContainer,
                    )
                  }
                >
                  <Tabs
                    className={classes.sidePanelTab}
                    classes={{
                      ...commonCss.tabs,
                      indicator: classNames(
                        commonCss.tabs.indicator,
                        'tertiary',
                      ),
                    }}
                    textColor="inherit"
                    value={selectedSideBarTab}
                  >
                    {!hasNoChats && hasRelationData && (
                      <Tab
                        label="Graph"
                        value="graph"
                        icon={<RelationChartIcon height={20} width={20} />}
                        iconPosition="start"
                        onClick={() => setSelectedSideBarTab({ tabName: 'graph' })}
                      />
                    )}
                    {sourceCitation?.url && (
                      <Tab
                        label="Browser"
                        value="browser"
                        icon={<BrowserIcon height={20} width={20} />}
                        iconPosition="start"
                        onClick={() => setSelectedSideBarTab({ tabName: 'browser' })}
                      />
                    )}
                    {sourceCitation?.sourceId && (
                      <Tab
                        label="PDF"
                        value="pdf"
                        icon={<BrowserIcon height={20} width={20} />}
                        iconPosition="start"
                        onClick={() => setSelectedSideBarTab({ tabName: 'pdf' })}
                      />
                    )}
                    <Tab
                      label="Settings"
                      value="settings"
                      icon={<SettingsIcon height={20} width={20} />}
                      iconPosition="start"
                      onClick={() => setSelectedSideBarTab({ tabName: 'settings' })}
                    />
                  </Tabs>
                </Box>
              )}
              {!isSidePanelCollapse && (
                <Box className={classes.sidePanelCollapseButton}>
                  <IconButton
                    onClick={() => toggleSidePanel()}
                  >
                    <DoubleRightArrowsIcon
                      className={
                        classNames(
                          classes.sidePanelCollapseIcon,
                          isSidePanelCollapse && 'collapsed',
                        )
                      }
                    />
                  </IconButton>
                </Box>
              )}
              {(isSidePanelCollapse) && (
                <Box
                  className={classes.sidePanelButtonsContainer}
                >
                  {!hasNoChats && hasRelationData && (
                    <Tooltip title="Graph" arrow placement="left">
                      <Button
                        variant="outlined"
                        classes={commonCss.buttons.generalButton}
                        className={classes.sidePanelButtons}
                        style={{ boxSizing: 'border-box' }}
                        onClick={() => {
                          toggleSidePanel();
                          setSelectedSideBarTab({ tabName: 'graph' });
                        }}
                      >
                        <RelationChartIcon />
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip title="Settings" arrow placement="left">
                    <Button
                      variant="outlined"
                      classes={commonCss.buttons.generalButton}
                      className={classes.sidePanelButtons}
                      style={{ boxSizing: 'border-box' }}
                      onClick={() => {
                        toggleSidePanel();
                        setSelectedSideBarTab({ tabName: 'settings' });
                      }}
                    >
                      <SettingsIcon />
                    </Button>
                  </Tooltip>
                  {sourceCitation?.url && (
                    <Tooltip title="Browser" arrow placement="left">
                      <Button
                        variant="outlined"
                        classes={commonCss.buttons.generalButton}
                        className={classes.sidePanelButtons}
                        style={{ boxSizing: 'border-box' }}
                        onClick={() => {
                          toggleSidePanel();
                          setSelectedSideBarTab({ tabName: 'browser' });
                        }}
                      >
                        <BrowserIcon />
                      </Button>
                    </Tooltip>
                  )}
                  {sourceCitation?.sourceId && (
                    <Tooltip title="PDF" arrow placement="left">
                      <Button
                        variant="outlined"
                        classes={commonCss.buttons.generalButton}
                        className={classes.sidePanelButtons}
                        style={{ boxSizing: 'border-box' }}
                        onClick={() => {
                          toggleSidePanel();
                          setSelectedSideBarTab({ tabName: 'pdf' });
                        }}
                      >
                        <BrowserIcon />
                      </Button>
                    </Tooltip>
                  )}
                </Box>
              )}

              <RelationChart hidden={isSidePanelCollapse || selectedSideBarTab !== 'graph' || !isSidePanelReady} />

              {(!isSidePanelCollapse) && selectedSideBarTab === 'settings' && (
                <SettingsView isSmall={hasNoChats} />
              )}

              {(!isSidePanelCollapse) && selectedSideBarTab === 'browser' && isSidePanelReady && (
                <BrowserView
                  className={classes.browserView}
                  url={sourceCitation?.url}
                  content={jinaContent}
                  format={jinaContentFormat}
                  highlight={sourceCitation?.phrase}
                  highlightTimestamp={sourceCitation?.timestamp}
                  isLoading={jinaContentRequest.isRequesting}
                  hasError={!!jinaContentRequest.lastError}
                  onFormatChange={(format) => {
                    if (!sourceCitation?.url) {
                      return;
                    }

                    requestJinaContent({
                      url: sourceCitation?.url,
                      format,
                    });
                  }}
                />
              )}

              {(!isSidePanelCollapse) && selectedSideBarTab === 'pdf' && isSidePanelReady && (
                <PdfView
                  className={classes.pdfView}
                  title={sourceCitation?.title}
                  localSource={localSource}
                  pageNumber={localSource?.pageNumber}
                  highlight={sourceCitation?.phrase}
                  highlightTimestamp={sourceCitation?.timestamp}
                  isLoading={localSourceRequest.isRequesting}
                  hasError={!!localSourceRequest.lastError}
                />
              )}
            </div>
          </Container>
        )}
      </AnimatePresence>
      {isPromptPopupOpen && (
        <PromptInfoPopup
          open={isPromptPopupOpen}
          onClose={() => {
            setPromptPopupOpen(false);
          }}
          prompt={editingPromptItem}
        />
      )}
    </div>
  );
};

export default GptPortal;
