import { configureStore } from '@reduxjs/toolkit';

import {
  combineReducers,
} from 'redux';

import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';

import { history } from '../core';
import sagas from './sagas';
import reducers from './reducers';

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({ history });

const sagaMiddleWare = createSagaMiddleware();

const store = configureStore({
  reducer: combineReducers({
    ...reducers,
    router: routerReducer,
  }),
  middleware: [
    routerMiddleware,
    sagaMiddleWare,
  ],
});

sagaMiddleWare.run(sagas);

export default store;

export const reduxHistory = createReduxHistory(store);
