import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import { getCustomScrollBarCss } from '../../../../core/utils/uxUtils';
import getCommonStyles, {
  getColorModifierClasses,
  getContrastColorModifierClasses,
  getLightColorModifierClasses,
} from '../../../../theme/commonStyles';
import { otherColorSets, otherContrastColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
    },
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      position: 'sticky',
      top: 16,
      background: theme.palette.background.paper,
      backgroundColor: theme.palette.background.paper,
      zIndex: 1,
    },
    sectionHeaderText: {
      flex: 0,
      fontSize: '0.9285rem !important',
      fontWeight: 'bold !important',
      whiteSpace: 'nowrap',
      ...getColorModifierClasses('color'),
      '&.primary': {
        ...getContrastColorModifierClasses('color'),
      },
      '&.utility': {
        ...getColorModifierClasses('color'),
      },
      '&.pvalyou': {
        ...getContrastColorModifierClasses('color'),
      },
    },
    sectionHeaderDivider: {
      flex: 1,
      marginLeft: `${theme.spacing(0.5)} !important`,
      marginRight: `${theme.spacing(0)} !important`,
      boxSizing: 'border-box',
      ...getColorModifierClasses('borderColor'),
      '&.pvalyou': {
        borderColor: theme.palette.primary.main,
      },
      position: 'relative',
    },
    sectionHeaderButton: {
      width: 'auto !important',
      flexBasis: theme.spacing(12),
      flex: 0,
      fontSize: '0.9285rem !important',
      fontWeight: '300 !important',
      whiteSpace: 'nowrap',
      borderRadius: '6px !important',
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      borderColor: 'transparent !important',
      backgroundColor: 'transparent !important',
      '&.noPaddingLeft': {
        paddingLeft: '0 !important',
      },
      ...getContrastColorModifierClasses('color'),
      ...getColorModifierClasses('stroke', ' svg .caret'),
      '&.pvalyou': {
        color: otherColorSets[2],
        '& svg .caret': {
          stroke: `${otherColorSets[2]} !important`,
        },
      },
    },
    exampleContainer: {
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
      alignContent: 'flex-start',
      ...getCustomScrollBarCss(),
    },
    dataSetIconBox: {
      flex: 0,
      flexBasis: 40,
      paddingTop: 4,
    },
    dataSetContentBox: {
      flex: 1,
      marginBottom: theme.spacing(2),
    },
    dataSetContentHeader: {
      display: 'flex',
    },
    dataSetContentTitle: {
      fontSize: '1.1rem !important',
      fontWeight: 'normal !important',
      color: theme.palette.grey[900],
      lineHeight: 1.15,
      display: 'flex',
      flexDirection: 'column',
      height: 40,
      justifyContent: 'center',
      '&.multi-rows': {
        flexBasis: 150,
        marginRight: 20,
        justifyContent: 'center',
      },
    },
    dataSetContentDesc: {
      display: 'flex',
      flex: 1,
      fontSize: '1rem !important',
      fontWeight: 'normal !important',
      color: theme.palette.grey[500],
      alignItems: 'center',
      marginTop: -1,
    },
    dataSetExamplesBox: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    dataSetExample: {
      display: 'inline-block',
      color: `${theme.palette.grey[900]} !important`,
      textDecorationColor: `${otherContrastColorSets[2]} !important`,
      textDecoration: 'none !important',
      fontSize: '0.9285rem !important',
      fontWeight: '300 !important',
      cursor: 'pointer',
      padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
      borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
      border: `1px solid ${theme.palette.grey[50]}`,
      '&:hover': {
        ...getLightColorModifierClasses('backgroundColor'),
      },
    },
    siteExIcon: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '12px 12px 12px 0',
      borderWidth: 1,
      borderStyle: 'solid',
      ...getColorModifierClasses('borderColor'),
      '&.primary': {
        ...getContrastColorModifierClasses('borderColor'),
      },
      '&.pvalyou': {
        borderColor: theme.palette.secondary.main,
        width: `${theme.spacing(4)} !important`,
      },
    },
    homeExampleButton: {
      borderRadius: `${theme.spacing(2)} !important`,
      borderColor: `${otherContrastColorSets[2]} !important`,
      backgroundColor: `${theme.palette.common.white} !important`,
      color: `${theme.palette.grey[500]} !important`,
      flex: 1,
      textAlign: 'left',
      padding: `${theme.spacing(2)} !important`,
      alignItems: 'flex-start !important',
      '&:hover': {
        backgroundColor: '#f1f0ff !important',
      },
    },
  });
};

export default styles;
