import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles, { getContrastColorModifierClasses } from '../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      width: '100%',
      height: 6,
      background: theme.palette.common.white,
      borderRadius: 5,
      position: 'relative',
      padding: 2,
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
    percentBar: {
      position: 'relative',
      minWidth: 2,
      height: 2,
      borderRadius: 1,
      ...getContrastColorModifierClasses('backgroundColor'),
      transition: 'all ease-out 1s',
    },
  });
};

export default styles;
