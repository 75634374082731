import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Input,
  Typography,
} from '@mui/material';

import {
  Edit as EditIcon,
  Close as DeleteIcon,
} from '@mui/icons-material';

import { Reorder } from 'framer-motion';
import classNames from 'classnames';
import { v4 } from 'uuid';

import { useCommonClasses } from '../../../../theme/commonStyles';
import { GptChatItem, GptTaskItem, TaskTemplate } from '../types';
import TemplateInfoPopup from '../templateInfoPopup';
import { PropsFromRedux } from './container';
import styles from './GptTasksView.styles';

interface Props extends PropsFromRedux {
  chatIndex: number;
  chatItem: GptChatItem;
  mode?: 'deepSearch';
  objective?: string;
}

const useStyles = makeStyles(styles);

const GptTasksView: React.FC<Props> = ({
  chatIndex,
  chatItem,
  startExecuteTasks,
  tasksExecution,
  setChatItemTasks,
  mode,
  objective,
  taskTemplates,
  applyTaskTemplate,
  openMessageBox,
  requestDeleteTaskTemplate,
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const [isTemplatePopupOpen, setTemplatePopupOpen] = useState(false);
  const [templateItem, setTemplateItem] = useState<TaskTemplate>();
  const [hoveringTemplateItem, setHoveringTemplateItem] = useState<number | undefined>();

  const taskItems = chatItem.tasks || (
    [
      ...(chatItem.content || '').split('\n')
        .map((task) => task.replace(/^[0-9]+. /g, ''))
        .map((task) => ({
          id: v4(),
          value: task,
        })),
      {
        id: v4(),
        value: undefined,
      },
    ]
  );

  const updateTaskItem = (index: number, value: string) => {
    const items = taskItems.map((taskItem, idx) => {
      if (idx === index) {
        return {
          ...taskItems[idx],
          value,
        };
      }

      return taskItem;
    });

    if (items.every((item) => item?.value !== undefined)) {
      items.push({
        id: v4(),
        value: undefined,
      });
    }

    setChatItemTasks({
      chatIndex,
      tasks: items,
    });
  };

  const handleReorder = (items: GptTaskItem[]) => {
    const blankItemIndex = items.findIndex((item) => item?.value === undefined);
    if (blankItemIndex < items.length - 1) {
      setChatItemTasks({
        chatIndex,
        tasks: [
          ...items.filter((item) => item?.value !== undefined),
          items[blankItemIndex],
        ],
      });

      return;
    }

    setChatItemTasks({
      chatIndex,
      tasks: items,
    });
  };

  return (
    <div className={classes.root}>
      <Reorder.Group
        axis="y"
        values={taskItems}
        onReorder={handleReorder}
        className={classes.taskList}
      >
        {taskItems.map((item, index) => (
          <Reorder.Item
            key={item?.id || index}
            value={item}
            className={
              classNames(
                classes.taskItem,
                item?.value === undefined && 'new',
              )
            }
            dragListener={!!item?.value}
            onClick={(event) => {
              const { nodeName } = (event.target as HTMLLIElement);
              if (nodeName === 'LI' && item?.value !== undefined) {
                setChatItemTasks({
                  chatIndex,
                  tasks: taskItems.filter((task) => task?.id !== item?.id),
                });
              }
            }}
          >
            <span>
              <Input
                className={classes.taskInput}
                placeholder={`Type to ${item === undefined ? 'Add' : 'Edit'} a task`}
                fullWidth
                multiline
                value={item?.value || ''}
                onChange={(event) => {
                  updateTaskItem(index, event.currentTarget.value);
                }}
              />
            </span>
          </Reorder.Item>
        ))}
      </Reorder.Group>
      <Box className={classes.executeBox}>
        <Box display="flex">
          <Button
            classes={commonCss.buttons.leafButton}
            className={
              classNames(
                classes.executeButton,
              )
            }
            variant="outlined"
            size="small"
            onClick={() => startExecuteTasks({
              tasks: taskItems,
              mode,
              objective,
            })}
            disabled={!!tasksExecution}
          >
            {!!tasksExecution && (
              <Box marginRight={1}>
                <CircularProgress color="inherit" size={12} />
              </Box>
            )}
            Execute
          </Button>
          <Box marginLeft={1}>
            <Button
              classes={commonCss.buttons.leafButton}
              className={
                classNames(
                  classes.saveTemplateButton,
                  'tertiary',
                )
              }
              variant="outlined"
              size="small"
              onClick={() => {
                setTemplateItem({
                  tag: '',
                  objective: objective || '',
                  tasks: taskItems.map((task, index) => `${index + 1}. ${task.value || ''}`),
                });

                setTemplatePopupOpen(true);
              }}
            >
              Save as Template
            </Button>
          </Box>
        </Box>
        <Box marginTop={0.5}>
          <Typography variant="caption" className={classes.executeEstimate}>
            {`Estimated Duration: ${(taskItems || []).length / 2} Minute(s)`}
          </Typography>
        </Box>
      </Box>
      {taskTemplates && taskTemplates.length > 0 && (
        <Box marginTop={3} marginBottom={2}>
          <Divider />
          <Box
            marginTop={3}
            display="flex"
            gap={1}
            flexWrap="wrap"
            style={{
              transition: 'all 0.3s ease-out',
            }}
          >
            <Typography variant="body1" className={classes.templateHeader}>
              Templates:
            </Typography>
            {(taskTemplates || []).map((template, index) => (
              <Button
                key={index}
                variant="outlined"
                classes={commonCss.buttons.leafButton}
                className={classNames(
                  classes.templateItem,
                  'tertiary',
                )}
                size="small"
                onClick={() => applyTaskTemplate({ chatIndex, template })}
                onMouseEnter={() => setHoveringTemplateItem(index)}
                onMouseLeave={() => setHoveringTemplateItem(undefined)}
              >
                <Box
                  className={classes.templateItemLabel}
                >
                  {template.tag}
                </Box>
                <Box
                  display="flex"
                  className="templateItemButtons"
                  style={{
                    marginLeft: (hoveringTemplateItem === index) ? -50 : 0,
                    zIndex: 1,
                  }}
                >
                  <Box
                    className={classNames(
                      classes.templateItemButtonBox,
                    )}
                  >
                    <span
                      className={classes.templateItemButton}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        setTemplateItem(template);
                        setTemplatePopupOpen(true);
                      }}
                    >
                      <EditIcon />
                    </span>
                  </Box>
                  <Box
                    className={classNames(
                      classes.templateItemButtonBox,
                    )}
                  >
                    <span
                      className={classes.templateItemButton}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        openMessageBox({
                          content: (
                            <Box display="flex" flexDirection="column" justifyContent="center">
                              <Typography variant="body1">
                                Are you sure you want to delete the following template?
                              </Typography>
                              <Typography variant="body2">
                                {template.tag}
                              </Typography>
                            </Box>
                          ),
                          dismissText: 'No',
                          colorSet: 'tertiary',
                          commands: [{
                            title: 'Yes',
                            action: () => {
                              requestDeleteTaskTemplate({
                                template,
                              });
                            },
                          }],
                        });
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </span>
                  </Box>

                </Box>
              </Button>
            ))}
          </Box>
        </Box>
      )}
      {isTemplatePopupOpen && (
        <TemplateInfoPopup
          open={isTemplatePopupOpen}
          onClose={() => {
            setTemplatePopupOpen(false);
          }}
          template={templateItem}
        />
      )}
    </div>
  );
};

export default GptTasksView;
