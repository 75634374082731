import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';

import {
  InfoRounded as InfoIcon,
  WarningRounded as ErrorIcon,
} from '@mui/icons-material';

import classNames from 'classnames';

import { useCommonClasses } from '../../../theme/commonStyles';

import { PropsFromRedux } from './container';
import { NotificationInfo } from './types';

interface Props extends PropsFromRedux {}

function createInvestmentUpdateComponnt(notificationInfo: NotificationInfo<unknown>) {
  const { payload } = notificationInfo;
  if (!payload) {
    return undefined;
  }

  return (
    <Box display="flex" alignItems="center">
      <Box marginRight={1}>
        <CircularProgress size={12} color="inherit" />
      </Box>
      <Typography variant="body1" color="inherit">
        Updating Investment Data
      </Typography>
    </Box>
  );
}

function createGeneralInfoComponent(notificationInfo: NotificationInfo<unknown>) {
  const { payload } = notificationInfo;
  if (!payload) {
    return undefined;
  }

  return (
    <Box display="flex" alignItems="center">
      <Box marginRight={1} display="flex" alignItems="center">
        <InfoIcon fontSize="small" />
      </Box>
      <Typography variant="body1" color="inherit">
        {payload as string}
      </Typography>
    </Box>
  );
}

function createErrorInfoComponent(notificationInfo: NotificationInfo<unknown>) {
  const { payload } = notificationInfo;
  if (!payload) {
    return undefined;
  }

  return (
    <Box display="flex" alignItems="center">
      <Box marginRight={1}>
        <ErrorIcon fontSize="small" />
      </Box>
      <Typography variant="body1" color="inherit">
        {payload as string}
      </Typography>
    </Box>
  );
}

function createNotificationComponent<T>(notificationInfo: NotificationInfo<T>) {
  if (notificationInfo.type === 'investmentUpdate') {
    return createInvestmentUpdateComponnt(notificationInfo);
  }

  if (notificationInfo.type === 'general') {
    return createGeneralInfoComponent(notificationInfo);
  }

  if (notificationInfo.type === 'val') {
    return createGeneralInfoComponent(notificationInfo);
  }

  if (notificationInfo.type === 'error') {
    return createErrorInfoComponent(notificationInfo);
  }

  return undefined;
}

const Notifier: React.FC<Props> = ({
  notifications,
  removeNotification,
  setNotificationDisplayed,
}) => {
  const commonCss = useCommonClasses();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    notifications.forEach((notificationInfo) => {
      if (notificationInfo.isDisplayed) {
        setTimeout(() => {
          closeSnackbar(notificationInfo.key);
        }, 1000);

        return;
      }

      const component = createNotificationComponent(notificationInfo);
      if (!component) {
        return;
      }

      const colorMaps = {
        investmentUpdate: 'investing',
        general: 'secondary',
        error: 'error',
        val: 'tertiary',
      };

      const colorSet = colorMaps[notificationInfo.type];

      enqueueSnackbar(component, {
        key: notificationInfo.key,
        autoHideDuration: notificationInfo.delay || 3000,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        className: classNames(
          commonCss.notifications.snackBar,
          colorSet,
        ),
        onExited: () => {
          removeNotification({ key: notificationInfo.key });
        },
      });

      setNotificationDisplayed({
        key: notificationInfo.key,
      });
    });
  }, [
    notifications,
    setNotificationDisplayed,
    enqueueSnackbar,
    removeNotification,
  ]);

  return (
    <div />
  );
};

export default Notifier;
