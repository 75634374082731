import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../types';

import {
  openMessageBox,
  openCompanyReport,
} from '../main/actions';

import {
  requestCompanyItems,
  requestHistoryItems,
  requestMarketInquiryItems,
  requestGlossaryItems,
  requestGptSearch,
  openFilterEdit,
  closeFilterEdit,
  clearAllFilters,
  setPage,
  setSearchKeyword,
  toggleGptChat,
  toggleGptSearch,
  abortSearchRequest,
} from './actions';

import Search from './Search';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.main,
    ...state.search,
    ...state.session,
    networkStates: {
      ...state.search.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    requestCompanyItems,
    requestHistoryItems,
    requestMarketInquiryItems,
    requestGlossaryItems,
    requestGptSearch,
    openFilterEdit,
    closeFilterEdit,
    clearAllFilters,
    setPage,
    setSearchKeyword,
    openMessageBox,
    openCompanyReport,
    toggleGptChat,
    toggleGptSearch,
    abortSearchRequest,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Search);
