import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container, Pagination } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

import { MarketInquiryItem } from '../types';
import { PAGE_SIZE, SEARCH_ITEM_TOTAL_HEIGHT } from '../Search';
import { getStackItemAnimationProps } from '../searchUtils';
import MarketItemCard from '../marketItemCard';

import { PropsFromRedux } from './container';
import styles from './MarketList.styles';
import { otherContrastColorSets } from '../../../../theme/palette';

interface Props extends PropsFromRedux {
  marketItems?: MarketInquiryItem[];
  totalItemsInPage?: number;
}

interface ResultSpin {
  isForward: boolean;
}

const MARKET_CHART_HEIGHT = 200;

const useStyles = makeStyles(styles);

const MarketList: React.FC<Props> = ({
  marketItems,
  totalItemsInPage = PAGE_SIZE,
}: Props) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isAnimating, setIsAnimating] = useState(false);
  const [resultSpin, setResultSpin] = useState<ResultSpin>();
  const [currentResultItems, setCurrentResultItems] = useState<MarketInquiryItem[]>();
  const [postponeResultItems, setPostponeResultItems] = useState<MarketInquiryItem[]>();
  const [activeItem, setActiveItem] = useState<MarketInquiryItem>();

  const totalPages = Math.ceil((marketItems || []).length / totalItemsInPage);
  const startIndex = Math.max((currentPage || 0) - 1, 0) * totalItemsInPage;
  const finalSearchItems = (marketItems || []).slice(startIndex, startIndex + totalItemsInPage);
  const prevPage = useRef(currentPage);
  const isMouseWheelProcessing = useRef<boolean>(false);

  useEffect(() => {
    setActiveItem(finalSearchItems.at(0));
  }, [currentPage, marketItems]);

  useEffect(() => {
    const isResultSpinForword = (
      (currentPage || 0) >= (prevPage.current || 0)
    );

    setResultSpin({
      isForward: isResultSpinForword,
    });

    if (currentPage !== prevPage.current) {
      prevPage.current = currentPage;
    }

    // This will refresh the resultSpin attributes on list items exit.
    setCurrentResultItems([...currentResultItems || []]);
  }, [currentPage, prevPage, marketItems]);

  useEffect(() => {
    if (isAnimating) {
      setPostponeResultItems([...finalSearchItems]);
      return;
    }

    setCurrentResultItems([...finalSearchItems]);
    setPostponeResultItems(undefined);
  }, [resultSpin]);

  useEffect(() => {
    if (!isAnimating && postponeResultItems) {
      setCurrentResultItems(postponeResultItems);
      setPostponeResultItems(undefined);
    }
  }, [isAnimating, postponeResultItems]);

  const onMouseWheel = (deltaY: number) => {
    if (isMouseWheelProcessing.current) {
      return;
    }

    isMouseWheelProcessing.current = true;

    if (deltaY > 0) {
      const page = Math.min(totalPages || 0, (currentPage || 0) + 1);
      setCurrentPage(page);
    }

    if (deltaY < 0) {
      const page = Math.max(1, (currentPage || 0) - 1);
      setCurrentPage(page);
    }

    const timeOutHandler = setTimeout(() => {
      isMouseWheelProcessing.current = false;
      clearTimeout(timeOutHandler);
    }, 1200);
  };

  return (
    <div className={classes.root}>
      <Container>
        <Box
          className={classes.resultPanel}
          onWheel={(event) => onMouseWheel(event.deltaY)}
        >
          <Box
            className={classes.searchResults}
          >
            <AnimatePresence>
              {(currentResultItems || []).map((item, index) => (
                <Box key={`${item.idMarket}`} marginBottom={2}>
                  <motion.div
                    onAnimationStart={() => setIsAnimating(true)}
                    onAnimationComplete={() => setIsAnimating(false)}
                    {...getStackItemAnimationProps({
                      isForward: resultSpin?.isForward,
                      itemHeight: SEARCH_ITEM_TOTAL_HEIGHT,
                      itemIndex: index,
                      pageSize: PAGE_SIZE,
                    })}
                  >
                    <MarketItemCard
                      marketItem={item}
                      onDataClick={(marketItem) => setActiveItem(marketItem)}
                      expanded={activeItem?.idMarket === item.idMarket}
                      chartHeight={MARKET_CHART_HEIGHT}
                    />
                  </motion.div>
                </Box>
              ))}
            </AnimatePresence>
          </Box>
        </Box>
        {(totalPages || 0) > 0 && (
          <Box className={classes.paginationContainer}>
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              onChange={(_, page) => setCurrentPage(page)}
              sx={{
                '& .MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: 'transparent',
                  color: otherContrastColorSets[0],
                  textDecoration: 'underline',
                },
              }}
            />
          </Box>
        )}
      </Container>
    </div>
  );
};

export default MarketList;
