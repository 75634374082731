import { Country, ICountry } from 'country-state-city';

const countries = Country.getAllCountries();

export const countryLookupByName = countries.reduce((lookup, country) => ({
  ...lookup,
  [country.name]: country,
}), {} as Record<string, ICountry>);

export const countryLookupByIsoCode = countries.reduce((lookup, country) => ({
  ...lookup,
  [country.isoCode.toUpperCase()]: country,
}), {} as Record<string, ICountry>);

export function getCountryIsoCode(countryName: string) {
  if (countryLookupByName[countryName]) {
    return countryLookupByName[countryName].isoCode;
  }

  return undefined;
}

export function getCountryName(countryIsoCode: string) {
  if (countryLookupByIsoCode[countryIsoCode.toUpperCase()]) {
    return countryLookupByIsoCode[countryIsoCode.toUpperCase()].name;
  }

  return undefined;
}

export function getCountryByIsoCode(countryIsoCode: string) {
  if (countryLookupByIsoCode[countryIsoCode.toUpperCase()]) {
    return countryLookupByIsoCode[countryIsoCode.toUpperCase()];
  }

  return undefined;
}
