import fetchJsonp from 'fetch-jsonp';
import config from '../config';
import HttpError from './types/errors';

export type ApiFunc<T> = (
  endpoint: string,
  queryParams?: URLSearchParams,
  options?: RequestInit,
) => Promise<T>;

export type AdditionalOptions = {
  useMultiPartsFormData?: boolean;
  useFullUrlAsPath?: boolean;
  useOriginalResponse?: boolean;
  useOriginalPayload?: boolean;
  authorization?: string | null;
};

export const defaultHeaders: Record<string, string> = {
  'Content-Type': 'application/json',
  'Accept-Encoding': 'gzip, deflate, br',
};

export function getBackendUrl() {
  return `${config.backendUrl}`;
}

export async function jsonp<T>(
  endpoint: string,
): Promise<T> {
  const response = await fetchJsonp(endpoint);
  if (!response.ok) {
    throw new Error('Request Error');
  }

  const json = await response.json<T>();
  return json;
}

export async function performRequest<T>(
  endpoint: string,
  requestInit: RequestInit & AdditionalOptions,
): Promise<T> {
  const init = requestInit;

  if (!init.headers) {
    init.headers = defaultHeaders;
  }

  // Extends authorization header.
  if (window.authToken && requestInit.authorization !== null) {
    init.headers = {
      ...init.headers,
      Authorization: `token ${window.authToken}`,
    };
  }

  if (requestInit.authorization) {
    init.headers = {
      ...init.headers,
      Authorization: requestInit.authorization,
    };
  }

  const response = await fetch(endpoint, init);
  if (!response.ok) {
    throw new HttpError(response.status, `Error ${response.status}`);
  }

  const text = await response.text();
  try {
    return JSON.parse(text) as T;
  } catch (e) {
    return text as unknown as T;
  }
}

export function httpGet<T>(
  endpoint: string,
  options?: RequestInit,
): Promise<T> {
  const requestInit: RequestInit = {
    ...options,
    method: 'GET',
    headers: {
      ...defaultHeaders,
      ...options?.headers,
    },
  };

  return performRequest<T>(endpoint, requestInit);
}

export function httpPost<T>(
  endpoint: string,
  body?: BodyInit,
  options?: RequestInit & AdditionalOptions,
): Promise<T> {
  const postHeader = {
    ...defaultHeaders,
    ...options?.headers,
  } as Record<string, string>;

  if (options?.useMultiPartsFormData) {
    delete postHeader['Content-Type'];
  }

  const requestInit: RequestInit = {
    ...options,
    body,
    method: 'POST',
    headers: postHeader,
  };

  return performRequest<T>(endpoint, requestInit);
}

export function httpPut<T>(
  endpoint: string,
  body?: BodyInit,
  options?: RequestInit & AdditionalOptions,
): Promise<T> {
  const putHeader = {
    ...defaultHeaders,
    ...options?.headers,
  } as Record<string, string>;

  if (options?.useMultiPartsFormData) {
    delete putHeader['Content-Type'];
  }

  const requestInit: RequestInit = {
    ...options,
    body,
    method: 'PUT',
    headers: putHeader,
  };

  return performRequest<T>(endpoint, requestInit);
}

export function httpDelete<T>(
  endpoint: string,
  options?: RequestInit & AdditionalOptions,
): Promise<T> {
  const delHeader = {
    ...defaultHeaders,
    ...options?.headers,
  } as Record<string, string>;

  const requestInit: RequestInit = {
    ...options,
    method: 'DELETE',
    headers: delHeader,
  };

  return performRequest<T>(endpoint, requestInit);
}
