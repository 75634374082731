import React, {
  lazy,
  Suspense,
  useEffect,
  useRef,
  useState,
} from 'react';

import { makeStyles } from '@mui/styles';

import {
  Grid,
  Box,
  Typography,
  Chip,
  useTheme,
  Link,
  Tooltip,
  Button,
  IconButton,
} from '@mui/material';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import classNames from 'classnames';

import { removeStopwords, eng } from 'stopword';

import { ReactComponent as CopyIcon } from '../../../../assets/svg/copyDescIcon.svg';
import { ReactComponent as DefaultCompanyIcon } from '../../../../assets/svg/defaultCompanyIcon.svg';
import { ReactComponent as ProfileScoreIcon } from '../../../../assets/svg/profileScoreIcon.svg';
import { ReactComponent as SuccessScoreIcon } from '../../../../assets/svg/successScoreIcon.svg';
import { ReactComponent as MarketAnalysisIcon } from '../../../../assets/svg/marketAnalysisIcon.svg';
import { ReactComponent as CompetitorIcon } from '../../../../assets/svg/competitorIcon.svg';
import { ReactComponent as LandscapeIcon } from '../../../../assets/svg/landscapeIcon.svg';
import { ReactComponent as TechnologiesIcon } from '../../../../assets/svg/technologiesIcon.svg';
import { ReactComponent as WebTrafficIcon } from '../../../../assets/svg/webTrafficIcon.svg';

import { useCommonClasses } from '../../../../theme/commonStyles';
import { getCountryIsoCode } from '../../../../core/utils/countryUtils';

import { CompanyItem } from '../types';
import { PropsFromRedux } from './container';
import styles from './CompanyItemCard.styles';
import { elementContainsSelection, getRelatedCompanyLogo, isMouseOnElement } from '../../../../core/utils/uxUtils';

interface Props extends PropsFromRedux {
  companyItem: CompanyItem;
  onDataClick?: (companyItem: CompanyItem) => void;
  onSubReportClick?: (companyItem: CompanyItem, report: string) => void;
  showScore?: boolean;
  showKeywords?: boolean;
  isRelatedCompany?: boolean;
}

const Flag = lazy(() => import('react-world-flags'));

const useStyles = makeStyles(styles);

const CompanyItemCard: React.FC<Props> = ({
  companyItem,
  onDataClick,
  onSubReportClick,
  keyword: searchKeyword,
  showScore,
  showKeywords = true,
  setSearchKeyword,
  isRelatedCompany,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();
  const descriptionEl = useRef<HTMLParagraphElement>(null);
  const [isHover, setHover] = useState(false);
  const [isHoverOpenReport, setHoverOpenReport] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);

  const keywords = (companyItem.keywords && companyItem.keywords.split(',')) || [];
  const pureSearchKeywords = removeStopwords(searchKeyword.split(' '), eng).join(' ').replace(/,/g, '');

  const companyNameMatches: [number, number][] = match(companyItem.name || '', pureSearchKeywords);
  const companyNameParts = parse(companyItem.name || '', companyNameMatches);

  const descriptionMatches: [number, number][] = match(companyItem.description || '', pureSearchKeywords);
  const descriptionParts = parse(companyItem.description || '', descriptionMatches);

  const { score } = companyItem;

  useEffect(() => {
    const listener = () => {
      const selection = window.getSelection();
      const selectedText = selection?.toString();

      if (!selectedText && rootRef.current && !isMouseOnElement(rootRef.current)) {
        setHover(false);
      }
    };

    document.addEventListener('selectionchange', listener);

    return () => {
      document.removeEventListener('selectionchange', listener);
    };
  }, []);

  const availableReports = [{
    name: 'Profile Score',
    color: 'secondary',
    isActive: companyItem.report,
    icon: score && showScore ? (
      <Typography className={classes.scoreText} variant="body2">
        {((score || 0) * 100).toFixed(1)}
        <small>%</small>
      </Typography>
    ) : (
      <ProfileScoreIcon width={14} height={14} />
    ),
    report: 'profileScore',
    iconClass: (
      score && showScore && 'score'
    ),
  }, {
    name: 'Success Score',
    color: 'investing',
    isActive: companyItem.report,
    icon: <SuccessScoreIcon width={14} height={14} />,
    report: 'successScore',
  }, {
    name: 'Market Analysis',
    color: 'market',
    isActive: true,
    icon: <MarketAnalysisIcon width={14} height={14} />,
    report: 'market',
  }, {
    name: 'Competitor Ranking',
    color: 'ranking',
    isActive: true,
    icon: <CompetitorIcon width={14} height={14} />,
    report: 'ranking',
  }, {
    name: 'Competitive Landscape',
    color: 'landscape',
    isActive: true,
    icon: <LandscapeIcon width={14} height={14} />,
    report: 'landscape',
  }, {
    name: 'Web Traffic Analysis',
    color: 'traffic',
    isActive: false,
    icon: <WebTrafficIcon width={14} height={14} />,
    report: 'webTraffic',
  }, {
    name: 'Technologies',
    color: 'technology',
    isActive: false,
    icon: <TechnologiesIcon width={14} height={14} />,
    report: 'technologies',
  }];

  const onButtonClick = () => {
    if (onDataClick) {
      onDataClick(companyItem);
    }
  };

  const refineUrl = (url: string) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `//${url}`;
    }

    return url;
  };

  const openWeb = (event: React.MouseEvent<HTMLElement, MouseEvent>, url?: string) => {
    if (!url) {
      return;
    }

    const refinedUrl = refineUrl(url);

    event.stopPropagation();
    const handle = window.open(refinedUrl, url);

    setTimeout(() => {
      handle?.blur();

      // eslint-disable-next-line no-restricted-globals
      self.focus();
      window.focus();
    }, 1000);
  };

  const copyDescriptionToSearch = () => {
    const newKeywords = companyItem.description?.replaceAll(companyItem.name, '');
    if (newKeywords) {
      setSearchKeyword({
        keyword: newKeywords,
      });
    }
  };

  const websiteUrl = companyItem.domain?.at(0) || companyItem.website?.at(0);

  return (
    <div
      className={
        classNames(
          classes.root,
          isHover && 'active',
        )
      }
      color="secondary"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={(event) => {
        if (elementContainsSelection(event.currentTarget)) {
          return;
        }

        setHover(false);
      }}
      ref={rootRef}
    >
      <div className={classes.body}>
        <Grid container spacing={2} columns={14}>
          <Grid item xs={showKeywords ? 7 : 11}>
            <Grid container flexWrap="nowrap">
              {companyItem?.logo ? (
                <Box
                  className={
                    classNames(
                      commonCss.boxes.imageBox,
                      classes.logoContainer,
                    )
                  }
                  component="object"
                  data={getRelatedCompanyLogo(companyItem.logo, 100)}
                  type="image/png"
                  width={100}
                  height={100}
                  onMouseEnter={() => setHoverOpenReport(true)}
                  onMouseLeave={() => setHoverOpenReport(false)}
                  onClick={onButtonClick}
                >
                  <DefaultCompanyIcon />
                </Box>
              ) : (
                <Box
                  className={
                    classNames(
                      commonCss.boxes.imageBox,
                      classes.logoContainer,
                    )
                  }
                  width={100}
                  height={100}
                  onMouseEnter={() => setHoverOpenReport(true)}
                  onMouseLeave={() => setHoverOpenReport(false)}
                  onClick={onButtonClick}
                >
                  <DefaultCompanyIcon />
                </Box>
              )}
              <Box
                paddingLeft={3}
                flexShrink={1}
                className={classes.selectableBox}
                ref={descriptionEl}
              >
                <Typography variant="h6" className={classes.name}>
                  {companyNameParts.map((part, index) => (
                    <Tooltip key={index} arrow title={companyItem.name}>
                      <span
                        style={{
                          fontWeight: part.highlight ? 700 : undefined,
                          color: part.highlight ? theme.palette.grey[900] : theme.palette.grey[700],
                        }}
                      >
                        {part.text}
                      </span>
                    </Tooltip>
                  ))}
                  {websiteUrl && (
                    <Link
                      className={classes.webLink}
                      type="button"
                      onClick={(event) => openWeb(event, websiteUrl)}
                    >
                      <small style={{ marginLeft: theme.spacing(1) }}>
                        ({websiteUrl})
                      </small>
                    </Link>
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.description}
                >
                  {descriptionParts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 500 : undefined,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {showKeywords && (
            <Grid item xs={4}>
              <Grid container className={classes.keywords}>
                {keywords.slice(0, 6).map((keyword) => {
                  const keywordMatches: [number, number][] = match(keyword || '', pureSearchKeywords);
                  const keywordParts = parse(keyword || '', keywordMatches);
                  const hasMatches = !!keywordParts.find((m) => m.highlight);

                  const keywordNodes = keywordParts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 500 : undefined,
                      }}
                    >
                      {part.text}
                    </span>
                  ));

                  return (
                    <Chip
                      key={keyword}
                      className={
                        classNames(
                          classes.tagItem,
                          hasMatches && 'active',
                        )
                      }
                      classes={commonCss.chips.tagItem}
                      variant="outlined"
                      label={keywordNodes}
                    />
                  );
                })}
              </Grid>
            </Grid>
          )}
          {!isRelatedCompany && (
            <Grid item xs={3} alignItems="flex-start">
              <Box className={classes.companyInfo}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography className='label' variant="body2" color="secondary">
                      Location
                    </Typography>
                  </Grid>
                  <Grid item container xs={8} flexWrap="nowrap" textOverflow="ellipsis" overflow="hidden">
                    {companyItem.country && getCountryIsoCode(companyItem.country) && (
                      <Suspense>
                        <Box marginRight={0.75} marginBottom={-0.5}>
                          <Flag
                            code={companyItem.countryIso}
                            width={theme.spacing(2.5)}
                            height={theme.spacing(1.5)}
                            style={{
                              marginBottom: 1,
                            }}
                          />
                        </Box>
                      </Suspense>
                    )}
                    <Typography variant="body2" color="info" style={{ whiteSpace: 'nowrap' }}>
                      {companyItem.country}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography className='label' variant="body2" color="secondary">
                      Founded
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="info">
                      {companyItem.foundingYear}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography className='label' variant="body2" color="secondary">
                      Industry
                    </Typography>
                  </Grid>
                  <Grid item xs={8} whiteSpace="nowrap">
                    <Typography variant="body2" color="info">
                      {companyItem.industry}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Typography className='label' variant="body2" color="secondary">
                      Vertical
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="info">
                      {companyItem.vertical}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
          {isRelatedCompany && (
            <Grid item xs={3} alignItems="flex-start">
              <Box>
                <Typography className='label' variant="body2" color="secondary">
                  Location
                </Typography>
                <Box display="flex" alignItems="center">
                  {companyItem.country && getCountryIsoCode(companyItem.country) && (
                    <Suspense>
                      <Box marginRight={0.75} marginBottom={-0.5}>
                        <Flag
                          code={companyItem.countryIso}
                          width={theme.spacing(2.5)}
                          height={theme.spacing(1.5)}
                          style={{
                            marginBottom: 1,
                          }}
                        />
                      </Box>
                    </Suspense>
                  )}
                  <Typography variant="body2" color="info" style={{ whiteSpace: 'nowrap' }}>
                    {companyItem.country}
                  </Typography>
                </Box>
                <Typography className='label' variant="body2" color="secondary">
                  Established
                </Typography>
                <Typography variant="body2" color="info" style={{ whiteSpace: 'nowrap' }}>
                  {companyItem.foundingYear}
                </Typography>
                <Typography className='label' variant="body2" color="secondary">
                  Status
                </Typography>
                <Typography variant="body2" color="info" style={{ whiteSpace: 'nowrap' }}>
                  Active
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </div>
      <div
        className={
          classNames(
            classes.moreControls,
            isHover && 'active',
          )
        }
      >
        <Button
          variant={isHoverOpenReport ? 'contained' : 'outlined'}
          size="small"
          classes={commonCss.buttons.roundButton}
          className={
            classNames(
              classes.openReportButton,
              'secondary',
            )
          }
          onMouseEnter={() => setHoverOpenReport(true)}
          onMouseLeave={() => setHoverOpenReport(false)}
          onClick={onButtonClick}
        >
          Open Report
        </Button>
        <Box marginLeft={3} display="flex" columnGap={1} flex={1}>
          {availableReports.map((r) => (
            <Box
              key={r.name}
              className={
                classNames(
                  classes.reportIconContainer,
                  r.iconClass,
                )
              }
              onClick={() => (
                r.isActive
                && onSubReportClick
              ) && onSubReportClick(companyItem, r.report)}
            >
              <Box
                className={
                  classNames(
                    commonCss.boxes.dataBox,
                    classes.reportIconBox,
                    r.iconClass,
                    r.isActive && 'active',
                    r.isActive && r.color,
                  )
                }
              >
                {r.icon}
              </Box>
              <Box className="desc">
                {r.name}
              </Box>
            </Box>
          ))}
        </Box>
        <Box marginTop={-0.5}>
          <Tooltip arrow title="Copy description to search">
            <IconButton
              color="secondary"
              size="small"
              onClick={() => copyDescriptionToSearch()}
            >
              <CopyIcon color="secondary" fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    </div>
  );
};

export default CompanyItemCard;
