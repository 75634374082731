import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import config from '../../../config';
import { getImageType } from '../../../core/utils/imageUtils';
import { performApiGet, performApiPost } from '../../../core/utils/sagaUtils';
import { isBase64, stripHttp } from '../../../core/utils/stringUtils';
import { ApplicationState } from '../../types';
import {
  requestCompanySave,
  requestFounderSave,
  requestImageUpload,
  requestPvyParams,
  setAvailableKeywords,
  setCompanySaved,
  setFounderSaved,
  setImageUploaded,
  setLinkedInProfile,
  setManualFormSaved,
  setPvyParams,
} from './actions';
import {
  CompanyInfo,
  CrunchbaseCategory,
  FounderInfo,
  LinkedInProfileInfo,
  ManualFormActions,
  ManualFormData,
  ManualFormState,
  PvyParamsInfo,
  RequestCompanySaveArgs,
  RequestFounderSaveArgs,
  RequestImageUploadArgs,
  RequestLinkedInProfileArgs,
  RequestPvyParamsArgs,
  SetCompanySavedArgs,
  SetFounderSavedArgs,
  SetImageUploadedArgs,
  SetPvyParamsArgs,
  UploadedImageInfo,
} from './types';
import { getFilledFounders, hasError, validate } from './manualFormUtils';

const loadCrunchbaseCategories = () => (
  new Promise<CrunchbaseCategory[]>((resolve, reject) => {
    import('../../../assets/data/crunchbaseCategories.json')
      .then((data) => {
        resolve(data?.default);
      })
      .catch((error) => reject(error));
  })
);

function* generateFormId(manualForm?: ManualFormData) {
  if (manualForm?.formId) {
    return {
      payload: {
        pvyParams: {
          id: manualForm.formId,
        },
      },
      type: ManualFormActions.PVY_PARAMS_SET,
    } as PayloadAction<SetPvyParamsArgs>;
  }

  const founders = getFilledFounders(manualForm);
  yield put(requestPvyParams({
    numOfFounders: founders.length,
  }));

  const act: PayloadAction<SetPvyParamsArgs> = (
    yield take(ManualFormActions.PVY_PARAMS_SET)
  );

  return act;
}

function* saveCompanyInfo(companyInfo?: CompanyInfo) {
  if (!companyInfo) {
    return undefined;
  }

  const innerCompanyInfo = {
    ...companyInfo,
  };

  if (innerCompanyInfo.photoUrl
    && isBase64(innerCompanyInfo.photoUrl)
    && !innerCompanyInfo.compLogo
  ) {
    const type = getImageType(innerCompanyInfo.photoUrl);
    const response: Response = yield fetch(innerCompanyInfo.photoUrl);
    const blob: ArrayBuffer = yield response.blob();
    const file = new File([blob], `company-logo.${type}`);

    yield put(requestImageUpload({ file }));

    const uploadAct: PayloadAction<SetImageUploadedArgs> = (
      yield take(ManualFormActions.IMAGE_UPLOADED_SET)
    );

    if (!uploadAct.payload.image) {
      return uploadAct;
    }

    innerCompanyInfo.compLogo = String(uploadAct.payload.image.id);
  }

  yield put(requestCompanySave({
    companyInfo: innerCompanyInfo,
  }));

  const act: PayloadAction<SetCompanySavedArgs> = (
    yield take(ManualFormActions.COMPANY_SAVED_SET)
  );

  return act;
}

function* saveFounderInfo(founderInfo?: FounderInfo) {
  if (!founderInfo) {
    return undefined;
  }

  const innerFounderInfo = {
    ...founderInfo,
  };

  if (innerFounderInfo.photoUrl
    && isBase64(innerFounderInfo.photoUrl)
    && !innerFounderInfo.founderPic
  ) {
    const type = getImageType(innerFounderInfo.photoUrl);
    const response: Response = yield fetch(innerFounderInfo.photoUrl);
    const blob: ArrayBuffer = yield response.blob();
    const file = new File([blob], `founder-image.${type}`);

    yield put(requestImageUpload({ file }));

    const uploadAct: PayloadAction<SetImageUploadedArgs> = (
      yield take(ManualFormActions.IMAGE_UPLOADED_SET)
    );

    if (!uploadAct.payload.image) {
      return uploadAct;
    }

    innerFounderInfo.founderPic = String(uploadAct.payload.image.id);
  }

  yield put(requestFounderSave({
    founderInfo: innerFounderInfo,
  }));

  const act: PayloadAction<SetFounderSavedArgs> = (
    yield take(ManualFormActions.FOUNDER_SAVED_SET)
  );

  return act;
}

function* initializeFlow() {
  yield takeEvery(
    ManualFormActions.MANUAL_FORM_INITIALIZE,
    function* _() {
      const categories: CrunchbaseCategory[] = yield loadCrunchbaseCategories();
      const keywords = categories.map((category) => category.name);

      yield put(setAvailableKeywords({ keywords }));
    },
  );
}

function* pvyParamsRequestFlow() {
  yield takeEvery(
    ManualFormActions.PVY_PARAMS_REQUEST,
    function* _(act: PayloadAction<RequestPvyParamsArgs>) {
      yield fork(() => performApiPost<PvyParamsInfo>(
        config.api.requestPvyParams,
        {
          payload: {
            numOfFounders: act.payload.numOfFounders,
          },
          onResult: ({ result, error }) => put(setPvyParams({
            pvyParams: result,
            error,
          })),
        },
      ));
    },
  );
}

function* saveCompanyRequestFlow() {
  yield takeEvery(
    ManualFormActions.COMPANY_SAVE_REQUEST,
    function* _(act: PayloadAction<RequestCompanySaveArgs>) {
      yield fork(() => performApiPost<CompanyInfo>(
        config.api.saveCompanyData,
        {
          payload: act.payload.companyInfo as Record<string, unknown>,
          onResult: ({ result, error }) => put(setCompanySaved({
            companyInfo: result,
            error,
          })),
        },
      ));
    },
  );
}

function* saveFounderRequestFlow() {
  yield takeEvery(
    ManualFormActions.FOUNDER_SAVE_REQUEST,
    function* _(act: PayloadAction<RequestFounderSaveArgs>) {
      yield fork(() => performApiPost<FounderInfo>(
        config.api.saveFounderData,
        {
          payload: act.payload.founderInfo as Record<string, unknown>,
          onResult: ({ result, error }) => put(setFounderSaved({
            founderInfo: result,
            error,
          })),
        },
      ));
    },
  );
}

function* manualFormSaveInitiateFlow() {
  yield takeEvery(
    ManualFormActions.MANUAL_FORM_SAVE_INITIATE,
    function* _() {
      const manualFormState: ManualFormState = (
        yield select((state: ApplicationState) => state.manualForm)
      );

      const manualForm = manualFormState.manualFormData;
      if (!manualForm) {
        return;
      }

      const validationInfo = validate(manualFormState);
      if (hasError(validationInfo)) {
        return;
      }

      const formIdAct: PayloadAction<SetPvyParamsArgs> = (
        yield generateFormId(manualForm)
      );

      if (!formIdAct.payload.pvyParams) {
        yield put(setManualFormSaved({
          manualForm,
          error: formIdAct.payload.error,
        }));

        return;
      }

      const { pvyParams } = formIdAct.payload;
      const companyInfo = {
        ...manualForm?.companyInfo,
        form: pvyParams?.id,
      };

      const saveCompanyAct: PayloadAction<SetCompanySavedArgs> = (
        yield saveCompanyInfo(companyInfo)
      );

      if (!saveCompanyAct.payload.companyInfo) {
        yield put(setManualFormSaved({
          manualForm: {
            ...manualForm,
            formId: pvyParams?.id,
          },
          error: saveCompanyAct.payload.error,
        }));

        return;
      }

      const founders = getFilledFounders(manualForm);
      const founderInfos = founders.map((founderInfo, index) => ({
        ...founderInfo,
        form: pvyParams?.id,
        index,
      } as FounderInfo));

      while (founderInfos.length > 0) {
        const founderInfo = founderInfos.pop();

        const saveFounderAct: PayloadAction<SetFounderSavedArgs> = (
          yield saveFounderInfo(founderInfo)
        );

        if (!saveFounderAct.payload.founderInfo) {
          yield put(setManualFormSaved({
            manualForm: {
              ...manualForm,
              formId: pvyParams?.id,
            },
            error: saveFounderAct.payload.error,
          }));

          return;
        }
      }

      yield put(setManualFormSaved({
        manualForm: {
          ...manualForm,
          formId: pvyParams?.id,
        },
      }));
    },
  );
}

function* uploadImageFlow() {
  yield takeEvery(
    ManualFormActions.IMAGE_UPLOAD_REQUEST,
    function* _(act: PayloadAction<RequestImageUploadArgs>) {
      yield fork(() => performApiPost<UploadedImageInfo>(
        config.api.uploadImage,
        {
          payload: {
            img: act.payload.file,
          },
          onResult: ({ result, error }) => put(setImageUploaded({
            image: result,
            error,
          })),
        },
        {
          useMultiPartsFormData: true,
          useOriginalPayload: true,
        },
      ));
    },
  );
}

function* linkedInProfileRequestFlow() {
  yield takeEvery(
    ManualFormActions.LINKED_IN_PROFILE_REQUEST,
    function* _(act: PayloadAction<RequestLinkedInProfileArgs>) {
      const linkedInUrl = stripHttp(act.payload.linkedInUrl);

      yield fork(() => performApiGet<LinkedInProfileInfo>(
        config.api.requestLinkedInProfile,
        {
          params: {
            li_profile: linkedInUrl,
            year_founded: String(act.payload.yearFound),
            org_name: act.payload.orgName,
          },
          onResult: ({ result, error }) => put(setLinkedInProfile({
            founderIndex: act.payload.founderIndex,
            linkedInProfile: result,
            error,
          })),
        },
      ));
    },
  );
}

export default function* manualFormSaga() {
  yield all([
    initializeFlow(),
    manualFormSaveInitiateFlow(),
    pvyParamsRequestFlow(),
    saveCompanyRequestFlow(),
    saveFounderRequestFlow(),
    uploadImageFlow(),
    linkedInProfileRequestFlow(),
  ]);
}
