import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import jsonpath from 'jsonpath';

import { ReactComponent as UploadImageIcon } from '../../../../assets/svg/uploadImageIcon.svg';
import { useCommonClasses } from '../../../../theme/commonStyles';
import useCommonProps from '../../../../theme/commonProps';
import { toBase64 } from '../../../../core/utils/imageUtils';
import createPerformanceUtils from '../../../../core/utils/performanceUtils';
import { isLinkedInProfileUrl } from '../../../../core/utils/validationUtils';
import { isNullOrUndefined } from '../../../../core/utils/dataUtils';

import { FounderInfo, ManualFormData } from '../types';
import {
  FOUNDER_NAME_MAX_LENGTH,
  FOUNDER_TITLE_MAX_LENGTH,
  getFirstError,
  hasErrorInfo,
} from '../manualFormUtils';
import { PropsFromRedux } from './container';
import styles from './FoundersForm.styles';
import { getNumberFieldProps, getUpDownEndAdornment } from '../../../../core/utils/fieldUtils';

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);
const performanceUtils = createPerformanceUtils();

const FoundersForm: React.FC<Props> = ({
  manualFormData,
  updateManualFormField,
  validationInfo,
  requestLinkedInProfile,
  networkStates: {
    linkedInProfileRequest,
  },
  setHoveredDataInfoKey,
  setFocusedDataInfoKey,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();
  const commonProps = useCommonProps();
  const [founderInfos, setFounderInfos] = (
    useState<(FounderInfo | undefined)[]>(
      manualFormData?.founderInfos || [] as FounderInfo[])
  );

  const [linkedInUrls, setLinkedInUrls] = (
    useState<string[]>((manualFormData?.founderInfos || []).map(() => ''))
  );

  const [linkedInUrlErrors, setLinkedInUrlErrors] = (
    useState<string[]>((manualFormData?.founderInfos || []).map(() => ''))
  );

  const [linkedInUrlFocusIndex, setLinkedInInputFocusIndex] = (
    useState<number | undefined>()
  );

  const [founderToBeRemovedIndex, setFounderToBeRemovedIndex] = useState<number | undefined>();
  const uploadImageInputFiles = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const founderValidations = validationInfo?.founders || [];
  const textInputRefs = useRef<(HTMLInputElement | null)[][]>([
    [],
    [],
    [],
  ]);

  const updateStore = async (
    updatedFormData: (FounderInfo | undefined)[],
    pooling: boolean = true,
  ) => {
    const manualForm = manualFormData || {} as ManualFormData;

    if (pooling) {
      await performanceUtils.forLast(updatedFormData, 500);
    }

    updateManualFormField({
      manualForm,
      path: 'founderInfos',
      value: updatedFormData,
    });
  };

  const updateFounderInfoField = (
    index: number,
    path?: keyof FounderInfo,
    value?: unknown,
    pooling?: boolean,
  ) => {
    const founderInfo = founderInfos[index] || {
      workExp: 0,
      numPriorJobs: 0,
      numCompFounded: 0,
      numOfAcDeg: 0,
    } as FounderInfo;
    const updatedFounderInfo = { ...founderInfo };
    const updatedFounderInfos = [...founderInfos];

    if (path) {
      jsonpath.value(updatedFounderInfo, `$.${path}`, value);
    }

    if (path === 'photoUrl') {
      updateFounderInfoField(index, 'founderPic', undefined);
    }

    if (path === 'workExp' && !Number(value)) {
      jsonpath.value(updatedFounderInfo, '$.numCompFounded', 0);
      jsonpath.value(updatedFounderInfo, '$.numPriorJobs', 0);
      jsonpath.value(updatedFounderInfo, '$.wasCeo', false);
      jsonpath.value(updatedFounderInfo, '$.wasFaamg', false);
    }

    if (path === 'numOfAcDeg' && Number(value) < 2) {
      jsonpath.value(updatedFounderInfo, '$.hasMbaDeg', false);
      jsonpath.value(updatedFounderInfo, '$.hasPhdDeg', false);
    }

    updatedFounderInfos[index] = updatedFounderInfo;

    setFounderInfos(updatedFounderInfos);
    void updateStore(updatedFounderInfos, pooling);
  };

  const updateLinkedInUrl = (
    index: number,
    url: string,
  ) => {
    const urls = [...linkedInUrls];
    urls[index] = url;

    setLinkedInUrls(urls);
  };

  const findLinkedProfile = (index: number) => {
    const linkedInUrl = linkedInUrls[index];
    const errors = [...linkedInUrlErrors];
    if ((linkedInUrl || '').trim() === '') {
      errors[index] = 'LinkedIn URL cannot be empty';
      setLinkedInUrlErrors(errors);
      return;
    }

    if (!isLinkedInProfileUrl(linkedInUrl)) {
      errors[index] = 'Not a valid LinkedIn profile URL';
      setLinkedInUrlErrors(errors);
      return;
    }

    errors[index] = '';
    setLinkedInUrlErrors(errors);

    requestLinkedInProfile({
      founderIndex: index,
      linkedInUrl,
      orgName: manualFormData?.companyInfo?.compName || '',
      yearFound: manualFormData?.companyInfo?.yearFounded || 2000,
    });
  };

  const fillManualForm = (index: number) => {
    updateFounderInfoField(
      index,
      undefined,
      undefined,
      false,
    );
  };

  const confirmRemoveFounderInfo = (index: number) => {
    setFounderToBeRemovedIndex(index);
  };

  const removeFounderInfo = (index: number) => {
    const updatedFounderInfos = [...founderInfos];
    updatedFounderInfos[index] = undefined;

    setFounderInfos(updatedFounderInfos);
    setFounderToBeRemovedIndex(undefined);
    void updateStore(updatedFounderInfos, false);
  };

  const handleImageFileSelected = async (index: number, files: FileList | null) => {
    if (!files?.length) {
      return;
    }

    const base64 = await toBase64(files[0]);

    updateFounderInfoField(index, 'photoUrl', base64);
  };

  const getValidationInfo = (index: number) => (
    index < founderValidations.length
      ? founderValidations[index]
      : {} as Record<keyof FounderInfo, string[]>
  );

  const hasPreviousFounderEntry = (index: number) => (
    index === 0 || !!founderInfos[index - 1]
  );

  const hasNextFounderEntry = (index: number) => (
    index < founderInfos.length - 1 && !!founderInfos[index + 1]
  );

  const getLinkedInLabel = (index: number) => {
    if (linkedInUrlFocusIndex === index || linkedInUrls[index]) {
      return linkedInUrls[index].includes('@') ? 'Email' : 'LinkedIn';
    }

    return 'LinkedIn URL or business email';
  };

  useEffect(() => {
    const errorGroupInputs = textInputRefs.current.filter((input) => !!input);
    const firstErrorGroupInputs = errorGroupInputs.length > 0
      ? (errorGroupInputs.find((group) => !!group.length) || [])
      : [];
    const firstErrorInput = firstErrorGroupInputs.length > 0
      ? firstErrorGroupInputs.find((input) => !!input)
      : undefined;
    firstErrorInput?.focus();
  }, [validationInfo]);

  useEffect(() => {
    setFounderInfos(manualFormData?.founderInfos || []);
  }, [manualFormData]);

  useEffect(() => {
    if (linkedInProfileRequest.index === undefined) {
      return;
    }

    if (linkedInProfileRequest.lastError) {
      const errors = [...linkedInUrlErrors];
      errors[linkedInProfileRequest.index] = 'Having trouble getting info. Try again.';
      setLinkedInUrlErrors(errors);
    } else {
      setLinkedInUrlErrors((manualFormData?.founderInfos || []).map(() => ''));
    }
  }, [linkedInProfileRequest]);

  return (
    <Grid container spacing={3}>
      {(founderInfos || []).map((founder, index) => (
        <Grid key={index} item xs={4}>
          <Typography variant="h5" textAlign="center" className={classes.founderTitle} color="inherit">
            {`Founder ${index + 1}`}
          </Typography>
          <Paper
            className={
              classNames(
                classes.root,
                'tertiary',
                'light',
                'dense',
                founderToBeRemovedIndex === index && 'crop',
              )
            }
            classes={commonCss.panels.leafPanel}
            elevation={0}
          >
            {!founder && (
              <div>
                <Box marginTop={5} marginBottom={5}>
                  <Typography variant="body2" className={classes.linkedInHeader} textAlign="center">
                    Enter Founder's LinkedIn URL
                  </Typography>
                  <Box marginTop={2}>
                    <TextField
                      {...commonProps.textField({
                        label: getLinkedInLabel(index),
                        color: 'tertiary',
                      })}
                      fullWidth
                      size="small"
                      disabled={!hasPreviousFounderEntry(index)}
                      autoFocus
                      type="url"
                      value={linkedInUrls[index] || ''}
                      onChange={(event) => updateLinkedInUrl(index, event.currentTarget.value)}
                      error={!!linkedInUrlErrors[index]}
                      helperText={linkedInUrlErrors[index]}
                      onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'linkedInUrl' })}
                      onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                      onFocus={() => {
                        setLinkedInInputFocusIndex(index);
                        setFocusedDataInfoKey({ dataInfoKey: 'linkedInUrl' });
                      }}
                      onBlur={() => {
                        setLinkedInInputFocusIndex(undefined);
                        setFocusedDataInfoKey({ dataInfoKey: undefined });
                      }}
                    />
                  </Box>
                  <Box marginTop={2} textAlign="center">
                    <Button
                      classes={commonCss.buttons.roundButton}
                      className={classNames(classes.formButton, 'tertiary')}
                      variant="contained"
                      disabled={
                        !hasPreviousFounderEntry(index)
                        || (
                          linkedInProfileRequest.isRequesting
                          && index === linkedInProfileRequest.index
                        )
                      }
                      onClick={() => findLinkedProfile(index)}
                    >
                      {linkedInProfileRequest.isRequesting
                        && index === linkedInProfileRequest.index && (
                        <Box marginRight={1}>
                          <CircularProgress color="inherit" size={12} />
                        </Box>
                      )}
                      Load Data
                    </Button>
                  </Box>
                  <Box marginTop={10} textAlign="center">
                    <Link
                      component="button"
                      color="inherit"
                      className={classes.manualFormLink}
                      onClick={() => fillManualForm(index)}
                      disabled={!hasPreviousFounderEntry(index)}
                    >
                      Fill manual form
                    </Link>
                  </Box>
                </Box>
              </div>
            )}
            {founder && (
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Button
                      classes={commonCss.buttons.generalButton}
                      variant="outlined"
                      className={
                        classNames(
                          classes.founderImage,
                          commonCss.boxes.imageBox,
                          'leaf',
                          'tertiary',
                        )
                      }
                      onClick={() => uploadImageInputFiles[index].current?.click()}
                      onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'founderImage' })}
                      onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                      onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'founderImage' })}
                      onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                    >
                      {founder?.photoUrl ? (
                        <Box
                          className={classes.founderImage}
                          component="img"
                          src={founder?.photoUrl}
                        />
                      ) : (
                        <Box className={classes.uploadImageIcon}>
                          <UploadImageIcon />
                        </Box>
                      )}
                    </Button>
                    <input
                      type="file"
                      ref={uploadImageInputFiles[index]}
                      style={{ display: 'none' }}
                      accept=".jpg,.jpeg,.png"
                      onChange={(event) => {
                        void handleImageFileSelected(index, event.currentTarget.files);
                      }}
                    />
                  </Grid>
                  <Grid item container xs={7} spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        {...commonProps.textField({
                          label: 'First Name',
                          color: 'tertiary',
                          inputRef: (
                            hasErrorInfo(validationInfo?.founders?.[index]?.founderFirstName)
                              ? ((r) => { textInputRefs.current[index][0] = r; })
                              : undefined
                          ),
                          required: true,
                          maxLength: FOUNDER_NAME_MAX_LENGTH,
                        })}
                        fullWidth
                        size="small"
                        value={founder?.founderFirstName || ''}
                        onChange={(event) => updateFounderInfoField(index, 'founderFirstName', event.currentTarget.value)}
                        error={hasErrorInfo(getValidationInfo(index).founderFirstName)}
                        helperText={getFirstError(getValidationInfo(index).founderFirstName)}
                        onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'founderFirstName' })}
                        onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                        onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'founderFirstName' })}
                        onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        {...commonProps.textField({
                          label: 'Last Name',
                          color: 'tertiary',
                          inputRef: (
                            hasErrorInfo(validationInfo?.founders?.[index]?.founderLastName)
                              ? ((r) => { textInputRefs.current[index][1] = r; })
                              : undefined
                          ),
                          required: true,
                          maxLength: FOUNDER_NAME_MAX_LENGTH,
                        })}
                        fullWidth
                        size="small"
                        value={founder?.founderLastName || ''}
                        onChange={(event) => updateFounderInfoField(index, 'founderLastName', event.currentTarget.value)}
                        error={hasErrorInfo(getValidationInfo(index).founderLastName)}
                        helperText={getFirstError(getValidationInfo(index).founderLastName)}
                        onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'founderLastName' })}
                        onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                        onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'founderLastName' })}
                        onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                        style={{ marginTop: 10 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...commonProps.textField({
                        label: 'Title',
                        color: 'tertiary',
                        maxLength: FOUNDER_TITLE_MAX_LENGTH,
                      })}
                      fullWidth
                      size="small"
                      value={founder?.title || ''}
                      onChange={(event) => updateFounderInfoField(index, 'title', event.currentTarget.value)}
                      onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'title' })}
                      onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                      onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'title' })}
                      onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...commonProps.textField({ label: 'Email', color: 'tertiary' })}
                      fullWidth
                      size="small"
                      value={founder?.founderEmail || ''}
                      onChange={(event) => updateFounderInfoField(index, 'founderEmail', event.currentTarget.value)}
                      onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'founderEmail' })}
                      onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                      onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'founderEmail' })}
                      onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...commonProps.textField({
                        label: 'Working Experience',
                        color: 'tertiary',
                        inputRef: (
                          hasErrorInfo(validationInfo?.founders?.[index]?.workExp)
                            ? ((r) => { textInputRefs.current[index][2] = r; })
                            : undefined
                        ),
                        required: true,
                        endAdornment: getUpDownEndAdornment({
                          currentValue: founder?.workExp || 0,
                          minValue: 0,
                          onUp: () => updateFounderInfoField(
                            index,
                            'workExp',
                            Number(founder?.workExp || 0) + 1,
                          ),
                          onDown: () => updateFounderInfoField(
                            index,
                            'workExp',
                            Number(founder?.workExp || 0) - 1,
                          ),
                        }),
                        min: 0,
                      })}
                      {...getNumberFieldProps({
                        maxLength: 2,
                      })}
                      fullWidth
                      size="small"
                      type="number"
                      value={isNullOrUndefined(founder?.workExp) ? '' : founder?.workExp}
                      onChange={(event) => updateFounderInfoField(index, 'workExp', event.currentTarget.value || '')}
                      error={hasErrorInfo(getValidationInfo(index).workExp)}
                      helperText={getFirstError(getValidationInfo(index).workExp)}
                      onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'workExp' })}
                      onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                      onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'workExp' })}
                      onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...commonProps.textField({
                        label: 'Prior Employers',
                        color: 'tertiary',
                        inputRef: (
                          hasErrorInfo(validationInfo?.founders?.[index]?.numPriorJobs)
                            ? ((r) => { textInputRefs.current[index][3] = r; })
                            : undefined
                        ),
                        required: true,
                        endAdornment: getUpDownEndAdornment({
                          currentValue: founder?.numPriorJobs || 0,
                          minValue: 0,
                          onUp: () => updateFounderInfoField(
                            index,
                            'numPriorJobs',
                            Number(founder?.numPriorJobs || 0) + 1,
                          ),
                          onDown: () => updateFounderInfoField(
                            index,
                            'numPriorJobs',
                            Number(founder?.numPriorJobs || 0) - 1,
                          ),
                        }),
                        min: 0,
                      })}
                      {...getNumberFieldProps({
                        maxLength: 2,
                      })}
                      fullWidth
                      size="small"
                      type="number"
                      value={isNullOrUndefined(founder?.numPriorJobs) ? '' : founder?.numPriorJobs}
                      onChange={(event) => updateFounderInfoField(index, 'numPriorJobs', event.currentTarget.value || '')}
                      error={hasErrorInfo(getValidationInfo(index).numPriorJobs)}
                      helperText={getFirstError(getValidationInfo(index).numPriorJobs)}
                      onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'numPriorJobs' })}
                      onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                      onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'numPriorJobs' })}
                      onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                      disabled={!Number(founder?.workExp)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...commonProps.textField({
                        label: 'Companies Founded',
                        color: 'tertiary',
                        inputRef: (
                          hasErrorInfo(validationInfo?.founders?.[index]?.numCompFounded)
                            ? ((r) => { textInputRefs.current[index][3] = r; })
                            : undefined
                        ),
                        required: true,
                        endAdornment: getUpDownEndAdornment({
                          currentValue: founder?.numCompFounded || 0,
                          minValue: 0,
                          onUp: () => updateFounderInfoField(
                            index,
                            'numCompFounded',
                            Number(founder?.numCompFounded || 0) + 1,
                          ),
                          onDown: () => updateFounderInfoField(
                            index,
                            'numCompFounded',
                            Number(founder?.numCompFounded || 0) - 1,
                          ),
                        }),
                        min: 0,
                      })}
                      {...getNumberFieldProps({
                        maxLength: 2,
                      })}
                      fullWidth
                      size="small"
                      type="number"
                      value={isNullOrUndefined(founder?.numCompFounded) ? '' : founder?.numCompFounded}
                      onChange={(event) => updateFounderInfoField(index, 'numCompFounded', event.currentTarget.value || '')}
                      error={hasErrorInfo(getValidationInfo(index).numCompFounded)}
                      helperText={getFirstError(getValidationInfo(index).numCompFounded)}
                      onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'numCompFounded' })}
                      onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                      onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'numCompFounded' })}
                      onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                      disabled={!Number(founder?.workExp)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  columnSpacing={0}
                  style={{
                    marginTop: theme.spacing(0.5),
                    marginBottom: theme.spacing(-0.5),
                  }}
                >
                  <Grid item xs={7}>
                    <FormControlLabel
                      {...commonProps.formControlLabel({ color: 'tertiary' })}
                      control={<Checkbox color="secondary" />}
                      label="Was CEO"
                      checked={founder?.wasCeo || false}
                      onChange={(_, checked) => updateFounderInfoField(index, 'wasCeo', checked)}
                      onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'wasCeo' })}
                      onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                      onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'wasCeo' })}
                      onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                      disabled={!Number(founder?.workExp)}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormControlLabel
                      {...commonProps.formControlLabel({ color: 'tertiary' })}
                      control={<Checkbox color="secondary" />}
                      label="FAAMG"
                      checked={founder?.wasFaamg || false}
                      onChange={(_, checked) => updateFounderInfoField(index, 'wasFaamg', checked)}
                      onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'wasFaamg' })}
                      onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                      onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'wasFaamg' })}
                      onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                      disabled={!Number(founder?.workExp)}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={0} marginTop={2}>
                  <Grid item xs={12}>
                    <TextField
                      {...commonProps.textField({
                        label: 'Number of Degrees',
                        color: 'tertiary',
                        inputRef: (
                          hasErrorInfo(validationInfo?.founders?.[index]?.numOfAcDeg)
                            ? ((r) => { textInputRefs.current[index][4] = r; })
                            : undefined
                        ),
                        required: true,
                        endAdornment: getUpDownEndAdornment({
                          currentValue: founder?.numOfAcDeg || 0,
                          minValue: 0,
                          onUp: () => updateFounderInfoField(
                            index,
                            'numOfAcDeg',
                            Number(founder?.numOfAcDeg || 0) + 1,
                          ),
                          onDown: () => updateFounderInfoField(
                            index,
                            'numOfAcDeg',
                            Number(founder?.numOfAcDeg || 0) - 1,
                          ),
                        }),
                        min: 0,
                      })}
                      {...getNumberFieldProps({
                        maxLength: 2,
                      })}
                      fullWidth
                      size="small"
                      type="number"
                      value={isNullOrUndefined(founder?.numOfAcDeg) ? '' : founder?.numOfAcDeg}
                      onChange={(event) => updateFounderInfoField(index, 'numOfAcDeg', event.currentTarget.value || '')}
                      error={hasErrorInfo(getValidationInfo(index).numOfAcDeg)}
                      helperText={getFirstError(getValidationInfo(index).numOfAcDeg)}
                      onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'numOfAcDeg' })}
                      onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                      onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'numOfAcDeg' })}
                      onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    style={{
                      marginTop: theme.spacing(0.5),
                      marginBottom: theme.spacing(-1.5),
                    }}
                  >
                    <FormControlLabel
                      {...commonProps.formControlLabel({ color: 'tertiary' })}
                      control={<Checkbox color="secondary" />}
                      label="Science Degree"
                      checked={founder?.hasScienceDeg || false}
                      onChange={(_, checked) => updateFounderInfoField(index, 'hasScienceDeg', checked)}
                      onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'hasScienceDeg' })}
                      onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                      onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'hasScienceDeg' })}
                      onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    style={{
                      marginTop: theme.spacing(0.5),
                      marginBottom: theme.spacing(-1.5),
                    }}
                  >
                    <FormControlLabel
                      {...commonProps.formControlLabel({ color: 'tertiary' })}
                      control={<Checkbox color="secondary" />}
                      label="MBA"
                      checked={founder?.hasMbaDeg || false}
                      onChange={(_, checked) => updateFounderInfoField(index, 'hasMbaDeg', checked)}
                      onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'hasMbaDeg' })}
                      onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                      onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'hasMbaDeg' })}
                      onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                      disabled={
                        !founder?.numOfAcDeg
                        || Number(founder?.numOfAcDeg) < 1
                        || (
                          !founder?.hasMbaDeg
                          && Boolean(founder?.hasPhdDeg)
                          && (founder?.numOfAcDeg || 0) < 2
                        )
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={7}
                  >
                    <FormControlLabel
                      {...commonProps.formControlLabel({ color: 'tertiary' })}
                      control={<Checkbox color="secondary" />}
                      label="Top University"
                      checked={founder?.isTop10Uni || false}
                      onChange={(_, checked) => updateFounderInfoField(index, 'isTop10Uni', checked)}
                      onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'isTop10Uni' })}
                      onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                      onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'isTop10Uni' })}
                      onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                  >
                    <FormControlLabel
                      {...commonProps.formControlLabel({ color: 'tertiary' })}
                      control={<Checkbox color="secondary" />}
                      label="Ph.D."
                      checked={founder?.hasPhdDeg || false}
                      onChange={(_, checked) => updateFounderInfoField(index, 'hasPhdDeg', checked)}
                      onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: 'hasPhdDeg' })}
                      onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                      onFocus={() => setFocusedDataInfoKey({ dataInfoKey: 'hasPhdDeg' })}
                      onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                      disabled={
                        !founder?.numOfAcDeg
                        || Number(founder?.numOfAcDeg) < 1
                        || (
                          !founder?.hasPhdDeg
                          && Boolean(founder?.hasMbaDeg)
                          && (founder?.numOfAcDeg || 0) < 2
                        )
                      }
                    />
                  </Grid>
                </Grid>
                {!hasNextFounderEntry(index) && (
                  <div className={classes.removeButton}>
                    <IconButton
                      color="inherit"
                      classes={commonCss.buttons.circularButton}
                      className={classNames('tertiary', 'opaque')}
                      size="small"
                      onClick={() => confirmRemoveFounderInfo(index)}
                    >
                      <CloseIcon color="inherit" fontSize="small" />
                    </IconButton>
                  </div>
                )}
              </div>
            )}
            {founderToBeRemovedIndex === index && (
              <motion.div
                className={classes.removeFounderBox}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
              >
                <motion.div
                  initial={{
                    y: 20,
                    opacity: 0,
                  }}
                  animate={{
                    y: 0,
                    opacity: 1,
                  }}
                >
                  <Paper
                    classes={commonCss.panels.leafPanel}
                    className="dense"
                  >
                    <Typography variant="body1" color="inherit">
                      Are you sure you want to remove this founder?
                    </Typography>
                    <Box
                      marginTop={2}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Button
                        variant="outlined"
                        classes={commonCss.buttons.roundButton}
                        className={classes.formButton}
                        color="info"
                        onClick={() => setFounderToBeRemovedIndex(undefined)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        classes={commonCss.buttons.roundButton}
                        className={classNames(classes.formButton, 'tertiary')}
                        sx={{ marginTop: theme.spacing(1) }}
                        onClick={() => removeFounderInfo(index)}
                      >
                        OK
                      </Button>
                    </Box>
                  </Paper>
                </motion.div>
              </motion.div>
            )}
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default FoundersForm;
