import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';

import dimensions from '../../theme/dimensions';

const styles = (theme: Theme): StyleRules<{}, string> => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    left: 0,
    top: 0,
    background: theme.palette.grey[800],
    borderBottomRightRadius: theme.spacing(10),
    width: dimensions.navMenu.width,
    backdropFilter: 'blur(6px)',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    boxSizing: 'border-box',
    overflow: 'hidden',
    transition: 'all ease-out .3s',
    boxShadow: '0 6px 9px 0 rgba(0, 0, 0, 0.16)',

    '&.hover': {
      background: Color(theme.palette.grey[900]).alpha(0.9).toString(),
      width: theme.spacing(30),
      '& $menuTypography': {
        opacity: 1,
      },
      '& $homeButton': {
        marginLeft: `${theme.spacing(2)} !important`,
      },
    },

    '&.collapsed': {
      paddingBottom: theme.spacing(4),
    },

    zIndex: 100,
  },
  homeButton: {
    transition: 'all ease-out .3s !important',
    marginLeft: `${theme.spacing(0)} !important`,
  },
  menuContainer: {
    transition: 'all ease-out .3s !important',
  },
  menuItem: {
    paddingLeft: `${theme.spacing(1.5)} !important`,
    marginTop: `${theme.spacing(0.75)} !important`,
    transition: 'all ease-out .3s',
    '&.collapsed': {
      paddingTop: '4px !important',
      paddingBottom: '4px !important',
    },
    '& .MuiListItemIcon-root': {
      transition: 'all ease-out .3s',
      minWidth: 50,
    },
    '& .MuiListItemText-root': {
      transition: 'all ease-out .3s',
      display: 'flex',
      alignItems: 'center',
    },
    '&.collapsed .MuiListItemIcon-root': {
      minWidth: '0 !important',
      width: '8px !important',
    },
    '&.collapsed .MuiListItemText-root': {
      transform: 'scale(0, 0)',
      height: 0,
    },
  },
  menuIconBox: {
    paddingTop: theme.spacing(0.5),
    transition: 'all ease-out .3s !important',
    '& > svg': {
      transition: 'all ease-out .3s !important',
    },
    '&.active > svg .cls-invert': {
      stroke: 'rgb(98, 98, 98)',
      // borderColor: `${theme.palette.grey[900]} !important`,
    },
    '&.collapsed': {
      borderRadius: '50% !important',
      '& > svg': {
        opacity: 0,
      },
      '&.active': {
        // borderColor: `${theme.palette.grey[900]} !important`,
      },
    },
  },
  menuText: {
    marginLeft: theme.spacing(1.5),
  },
  menuTypography: {
    opacity: 0,
    transition: 'all ease-out .3s',
  },
});

export default styles;
