import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles, { getContrastColorModifierClasses } from '../../../../theme/commonStyles';
import {
  otherAltLightColorSets,
  otherBorderColorSets,
  otherContrastColorSets,
  otherLightColorSets,
} from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      boxSizing: 'border-box',
      paddingLeft: theme.spacing(8),
      paddingTop: theme.spacing(4),
      [theme.breakpoints.down('xl')]: {
        paddingLeft: theme.spacing(8),
      },
    },
    title: {
      fontWeight: '100 !important',
      [theme.breakpoints.down('xl')]: {
        marginTop: `${theme.spacing(2.5)} !important`,
        fontSize: '1.6rem !important',
      },
    },
    reportContainer: {
      display: 'flex',
    },
    groupHeader: {
      position: 'relative',
      height: 1,
      zIndex: 2,
      pointerEvents: 'none',
    },
    groupItemsContainer: {
      '& $reportContainer:first-child': {
        '& $rowHeader': {
          paddingTop: theme.spacing(3),
        },
        '& $competitorColumnWrapper': {
          paddingTop: theme.spacing(3),
        },
      },
      '& $reportContainer:not(:first-child)': {
        '& $rowHeader': {
          paddingTop: theme.spacing(0.5),
        },
        '& $competitorColumnWrapper': {
          paddingTop: theme.spacing(0.5),
        },
      },
      '& $reportContainer:last-child': {
        '& $rowHeader': {
          paddingBottom: theme.spacing(1),
          marginBottom: theme.spacing(-0.5),
        },
        '& $competitorColumnWrapper': {
          paddingBottom: theme.spacing(1),
          marginBottom: theme.spacing(-0.5),
        },
      },
      '& $reportContainer:not(:last-child)': {
        '& $competitorColumnWrapper': {
          paddingBottom: theme.spacing(0.5),
        },
      },
    },
    rowHeader: {
      flexBasis: theme.spacing(25),
      flexGrow: 0,
      flexShrink: 1,
      [theme.breakpoints.down('xl')]: {
        flexBasis: theme.spacing(14),
      },
    },
    subRowHeader: {
      overflow: 'hidden',
    },
    rowBody: {
      flex: 1,
    },
    competitorColumn: {
      '&:not(:last-child)': {
        borderRight: `0.5px solid ${theme.palette.grey[50]}`,
      },
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      height: '100%',
    },
    competitorColumnWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      position: 'relative',
      '&.emphasize': {
        '& $rankValue': {
          color: `${theme.palette.grey[900]} !important`,
          fontWeight: 'normal',
        },
      },
      '&.primary': {
        backgroundColor: otherAltLightColorSets[0],
      },
      '&.secondary': {
        backgroundColor: otherAltLightColorSets[1],
      },
      '&.tertiary': {
        backgroundColor: otherAltLightColorSets[2],
      },
      '&.quaternary': {
        backgroundColor: otherAltLightColorSets[3],
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        transition: 'background-color 0.3s ease-out',
        backgroundColor: 'transparent',
      },
      '&.highlight': {
        backgroundColor: 'transparent',
        '&:before': {
          content: '""',
          backgroundColor: otherLightColorSets[4],
          zIndex: 0,
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 1,
        },
        '&.primary': {
          '&:after': {
            backgroundColor: otherAltLightColorSets[0],
          },
        },
        '&.secondary': {
          '&:after': {
            backgroundColor: otherAltLightColorSets[1],
          },
        },
        '&.tertiary': {
          '&:after': {
            backgroundColor: otherAltLightColorSets[2],
          },
        },
        '&.quaternary': {
          '&:after': {
            backgroundColor: otherAltLightColorSets[3],
          },
        },
      },
      '&.clickable': {
        cursor: 'pointer',
      },
      '& > *': {
        zIndex: 2,
      },
    },
    rankingNumber: {
      fontSize: '1.75rem !important',
      lineHeight: '1.67 !important',
      color: `${theme.palette.grey[900]} !important`,
      '& small': {
        fontSize: '1rem !important',
      },
      '&.bolder': {
        fontWeight: 400,
      },
      [theme.breakpoints.down('xl')]: {
        fontSize: '1.5rem !important',
      },
    },
    companyName: {
      lineHeight: '1.19 !important',
      textAlign: 'center',
      height: 40,
      overflow: 'hidden',
      '&.bolder': {
        fontWeight: 'normal',
      },
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      [theme.breakpoints.down('xl')]: {
        fontSize: '1rem !important',
        height: 32,
      },
    },
    companyLogoContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(5),
    },
    buttonContainer: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('xl')]: {
        marginTop: theme.spacing(4.25),
      },
    },
    selectCompetitorsButton: {
      fontWeight: '500 !important',
      fontSize: '1.142rem !important',
      width: 173,
      borderRadius: `${theme.spacing(2)} !important`,
      lineHeight: '1.2 !important',
      paddingTop: `${theme.spacing(0.75)} !important`,
      paddingBottom: `${theme.spacing(0.75)} !important`,
      [theme.breakpoints.down('xl')]: {
        width: '100%',
      },
    },
    groupTitle: {
      fontSize: '1.4825rem !important',
      marginTop: `${theme.spacing(-2.25)} !important`,
      marginRight: `${theme.spacing(1)} !important`,
      '&.primary': {
        color: otherContrastColorSets[0],
      },
      '&.secondary': {
        color: otherContrastColorSets[1],
      },
      '&.tertiary': {
        color: otherContrastColorSets[2],
      },
      '&.quaternary': {
        color: otherContrastColorSets[3],
      },
      [theme.breakpoints.down('xl')]: {
        fontSize: '1.25rem !important',
        marginTop: `${theme.spacing(-1.75)} !important`,
      },
    },
    subjectHeadDivider: {
      flex: 1,
      borderBottom: 'none !important',
      borderTop: '1px solid #ccc !important',
      '&.primary': {
        borderColor: `${otherBorderColorSets[0]} !important`,
      },
      '&.secondary': {
        borderColor: `${otherBorderColorSets[1]} !important`,
      },
      '&.tertiary': {
        borderColor: `${otherBorderColorSets[2]} !important`,
      },
      '&.quaternary': {
        borderColor: `${otherBorderColorSets[3]} !important`,
      },
      pointerEvents: 'none',
      zIndex: 1,
    },
    scoreBox: {
      marginTop: theme.spacing(-1.5),
      marginBottom: 5,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      color: `${theme.palette.common.white} !important`,
      zIndex: 1,
      ...getContrastColorModifierClasses('background'),
    },
    subTitle: {
      color: `${theme.palette.grey[500]} !important`,
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('xl')]: {
        fontSize: '1rem !important',
      },
    },
    groupSeparator: {
      height: 'calc((100vh - 820px) / 3 + 1px)',
      marginBottom: -1,
      '& $reportContainer': {
        height: '100%',
        '& .MuiGrid-container': {
          height: '100%',
        },
      },
    },
    calcStatusBox: {
      borderRadius: 11.5,
      background: theme.palette.common.white,
      border: '1px solid #eaeaea',
      padding: '0px 4px',
      width: 89,
      textAlign: 'center',
      textTransform: 'capitalize',
      marginTop: -2,
      lineHeight: 1.79,
      ...getContrastColorModifierClasses('color'),
      [theme.breakpoints.down('xl')]: {
        width: 70,
      },
    },
    rankValue: {
      color: `${theme.palette.grey[500]} !important`,
      fontWeight: 300,
    },
  });
};

export default styles;
