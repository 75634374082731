import { all, AllEffect } from 'redux-saga/effects';

import sessionSaga from './features/session/saga';
import mainSaga from './features/main/saga';
import reportSaga from './features/report/saga';
import notifierSaga from './features/notifier/saga';
import autocompleteSaga from './features/autocomplete/saga';
import searchSaga from './features/search/saga';
import manualFormSaga from './features/manualForm/saga';
import gptSaga from './features/gpt/saga';
import relationChartSaga from './features/relationChart/saga';

export default function* sagas(): Generator<AllEffect<unknown>, void, unknown> {
  yield all([
    sessionSaga(),
    mainSaga(),
    notifierSaga(),
    reportSaga(),
    autocompleteSaga(),
    manualFormSaga(),
    searchSaga(),
    gptSaga(),
    relationChartSaga(),
  ]);
}
