import { getYear } from 'date-fns';
import { isNullOrUndefined } from '../../../core/utils/dataUtils';
import {
  FounderInfo,
  ManualFormData,
  ManualFormState,
  ManualFormValidationInfo,
} from './types';

export const COMPANY_FOUNDING_YEARS_MIN = 1960;
export const COMPANY_NAME_MAX_LENGTH = 25;
export const COMPANY_DESC_MIN_LENGTH = 30;
export const COMPANY_DESC_MAX_LENGTH = 320;
export const FOUNDER_NAME_MAX_LENGTH = 15;
export const FOUNDER_TITLE_MAX_LENGTH = 25;

const isEmptyString = (text?: string) => (text || '').trim() === '';
const isEmptyNumber = (number?: number) => isNullOrUndefined(number) || String(number) === '';
const isGreaterThan = (maxNumber: number, number?: number) => (Number(number) || 0) > maxNumber;
const isLessThan = (minNumber: number, number?: number) => (Number(number) || 0) < minNumber;

const validateCompanyInfo = (state: ManualFormState) => ({
  ...state.validationInfo,
  company: {
    compName: [
      isEmptyString(state.manualFormData?.companyInfo?.compName) && 'Startup Name is required',
    ].filter(Boolean),
    yearFounded: [
      isEmptyNumber(state.manualFormData?.companyInfo?.yearFounded) && 'Founding Year is required',
      isLessThan(
        COMPANY_FOUNDING_YEARS_MIN,
        state.manualFormData?.companyInfo?.yearFounded,
      ) && `Founding Year must not be before ${COMPANY_FOUNDING_YEARS_MIN}`,
      isGreaterThan(
        getYear(new Date()),
        state.manualFormData?.companyInfo?.yearFounded,
      ) && 'Founding Year must not over current year',
    ].filter(Boolean),
    compCountry: [
      isEmptyString(state.manualFormData?.companyInfo?.compCountry) && 'Country is required',
    ].filter(Boolean),
    compDesc: [
      isEmptyString(state.manualFormData?.companyInfo?.compDesc)
        && 'Description is required',
      isLessThan(
        COMPANY_DESC_MIN_LENGTH,
        state.manualFormData?.companyInfo?.compDesc?.length || 0,
      ) && `Description must be at least ${COMPANY_DESC_MIN_LENGTH} characters`,
      isGreaterThan(
        COMPANY_DESC_MAX_LENGTH,
        state.manualFormData?.companyInfo?.compDesc?.length || 0,
      ) && `Description must not exceeds ${COMPANY_DESC_MAX_LENGTH} characters`,
    ].filter(Boolean),
  },
} as ManualFormValidationInfo);

const validateFounderInfo = (state: ManualFormState) => ({
  ...state.validationInfo,
  founders: state.manualFormData?.founderInfos
    && state.manualFormData?.founderInfos
      .filter((founderInfo) => !!founderInfo)
      .map((founderInfo) => ({
        founderFirstName: [
          isEmptyString(founderInfo?.founderFirstName) && 'First Name is required',
          isGreaterThan(15, founderInfo?.founderFirstName?.length || 0)
            && 'First Name must not exceeds 15 characters',
        ].filter(Boolean),
        founderLastName: [
          isEmptyString(founderInfo?.founderLastName) && 'Last Name is required',
          isGreaterThan(15, founderInfo?.founderLastName?.length || 0)
            && 'Last Name must not exceeds 15 characters',
        ].filter(Boolean),
        workExp: [
          isEmptyNumber(founderInfo?.workExp) && 'Working Experience is required',
          isLessThan(0, founderInfo?.workExp) && 'Invalid Working Experience value',
          isGreaterThan(70, founderInfo?.workExp) && 'Working Experience cannot be over 70 years',
        ].filter(Boolean),
        numPriorJobs: [
          isEmptyNumber(founderInfo?.numPriorJobs) && 'Prior Employers is required',
          isLessThan(0, founderInfo?.numPriorJobs) && 'Invalid Prior Employers value',
          ((Number(founderInfo?.numPriorJobs) || 0) > 0 && Number(founderInfo?.numPriorJobs) === 0)
            && 'Prior Employers must be at least 1 year when Working Experience is greater than 0 year',
        ].filter(Boolean),
        numCompFounded: [
          isLessThan(0, founderInfo?.numCompFounded) && 'Invalid Companies Founded value',
        ].filter(Boolean),
        numOfAcDeg: [
          isLessThan(0, founderInfo?.numOfAcDeg) && 'Invalid Number of Degrees value',
          isEmptyNumber(founderInfo?.numOfAcDeg) && 'Number of Degrees is required',
        ].filter(Boolean),
      })),
} as ManualFormValidationInfo);

const validateFounderInteractions = (state: ManualFormState) => {
  if ((state.manualFormData?.founderInfos?.length || 0) <= 1) {
    return ({
      ...state.validationInfo,
      interactions: {
        workAlumni: [] as string[],
        schoolAlumni: [] as string[],
      },
    } as ManualFormValidationInfo);
  }

  const founderInfos = state.manualFormData?.founderInfos || [];

  return ({
    ...state.validationInfo,
    interactions: {
      workAlumni: [
        founderInfos.filter((founder) => !!founder?.workAlumni).length === 1
          && 'Please select 2 or more founders for Work Alumni',
      ].filter(Boolean),
      schoolAlumni: [
        founderInfos.filter((founder) => !!founder?.schoolAlumni).length === 1
          && 'Please select 2 or more founders for School Alumni',
      ].filter(Boolean),
    },
  } as ManualFormValidationInfo);
};

export const validate = (state: ManualFormState) => {
  if (state.currentStep === 0) {
    return validateCompanyInfo(state);
  }

  if (state.currentStep === 1) {
    return validateFounderInfo(state);
  }

  if (state.currentStep === 2) {
    return validateFounderInteractions(state);
  }

  return state.validationInfo;
};

export const hasErrorInStep = (step: number, validationInfo?: ManualFormValidationInfo) => {
  if (!validationInfo) {
    return false;
  }

  if (step === 0) {
    return !!Object.keys(validationInfo?.company || {})
      .find((key) => (validationInfo.company as Record<string, string[]>)[key]?.length > 0);
  }

  if (step === 1) {
    return !!(validationInfo.founders || [])
      .filter((founder) => !!founder)
      .find((founder) => (
        !!Object.keys(founder)
          .find((key) => (founder as Record<string, string[]>)[key]?.length > 0)
      ));
  }

  if (step === 2) {
    return !!Object.keys(validationInfo?.interactions || {})
      .find((key) => (validationInfo.interactions as Record<string, string[]>)[key]?.length > 0);
  }

  return false;
};

export const hasError = (validationInfo?: ManualFormValidationInfo) => {
  if (!validationInfo) {
    return false;
  }

  return hasErrorInStep(0)
    || hasErrorInStep(1)
    || hasErrorInStep(2);
};

export const hasErrorInfo = (errorInfos?: string[]) => (
  (errorInfos?.length || 0) > 0
);

export const getFirstError = (errorInfos?: string[]) => (
  errorInfos && errorInfos.length > 0 ? errorInfos[0] : undefined
);

export const getFilledFounders = (manualFormData?: ManualFormData) => (
  (manualFormData?.founderInfos || [])
    .filter((founderInfo): founderInfo is FounderInfo => !!founderInfo)
);

export const isTeamRelevant = (manualFormData?: ManualFormData) => (
  getFilledFounders(manualFormData).length >= 2
);

export const getTooltipLabel = (fieldName?: string) => {
  switch (fieldName) {
    case 'compName':
      return 'Enter Startup name without legal abbreviations';
    case 'yearFounded':
      return 'The year the founders invested significant time and resources in the venture';
    case 'compLogo':
      return 'Add a jpeg/png file of less than 100kb';
    case 'website':
      return 'Enter startup website';
    case 'compCountry':
      return 'Choose the country';
    case 'compState':
      return 'Choose the state/province';
    case 'compDesc':
      return `Provide a general business description of the Startup (${COMPANY_DESC_MIN_LENGTH}-${COMPANY_DESC_MAX_LENGTH} chars)`;
    case 'compKwords':
      return 'Provide keywords that are associated with the Startup seperated with a comma';
    case 'founderFirstName':
      return 'Enter the founder\'s first name ';
    case 'founderLastName':
      return 'Enter the founder\'s last name';
    case 'founderEmail':
      return 'Enter  founder\'s email (optional), used only for the report';
    case 'workExp':
      return 'Enter the years of relevant working experience for this startup';
    case 'numPriorJobs':
      return 'Enter the number of prior job employers in the founder\'s relevant working experience';
    case 'numCompFounded':
      return 'Enter the number of companies founded prior to this startup';
    case 'wasCeo':
      return 'Fill the box if the founder has CEO experience';
    case 'wasFaamg':
      return 'Fill the box if the founder has significant experience working for Facebook, Amazon, Apple, Microsoft or Google';
    case 'numOfAcDeg':
      return 'Enter the number of degrees obtained from academic institutions (e.g. B.A., M.A., B.Sc, M.Sc, Ph.D., MBA etc.)';
    case 'hasScienceDeg':
      return 'Fill the box if the founder has a Bachelor or Masters of Science degrees';
    case 'hasMbaDeg':
      return 'Fill the box if the founder has a Master of Business Administration (MBA)';
    case 'isTop10Uni':
      return 'Fill the box if the founder has a relevant academic degree from a top tier university in your region';
    case 'hasPhdDeg':
      return 'Fill the box if the founder has a Doctor of Philosophy (Ph.D.) or Medical Doctor (MD)/Dentist (DMD)';
    case 'linkedInUrl':
      return 'Enter the founder\'s LinkedIn URL (optional), used only for the report';
    case 'founderImage':
      return 'Add a jpeg/png file of less than 100kb';
    case 'title':
      return 'Enter a short abbreviation of the founder\'s position in the Startup';
    case 'workAlumni':
      return 'Select if two or more founders have worked for the same company in the past';
    case 'schoolAlumni':
      return 'Select if two or more founders obtained their academic degrees from the same college/university';
    default:
      return 'Hover on object for additional details';
  }
};
