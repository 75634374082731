import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../types';
import {
  initializeManualForm,
  setStep,
  initiateManualFormSave,
  setIsCancelling,
} from './actions';

import ManualForm from './ManualForm';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.manualForm,
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    initializeManualForm,
    setStep,
    initiateManualFormSave,
    setIsCancelling,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ManualForm);
