import { Node, Edge } from '@sayari/trellis';
import { hierarchy, HierarchyPointNode } from 'd3-hierarchy';
import { GraphLookup, HierarchyData } from '../types';

export const createNodeIndex = <N extends Node>(nodes: N[]): Record<string, N> => (
  nodes.reduce((lookup, node) => ({
    ...lookup,
    [node.id]: node,
    ...(node.subgraph?.nodes
      ? createNodeIndex(node.subgraph.nodes)
      : {}
    ) as Record<string, N>,
  }), {})
);

export const createGraphIndex = <N extends Node, E extends Edge>(
  graph: { nodes: N[]; edges: E[] },
) => {
  const nodes = createNodeIndex(graph.nodes);

  return graph.edges.reduce<GraphLookup<N, E>>((lookup, edge) => {
    const updatedLookup = {
      ...lookup,
    };

    if (!!nodes[edge.source] && !!nodes[edge.target]) {
      if (lookup[edge.source] === undefined) {
        updatedLookup[edge.source] = { node: nodes[edge.source], paths: [] };
      }

      updatedLookup[edge.source].paths.push({ edge, node: nodes[edge.target] });

      if (lookup[edge.target] === undefined) {
        updatedLookup[edge.target] = { node: nodes[edge.target], paths: [] };
      }

      updatedLookup[edge.target].paths.push({ edge, node: nodes[edge.source] });
    }

    return updatedLookup;
  }, {});
};

export const graphToBFSHierarchy = <N extends Node, E extends Edge>(
  graphLookup: GraphLookup<N, E>,
  rootId: string,
): HierarchyData<N, E> => {
  const initialChildren: HierarchyData<N, E>[] = [];

  const queue: [string, HierarchyData<N, E>[]][] = [[rootId, initialChildren]];
  const visited = new Set<string>([rootId]);

  while (queue.length > 0) {
    const [id, children] = queue.shift()!;
    const { paths } = graphLookup[id];
    paths.forEach((path) => {
      const { node, edge } = path;
      if (!visited.has(node.id)) {
        visited.add(node.id);
        const grandChildren: HierarchyData<N, E>[] = [];
        children.push({
          root: false,
          edge,
          node,
          children: grandChildren,
        });

        queue.push([node.id, grandChildren]);
      }
    });
  }

  return {
    node: graphLookup[rootId].node,
    root: true,
    edge: null,
    children: initialChildren,
  };
};

export const graphToDFSHierarchy = <N extends Node, E extends Edge>(
  graphLookup: GraphLookup<N, E>,
  node: N,
  edge: E | null,
  visited = new Set<string>(),
): HierarchyData<N, E> => {
  visited.add(node.id);

  const children: HierarchyData<N, E>[] = [];
  const { paths } = graphLookup[node.id];
  paths.forEach((path) => {
    if (!visited.has(path.node.id)) {
      children.push(graphToDFSHierarchy(graphLookup, path.node, path.edge, visited));
    }
  });

  return !edge ? {
    root: true,
    node,
    edge,
    children,
  } : {
    root: false,
    node,
    edge,
    children,
  };
};

export const graphToHierarchy = <N extends Node, E extends Edge>(
  index: GraphLookup<N, E>,
  rootId: string,
  bfs = true,
) => (
    hierarchy(bfs
      ? graphToBFSHierarchy(index, rootId)
      : graphToDFSHierarchy(index, index[rootId].node, null))
  );

export const hierarchyToGraph = <N extends Node, E extends Edge>(
  hierarchyPointNode: HierarchyPointNode<HierarchyData<N, E>>,
): Record<string, HierarchyPointNode<HierarchyData<N, E>>> => ({
    [hierarchyPointNode.data.node.id]: hierarchyPointNode,
    ...(hierarchyPointNode.children?.reduce((lookup, child) => ({
      ...lookup,
      ...hierarchyToGraph(child),
    }), {} as Record<string, HierarchyPointNode<HierarchyData<N, E>>>)),
  });

export const containsSubgraphNode = (nodes: Node[], id: string): boolean => {
  if (nodes.some((node) => (
    node.id === id
    || (
      node.subgraph
      && containsSubgraphNode(node.subgraph?.nodes, id)
    )
  ))) {
    return true;
  }

  return false;
};

export const findAncestor = (nodes: Node[], id: string): string | undefined => (
  nodes.find((node) => (
    node.id === id
    || (
      node.subgraph
      && containsSubgraphNode(node.subgraph?.nodes, id)
    )
  ))?.id
);

export function findNodeIdWithMostConnections(edges: Edge[]): string {
  const connectionsCount: { [key: string]: number } = {};

  edges.forEach((edge) => {
    connectionsCount[edge.source] = (connectionsCount[edge.source] || 0) + 1;
    connectionsCount[edge.target] = (connectionsCount[edge.target] || 0) + 1;
  });

  const keys = Object.keys(connectionsCount);
  if (keys.length === 0) {
    return ''; // Return a default value if connectionsCount is empty
  }

  return keys.reduce((a, b) => (
    connectionsCount[a] > connectionsCount[b] ? a : b
  ));
}
