import { ReactNode } from 'react';
import NetworkState from '../../../core/types/network';
import { CompanyData } from '../report/types';
import { GlossaryItem } from '../search/types';
import { User } from '../session/types';

export enum MainActions {
  MESSAGE_BOX_OPEN = '@@main/MESSAGE_BOX_OPEN',
  MESSAGE_BOX_CLOSE = '@@main/MESSAGE_BOX_CLOSE',
  FEEDBACK_FORM_OPEN = '@@main/FEEDBACK_FORM_OPEN',
  FEEDBACK_FORM_CLOSE = '@@main/FEEDBACK_FORM_CLOSE',
  CONTACT_FORM_OPEN = '@@main/CONTACT_FORM_OPEN',
  CONTACT_FORM_CLOSE = '@@main/CONTACT_FORM_CLOSE',
  CONTACT_FORM_SUBMIT_REQUEST = '@@main/CONTACT_FORM_SUBMIT_REQUEST',
  CONTACT_FORM_SUBMITTED_SET = '@@main/CONTACT_FORM_SUBMITTED_SET',
  SUBSCRIBE_REQUEST = '@@main/SUBSCRIBE_REQUEST',
  SUBSCRIBED_SET = '@@main/SUBSCRIBED_SET',
  COMPANY_REPORT_OPEN = '@@main/COMPANY_REPORT_OPEN',
  GLOSSARY_BUBBLE_OPEN = '@@main/GLOSSARY_BUBBLE_OPEN',
  GLOSSARY_BUBBLE_CLOSE = '@@main/GLOSSARY_BUBBLE_CLOSE',
  GLOSSARY_ITEMS_REQUEST = '@@main/GLOSSARY_ITEMS_REQUEST',
  GLOSSARY_ITEMS_SET = '@@main/GLOSSARY_ITEMS_SET',
}

export type Page = 'portal'
| 'login'
| 'reports'
| 'manualForm';

export type Routes = Record<Page, string>;

export type MessageBoxType = 'info' | 'warning' | 'error' | 'question';

export interface MessageBoxCommand {
  title: string;
  action: () => void;
}

export interface MessageBoxInfo {
  title?: string;
  content?: string | ReactNode | ReactNode[];
  mode?: MessageBoxType;
  commands?: MessageBoxCommand[];
  dismissText?: string;
  blurBackdrop?: boolean;
  colorSet?: string;
  onDismiss?: Function;
}

export interface ContactFormInfo {
  user?: User;
  email?: string;
  name?: string;
  description?: string;
}

export type OpenMessageBoxArgs = MessageBoxInfo;

export interface RequestSubmitContactFormArgs {
  formInfo: ContactFormInfo;
}

export interface SetContactFormSubmittedArgs {
  error?: Error;
}

export interface RequestSubscribeArgs {
  email: string;
}

export interface SetSubsribedArgs {
  error?: Error;
}

export interface OpenReportArgs {
  companyId: string;
  companyData: CompanyData;
  report?: string;
  hasScore?: boolean;
}

export interface OpenGlossaryBubbleArgs {
  phrase: string;
  originX: number;
  originY: number;
}

export interface RequestGlossaryItemsArgs {
  phrase: string;
}

export interface SetGlossaryItemsArgs {
  items?: GlossaryItem[];
  error?: Error;
}

export interface MainState {
  networkStates: {
    contactFormSubmitRequest: NetworkState;
    subscribeRequest: NetworkState;
    glossaryItemsRequest: NetworkState;
  };
  isMessageBoxOpen: boolean;
  messageBoxInfo?: MessageBoxInfo;
  isFeedbackFormOpen: boolean;
  isContactFormOpen: boolean;
  openedReports: Record<string, boolean>;
  glossaryBuble: {
    isOpen: boolean;
    originX?: number;
    originY?: number;
    phrase?: string;
    glossaryItems?: GlossaryItem[];
  }
}
