import { createAction } from '@reduxjs/toolkit';
import {
  ReportActions,
  InitializeReportArgs,
  RequestCompanyDataArgs,
  RequestCompetitorsDataArgs,
  RequestDistributionsDataArgs,
  RequestInvestorDataArgs,
  SetCompanyDataArgs,
  SetDistributionsDataArgs,
  SetInvestorDataArgs,
  UpdateInvestingParamsArgs,
  ToggleDataInfoKeySelectionArgs,
  RequestMarketDataArgs,
  SetMarketDataArgs,
  AddMarketItemArgs,
  RemoveMarketItemArgs,
  SetAggregateArgs,
  SetMarketViewArgs,
  SetMarketCountriesArgs,
  SelectMarketCountryArgs,
  SetMarketItemsArgs,
  SetMarketEcoIndicatorArgs,
  SetMarketHighlightArgs,
  SetCompetitorsDataArgs,
  SetCompetitorHighlightArgs,
  SelectCompetitorArgs,
  SetLandscapeStatusArgs,
  SetLandscapeIsFundedArgs,
} from './types';

export const initializeReport = (
  createAction<InitializeReportArgs>(ReportActions.REPORT_INITIALIZE)
);
export const requestCompanyData = (
  createAction<RequestCompanyDataArgs>(ReportActions.COMPANY_DATA_REQUEST)
);
export const setCompanyData = (
  createAction<SetCompanyDataArgs>(ReportActions.COMPANY_DATA_SET)
);
export const requestInvestorData = (
  createAction<RequestInvestorDataArgs>(ReportActions.INVESTOR_DATA_REQUEST)
);
export const setInvestorData = (
  createAction<SetInvestorDataArgs>(ReportActions.INVESTOR_DATA_SET)
);
export const requestDistributionsData = (
  createAction<RequestDistributionsDataArgs>(ReportActions.DISTRIBUITIONS_DATA_REQUEST)
);
export const setDistributionsData = (
  createAction<SetDistributionsDataArgs>(ReportActions.DISTRIBUITIONS_DATA_SET)
);
export const requestMarketData = (
  createAction<RequestMarketDataArgs>(ReportActions.MARKET_DATA_REQUEST)
);
export const setMarketData = (
  createAction<SetMarketDataArgs>(ReportActions.MARKET_DATA_SET)
);
export const requestCompetitorsData = (
  createAction<RequestCompetitorsDataArgs>(ReportActions.COMPETITORS_DATA_REQUEST)
);
export const setCompetitorsData = (
  createAction<SetCompetitorsDataArgs>(ReportActions.COMPETITORS_DATA_SET)
);
export const requestMarketCountries = (
  createAction(ReportActions.MARKET_COUNTRIES_REQUEST)
);
export const setMarketCountries = (
  createAction<SetMarketCountriesArgs>(ReportActions.MARKET_COUNTRIES_SET)
);
export const updateInvestingParams = (
  createAction<UpdateInvestingParamsArgs>(ReportActions.INVESTING_PARAMS_UPDATE)
);
export const toggleDataInfoKeySelection = (
  createAction<ToggleDataInfoKeySelectionArgs>(ReportActions.DATA_KEY_SELECTION_TOGGLE)
);
export const addMarketItem = (
  createAction<AddMarketItemArgs>(ReportActions.MARKET_ITEM_ADD)
);
export const removeMarketItem = (
  createAction<RemoveMarketItemArgs>(ReportActions.MARKET_ITEM_REMOVE)
);
export const setMarketItems = (
  createAction<SetMarketItemsArgs>(ReportActions.MARKET_ITEMS_SET)
);
export const setMarketAggregate = (
  createAction<SetAggregateArgs>(ReportActions.MARKET_AGGREGATE_SET)
);
export const setMarketView = (
  createAction<SetMarketViewArgs>(ReportActions.MARKET_VIEW_SET)
);
export const selectMarketCountry = (
  createAction<SelectMarketCountryArgs>(ReportActions.MARKET_COUNTRY_SELECT)
);
export const setMarketEcoIndicator = (
  createAction<SetMarketEcoIndicatorArgs>(ReportActions.MARKET_ECO_INDICATOR_SET)
);
export const setMarketHighlight = (
  createAction<SetMarketHighlightArgs>(ReportActions.MARKET_HIGHLIGHT_SET)
);
export const setCompetitorHighlight = (
  createAction<SetCompetitorHighlightArgs>(ReportActions.COMPETITOR_HIGHLIGHT_SET)
);
export const selectCompetitor = (
  createAction<SelectCompetitorArgs>(ReportActions.COMPETITOR_SELECT)
);
export const setLandscapeStatus = (
  createAction<SetLandscapeStatusArgs>(ReportActions.LANDSCAPE_STATUS_SET)
);
export const setLandscapeIsFunded = (
  createAction<SetLandscapeIsFundedArgs>(ReportActions.LANDSCAPE_IS_FUNDED_SET)
);
