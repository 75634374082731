import React from 'react';
import { makeStyles } from '@mui/styles';
import { SpinnerDotted } from 'spinners-react';

import styles from './PageLoadProgress.styles';

const useStyles = makeStyles(styles);

const PageLoadProgress = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SpinnerDotted size={200} thickness={100} speed={100} color="#9951c5" />
    </div>
  );
};

export default PageLoadProgress;
