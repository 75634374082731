const createPerformanceUtils = () => {
  const throttleTimeoutIds: NodeJS.Timeout[] = [];

  function throttle(ms: number, callback: () => void) {
    if (throttleTimeoutIds.length > 0) {
      const timeoutId = throttleTimeoutIds.pop();

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }

    throttleTimeoutIds.push(setTimeout(() => {
      callback();
      while (throttleTimeoutIds.length > 0) {
        const timeoutId = throttleTimeoutIds.pop();

        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      }
    }, ms));
  }

  function forLast<T>(value: T, ms: number) {
    return new Promise<T>((resolve) => {
      throttle(ms, () => {
        resolve(value);
      });
    });
  }

  return {
    forLast,
  };
};

export default createPerformanceUtils;
