import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Link,
  Typography,
  Grid,
  Divider,
  Button,
} from '@mui/material';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import { ReactComponent as CaretRightIcon } from '../../../../assets/svg/caretRightIcon.svg';
import { ReactComponent as CaretLeftIcon } from '../../../../assets/svg/caretLeftIcon.svg';
import { ReactComponent as AddToolIcon } from '../../../../assets/svg/addTool.svg';
import { SupportedColor } from '../../../../theme/commonProps';

import {
  getSiteExampleGroupIndexes,
} from '../gptUtils';

import { PropsFromRedux } from './container';
import styles from './ChatExampleView.styles';
import { SiteExampleDataSet } from '../types';
import { useCommonClasses } from '../../../../theme/commonStyles';

interface Props extends PropsFromRedux {
  className?: string;
  onShowMoreToolsChange?: (tool?: string) => void;
}

const useStyles = makeStyles(styles);
const MAX_HOMEPAGE_EXAMPLE = 4;

const ContentView: React.FC<Props> = ({
  className,
  siteExampleGroups,
  homePageExamples,
  requestChatResponse,
  taskTemplates,
  applyTaskTemplate,
  isShowingToolsAtHome,
  onShowMoreToolsChange,
}: Props) => {
  const commonCss = useCommonClasses();
  const classes = useStyles();
  const [showMoreHomeExamples, setShowMoreHomeExamples] = useState(false);
  const [showMoreToolExamples, setShowMoreToolExamples] = useState<string>();

  const siteExGroupIndexes = getSiteExampleGroupIndexes();
  const siteExColorMaps = [
    'primary',
    'tertiary',
    'pvalyou',
    'secondary',
    'utility',
  ];

  const getSiteExColor = (groupName: string) => {
    const index = siteExGroupIndexes.findIndex((t) => t === groupName);
    return siteExColorMaps[index] as SupportedColor;
  };

  const renderSiteExIcon = (dataSet: SiteExampleDataSet) => (
    dataSet.icon
      ? <img src={dataSet.icon} width={18} height={18} />
      : undefined
  );

  const startDefaultTask = () => {
    const template = taskTemplates?.at(0) || {
      objective: '',
      tag: '',
      tasks: [],
    };

    applyTaskTemplate({
      chatIndex: 0,
      template,
    });
  };

  return (
    <Box className={className}>
      <AnimatePresence>
        {!isShowingToolsAtHome && (
          <motion.div
            initial={{
              x: 0,
              y: 20,
              opacity: 0,
            }}
            animate={{
              x: 0,
              y: 0,
              opacity: 1,
            }}
            exit={{
              x: 0,
              y: 20,
              opacity: 0,
            }}
          >
            <Box margin={5}>
              <Grid container spacing={2}>
                {(homePageExamples || [])
                  .slice(0, showMoreHomeExamples ? MAX_HOMEPAGE_EXAMPLE : undefined)
                  .map((siteExample, index) => (
                    <Grid key={index} item container xs={6}>
                      <Button
                        color="info"
                        classes={commonCss.buttons.roundButton}
                        className={classes.homeExampleButton}
                        variant="outlined"
                        onClick={() => {
                          requestChatResponse({
                            input: siteExample.example,
                          });
                        }}
                      >
                        {siteExample.example}
                      </Button>
                    </Grid>
                  ))
                }
              </Grid>
              {(homePageExamples || []).length > MAX_HOMEPAGE_EXAMPLE && (
                <Box marginTop={1} textAlign="right">
                  <Button
                    variant="outlined"
                    size="small"
                    className={
                      classNames(
                        classes.sectionHeaderButton,
                        'grey',
                      )
                    }
                    onClick={() => (
                      setShowMoreHomeExamples(!showMoreHomeExamples)
                    )}
                  >
                    Show {!showMoreHomeExamples ? 'more' : 'less'}
                  </Button>
                </Box>
              )}
            </Box>
          </motion.div>
        )}
        {isShowingToolsAtHome && (
          <motion.div
            initial={{
              x: 0,
              y: 20,
              opacity: 0,
            }}
            animate={{
              x: 0,
              y: 0,
              opacity: 1,
            }}
            exit={{
              x: 0,
              y: 20,
              opacity: 0,
            }}
          >
            <Grid container spacing={2} className={classes.exampleContainer}>
              {(siteExampleGroups || []).filter((group) => (
                !showMoreToolExamples || group.groupName === showMoreToolExamples
              )).map((group, groupIndex) => (
                <Grid
                  xs={!showMoreToolExamples ? group.layout : 12}
                  item
                  key={group.groupName}
                >
                  <Box className={classes.sectionHeader}>
                    <Typography
                      variant="h6"
                      className={
                        classNames(
                          classes.sectionHeaderText,
                          getSiteExColor(group.groupName),
                        )
                      }
                    >
                      {group.groupName}
                    </Typography>
                    <Divider
                      className={
                        classNames(
                          classes.sectionHeaderDivider,
                          getSiteExColor(group.groupName),
                        )
                      }
                    />
                    {showMoreToolExamples && (
                      <Button
                        variant="outlined"
                        size="small"
                        className={
                          classNames(
                            classes.sectionHeaderButton,
                            getSiteExColor(group.groupName),
                          )
                        }
                        onClick={() => {
                          const toolName = !showMoreToolExamples ? group.groupName : undefined;

                          if (onShowMoreToolsChange) {
                            onShowMoreToolsChange(toolName);
                          }

                          setShowMoreToolExamples(toolName);
                        }}
                      >
                        {showMoreToolExamples && (
                          <Box marginRight={1} display="flex" alignItems="center">
                            <CaretLeftIcon />
                          </Box>
                        )}
                        Show {!showMoreToolExamples ? 'more' : 'less'}
                        {!showMoreToolExamples && (
                          <Box marginLeft={1} display="flex" alignItems="center">
                            <CaretRightIcon />
                          </Box>
                        )}
                      </Button>
                    )}
                  </Box>
                  <AnimatePresence>
                    {!showMoreToolExamples && (
                      <motion.div
                        initial={{
                          x: -20,
                          y: 0,
                          opacity: 0,
                        }}
                        animate={{
                          x: 0,
                          y: 0,
                          opacity: 1,
                        }}
                        exit={{
                          x: -20,
                          y: 0,
                          opacity: 0,
                        }}
                      >
                        <Grid
                          container
                          columnSpacing={2}
                          style={{ minHeight: group.layout === 12 ? 0 : 116 }}
                        >
                          {group.dataSets.slice(0, groupIndex === 0 ? 3 : 4).map((ds) => (
                            <Grid key={ds.dataSetName} item xs={6} display="flex">
                              <Box className={classes.dataSetIconBox}>
                                <Box
                                  className={
                                    classNames(
                                      classes.siteExIcon,
                                      getSiteExColor(group.groupName),
                                    )
                                  }
                                >
                                  {renderSiteExIcon(ds)}
                                </Box>
                              </Box>
                              <Box className={classes.dataSetContentBox}>
                                <Box className={classes.dataSetContentHeader}>
                                  <Box
                                    className={classes.dataSetContentTitle}
                                    style={{
                                      opacity: !ds.active ? 0.65 : 1,
                                    }}
                                  >
                                    {ds.dataSetName}
                                    {!ds.active && (
                                      <Typography variant="body2">Soon!</Typography>
                                    )}
                                    {ds.dataSetName === 'TaskMaster' && (
                                      <Button
                                        color="secondary"
                                        classes={commonCss.buttons.leafButton}
                                        variant="outlined"
                                        size="small"
                                        style={{ padding: 0, height: 16, marginTop: 2 }}
                                        onClick={() => startDefaultTask()}
                                      >
                                        Start
                                      </Button>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                          {groupIndex === 0 && (
                            <Grid item xs={6} display="flex">
                              <Box className={classes.dataSetIconBox}>
                                <Box
                                  className={
                                    classNames(
                                      classes.siteExIcon,
                                      getSiteExColor(group.groupName),
                                    )
                                  }
                                >
                                  <AddToolIcon />
                                </Box>
                              </Box>
                              <Box className={classes.dataSetContentBox}>
                                <Box className={classes.dataSetContentHeader}>
                                  <Box
                                    className={classes.dataSetContentTitle}
                                  >
                                    Add Tool
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                        {!showMoreToolExamples && (
                          <Button
                            variant="outlined"
                            size="small"
                            className={
                              classNames(
                                classes.sectionHeaderButton,
                                getSiteExColor(group.groupName),
                                'noPaddingLeft',
                              )
                            }
                            onClick={() => {
                              const toolName = !showMoreToolExamples ? group.groupName : undefined;

                              if (onShowMoreToolsChange) {
                                onShowMoreToolsChange(toolName);
                              }

                              setShowMoreToolExamples(toolName);
                            }}
                          >
                            {showMoreToolExamples && (
                              <Box marginRight={1} display="flex" alignItems="center">
                                <CaretLeftIcon />
                              </Box>
                            )}
                            Show {!showMoreToolExamples ? 'more' : 'less'}
                            {!showMoreToolExamples && (
                              <Box marginLeft={1} display="flex" alignItems="center">
                                <CaretRightIcon />
                              </Box>
                            )}
                          </Button>
                        )}
                      </motion.div>
                    )}
                    {showMoreToolExamples && (
                      <motion.div
                        initial={{
                          x: 20,
                          y: 0,
                          opacity: 0,
                        }}
                        animate={{
                          x: 0,
                          y: 0,
                          opacity: 1,
                        }}
                        exit={{
                          x: 20,
                          y: 0,
                          opacity: 0,
                        }}
                      >
                        {group.dataSets.map((ds) => (
                          <Box key={ds.dataSetName} display="flex">
                            <Box className={classes.dataSetIconBox}>
                              <Box
                                className={
                                  classNames(
                                    classes.siteExIcon,
                                    getSiteExColor(group.groupName),
                                  )
                                }
                              >
                                {renderSiteExIcon(ds)}
                              </Box>
                            </Box>
                            <Box className={classes.dataSetContentBox}>
                              <Box className={classes.dataSetContentHeader}>
                                <Box
                                  className={
                                    classNames(
                                      classes.dataSetContentTitle,
                                      showMoreToolExamples && 'multi-rows',
                                    )
                                  }
                                  style={{
                                    opacity: !ds.active ? 0.65 : 1,
                                  }}
                                >
                                  {ds.dataSetName}
                                  {!ds.active && (
                                    <Typography variant="body2">Soon!</Typography>
                                  )}
                                </Box>
                                <Box className={classes.dataSetContentDesc}>
                                  {ds.description}
                                </Box>
                              </Box>
                              <Box className={classes.dataSetExamplesBox}>
                                {ds.examples.map((ex) => (
                                  <Box key={ex} marginBottom={0.75}>
                                    <Link
                                      type="button"
                                      className={
                                        classNames(
                                          classes.dataSetExample,
                                          getSiteExColor(group.groupName),
                                        )
                                      }
                                      onClick={() => {
                                        requestChatResponse({
                                          input: ex,
                                        });
                                      }}
                                    >
                                      {ex}
                                    </Link>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Grid>
              ))}
            </Grid>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default ContentView;
