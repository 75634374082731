import { Node, Edge } from '@sayari/trellis';
import { HierarchyNode } from 'd3-hierarchy';
import HierarchyLayout from './hierarchyLayout';
import { CompareFn, GraphData, HierarchyData } from '../types';

const radialLayout = <N extends Node, E extends Edge>(
  root: string,
  graph: GraphData<N, E>,
) => {
  const layout = HierarchyLayout();
  const { nodes, edges } = layout(root, {
    nodes: graph.nodes,
    edges: graph.edges,
    options: {
      bfs: graph.options?.bfs,
      size: graph.options?.size || [Math.PI * 2, graph.options?.radius ?? 150],
      separation: (
        graph.options?.separation
        || ((
          a: HierarchyNode<HierarchyData<N, E>>,
          b: HierarchyNode<HierarchyData<N, E>>,
        ) => (a.parent === b.parent ? 1 : 2) / a.depth)
      ) as unknown as CompareFn<Node, Edge>,
    },
  });

  return {
    nodes: nodes.map((node) => {
      const theta = (
        node.x === undefined && node.id !== root
          ? Math.random() * Math.PI * 2
          : node.x ?? 0
      );

      const radius = (
        node.y === undefined && node.id !== root
          ? (graph.options?.radius ?? 150) * 1.5
          : node.y ?? 0
      );

      return {
        ...node,
        x: Math.cos(theta) * radius + (graph.options?.x ?? 0),
        y: Math.sin(theta) * radius - (graph.options?.y ?? 0),
      };
    }),
    edges,
  };
};

export default radialLayout;
