import { Routes } from './types';

const routes: Routes = {
  portal: '/',
  login: '/login',
  reports: '/reports',
  manualForm: '/manualForm',
};

export default routes;
