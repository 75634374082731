import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';

import {
  openGlossaryBubble,
} from '../../main/actions';

import {
  setSearchKeyword,
  requestGptSearch,
  toggleGptSearch,
} from '../actions';

import CompanyGroupResults from './CompanyGroupResults';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.search,
    networkStates: {
      ...state.search.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    setSearchKeyword,
    requestGptSearch,
    openGlossaryBubble,
    toggleGptSearch,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CompanyGroupResults);
