import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles, { getContrastColorModifierClasses } from '../../../../theme/commonStyles';
import { otherColorSets, otherContrastColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      boxSizing: 'border-box',
    },
    countrySelectorPanel: {
      flex: 0,
      width: '40% !important',
      marginTop: theme.spacing(-8.5),
      marginLeft: theme.spacing(9),
      marginBottom: theme.spacing(3.5),
    },
    countryLabel: {
      fontSize: '1.285rem !important',
      fontWeight: 'normal !important',
      color: `${otherContrastColorSets[0]} !important`,
    },
    chartsPanel: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginLeft: theme.spacing(3),
    },
    tabItem: {
      width: '100%',
      boxSizing: 'border-box',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      '&.active $tabItemIndicator': {
        border: 'none',
      },
      '&.aggregate $tabItemIndicator > div': {
        backgroundColor: otherColorSets[0],
      },
      '&.active $tabItemText': {
        fontWeight: 'normal !important',
      },
    },
    tabItemButton: {
      borderRadius: '8px 8px 0 0 !important',
    },
    tabItemText: {
      marginTop: `${theme.spacing(0.5)} !important`,
      marginBottom: `${theme.spacing(0.5)} !important`,
    },
    tabItemIndicator: {
      boxSizing: 'border-box',
      width: '100%',
      height: theme.spacing(0.75),
      justifySelf: 'flex-end',
      backgroundColor: theme.palette.grey[50],
      borderTop: `1.5px solid ${theme.palette.common.white}`,
      borderBottom: `1.5px solid ${theme.palette.common.white}`,
      transition: 'all ease-out 0.3s',
      display: 'flex',
      overflow: 'hidden',
      '& > div': {
        flex: 1,
        transition: 'all ease-out 0.3s',
        '&:nth-child(1)': {
          backgroundColor: otherColorSets[0],
        },
        '&:nth-child(2)': {
          backgroundColor: otherColorSets[2],
        },
        '&:nth-child(3)': {
          backgroundColor: otherColorSets[3],
        },
        '&:nth-child(4)': {
          backgroundColor: otherColorSets[1],
        },
      },
    },
    sizeStatSummary: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      height: theme.spacing(4.5),
      display: 'flex',
      alignItems: 'center',
      color: `${theme.palette.grey[500]} !important`,
    },
    sizeStatSummaryTitle: {
      color: `${theme.palette.grey[500]} !important`,
    },
    sizeStatSummaryItem: {
      height: theme.spacing(4.5),
      width: '15%',
      [theme.breakpoints.down('xl')]: {
        width: '18%',
      },
      marginLeft: theme.spacing(2),
      fontSize: '1.357rem',
      fontWeight: 'normal',
      color: theme.palette.grey[300],
    },
    growthStatSummary: {
      marginBottom: theme.spacing(2),
      height: theme.spacing(4.5),
      display: 'flex',
      alignItems: 'center',
      color: `${theme.palette.grey[500]} !important`,
    },
    growthStatSummaryTitle: {
      color: `${theme.palette.grey[500]} !important`,
    },
    growthRateIndicator: {
      height: 2,
      width: 111,
      marginLeft: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
      '&.one': {
        backgroundColor: otherColorSets[0],
      },
      '&.two': {
        backgroundImage: `
          linear-gradient(
            90deg,
            ${otherColorSets[0]} 0%, ${otherColorSets[0]} 50%,
            ${otherColorSets[2]} 50%, ${otherColorSets[2]} 100%
          );
        `,
      },
      '&.three': {
        backgroundImage: `
          linear-gradient(
            90deg,
            ${otherColorSets[0]} 0%, ${otherColorSets[0]} 33.3%,
            ${otherColorSets[2]} 33.3%, ${otherColorSets[2]} 66.6%,
            ${otherColorSets[3]} 66.6%, ${otherColorSets[3]} 100%
          );
        `,
      },
      '&.four': {
        backgroundImage: `
          linear-gradient(
            90deg,
            ${otherColorSets[0]} 0%, ${otherColorSets[0]} 25%,
            ${otherColorSets[2]} 25%, ${otherColorSets[2]} 50%,
            ${otherColorSets[3]} 50%, ${otherColorSets[3]} 75%,
            ${otherColorSets[1]} 75%, ${otherColorSets[1]} 100%
          );
        `,
      },
      '&.aggregate': {
        backgroundColor: `${otherColorSets[0]} !important`,
        backgroundImage: 'none !important',
      },
    },
    growthStatIndicatorsBox: {
      flex: 1,
      display: 'flex',
      marginLeft: theme.spacing(8),
      columnGap: theme.spacing(5),
      [theme.breakpoints.down('xl')]: {
        marginLeft: theme.spacing(5),
        columnGap: theme.spacing(2),
      },
    },
    growthStatIndicatorTitle: {
      fontWeight: 300,
      color: `${theme.palette.grey[300]} !important`,
      paddingTop: theme.spacing(0.75),
    },
    growthGdpIndicator: {
      backgroundImage: `linear-gradient(to left, ${theme.palette.grey[500]} 50%, rgba(255,255,255,1) 0%)`,
      backgroundPosition: 'bottom',
      backgroundSize: '10px 1px',
      backgroundRepeat: 'repeat-x',
      height: 1,
      width: 40,
      marginTop: theme.spacing(1),
    },
    growthGdpPerCapIndicator: {
      backgroundImage: `linear-gradient(to left, ${theme.palette.grey[500]} 50%, rgba(255,255,255,1) 0%)`,
      backgroundPosition: 'bottom',
      backgroundSize: '4px 1px',
      backgroundRepeat: 'repeat-x',
      height: 1,
      width: 40,
      marginTop: theme.spacing(1),
    },
    growthInflationIndicator: {
      backgroundImage: `linear-gradient(to left, ${theme.palette.grey[500]} 50%, rgba(255,255,255,1) 0%)`,
      backgroundPosition: 'bottom',
      backgroundSize: '4px 4px',
      backgroundRepeat: 'repeat-x',
      height: 4,
      width: 40,
      marginTop: theme.spacing(0.75),
    },
    growthMarketCapIndicator: {
      backgroundImage: `linear-gradient(to right, ${theme.palette.grey[500]} 50%, rgba(255,255,255,1) 0%)`,
      backgroundPosition: 'bottom',
      backgroundSize: '8px 4px',
      backgroundRepeat: 'repeat-x',
      height: 4,
      width: 40,
      marginTop: theme.spacing(0.75),
    },
    economicIndicatorButton: {
      marginTop: `${theme.spacing(-1)} !important`,
      padding: `${theme.spacing(0.5)} ${theme.spacing(2)} !important`,
    },
    selectorDropDownIcon: {
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(-0.5),
    },
    economicIndicatorMenu: {
      '& .MuiListItemIcon-root': {
        minWidth: `${theme.spacing(3)} !important`,
      },
    },
    chartsWrapper: {
      flex: 1,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      position: 'relative',
      boxSizing: 'border-box',
    },
    chartsInnerContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      overflow: 'hidden',
    },
    chartArea: {
      position: 'relative',
      '& .highcharts-series-hover.primary': {
        '& .highcharts-point-hover': {
          fill: otherContrastColorSets[0],
        },
      },
      '& .highcharts-series-hover.secondary': {
        '& .highcharts-point-hover': {
          fill: otherContrastColorSets[1],
        },
      },
      '& .highcharts-series-hover.tertiary': {
        '& .highcharts-point-hover': {
          fill: otherContrastColorSets[2],
        },
      },
      '& .highcharts-series-hover.quaternary': {
        '& .highcharts-point-hover': {
          fill: otherContrastColorSets[3],
        },
      },
    },
    trendsBox: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      boxSizing: 'border-box',
      borderTop: `0.5px solid ${theme.palette.grey[100]}`,
      borderBottom: `0.5px solid ${theme.palette.grey[500]}`,
    },
    singleRowTrendSet: {
      justifyContent: 'center',
    },
    singleRowTrendSetLabelBox: {
      flexBasis: 'auto !important',
      [theme.breakpoints.down('xl')]: {
        '& p': {
          fontSize: '1rem !important',
        },
      },
    },
    singleRowTrendSetItemsBox: {
      flex: '0 !important',
    },
    trendItem: {
      height: 21.5,
      marginLeft: theme.spacing(1),
      boxSizing: 'border-box',
      minWidth: 50,
      padding: `0 ${theme.spacing(1)}`,
      '&.width25': {
        width: 'calc(25% - 8px)',
      },
      '&.width50': {
        width: 'calc(50% - 8px)',
      },
      '&.width100': {
        width: '100%',
      },
    },
    trendItemText: {
      color: `${theme.palette.grey[300]} !important`,
      fontWeight: '400 !important',
      fontSize: '1.07rem',
      ...getContrastColorModifierClasses('color'),
      [theme.breakpoints.down('xl')]: {
        fontSize: '0.9rem !important',
      },
    },
    trendDivider: {
      width: 1,
      height: `calc(100% + ${theme.spacing(1)})`,
      marginTop: theme.spacing(-0.5),
      backgroundColor: theme.palette.grey[100],
    },
    marketHighlightsHeader: {
      color: `${theme.palette.grey[100]} !important`,
      fontWeight: '500 !important',
    },
    marketHighlightsBox: {
      overflow: 'auto',
    },
    marketHighlightsList: {
      margin: 0,
      paddingLeft: theme.spacing(3),
      '& > li': {
        paddingLeft: theme.spacing(2),
        fontSize: '1.285rem',
        marginBottom: theme.spacing(1),
        '&::marker': {
          content: 'counter(list-item) "\\A0"',
          fontSize: '1.285rem',
          fontWeight: 'bold',
          color: theme.palette.grey[100],
          fontFamily: 'Roboto',
        },
        '& .MuiTypography-root': {
          color: `${theme.palette.grey[900]} !important`,
        },
      },
    },
  });
};

export default styles;
