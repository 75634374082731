import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles, {
  FOUNDER_AVATAR_SIZE,
} from '../../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 300,
    },
    founderBox: {
      position: 'relative',
      alignSelf: 'center',
      width: '100%',
      boxSizing: 'border-box',
    },
    founderAvatar: {
      position: 'relative',
    },
    founderMoreInfoWrapper: {
      width: `calc(${FOUNDER_AVATAR_SIZE}px - ${theme.spacing(1.125)})`,
      height: `calc(${FOUNDER_AVATAR_SIZE}px - ${theme.spacing(1.125)})`,
      borderRadius: '50%',
      position: 'absolute',
      top: 5,
      left: 5,
      overflow: 'hidden',
    },
    founderMoreInfo: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transform: 'translate(0, 100%)',
      transition: 'all ease-out 0.3s',
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1,
      '&.open': {
        transform: 'translate(0, 0)',
      },
    },
    founderDecorate: {
      position: 'absolute',
      top: `calc(${theme.spacing(5)} + 2px)`,
      left: 0,
      right: 0,
      height: 600,
      background: 'linear-gradient(180deg, rgba(247,247,247,1) 0%, rgba(247,247,247,0) 100%);',
      zIndex: 0,
    },
    founderItem: {
      position: 'relative',
      zIndex: 1,
    },
    founderValue: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    leafBanner: {
      width: theme.spacing(7.5),
      padding: `0 ${theme.spacing(1)}`,
      background: theme.palette.common.white,
      height: 31.36,
    },
    reportRow: {
      padding: 0,
      margin: `${theme.spacing(0.75)} 0`,
      cursor: 'pointer',
    },
    rowTitle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    title: {
      color: `${theme.palette.grey[900]} !important`,
      lineHeight: '1.28 !important',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    value: {
      color: `${theme.palette.grey[900]} !important`,
      lineHeight: '1.14 !important',
      letterSpacing: '-0.21px',
      fontWeight: '400 !important',
      fontSize: '1rem !important',
      display: '-webkit-box',
      height: '32px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      paddingLeft: 1,
      paddingRight: 1,
    },
  });
};

export default styles;
