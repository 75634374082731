import { createAction } from '@reduxjs/toolkit';
import {
  NotifierActions,
  RemoveNotificationArgs,
  TriggerNotificationArgs,
  SetNotificationDisplayedArgs,
} from './types';

export const triggerNotification = (
  createAction<TriggerNotificationArgs>(NotifierActions.NOTIFICATION_TRIGGER)
);

export const removeNotification = (
  createAction<RemoveNotificationArgs>(NotifierActions.NOTIFICATION_REMOVE)
);

export const setNotificationDisplayed = (
  createAction<SetNotificationDisplayedArgs>(NotifierActions.NOTIFICATION_DISPLAYED_SET)
);
