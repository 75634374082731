import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  SvgIcon,
} from '@mui/material';
import classNames from 'classnames';

import { ReactComponent as HomeIcon } from '../../assets/svg/homeIcon.svg';
import { useCommonClasses } from '../../theme/commonStyles';
import { stripParams } from '../../core/utils/stringUtils';

import { NavMenuItem } from './types';
import styles from './NavMenu.styles';

type Props = {
  menus: NavMenuItem[];
  currentPath?: string;
  onHomeClick: () => void;
  onMenuClick: (menu: NavMenuItem) => void;
};

const useStyles = makeStyles(styles);

const NavMenu: React.FC<Props> = ({
  menus,
  currentPath,
  onHomeClick,
  onMenuClick,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [hoveringMenu, setHoveringMenu] = useState<string>();

  return (
    <div
      className={
        classNames(
          classes.root,
          !isMenuActive && 'collapsed',
          isMenuActive && 'hover',
        )
      }
      onMouseEnter={() => setIsMenuActive(true)}
      onMouseLeave={() => setIsMenuActive(false)}
    >
      <IconButton
        className={
          classNames(
            classes.homeButton,
            !isMenuActive && 'collapsed',
          )
        }
        classes={commonCss.navMenu.menuIconButton}
        color="inherit"
        size="small"
        onClick={() => onHomeClick()}
      >
        <HomeIcon fontSize="medium" color="inherit" />
      </IconButton>
      <Box className={classes.menuContainer} marginTop={isMenuActive ? 3 : 1}>
        <MenuList>
          {menus.map((menu) => (
            <MenuItem
              key={menu.name}
              className={classNames(
                classes.menuItem,
                (hoveringMenu === menu.name || currentPath === stripParams(menu.routePath))
                  ? isMenuActive && 'active'
                  : undefined,
                !isMenuActive && 'collapsed',
              )}
              classes={commonCss.navMenu.menuItem}
              onMouseEnter={() => setHoveringMenu(menu.name)}
              onMouseLeave={() => setHoveringMenu(undefined)}
              onClick={() => {
                onMenuClick(menu);
                setIsMenuActive(false);
              }}
              disabled={menu.diabled}
            >
              <ListItemIcon>
                <div
                  className={
                    classNames(
                      classes.menuIconBox,
                      commonCss.navMenu.menuIconBox,
                      (hoveringMenu === menu.name || currentPath === stripParams(menu.routePath))
                        ? menu.iconHighlightClassName
                        : undefined,
                      (hoveringMenu === menu.name || currentPath === stripParams(menu.routePath))
                        ? 'active'
                        : undefined,
                      !isMenuActive && 'collapsed',
                    )
                  }
                >
                  <SvgIcon color="inherit">
                    <menu.icon />
                  </SvgIcon>
                </div>
              </ListItemIcon>
              <ListItemText
                className={classes.menuTypography}
                classes={commonCss.navMenu.menuText}
                primaryTypographyProps={{
                  classes: {
                    root: classNames(
                      commonCss.navMenu.menuTypography.root,
                      (hoveringMenu === menu.name || currentPath === stripParams(menu.routePath))
                        ? 'active'
                        : undefined,
                    ),
                  },
                }}
                primary={menu.name}
              />
            </MenuItem>
          ))}
        </MenuList>
      </Box>
    </div>
  );
};

export default NavMenu;
