import React, { HTMLProps, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import ReactSlider from 'react-slider';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { ReactComponent as DropDownIcon } from '../../../../assets/svg/arrowDownIcon.svg';
import FeatureBarChart from '../../../../components/featureBarChart/FeatureBarChart';
import { useCommonClasses } from '../../../../theme/commonStyles';
import {
  AMOUNT_1M,
  AMOUNT_15M,
  AMOUNT_25K,
  getMaxScorePercent,
  normalizePercentage,
  getAmountDisplayInDollars,
} from '../reportUtils';

import {
  InvestingParams,
  InvestorGeography,
  InvestorReportInfo,
  InvestorType,
  UpdateInvestingParamsArgs,
} from '../types';
import styles from './InvestingPanel.styles';
import { INTERNAL_SPACING } from '../Reports.styles';

type Props = {
  investorReportInfo?: InvestorReportInfo;
  investingParams?: InvestingParams;
  updateInvestingParams: ActionCreatorWithPayload<UpdateInvestingParamsArgs, string>;
  currentDataInfoKey?: string;
  selectedDataInfoKeys?: string[];
  onDataMouseEnter?: (dataInfoKey: string) => void;
  onDataMouseLeave?: () => void;
  onDataMouseClick?: (dataInfoKey: string) => void;
};

const useStyles = makeStyles(styles);

const InvestingPanel: React.FC<Props> = ({
  investorReportInfo,
  investingParams,
  updateInvestingParams,
  currentDataInfoKey,
  selectedDataInfoKeys,
  onDataMouseEnter,
  onDataMouseLeave,
  onDataMouseClick,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();

  const initialInvestmentAmount = investorReportInfo?.round
    ? (investorReportInfo?.round || 0) * AMOUNT_1M
    : AMOUNT_25K;

  const [value, setValue] = useState<number | undefined>();
  const [investorTypeMenuAnchor, setInvestorTypeMenuAnchor] = useState<HTMLButtonElement>();

  const { featureImportance, groupImportance } = investorReportInfo || {};
  const maxPercent = getMaxScorePercent({
    ...featureImportance,
    ...groupImportance,
  });

  const initialMaxAmount = initialInvestmentAmount > AMOUNT_1M
    ? AMOUNT_15M
    : AMOUNT_1M;

  const maxAmount = investingParams?.maxAmount || initialMaxAmount;
  const investorType = investingParams?.investorType || 1;
  const investorExp = investingParams?.investorExp || 1;
  const investorGeography: InvestorGeography = (
    investingParams?.geography
      ? InvestorGeography.domestic
      : InvestorGeography.foreign
  );

  useEffect(() => {
    if (investorReportInfo && value === undefined) {
      setValue(initialInvestmentAmount);
    }
  }, [investorReportInfo]);

  const renderBarChart = (val?: number) => {
    if (!maxPercent) {
      return <div />;
    }

    const normalizedValue = normalizePercentage(val || 0, maxPercent);
    return (
      <Box>
        <FeatureBarChart
          value={val}
          normalizedValue={normalizedValue}
          colorClass="investing"
        />
      </Box>
    );
  };

  const selectInvestorType = (type: InvestorType) => {
    updateInvestingParams({
      path: 'investorType',
      value: type,
    });

    setInvestorTypeMenuAnchor(undefined);
  };

  const getSelectedInvestorType = () => {
    switch (investorType) {
      case 2:
        return 'Hub';
      case 3:
        return 'Corporate';
      case 4:
        return 'Firm';
      case 5:
        return 'Individual';
      default:
        return 'Non-VC';
    }
  };

  const updateMaxAmount = (amount: number) => {
    updateInvestingParams({
      path: 'maxAmount',
      value: amount,
    });

    if (amount === AMOUNT_1M) {
      setValue(AMOUNT_25K);
    }

    if (amount === AMOUNT_15M) {
      setValue(AMOUNT_1M);
    }
  };

  const handleMouseClick = (dataInfoKey: string) => {
    if (onDataMouseClick) {
      onDataMouseClick(dataInfoKey);
    }
  };

  return (
    <div className={classes.root}>
      <Divider
        className={classNames(classes.divider, 'top')}
      />
      <Box
        className={
          classNames(
            classes.reportGroupRow,
            'investing',
            commonCss.reports.row,
            currentDataInfoKey === 'i32Investor' && 'active',
            selectedDataInfoKeys?.includes('i32Investor') && 'active',
          )
        }
      >
        <Box
          onClick={() => handleMouseClick('i33Amount')}
          onMouseEnter={() => onDataMouseEnter && onDataMouseEnter('i33Amount')}
          onMouseLeave={() => onDataMouseLeave && onDataMouseLeave()}
          className={
            classNames(
              classes.reportRow,
              commonCss.reports.row,
              commonCss.reports.rowHover,
              'investing',
              selectedDataInfoKeys?.includes('i33Amount') && 'active',
            )
          }
          sx={{
            marginTop: theme.spacing(-0.5),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          }}
        >
          <Grid
            container
            columnSpacing={{ xl: INTERNAL_SPACING / 8, xs: 5 }}
          >
            <Grid item xs={12} md={6}>
              <Grid container columnSpacing={1} style={{ width: 'calc(100% + 40px)' }}>
                <Grid item container xs={3} xl={4} alignItems="center">
                  <Typography variant="body1" className={classes.title}>
                    Amount to Invest
                  </Typography>
                </Grid>
                <Grid item container xs={9} xl={8} columnSpacing={1}>
                  <Grid item container xs={4} alignContent="center">
                    <Button
                      variant={maxAmount <= AMOUNT_1M ? 'contained' : 'outlined'}
                      className={classNames(classes.button, 'investing', 'opaque')}
                      classes={commonCss.buttons.generalButton}
                      size="medium"
                      fullWidth
                      onClick={(event) => {
                        updateMaxAmount(AMOUNT_1M);
                        event.stopPropagation();
                      }}
                    >
                      {'< $1M'}
                    </Button>
                  </Grid>
                  <Grid item container xs={6} xl={4} alignContent="center">
                    <Button
                      variant={maxAmount > AMOUNT_1M ? 'contained' : 'outlined'}
                      className={classNames(classes.button, 'investing', 'opaque')}
                      classes={commonCss.buttons.generalButton}
                      size="medium"
                      fullWidth
                      onClick={(event) => {
                        updateMaxAmount(AMOUNT_15M);
                        event.stopPropagation();
                      }}
                    >
                      {'$1M - $15M'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={{ xl: INTERNAL_SPACING / 8, xs: 5 }}
            marginTop={2}
          >
            <Grid item xs={12} md={6}>
              <Box style={{ width: 'calc(100% + 32px)' }}>
                <div className={classes.investSliderMarkLabel}>
                  <Typography color="inherit">
                    {maxAmount <= AMOUNT_1M ? '$25K' : '$1M'}
                  </Typography>
                  <Typography color="inherit">
                    {maxAmount <= AMOUNT_1M ? '$1M' : '$15M'}
                  </Typography>
                </div>
                <ReactSlider
                  className={classes.investSlider}
                  min={maxAmount <= AMOUNT_1M ? AMOUNT_25K : AMOUNT_1M}
                  max={maxAmount <= AMOUNT_1M ? AMOUNT_1M : AMOUNT_15M}
                  step={maxAmount <= AMOUNT_1M ? 25000 : 250000}
                  value={value}
                  onChange={(val: number) => {
                    setValue(val);
                  }}
                  onAfterChange={(val: number) => {
                    updateInvestingParams({
                      path: 'amount',
                      value: val,
                    });
                  }}
                  renderTrack={(
                    props: HTMLProps<HTMLDivElement>,
                    state: { index: number },
                  ) => (
                    <div
                      {...props}
                      className={
                        classNames(
                          commonCss.sliders.labeledSlider.track,
                          state.index === 0 ? 'investing' : 'grey',
                        )
                      }
                      style={{
                        ...props.style,
                      }}
                      onClick={(event) => event.preventDefault()}
                    />
                  )}
                  renderThumb={(
                    props: HTMLProps<HTMLDivElement>,
                    state: { valueNow: number },
                  ) => (
                    <div
                      {...props}
                      className={
                        classNames(
                          commonCss.sliders.labeledSlider.thumb,
                          'investing',
                        )
                      }
                      style={{
                        ...props.style,
                      }}
                      onClick={(event) => event.stopPropagation()}
                    >
                      {getAmountDisplayInDollars(state.valueNow)}
                    </div>
                  )}
                />
              </Box>
            </Grid>
            <Grid item container xs={12} md={6} flexDirection="column" justifyContent="flex-end">
              <Box marginBottom={0}>
                {renderBarChart(groupImportance?.investor)}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box marginTop={1.5} marginBottom={1}>
          <Divider className={classes.divider} />
        </Box>
        <Box
          onClick={() => handleMouseClick('i34InvestorType')}
          onMouseEnter={() => onDataMouseEnter && onDataMouseEnter('i34InvestorType')}
          onMouseLeave={() => onDataMouseLeave && onDataMouseLeave()}
          className={
            classNames(
              classes.reportRow,
              commonCss.reports.row,
              commonCss.reports.rowHover,
              'investing',
              selectedDataInfoKeys?.includes('i34InvestorType') && 'active',
            )
          }
        >
          <Grid
            container
            columnSpacing={{ xl: INTERNAL_SPACING / 8, xs: 5 }}
          >
            <Grid item xs={12} md={6}>
              <Grid container columnSpacing={1} style={{ width: 'calc(100% + 40px)' }}>
                <Grid item container xs={3} xl={4} alignItems="center">
                  <Typography variant="body1" className={classes.title}>
                    Investor Type
                  </Typography>
                </Grid>
                <Grid item container xs={9} xl={8} columnSpacing={1}>
                  <Grid item container xs={4} alignContent="center">
                    <Button
                      variant={investorType === 1 ? 'contained' : 'outlined'}
                      className={classNames(classes.button, 'investing', 'opaque')}
                      classes={commonCss.buttons.generalButton}
                      size="medium"
                      fullWidth
                      onClick={(event) => {
                        updateInvestingParams({
                          path: 'investorType',
                          value: 1,
                        });
                        event.stopPropagation();
                      }}
                    >
                      {'VC'}
                    </Button>
                  </Grid>
                  <Grid item container xs={4} alignContent="center">
                    <Button
                      variant={investorType > 1 ? 'contained' : 'outlined'}
                      className={classNames(classes.button, 'investing', 'opaque')}
                      classes={commonCss.buttons.generalButton}
                      size="medium"
                      fullWidth
                      onClick={(event) => {
                        setInvestorTypeMenuAnchor(event.currentTarget);
                      }}
                    >
                      {getSelectedInvestorType()}
                      <DropDownIcon width="12.98" className={classes.selectorDropDownIcon} />
                    </Button>
                    <Menu
                      open={Boolean(investorTypeMenuAnchor)}
                      anchorEl={investorTypeMenuAnchor}
                      onClose={() => setInvestorTypeMenuAnchor(undefined)}
                    >
                      <MenuItem selected={investorType === 2} onClick={() => selectInvestorType(2)}>
                        Hub
                      </MenuItem>
                      <MenuItem selected={investorType === 3} onClick={() => selectInvestorType(3)}>
                        Corporate
                      </MenuItem>
                      <MenuItem selected={investorType === 4} onClick={() => selectInvestorType(4)}>
                        Firm
                      </MenuItem>
                      <MenuItem selected={investorType === 5} onClick={() => selectInvestorType(5)}>
                        Individual
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6} flexDirection="column" justifyContent="center">
              <Box>
                {renderBarChart(featureImportance?.investorType)}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box marginTop={1} marginBottom={1}>
          <Divider className={classes.divider} />
        </Box>
        <Box
          onClick={() => handleMouseClick('i35Geography')}
          onMouseEnter={() => onDataMouseEnter && onDataMouseEnter('i35Geography')}
          onMouseLeave={() => onDataMouseLeave && onDataMouseLeave()}
          className={
            classNames(
              classes.reportRow,
              commonCss.reports.row,
              commonCss.reports.rowHover,
              'investing',
              selectedDataInfoKeys?.includes('i35Geography') && 'active',
            )
          }
        >
          <Grid
            container
            columnSpacing={{ xl: INTERNAL_SPACING / 8, xs: 5 }}
          >
            <Grid item xs={12} md={6}>
              <Grid container columnSpacing={1} style={{ width: 'calc(100% + 40px)' }}>
                <Grid item container xs={3} xl={4} alignItems="center">
                  <Typography variant="body1" className={classes.title}>
                    Geography
                  </Typography>
                </Grid>
                <Grid item container xs={9} xl={8} columnSpacing={1}>
                  <Grid item container xs={4} alignContent="center">
                    <Button
                      variant={investorGeography === InvestorGeography.domestic ? 'contained' : 'outlined'}
                      className={classNames(classes.button, 'investing', 'opaque')}
                      classes={commonCss.buttons.generalButton}
                      size="medium"
                      fullWidth
                      onClick={(event) => {
                        updateInvestingParams({
                          path: 'geography',
                          value: InvestorGeography.domestic,
                        });
                        event.stopPropagation();
                      }}
                    >
                      {'Domestic'}
                    </Button>
                  </Grid>
                  <Grid item container xs={4} alignContent="center">
                    <Button
                      variant={investorGeography === InvestorGeography.foreign ? 'contained' : 'outlined'}
                      className={classNames(classes.button, 'investing', 'opaque')}
                      classes={commonCss.buttons.generalButton}
                      size="medium"
                      fullWidth
                      onClick={(event) => {
                        updateInvestingParams({
                          path: 'geography',
                          value: InvestorGeography.foreign,
                        });
                        event.stopPropagation();
                      }}
                    >
                      {'Foreign'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              flexDirection="column"
              justifyContent="center"
            >
              {renderBarChart(featureImportance?.geography)}
            </Grid>
          </Grid>
        </Box>
        <Box marginTop={1} marginBottom={1}>
          <Divider className={classes.divider} />
        </Box>
        <Box
          onClick={() => handleMouseClick('i36Investingexp')}
          onMouseEnter={() => onDataMouseEnter && onDataMouseEnter('i36Investingexp')}
          onMouseLeave={() => onDataMouseLeave && onDataMouseLeave()}
          className={
            classNames(
              classes.reportRow,
              commonCss.reports.row,
              commonCss.reports.rowHover,
              'investing',
              selectedDataInfoKeys?.includes('i36Investingexp') && 'active',
            )
          }
        >
          <Grid
            container
            columnSpacing={{ xl: INTERNAL_SPACING / 8, xs: 5 }}
          >
            <Grid item xs={12} md={6}>
              <Grid container columnSpacing={1} style={{ width: 'calc(100% + 40px)' }}>
                <Grid item container xs={3} xl={4} alignItems="center">
                  <Typography variant="body1" className={classes.title}>
                    Prior Investing
                  </Typography>
                </Grid>
                <Grid item container xs={9} xl={8} columnSpacing={1}>
                  <Grid item container xs={4} alignContent="center">
                    <Button
                      variant={investorExp === 1 ? 'contained' : 'outlined'}
                      className={classNames(classes.button, 'investing', 'opaque')}
                      classes={commonCss.buttons.generalButton}
                      size="medium"
                      fullWidth
                      onClick={(event) => {
                        updateInvestingParams({
                          path: 'investorExp',
                          value: 1,
                        });
                        event.stopPropagation();
                      }}
                    >
                      {'First Time'}
                    </Button>
                  </Grid>
                  <Grid item container xs={4} alignContent="center">
                    <Button
                      variant={investorExp === 2 ? 'contained' : 'outlined'}
                      className={classNames(classes.button, 'investing', 'opaque')}
                      classes={commonCss.buttons.generalButton}
                      size="medium"
                      fullWidth
                      onClick={(event) => {
                        updateInvestingParams({
                          path: 'investorExp',
                          value: 2,
                        });
                        event.stopPropagation();
                      }}
                    >
                      {'Professional'}
                    </Button>
                  </Grid>
                  <Grid item container xs={4} alignContent="center">
                    <Button
                      variant={investorExp === 3 ? 'contained' : 'outlined'}
                      className={classNames(classes.button, 'investing', 'opaque')}
                      classes={commonCss.buttons.generalButton}
                      size="medium"
                      fullWidth
                      onClick={(event) => {
                        updateInvestingParams({
                          path: 'investorExp',
                          value: 3,
                        });
                        event.stopPropagation();
                      }}
                    >
                      {'Capitalist'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              flexDirection="column"
              justifyContent="center"
            >
              {renderBarChart(featureImportance?.investingExperience)}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default InvestingPanel;
