import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';
import getCommonStyles from '../../../../theme/commonStyles';
import { otherColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      borderWidth: '1px !important',
      borderColor: `${Color(otherColorSets[1]).alpha(0.5).toString()} !important`,
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
    companyLogo: {
      width: 115,
      height: 115,
      backgroundColor: `${theme.palette.common.white} !important`,
      borderColor: `${Color(otherColorSets[1]).alpha(0.25).toString()} !important`,
      flexDirection: 'column',
      '&:hover': {
        backgroundColor: `${theme.palette.grey[50]} !important`,
      },
      borderWidth: '1.5px !important',
    },
    uploadLogoText: {
      color: `${theme.palette.grey[400]} !important`,
    },
    companyKeywords: {
      '& .MuiOutlinedInput-root': {
        paddingLeft: `${theme.spacing(2.5)} !important`,
        paddingRight: `${theme.spacing(2.5)} !important`,
      },
    },
    fieldDisabled: {
      opacity: 0.5,
    },
  });
};

export default styles;
