import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  fork,
  put,
  take,
  takeEvery,
} from 'redux-saga/effects';
import config from '../../../config';
import createPerformanceUtils from '../../../core/utils/performanceUtils';

import { performApiGet, performApiPost } from '../../../core/utils/sagaUtils';
import {
  requestCsrf,
  setCsrf,
  setLoggedOut,
  setLogin,
  setUserExtra,
} from './actions';

import {
  LoginInfo,
  SessionActions,
  RequestLoginArgs,
  SetLoginArgs,
  RequestUserExtraArgs,
  UserExtraInfo,
} from './types';

export const LOGIN_STORAGE = 'pvalyou-user';

const performanceUtils = createPerformanceUtils();

function* initializeFlow() {
  yield takeEvery(
    SessionActions.SESSION_INITIALIZE,
    function* _() {
      const storedData = localStorage.getItem(LOGIN_STORAGE);
      if (!storedData) {
        return;
      }

      try {
        const loginInfo = JSON.parse(storedData) as LoginInfo;
        yield put(setLogin({ loginInfo }));
      } catch (error) {
        yield put(setLogin({ error: error as Error }));
      }
    },
  );
}

function* requestCsrfFlow() {
  yield takeEvery(
    SessionActions.CSRF_REQUEST,
    function* _() {
      yield fork(() => performApiGet(
        config.api.setCsrf,
        {
          onResult: () => put(setCsrf()),
        },
      ));
    },
  );
}

function* requestLoginFlow() {
  yield takeEvery(
    SessionActions.LOGIN_REQUEST,
    function* _(act: PayloadAction<RequestLoginArgs>) {
      yield put(requestCsrf());
      yield take(SessionActions.CSRF_SET);

      yield fork(() => performApiPost<LoginInfo>(
        config.api.login,
        {
          payload: {
            username: act.payload.username,
            password: act.payload.password,
          },
          onResult: ({ result, error }) => put(setLogin({
            loginInfo: result,
            error,
          })),
        },
      ));
    },
  );
}

function* loginSetFlow() {
  yield takeEvery(
    SessionActions.LOGIN_SET,
    (act: PayloadAction<SetLoginArgs>) => {
      if (!act.payload.error) {
        localStorage.setItem(LOGIN_STORAGE, JSON.stringify(act.payload.loginInfo));
        window.authToken = act.payload.loginInfo?.key;
      }
    },
  );
}

function* requestLogoutFlow() {
  yield takeEvery(
    SessionActions.LOGOUT_REQUEST,
    function* _() {
      yield fork(() => performApiPost<LoginInfo>(
        config.api.logout,
        {
          onResult: ({ error }) => put(setLoggedOut({
            error,
          })),
        },
      ));
    },
  );
}

function* loggedOutSetFlow() {
  yield takeEvery(
    SessionActions.LOGOUT_SET,
    () => {
      localStorage.removeItem(LOGIN_STORAGE);
      window.authToken = undefined;
    },
  );
}

function* requestUserExtraFlow() {
  yield takeEvery(
    SessionActions.USER_EXTRA_REQUEST,
    function* _(act: PayloadAction<RequestUserExtraArgs>) {
      yield performanceUtils.forLast(act, 500);

      yield fork(() => performApiGet<UserExtraInfo>(
        config.api.getUserExtra,
        {
          params: {
            user: act.payload.user,
          },
          onResult: ({ result, error }) => put(setUserExtra({
            userExtraInfo: result,
            error,
          })),
        },
      ));
    },
  );
}

export default function* sessionSaga() {
  yield all([
    initializeFlow(),
    requestCsrfFlow(),
    requestLoginFlow(),
    loginSetFlow(),
    requestLogoutFlow(),
    loggedOutSetFlow(),
    requestUserExtraFlow(),
  ]);
}
