import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';

import {
  openGlossaryBubble,
  openMessageBox,
} from '../../main/actions';

import {
  setSearchKeyword,
  toggleGptChat,
} from '../../search/actions';

import {
  openSourceBubble,
  closeSourceBubble,
  startExecuteTasks,
  setChatItemTasks,
  applyTaskTemplate,
  requestDeleteTaskTemplate,
} from '../actions';

import GptTasksView from './GptTasksView';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.search,
    ...state.gpt,
    networkStates: {
      ...state.search.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    openMessageBox,
    openGlossaryBubble,
    setSearchKeyword,
    toggleGptChat,
    openSourceBubble,
    closeSourceBubble,
    startExecuteTasks,
    setChatItemTasks,
    applyTaskTemplate,
    requestDeleteTaskTemplate,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GptTasksView);
