import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Popover,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import classNames from 'classnames';
import { useResizeDetector } from 'react-resize-detector';
import { AnimatePresence, motion } from 'framer-motion';

import useCommonProps from '../../../../theme/commonProps';
import { TypingIndicator } from '../../../../components';
import { useCommonClasses } from '../../../../theme/commonStyles';
import { isJsonString } from '../../../../core/utils/stringUtils';

import { ReactComponent as GuidesIcon } from '../../../../assets/svg/guidesIcon.svg';
import { ReactComponent as BrowseToolsIcon } from '../../../../assets/svg/browseToolsIcon.svg';
import { ReactComponent as CaretDownIcon } from '../../../../assets/svg/caretDownIcon.svg';
import { ReactComponent as ProceedIcon } from '../../../../assets/svg/proceedIcon.svg';
import { ReactComponent as CaretLeftIcon } from '../../../../assets/svg/caretLeftIcon.svg';

import { GptChatItem } from '../types';

import GptChatItemView from '../gptChatItemView';
import ChatExampleView from '../chatExampleView';
import { getAvailableAgents } from '../gptUtils';

import { PropsFromRedux } from './container';
import styles from './GptChatView.styles';

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);

const GptChatView: React.FC<Props> = ({
  chats,
  requestChatResponse,
  networkStates: {
    chatResponseRequest,
    taskerExecuteRequest,
  },
  cancelChatRequest,
  requestTaskTemplates,
  settings,
  updateSettings,
  gptModels,
  userExtraInfo,
  setShowingGuidesAtHome,
  setShowingToolsAtHome,
  isShowingGuidesAtHome,
  isShowingToolsAtHome,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();
  const commonProps = useCommonProps();
  const [chatMessage, setChatMessage] = useState('');
  const [agentsPopupTarget, setAgentsPopupTarget] = useState<HTMLButtonElement>();
  const [modelsPopupTarget, setModelsPopupTarget] = useState<HTMLButtonElement>();
  const [showMoreToolExamples, setShowMoreToolExamples] = useState<string>();

  const lastChatItemRef = useRef<HTMLDivElement>(null);

  const availableAgents = getAvailableAgents().map((agent) => {
    if (agent.value === 'plan_execute') {
      return {
        ...agent,
        options: [{
          name: 'Show Plan',
          value: settings.showPlan,
          onChange: (checked: boolean) => {
            updateSettings({
              settings: {
                ...settings,
                showPlan: checked,
              },
            });
          },
        }],
      };
    }

    return {
      ...agent,
      options: undefined,
    };
  });

  const availableModels = (gptModels || []).filter((model) => (
    model.permLevel <= (userExtraInfo?.results.at(0)?.permLevel || 0)
    && model.active
  ));

  const getSelectedAgentText = () => {
    const selectedAgent = (availableAgents)
      .find((agent) => agent.value === settings.agent);

    return selectedAgent?.name;
  };

  const getSelectedModelText = () => {
    const selectedModel = (gptModels || [])
      .find((model) => model.modelName === settings.gptModelName);

    return selectedModel?.modelName;
  };

  const selectedAgent = availableAgents.find((agent) => agent.value === settings.agent);

  const isPlanExecuteChatInput = (chatItem: GptChatItem, index: number) => {
    const chatContent = (
      chatItem.content
      && isJsonString(chatItem.content)
      && JSON.parse(chatItem.content)
    ) || undefined;

    if (!chatContent) {
      return false;
    }

    if (chatItem.type === 'user'
      && chats.at(index - 1)?.agent === 'plan_execute'
    ) {
      return true;
    }

    return false;
  };

  const isPlanExecuteChatPlan = (chatItem: GptChatItem, index: number) => {
    if (chatItem.type !== 'bot') {
      return false;
    }

    if (!chatItem.structuredContent || chatItem.agent !== 'plan_execute' || chatItem.content) {
      return false;
    }

    const nextChatItem = chats.at(index + 1);
    if (!nextChatItem) {
      return false;
    }

    if (isPlanExecuteChatInput(nextChatItem, index + 1)) {
      return true;
    }

    return false;
  };

  const isPlanExecuteChatAnswer = (chatItem: GptChatItem, index: number) => {
    const prevChatItem = chats.at(index - 1);
    if (!prevChatItem || chatItem.type !== 'bot') {
      return false;
    }

    if (isPlanExecuteChatInput(prevChatItem, index - 1)) {
      return true;
    }

    return false;
  };

  const isPlanExecuteChatPlanAlreadyHasAnswer = (chatItem: GptChatItem, index: number) => {
    if (!chatItem) {
      return false;
    }

    if (!isPlanExecuteChatPlan(chatItem, index)) {
      return false;
    }

    const nextChatItem = chats.at(index + 2);
    if (!nextChatItem) {
      return false;
    }

    if (nextChatItem.type === 'bot'
      && !nextChatItem.isProcessing
      && nextChatItem.structuredContent
      && 'structured' in nextChatItem.structuredContent
      && 'tasks' in (nextChatItem.structuredContent.structured as object)) {
      return true;
    }

    return false;
  };

  const { width: rootWidth, ref: rootRef } = useResizeDetector();

  useEffect(() => {
    requestTaskTemplates();
  }, []);

  const performRequestChat = () => {
    requestChatResponse({
      input: chatMessage,
      // isTest: true,
    });

    setChatMessage('');
  };

  const handleChatKeydown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!event.shiftKey && event.key === 'Enter' && chatMessage.trim().length > 0) {
      performRequestChat();
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    return true;
  };

  const getInputEndAdornment = () => {
    if (chatResponseRequest.isRequesting) {
      return (
        <Button
          classes={commonCss.buttons.roundButton}
          className={
            classNames(
              'tertiary',
              classes.stopGenerateButton,
            )
          }
          size="small"
          onClick={() => cancelChatRequest()}
        >
          Stop
        </Button>
      );
    }

    if (!chatMessage.trim()) {
      return undefined;
    }

    return (
      <Box position="relative" alignSelf="flex-start">
        <Button
          classes={commonCss.buttons.transparentButton}
          className={
            classNames(
              'tertiary',
              classes.proceedButton,
            )
          }
          onClick={() => performRequestChat()}
        >
          <ProceedIcon />
        </Button>
      </Box>
    );
  };

  return (
    <Box ref={rootRef} className={classes.root}>
      {chats.length > 0 && (
        <Box className={classes.gptChatBox}>
          <div className={classes.gptChatBoxTopBar} />
          {chats.map((chatItem, index) => (
            <div
              key={index}
              className={
                classNames(
                  classes.gptChatItemContainer,
                  chatItem.type === 'user' ? 'secondary' : 'tertiary',
                  isPlanExecuteChatInput(chatItem, index) && 'hidden',
                  isPlanExecuteChatPlan(chatItem, index) && 'noMargin',
                  isPlanExecuteChatPlanAlreadyHasAnswer(chatItem, index) && 'hidden',
                )
              }
              ref={index === chats.length - 1 ? lastChatItemRef : undefined}
            >
              <GptChatItemView
                chatItem={chatItem}
                chatItemIndex={index}
                rootWidth={rootWidth}
                hideIcon={(
                  isPlanExecuteChatAnswer(chatItem, index)
                  && chats.at(index - 2)
                  && !isPlanExecuteChatPlanAlreadyHasAnswer(chats.at(index - 2)!, index - 2)
                )}
              />
            </div>
          ))}
          {taskerExecuteRequest.isRequesting && (
            <div style={{ marginLeft: theme.spacing(6), marginBottom: theme.spacing(2) }}>
              <TypingIndicator />
            </div>
          )}
        </Box>
      )}
      <AnimatePresence>
        {!isShowingGuidesAtHome && (
          <Box
            className={
              classNames(
                classes.gptPromptInputBox,
                !chats.length && 'intro',
              )
            }
          >
            {!chats.length && (
              <Box marginTop={7.5} marginBottom={2}>
                <Typography variant="h6" className={classes.introHeader} align="center">
                  <span className={classes.valHighlight}>Meet VAL</span>, your personal AI analyst
                </Typography>
              </Box>
            )}
            <TextField
              className={classes.gptPromptInput}
              {...commonProps.textField({
                color: 'tertiary',
                endAdornment: getInputEndAdornment(),
              })}
              size="small"
              fullWidth
              placeholder="Ask VAL anything..."
              value={chatMessage}
              onChange={(event) => setChatMessage(event.target.value)}
              onKeyDown={(event) => handleChatKeydown(event)}
              multiline
              disabled={chatResponseRequest.isRequesting}
            />
            <Box className={classes.miniSettingsBox}>
              <Button
                classes={commonCss.buttons.roundButton}
                className={classes.miniSettingsButton}
                size="small"
                onClick={(event) => setAgentsPopupTarget(event.currentTarget)}
              >
                {getSelectedAgentText()}
                <Box ml={1} display="flex" alignItems="center">
                  <CaretDownIcon />
                </Box>
              </Button>
              {(selectedAgent?.options || []).map((option) => (
                <Box key={option.name} mr={1} display="flex" alignItems="center">
                  <FormControlLabel
                    {...commonProps.formControlLabel({ color: 'tertiary' })}
                    classes={{
                      root: classes.gptModelOptionLabel,
                      label: classNames(
                        classes.gptModelOptionLabel,
                      ),
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onChange={(_, checked) => {
                      option.onChange(checked);
                    }}
                    control={(
                      <Checkbox
                        size="small"
                        checked={option.value}
                        style={{ marginLeft: 12, marginRight: 4, padding: 0 }}
                      />
                    )}
                    label={option.name}
                    style={{
                      marginRight: 0,
                    }}
                  />
                </Box>
              ))}
              <Button
                classes={commonCss.buttons.roundButton}
                className={classes.miniSettingsButton}
                size="small"
                onClick={(event) => setModelsPopupTarget(event.currentTarget)}
              >
                {getSelectedModelText()}
                <Box ml={1} display="flex" alignItems="center">
                  <CaretDownIcon />
                </Box>
              </Button>
            </Box>
          </Box>
        )}
        {isShowingGuidesAtHome && (
          <motion.div
            className={classes.guideContainer}
            initial={{
              x: -20,
              y: 0,
              opacity: 0,
            }}
            animate={{
              x: 0,
              y: 0,
              opacity: 1,
            }}
            exit={{
              x: -20,
              y: 0,
              opacity: 0,
            }}
          >
            <iframe src="https://userguides.super.site/" className={classes.guideFrame} />
          </motion.div>
        )}
      </AnimatePresence>
      {!chats.length && (
        <>
          {!isShowingGuidesAtHome && (
            <ChatExampleView
              className={classes.exampleBox}
              onShowMoreToolsChange={(tool) => setShowMoreToolExamples(tool)}
            />
          )}
          {!showMoreToolExamples && (
            <Box display="flex" justifyContent="center" columnGap={1} marginTop={3}>
              {!isShowingToolsAtHome && (
                <Button
                  color="info"
                  classes={commonCss.buttons.roundButton}
                  className={classes.guidesButton}
                  variant="outlined"
                  onClick={() => setShowingGuidesAtHome({
                    isShowing: !isShowingGuidesAtHome,
                  })}
                >
                  <Box display="flex" alignItems="center" mr={1}>
                    {!isShowingGuidesAtHome ? (
                      <GuidesIcon />
                    ) : (
                      <CaretLeftIcon />
                    )}
                  </Box>
                  {!isShowingGuidesAtHome ? 'Guides' : 'Go Back'}
                </Button>
              )}
              {!isShowingGuidesAtHome && (
                <Button
                  color="info"
                  classes={commonCss.buttons.roundButton}
                  className={classes.browseToolsButton}
                  variant="outlined"
                  onClick={() => setShowingToolsAtHome({
                    isShowing: !isShowingToolsAtHome,
                  })}
                >
                  <Box display="flex" alignItems="center" mr={1}>
                    {!isShowingToolsAtHome ? (
                      <BrowseToolsIcon />
                    ) : (
                      <CaretLeftIcon />
                    )}
                  </Box>
                  {!isShowingToolsAtHome ? 'Browse Tools' : 'Go Back'}
                </Button>
              )}
            </Box>
          )}
        </>
      )}
      <Popover
        open={!!agentsPopupTarget}
        anchorEl={agentsPopupTarget}
        onClose={() => setAgentsPopupTarget(undefined)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 100, horizontal: 0 }}
        classes={{
          paper: classNames(classes.settingPopup, 'extended'),
        }}
      >
        {(availableAgents || []).map((agent) => (
          <div
            key={agent.name}
            className={classes.settingItem}
            onClick={() => {
              updateSettings({
                settings: {
                  ...settings,
                  agent: agent.value,
                },
              });

              setAgentsPopupTarget(undefined);
            }}
          >
            <Grid container>
              <Grid item container xs={4.5} alignItems="center">
                <Typography variant="body2" className={classes.settingItemName}>
                  {agent.name}
                </Typography>
              </Grid>
              <Grid item container xs={7.5} alignItems="center">
                <Typography variant="body2" className={classes.settingItemDescription}>
                  {agent.description}
                </Typography>
              </Grid>
            </Grid>
            <Box marginTop={1}>
              <Divider />
            </Box>
          </div>
        ))}
      </Popover>
      <Popover
        open={!!modelsPopupTarget}
        anchorEl={modelsPopupTarget}
        onClose={() => setModelsPopupTarget(undefined)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 100, horizontal: 0 }}
        classes={{
          paper: classes.settingPopup,
        }}
      >
        {(availableModels || []).map((model) => (
          <div
            key={model.modelName}
            className={classes.settingItem}
            onClick={() => {
              updateSettings({
                settings: {
                  ...settings,
                  gptModelInput: model.modelInput,
                  gptModelName: model.modelName,
                },
              });

              setModelsPopupTarget(undefined);
            }}
          >
            <Grid container>
              <Grid item container xs={12} alignItems="center">
                <Typography variant="body2" className={classes.settingItemName}>
                  {model.modelName}
                </Typography>
              </Grid>
            </Grid>
            <Box marginTop={1}>
              <Divider />
            </Box>
          </div>
        ))}
      </Popover>
    </Box>
  );
};

export default GptChatView;
