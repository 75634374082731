import { Edge, Node } from '@sayari/trellis';
import NetworkState from '../../../core/types/network';

export enum RelationChartActions {
  RELATION_DATA_REQUEST = '@@relationChart/RELATION_DATA_REQUEST',
  RELATION_DATA_SET = '@@relationChart/RELATION_DATA_SET',
  ENTITY_BUBBLE_OPEN = '@@relationChart/ENTITY_BUBBLE_OPEN',
  ENTITY_BUBBLE_CLOSE = '@@relationChart/ENTITY_BUBBLE_CLOSE',
  ENTITY_BUBBLE_SHOWMORE_TOGGLE = '@@relationChart/ENTITY_BUBBLE_SHOWMORE_TOGGLE',
  INVISIBLE_ENTITY_IDS_UPDATE = '@@relationChart/INVISIBLE_ENTITY_IDS_UPDATE',
}

export interface RelationData {
  nodes: RelationNode[];
  relationships: Edge[];
}

export interface RelationEntity {
  entityType: 'Person' | 'Organization' | 'Industry' | 'Location' | 'Other';
  label?: string;
  count: number;
  wikiUrl: string;
  wikiTitle: string;
  summary?: string;
  website?: string;
  linkedinUrl?: string;
  pvalyouUrl?: string;
  infobox?: RelationNodeInfoBox[];
}

export interface RelationNode extends Node, RelationEntity {}

export interface RelationNodeInfoBox {
  key: string;
  value: string;
  wikiLinks: Record<string, string>;
}

export interface RequestRelationDataArgs {
  text?: string;
  companyId?: string;
  conversationId?: string;
}

export interface SetRelationDataArgs {
  data?: RelationData;
  error?: Error;
}

export interface OpenEntityBubbleArgs {
  entityName: string;
  entityType: string;
  originX: number;
  originY: number;
}

export interface UpdateInvisibleEntityIdsArgs {
  entityIds: string[];
}

export interface RelationChartState {
  networkStates: {
    relationDataRequest: NetworkState;
  };
  relationData?: RelationData;
  invisibleEntityIds: string[];
  entityBubble: {
    isOpen: boolean;
    entityName?: string;
    entityType?: string;
    originX?: number;
    originY?: number;
    isShowingMore?: boolean;
  }
}
