import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    founderInfoContainer: {
      marginTop: `-${theme.spacing(5.25)}`,
      overflow: 'visible',
    },
    reportDetailsBody: {
      marginTop: 0,
    },
  });
};

export default styles;
