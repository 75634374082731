import { createReducer } from '@reduxjs/toolkit';
import { getYear } from 'date-fns';
import jsonpath from 'jsonpath';
import {
  updateManualFormField,
  setAvailableKeywords,
  setStep,
  initializeManualForm,
  initiateManualFormSave,
  setManualFormSaved,
  requestLinkedInProfile,
  setLinkedInProfile,
  setIsCancelling,
  setFocusedDataInfoKey,
  setHoveredDataInfoKey,
} from './actions';

import {
  FounderInfo,
  ManualFormData,
  ManualFormState,
} from './types';
import { hasErrorInStep, validate } from './manualFormUtils';

const initialState: ManualFormState = {
  networkStates: {
    linkedInProfileRequest: { isRequesting: false },
    saveRequest: { isRequesting: false },
  },
  currentStep: 0,
  isSuccessfullySaved: false,
  isCancelling: false,
};

const manualFormReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(initializeManualForm, (state) => ({
      ...state,
      currentStep: 0,
      manualFormData: {
        companyInfo: {
          yearFounded: getYear(new Date()),
        },
        founderInfos: Array.from({ length: 3 }).fill(undefined) as FounderInfo[],
      },
      isSuccessfullySaved: false,
      isCancelling: false,
      validationInfo: undefined,
    }))
    .addCase(setIsCancelling, (state, action) => ({
      ...state,
      isCancelling: action.payload.isCancelling,
    }))
    .addCase(setAvailableKeywords, (state, action) => ({
      ...state,
      availableKeywords: action.payload.keywords,
    }))
    .addCase(setStep, (state, action) => {
      const validationInfo = validate(state);
      const shouldPreventNextStep = (action.payload.step > state.currentStep);

      const nextStep = shouldPreventNextStep && hasErrorInStep(state.currentStep, validationInfo)
        ? state.currentStep
        : action.payload.step;

      return {
        ...state,
        currentStep: nextStep,
        validationInfo,
      };
    })
    .addCase(updateManualFormField, (state, action) => {
      const manualFormData: ManualFormData = {
        ...state.manualFormData || {},
      };

      jsonpath.value(manualFormData, `$.${action.payload.path}`, action.payload.value);

      return ({
        ...state,
        manualFormData,
      });
    })
    .addCase(initiateManualFormSave, (state) => {
      const validationInfo = validate(state);
      const hasError = hasErrorInStep(state.currentStep, validationInfo);

      if (hasError) {
        return {
          ...state,
          validationInfo,
        };
      }

      return ({
        ...state,
        networkStates: {
          ...state.networkStates,
          saveRequest: {
            isRequesting: true,
          },
        },
      });
    })
    .addCase(setManualFormSaved, (state, act) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        saveRequest: {
          isRequesting: false,
          lastError: act.payload.error,
        },
      },
      manualFormData: act.payload.manualForm,
      isSuccessfullySaved: !act.payload.error,
    }))
    .addCase(requestLinkedInProfile, (state, act) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        linkedInProfileRequest: {
          ...state.networkStates.linkedInProfileRequest,
          isRequesting: true,
          index: act.payload.founderIndex,
        },
      },
    }))
    .addCase(setLinkedInProfile, (state, act) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        linkedInProfileRequest: {
          isRequesting: false,
          lastError: act.payload.error,
          index: act.payload.founderIndex,
        },
      },
      manualFormData: {
        ...state.manualFormData,
        founderInfos: (state.manualFormData?.founderInfos || []).map((founderInfo, index) => {
          if (index === act.payload.founderIndex && !act.payload.error) {
            const linkedIn = act.payload.linkedInProfile;
            return {
              founderFirstName: linkedIn?.firstName,
              founderLastName: linkedIn?.lastName,
              title: linkedIn?.title,
              isFemale: linkedIn?.isFemale,
              workExp: Math.max(linkedIn?.workExp || 0, 0),
              numPriorJobs: Math.max(linkedIn?.numPriorJobs || 0, 0),
              numCompFounded: Math.max(linkedIn?.numCompFounded || 0, 0),
              wasCeo: linkedIn?.wasCeo,
              wasFaamg: linkedIn?.wasFaamg,
              hasHighEdu: linkedIn?.hasHighEdu,
              numOfAcDeg: Math.max(linkedIn?.numOfAcDeg || 0, 0),
              hasScienceDeg: linkedIn?.hasScienceDeg,
              isTop10Uni: linkedIn?.isTop10Uni,
              hasMbaDeg: linkedIn?.hasMbaDeg,
              hasPhdDeg: linkedIn?.hasPhdDeg,
            };
          }
          return founderInfo;
        }),
      },
    }))
    .addCase(setFocusedDataInfoKey, (state, action) => ({
      ...state,
      focusedDataInfoKey: action.payload.dataInfoKey,
    }))
    .addCase(setHoveredDataInfoKey, (state, action) => ({
      ...state,
      hoveredDataInfoKey: action.payload.dataInfoKey,
    }));
});

export default manualFormReducer;
