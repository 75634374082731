import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';

import {
  Logout as LogoutIcon,
} from '@mui/icons-material';

import { useCommonClasses } from '../../../theme/commonStyles';

import { PropsFromRedux } from './container';

import styles from './Portal.styles';
import GptPortal from './gptPortal';

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);

const Portal: React.FC<Props> = ({
  user,
  requestLogout,
  networkStates: {
    logoutRequest,
    userExtraRequest,
  },
  userExtraInfo,
  requestUserExtra,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const theme = useTheme();
  const [userMenuAnchor, setUserMenuAnchor] = useState<HTMLButtonElement>();

  const userFullName = [user?.firstName || '', user?.lastName || ''].join(' ');
  const creditLeft = (
    (userExtraInfo?.results.at(0)?.credits || 0)
    - (userExtraInfo?.results.at(0)?.creditsUsed || 0)
  );

  useEffect(() => {
    if (user && !userExtraRequest.isRequesting) {
      requestUserExtra({
        user: String(user.pk),
      });
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <Container className={classes.headerContainer}>
        <div className={classes.header}>
          <Box
            sx={{
              position: 'absolute',
              left: theme.spacing(0),
              top: theme.spacing(2.5),
              display: 'flex',
            }}
          >
            <Typography variant="body1" className={classes.credit}>
              {creditLeft}
            </Typography>
            <Typography variant="body1" className={classes.creditLeft}>
              Credits
            </Typography>
          </Box>
          <Button
            variant="text"
            color="secondary"
            onClick={(event) => setUserMenuAnchor(event.currentTarget)}
            sx={{
              position: 'absolute',
              right: theme.spacing(0),
            }}
          >
            <Box marginRight={1} display="inline-block">
              <Avatar
                classes={commonCss.avatars.user}
                alt=""
                src=""
              />
            </Box>
            {`Hello ${userFullName}`}
          </Button>
          <Menu
            open={Boolean(userMenuAnchor)}
            anchorEl={userMenuAnchor}
            onClose={() => setUserMenuAnchor(undefined)}
          >
            <MenuItem onClick={() => requestLogout()}>
              <ListItemIcon>
                {logoutRequest.isRequesting ? (
                  <CircularProgress size={12} />
                ) : (
                  <LogoutIcon />
                )}
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </MenuItem>
          </Menu>
        </div>
      </Container>
      <div className={classes.body}>
        <GptPortal />
      </div>
    </div>
  );
};

export default Portal;
