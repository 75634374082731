import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles, { getContrastColorModifierClasses } from '../../../theme/commonStyles';
import { sourceRefColor } from '../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flex: 1,
    },
    chartHeaderPanel: {
      position: 'absolute',
      top: theme.spacing(2),
      left: theme.spacing(0),
      right: theme.spacing(0),
      height: 'auto',
    },
    chartHeaderButton: {
      minWidth: 'auto !important',
      borderRadius: `${theme.spacing(1)} !important`,
      backgroundColor: `${theme.palette.common.white} !important`,
      display: 'flex',
      alignItems: 'center !important',
      '&.active': {
        ...getContrastColorModifierClasses('backgroundColor', '.active'),
        '& *': {
          color: `${theme.palette.common.white} !important`,
        },
      },
      justifyContent: 'center',
      '& .collapsibleLabel': {
        overflow: 'hidden',
        maxWidth: 0,
        transition: 'all 0.5s ease-out',
        marginLeft: 0,
      },
      '&:hover .collapsibleLabel, &.active .collapsibleLabel': {
        maxWidth: 150,
        marginLeft: theme.spacing(0.5),
      },
    },
    entityBubbleContent: {
      fontSize: '0.92rem !important',
      color: theme.palette.grey[700],
      fontWeight: 400,
      lineHeight: '1.3 !important',
    },
    entityBubbleInfoBox: {
      fontSize: '0.92rem !important',
      letterSpacing: '0.105px !important',
      lineHeight: '1.3 !important',
    },
    entityBubbleInfoBoxHead: {
      fontWeight: 'bold !important',
      fontSize: '0.9rem !important',
      verticalAlign: 'top !important',
      letterSpacing: '0.105px !important',
      lineHeight: '1.3 !important',
    },
    entityBubbleInfoBoxValue: {
      paddingLeft: `${theme.spacing(1)} !important`,
      fontSize: '0.92rem !important',
      verticalAlign: 'top !important',
      lineHeight: '1.3 !important',
      letterSpacing: '0.105px !important',
    },
    entityBubbleInfoBoxLink: {
      textTransform: 'capitalize',
      letterSpacing: '0.105px !important',
      lineHeight: '1.3 !important',
    },
    entityBubbleShowMore: {
      cursor: 'pointer',
      textDecoration: 'none',
      fontSize: '0.92rem !important',
      color: `${sourceRefColor} !important`,
      textDecorationColor: `${sourceRefColor} !important`,
      letterSpacing: '0.105px !important',
      lineHeight: '1.3 !important',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    legendItems: {
      width: 'calc(100% + 36px) !important',
      marginLeft: `${theme.spacing(-2)} !important`,
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    },
    layoutToggleButton: {
      '& + p': {
        opacity: 0,
        transition: 'all ease-out 0.3s',
      },
      '&:hover + p': {
        opacity: 1,
      },
    },
  });
};

export default styles;
