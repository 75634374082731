import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      background: theme.palette.background.paper,
    },
    headerContainer: {
      position: 'relative',
      width: '850px !important',
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      padding: `${theme.spacing(2)} 0`,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      flex: 0,
    },
    credit: {
      fontSize: '1.285rem !important',
      letterSpacing: '0.18px',
      color: `${theme.palette.secondary.main} !important`,
      textTransform: 'uppercase',
      fontWeight: 'normal !important',
      marginRight: `${theme.spacing(1)} !important`,
    },
    creditLeft: {
      fontSize: '1.285rem !important',
      letterSpacing: '0.18px',
      color: `${theme.palette.grey[300]} !important`,
      fontWeight: 'normal !important',
    },
    body: {
      display: 'flex',
      flex: 1,
    },
  });
};

export default styles;
