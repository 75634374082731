import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { v4 } from 'uuid';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Highlighter from 'react-highlight-words';

import { ReactComponent as CaretLeftIcon } from '../../../assets/svg/caretLeftIcon.svg';
import { ReactComponent as CaretRightIcon } from '../../../assets/svg/caretRightIcon.svg';

import { CalloutBubble, PopupDialog, ProtectedRoute } from '../../../components';
import { useCommonClasses } from '../../../theme/commonStyles';
import useCommonProps from '../../../theme/commonProps';
import { validateEmail } from '../../../core/utils/validationUtils';

import Login from '../login';
import Notifier from '../notifier';
import Report from '../report';
import Portal from '../portal';
import ManualForm from '../manualForm';

import routes from './routes';
import { PropsFromRedux } from './container';
import { ContactFormInfo } from './types';
import styles from './Main.styles';
import { isNullOrUndefined } from '../../../core/utils/dataUtils';

const useStyles = makeStyles(styles);

interface Props extends PropsFromRedux {}

function refineMessageBoxContent(content: string | ReactNode | ReactNode[]) {
  if (typeof content === 'string') {
    return content.split('\n').map((subContent) => (
      <Typography variant="body1" key={v4()}>
        {subContent}
      </Typography>
    ));
  }

  return content;
}

const Main: React.FC<Props> = ({
  initializeSession,
  user,
  isSessionInitialized,
  token,
  isMessageBoxOpen,
  isFeedbackFormOpen,
  isContactFormOpen,
  messageBoxInfo,
  closeMessageBox,
  closeFeedbackForm,
  closeContactForm,
  requestSubmitContactForm,
  // openGlossaryBubble,
  closeGlossaryBubble,
  glossaryBuble,
  setSearchKeyword,
  networkStates: {
    glossaryItemsRequest,
  },
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();
  const commonProps = useCommonProps();
  const navigate = useNavigate();
  const location = useLocation();
  const [contactFormInfo, setContactFormInfo] = useState<ContactFormInfo>();
  const firstContactFormFieldRef = useRef<HTMLInputElement>(null);
  const userDisplayName = [user?.firstName, user?.lastName].join(' ').trim() || user?.username;
  const [currentSelectedText, setCurrentSelectedText] = useState('');
  const [currentSelectedRect, setCurrentSelectedRect] = useState<DOMRect>();
  const [glossaryIndex, setGlossaryIndex] = useState(0);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryString = urlSearchParams.toString();

  const { glossaryItems } = glossaryBuble;
  const currentGlossaryItem = glossaryItems?.at(glossaryIndex);

  useEffect(() => {
    if (!isSessionInitialized) {
      initializeSession();
    }
  }, [isSessionInitialized, initializeSession]);

  useEffect(() => {
    if (!isSessionInitialized) {
      return;
    }

    if (!user) {
      navigate(routes.login, { replace: true });
      return;
    }

    if (location.pathname === '/') {
      navigate(`${routes.portal}${queryString ? `?${queryString}` : ''}`, { replace: true });
    }
  }, [isSessionInitialized, user]);

  useEffect(() => {
    if (isContactFormOpen) {
      setContactFormInfo({
        user,
        email: user?.email,
        name: userDisplayName,
      });
      setTimeout(() => {
        firstContactFormFieldRef.current?.focus();
      }, 100);
    }
  }, [isContactFormOpen]);

  useEffect(() => {
    const listener = () => {
      const selection = window.getSelection();
      if (selection?.anchorNode instanceof HTMLElement) {
        if (selection?.anchorNode.classList.contains('MuiInputBase-root')) {
          return;
        }

        if (selection?.anchorNode instanceof HTMLInputElement) {
          return;
        }
      }
      const selectedText = selection?.toString();
      const range = (
        selection
        && (selection?.rangeCount || 0) > 0
      ) ? selection.getRangeAt(0) : undefined;

      const rect = range?.getBoundingClientRect();
      const totalWords = (selectedText || '').split(' ').length;
      const wholeTextLength = (selectedText || '').replaceAll(' ', '').length;

      if (rect && (
        !isNullOrUndefined(rect?.left)
        && !isNullOrUndefined(rect?.top)
        && selectedText
        && (totalWords >= 1 && totalWords <= 3)
        && wholeTextLength > 2
      )) {
        setCurrentSelectedText(selectedText);
        setCurrentSelectedRect(rect);
      } else {
        setCurrentSelectedText('');
        setCurrentSelectedRect(undefined);
        closeGlossaryBubble();
      }
    };

    document.addEventListener('selectionchange', listener);

    return () => {
      document.removeEventListener('selectionchange', listener);
    };
  }, []);

  useEffect(() => {
    if (!glossaryBuble.glossaryItems || glossaryBuble.glossaryItems.length === 0) {
      return;
    }

    setGlossaryIndex(0);
  }, [glossaryBuble.glossaryItems]);

  const dismissMessageBox = () => {
    if (messageBoxInfo?.onDismiss) {
      messageBoxInfo?.onDismiss();
    }

    return closeMessageBox();
  };

  return (
    <div
      className={classes.root}
      onMouseUp={() => {
        if (currentSelectedText && currentSelectedRect) {
          // openGlossaryBubble({
          //   originX: currentSelectedRect.left + (currentSelectedRect.width / 2),
          //   originY: currentSelectedRect.top,
          //   phrase: currentSelectedText,
          // });
        }
      }}
    >
      {isSessionInitialized && (
        <Routes>
          <Route
            path={routes.login}
            element={<Login />}
          />
          <Route
            element={
              <ProtectedRoute
                token={token}
                redirectPath={routes.login}
              />
            }
          >
            <Route
              path={routes.portal}
              element={<Portal />}
            />
            <Route
              path={routes.manualForm}
              element={<ManualForm />}
            />
            <Route
              path={`${routes.reports}/:id/*`}
              element={<Report />}
            />
          </Route>
        </Routes>
      )}
      <Notifier />
      <PopupDialog
        open={messageBoxInfo && isMessageBoxOpen}
        closeAction={dismissMessageBox}
        classes={{
          backdrop: classNames(
            classes.messageBoxBackdrop,
            messageBoxInfo?.blurBackdrop
              ? classes.messageBoxBackdropBlur
              : undefined,
          ),
        }}
        renderCommandActions={() => (
          <Box textAlign="center" width="100%">
            {messageBoxInfo?.commands?.map((command) => (
              <Button
                key={command.title}
                variant="contained"
                classes={commonCss.buttons.roundButton}
                className={classNames(classes.messageBoxButton, messageBoxInfo.colorSet)}
                onClick={() => {
                  closeMessageBox();
                  return command.action();
                }}
              >
                {command.title}
              </Button>
            ))}
            <Button
              variant="outlined"
              classes={commonCss.buttons.roundButton}
              className={classes.messageBoxButton}
              color="info"
              onClick={() => dismissMessageBox()}
            >
              {messageBoxInfo?.dismissText}
            </Button>
          </Box>
        )}
      >
        {refineMessageBoxContent(messageBoxInfo?.content)}
      </PopupDialog>
      <PopupDialog
        open={isFeedbackFormOpen}
        closeButton
        closeAction={closeFeedbackForm}
        maxWidth="lg"
        classes={{
          backdrop: classNames(
            classes.messageBoxBackdrop,
            messageBoxInfo?.blurBackdrop
              ? classes.messageBoxBackdropBlur
              : undefined,
          ),
        }}
      >
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSewa0u8irrdGgdAsoaCHfMPfnyDwqZsknbW4tpBlSkfi97iFA/viewform?embedded=true"
          width="840"
          height="640"
          style={{
            border: '1px solid #ccc',
          }}
        >
          Loading…
        </iframe>
      </PopupDialog>
      <PopupDialog
        open={isContactFormOpen}
        closeAction={closeContactForm}
        maxWidth="md"
        classes={{
          backdrop: classNames(
            classes.messageBoxBackdrop,
            messageBoxInfo?.blurBackdrop
              ? classes.messageBoxBackdropBlur
              : undefined,
          ),
        }}
        renderCommandActions={() => (
          <Box textAlign="center" width="100%">
            <Button
              variant="contained"
              classes={commonCss.buttons.roundButton}
              className={classNames(classes.messageBoxButton, 'secondary')}
              onClick={() => {
                if (contactFormInfo) {
                  requestSubmitContactForm({
                    formInfo: contactFormInfo,
                  });
                }

                closeContactForm();
              }}
              disabled={(
                !contactFormInfo?.email
                || !validateEmail(contactFormInfo?.email || '')
                || !contactFormInfo?.name
              )}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              classes={commonCss.buttons.roundButton}
              className={classes.messageBoxButton}
              color="info"
              onClick={() => closeContactForm()}
            >
              Cancel
            </Button>
          </Box>
        )}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h6" className={classes.contactFormTitle}>
            {'We are HERE to HEAR you :)'}
          </Typography>
          <Box marginTop={2} width={500}>
            <TextField
              {...commonProps.textField({
                label: 'Email Address',
                color: 'info',
                required: true,
                readOnly: !!user?.email,
                inputRef: !contactFormInfo?.email ? firstContactFormFieldRef : undefined,
              })}
              size="small"
              fullWidth
              value={contactFormInfo?.email || ''}
              error={!contactFormInfo?.email || !validateEmail(contactFormInfo?.email)}
              helperText={(
                !contactFormInfo?.email?.trim()
                  ? 'Required'
                  : (
                    !validateEmail(contactFormInfo?.email || '')
                  ) && 'Please enter a valid email address'
              )}
              onChange={(event) => setContactFormInfo({
                ...contactFormInfo,
                email: event.target.value.trim(),
              })}
            />
          </Box>
          <Box marginTop={2} width={500}>
            <TextField
              {...commonProps.textField({
                label: 'Name',
                color: 'info',
                required: true,
                readOnly: !!userDisplayName,
              })}
              size="small"
              fullWidth
              value={contactFormInfo?.name || ''}
              error={!contactFormInfo?.name}
              helperText={(
                !contactFormInfo?.name?.trim()
                && 'Required'
              )}
              onChange={(event) => setContactFormInfo({
                ...contactFormInfo,
                name: event.target.value,
              })}
            />
          </Box>
          <Box marginTop={2} width={500}>
            <TextField
              {...commonProps.textField({
                label: 'Type here',
                color: 'info',
                required: true,
                inputRef: contactFormInfo?.email ? firstContactFormFieldRef : undefined,
              })}
              fullWidth
              multiline
              rows={5}
              value={contactFormInfo?.description || ''}
              // error={!contactFormInfo?.description}
              // helperText={(
              //   !contactFormInfo?.description?.trim()
              //   && 'Required'
              // )}
              onChange={(event) => setContactFormInfo({
                ...contactFormInfo,
                description: event.target.value,
              })}
            />
          </Box>
        </Box>
      </PopupDialog>
      <CalloutBubble
        colorSet="quaternary"
        open={glossaryBuble.isOpen}
        originX={glossaryBuble.originX}
        originY={glossaryBuble.originY}
        onClose={() => closeGlossaryBubble()}
      >
        {glossaryItemsRequest.isRequesting ? (
          <Box display="flex" flex="1" alignItems="center" justifyContent="center">
            <CircularProgress color="info" size={12} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column">
            {!currentGlossaryItem ? (
              <Typography variant="body2">
                No definition found
              </Typography>
            ) : (
              <Box
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
                onMouseUp={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body2">
                    <strong>
                      {currentGlossaryItem.term}
                      {currentGlossaryItem.abbreviation && (
                        ` (${currentGlossaryItem.abbreviation})`
                      )}
                    </strong>
                  </Typography>
                  {(glossaryItems || []).length > 0 && (
                    <Box display="flex" marginLeft={5} alignItems="center">
                      <Box display="flex" alignItems="center">
                        <IconButton
                          size="small"
                          style={{ width: 22 }}
                          onMouseUp={(event) => {
                            event.stopPropagation();
                            event.preventDefault();

                            setGlossaryIndex(Math.max(0, glossaryIndex - 1));
                          }}
                        >
                          <CaretLeftIcon />
                        </IconButton>
                      </Box>
                      {(glossaryItems || []).map((_, index) => (
                        <Box
                          key={index}
                          className={
                            classNames(
                              classes.glossaryDot,
                              index === glossaryIndex && 'active',
                            )
                          }
                        />
                      ))}
                      <Box display="flex" alignItems="center">
                        <IconButton
                          size="small"
                          style={{ width: 22 }}
                          onMouseUp={(event) => {
                            event.stopPropagation();
                            event.preventDefault();

                            setGlossaryIndex(
                              Math.min((glossaryItems || []).length - 1, glossaryIndex + 1),
                            );
                          }}
                        >
                          <CaretRightIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Typography
                  variant="body2"
                  marginTop={1}
                  fontWeight="normal"
                  color={theme.palette.grey[300]}
                >
                  <i>{`in ${currentGlossaryItem.field}`}</i>
                </Typography>
                <Box marginTop={1}>
                  <Highlighter
                    className={classes.glossaryBubbleText}
                    searchWords={currentGlossaryItem.highlights}
                    autoEscape={true}
                    textToHighlight={currentGlossaryItem.definition}
                    highlightTag={(node) => (
                      <Link
                        type="button"
                        className={classes.glossaryBubbleHighlight}
                        onMouseUp={() => {
                          setSearchKeyword({
                            keyword: `"${node.children as string}"`,
                          });
                          closeGlossaryBubble();
                        }}
                      >
                        {node.children}
                      </Link>
                    )}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </CalloutBubble>
    </div>
  );
};

export default Main;
