import { PayloadAction } from '@reduxjs/toolkit';
import { EventChannel } from 'redux-saga';
import {
  all,
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { v4 } from 'uuid';
import { Buffer } from 'buffer';
import { jsonrepair } from 'jsonrepair';
import { format } from 'date-fns';

import config from '../../../config';
import { QuickChartInfo, QuickChartUpdateResponse } from '../../../core/types/quickChart';
import createPerformanceUtils from '../../../core/utils/performanceUtils';

import {
  createIntervalChannel,
  performApiDelete,
  performApiGet,
  performApiPost,
  performApiPut,
  subscribeServerEvent,
} from '../../../core/utils/sagaUtils';
import {
  isBase64Content,
  isJsonString,
  isValidUrl,
} from '../../../core/utils/stringUtils';
import { ApplicationState } from '../../types';
import { requestRelationData, setRelationData } from '../relationChart/actions';
import { toggleGptChat } from '../search/actions';
import { SearchActions } from '../search/types';
import { SessionActions, User } from '../session/types';
import testStreaming from './streaming.json';

import {
  duplicateChatItemChart,
  requestChatHistory,
  requestChatPrompts,
  requestChatResponse,
  requestFollowUpQuestions,
  requestGptModels,
  requestJinaContent,
  requestLocalSource,
  requestTaskerExecute,
  setChatHistory,
  setChatHistoryDeleted,
  setChatItemChartUpdated,
  setChatPromptCreated,
  setChatPromptDeleted,
  setChatPrompts,
  setChatPromptUpdated,
  setChatResponse,
  setChatStreaming,
  setFollowUpQuestions,
  setGptModels,
  setJinaContent,
  setLocalSource,
  setSiteExamples,
  setTableToChart,
  setTaskerExecuted,
  setTasksExecuted,
  setTaskTemplates,
} from './actions';

import {
  extractNakedAndReactIntermediateSteps,
  extractIntermediateStepsSources,
  getApiSourceInfo,
  parseFinalAnswerByAgent,
  extractSelfAskIntermediateSteps,
  refineJsonOutput,
  extractPlanExecuteIntermediateSteps,
  parseStreamingTasks,
  extractIntermediateSteps,
  determineContentFormat,
} from './gptUtils';

import {
  ApplyChatHistoryArgs,
  ApplyChatOutputsCompareArgs,
  ChartResponse,
  ChatHistoryResponse,
  ChatPromptItem,
  ChatPromptsResponse,
  ChatResponseMode,
  FollowUpInfo,
  GetTaskerSummaryResult,
  GptActions,
  GptChatItem,
  GptModelResponse,
  GptSettings,
  GptTaskerInputItem,
  IntermediateStep,
  LocalSourceInfo,
  PlanAndExecuteTask,
  RequestChatItemChartUpdateArgs,
  RequestChatPromptsArgs,
  RequestChatResponseArgs,
  RequestCreateChatPromptArgs,
  RequestDeleteChatHistoryArgs,
  RequestDeleteChatPromptArgs,
  RequestFollowUpQuestionsArgs,
  RequestJinaContentArgs,
  RequestLocalSourceArgs,
  RequestTableToChartArgs,
  RequestTaskerExecuteArgs,
  RequestUpdateChatPromptArgs,
  SetChatHistoryDeletedArgs,
  SetChatResponseArgs,
  SiteExampleResponse,
  StartTasksExecuteArgs,
  TaskTemplate,
  ToggleSourceCitationArgs,
} from './types';
import isDebugMode from '../../../core/utils/debugUtils';
import { getAncestorOf } from '../../../core/utils/dataUtils';
import { triggerNotification } from '../notifier/actions';

const abortControllers: AbortController[] = [];
const eventSources: EventSource[] = [];
const poolingEventChannels: EventChannel<Event>[] = [];
const currentEvents: Event[] = [];
const chatSessionIds: string[] = [];

const examplePerformanceUtils = createPerformanceUtils();
const historyPerformanceUtils = createPerformanceUtils();

function* initializeFlow() {
  yield take(SessionActions.USER_EXTRA_SET);
  yield put(requestGptModels());
}

function terminateLastEventSource() {
  const eventSource = eventSources.pop();
  if (eventSource) {
    eventSource.dispatchEvent(new Event('close'));
    eventSource.close();
  }

  const poolingEventChannel = poolingEventChannels.pop();
  if (poolingEventChannel) {
    poolingEventChannel.close();
  }
}

function pushEventSource(eventSource: EventSource) {
  eventSources.push(eventSource);
}

function* determineRequestFollowUpQuestions(
  steps: IntermediateStep[],
  sessionId: string,
  options?: {
    isStreaming: boolean;
  },
) {
  const allChats = (
    yield select((appState: ApplicationState) => appState.gpt.chats)
  ) as GptChatItem[];

  const needFolowUpQuestions = steps.find((step) => (
    'tool' in step[0]
  )) || allChats.find((chat) => chat.steps?.find((step) => (
    step.items.find((item) => item.name === 'Action')
  )));

  if (needFolowUpQuestions) {
    yield put(requestFollowUpQuestions({
      sessionId,
      isStreaming: options?.isStreaming,
    }));
  }
}

function* poolingDispatchEvents(
  poolingEventChannel: EventChannel<Event>,
  input: string,
  llmModel?: string,
  mode?: ChatResponseMode,
  agent?: string,
  showPlan?: boolean,
  isExecutePlan?: boolean,
) {
  const intermediateSteps: IntermediateStep[][] = [];
  const exceptions: string[] = [];
  const finalAnswers: string[] = [];
  const streams: object[] = [];
  const parsedStreams: object[] = [];

  const needPlan = agent === 'plan_execute' && showPlan;

  yield takeEvery(poolingEventChannel, function* _() {
    while (currentEvents.length > 0) {
      const item = currentEvents.shift();

      if (!item) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const subscribingChatId = (
        yield select((appState: ApplicationState) => appState.gpt.chatId)
      ) as string;

      const settings = (
        yield select((appState: ApplicationState) => appState.gpt.settings)
      ) as GptSettings;

      if (item.type === 'error' || item.type === 'close') {
        poolingEventChannel.close();

        const currentChatStreaming = (
          yield select((appState: ApplicationState) => appState.gpt.chatStreaming)
        ) as string;

        const currentChatId = (
          yield select((appState: ApplicationState) => appState.gpt.chatId)
        ) as string;

        if (String(currentChatId) !== String(subscribingChatId)) {
          yield put(setChatResponse({
            llmModel,
          }));

          return;
        }

        const steps = intermediateSteps.flatMap((is) => is);
        const chatSessionId = chatSessionIds.at(0);
        if (chatSessionId) {
          yield determineRequestFollowUpQuestions(steps, chatSessionId, { isStreaming: true });
        }

        const keptFinalAnswer = finalAnswers.pop();
        const theAnswer = currentChatStreaming || keptFinalAnswer || '';

        const finalAnswer = parseFinalAnswerByAgent(settings.agent, theAnswer) || '';
        const combinedIntermediateSteps = intermediateSteps
          .flatMap((intermediateStep) => intermediateStep);

        yield put(setChatResponse({
          llmModel,
          response: {
            input,
            output: finalAnswer,
            mode,
            chatHistory: [],
            intermediateSteps: combinedIntermediateSteps || [],
            exception: exceptions.at(0),
            needPlan,
            isExecutePlan,
          },
        }));

        if (isDebugMode()) {
          console.log('Final Streams:', streams);
          console.log('Final Parsed Streams:', parsedStreams);
        }

        return;
      }

      if (item.type === 'message') {
        const messageEvent = item as MessageEvent;
        const base64Data = isBase64Content(String(messageEvent.data))
          ? String(messageEvent.data)
          : undefined;

        const buffer = base64Data ? Buffer.from(base64Data, 'base64') : undefined;
        const jsonData = buffer ? buffer.toString('utf8') : '{}';

        const json = JSON.parse(jsonData);
        const path = String(json.path);
        const taskContainer = json['/tasks'];
        const op = String(json.op);
        const data = json.value;

        streams.push({ data: String(messageEvent.data) });

        if (isDebugMode()) {
          parsedStreams.push({
            time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            json,
          });
        }

        // Non: this is the fallback when the model does not support stream.
        // -----------------------------------------------------------------
        if (typeof data === 'string' && path.endsWith('/final_output')) {
          finalAnswers.push(data);
        }

        if (path.includes('HuggingFaceSpaces') && data && typeof data === 'object' && 'generations' in data) {
          const dataInners = Array.isArray(data.generations) ? data.generations as unknown[] : [];
          const generationOutputs = Array.isArray(dataInners.at(0))
            ? dataInners.at(0) as unknown[]
            : [];

          const generationOutput = (generationOutputs.at(0) || {}) as Record<string, string>;
          if ('text' in generationOutput) {
            finalAnswers.push(String(generationOutput.text));
          }
        }
        // -----------------------------------------------------------------

        const hasException = 'exception' in json;
        if (hasException) {
          exceptions.push(String(json.exception));
        }

        const shouldBeInStreaming = !json.session_id
          && path !== '/final_output/output'
          && path !== '/final_output'
          && !path.endsWith('/end_time');

        if (json.session_id) {
          chatSessionIds.push(String(json.session_id));
        }

        if (agent === 'react' || agent === 'naked') {
          const chatAnswer = {
            [path]: data,
          };

          const steps = extractNakedAndReactIntermediateSteps(chatAnswer);

          if (steps.length) {
            intermediateSteps.splice(0, intermediateSteps.length);
            intermediateSteps.push(steps);
          }

          if (jsonData.includes('agent_scratchpad')) {
            yield put(setChatStreaming({
              data: '<br>',
              chatSessionId: chatSessionIds.length > 0
                ? chatSessionIds[chatSessionIds.length - 1]
                : undefined,
              agent,
              exception: exceptions.at(0),
            }));
          }
        }

        if (agent === 'plan_execute') {
          const tasks = parseStreamingTasks(taskContainer as object);

          const chatAnswer = !tasks
            ? {
              [path]: data,
            } : {
              '/tasks': [tasks],
            };

          const steps = extractPlanExecuteIntermediateSteps(chatAnswer);

          if (steps.length) {
            intermediateSteps.push(steps);
          }

          if (path.endsWith('/final_output')
            && typeof data === 'object'
            && 'output' in data) {
            yield put(setChatStreaming({
              data: '<br>',
              chatSessionId: chatSessionIds.length > 0
                ? chatSessionIds[chatSessionIds.length - 1]
                : undefined,
              agent,
              exception: exceptions.at(0),
              needPlan,
            }));
          }

          // This is to make sure the structure part is separated with actual answer.
          const isTaskPrepared = (
            Array.isArray(json)
              && !!json.at(0)
              && typeof json.at(0) === 'object'
              && 'step_hierarchy' in json.at(0)
          ) || (
            typeof json === 'object'
            && '/tasks' in json
          );

          const finishedTasks: PlanAndExecuteTask[] = [];
          const executingTasks: PlanAndExecuteTask[] = [];

          if (typeof json === 'object' && '/tasks' in json) {
            const jsonTaskContainer = json['/tasks'] as object;
            const jsonTasks = parseStreamingTasks(jsonTaskContainer);

            const planExecTasks = jsonTasks;
            (planExecTasks || []).forEach((task) => {
              if (task.tool_output) {
                finishedTasks.push(task);
              } else {
                executingTasks.push(task);
              }
            });
          }

          if (isTaskPrepared || (op === 'replace' && path.endsWith('/final_output') && data === '')) {
            yield put(setChatStreaming({
              data: '\n',
              chatSessionId: chatSessionIds.length > 0
                ? chatSessionIds[chatSessionIds.length - 1]
                : undefined,
              agent,
              exception: exceptions.at(0),
              needPlan,
              executingTasks,
              finishedTasks,
            }));
          }
        }

        if (agent === 'self_ask') {
          const chatAnswer = {
            [path]: data,
          };

          const steps = extractSelfAskIntermediateSteps(chatAnswer);

          if (steps.length) {
            intermediateSteps.push(steps);
          }

          // In case of self_ask, the stream need to be refined to make
          // it works with the rest of logic.
          if (path === '/final_output/intermediate_steps') {
            yield put(setChatStreaming({
              data: '<br>',
              chatSessionId: chatSessionIds.length > 0
                ? chatSessionIds[chatSessionIds.length - 1]
                : undefined,
              agent,
              exception: exceptions.at(0),
            }));
          }

          const isIntermediateStep = (path.includes('Intermediate Answer') && path.endsWith('/final_output'));

          if (isIntermediateStep && data) {
            const refinedOutput = refineJsonOutput(String(data.output));

            const outputInfo = JSON.parse(
              jsonrepair(refinedOutput),
            ) as Record<string, unknown>[];

            const {
              tool,
              tool_input,
              tool_output,
            } = (outputInfo.at(0) || {});

            if (tool && tool_input) {
              const refinedData = [
                `Action: ${String(tool)}`,
                `Action Input: ${String(tool_input)}`,
                `Output: ${JSON.stringify(tool_output || {})}`,
                '<br>',
              ].join(' ');

              yield put(setChatStreaming({
                data: refinedData,
                chatSessionId: chatSessionIds.length > 0
                  ? chatSessionIds[chatSessionIds.length - 1]
                  : undefined,
                agent,
                exception: exceptions.at(0),
              }));
            }
          }
        }

        if (typeof data === 'string' && shouldBeInStreaming) {
          yield put(setChatStreaming({
            data,
            chatSessionId: chatSessionIds.length > 0
              ? chatSessionIds[chatSessionIds.length - 1]
              : undefined,
            agent,
            exception: exceptions.at(0),
            needPlan,
          }));
        }
      }
    }
  });
}

function* subscribeChatEventSource(
  input: string,
  llm?: string,
  mode?: ChatResponseMode,
  sessionId?: string,
  isCompare?: boolean,
  isExecutePlan?: boolean,
  isTest?: boolean,
) {
  terminateLastEventSource();

  const userId = (
    yield select((appState: ApplicationState) => appState.session.user?.pk)
  ) as string;

  const settings = (
    yield select((appState: ApplicationState) => appState.gpt.settings)
  ) as GptSettings;

  const llmChosen: string | undefined = llm || settings.gptModelName;
  const settingAgent = settings.agent;
  const agent = isExecutePlan ? 'plan_execute' : settingAgent;

  const chatStreamingUrl = [
    config.backendUrl,
    isExecutePlan ? config.api.getPlanExecuteOnly : config.api.getChatStreamLog,
  ].join('/');

  const searchParams = new URLSearchParams();
  searchParams.set('input', input);
  searchParams.set('user_id', userId);

  if (llmChosen) {
    searchParams.set('llm', llmChosen);
    searchParams.set('temperature', String(settings.temperature));
  }

  if (agent && !isExecutePlan) {
    searchParams.set('agent', agent);

    if (agent === 'plan_execute' && settings.showPlan) {
      searchParams.set('plan', 'true');
    }
  }

  if (sessionId) {
    searchParams.set('session_id', sessionId);
  }

  if (isCompare) {
    searchParams.set('compare', 'true');
  }

  const qs = searchParams.toString();

  const { channel, eventSource } = (
    yield call(
      subscribeServerEvent,
      `${chatStreamingUrl}${qs && `?${qs}`}`,
      (isTest ? testStreaming : undefined) as unknown as { data: string }[],
    )
  ) as { channel: EventChannel<Event>, eventSource: EventSource };

  chatSessionIds.splice(0, chatSessionIds.length);
  currentEvents.splice(0, currentEvents.length);
  eventSources.splice(0, eventSources.length);
  poolingEventChannels.splice(0, poolingEventChannels.length);

  pushEventSource(eventSource);

  const poolingEventChannel = createIntervalChannel(10) as EventChannel<Event>;
  yield fork(
    poolingDispatchEvents,
    poolingEventChannel,
    input,
    llmChosen,
    mode,
    agent,
    settings.showPlan && !isExecutePlan,
    isExecutePlan,
  );

  poolingEventChannels.push(poolingEventChannel);

  try {
    while (true) {
      const item: Event = yield take(channel);
      currentEvents.push(item);
    }
  } finally {
    const isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      channel.close();
    }
  }
}

function* requestChatResponseFlow() {
  yield takeEvery(
    GptActions.CHAT_RESPONSE_REQUEST,
    function* _(act: PayloadAction<RequestChatResponseArgs>) {
      const {
        input,
        llm,
        mode,
        isCompare,
        isExecutePlan,
        isTest,
      } = act.payload;

      const chatSessionId: string = (
        yield select((appState: ApplicationState) => appState.gpt.chatSessionId)
      );

      yield subscribeChatEventSource(
        input,
        llm,
        mode,
        chatSessionId,
        isCompare,
        isExecutePlan,
        isTest,
      );
    },
  );
}

function* cancelChatRequestFlow() {
  yield takeEvery(
    GptActions.CHAT_REQUEST_CANCEL,
    () => {
      terminateLastEventSource();

      const abortController = abortControllers.pop();
      if (abortController) {
        abortController.abort();
      }
    },
  );
}

function* requestChatHistoryFlow() {
  yield takeEvery(
    GptActions.CHAT_HISTORY_REQUEST,
    function* _() {
      yield historyPerformanceUtils.forLast(null, 100);
      const user: User = (
        yield select((state: ApplicationState) => state.session.user)
      );

      yield fork(() => performApiGet<ChatHistoryResponse>(
        config.api.getGptChatHistory,
        {
          params: {
            user: String(user.pk),
            deleted: 'false',
          },
          onResult: function* __({ result, error }) {
            yield put(setChatHistory({
              items: result?.results,
              error,
            }));
          },
        },
      ));
    },
  );
}

function* requestChatHistoryDeleteFlow() {
  yield takeEvery(
    GptActions.CHAT_HISTORY_DELETE_REQUEST,
    function* _(act: PayloadAction<RequestDeleteChatHistoryArgs>) {
      yield fork(() => performApiDelete<unknown>(
        `${config.api.getGptChatHistory}/${act.payload.id}`,
        {
          onResult: function* __({ error }) {
            yield put(setChatHistoryDeleted({
              id: act.payload.id,
              conversationId: act.payload.conversationId,
              error,
            }));
          },
        },
      ));
    },
  );
}

function* requestChatPromptsFlow() {
  yield takeEvery(
    GptActions.CHAT_PROMPTS_REQUEST,
    function* _(act: PayloadAction<RequestChatPromptsArgs>) {
      yield fork(() => performApiGet<ChatPromptsResponse>(
        config.api.getGptChatPrompt,
        {
          params: {
            user: `${act.payload.user}`,
          },
          onResult: function* __({ result, error }) {
            yield put(setChatPrompts({
              promptResponse: result,
              error,
            }));
          },
        },
      ));
    },
  );
}

function* requestChatPromptCreateFlow() {
  yield takeEvery(
    GptActions.CHAT_PROMPT_CREATE_REQUEST,
    function* _(act: PayloadAction<RequestCreateChatPromptArgs>) {
      yield fork(() => performApiPost<ChatPromptItem>(
        config.api.getGptChatPrompt,
        {
          payload: act.payload.prompt as unknown as Record<string, unknown>,
          onResult: function* __({ result, error }) {
            yield put(setChatPromptCreated({
              prompt: result,
              error,
            }));

            const user: User = (
              yield select((state: ApplicationState) => state.session.user)
            );

            yield put(requestChatPrompts({
              user: user.pk,
            }));
          },
        },
      ));
    },
  );
}

function* requestChatPromptUpdateFlow() {
  yield takeEvery(
    GptActions.CHAT_PROMPT_UPDATE_REQUEST,
    function* _(act: PayloadAction<RequestUpdateChatPromptArgs>) {
      yield fork(() => performApiPut<ChatPromptItem>(
        `${config.api.getGptChatPrompt}${act.payload.prompt.id || ''}/`,
        {
          payload: act.payload.prompt as unknown as Record<string, unknown>,
          onResult: function* __({ result, error }) {
            yield put(setChatPromptUpdated({
              prompt: result,
              error,
            }));

            const user: User = (
              yield select((state: ApplicationState) => state.session.user)
            );

            yield put(requestChatPrompts({
              user: user.pk,
            }));
          },
        },
      ));
    },
  );
}

function* requestChatPromptDeleteFlow() {
  yield takeEvery(
    GptActions.CHAT_PROMPT_DELETE_REQUEST,
    function* _(act: PayloadAction<RequestDeleteChatPromptArgs>) {
      yield fork(() => performApiDelete<ChatPromptItem>(
        `${config.api.getGptChatPrompt}${act.payload.prompt.id || ''}/`,
        {
          onResult: function* __({ result, error }) {
            yield put(setChatPromptDeleted({
              prompt: result,
              error,
            }));

            const user: User = (
              yield select((state: ApplicationState) => state.session.user)
            );

            yield put(requestChatPrompts({
              user: user.pk,
            }));
          },
        },
      ));
    },
  );
}

function* requestSiteExamplesFlow() {
  yield takeEvery(
    GptActions.SITE_EXAMPLES_REQUEST,
    function* _() {
      yield examplePerformanceUtils.forLast(null, 100);
      yield fork(() => performApiGet<SiteExampleResponse>(
        config.api.getSiteExamples,
        {
          onResult: function* __({ result, error }) {
            yield put(setSiteExamples({
              siteExamplesResponse: result,
              error,
            }));
          },
        },
      ));
    },
  );
}

function* requestChatCleanFlow() {
  yield takeEvery(
    GptActions.CHAT_CLEAN_REQUEST,
    () => {
      terminateLastEventSource();
    },
  );
}

function* chatResponseSetFlow() {
  yield takeEvery(
    GptActions.CHAT_RESPONSE_SET,
    function* _(act: PayloadAction<SetChatResponseArgs>) {
      yield put(requestChatHistory());
      const chatSessionId: string = (
        yield select((state: ApplicationState) => (
          state.gpt.chatSessionId
        ))
      );
      if (act.payload.response?.output && !act.payload.response?.needPlan) {
        yield put(requestRelationData({
          conversationId: chatSessionId,
          text: act.payload.response?.output,
        }));
      }
    },
  );
}

function* taskExecutionFlow() {
  yield takeEvery(
    GptActions.TASKS_EXECUTE_START,
    function* _(act: PayloadAction<StartTasksExecuteArgs>) {
      const queries = act.payload.tasks.filter((task) => !!task?.value);
      const taskerInputItems: GptTaskerInputItem[] = [];
      const hasValueQueries = queries.filter((query) => !!query.value);

      for (let i = 0; i < hasValueQueries.length; i += 1) {
        const queryValue = hasValueQueries[i].value!;
        const isOnlyUrl = isValidUrl(queryValue);
        const query = isOnlyUrl
          ? `Crawl URL ${act.payload.objective || ''} ${queryValue}`
          : queryValue;

        yield put(requestChatResponse({
          input: query,
          mode: 'task',
        }));

        const a: PayloadAction<SetChatResponseArgs> = yield take(GptActions.CHAT_RESPONSE_SET);

        if (a.payload.response?.output) {
          taskerInputItems.push({
            task: queries[i].value!,
            answer: a.payload.response?.output,
            sources: extractIntermediateStepsSources(a.payload.response.intermediateSteps),
          });
        }

        if (a.payload.error) {
          return;
        }
      }

      yield put(setTasksExecuted());

      if (taskerInputItems.length > 0) {
        yield put(requestTaskerExecute({
          objective: act.payload.objective,
          tasks: taskerInputItems,
        }));
      }
    },
  );
}

function* requestTaskerExecuteFlow() {
  yield takeEvery(
    GptActions.TASKER_EXECUTE_REQUEST,
    function* _(act: PayloadAction<RequestTaskerExecuteArgs>) {
      yield fork(() => performApiPost<GetTaskerSummaryResult>(
        config.api.postTaskerSum,
        {
          payload: {
            objective: act.payload.objective,
            tasks: act.payload.tasks.map((task, index) => ({
              ...task,
              task: `${index + 1}. ${task.task}`,
              sources: JSON.stringify(
                task.sources?.map((source, srcIndex) => getApiSourceInfo(source, srcIndex + 1)),
              ),
            })),
          },
          onResult: function* __({ result, error }) {
            yield put(setTaskerExecuted({
              result,
              error,
            }));
          },
        },
      ));
    },
  );
}

function* requestTaskTemplatesFlow() {
  yield takeEvery(
    GptActions.TASK_TEMPLATES_REQUEST,
    function* _() {
      yield fork(() => performApiGet<TaskTemplate[]>(
        config.api.taskTemplates,
        {
          onResult: function* __({ result, error }) {
            yield put(setTaskTemplates({
              templates: result?.map((template) => ({
                ...template,
                id: v4(),
              })),
              error,
            }));
          },
        },
      ));
    },
  );
}

function* requestChatItemChartUpdateFlow() {
  yield takeEvery(
    GptActions.CHAT_ITEM_CHART_UPDATE_REQUEST,
    function* _(act: PayloadAction<RequestChatItemChartUpdateArgs>) {
      yield fork(() => performApiPost<QuickChartUpdateResponse>(
        config.api.postChartUpdate,
        {
          payload: {
            graph_id: act.payload.chartModifyInfo.graphId,
            graph_type: act.payload.chartModifyInfo.graphType,
            text: act.payload.chartModifyInfo.text,
            x_value: act.payload.chartModifyInfo.xValue,
            y_value: act.payload.chartModifyInfo.yValue,
            category: act.payload.chartModifyInfo.category,
          },
          onResult: function* __({ result, error }) {
            if (error || typeof result !== 'object') {
              yield put(triggerNotification({
                notification: {
                  key: v4(),
                  type: 'error',
                  payload: 'Cannot update chart, some error occurs.',
                  isDisplayed: false,
                  delay: 5000,
                },
              }));
            }

            yield put(setChatItemChartUpdated({
              chatItemIndex: act.payload.chatItemIndex,
              chartType: act.payload.chartType,
              chartIndex: act.payload.chartIndex,
              chartSubIndex: act.payload.chartSubIndex,
              chartUpdateResult: result,
              chartModifyInfo: act.payload.chartModifyInfo,
              error,
            }));
          },
        },
      ));
    },
  );
}

function* chatHistoryApplyFlow() {
  yield takeEvery(
    GptActions.CHAT_HISTORY_APPLY,
    function* _(act: PayloadAction<ApplyChatHistoryArgs>) {
      terminateLastEventSource();

      const chatItems = act.payload.items;
      const needPlan = chatItems.at(0)?.needPlan;
      const chatSessionId = chatItems.at(0)?.conversationId;
      const allIntermediateSteps = chatItems.flatMap((item) => {
        const chatAnswerJson = jsonrepair(item.outputText);
        const chatAnswer = JSON.parse(chatAnswerJson) as Record<string, unknown>;

        return extractIntermediateSteps(chatAnswer, item.agent);
      });

      if (chatSessionId) {
        yield determineRequestFollowUpQuestions(allIntermediateSteps, chatSessionId);
      }

      if (!needPlan) {
        const finalNetGraph = (
          act.payload.netGraph
          && typeof act.payload.netGraph === 'object'
          && Object.keys(act.payload.netGraph).length > 0
        )
          ? act.payload.netGraph
          : undefined;

        yield put(setRelationData({
          data: finalNetGraph,
        }));
      }
    },
  );
}

function* chatHistoryDeletedFlow() {
  yield takeEvery(
    GptActions.CHAT_HISTORY_DELETED_SET,
    function* _(act: PayloadAction<SetChatHistoryDeletedArgs>) {
      if (act.payload.error) {
        return;
      }

      yield put(requestChatHistory());
    },
  );
}

function* searchKeywordSetFlow() {
  yield takeEvery(
    SearchActions.SEARCH_KEYWORD_SET,
    function* _() {
      yield put(toggleGptChat({ isGptChat: false }));
    },
  );
}

function* requestTableToChartFlow() {
  yield takeEvery(
    GptActions.TABLE_TO_CHART_REQUEST,
    function* _(act: PayloadAction<RequestTableToChartArgs>) {
      const { isAttachment } = act.payload;

      const charts: (QuickChartInfo | undefined)[][] | undefined = (
        yield select((state: ApplicationState) => (
          isAttachment
            ? state.gpt.chats[act.payload.chatItemIndex]?.attachedCharts
            : state.gpt.chats[act.payload.chatItemIndex]?.inlineCharts
        ))
      );

      const existingCharts = charts?.at(act.payload.chartIndex);
      const existingChart = existingCharts?.at(0);
      const originalChartInfo = existingChart
        ? getAncestorOf(existingChart, 'prevChartInfo')
        : undefined;

      if (originalChartInfo) {
        yield put(duplicateChatItemChart({
          chartType: isAttachment ? 'attached' : 'inline',
          chatItemIndex: act.payload.chatItemIndex,
          chartIndex: act.payload.chartIndex,
          chartSubIndex: (existingCharts?.length || 0) + 1,
          chartInfo: {
            ...originalChartInfo,
            graphType: 'bar',
          },
        }));

        return;
      }

      yield fork(() => performApiPost<ChartResponse[]>(
        config.api.postTableToGraph,
        {
          payload: {
            table: act.payload.tableMarkdown,
          },
          onResult: function* __({ result, error }) {
            yield put(setTableToChart({
              chatItemIndex: act.payload.chatItemIndex,
              chartIndex: act.payload.chartIndex,
              chartItems: result,
              isAttachment,
              error,
            }));

            const resultWithCharts = (result || []).filter((r) => {
              if (!r) {
                return false;
              }

              const keys = Object.keys(r);
              const hasNoneResult = keys.some((key) => !(r as Record<string, unknown>)[key]);

              return !hasNoneResult;
            });

            if (error || !result || resultWithCharts.length === 0) {
              yield put(triggerNotification({
                notification: {
                  key: v4(),
                  type: 'val',
                  payload: 'Cannot create chart from the table',
                  isDisplayed: false,
                  delay: 5000,
                },
              }));
            }
          },
        },
      ));
    },
  );
}

function* requestGptModelsFlow() {
  yield takeEvery(
    GptActions.GPT_MODELS_REQUEST,
    function* _() {
      const permLevel: number = (
        yield select((state: ApplicationState) => (
          state.session.userExtraInfo?.results?.at(0)?.permLevel
        ))
      );

      yield fork(() => performApiGet<GptModelResponse>(
        config.api.getAiModel,
        {
          params: {
            active: 'true',
          },
          onResult: function* __({ result, error }) {
            yield put(setGptModels({
              permLevel,
              response: result,
              error,
            }));
          },
        },
      ));
    },
  );
}

function* requestFollowUpQuestionsFlow() {
  yield takeEvery(
    GptActions.FOLLOW_UP_QUESTIONS_REQUEST,
    function* _(act: PayloadAction<RequestFollowUpQuestionsArgs>) {
      yield fork(() => performApiPost<FollowUpInfo>(
        config.api.postFollowUpQuestions,
        {
          params: {
            session_id: act.payload.sessionId,
          },
          onResult: function* __({ result, error }) {
            const chatSessionId = (
              yield select((appState: ApplicationState) => appState.gpt.chatSessionId)
            ) as string;

            // Do not apply it if currently it is different converation.
            if (chatSessionId !== act.payload.sessionId) {
              yield put(setFollowUpQuestions({
                isStreaming: act.payload.isStreaming,
                result: undefined,
              }));

              return;
            }

            yield put(setFollowUpQuestions({
              isStreaming: act.payload.isStreaming,
              result,
              error,
            }));
          },
        },
      ));
    },
  );
}

function* applyCompareOutputFlow() {
  yield takeEvery(
    GptActions.CHAT_OUTPUTS_COMPARE_APPLY,
    function* _(act: PayloadAction<ApplyChatOutputsCompareArgs>) {
      const chats: GptChatItem[] = (
        yield select((appState: ApplicationState) => appState.gpt.chats)
      );

      const prevChatIndex = chats.length - 2;
      const prevChat = chats.at(prevChatIndex);
      const isPrevChatJson = prevChat?.content && isJsonString(prevChat.content);
      const prevChatStructure = prevChat?.content && isPrevChatJson && JSON.parse(prevChat.content);
      const isPrevChatPlanTask = prevChatStructure && ('tasks' in prevChatStructure);

      yield put(requestChatResponse({
        input: act.payload.input,
        llm: act.payload.llmModel,
        isCompare: true,
        isExecutePlan: isPrevChatPlanTask ? true : undefined,
        // isTest: true,
      }));
    },
  );
}

function* requestJinaContentFlow() {
  yield takeEvery(
    GptActions.JINA_CONTENT_REQUEST,
    function* _(act: PayloadAction<RequestJinaContentArgs>) {
      const finalUrl = `${config.externalUrl.jina}${act.payload.url}`;
      const defaultFormat = determineContentFormat(act.payload.url);
      const pickedFormat = act.payload.format || defaultFormat;

      const cache: Record<string, Record<string, string>> = (
        yield select((appState: ApplicationState) => appState.gpt.jinaContentCache)
      );

      const requestFormat = pickedFormat === 'html' ? 'html' : 'markdown';

      if (cache?.[act.payload.url]?.[requestFormat]) {
        yield put(setJinaContent({
          url: act.payload.url,
          result: cache[act.payload.url]?.[requestFormat],
          format: pickedFormat,
        }));

        return;
      }

      yield fork(() => performApiGet<string>(
        finalUrl,
        {
          onResult: function* __({ result, error }) {
            yield put(setJinaContent({
              url: act.payload.url,
              result,
              error,
              format: pickedFormat,
            }));
          },
        },
        {
          useFullUrlAsPath: true,
          authorization: null,
          headers: {
            'X-Return-Format': requestFormat,
          },
        },
      ));
    },
  );
}

function* requestLocalSourceFlow() {
  yield takeEvery(
    GptActions.LOCAL_SOURCE_REQUEST,
    function* _(act: PayloadAction<RequestLocalSourceArgs>) {
      yield fork(() => performApiGet<LocalSourceInfo>(
        config.api.getLocalSourcePdf,
        {
          params: {
            title: act.payload.title || '',
            source_id: act.payload.sourceId,
          },
          onResult: function* __({ result, error }) {
            yield put((setLocalSource({
              title: act.payload.title,
              result: {
                ...result,
                sourceId: act.payload.sourceId,
                title: act.payload.title || '',
                pageNumber: act.payload.pageNumber,
              },
              error,
            })));
          },
        },
      ));
    },
  );
}

function* sourceCitationToggleFlow() {
  yield takeEvery(
    GptActions.SOURCE_CITATION_TOGGLE,
    function* _(act: PayloadAction<ToggleSourceCitationArgs>) {
      if (act.payload.url) {
        yield put(requestJinaContent({
          url: act.payload.url,
        }));
      }

      if (act.payload.sourceId) {
        yield put(requestLocalSource({
          sourceId: act.payload.sourceId,
          title: act.payload.title,
          pageNumber: act.payload.pageNumber,
        }));
      }
    },
  );
}

export default function* gptSaga() {
  yield all([
    initializeFlow(),
    requestChatResponseFlow(),
    cancelChatRequestFlow(),
    requestChatHistoryFlow(),
    requestChatHistoryDeleteFlow(),
    requestChatPromptsFlow(),
    requestChatCleanFlow(),
    requestSiteExamplesFlow(),
    requestChatPromptCreateFlow(),
    requestChatPromptUpdateFlow(),
    requestChatPromptDeleteFlow(),
    requestTaskerExecuteFlow(),
    requestTaskTemplatesFlow(),
    requestChatItemChartUpdateFlow(),
    requestTableToChartFlow(),
    requestGptModelsFlow(),
    requestFollowUpQuestionsFlow(),
    requestJinaContentFlow(),
    requestLocalSourceFlow(),
    taskExecutionFlow(),
    chatResponseSetFlow(),
    chatHistoryApplyFlow(),
    chatHistoryDeletedFlow(),
    searchKeywordSetFlow(),
    applyCompareOutputFlow(),
    sourceCitationToggleFlow(),
  ]);
}
