import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import jsonpath from 'jsonpath';

import { ReactComponent as DefaultManIcon } from '../../../../assets/svg/defaultManIcon.svg';
import { useCommonClasses } from '../../../../theme/commonStyles';
import useCommonProps from '../../../../theme/commonProps';
import { getFirstError, hasErrorInfo } from '../manualFormUtils';
import { FounderInfo, ManualFormData } from '../types';

import { PropsFromRedux } from './container';
import styles from './TeamForm.styles';

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);

const TeamForm: React.FC<Props> = ({
  manualFormData,
  updateManualFormField,
  validationInfo,
  setHoveredDataInfoKey,
  setFocusedDataInfoKey,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const commonProps = useCommonProps();
  const [founderInfos, setFounderInfos] = useState<(FounderInfo | undefined)[]>(
    manualFormData?.founderInfos || [] as FounderInfo[]);

  const updateStore = (updatedFormData: (FounderInfo | undefined)[]) => {
    const manualForm = manualFormData || {} as ManualFormData;

    updateManualFormField({
      manualForm,
      path: 'founderInfos',
      value: updatedFormData,
    });
  };

  const updateFounderInfoField = (index: number, path?: keyof FounderInfo, value?: unknown) => {
    const founderInfo = founderInfos[index] || {} as FounderInfo;
    const updatedFounderInfo = { ...founderInfo };
    const updatedFounderInfos = [...founderInfos];

    if (path) {
      jsonpath.value(updatedFounderInfo, `$.${path}`, value);
    }

    updatedFounderInfos[index] = updatedFounderInfo;

    setFounderInfos(updatedFounderInfos);
    updateStore(updatedFounderInfos);
  };

  const togglePastInteraction = (index: number, field: string) => {
    const founderInfo = manualFormData?.founderInfos?.[index];
    const currentFieldValue = (founderInfo as Record<string, boolean>)[field];
    updateFounderInfoField(index, field as keyof FounderInfo, !currentFieldValue);
  };

  const isPastInteractionSelected = (field: string, founderInfo?: FounderInfo) => (
    !!((founderInfo || {}) as Record<string, boolean>)[field]
  );

  const shouldLineHighlight = (field: string, index: number) => {
    const filledFounders = (manualFormData?.founderInfos || [])
      .filter((founderInfo) => !!founderInfo);

    if (index === 0) {
      return filledFounders
        .every((founderInfo) => isPastInteractionSelected(field, founderInfo));
    }

    const hasPrevSiblingsSelected = filledFounders
      .slice(0, index)
      .some((founderInfo) => isPastInteractionSelected(field, founderInfo));

    const hasNextSiblingsSelected = filledFounders
      .slice(index)
      .some((founderInfo) => isPastInteractionSelected(field, founderInfo));

    return hasPrevSiblingsSelected && hasNextSiblingsSelected;
  };

  const pastInteractions = [{
    field: 'workAlumni',
    title: (
      <>
        Work
        <br />
        Alumni
      </>
    ),
  }, {
    field: 'schoolAlumni',
    title: (
      <>
        School
        <br />
        Alumni
      </>
    ),
  }];

  return (
    <div>
      <Grid container spacing={3}>
        {(founderInfos || []).filter((founder) => !!founder).map((founder, index) => (
          <Grid key={index} item xs={4}>
            <Typography variant="h5" textAlign="center" className={classes.founderTitle} color="inherit">
              {`Founder ${index + 1}`}
            </Typography>
            <Paper
              className={
                classNames(
                  classes.root,
                  'primary',
                  'light',
                  'dense',
                )
              }
              classes={commonCss.panels.leafPanel}
              elevation={0}
            >
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    {founder?.photoUrl ? (
                      <Box
                        className={
                          classNames(
                            classes.founderImage,
                            commonCss.boxes.imageBox,
                            'leaf',
                            'primary',
                          )
                        }
                        component="img"
                        src={founder?.photoUrl}
                      />
                    ) : (
                      <Box
                        className={
                          classNames(
                            classes.founderImage,
                            commonCss.boxes.imageBox,
                            'leaf',
                          )
                        }
                      >
                        <DefaultManIcon />
                      </Box>
                    )}
                  </Grid>
                  <Grid item container xs={7} spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.textField}
                        {...commonProps.textField({
                          label: 'First Name',
                          color: 'primary',
                          readOnly: true,
                        })}
                        fullWidth
                        size="small"
                        value={founder?.founderFirstName || ''}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.textField}
                        {...commonProps.textField({
                          label: 'Last Name',
                          color: 'primary',
                          readOnly: true,
                        })}
                        fullWidth
                        size="small"
                        value={founder?.founderLastName || ''}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      className={classes.textField}
                      {...commonProps.textField({
                        label: 'Title',
                        color: 'primary',
                        readOnly: true,
                      })}
                      fullWidth
                      size="small"
                      value={founder?.title || ''}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>
            {pastInteractions.map((pi) => (
              <Box key={pi.field} className={classes.interactionBox}>
                {index === 0 && (
                  <Typography
                    variant="h5"
                    className={classes.interactionTitle}
                    color="inherit"
                  >
                    {pi.title}
                  </Typography>
                )}
                <Divider
                  className={
                    classNames(
                      classes.interactionLine,
                      index > 0 && 'extended',
                      shouldLineHighlight(pi.field, index) && 'active',
                    )
                  }
                />
                <div className={classes.interactionDecorate} />
                <Box
                  position="absolute"
                  className={
                    classNames(
                      classes.interactionButton,
                      !isPastInteractionSelected(pi.field, founder) && 'unselected',
                      shouldLineHighlight(pi.field, index) && 'outlinedHighlight',
                    )
                  }
                >
                  <Button
                    variant="outlined"
                    classes={commonCss.buttons.roundButton}
                    className={
                      classNames(
                        'leaf',
                        'opaque',
                        'contrast',
                      )
                    }
                    fullWidth
                    onClick={() => togglePastInteraction(index, pi.field)}
                    onMouseEnter={() => setHoveredDataInfoKey({ dataInfoKey: pi.field })}
                    onMouseLeave={() => setHoveredDataInfoKey({ dataInfoKey: undefined })}
                    onFocus={() => setFocusedDataInfoKey({ dataInfoKey: pi.field })}
                    onBlur={() => setFocusedDataInfoKey({ dataInfoKey: undefined })}
                  >
                    {isPastInteractionSelected(pi.field, founder) ? (
                      'Yes'
                    ) : (
                      'No'
                    )}
                  </Button>
                </Box>
              </Box>
            ))}
          </Grid>
        ))}
      </Grid>
      <Box marginTop={5}>
        {hasErrorInfo(validationInfo?.interactions?.workAlumni) && (
          <Typography variant="body2" color="error" fontWeight={400}>
            {getFirstError(validationInfo?.interactions?.workAlumni)}
          </Typography>
        )}
        {hasErrorInfo(validationInfo?.interactions?.schoolAlumni) && (
          <Typography variant="body2" color="error" fontWeight={400}>
            {getFirstError(validationInfo?.interactions?.schoolAlumni)}
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default TeamForm;
