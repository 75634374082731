import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles, { getContrastColorModifierClasses } from '../../theme/commonStyles';
import { otherColorSets } from '../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 300,
      paddingTop: theme.spacing(1.25),
    },
    logoWithDescBox: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    description: {
      height: 100,
      overflow: 'hidden',
      '& > *': {
        textOverflow: 'ellipsis',
      },
    },
    tagsBox: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      alignContent: 'start',
      height: theme.spacing(9),
      overflow: 'hidden',
    },
    linksBox: {
      display: 'flex',
      marginLeft: theme.spacing(-0.75),
      marginTop: theme.spacing(0.5),
      marginBottom: `-${theme.spacing(1)}`,
      '& > *': {
        marginLeft: `${theme.spacing(1)} !important`,
      },
      '& > *:first-child': {
        marginLeft: '0 !important',
      },
      '& > *:last-child': {
        marginLeft: 'auto !important',
        marginRight: `${theme.spacing(4)} !important`,
      },
      '&.competitor > *:last-child': {
        marginLeft: 'auto !important',
        marginRight: `${theme.spacing(0)} !important`,
      },
    },
    fieldDetails: {
      marginTop: `${theme.spacing(0.5)} !important`,
      marginBottom: theme.spacing(1),
      '& p:not(.label)': {
        color: theme.palette.grey[900],
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      whiteSpace: 'nowrap',
    },
    scoreBox: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      color: `${theme.palette.common.white} !important`,
      zIndex: 1,
      width: 78,
      height: 32,
      fontSize: '1.4rem',
      lineHeight: 1.63,
      fontWeight: 500,
      ...getContrastColorModifierClasses('background'),
    },
    fundedIndicator: {
      marginLeft: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    competitorHighlight: {
      borderColor: `${otherColorSets[4]} !important`,
      fontWeight: 500,
    },
  });
};

export default styles;
