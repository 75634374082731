import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import { getCustomScrollBarCss } from '../../../../core/utils/uxUtils';
import getCommonStyles from '../../../../theme/commonStyles';
import { otherLightColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(1),
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
    virtualizedContainer: {
      ...getCustomScrollBarCss(),
    },
    tabContentItem: {
      borderRadius: '0 !important',
      boxShadow: 'none !important',
      padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
      border: 'none !important',
      marginBottom: theme.spacing(1),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: otherLightColorSets[2],
      },
      display: 'flex',
      alignItems: 'center',
      '& header': {
        fontSize: '0.9rem !important',
        color: theme.palette.grey[900],
        fontWeight: 'normal',
        marginBottom: theme.spacing(0.25),
      },
    },
    tabContentItemDivider: {
      borderColor: `${theme.palette.grey[50]} !important`,
    },
    tabContentItemText: {
      flex: 1,
      boxSizing: 'border-box',
      lineHeight: 1.33,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      fontSize: '0.9rem !important',
      fontWeight: '300 !important',
      color: `${theme.palette.grey[900]} !important`,
      letterSpacing: '0.06px !important',
    },
    tabContentControlBox: {
      flex: 0,
      flexBasis: theme.spacing(4),
      marginRight: theme.spacing(-2),
      '& svg': {
        color: theme.palette.grey[50],
      },
      '& button:hover svg': {
        color: 'inherit',
      },
    },
  });
};

export default styles;
