import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      width: '100%',
      height: '100%',
    },
    chartContainer: {
      flex: 1,
      position: 'relative',
      paddingTop: theme.spacing(9),
      cursor: 'pointer',
      height: 192,
    },
    scorePinContainer: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    scorePinLine: {
      position: 'absolute',
      bottom: theme.spacing(-1),
      transform: 'translate(-1px, 0px)',
      transition: 'transform 1s ease-out',
      width: 1,
      height: '85%',
      backgroundSize: '2px 12px',
      backgroundImage: `linear-gradient(${theme.palette.secondary.main} 50%, rgba(255,255,255,0) 0%)`,
    },
    scoreBannerContainer: {
      position: 'absolute',
      top: `-${theme.spacing(3)}`,
      left: `-${theme.spacing(16)}`,
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'flex-end',
    },
    scorePinBanner: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      fontSize: '2.857rem',
      color: theme.palette.common.white,
      height: theme.spacing(7.75),
      '& > span > span:not(:first-child)': {
        fontSize: '2.142rem !important',
      },
      boxShadow: '0 6px 9px 0 rgba(0, 0, 0, 0.16)',
    },
    scorePinSubBanner: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginRight: theme.spacing(1),
      fontSize: '1.142rem !important',
      borderRadius: '10px 10px 0 10px !important',
    },
  });
};

export default styles;
