import { Edge, Node, NodeStyle } from '@sayari/trellis';
import { HierarchyNode, TreeLayout as HierarchyTreeLayout } from 'd3-hierarchy';

export const layoutTypes = [
  'network',
  'hierarchy',
  'radial',
  'clusters',
] as const;

export type LayoutType = typeof layoutTypes[number];

export type CompareFn<N extends Node, E extends Edge> = (
  (a: HierarchyNode<HierarchyData<N, E>>, b: HierarchyNode<HierarchyData<N, E>>) => number
);

export type HierarchyAnchor = 'bottom' | 'left' | 'top' | 'right';
export type HierarchyAlignment = 'min' | 'mid' | 'max';

export type Options<N extends Node, E extends Edge> = Partial<{
  x: number;
  y: number;
  bfs: boolean;
  anchor: HierarchyAnchor;
  alignment: HierarchyAlignment;
  size: [number, number];
  nodeSize: [number, number];
  separation: CompareFn<N, E>;
  sort: CompareFn<N, E> | CompareFn<N, E>[];
  radius?: number;
}>;

export type TreePath<N extends Node, E extends Edge> = {
  edge: E;
  node: N;
};

export type GraphData<N extends Node, E extends Edge> = {
  edges: E[];
  nodes: N[];
  options?: Options<N, E>;
};

export type GraphLookup<N extends Node, E extends Edge> = (
  Record<string, { node: N; paths: { edge: E; node: N }[] }>
);

export type HierarchyData<N extends Node, E extends Edge> = (
  ({ root: true; node: N; edge: null } | { root: false; node: N; edge: E }) & {
    children: HierarchyData<N, E>[]
  }
);

export interface TreeLayout<Datum> extends HierarchyTreeLayout<Datum> {
  alignment: (alignment: 'min' | 'max' | 'mid') => this
}

export interface ChartLegendInfo {
  color: string;
  colorSet: string;
  label: string;
  labelPlural: string;
  subcategories?: string[];
  style: NodeStyle;
}

export interface ChartDataInfo {
  nodes: Node[];
  edges: Edge[];
  x: number;
  y: number;
  zoom: number;
}

export type ClusterLayoutType = 'radial' | 'hierarchy' | 'collide';

export interface NetworkChartConfig {
  layoutType: LayoutType;
  isLayoutLocked: boolean;
  isViewportLocked: boolean;
  layoutOptions: {
    network: {
      gravity: number;
      linkDistance: number;
    };
    hierarchy: {
      stack: boolean;
      anchor: HierarchyAnchor;
      alignment: HierarchyAlignment;
      widthDistance: number;
      heightDistance: number;
    };
    radial: {
      stack: boolean;
      radius: number;
      linkDistance: number;
      nodeSeparation: number;
    };
    clusters: {
      layout: 'radial' | 'hierarchy' | 'collide';
      clusterPadding: number;
      collidePadding: number;
    };
  };
  stylingOptions: {
    useSameRadius: boolean;
    hideBadge: boolean;
    hideLabel: boolean;
    hideIcon: boolean;
  };
}
