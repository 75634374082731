import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';
import {
  updateInvestingParams,
  toggleDataInfoKeySelection,
  requestMarketData,
  addMarketItem,
  removeMarketItem,
  setMarketAggregate,
  setMarketHighlight,
  selectMarketCountry,
} from '../actions';

import OnePagerDetailsPanel from './OnePagerDetailsPanel';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.router,
    ...state.report,
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    updateInvestingParams,
    toggleDataInfoKeySelection,
    requestMarketData,
    addMarketItem,
    removeMarketItem,
    setMarketAggregate,
    setMarketHighlight,
    selectMarketCountry,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OnePagerDetailsPanel);
