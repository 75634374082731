import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';

const styles = (theme: Theme): StyleRules<{}, string> => createStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
    zIndex: 1,
    marginBottom: -2,
  },
  successItem: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    margin: `-${theme.spacing(0.5)} 0`,
    padding: `${theme.spacing(1)} 0`,
    '& > *:first-child': {
      width: '60%',
      minWidth: theme.spacing(3),
      overflow: 'hidden',
      marginRight: theme.spacing(0.75),
      textOverflow: 'ellipsis',
      fontSize: '1rem',
      textAlign: 'right',
      whiteSpace: 'nowrap',
      lineHeight: 1.2,
      fontWeight: 'normal',
    },
    '& > *:last-child': {
      color: `${theme.palette.grey[900]} !important`,
      fontSize: '1.3574rem',
    },
    cursor: 'pointer',
  },
  divider: {
    borderColor: `${Color(theme.palette.common.black).alpha(0.25).toString()} !important`,
    zIndex: 1,
  },
  dividerMarginBox: {
    height: 'max(calc((100vh - 860px) / 8), 8.5px) !important',
    '&.opaque': {
      background: theme.palette.common.white,
    },
  },
  reportGroupRow: {
    margin: `-${theme.spacing(0.5)} 0`,
    padding: `${theme.spacing(0.5)} 0`,
    background: theme.palette.common.white,
  },
});

export default styles;
