import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    recentContainer: {
      display: 'flex !important',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '768px !important',
      marginTop: theme.spacing(7),
    },
    recentTitle: {
      fontSize: '1.0714rem !important',
      fontWeight: 'normal !important',
      color: `${theme.palette.grey[300]} !important`,
    },
    recentItemsContainer: {
      display: 'flex',
      marginTop: theme.spacing(2),
      columnGap: theme.spacing(3),
    },
    recentItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      '& .name': {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '&:hover': {
        '& .logo': {
          borderColor: theme.palette.secondary.main,
        },
        '& .name': {
          color: `${theme.palette.secondary.main} !important`,
          textDecoration: 'underline',
        },
      },
    },
    linkButtons: {
      display: 'flex',
      alignItems: 'center',
      color: `${theme.palette.grey[300]} !important`,
      textDecorationColor: `${theme.palette.grey[300]} !important`,
      fontSize: '1.1rem',
      fontWeight: 300,
      fontFamily: 'Roboto',
    },
  });
};

export default styles;
