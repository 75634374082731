import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../types';
import {
  closeEntityBubble,
  toggleEntityBubbleShowMore,
  updateInvisibleEntityIds,
} from './actions';

import RelationChart from './RelationChart';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.relationChart,
    networkStates: {
      ...state.relationChart.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    closeEntityBubble,
    toggleEntityBubbleShowMore,
    updateInvisibleEntityIds,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RelationChart);
