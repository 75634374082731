import React from 'react';
import { makeStyles } from '@mui/styles';

import styles from './TypingIndicator.styles';

const useStyles = makeStyles(styles);

const TypingIndicator: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <div className={classes.dot} />
        <div className={classes.dot} />
        <div className={classes.dot} />
      </div>
    </div>
  );
};

export default TypingIndicator;
