import { ICountry } from 'country-state-city';
import NetworkState from '../../../core/types/network';
import { AutocompleteItem } from '../autocomplete/types';

export enum SearchActions {
  SEARCH_KEYWORD_SET = '@@search/SEARCH_KEYWORD_SET',
  SEARCH_REQUEST_ABORT = '@@search/SEARCH_REQUEST_ABORT',
  COMPANY_ITEMS_REQUEST = '@@search/COMPANY_ITEMS_REQUEST',
  COMPANY_ITEMS_SET = '@@search/COMPANY_ITEMS_SET',
  FILTER_EDIT_OPEN = '@@search/FILTER_EDIT_OPEN',
  FILTER_EDIT_CLOSE = '@@search/FILTER_EDIT_CLOSE',
  COUNTRIES_FILTER_UPDATE = '@@search/COUNTRIES_FILTER_UPDATE',
  INDUSTRIES_FILTER_UPDATE = '@@search/INDUSTRIES_FILTER_UPDATE',
  VERTICALS_FILTER_UPDATE = '@@search/VERTICALS_FILTER_UPDATE',
  MIN_FOUNDING_YEARS_FILTER_UPDATE = '@@search/MIN_FOUNDING_YEARS_FILTER_UPDATE',
  FILTERS_CLEAR = '@@search/FILTERS_CLEAR',
  PAGE_SET = '@@search/PAGE_SET',
  HISTORY_ITEMS_REQUEST = '@@search/HISTORY_ITEMS_REQUEST',
  HISTORY_ITEMS_SET = '@@search/HISTORY_ITEMS_SET',
  MARKET_INQUIRY_ITEMS_REQUEST = '@@search/MARKET_INQUIRY_ITEMS_REQUEST',
  MARKET_INQUIRY_ITEMS_SET = '@@search/MARKET_INQUIRY_ITEMS_SET',
  GLOSSARY_ITEMS_REQUEST = '@@search/GLOSSARY_ITEMS_REQUEST',
  GLOSSARY_ITEMS_SET = '@@search/GLOSSARY_ITEMS_SET',
  GPT_SEARCH_REQUEST = '@@search/GPT_SEARCH_REQUEST',
  GPT_SEARCH_SET = '@@search/GPT_SEARCH_SET',
  GPT_SEARCH_TOGGLE = '@@search/GPT_SEARCH_TOGGLE',
  GPT_CHAT_TOGGLE = '@@search/GPT_CHAT_TOGGLE',
}

export interface CompanyItemsGroup {
  founded?: CompanyItem[];
  popular?: CompanyItem[];
  score?: CompanyItem[];
  size?: CompanyItem[];
}

export interface CompanyItem {
  id: string;
  name: string;
  logo?: string[];
  description?: string;
  keywords?: string;
  city?: string;
  country?: string;
  countryIso?: string;
  industry?: string;
  vertical?: string;
  foundingYear?: number;
  domain?: string[];
  website?: string[];
  report?: boolean;
  linkedin?: string[];
  facebook?: string[];
  twitter?: string[];
  crunchbase?: string[];
  historyReport?: boolean;
  score?: number;
}

export interface MarketInquiryItem {
  idMarket: number,
  score: number,
  answer: string,
  chartTitle: string;
  chartData: Record<string, number>;
  highlights: string[];
  link: string;
  stat: string;
}

export interface GlossaryItem {
  term: string;
  abbreviation: string;
  field: string;
  definition: string;
  example: string;
  highlights: string[];
}

export interface GptSearchResult {
  queryDetails?: GptQueryDetails[];
  companies?: CompanyItem[];
  markets?: MarketInquiryItem[];
  exactWords?: GlossaryItem[];
  similiarWords?: GlossaryItem[];
  wordCloud?: string;
}

export interface GptQueryDetails {
  prompt: string;
  answer: string;
}

export interface Industry extends AutocompleteItem {}
export interface Vertical extends AutocompleteItem {}

export interface SearchValidationInfo {
  isSearchable: boolean;
  message?: string;
}

export interface SetSearchKeywordArgs {
  keyword: string;
}

export interface RequestCompanyItemsArgs {
  keyword?: string;
  minKeyword?: number;
  shouldAddCompanyUrl?: boolean;
  isAdditionalSearch?: boolean;
}

export interface SetCompanyItemsArgs {
  items?: CompanyItemsGroup | CompanyItem[];
  error?: Error;
  isAdditionalResults?: boolean;
}

export interface SetHistoryItemsArgs {
  items?: CompanyItem[];
  error?: Error;
}

export interface RequestMarketInquiryItemsArgs {
  question?: string;
  minKeyword?: number;
}

export interface SetMarketInquiryItemsArgs {
  items?: MarketInquiryItem[];
  error?: Error;
}

export interface RequestGlossaryItemsArgs {
  phrase?: string;
}

export interface SetGlossaryItemsArgs {
  items?: GlossaryItem[];
  error?: Error;
}

export interface UpdateCountriesFilterArgs {
  countries?: ICountry[];
}

export interface UpdateIndustriesFilterArgs {
  industries?: Industry[];
}

export interface UpdateVerticalsFilterArgs {
  verticals?: Vertical[];
}

export interface UpdateMinFoundingYearsFilterArgs {
  minFoundingYears?: number;
}

export interface SetPageArgs {
  page: number;
}

export interface ToggleGptSearchArgs {
  isGptSearch: boolean;
}

export interface RequestGptSearchArgs {
  question: string;
}

export interface SetGptSearchArgs {
  result?: GptSearchResult[];
  error?: Error;
}

export interface ToggleGptChatArgs {
  isGptChat: boolean;
}

export interface SearchState {
  networkStates: {
    historyItemsRequest: NetworkState;
    companySearchRequest: NetworkState;
    marketInquiryRequest: NetworkState;
    glossaryItemsRequest: NetworkState;
    gptSearchRequest: NetworkState;
  };
  validationInfo?: SearchValidationInfo;
  searchId?: string;
  companyResultItems?: CompanyItem[];
  companyResultGroup?: CompanyItemsGroup;
  companyResultAdditionals?: CompanyItem[] | CompanyItemsGroup;
  historyItems?: CompanyItem[];
  marketInquiryItems?: MarketInquiryItem[];
  glossaryItems?: GlossaryItem[];
  isGptSearch: boolean;
  isGptChat: boolean;
  gptSearchResults?: GptSearchResult[] | string;
  filter: {
    isOpen: boolean;
    countries?: ICountry[];
    industries?: Industry[];
    verticals?: Vertical[];
    minFoundingYears?: number;
  };
  keyword: string;
  minKeyword: number;
}
