import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';

import {
  defaultTextColor,
  otherContrastColorSets,
} from '../../../../theme/palette';

import getCommonStyles from '../../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      ...commonStyles.smallRoundedBorder,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: `${theme.spacing(2)} ${theme.spacing(3)} !important`,
      borderRadius: `${theme.spacing(1.5)} !important`,
      transition: 'all ease-in-out 0.3s',
      cursor: 'default',
      borderWidth: '1px !important',
      borderColor: `${otherContrastColorSets[3]} !important`,
      background: `${theme.palette.common.white} !important`,
      boxShadow: `${theme.shadows[3]} !important`,
      textAlign: 'left',
      backgroundColor: `${theme.palette.background.paper} !important`,
      position: 'relative',
      overflow: 'hidden',
      '&.active $definition': {
        maxHeight: 150,
      },
      '&.active $example': {
        maxHeight: 100,
      },
    },
    title: {
      color: `${theme.palette.grey[900]} !important`,
      fontWeight: 'normal !important',
      lineHeight: 1.125,
      width: '75%',
    },
    abbrev: {
      fontSize: '1.4285rem !important',
      fontWeight: 300,
      color: `${theme.palette.grey[300]} !important`,
      marginLeft: theme.spacing(1.5),
    },
    field: {
      color: `${theme.palette.grey[400]} !important`,
      fontWeight: '300 !important',
      fontStyle: 'italic',
      textAlign: 'right',
      paddingRight: theme.spacing(3),
    },
    definition: {
      lineHeight: 1.40,
      fontSize: '1.142rem',
      fontWeight: '300 !important',
      color: defaultTextColor,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      maxHeight: 40,
      transition: 'all ease-in-out 0.3s',
    },
    example: {
      lineHeight: 1.40,
      fontSize: '1.142rem',
      fontWeight: '300 !important',
      color: defaultTextColor,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      maxHeight: 0,
      transition: 'all ease-in-out 0.3s',
    },
    highlight: {
      color: `${otherContrastColorSets[3]} !important`,
      textDecorationColor: `${otherContrastColorSets[3]} !important`,
      cursor: 'pointer',
    },
  });
};

export default styles;
