import React, { useRef } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import useMeasure from 'react-use-measure';
import { AnimatePresence, motion } from 'framer-motion';

import styles from './CalloutBubble.styles';

interface Props {
  colorSet?: string;
  children?: React.ReactNode;
  originX?: number;
  originY?: number;
  arrowHeight?: number;
  open?: boolean;
  onClose?: () => void;
}

const ARROW_PADDING_LEFT = 40;
const ARROW_VERTICAL_GAP = 4;
const useStyles = makeStyles(styles);

const CalloutBubble: React.FC<Props> = ({
  colorSet,
  children,
  originX,
  originY,
  arrowHeight = 21,
  open,
  onClose,
}: Props) => {
  const classes = useStyles();
  const [bubbleRef, bubbleBounds] = useMeasure();
  const closeTimeoutRef = useRef<NodeJS.Timeout>();
  const bubbleBodyRef = useRef<HTMLDivElement>(null);

  const alignTopDim = (bubbleBounds.height + arrowHeight + ARROW_VERTICAL_GAP);
  const alignBottomDim = (20 + arrowHeight + ARROW_VERTICAL_GAP);
  const flipVertical = (originY || 0) - alignTopDim < 0;

  const resetCloseTimeout = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
      closeTimeoutRef.current = undefined;
    }
  };

  const determineCloseBubble = (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event && event.currentTarget && bubbleBodyRef.current) {
      const bounds = bubbleBodyRef.current.getBoundingClientRect();
      if (event.clientX >= bounds.left
        && event.clientX <= bounds.right
        && event.clientY >= bounds.top
        && event.clientY <= bounds.bottom
      ) {
        resetCloseTimeout();
        return;
      }
    }

    if (closeTimeoutRef.current) {
      return;
    }

    closeTimeoutRef.current = setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, 500);
  };

  return (
    <div
      className={
        classNames(
          classes.root,
          open && 'active',
        )
      }
      onMouseMove={(event) => determineCloseBubble(event)}
    >
      {open && (
        <div
          className={classes.backdrop}
          onClick={() => onClose && onClose()}
          onKeyDown={(event) => {
            if (event.key === 'Escape' && onClose) {
              onClose();
            }
          }}
        />
      )}
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{
              opacity: 0,
              y: 30,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{
              opacity: 0,
              y: 30,
            }}
            className={
              classNames(
                classes.bubble,
                colorSet,
                flipVertical && 'flipVertical',
              )
            }
            style={{
              top: (originY || 0) + (!flipVertical ? -alignTopDim : alignBottomDim),
              left: (originX || 0) - ARROW_PADDING_LEFT,
              opacity: (
                bubbleBounds.width
                && bubbleBounds.height
              ) ? 1 : 0,
            }}
            onAnimationComplete={() => resetCloseTimeout()}
            onMouseEnter={() => resetCloseTimeout()}
            onMouseLeave={() => determineCloseBubble()}
            ref={bubbleRef}
          >
            <div className={classes.body} ref={bubbleBodyRef}>
              {children}
            </div>
            <div className={classes.arrow} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CalloutBubble;
