import React, { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import CountUp from 'react-countup';

import { easeOut } from '../../../../core/utils/animationUtils';
import FeatureBarChart from '../../../../components/featureBarChart/FeatureBarChart';
import { InvestorReportInfo } from '../types';
import {
  getMaxScorePercent,
  normalizePercentage,
} from '../reportUtils';
import styles from './SuccessScoreReport.styles';
import { useCommonClasses } from '../../../../theme/commonStyles';
import { INTERNAL_SPACING } from '../Reports.styles';

type Props = {
  investorReportInfo?: InvestorReportInfo;
  currentDataInfoKey?: string;
  selectedDataInfoKeys?: string[];
  onDataMouseEnter?: (dataInfoKey: string) => void;
  onDataMouseLeave?: () => void;
  onDataMouseClick?: (dataInfoKey: string) => void;
};

const useStyles = makeStyles(styles);

const SuccessScoreReport: React.FC<Props> = ({
  investorReportInfo,
  currentDataInfoKey,
  onDataMouseEnter,
  onDataMouseLeave,
  onDataMouseClick,
  selectedDataInfoKeys,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();

  const { featureImportance, groupImportance } = investorReportInfo || {};
  const maxPercent = getMaxScorePercent(groupImportance);

  const renderText = (text?: number) => (
    <Typography variant="body1">
      <CountUp
        end={text || 0}
        duration={1}
        decimals={1}
        useEasing
        delay={0.5}
        easingFn={easeOut}
      />
      <small style={{ fontSize: '0.9rem' }}>%</small>
    </Typography>
  );

  const renderBarChart = (
    value?: number,
    colorClass?: string,
    shouldHighlight?: boolean,
  ) => {
    const normalizedValue = normalizePercentage(value || 0, maxPercent);
    return normalizedValue ? (
      <FeatureBarChart
        value={value}
        normalizedValue={normalizedValue}
        colorClass={colorClass}
        modifierClass={shouldHighlight ? 'contrast' : undefined}
      />
    ) : (
      <div />
    );
  };

  const fieldSets = [{
    key: 'team',
    colorClass: 'primary',
    parentDataInfoKey: 'i7Team',
    fields: [{
      title: 'Team\nSize',
      dataInfoKey: 'i11Teamsize',
      render: () => (
        renderText(featureImportance?.teamSize)
      ),
    }, {
      title: 'Work\nAlumni',
      dataInfoKey: 'i13Workalumni',
      render: () => (
        renderText(featureImportance?.jobAlumni)
      ),
    }, {
      title: 'School\nAlumni',
      dataInfoKey: 'i14Schoolalumni',
      render: () => (
        renderText(featureImportance?.schoolAlumni)
      ),
    // }, {
    //   title: 'Class\nAlumni',
    //   dataInfoKey: 'i15Classalumni',
    //   render: () => (
    //     renderText(featureImportance?.classAlumni)
    //   ),
    }],
    renderChart: (shouldHighlight?: boolean) => (
      renderBarChart(groupImportance?.team, 'primary', shouldHighlight)
    ),
  }, {
    key: 'experience',
    colorClass: 'tertiary',
    parentDataInfoKey: 'i8Experience',
    fields: [{
      title: 'Working\nExperience',
      dataInfoKey: 'i16WorkingExperience',
      render: () => (
        renderText(featureImportance?.workingExperience)
      ),
    }, {
      title: 'Prior\nJobs',
      dataInfoKey: 'i17PriorJobs',
      render: () => (
        renderText(featureImportance?.priorJobs)
      ),
    }, {
      title: 'Orgs\nFounded',
      dataInfoKey: 'i18CompaniesFounded',
      render: () => (
        renderText(featureImportance?.orgsFounded)
      ),
    }, {
      title: 'Was\nCEO',
      dataInfoKey: 'i19WasCeo',
      render: () => (
        renderText(featureImportance?.wasCeo)
      ),
    }, {
      title: 'Worked\nFAAMG',
      dataInfoKey: 'i20WorkedFaamg',
      render: () => (
        renderText(featureImportance?.workedAtFaamg)
      ),
    }],
    renderChart: (shouldHighlight?: boolean) => (
      renderBarChart(groupImportance?.experience, 'tertiary', shouldHighlight)
    ),
  }, {
    key: 'education',
    colorClass: 'quaternary',
    parentDataInfoKey: 'i9Education',
    fields: [{
      title: 'No. of\nDegrees',
      dataInfoKey: 'i21Degrees',
      render: () => (
        renderText(featureImportance?.degreeCount)
      ),
    }, {
      title: 'Science\nDegree',
      dataInfoKey: 'i22ScienceDegree',
      render: () => (
        renderText(featureImportance?.scienceDegree)
      ),
    }, {
      title: 'Top\nUniversity',
      dataInfoKey: 'i23TopUniversity',
      render: () => (
        renderText(featureImportance?.topUniversity)
      ),
    }, {
      title: 'Has\nMBA',
      dataInfoKey: 'i24Mba',
      render: () => (
        renderText(featureImportance?.mba)
      ),
    }, {
      title: 'Has\nPhD',
      dataInfoKey: 'i25Phd',
      render: () => (
        renderText(featureImportance?.phD)
      ),
    }],
    renderChart: (shouldHighlight?: boolean) => (
      renderBarChart(groupImportance?.education, 'quaternary', shouldHighlight)
    ),
  }];

  const handleMouseEnter = (dataInfoKey: string) => {
    if (onDataMouseEnter) {
      onDataMouseEnter(dataInfoKey);
    }
  };

  const handleMouseLeave = () => {
    if (onDataMouseLeave) {
      onDataMouseLeave();
    }
  };

  const handleMouseClick = (dataInfoKey: string) => {
    if (onDataMouseClick) {
      onDataMouseClick(dataInfoKey);
    }
  };

  return (
    <div className={classes.root}>
      {fieldSets.map((fieldSet, index) => (
        <React.Fragment key={fieldSet.key}>
          <div
            className={
              classNames(
                classes.dividerMarginBox,
                index > 0 && 'opaque',
              )
            }
          />
          <Divider className={classes.divider} />
          <div
            className={
              classNames(
                classes.dividerMarginBox,
                'opaque',
              )
            }
          />
          <Box
            className={
              classNames(
                classes.reportGroupRow,
                fieldSet.colorClass,
                commonCss.reports.row,
                currentDataInfoKey === fieldSet.parentDataInfoKey && 'active',
                selectedDataInfoKeys?.includes(fieldSet.parentDataInfoKey) && 'active',
              )
            }
          >
            <Grid
              container
              columnSpacing={{ xl: INTERNAL_SPACING / 8, xs: 5 }}
            >
              <Grid item xs={12} md={6}>
                <Grid container columnSpacing={0.5} columns={{ xs: 5 }} style={{ width: 'calc(100% + 35px)' }}>
                  {fieldSet.fields.map((field) => (
                    <Grid
                      key={field.title}
                      item
                      xs={1}
                    >
                      <Box
                        onClick={() => handleMouseClick(field.dataInfoKey)}
                        onMouseEnter={() => handleMouseEnter(field.dataInfoKey)}
                        onMouseLeave={() => handleMouseLeave()}
                        className={
                          classNames(
                            classes.successItem,
                            commonCss.charts.dataTitle,
                            commonCss.reports.row,
                            commonCss.reports.rowHover,
                            fieldSet.colorClass,
                            selectedDataInfoKeys?.includes(field.dataInfoKey) && 'active',
                          )
                        }
                      >
                        <Typography variant="body2" color="inherit">
                          {field.title.split('\n')
                            .map((s, i) => [
                              <span key={s}>{s}</span>,
                              <br key={i} />,
                            ] as ReactNode[])
                            .flatMap((s) => s)
                          }
                        </Typography>
                        {field.render()}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item container xs={12} md={6} flexDirection="column" justifyContent="center">
                <Box>
                  {fieldSet.renderChart(
                    currentDataInfoKey === fieldSet.parentDataInfoKey,
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
      ))}
    </div>
  );
};

export default SuccessScoreReport;
