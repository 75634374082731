import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';

const styles = (theme: Theme): StyleRules<{}, string> => createStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  profileItem: {
    position: 'relative',
    zIndex: 1,
  },
  divider: {
    marginTop: 'max(calc((100vh - 840px) / 8), 8px) !important',
    marginBottom: 'max(calc((100vh - 840px) / 8), 8px) !important',
    borderColor: `${Color(theme.palette.common.black).alpha(0.25).toString()} !important`,
    zIndex: 1,
  },
  reportGroupRow: {
    margin: `-${theme.spacing(1)} 0`,
    padding: `${theme.spacing(0.75)} 0`,
  },
  reportRow: {
    padding: 0,
    margin: `${theme.spacing(0.5)} 0`,
    cursor: 'pointer',
  },
  rowTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'normal !important',
  },
  checkedIconDecorate: {
    position: 'relative',
    display: 'flex',
    margin: 'auto',
    padding: 2,
    '& > svg': {
      width: 16,
      height: 16,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 2,
      right: 2,
      top: 2,
      bottom: 2,
      borderRadius: '50%',
      border: '0.5px solid #fff',
    },
  },
  uncheckedIconDecorate: {
    borderRadius: '50%',
    border: `0.5px solid ${theme.palette.grey[100]}`,
    width: 16,
    height: 16,
  },
  valueLabel: {
    color: `${theme.palette.grey[900]} !important`,
  },
});

export default styles;
