import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Link, Typography } from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';
import classNames from 'classnames';

import styles from './ContentView.styles';

interface Props {
  content?: string;
  className?: string;
}

const useStyles = makeStyles(styles);

const ContentView: React.FC<Props> = ({
  content,
  className,
}: Props) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const { height, ref: boxRef } = useResizeDetector();
  const [hasShowMore, setHasShowMore] = useState<boolean | undefined>();
  const [isShowMore, setShowMore] = useState(false);

  const determineShowMore = () => {
    const element = ref?.current;
    const offsetHeight = element?.offsetHeight || 0;
    const scrollHeight = element?.scrollHeight || 0;

    setHasShowMore(offsetHeight < scrollHeight);
  };

  if (height && hasShowMore === undefined) {
    determineShowMore();
  }

  useEffect(() => {
    setShowMore(false);
    determineShowMore();
  }, [content]);

  return (
    <Box
      ref={boxRef}
      className={classNames(
        classes.root,
        isShowMore && 'isShowingMore',
        className,
      )}
    >
      <Typography
        ref={ref}
        variant="body2"
        className={classes.content}
      >
        {content?.split('\n').filter((c) => !!c.trim()).map((c, index) => (
          <span key={index}>{c}</span>
        ))}
      </Typography>
      {hasShowMore && (
        <Box display="flex" justifyContent="flex-start" marginTop={1}>
          <Link
            type="button"
            className={classes.showMoreButton}
            onClick={() => setShowMore(!isShowMore)}
          >
            {isShowMore ? '< Show Less' : 'Show More >'}
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default ContentView;
