import React, { Suspense, lazy } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { ReactComponent as DefaultCompanyIcon } from '../../assets/svg/defaultCompanyIcon.svg';
import { ReactComponent as FundedIcon } from '../../assets/svg/fundedIcon.svg';
import { ReactComponent as WebIcon } from '../../assets/svg/wwwIcon.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/svg/linkedInIcon.svg';
import { ReactComponent as CrunchbaseIcon } from '../../assets/svg/crunchbaseIcon.svg';
import { ReactComponent as TwitterIcon } from '../../assets/svg/twitterIcon.svg';
import { ReactComponent as InfoIcon } from '../../assets/svg/infoIcon.svg';
import { ReactComponent as PvalyouSocialIcon } from '../../assets/svg/pvalyouSocialIcon.svg';

import { useCommonClasses } from '../../theme/commonStyles';
import { CompanyData, CompetitorCompanyInfo } from '../../app/features/report/types';
import { getCompanyLocation, getCompetitorLocation } from '../../app/features/report/reportUtils';

import styles from './CompanyInfoPanel.styles';

const Flag = lazy(() => import('react-world-flags'));

const FIELD_DETAILS_HEIGHT = 48;

const useStyles = makeStyles(styles);

type Props = {
  companyData?: CompanyData;
  competitorData?: CompetitorCompanyInfo;
  showFieldDetails?: boolean;
  highlightedKeywords?: string[];
  highlightedDescriptionKeywords?: string[];
  onCompetitorInfoOpen?: (competitorData: CompetitorCompanyInfo) => void;
};

const CompanyInfoPanel: React.FC<Props> = ({
  companyData,
  competitorData,
  showFieldDetails,
  highlightedKeywords,
  highlightedDescriptionKeywords,
  onCompetitorInfoOpen,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();

  const companyName = competitorData?.name || companyData?.orgName;
  const logo = competitorData?.logo?.at(0) || (
    Array.isArray(companyData?.orgLogo)
      ? companyData?.orgLogo?.at(0)
      : companyData?.orgLogo
  );
  const description = competitorData?.description || companyData?.orgDesc;
  const keywords = (
    competitorData?.keywords
      ? (competitorData?.keywords || '').split(',')
      : companyData?.orgKeywords
  )?.filter((kw) => !!kw);

  const industry = competitorData?.industry || companyData?.orgIndustry;
  const foundingYear = competitorData?.foundingYear || companyData?.orgFoundingYear;
  const vertical = competitorData?.vertical || companyData?.orgVertical;

  const descriptionMatches: [number, number][] = match(description || '', (highlightedDescriptionKeywords || []).join(' '));
  const descriptionParts = parse(description || '', descriptionMatches);

  const renderLocation = () => {
    const location = (
      getCompetitorLocation(competitorData)
      || getCompanyLocation(companyData)
    );

    const country = competitorData?.countryIso || companyData?.orgCountry;

    return (
      <Box display="flex" alignItems="center">
        {country && (
          <Suspense>
            <Box marginRight={0.5} display="inline-flex" alignItems="center">
              <Flag
                className={classes.selectorItemIcon}
                code={country}
                width={theme.spacing(3)}
              />
            </Box>
          </Suspense>
        )}
        <Typography color="info" variant="body2" display="inline-block">
          {location}
        </Typography>
      </Box>
    );
  };

  const openCompetitorInfo = () => {
    if (onCompetitorInfoOpen && competitorData) {
      onCompetitorInfoOpen(competitorData);
    }
  };

  const openExternalUrl = (url?: string) => {
    if (!url) {
      return;
    }

    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      window.open(`//${url}`, '_blank');
      return;
    }

    window.open(url, '_black');
  };

  const domain = companyData?.domain?.at(0) || competitorData?.domain?.at(0);
  const linkedIn = companyData?.linkedin?.at(0) || competitorData?.linkedin?.at(0);
  const crunchbase = companyData?.crunchbase?.at(0) || competitorData?.crunchbase?.at(0);
  const twitter = companyData?.twitter?.at(0) || competitorData?.twitter?.at(0);

  return (
    <div
      className={classes.root}
    >
      <Grid container>
        <Grid item xs={6} xl={8}>
          <Typography
            variant="h1"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {companyName}
          </Typography>
        </Grid>
        <Grid item container xs={6} xl={4} justifyContent="flex-end" alignItems="center">
          {competitorData?.score && (
            <div
              className={
                classNames(
                  classes.scoreBox,
                  commonCss.boxes.dataBox,
                  'leaf',
                  'secondary',
                )
              }
            >
              {Number(((competitorData.score) * 100).toFixed(1))}
              <small>%</small>
            </div>
          )}
          {competitorData?.hadFunding && (
            <Box className={classes.fundedIndicator}>
              <FundedIcon width={32} />
            </Box>
          )}
        </Grid>
      </Grid>
      <Box
        marginTop={1.5}
        className={classes.logoWithDescBox}
      >
        {logo ? (
          <Box
            className={commonCss.boxes.imageBox}
            component="object"
            data={logo}
            type="image/png"
            flexShrink={0}
            width={97}
            height={97}
          >
            <DefaultCompanyIcon />
          </Box>
        ) : (
          <Box
            className={commonCss.boxes.imageBox}
            width={97}
            height={97}
          >
            <DefaultCompanyIcon />
          </Box>
        )}
        <Box marginLeft={2} className={classes.description}>
          <Typography variant="body1">
            {descriptionParts.map((part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? 500 : undefined,
                }}
              >
                {part.text}
              </span>
            ))}
          </Typography>
        </Box>
      </Box>
      <Box
        marginTop={1.5}
        className={classes.tagsBox}
      >
        {(keywords || []).map((keyword) => (
          <Chip
            key={keyword}
            classes={{
              root: classNames(
                commonCss.chips.tagItem.root,
                (highlightedKeywords || []).includes(keyword) && classes.competitorHighlight,
              ),
            }}
            variant="outlined"
            label={keyword}
          />
        ))}
      </Box>
      <AnimatePresence>
        {showFieldDetails && (
          <motion.div
            className={classes.fieldDetails}
            initial={{
              maxHeight: 0,
              opacity: 0,
              overflow: 'hidden',
            }}
            animate={{
              maxHeight: [
                0,
                FIELD_DETAILS_HEIGHT,
                FIELD_DETAILS_HEIGHT,
              ],
              opacity: [0, 0, 1],
              overflow: 'hidden',
            }}
            exit={{
              maxHeight: [
                FIELD_DETAILS_HEIGHT,
                FIELD_DETAILS_HEIGHT,
                0,
              ],
              opacity: [1, 0, 0],
              overflow: 'hidden',
            }}
          >
            <Grid container columnSpacing={0.5} rowSpacing={0.5}>
              <Grid item container xs={7} xl={6}>
                <Grid item xs={5}>
                  <Typography className="label" color="secondary" variant="body2">
                    Location
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  {renderLocation()}
                </Grid>
              </Grid>
              <Grid item container xs={5} xl={6}>
                <Grid item xs={6} xl={4}>
                  <Typography className="label" color="secondary" variant="body2">
                    Industry
                  </Typography>
                </Grid>
                <Grid item xs={6} xl={8}>
                  <Typography color="info" variant="body2">
                    {industry}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={7} xl={6}>
                <Grid item xs={5}>
                  <Typography className="label" color="secondary" variant="body2">
                    Established
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography color="info" variant="body2">
                    {foundingYear}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={5} xl={6}>
                <Grid item xs={6} xl={4}>
                  <Typography className="label" color="secondary" variant="body2">
                    Vertical
                  </Typography>
                </Grid>
                <Grid item xs={6} xl={8}>
                  <Typography color="info" variant="body2">
                    {vertical}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </motion.div>
        )}
      </AnimatePresence>
      <Box
        className={
          classNames(
            classes.linksBox,
            !companyData && competitorData && 'competitor',
          )
        }
      >
        <IconButton
          disabled={!domain}
          size="small"
          onClick={() => openExternalUrl(domain)}
        >
          <WebIcon />
        </IconButton>
        <IconButton
          disabled={!linkedIn}
          size="small"
          onClick={() => openExternalUrl(linkedIn)}
        >
          <LinkedInIcon />
        </IconButton>
        <IconButton
          disabled={!crunchbase}
          size="small"
          onClick={() => openExternalUrl(crunchbase)}
        >
          <CrunchbaseIcon />
        </IconButton>
        <IconButton
          disabled={!twitter}
          size="small"
          onClick={() => openExternalUrl(twitter)}
        >
          <TwitterIcon />
        </IconButton>
        <div>
          {!companyData && competitorData && (
            <IconButton
              size="small"
              onClick={() => openCompetitorInfo()}
            >
              <PvalyouSocialIcon />
            </IconButton>
          )}
          <IconButton disabled size="small">
            <InfoIcon />
          </IconButton>
        </div>
      </Box>
    </div>
  );
};

export default CompanyInfoPanel;
