import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';
import getCommonStyles from '../../../../theme/commonStyles';
import { otherColorSets, otherContrastColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      borderWidth: '1px !important',
      borderColor: `${Color(otherColorSets[0]).alpha(0.5).toString()} !important`,
      position: 'relative',
      '&.crop': {
        overflow: 'hidden',
      },
    },
    founderTitle: {
      color: `${otherContrastColorSets[0]} !important`,
      marginBottom: `${theme.spacing(1)} !important`,
    },
    founderImage: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      backgroundColor: `${theme.palette.common.white} !important`,
      borderColor: `${Color(otherColorSets[0]).alpha(0.25).toString()} !important`,
      color: `${theme.palette.grey[600]} !important`,
      borderWidth: 1.5,
    },
    textField: {
      '& .MuiInputBase-input': {
        fontWeight: 'normal',
        color: `${theme.palette.grey[600]} !important`,
      },
    },
    formButton: {
      width: 150,
    },
    interactionBox: {
      position: 'relative',
      color: theme.palette.grey[600],
      height: theme.spacing(16.5),
    },
    interactionTitle: {
      position: 'absolute',
      top: theme.spacing(5),
      left: theme.spacing(1),
    },
    interactionLine: {
      position: 'absolute',
      top: theme.spacing(14),
      left: 0,
      right: 0,
      borderWidth: '1px !important',
      borderColor: theme.palette.grey[700],
      transition: 'all ease-out 0.3s',
      '&.active': {
        borderColor: `${otherContrastColorSets[0]} !important`,
      },
      '&.extended': {
        left: theme.spacing(-3),
      },
    },
    interactionButton: {
      position: 'absolute',
      top: theme.spacing(11.5),
      right: '0 !important',
      zIndex: 1,
      '& > button': {
        borderWidth: '2px !important',
        color: `${otherContrastColorSets[0]} !important`,
        borderColor: `${otherContrastColorSets[0]} !important`,
        fontSize: '1.285rem',
        width: theme.spacing(15),
        height: theme.spacing(5),
      },
      '&.unselected > button': {
        borderColor: `${theme.palette.grey[100]} !important`,
        color: `${theme.palette.grey[500]} !important`,
      },
      '&.unselected.outlinedHighlight > button': {
        borderColor: `${otherContrastColorSets[0]} !important`,
      },
    },
    interactionDecorate: {
      position: 'absolute',
      top: 0,
      right: theme.spacing(7.5),
      bottom: 0,
      width: 1,
      borderRight: '1px solid #edd5dd',
    },
  });
};

export default styles;
