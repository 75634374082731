import {
  all,
  fork,
  takeEvery,
  put,
  take,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ICountry } from 'country-state-city';

import { performApiGet } from '../../../core/utils/sagaUtils';
import config from '../../../config';
import { ApplicationState } from '../../types';

import {
  SearchActions,
  CompanyItem,
  RequestCompanyItemsArgs,
  SearchState,
  Industry,
  Vertical,
  SetSearchKeywordArgs,
  RequestMarketInquiryItemsArgs,
  MarketInquiryItem,
  RequestGlossaryItemsArgs,
  GlossaryItem,
  RequestGptSearchArgs,
  GptSearchResult,
  ToggleGptSearchArgs,
} from './types';

import {
  abortSearchRequest,
  requestCompanyItems,
  setCompanyItems,
  setGlossaryItems,
  setGptSearch,
  setHistoryItems,
  setMarketInquiryItems,
} from './actions';
import { searchSubSaga } from './saga.sub';

const abortControllers: AbortController[] = [];

function* requestHistoryItemsFlow() {
  yield takeEvery(
    SearchActions.HISTORY_ITEMS_REQUEST,
    function* _() {
      yield fork(() => performApiGet<CompanyItem[]>(
        config.api.getUserHistory,
        {
          onResult: function* __({ result, error }) {
            yield put(setHistoryItems({
              items: result,
              error,
            }));
          },
        },
      ));
    },
  );
}

function* requestCompanyItemsFlow() {
  yield takeEvery(
    SearchActions.COMPANY_ITEMS_REQUEST,
    function* _(act: PayloadAction<RequestCompanyItemsArgs>) {
      if (abortControllers.length) {
        const controller = abortControllers.pop();
        controller?.abort();

        yield take([
          SearchActions.COMPANY_ITEMS_SET,
          SearchActions.MARKET_INQUIRY_ITEMS_SET,
          SearchActions.GLOSSARY_ITEMS_SET,
          SearchActions.GPT_SEARCH_SET,
        ]);

        yield put(act);
        return;
      }

      // When request to add company.
      if (act.payload.shouldAddCompanyUrl) {
        yield fork(() => performApiGet<CompanyItem[]>(
          config.api.addOrgExtSrc,
          {
            params: {
              query: act.payload.keyword,
            },
            onResult: function* __({ result, error }) {
              yield put(setCompanyItems({
                items: result,
                error,
              }));

              abortControllers.pop();
            },
          },
        ));

        return;
      }

      const minKeyword = act.payload.minKeyword || 0;
      if ((act.payload.keyword?.length || 0) < minKeyword) {
        return;
      }

      const countries: ICountry[] = (
        yield select((state: ApplicationState) => state.search.filter.countries)
      );

      const industries: Industry[] = (
        yield select((state: ApplicationState) => state.search.filter.industries)
      );

      const verticals: Vertical[] = (
        yield select((state: ApplicationState) => state.search.filter.verticals)
      );

      const foundingYear: number = (
        yield select((state: ApplicationState) => state.search.filter.minFoundingYears)
      );

      const countryIsos = countries && countries.map((c) => c.isoCode);
      const industryCodes = industries && industries.map((c) => c.id);
      const verticalCodes = verticals && verticals.map((c) => c.id);

      const abortController = new AbortController();
      abortControllers.push(abortController);

      yield fork(() => performApiGet<CompanyItem[]>(
        config.api.getCompanySearch,
        {
          params: {
            q: act.payload.keyword,
            country_iso: countryIsos?.length > 0 ? countryIsos.join(',') : undefined,
            industry: industryCodes?.length > 0 ? industryCodes.join(',') : undefined,
            vertical: verticalCodes?.length > 0 ? verticalCodes.join(',') : undefined,
            founding_year: foundingYear ? String(foundingYear) : undefined,
          },
          onResult: function* __({ result, error }) {
            yield put(setCompanyItems({
              items: result,
              error,
              isAdditionalResults: act.payload.isAdditionalSearch,
            }));

            abortControllers.pop();
          },
        },
        {
          signal: abortController.signal,
        },
      ));
    },
  );
}

function* requestMarketInqueryItemsFlow() {
  yield takeEvery(
    SearchActions.MARKET_INQUIRY_ITEMS_REQUEST,
    function* _(act: PayloadAction<RequestMarketInquiryItemsArgs>) {
      if (abortControllers.length) {
        const controller = abortControllers.pop();
        controller?.abort();

        yield take([
          SearchActions.COMPANY_ITEMS_SET,
          SearchActions.MARKET_INQUIRY_ITEMS_SET,
          SearchActions.GLOSSARY_ITEMS_SET,
          SearchActions.GPT_SEARCH_SET,
        ]);

        yield put(act);
        return;
      }

      const abortController = new AbortController();
      abortControllers.push(abortController);

      const minKeyword = act.payload.minKeyword || 0;
      if ((act.payload.question?.length || 0) < minKeyword) {
        return;
      }

      yield fork(() => performApiGet<MarketInquiryItem[]>(
        config.api.getMarketInfo,
        {
          params: {
            desc: act.payload.question,
          },
          onResult: function* __({ result, error }) {
            yield put(setMarketInquiryItems({
              items: result,
              error,
            }));

            abortControllers.pop();
          },
        },
        {
          signal: abortController.signal,
        },
      ));
    },
  );
}

function* requestGlossaryItemsFlow() {
  yield takeEvery(
    SearchActions.GLOSSARY_ITEMS_REQUEST,
    function* _(act: PayloadAction<RequestGlossaryItemsArgs>) {
      if (abortControllers.length) {
        const controller = abortControllers.pop();
        controller?.abort();

        yield take([
          SearchActions.COMPANY_ITEMS_SET,
          SearchActions.MARKET_INQUIRY_ITEMS_SET,
          SearchActions.GLOSSARY_ITEMS_SET,
          SearchActions.GPT_SEARCH_SET,
        ]);

        yield put(act);
        return;
      }

      const abortController = new AbortController();
      abortControllers.push(abortController);

      yield fork(() => performApiGet<GlossaryItem[]>(
        config.api.getDictionary,
        {
          params: {
            desc: act.payload.phrase,
          },
          onResult: function* __({ result, error }) {
            yield put(setGlossaryItems({
              items: result,
              error,
            }));

            abortControllers.pop();
          },
        },
        {
          signal: abortController.signal,
        },
      ));
    },
  );
}

function* requestGptSearchFlow() {
  yield takeEvery(
    SearchActions.GPT_SEARCH_REQUEST,
    function* _(act: PayloadAction<RequestGptSearchArgs>) {
      if (abortControllers.length) {
        const controller = abortControllers.pop();
        controller?.abort();

        yield take([
          SearchActions.COMPANY_ITEMS_SET,
          SearchActions.MARKET_INQUIRY_ITEMS_SET,
          SearchActions.GLOSSARY_ITEMS_SET,
          SearchActions.GPT_SEARCH_SET,
        ]);

        yield put(act);
        return;
      }

      const abortController = new AbortController();
      abortControllers.push(abortController);

      yield fork(() => performApiGet<GptSearchResult[]>(
        config.api.getMarketGpt,
        {
          params: {
            desc: act.payload.question,
          },
          onResult: function* __({ result, error }) {
            yield put(setGptSearch({
              result,
              error,
            }));

            abortControllers.pop();
          },
        },
        {
          signal: abortController.signal,
        },
      ));
    },
  );
}

function* abortSearchRequestFlow() {
  yield takeEvery(
    SearchActions.SEARCH_REQUEST_ABORT,
    () => {
      const abortController = abortControllers.pop();
      abortController?.abort();
    },
  );
}

function* filterUpdatedFlow() {
  yield takeLatest([
    SearchActions.FILTERS_CLEAR,
    SearchActions.COUNTRIES_FILTER_UPDATE,
    SearchActions.INDUSTRIES_FILTER_UPDATE,
    SearchActions.VERTICALS_FILTER_UPDATE,
    SearchActions.MIN_FOUNDING_YEARS_FILTER_UPDATE,
  ], function* _() {
    const { keyword, minKeyword } = (
      yield select((state: ApplicationState) => state.search)
    ) as SearchState;

    yield put(requestCompanyItems({
      keyword,
      minKeyword,
    }));
  });
}

function* searchKeywordSetFlow() {
  yield takeEvery(
    SearchActions.SEARCH_KEYWORD_SET,
    function* _(act: PayloadAction<SetSearchKeywordArgs>) {
      const { keyword } = act.payload;

      if (keyword.trim() === '') {
        yield put(abortSearchRequest());
      }
    },
  );
}

function* gptSearchToggleFlow() {
  yield takeEvery(
    SearchActions.GPT_SEARCH_TOGGLE,
    function* _(act: PayloadAction<ToggleGptSearchArgs>) {
      const { isGptSearch } = act.payload;

      if (!isGptSearch) {
        yield put(abortSearchRequest());
      }
    },
  );
}

export default function* searchSaga() {
  yield all([
    searchKeywordSetFlow(),
    gptSearchToggleFlow(),
    requestCompanyItemsFlow(),
    requestHistoryItemsFlow(),
    requestMarketInqueryItemsFlow(),
    requestGlossaryItemsFlow(),
    requestGptSearchFlow(),
    abortSearchRequestFlow(),
    filterUpdatedFlow(),
    searchSubSaga(),
  ]);
}
