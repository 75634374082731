import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';

import {
  requestChatResponse,
  requestChatClean,
  toggleChatItemStep,
  toggleChatItemTable,
  toggleChatItemSource,
  toggleChatItemOrgs,
  cancelChatRequest,
  prepareDeepSearchTasks,
  updateLastObjective,
  requestCreateChatPrompt,
  requestTaskTemplates,
  applyTaskTemplate,
  duplicateChatItemChart,
  requestUpdateChatItemChart,
  applyChatOutputsCompare,
  updateSettings,
  setShowingGuidesAtHome,
  setShowingToolsAtHome,
} from '../actions';

import {
  requestLogout,
  requestUserExtra,
} from '../../session/actions';

import {
  requestAutocompleteItems,
} from '../../autocomplete/actions';

import {
  openFeedbackForm,
  openContactForm,
  requestSubscribe,
  openMessageBox,
  openCompanyReport,
} from '../../main/actions';

import {
  triggerNotification,
} from '../../notifier/actions';

import {
  setSearchKeyword,
  toggleGptSearch,
} from '../../search/actions';

import GptChatView from './GptChatView';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.router,
    ...state.session,
    ...state.autocomplete,
    ...state.search,
    ...state.gpt,
    ...state.relationChart,
    ...state.main,
    networkStates: {
      ...state.session.networkStates,
      ...state.gpt.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    requestLogout,
    requestAutocompleteItems,
    requestUserExtra,
    openFeedbackForm,
    openContactForm,
    requestSubscribe,
    triggerNotification,
    setSearchKeyword,
    toggleGptSearch,
    requestChatResponse,
    requestChatClean,
    toggleChatItemStep,
    toggleChatItemTable,
    toggleChatItemSource,
    toggleChatItemOrgs,
    cancelChatRequest,
    prepareDeepSearchTasks,
    updateLastObjective,
    openMessageBox,
    requestCreateChatPrompt,
    requestTaskTemplates,
    applyTaskTemplate,
    duplicateChatItemChart,
    requestUpdateChatItemChart,
    openCompanyReport,
    applyChatOutputsCompare,
    updateSettings,
    setShowingGuidesAtHome,
    setShowingToolsAtHome,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GptChatView);
