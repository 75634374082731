import { createReducer } from '@reduxjs/toolkit';
import {
  requestLogin,
  setLogin,
  requestLogout,
  initializeSession,
  setLoggedOut,
  requestUserExtra,
  setUserExtra,
} from './actions';
import { SessionState } from './types';

const initialState: SessionState = {
  networkStates: {
    loginRequest: { isRequesting: false },
    logoutRequest: { isRequesting: false },
    userExtraRequest: { isRequesting: false },
  },
};

const sessionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(initializeSession, (state) => ({
      ...state,
      isSessionInitialized: true,
    }))
    .addCase(requestLogin, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        loginRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setLogin, (state, action) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        loginRequest: {
          ...state.networkStates.loginRequest,
          isRequesting: false,
          lastError: action.payload.error,
        },
      },
      token: action.payload.loginInfo?.key,
      user: action.payload.loginInfo?.user,
    }))
    .addCase(requestLogout, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        logoutRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setLoggedOut, (state, action) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        logoutRequest: {
          ...state.networkStates.logoutRequest,
          isRequesting: false,
          lastError: action.payload.error,
        },
      },
      token: undefined,
      user: undefined,
    }))
    .addCase(requestUserExtra, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        userExtraRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setUserExtra, (state, action) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        userExtraRequest: {
          ...state.networkStates.userExtraRequest,
          isRequesting: false,
          lastError: action.payload.error,
        },
      },
      userExtraInfo: action.payload.userExtraInfo,
    }));
});

export default sessionReducer;
