import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';

const styles = (theme: Theme): StyleRules<{}, string> => createStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
    zIndex: 1,
  },
  profileItem: {
    position: 'relative',
    zIndex: 1,
    paddingLeft: '12.5%',
  },
  divider: {
    borderColor: `${Color(theme.palette.common.black).alpha(0.25).toString()} !important`,
    zIndex: 1,
  },
  dividerMarginBox: {
    height: 'max(calc((100vh - 860px) / 7), 8px) !important',
    '&.opaque': {
      background: theme.palette.common.white,
    },
  },
  reportGroupRow: {
    background: theme.palette.common.white,
    marginBottom: 2,
  },
  reportRow: {
    padding: 0,
    margin: `${theme.spacing(0.5)} 0`,
    cursor: 'pointer',
  },
  rowTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& $profileTitle': {
      fontWeight: 'normal !important',
    },
  },
  profileTitle: {

  },
  valueLabel: {
    color: `${theme.palette.grey[900]} !important`,
    fontSize: '1.2rem !important',
  },
});

export default styles;
