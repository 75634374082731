import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../types';
import {
  initializeSession,
} from '../session/actions';

import {
  setSearchKeyword,
} from '../search/actions';

import {
  closeMessageBox,
  closeFeedbackForm,
  closeContactForm,
  requestSubmitContactForm,
  openGlossaryBubble,
  closeGlossaryBubble,
} from './actions';

import Main from './Main';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.router,
    ...state.session,
    ...state.main,
    networkStates: {
      ...state.main.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    initializeSession,
    closeMessageBox,
    closeFeedbackForm,
    closeContactForm,
    requestSubmitContactForm,
    openGlossaryBubble,
    closeGlossaryBubble,
    setSearchKeyword,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Main);
