import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Box, IconButton } from '@mui/material';
import classNames from 'classnames';

import {
  LinkedIn as LinkedInIcon,
  Email as EmailIcon,
} from '@mui/icons-material';

import { ReactComponent as DefaultManIcon } from '../../../../assets/svg/defaultManIcon.svg';
import { ReactComponent as DefaultWomanIcon } from '../../../../assets/svg/defaultWomanIcon.svg';
import { FounderData } from '../types';
import { getFullName } from '../reportUtils';
import { useCommonClasses } from '../../../../theme/commonStyles';

import styles from './FounderAvatar.styles';

type Props = {
  founder?: FounderData;
  useSmallSize?: boolean;
};

const useStyles = makeStyles(styles);

const FounderAvatar: React.FC<Props> = ({
  founder,
  useSmallSize,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const [isFounderMoreInfoOpen, setFounderMoreInfoOpen] = useState(false);

  return (
    <Box className={classNames(classes.root, useSmallSize && 'small')}>
      {useSmallSize && (
        <Box display="inline-flex" flexDirection="column" width={20}>
          <IconButton
            className={classes.smallContactButton}
            size="small"
            color="info"
            onClick={() => founder?.linkedin && window.open(founder?.linkedin, '_blank')}
            disabled={!founder?.linkedin}
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            className={classes.smallContactButton}
            size="small"
            color="info"
          >
            <EmailIcon />
          </IconButton>
        </Box>
      )}
      <Avatar
        classes={{
          root: classNames(
            commonCss.avatars.founder.root,
            useSmallSize && 'small',
            useSmallSize && classes.smallAvatar,
          ),
          fallback: commonCss.avatars.founder.fallback,
        }}
        alt={getFullName(founder)}
        src={founder?.founderPic || ''}
      >
        {!founder?.female ? (
          <DefaultManIcon />
        ) : (
          <DefaultWomanIcon />
        )}
      </Avatar>
      {!useSmallSize && (
        <Box
          className={classes.moreInfoWrapper}
          onMouseEnter={() => setFounderMoreInfoOpen(true)}
          onMouseLeave={() => setFounderMoreInfoOpen(false)}
        >
          <Box
            className={
              classNames(
                classes.moreInfo,
                isFounderMoreInfoOpen && 'open',
              )
            }
          >
            <IconButton
              className={classes.contactButton}
              size="small"
              color="info"
              onClick={() => founder?.linkedin && window.open(founder?.linkedin, '_blank')}
              disabled={!founder?.linkedin}
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
              className={classes.contactButton}
              size="small"
              color="info"
            >
              <EmailIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FounderAvatar;
