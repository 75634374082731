import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';

import styles from './GptSuggestPanel.styles';
import { PropsFromRedux } from './container';
import { useCommonClasses } from '../../../../theme/commonStyles';

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);

const GptSuggestPanel: React.FC<Props> = ({
  requestGptSearch,
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();

  const suggestQuestions = [
    'I want to analyze the renewable energy sector, what are the best questions to ask?',
    'Explain in detail to a 12 year old what are the different types of cyber security?',
    'Summarize in five paragraphs what are the current M&A trends in the robotics industry? Mention a few examples.',
    'Explain in detail what venture capital investors are using big data and AI to make more informed investment decisions.',
    'Create a table with recent acquisitions in the B2B SaaS industry. Include the date and acquisition amount.',
    'What are the top 10 most promising Israeli startups according to different sources. Summarize in table.',
    'How is government policy affecting the growth of the renewable energy industry in the 5 largest economies?',
  ];

  const performGptSearch = (question: string) => {
    requestGptSearch({
      question,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="body2" className={classes.title}>
          Quick Actions:
        </Typography>
        {suggestQuestions.map((question) => (
          <Button
            key={question}
            variant="outlined"
            classes={commonCss.buttons.leafButton}
            className={classes.questionItem}
            color="secondary"
            fullWidth
            onClick={() => performGptSearch(question)}
          >
            {question}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default GptSuggestPanel;
