import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Edge, Node, ImageIcon } from '@sayari/trellis';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
  Checkbox,
  FormControlLabel,
  Divider,
  Switch,
  Slider,
} from '@mui/material';
import classNames from 'classnames';

import {
  ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';

import NetworkChart, { defaultConfig } from '../../../components/networkChart/NetworkChart';
import { RelationData, RelationNode } from './types';
import { otherContrastColorSets, otherLightColorSets } from '../../../theme/palette';
import chartIcons from './chartIcons';
import { PropsFromRedux } from './container';
import { CalloutBubble } from '../../../components';
import { useCommonClasses } from '../../../theme/commonStyles';
import {
  ChartLegendInfo,
  ClusterLayoutType,
  HierarchyAlignment,
  HierarchyAnchor,
  LayoutType,
  layoutTypes,
  NetworkChartConfig,
} from '../../../components/networkChart/types';
import useCommonProps, { SupportedColor } from '../../../theme/commonProps';
import { ReactComponent as MenuButtonIcon } from '../../../assets/svg/menu.svg';
import { CompetitorCompanyInfo } from '../report/types';

import styles from './RelationChart.styles';
import { splitCamelCases } from '../../../core/utils/stringUtils';
import { getShortNodeLabel } from '../../../core/utils/uxUtils';

interface Props extends PropsFromRedux {
  companyRelationChart?: boolean;
  relatedCompanies?: CompetitorCompanyInfo[];
  headerSize?: 'small' | 'medium';
  hidden?: boolean;
}

const useStyles = makeStyles(styles);

const RelationChart: React.FC<Props> = ({
  relationData,
  networkStates: {
    relationDataRequest,
  },
  entityBubble,
  closeEntityBubble,
  toggleEntityBubbleShowMore,
  invisibleEntityIds,
  updateInvisibleEntityIds,
  companyRelationChart,
  relatedCompanies,
  headerSize = 'small',
  hidden = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();
  const commonProps = useCommonProps();
  const [configPopoverAnchorEl, setConfigPopoverAnchorEl] = useState<Element>();
  const [entityPopoverAnchorEl, setEntityPopoverAnchorEl] = useState<Element>();
  const [currentOpenLegend, setCurrentOpenLegend] = useState<ChartLegendInfo>();
  const [isShowingLayoutConfig, setShowingLayoutConfig] = useState(true);
  const [isShowingLayoutOptions, setShowingLayoutOptions] = useState(false);
  const [isShowingStylingOptions, setShowingSlyingOptions] = useState(false);
  const [networkChartConfig, setNetworkChartConfig] = useState<NetworkChartConfig>(defaultConfig);
  const [
    relationDataWithRelatedCompanies,
    setRelationDataWithRelatedCompanies,
  ] = useState<RelationData>();

  const setDataTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (companyRelationChart && !relatedCompanies) {
      return;
    }

    const companyNodeIdLookup = (relatedCompanies || []).reduce((lookup, company, index) => ({
      ...lookup,
      [company.id]: String((relationData?.nodes || []).length + index),
    }), {} as Record<string, string>);

    const refinedData = {
      ...relationData,
      nodes: [
        ...(relationData?.nodes || []),
        ...(relatedCompanies || []).map((company) => ({
          entityType: 'Organization',
          id: companyNodeIdLookup[company.id],
          label: company.name,
        })),
      ],
      relationships: [
        ...(relationData?.relationships || []),
        ...(relatedCompanies || []).map((company, index) => ({
          id: String((relationData?.relationships || []).length + index),
          label: 'Similar',
          source: '0',
          target: companyNodeIdLookup[company.id],
        })),
      ],
    } as RelationData;

    if (setDataTimeout.current) {
      clearTimeout(setDataTimeout.current);
    }

    setDataTimeout.current = setTimeout(() => {
      setRelationDataWithRelatedCompanies(refinedData);
    }, 500);
  }, [relationData, relatedCompanies]);

  const relationCountLookup = (relationDataWithRelatedCompanies?.relationships || [])
    .filter((relation) => (
      !invisibleEntityIds.includes(relation.source)
      && !invisibleEntityIds.includes(relation.target)
    ))
    .reduce((lookup, relation) => ({
      ...lookup,
      [relation.target]: (lookup[relation.target] || 0) + 1,
      [relation.source]: (lookup[relation.source] || 0) + 1,
    }), {} as Record<string, number>);

  const getNodeBackgroundColor = (entityType: string) => {
    switch (entityType) {
      case 'Person': return otherLightColorSets[2];
      case 'Organization':
      case 'School':
        return otherLightColorSets[1];
      case 'Industry': return otherLightColorSets[0];
      case 'Location':
      case 'Country':
      case 'State':
      case 'City':
        return otherLightColorSets[3];
      default: return '#f0f0f0';
    }
  };

  const getNodeStrokeColor = (entityType: string) => {
    switch (entityType) {
      case 'Person': return otherContrastColorSets[2];
      case 'Organization':
      case 'School':
        return otherContrastColorSets[1];
      case 'Industry': return otherContrastColorSets[0];
      case 'Location':
      case 'Country':
      case 'State':
      case 'City':
        return otherContrastColorSets[3];
      default: return theme.palette.grey[600];
    }
  };

  const getNodeLabelColor = (entityType: string) => {
    switch (entityType) {
      case 'Person': return otherContrastColorSets[2];
      case 'Organization':
      case 'School':
        return otherContrastColorSets[1];
      case 'Industry': return otherContrastColorSets[0];
      case 'Location':
      case 'Country':
      case 'State':
      case 'City':
        return otherContrastColorSets[3];
      default: return theme.palette.grey[900];
    }
  };

  const getNodeIcon = (entityType: string) => {
    switch (entityType) {
      case 'Person': return chartIcons.person;
      case 'Industry': return chartIcons.industry;
      case 'Location':
      case 'Country':
      case 'State':
      case 'City':
        return chartIcons.location;
      case 'Organization':
        return chartIcons.company;
      case 'School':
        return chartIcons.school;
      default: return chartIcons.other;
    }
  };

  const getNodeIconScale = (entityType: string) => {
    if (entityType === 'School') {
      return 0.2;
    }

    return 0.15;
  };

  const getViewIcon = (mode: LayoutType) => (
    chartIcons[mode]
  );

  const toggleLayoutType = () => {
    const currentViewIndex = layoutTypes.indexOf(networkChartConfig.layoutType);
    if (currentViewIndex === layoutTypes.length - 1) {
      setNetworkChartConfig({
        ...networkChartConfig,
        layoutType: layoutTypes[0],
      });

      return;
    }

    setNetworkChartConfig({
      ...networkChartConfig,
      layoutType: layoutTypes[currentViewIndex + 1],
    });
  };

  const getNodeStyle = (entityType: string) => (
    {
      color: getNodeBackgroundColor(entityType),
      stroke: [{
        color: getNodeStrokeColor(entityType),
        width: 1,
      }],
      icon: {
        type: 'imageIcon',
        url: getNodeIcon(entityType),
        scale: getNodeIconScale(entityType),
      },
      badge: [{
        position: 45,
        radius: 6,
        color: '#fff',
        stroke: getNodeStrokeColor(entityType),
        strokeWidth: 1,
      }],
    }
  );

  const getNodeAttributes = (node: Omit<Node, 'radius'>) => {
    const {
      useSameRadius,
      hideBadge,
      hideIcon,
      hideLabel,
    } = networkChartConfig.stylingOptions;

    const relationNode = node as RelationNode;
    const defaultNodeStyle = getNodeStyle(relationNode.entityType);
    const nodeStyle = {
      ...defaultNodeStyle,
      icon: hideIcon ? undefined : defaultNodeStyle.icon,
    };

    const relationCount = relationCountLookup[node.id] || 0;

    const getBadgeIconSize = () => {
      if (hideBadge || relationCount === 0) {
        return 0.1;
      }

      return useSameRadius
        ? 10
        : relationCount / 2 + 8;
    };

    const getBadgeRadius = () => {
      if (hideBadge || relationCount === 0) {
        return 0.1;
      }

      return useSameRadius
        ? relationCount / 2 + 4
        : relationCount / 2 + 6;
    };

    return {
      ...node,
      radius: (
        useSameRadius
          ? 18
          : relationCount * 1.8 + 16
      ),
      style: {
        ...nodeStyle,
        icon: {
          ...nodeStyle.icon,
          scale: useSameRadius
            ? getNodeIconScale(relationNode.entityType)
            : relationCount * 0.05 * 0.35 + getNodeIconScale(relationNode.entityType),
        },
        badge: nodeStyle.badge.map((b) => ({
          ...b,
          radius: getBadgeRadius(),
          strokeWidth: hideBadge ? 0 : relationCount / 25 + 1,
          icon: relationCount && {
            type: 'textIcon',
            family: 'Roboto',
            size: getBadgeIconSize(),
            color: getNodeStrokeColor(relationNode.entityType),
            text: String(relationCount),
          },
        })),
        label: {
          color: getNodeLabelColor(relationNode.entityType),
          fontSize: hideLabel ? 0.1 : relationCount + 10,
        },
        stroke: [{
          width: relationCount / 25 + 1,
          color: getNodeStrokeColor(relationNode.entityType),
        }],
      },
    } as Node;
  };

  const getEdgeAttributes = (edge: Edge) => ({
    ...edge,
    stroke: theme.palette.grey[200],
    width: 1,
    arrow: 'forward',
    style: {
      arrow: 'forward',
      label: {
        fontSize: networkChartConfig.stylingOptions.hideLabel ? 0.1 : 10,
        color: theme.palette.grey[900],
        background: '#fff',
        fontFamily: 'Roboto',
      },
    },
  }) as Edge;

  const legendData = [
    {
      color: '#3074d5',
      colorSet: 'secondary',
      label: 'Organization',
      labelPlural: 'Organizations',
      subcategories: ['Organization', 'School'],
      style: getNodeStyle('Organization'),
    },
    {
      color: '#3074d5',
      colorSet: 'tertiary',
      label: 'Person',
      labelPlural: 'People',
      subcategories: ['Person'],
      style: getNodeStyle('Person'),
    },
    {
      color: '#3074d5',
      colorSet: 'primary',
      label: 'Industry',
      labelPlural: 'Industries',
      subcategories: ['Industry'],
      style: getNodeStyle('Industry'),
    },
    {
      color: '#4e95aa',
      colorSet: 'quaternary',
      label: 'Location',
      labelPlural: 'Locations',
      subcategories: ['Location', 'City', 'State', 'Country'],
      style: getNodeStyle('Location'),
    },
    {
      color: '#3074d5',
      colorSet: 'grey',
      label: 'Other',
      labelPlural: 'Others',
      subcategories: ['Other'],
      style: getNodeStyle('Other'),
    },
  ] as ChartLegendInfo[];

  const currentEntity = (
    entityBubble.isOpen
    && entityBubble.entityName
    && entityBubble.entityType
  ) && relationDataWithRelatedCompanies?.nodes.find((node) => (
    node.label === entityBubble.entityName
    && node.entityType === entityBubble.entityType
  ));

  const getEntityCountBySubCategories = (subCategories?: string[]) => (
    (relationDataWithRelatedCompanies?.nodes || [])
      .filter((node) => (subCategories || []).includes(node.entityType))
      .filter((node) => !invisibleEntityIds.includes(node.id))
      .length
  );

  const selectableEntityNodes = (relationDataWithRelatedCompanies?.nodes || [])
    .filter((node) => (currentOpenLegend?.subcategories || []).includes(node.entityType));

  return (
    <div className={classes.root} style={{ flex: hidden ? 0 : 1 }}>
      {!hidden && (
        <>
          {!relationDataRequest.isRequesting ? (
            <>
              {relationDataWithRelatedCompanies && (
                <Box flex={1} position="relative">
                  <NetworkChart
                    getNodeAttributes={getNodeAttributes}
                    getEdgeAttributes={getEdgeAttributes}
                    networkData={relationDataWithRelatedCompanies}
                    legendData={legendData}
                    config={networkChartConfig}
                    invisibleNodeIds={invisibleEntityIds}
                    onInvisibleNodesUpdated={(invisibileNodeIds) => updateInvisibleEntityIds({
                      entityIds: invisibileNodeIds,
                    })}
                    firstIsRoot={companyRelationChart}
                  />
                  <div className={classes.chartHeaderPanel}>
                    <Grid container>
                      <Grid item container xs={companyRelationChart ? 2 : 1} alignItems="center">
                        <Button
                          classes={commonCss.buttons.roundButton}
                          className={classNames(
                            classes.chartHeaderButton,
                            classes.layoutToggleButton,
                            'grey',
                          )}
                          variant="outlined"
                          size={headerSize}
                          color="info"
                          onClick={() => toggleLayoutType()}
                          style={{ width: 40 }}
                        >
                          <img
                            src={getViewIcon(networkChartConfig.layoutType)}
                            width={26}
                            height={26}
                          />
                        </Button>
                        {companyRelationChart && (
                          <Typography textTransform="capitalize" marginLeft={1} fontWeight={500}>
                            {networkChartConfig.layoutType}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item container columnGap={1} xs={companyRelationChart ? 8 : 10} justifyContent="center" flexWrap="nowrap">
                        {(legendData || [])
                          .filter((legend) => (
                            getEntityCountBySubCategories(legend.subcategories) > 0
                          ))
                          .map((legend) => (
                            <React.Fragment key={legend.label}>
                              <Button
                                classes={commonCss.buttons.roundButton}
                                className={
                                  classNames(
                                    classes.chartHeaderButton,
                                    legend.colorSet,
                                    'contrast',
                                    (currentOpenLegend?.subcategories || []).includes(legend.label) && 'active',
                                  )
                                }
                                variant="outlined"
                                size={headerSize}
                                onClick={(event) => {
                                  setEntityPopoverAnchorEl(event.currentTarget);
                                  setCurrentOpenLegend(legend);
                                }}
                              >
                                <img src={(legend.style.icon as ImageIcon)?.url} height={18} />
                                <Box display="inline-block" marginLeft={1}>
                                  {getEntityCountBySubCategories(legend.subcategories)}
                                </Box>
                                <Box display="inline-block" className="collapsibleLabel">
                                  {getEntityCountBySubCategories(legend.subcategories) > 1
                                    ? legend.labelPlural
                                    : legend.label}
                                </Box>
                                <ArrowDropDownIcon
                                  sx={{
                                    marginRight: theme.spacing(-1),
                                    transition: 'all 0.3s ease-out',
                                    transform: (
                                      currentOpenLegend?.subcategories || []
                                    ).includes(legend.label)
                                      ? 'rotateZ(180deg)'
                                      : undefined,
                                  }}
                                />
                              </Button>
                              <Popover
                                open={(
                                  !!entityPopoverAnchorEl
                                  && (currentOpenLegend?.subcategories || []).includes(legend.label)
                                )}
                                anchorEl={entityPopoverAnchorEl}
                                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                onClose={() => {
                                  setEntityPopoverAnchorEl(undefined);
                                  setCurrentOpenLegend(undefined);
                                }}
                                sx={{
                                  '.MuiPopover-paper': {
                                    minWidth: 150,
                                  },
                                }}
                              >
                                {(currentOpenLegend?.subcategories || [])
                                  .filter((subCategory) => (
                                    selectableEntityNodes
                                      .filter((node) => node.entityType === subCategory).length > 0
                                  ))
                                  .map((subCategory) => (
                                    <Box
                                      key={subCategory}
                                      paddingTop={1}
                                      paddingBottom={1}
                                      paddingLeft={2}
                                      paddingRight={2}
                                    >
                                      <Typography variant="body2" marginBottom={1}>
                                        <strong>
                                          {subCategory}
                                        </strong>
                                      </Typography>
                                      {(selectableEntityNodes || [])
                                        .filter((node) => node.entityType === subCategory)
                                        .map((node) => (
                                          <Box key={node.id}>
                                            <FormControlLabel
                                              {...commonProps.formControlLabel({
                                                color: legend.colorSet as SupportedColor,
                                              })}
                                              classes={{
                                                root: classes.legendItems,
                                              }}
                                              label={getShortNodeLabel(node)}
                                              control={
                                                <Checkbox
                                                  size="small"
                                                  sx={{ padding: 0.5, marginLeft: 0.5 }}
                                                  checked={!invisibleEntityIds.includes(node.id)}
                                                />
                                              }
                                              onChange={(_, checked) => {
                                                if (!checked) {
                                                  updateInvisibleEntityIds({
                                                    entityIds: Array.from(new Set([
                                                      ...invisibleEntityIds,
                                                      node.id,
                                                    ])),
                                                  });
                                                } else {
                                                  updateInvisibleEntityIds({
                                                    entityIds: invisibleEntityIds
                                                      .filter((id) => id !== node.id),
                                                  });
                                                }
                                              }}
                                            />
                                          </Box>
                                        ))}
                                    </Box>
                                  ))}
                                {(selectableEntityNodes || []).length === 0 && (
                                  <Typography variant="body2" marginBottom={1}>
                                    No {legend.label}
                                  </Typography>
                                )}
                              </Popover>
                            </React.Fragment>
                          ))}
                      </Grid>
                      <Grid item container xs={companyRelationChart ? 2 : 1} justifyContent="flex-end">
                        <Button
                          classes={commonCss.buttons.roundButton}
                          className={classNames(classes.chartHeaderButton, 'grey')}
                          variant="outlined"
                          size={headerSize}
                          color="info"
                          onClick={(event) => setConfigPopoverAnchorEl(event.currentTarget)}
                          style={{
                            paddingLeft: 8,
                            paddingRight: 6,
                            width: 40,
                          }}
                        >
                          <MenuButtonIcon
                            width={24}
                            height={24}
                            style={{
                              transform: 'scale(1.5)',
                              marginLeft: 2,
                            }}
                          />
                        </Button>
                        <Popover
                          open={!!configPopoverAnchorEl}
                          anchorEl={configPopoverAnchorEl}
                          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                          onClose={() => {
                            setConfigPopoverAnchorEl(undefined);
                          }}
                          sx={{
                            '.MuiPopover-paper': {
                              width: 330,
                            },
                          }}
                        >
                          <Box padding={2}>
                            <Grid container marginBottom={1}>
                              <Grid item container xs={6} alignItems="center">
                                <ArrowDropDownIcon
                                  sx={{
                                    marginLeft: theme.spacing(-1),
                                    transition: 'all 0.3s ease-out',
                                    transform: isShowingLayoutConfig ? undefined : 'rotateZ(-90deg)',
                                  }}
                                />
                                <Link
                                  type="button"
                                  color={theme.palette.grey[900]}
                                  sx={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setShowingLayoutConfig(!isShowingLayoutConfig);
                                  }}
                                >
                                  <strong>
                                    LAYOUT
                                  </strong>
                                </Link>
                              </Grid>
                              <Grid item container xs={6} justifyContent="flex-end">
                                <Link
                                  type="button"
                                  color={theme.palette.grey[900]}
                                  sx={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setNetworkChartConfig(defaultConfig)}
                                >
                                  Reset Settings
                                </Link>
                              </Grid>
                            </Grid>
                            {isShowingLayoutConfig && (
                              <Box>
                                <Grid container columnSpacing={1} rowSpacing={1}>
                                  {['network', 'hierarchy', 'radial', 'clusters'].map((type) => (
                                    <Grid key={type} item xs={6}>
                                      <Button
                                        classes={commonCss.buttons.roundButton}
                                        className={
                                          classNames(
                                            classes.chartHeaderButton,
                                            'grey',
                                            networkChartConfig.layoutType === type && 'active',
                                          )
                                        }
                                        variant="outlined"
                                        size="small"
                                        color="info"
                                        fullWidth
                                        onClick={() => setNetworkChartConfig((config) => ({
                                          ...config,
                                          layoutType: type as LayoutType,
                                        }))}
                                        sx={{
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        <img
                                          src={getViewIcon(type as LayoutType)}
                                          width={24}
                                          height={24}
                                        />
                                        <Box component="span" display="inline-block" marginLeft={1}>
                                          {type}
                                        </Box>
                                      </Button>
                                    </Grid>
                                  ))}
                                </Grid>
                                <Grid container columnSpacing={1} marginTop={1}>
                                  <Grid item container xs={6} alignItems="center">
                                    <Box marginLeft={2}>
                                      <Typography variant="body2">
                                        Lock Layout
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item container xs={6} justifyContent="flex-end">
                                    <Switch
                                      {...commonProps.switchControl({
                                        color: 'secondary',
                                      })}
                                      color="secondary"
                                      checked={networkChartConfig.isLayoutLocked}
                                      onChange={(_, checked) => setNetworkChartConfig({
                                        ...networkChartConfig,
                                        isLayoutLocked: checked,
                                      })}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container columnSpacing={1}>
                                  <Grid item container xs={6} alignItems="center">
                                    <Box marginLeft={2}>
                                      <Typography variant="body2">
                                        Lock Viewport
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item container xs={6} justifyContent="flex-end">
                                    <Switch
                                      {...commonProps.switchControl({
                                        color: 'secondary',
                                      })}
                                      color="secondary"
                                      checked={networkChartConfig.isViewportLocked}
                                      onChange={(_, checked) => setNetworkChartConfig({
                                        ...networkChartConfig,
                                        isViewportLocked: checked,
                                      })}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            )}
                            <Divider />
                            <Grid container marginBottom={1} marginTop={1}>
                              <Grid item container xs={6} alignItems="center">
                                <ArrowDropDownIcon
                                  sx={{
                                    marginLeft: theme.spacing(-1),
                                    transition: 'all 0.3s ease-out',
                                    transform: isShowingLayoutOptions ? undefined : 'rotateZ(-90deg)',
                                  }}
                                />
                                <Link
                                  type="button"
                                  color={theme.palette.grey[900]}
                                  sx={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setShowingLayoutOptions(!isShowingLayoutOptions);
                                  }}
                                >
                                  <strong>
                                    LAYOUT OPTIONS
                                  </strong>
                                </Link>
                              </Grid>
                            </Grid>
                            {isShowingLayoutOptions && (
                              <>
                                {networkChartConfig.layoutType === 'network' && (
                                  <Box marginLeft={2} marginRight={2}>
                                    <Box>
                                      <Typography variant="body2">
                                        Gravity
                                      </Typography>
                                      <Slider
                                        color="secondary"
                                        defaultValue={50}
                                        valueLabelDisplay="auto"
                                        max={0}
                                        min={-1200}
                                        value={networkChartConfig.layoutOptions.network.gravity}
                                        onChange={(_, value) => {
                                          setNetworkChartConfig({
                                            ...networkChartConfig,
                                            layoutOptions: {
                                              ...networkChartConfig.layoutOptions,
                                              network: {
                                                ...networkChartConfig.layoutOptions.network,
                                                gravity: value as number,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </Box>
                                    <Box>
                                      <Typography variant="body2">
                                        Link Distance
                                      </Typography>
                                      <Slider
                                        color="secondary"
                                        defaultValue={50}
                                        valueLabelDisplay="auto"
                                        max={600}
                                        min={0}
                                        value={networkChartConfig
                                          .layoutOptions.network.linkDistance}
                                        onChange={(_, value) => {
                                          setNetworkChartConfig({
                                            ...networkChartConfig,
                                            layoutOptions: {
                                              ...networkChartConfig.layoutOptions,
                                              network: {
                                                ...networkChartConfig.layoutOptions.network,
                                                linkDistance: value as number,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                )}
                                {networkChartConfig.layoutType === 'hierarchy' && (
                                  <Box>
                                    <Grid container columnSpacing={1} marginTop={-1}>
                                      <Grid item container xs={6} alignItems="center">
                                        <Box marginLeft={2}>
                                          <Typography variant="body2">
                                            Stack Layout
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item container xs={6} justifyContent="flex-end">
                                        <Switch
                                          {...commonProps.switchControl({
                                            color: 'secondary',
                                          })}
                                          color="secondary"
                                          checked={networkChartConfig.layoutOptions.hierarchy.stack}
                                          onChange={(_, checked) => setNetworkChartConfig({
                                            ...networkChartConfig,
                                            layoutOptions: {
                                              ...networkChartConfig.layoutOptions,
                                              hierarchy: {
                                                ...networkChartConfig.layoutOptions.hierarchy,
                                                stack: checked,
                                              },
                                            },
                                          })}
                                        />
                                      </Grid>
                                    </Grid>
                                    <Box marginLeft={2} marginRight={2}>
                                      <Box>
                                        <Typography variant="body2">
                                          Anchor
                                        </Typography>
                                        {['Top', 'Left', 'Bottom', 'Right'].map((anchor) => (
                                          <FormControlLabel
                                            key={anchor}
                                            label={anchor}
                                            control={
                                              <Checkbox
                                                size="small"
                                                color="secondary"
                                                sx={{ padding: 0.5, marginLeft: 0.5 }}
                                                checked={
                                                  networkChartConfig
                                                    .layoutOptions
                                                    .hierarchy
                                                    .anchor === anchor.toLowerCase()
                                                }
                                              />
                                            }
                                            sx={{ marginRight: 1 }}
                                            onChange={(_, checked) => {
                                              if (checked) {
                                                setNetworkChartConfig({
                                                  ...networkChartConfig,
                                                  layoutOptions: {
                                                    ...networkChartConfig.layoutOptions,
                                                    hierarchy: {
                                                      ...networkChartConfig.layoutOptions.hierarchy,
                                                      anchor: (
                                                        anchor.toLowerCase() as HierarchyAnchor
                                                      ),
                                                    },
                                                  },
                                                });
                                              }
                                            }}
                                          />
                                        ))}
                                      </Box>
                                      <Box marginTop={1}>
                                        <Typography variant="body2">
                                          Alignment
                                        </Typography>
                                        {['Min', 'Mid', 'Max'].map((alignment) => (
                                          <FormControlLabel
                                            key={alignment}
                                            label={alignment}
                                            control={
                                              <Checkbox
                                                size="small"
                                                color="secondary"
                                                sx={{ padding: 0.5, marginLeft: 0.5 }}
                                                checked={
                                                  networkChartConfig
                                                    .layoutOptions
                                                    .hierarchy
                                                    .alignment === alignment.toLowerCase()
                                                }
                                              />
                                            }
                                            sx={{ marginRight: 1 }}
                                            onChange={(_, checked) => {
                                              if (checked) {
                                                setNetworkChartConfig({
                                                  ...networkChartConfig,
                                                  layoutOptions: {
                                                    ...networkChartConfig.layoutOptions,
                                                    hierarchy: {
                                                      ...networkChartConfig.layoutOptions.hierarchy,
                                                      alignment: (
                                                        alignment
                                                          .toLowerCase() as HierarchyAlignment
                                                      ),
                                                    },
                                                  },
                                                });
                                              }
                                            }}
                                          />
                                        ))}
                                      </Box>
                                      <Box marginTop={1}>
                                        <Typography variant="body2">
                                          Width Distance
                                        </Typography>
                                        <Slider
                                          color="secondary"
                                          defaultValue={50}
                                          valueLabelDisplay="auto"
                                          max={150}
                                          min={0}
                                          value={networkChartConfig
                                            .layoutOptions.hierarchy.widthDistance}
                                          onChange={(_, value) => {
                                            setNetworkChartConfig({
                                              ...networkChartConfig,
                                              layoutOptions: {
                                                ...networkChartConfig.layoutOptions,
                                                hierarchy: {
                                                  ...networkChartConfig.layoutOptions.hierarchy,
                                                  widthDistance: value as number,
                                                },
                                              },
                                            });
                                          }}
                                        />
                                      </Box>
                                      <Box>
                                        <Typography variant="body2">
                                          Height Distance
                                        </Typography>
                                        <Slider
                                          color="secondary"
                                          defaultValue={50}
                                          valueLabelDisplay="auto"
                                          max={150}
                                          min={0}
                                          value={networkChartConfig
                                            .layoutOptions.hierarchy.heightDistance}
                                          onChange={(_, value) => {
                                            setNetworkChartConfig({
                                              ...networkChartConfig,
                                              layoutOptions: {
                                                ...networkChartConfig.layoutOptions,
                                                hierarchy: {
                                                  ...networkChartConfig.layoutOptions.hierarchy,
                                                  heightDistance: value as number,
                                                },
                                              },
                                            });
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                )}
                                {networkChartConfig.layoutType === 'radial' && (
                                  <Box>
                                    <Grid container columnSpacing={1} marginTop={-1}>
                                      <Grid item container xs={6} alignItems="center">
                                        <Box marginLeft={2}>
                                          <Typography variant="body2">
                                            Stack Layout
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item container xs={6} justifyContent="flex-end">
                                        <Switch
                                          {...commonProps.switchControl({
                                            color: 'secondary',
                                          })}
                                          color="secondary"
                                          checked={networkChartConfig.layoutOptions.radial.stack}
                                          onChange={(_, checked) => setNetworkChartConfig({
                                            ...networkChartConfig,
                                            layoutOptions: {
                                              ...networkChartConfig.layoutOptions,
                                              radial: {
                                                ...networkChartConfig.layoutOptions.radial,
                                                stack: checked,
                                              },
                                            },
                                          })}
                                        />
                                      </Grid>
                                    </Grid>
                                    <Box marginLeft={2} marginRight={2}>
                                      <Box>
                                        <Typography variant="body2">
                                          Radius
                                        </Typography>
                                        <Slider
                                          color="secondary"
                                          defaultValue={50}
                                          valueLabelDisplay="auto"
                                          max={12}
                                          min={0}
                                          step={0.1}
                                          value={networkChartConfig
                                            .layoutOptions.radial.radius}
                                          onChange={(_, value) => {
                                            setNetworkChartConfig({
                                              ...networkChartConfig,
                                              layoutOptions: {
                                                ...networkChartConfig.layoutOptions,
                                                radial: {
                                                  ...networkChartConfig.layoutOptions.radial,
                                                  radius: value as number,
                                                },
                                              },
                                            });
                                          }}
                                        />
                                      </Box>
                                      <Box>
                                        <Typography variant="body2">
                                          Link Distance
                                        </Typography>
                                        <Slider
                                          color="secondary"
                                          defaultValue={50}
                                          valueLabelDisplay="auto"
                                          max={4000}
                                          min={0}
                                          value={networkChartConfig
                                            .layoutOptions.radial.linkDistance}
                                          onChange={(_, value) => {
                                            setNetworkChartConfig({
                                              ...networkChartConfig,
                                              layoutOptions: {
                                                ...networkChartConfig.layoutOptions,
                                                radial: {
                                                  ...networkChartConfig.layoutOptions.radial,
                                                  linkDistance: value as number,
                                                },
                                              },
                                            });
                                          }}
                                        />
                                      </Box>
                                      <Box>
                                        <Typography variant="body2">
                                          Node Separation
                                        </Typography>
                                        <Slider
                                          color="secondary"
                                          defaultValue={50}
                                          valueLabelDisplay="auto"
                                          max={10}
                                          min={0}
                                          step={0.5}
                                          value={networkChartConfig
                                            .layoutOptions.radial.nodeSeparation}
                                          onChange={(_, value) => {
                                            setNetworkChartConfig({
                                              ...networkChartConfig,
                                              layoutOptions: {
                                                ...networkChartConfig.layoutOptions,
                                                radial: {
                                                  ...networkChartConfig.layoutOptions.radial,
                                                  nodeSeparation: value as number,
                                                },
                                              },
                                            });
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                )}
                                {networkChartConfig.layoutType === 'clusters' && (
                                  <Box marginLeft={2} marginRight={2}>
                                    <Box>
                                      <Typography variant="body2">
                                        Cluster Layout
                                      </Typography>
                                      {['Radial', 'Hierarchy', 'Collide'].map((layout) => (
                                        <FormControlLabel
                                          key={layout}
                                          label={layout}
                                          control={
                                            <Checkbox
                                              size="small"
                                              color="secondary"
                                              sx={{ padding: 0.5, marginLeft: 0.5 }}
                                              checked={
                                                networkChartConfig
                                                  .layoutOptions
                                                  .clusters
                                                  .layout === layout.toLowerCase()
                                              }
                                            />
                                          }
                                          sx={{ marginRight: 1 }}
                                          onChange={(_, checked) => {
                                            if (checked) {
                                              setNetworkChartConfig({
                                                ...networkChartConfig,
                                                layoutOptions: {
                                                  ...networkChartConfig.layoutOptions,
                                                  clusters: {
                                                    ...networkChartConfig.layoutOptions.clusters,
                                                    layout: layout
                                                      .toLowerCase() as ClusterLayoutType,
                                                  },
                                                },
                                              });
                                            }
                                          }}
                                        />
                                      ))}
                                    </Box>
                                    <Box marginTop={1}>
                                      <Typography variant="body2">
                                        Cluster Padding
                                      </Typography>
                                      <Slider
                                        color="secondary"
                                        defaultValue={50}
                                        valueLabelDisplay="auto"
                                        max={200}
                                        min={0}
                                        value={networkChartConfig
                                          .layoutOptions.clusters.clusterPadding}
                                        onChange={(_, value) => {
                                          setNetworkChartConfig({
                                            ...networkChartConfig,
                                            layoutOptions: {
                                              ...networkChartConfig.layoutOptions,
                                              clusters: {
                                                ...networkChartConfig.layoutOptions.clusters,
                                                clusterPadding: value as number,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </Box>
                                    <Box>
                                      <Typography variant="body2">
                                        Collide Padding
                                      </Typography>
                                      <Slider
                                        color="secondary"
                                        defaultValue={50}
                                        valueLabelDisplay="auto"
                                        max={200}
                                        min={0}
                                        value={networkChartConfig
                                          .layoutOptions.clusters.collidePadding}
                                        onChange={(_, value) => {
                                          setNetworkChartConfig({
                                            ...networkChartConfig,
                                            layoutOptions: {
                                              ...networkChartConfig.layoutOptions,
                                              clusters: {
                                                ...networkChartConfig.layoutOptions.clusters,
                                                collidePadding: value as number,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                )}
                              </>
                            )}
                            <Divider />
                            <Grid container marginBottom={1} marginTop={1}>
                              <Grid item container xs={6} alignItems="center">
                                <ArrowDropDownIcon
                                  sx={{
                                    marginLeft: theme.spacing(-1),
                                    transition: 'all 0.3s ease-out',
                                    transform: isShowingStylingOptions ? undefined : 'rotateZ(-90deg)',
                                  }}
                                />
                                <Link
                                  type="button"
                                  color={theme.palette.grey[900]}
                                  sx={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setShowingSlyingOptions(!isShowingStylingOptions);
                                  }}
                                >
                                  <strong>
                                    STYLING OPTIONS
                                  </strong>
                                </Link>
                              </Grid>
                            </Grid>
                            {isShowingStylingOptions && (
                              <Box>
                                <Grid container columnSpacing={1}>
                                  <Grid item container xs={6} alignItems="center">
                                    <Box marginLeft={2}>
                                      <Typography variant="body2">
                                        Same Radius
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item container xs={6} justifyContent="flex-end">
                                    <Switch
                                      {...commonProps.switchControl({
                                        color: 'secondary',
                                      })}
                                      color="secondary"
                                      checked={networkChartConfig.stylingOptions.useSameRadius}
                                      onChange={(_, checked) => setNetworkChartConfig({
                                        ...networkChartConfig,
                                        stylingOptions: {
                                          ...networkChartConfig.stylingOptions,
                                          useSameRadius: checked,
                                        },
                                      })}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container columnSpacing={1}>
                                  <Grid item container xs={6} alignItems="center">
                                    <Box marginLeft={2}>
                                      <Typography variant="body2">
                                        Hide Badge
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item container xs={6} justifyContent="flex-end">
                                    <Switch
                                      {...commonProps.switchControl({
                                        color: 'secondary',
                                      })}
                                      color="secondary"
                                      checked={networkChartConfig.stylingOptions.hideBadge}
                                      onChange={(_, checked) => setNetworkChartConfig({
                                        ...networkChartConfig,
                                        stylingOptions: {
                                          ...networkChartConfig.stylingOptions,
                                          hideBadge: checked,
                                        },
                                      })}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container columnSpacing={1}>
                                  <Grid item container xs={6} alignItems="center">
                                    <Box marginLeft={2}>
                                      <Typography variant="body2">
                                        Hide Label
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item container xs={6} justifyContent="flex-end">
                                    <Switch
                                      {...commonProps.switchControl({
                                        color: 'secondary',
                                      })}
                                      color="secondary"
                                      checked={networkChartConfig.stylingOptions.hideLabel}
                                      onChange={(_, checked) => setNetworkChartConfig({
                                        ...networkChartConfig,
                                        stylingOptions: {
                                          ...networkChartConfig.stylingOptions,
                                          hideLabel: checked,
                                        },
                                      })}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid container columnSpacing={1}>
                                  <Grid item container xs={6} alignItems="center">
                                    <Box marginLeft={2}>
                                      <Typography variant="body2">
                                        Hide Icon
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item container xs={6} justifyContent="flex-end">
                                    <Switch
                                      {...commonProps.switchControl({
                                        color: 'secondary',
                                      })}
                                      color="secondary"
                                      checked={networkChartConfig.stylingOptions.hideIcon}
                                      onChange={(_, checked) => setNetworkChartConfig({
                                        ...networkChartConfig,
                                        stylingOptions: {
                                          ...networkChartConfig.stylingOptions,
                                          hideIcon: checked,
                                        },
                                      })}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            )}
                          </Box>
                        </Popover>
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              )}
            </>
          ) : (
            <Box display="flex" flex="1" alignItems="center" justifyContent="center">
              <CircularProgress color="secondary" size={24} />
            </Box>
          )}
        </>
      )}
      {currentEntity && (
        <CalloutBubble
          colorSet="source"
          open={entityBubble.isOpen && !!currentEntity}
          originX={entityBubble?.originX}
          originY={entityBubble?.originY}
          onClose={() => closeEntityBubble()}
        >
          <Box display="flex" flexDirection="column">
            <Box
              onMouseDown={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
              onMouseUp={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            >
              <Box className={classes.entityBubbleContent}>
                {currentEntity.summary}
              </Box>
              {entityBubble.isShowingMore && (
                <Box className={classes.entityBubbleInfoBox} marginTop={1} marginBottom={1}>
                  <Table size="small" padding="none">
                    <TableBody>
                      {currentEntity.infobox?.map((info) => (
                        <TableRow>
                          <TableCell
                            className={classes.entityBubbleInfoBoxHead}
                          >
                            {info.key}
                          </TableCell>
                          <TableCell
                            className={classes.entityBubbleInfoBoxValue}
                          >
                            {info.wikiLinks && Object.keys(info.wikiLinks).length > 0 ? (
                              <>
                                {Object.keys(info.wikiLinks).map((key, i) => (
                                  <React.Fragment key={key}>
                                    <Link
                                      color="secondary"
                                      href={info.wikiLinks[key]}
                                      className={classes.entityBubbleInfoBoxLink}
                                      target={info.wikiLinks[key]}
                                    >
                                      {splitCamelCases(key)}
                                    </Link>
                                    {i < Object.keys(info.wikiLinks).length - 1 && (
                                      <span>, </span>
                                    )}
                                  </React.Fragment>
                                ))}
                              </>
                            ) : (
                              info.value
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )}
              {currentEntity.infobox && (
                <Link
                  type="button"
                  color="secondary"
                  className={classes.entityBubbleShowMore}
                  onClick={() => toggleEntityBubbleShowMore()}
                >
                  Show {entityBubble.isShowingMore ? 'Less' : 'More'}
                </Link>
              )}
            </Box>
          </Box>
        </CalloutBubble>
      )}
    </div>
  );
};

export default RelationChart;
