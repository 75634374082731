import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import CountUp from 'react-countup';

import { useCommonClasses } from '../../../../theme/commonStyles';
import { ReactComponent as DefaultCompanyIcon } from '../../../../assets/svg/defaultCompanyIcon.svg';
import { easeOut } from '../../../../core/utils/animationUtils';
import { getAmountInfo, getCommonReportParams } from '../reportUtils';

import { PropsFromRedux } from './container';
import styles from './RankingReportDetails.styles';
import { TinyBarChart } from '../../../../components';
import { CompetitorCompanyInfo } from '../types';
import reportRoutes from '../reportRoutes';

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);

const RankingReportDetails: React.FC<Props> = ({
  companyReport,
  competitorsReport,
  selectedCompetitors,
  requestCompetitorsData,
  setCompetitorHighlight,
  highlightingCompetitor,
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const navigate = useNavigate();
  const { id } = useParams();

  const competitors = selectedCompetitors;

  useEffect(() => {
    if (!competitorsReport && id) {
      requestCompetitorsData({
        id,
      });
    }
  }, [competitorsReport]);

  useEffect(() => {
    setCompetitorHighlight({
      competitor: undefined,
      isPersist: true,
    });
  }, [setCompetitorHighlight]);

  const allCompetitors = [
    ...competitorsReport?.target || [],
    ...competitors || [],
  ].sort((a, b) => {
    const aScore = a?.score || 0;
    const bScore = b?.score || 0;

    const aScore10000 = (aScore * 10000).toFixed(2);
    const bScore10000 = (bScore * 10000).toFixed(2);

    const aYear = a?.foundingYear || 5000;
    const bYear = b?.foundingYear || 5000;

    const aVal: string = `${aScore10000.padStart(5, '0')}-${5000 - aYear}`;
    const bVal: string = `${bScore10000.padStart(5, '0')}-${5000 - bYear}`;

    return -aVal.localeCompare(bVal);
  });

  const getStatusColorSet = (status: string) => {
    switch (status) {
      case 'active':
        return 'secondary';
      case 'exit':
      case 'acquired':
      case 'IPO':
        return 'quaternary';
      case 'closed':
        return 'primary';
      default:
        return '';
    }
  };

  const getStatusName = (status: string) => {
    if (status === 'active') {
      return 'Operating';
    }

    if (status === 'IPO' || status === 'acquired') {
      return 'Exit';
    }

    return status;
  };

  const renderCompareIndicator = (
    value: string,
    field: string,
    colorSet: string,
    suffix?: string,
    decimals?: number,
  ) => {
    const total = Number(value) || 0;
    const maxValue = allCompetitors.reduce((max, c) => {
      const rec = c as unknown as Record<string, number | undefined>;
      const v = rec[field] || 0;
      return Math.max(max, v);
    }, 0);

    const percent = Math.round((total / maxValue) * 100);
    const amountInfo = getAmountInfo(total, {
      capitalized: true,
      smallUnit: false,
    });

    const { amount, unit } = amountInfo;

    return (
      <Grid container width="90%" spacing={0.5}>
        <Grid item container xs={6} xl={7.5} alignItems="center">
          <TinyBarChart
            percent={percent}
            colorClass={colorSet}
            animationDuration={500}
          />
        </Grid>
        <Grid item container xs={6} xl={4.5} alignItems="center">
          <CountUp
            className={classes.rankValue}
            end={Number(amount) || 0}
            duration={1}
            decimals={decimals || 0}
            useEasing
            delay={0.5}
            easingFn={easeOut}
          />
          <span className={classes.rankValue}>
            {unit || ''}
            {suffix || ''}
          </span>
        </Grid>
      </Grid>
    );
  };

  const persistCompetitorHighlight = (competitor?: CompetitorCompanyInfo) => {
    if (
      highlightingCompetitor?.competitor?.id === competitor?.id
      && highlightingCompetitor?.isPersist
    ) {
      setCompetitorHighlight({
        competitor: undefined,
        isPersist: true,
      });

      return;
    }

    setCompetitorHighlight({
      competitor,
      isPersist: !!competitor,
    });
  };

  const rankingReportDefs = [{
    title: 'Profile Score',
    colorSet: 'secondary',
    field: 'score',
    fieldRenderer: (value?: string) => (
      <>
        {value && (
          <div
            className={
              classNames(
                classes.scoreBox,
                commonCss.boxes.dataBox,
                'leaf',
                'secondary',
                'flip',
              )
            }
          >
            {Number(((Number(value)) * 100).toFixed(1))}
            <small>%</small>
          </div>
        )}
      </>
    ),
    subItems: [{
      title: 'Founded',
      field: 'foundingYear',
      fieldRenderer: (value: string) => (
        <Typography variant="body2" fontWeight={500}>
          {Number(value) > 0 ? value : ''}
        </Typography>
      ),
    }, {
      title: 'Status',
      field: 'calcStatus',
      fieldRenderer: (value: string) => (
        <div
          className={
            classNames(
              classes.calcStatusBox,
              getStatusColorSet(value),
            )
          }
        >
          {getStatusName(value)}
        </div>
      ),
    }],
  }, {
    title: 'Funding',
    colorSet: 'primary',
    subItems: [{
      title: 'Total Funding ($M)',
      field: 'totalFundingUsd',
      fieldRenderer: (value: string, field: string) => (
        renderCompareIndicator(value, field, 'primary')
      ),
    }, {
      title: 'Number of Rounds',
      field: 'numFundingRounds',
      fieldRenderer: (value: string, field: string) => (
        renderCompareIndicator(value, field, 'primary')
      ),
    }, {
      title: 'Number of Investors',
      field: 'numInvestors',
      fieldRenderer: (value: string, field: string) => (
        renderCompareIndicator(value, field, 'primary')
      ),
    }],
  }, {
    title: 'Web Traffic Analysis',
    colorSet: 'quaternary',
    subItems: [{
      title: 'Referral Sites',
      field: 'referralSites',
      fieldRenderer: (value: string, field: string) => (
        renderCompareIndicator(value, field, 'quaternary')
      ),
    }, {
      title: 'Monthly Visitors',
      field: 'semrushVisitsLatestMonth',
      fieldRenderer: (value: string, field: string) => (
        renderCompareIndicator(value, field, 'quaternary')
      ),
    }, {
      title: 'Monthly Trend',
      field: 'semrushVisitsMomPct',
      fieldRenderer: (value: string, field: string) => (
        renderCompareIndicator(value, field, 'quaternary', '%', 1)
      ),
    }],
  }, {
    title: 'Technologies',
    colorSet: 'tertiary',
    subItems: [{
      title: 'Patents Granted',
      field: 'patentsGranted',
      fieldRenderer: (value: string, field: string) => (
        renderCompareIndicator(value, field, 'tertiary')
      ),
    }, {
      title: 'Trade Marks',
      field: 'trademarksRegistered',
      fieldRenderer: (value: string, field: string) => (
        renderCompareIndicator(value, field, 'tertiary')
      ),
    }, {
      title: 'Web Stack',
      field: 'builtwithNumTechnologiesUsed',
      fieldRenderer: (value: string, field: string) => (
        renderCompareIndicator(value, field, 'tertiary')
      ),
    }],
  }];

  const openLandscapeReport = () => {
    const params = getCommonReportParams(companyReport);
    const landscapeReportUrl = `${reportRoutes.landscape}${params ? `?${params}` : ''}`;
    navigate(`.${landscapeReportUrl}`, { replace: true });
  };

  if (!competitorsReport) {
    return (
      <div />
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.reportContainer}>
        <div className={classes.rowHeader}>
          <Typography variant="h2" classes={{ root: classes.title }}>
            Competitor Ranking
          </Typography>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              size="medium"
              classes={commonCss.buttons.generalButton}
              className={
                classNames(
                  classes.selectCompetitorsButton,
                  'landscape',
                  'flip',
                )
              }
              onClick={() => (
                openLandscapeReport()
              )}
            >
              Select Competitors
            </Button>
          </div>
        </div>
        <div className={classes.rowBody}>
          <Grid container marginTop={2}>
            {allCompetitors.map((competitor, index) => (
              <Grid
                key={`${competitor.id}-${index}`}
                classes={{ root: classes.competitorColumn }}
                item
                container
                xs={2}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <div
                  className={
                    classNames(
                      classes.competitorColumnWrapper,
                      highlightingCompetitor?.competitor === competitor && 'highlight',
                      'clickable',
                    )
                  }
                  onClick={() => persistCompetitorHighlight(competitor)}
                  onMouseEnter={() => setCompetitorHighlight({
                    competitor,
                  })}
                  onMouseLeave={() => setCompetitorHighlight({
                    competitor: undefined,
                  })}
                >
                  <Box marginTop={0.5}>
                    <Typography
                      variant="body1"
                      className={
                        classNames(
                          classes.rankingNumber,
                          index === 0 && 'bolder',
                        )
                      }
                    >
                      <small>#</small>
                      {`${index + 1}`}
                    </Typography>
                  </Box>
                  <div>
                    <Typography
                      variant="body1"
                      className={
                        classNames(
                          classes.companyName,
                          index === 0 && 'bolder',
                        )
                      }
                    >
                      {competitor.name}
                    </Typography>
                  </div>
                  <div className={classes.companyLogoContainer}>
                    {competitor.logo?.at(0) ? (
                      <Box
                        className={commonCss.boxes.imageBox}
                        component="object"
                        data={competitor.logo?.at(0)}
                        type="image/png"
                        flexShrink={0}
                        width={75}
                        height={75}
                      >
                        <DefaultCompanyIcon />
                      </Box>
                    ) : (
                      <Box
                        className={commonCss.boxes.imageBox}
                        width={75}
                        height={75}
                      >
                        <DefaultCompanyIcon />
                      </Box>
                    )}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      {rankingReportDefs.map((def, defIndex) => (
        <div key={def.title}>
          <div
            className={
              classNames(
                classes.reportContainer,
                classes.groupHeader,
              )
            }
          >
            <div className={classes.rowHeader}>
              <Box display="flex">
                <Typography
                  variant="h6"
                  classes={{ root: classes.groupTitle }}
                  className={
                    classNames(
                      def.colorSet,
                    )
                  }
                  whiteSpace="nowrap"
                  flex={0}
                >
                  {def.title}
                </Typography>
                <Divider
                  className={
                    classNames(
                      classes.subjectHeadDivider,
                      def.colorSet,
                    )
                  }
                />
              </Box>
            </div>
            <div className={classes.rowBody}>
              <Divider
                className={
                  classNames(
                    classes.subjectHeadDivider,
                    def.colorSet,
                  )
                }
              />
              {def.fieldRenderer && (
                <Grid container>
                  {allCompetitors.map((competitor, index) => (
                    <Grid key={index} item container xs={2} justifyContent="center">
                      {def.fieldRenderer(
                        (competitor as unknown as Record<string, string>)[def.field],
                      )}
                    </Grid>
                  ))}
                </Grid>
              )}
            </div>
          </div>
          <div className={classes.groupItemsContainer}>
            {def.subItems.map((subItem) => (
              <div key={subItem.title} className={classes.reportContainer}>
                <div
                  className={
                    classNames(
                      classes.rowHeader,
                      classes.subRowHeader,
                    )
                  }
                >
                  <Typography
                    variant="body1"
                    classes={{ root: classes.subTitle }}
                    whiteSpace="nowrap"
                    flex={0}
                  >
                    {subItem.title}
                  </Typography>
                </div>
                <div className={classes.rowBody}>
                  <Grid container>
                    {allCompetitors.map((competitor, index) => (
                      <Grid
                        key={`compare-${competitor.id}-${index}`}
                        classes={{ root: classes.competitorColumn }}
                        item
                        container
                        xs={2}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <div
                          className={
                            classNames(
                              classes.competitorColumnWrapper,
                              def.colorSet,
                              index === 0 && 'emphasize',
                              highlightingCompetitor?.competitor === competitor && 'highlight',
                            )
                          }
                          // onClick={() => {
                          //   if (highlightingCompetitor?.competitor?.id !== competitor.id) {
                          //     return;
                          //   }
                          //   persistCompetitorHighlight(competitor);
                          // }}
                        >
                          {subItem.fieldRenderer(
                            (competitor as unknown as Record<string, string>)[subItem.field] || '0',
                            subItem.field,
                          )}
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>
            ))}
          </div>
          {defIndex < rankingReportDefs.length - 1 && (
            <div className={classes.groupSeparator}>
              <div className={classes.reportContainer}>
                <div
                  className={
                    classNames(
                      classes.rowHeader,
                      classes.subRowHeader,
                    )
                  }
                />
                <div className={classes.rowBody}>
                  <Grid container>
                    {allCompetitors.map((competitor, index) => (
                      <Grid
                        key={`compare-${competitor.id}-${index}`}
                        classes={{ root: classes.competitorColumn }}
                        item
                        container
                        xs={2}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <div
                          className={
                            classNames(
                              classes.competitorColumnWrapper,
                              highlightingCompetitor?.competitor === competitor && 'highlight',
                            )
                          }
                          style={{
                            height: '100%',
                          }}
                          // onClick={() => {
                          //   if (highlightingCompetitor?.competitor?.id !== competitor.id) {
                          //     return;
                          //   }
                          //   persistCompetitorHighlight(competitor);
                          // }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default RankingReportDetails;
