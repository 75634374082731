import React, { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Input,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import { jsonrepair } from 'jsonrepair';

import {
  Close as DeleteIcon,
  Check as CheckIcon,
} from '@mui/icons-material';

import ContentEditable from 'react-contenteditable';

import { ReactComponent as CaretDownIcon } from '../../../../assets/svg/caretDownIcon.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/closeIcon.svg';

import { TypingIndicator } from '../../../../components';

import {
  ChatStepInfo,
  GptChatItem,
  PlanAndExecuteInfo,
  PlanAndExecuteTask,
  PlanTaskGroup,
  StructuredContentInfo,
} from '../types';

import GptSummaryView from '../gptSummaryView';
import GptAnswerView from '../gptAnswerView';
import { getCleanPlanExecuteStructure, getLastObjective } from '../gptUtils';
import GptTasksView from '../gptTasksView';
import { PropsFromRedux } from './container';
import styles from './GptChatContentView.styles';
import { useCommonClasses } from '../../../../theme/commonStyles';
import { isRepairableJson } from '../../../../core/utils/stringUtils';

const useStyles = makeStyles(styles);
const MAX_TASKS = 5;

interface Props extends PropsFromRedux {
  chatItem: GptChatItem;
  chatItemIndex: number;
  isTasksVisible: boolean;
  userToolBox: React.ReactNode;
}

const GptChatContentView: React.FC<Props> = ({
  chats,
  chatItem,
  chatItemIndex,
  isTasksVisible,
  lastTaskObjective,
  lastTaskExecuted,
  lastTaskExecDurationInSeconds,
  relationData,
  updateLastObjective,
  userToolBox,
  updatePlanExecTask,
  updatePlanExec,
  availableTools,
  requestChatResponse,
  networkStates: {
    chatResponseRequest,
  },
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const objectiveInputRef = useRef<HTMLInputElement>(null);
  const [taskToolUpdateTask, setTaskToolUpdateTask] = useState<PlanAndExecuteTask>();
  const [toolSelectorPopupTarget, setToolSelectorPopupTarget] = useState<HTMLButtonElement>();
  const [stepHierarchyExpand, setStepHierarchyExpand] = useState<Record<number, boolean>>({});

  const hasChatContent = () => (
    chatItem.content
    || chatItem.evaluation
    || chatItem.summary
    || chatItem.error
    || chatItem.tasks
    || chatItem.structuredContent
  );

  const isTaskMaster = () => {
    if (chatItem.mode === 'customTask') {
      return true;
    }

    const hasTaskMasterAction = (
      chatItem.steps
        && chatItem.steps
          .at(chatItem.steps.length - 1)?.items.some((item) => (
            item.name === 'Action' && item.value === 'TaskMaster'
          ))
    );

    return hasTaskMasterAction;
  };

  const isSearchOnline = () => (
    (chatItem.steps || [])
      .some((step) => step.items.some((item) => (
        item.name === 'Action'
        && item.value === 'Search Online'
      )))
  );

  const getTableSources = (chatSteps?: ChatStepInfo[]) => (chatSteps || [])
    .flatMap((chatStep) => (
      chatStep.items.find((item) => item.name === 'Action')?.value
    ))
    .filter((source): source is string => !!source);

  const getTaskGroups = (tasks?: PlanAndExecuteTask[]) => {
    const taskGroups: PlanTaskGroup[] = [];
    (tasks || []).forEach((task, taskIndex) => {
      if (!taskGroups.find((tg) => tg.step === task.step_hierarchy)) {
        taskGroups.push({
          step: task.step_hierarchy,
          tasks: [],
        });
      }

      const taskGroup = taskGroups.find((tg) => tg.step === task.step_hierarchy);
      taskGroup?.tasks.push({
        ...task,
        originalTaskIndex: taskIndex,
      });
    });

    return taskGroups;
  };

  const renderGeneratedChartImages = () => {
    const chartSteps = (chatItem.steps || []).filter((step) => {
      const plotStep = step.items.find((stepItem) => stepItem.value === 'Plot Graph');
      return !!plotStep;
    });

    if (!chartSteps) {
      return undefined;
    }

    return (chartSteps || []).map((chartStep, index) => {
      const regex = /['"].+png['"]/g;
      const output = chartStep.items.find((item) => item.name === 'Output');
      let matches = regex.exec(output?.value || '');
      let fileName = matches?.at(0)?.replace(/['"]/g, '') || '';

      if (!fileName) {
        const input = chartStep.items.find((item) => item.name === 'Action Input');
        matches = regex.exec(input?.value || '');
        fileName = matches?.at(0)?.replace(/['"]/g, '') || '';
      }

      if (!fileName) {
        return (
          <React.Fragment key={index} />
        );
      }

      fileName = fileName?.replace(/['"]/g, '') || '';

      return (
        <React.Fragment key={index}>
          <img src={`https://www.pvalyou.com/api/graphimage/${fileName}`} width={550} />
        </React.Fragment>
      );
    });
  };

  const addPlanExecStep = () => {
    const planAndExecuteInfo = chatItem.structuredContent?.structured as PlanAndExecuteInfo;
    const { tasks } = (planAndExecuteInfo || {});
    const taskGroups = getTaskGroups(tasks);

    const nextStepIndex = taskGroups.length + 1;

    taskGroups.push({
      step: nextStepIndex,
      tasks: [{
        isCustomTask: true,
        originalTaskIndex: tasks.length,
        step_hierarchy: nextStepIndex,
        tool: 'Search Online',
        task: '',
        tool_input: '',
      }],
    });

    const updatedTasks: PlanAndExecuteTask[] = [];
    taskGroups.forEach((tg) => {
      tg.tasks.forEach((task) => {
        updatedTasks.push(task);
      });
    });

    updatePlanExec({
      chatItemIndex,
      planAndExec: {
        tasks: updatedTasks,
      },
    });
  };

  const addPlanExecTask = (groupIndex: number) => {
    const planAndExecuteInfo = chatItem.structuredContent?.structured as PlanAndExecuteInfo;
    const { tasks } = (planAndExecuteInfo || {});
    const taskGroups = getTaskGroups(tasks);

    taskGroups[groupIndex].tasks.push({
      isCustomTask: true,
      originalTaskIndex: tasks.length,
      step_hierarchy: groupIndex + 1,
      tool: 'Search Online',
      task: '',
      tool_input: '',
    });

    const updatedTasks: PlanAndExecuteTask[] = [];
    taskGroups.forEach((tg) => {
      tg.tasks.forEach((task) => {
        updatedTasks.push(task);
      });
    });

    updatePlanExec({
      chatItemIndex,
      planAndExec: {
        tasks: updatedTasks,
      },
    });
  };

  const removePlanExecTask = (groupIndex: number, taskIndex: number) => {
    const planAndExecuteInfo = chatItem.structuredContent?.structured as PlanAndExecuteInfo;
    const { tasks } = (planAndExecuteInfo || {});
    const taskGroups = getTaskGroups(tasks);

    taskGroups[groupIndex].tasks = (
      taskGroups[groupIndex].tasks.filter((_, index) => taskIndex !== index)
    );

    const updatedTasks: PlanAndExecuteTask[] = [];
    taskGroups.forEach((tg) => {
      tg.tasks.forEach((task) => {
        updatedTasks.push(task);
      });
    });

    updatePlanExec({
      chatItemIndex,
      planAndExec: {
        tasks: updatedTasks,
      },
    });
  };

  const toggleToolSelectorPopup = (target: HTMLButtonElement, task: PlanAndExecuteTask) => {
    setToolSelectorPopupTarget(target);
    setTaskToolUpdateTask(task);
  };

  const renderToolIcon = (tool: string) => {
    const toolData = (availableTools || []).find((t) => t.name === tool);
    if (!toolData?.icon) {
      return undefined;
    }

    return (
      <img src={toolData.icon} width={14} height={14} />
    );
  };

  const formatTaskInputDisplay = (taskInput?: string, isReadOnly?: boolean) => {
    if (!taskInput) {
      return '';
    }

    const refinedTaskInput = Array.isArray(taskInput) && taskInput.length > 0
      ? taskInput.at(0) as string
      : String(taskInput);

    if (isReadOnly) {
      const blacketFormat = refinedTaskInput.replace(/\[.*\]/g, (m: string) => (
        `<span class='bracket'>${m}</span>&nbsp;`
      ));

      const urlFormat = blacketFormat
        .replace(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)[, ]*/g,
          (m: string) => {
            const pureUrl = m.trim().endsWith(',') ? m.trim().replace(',', '') : m;
            return `<a href="${pureUrl}" class="bracket url" target="${pureUrl}">${pureUrl}</a>`;
          },
        );

      return urlFormat;
    }

    if (taskInput.includes('<span class="bracket">')) {
      return taskInput;
    }

    return taskInput.replace(/\[.*\]/g, (m: string) => (
      `<span class='bracket'>${m}</span>&nbsp;`
    ));
  };

  const renderStructuredContent = () => {
    const structuredContent = (chatItem.structuredContent || {}) as StructuredContentInfo;
    const isPlanAndExecute = 'tasks' in (structuredContent.structured || {});
    const isTaskReadOnly = (
      chatItem.isProcessing
      || !!chatItem.content?.trim()
      || !!chats.at(chatItemIndex + 1)?.content?.trim()
      || !!chatItem.exception
      || !chatItem.needPlan
    );

    const needPlanChat = chats.at(chatItemIndex + 2);
    const isTaskExecuting = (planTask: PlanAndExecuteTask) => (
      (
        !chatItem.needPlan
        && chatItem.isProcessing === true
        && !chatItem.exception
        && !chatItem.finishedTasks?.find((finishedTask) => finishedTask.task === planTask.task)
        && (
          chatItem.executingTasks?.find((executingTask) => executingTask.task === planTask.task)
          || chatItem.finishedTasks?.find(
            (finishedTask) => (
              finishedTask.step_hierarchy === planTask.step_hierarchy - 1
            ),
          )
        )
      ) || (
        chatItem.needPlan
        && needPlanChat
        && needPlanChat.isProcessing === true
        && !needPlanChat.exception
        && !needPlanChat.finishedTasks?.find((finishedTask) => finishedTask.task === planTask.task)
        && (
          needPlanChat.executingTasks?.find(
            (executingTask) => executingTask.task === planTask.task,
          )
          || needPlanChat.finishedTasks?.find(
            (finishedTask) => (
              finishedTask.step_hierarchy === planTask.step_hierarchy - 1
            ),
          )
        )
      )
    );

    const isTaskFinished = (planTask: PlanAndExecuteTask) => (
      (
        !chatItem.needPlan
        && (
          chatItem.finishedTasks?.find((finishedTask) => finishedTask.task === planTask.task)
          || !chatItem.isProcessing
        )
      ) || (
        chatItem.needPlan
        && needPlanChat
        && (
          needPlanChat.finishedTasks?.find((finishedTask) => finishedTask.task === planTask.task)
          || !needPlanChat?.isProcessing
        )
      )
    );

    const isTaskFinishedWithException = (planTask: PlanAndExecuteTask) => {
      if (!chatItem.needPlan) {
        const finishedTask = chatItem.finishedTasks?.find((ft) => (
          ft.task === planTask.task
          && ft.tool_input === planTask.tool_input
          && ft.step_hierarchy === planTask.step_hierarchy
        ));

        if (!finishedTask?.tool_output) {
          return true;
        }

        return ((
          typeof finishedTask.tool_output === 'object'
          && ('exception' in finishedTask.tool_output)
        ) || (
          typeof finishedTask.tool_output === 'string'
          && isRepairableJson(finishedTask.tool_output)
          && ('exception' in JSON.parse(jsonrepair(finishedTask.tool_output)))
        ));
      }

      if (!needPlanChat) {
        return true;
      }

      const finishedTask = needPlanChat.finishedTasks?.find((ft) => (
        ft.task === planTask.task
        && ft.tool_input === planTask.tool_input
        && ft.step_hierarchy === planTask.step_hierarchy
      ));

      if (!finishedTask?.tool_output) {
        return true;
      }

      return ((
        typeof finishedTask.tool_output === 'object'
        && ('exception' in finishedTask.tool_output)
      ) || (
        typeof finishedTask.tool_output === 'string'
        && isRepairableJson(finishedTask.tool_output)
        && ('exception' in JSON.parse(jsonrepair(finishedTask.tool_output)))
      ));
    };

    if (isPlanAndExecute) {
      const planAndExecuteInfo = chatItem.structuredContent?.structured as PlanAndExecuteInfo;
      const { tasks } = (planAndExecuteInfo || {});

      const chatFinishedTasks = chatItem.needPlan
        ? (needPlanChat?.finishedTasks || [])
        : (chatItem?.finishedTasks || []);

      const chatExecutingTasks = chatItem.needPlan
        ? (needPlanChat?.executingTasks || [])
        : (chatItem?.executingTasks || []);

      const allSteps = Array.from(new Set((tasks || []).map((task) => task.step_hierarchy)));

      const refinedTasks = allSteps.flatMap((step) => {
        const originalTasks = (tasks || []).filter((t) => t.step_hierarchy === step);

        const executingTasks = (chatExecutingTasks || [])
          .filter((et) => (
            et.step_hierarchy === step
          ));

        const finishedTasks = (chatFinishedTasks || [])
          .filter((ft) => (
            ft.step_hierarchy === step
          ));

        if (finishedTasks.length > 0) {
          return finishedTasks;
        }

        if (executingTasks.length > 0) {
          return executingTasks;
        }

        return originalTasks;
      });

      const taskGroups = getTaskGroups(refinedTasks);

      const additionalTasks = (chatExecutingTasks || []).filter((executingTask) => (
        !tasks.find((t) => (
          t.task === executingTask.task
          && t.step_hierarchy === executingTask.step_hierarchy
        ))
      ));

      if (additionalTasks.length) {
        taskGroups.push({
          step: taskGroups.length + 1,
          tasks: additionalTasks,
        });
      }

      return (
        <Box display="flex" flexDirection="column" flex={1}>
          {structuredContent.preUnstructured && (
            <Box marginBottom={2}>
              <GptAnswerView
                chatIndex={chatItemIndex}
                answerViewId={`pre-answerView-${chatItemIndex}`}
                answerMarkdown={structuredContent.preUnstructured}
              />
            </Box>
          )}
          {taskGroups.map((taskGroup, groupIndex) => (
            <Box key={groupIndex} display="flex" flex={1}>
              <Box flex={0} flexBasis={24} paddingTop={0.5} marginRight={1} />
              <Box flex={0} display="flex" flexDirection="column" marginRight={4}>
                <Typography variant="caption" whiteSpace="nowrap" className={classes.taskStep}>
                  STEP {taskGroup.step}
                </Typography>
                {(groupIndex < taskGroups.length - 1 || !isTaskReadOnly) && (
                  <div className={classes.taskStepTail} />
                )}
              </Box>
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                className={
                  classNames(
                    classes.taskGroup,
                    isTaskReadOnly && 'readOnly',
                  )
                }
              >
                {taskGroup.tasks
                  .slice(
                    0,
                    (stepHierarchyExpand[taskGroup.step] || !isTaskReadOnly)
                      ? undefined
                      : MAX_TASKS,
                  )
                  .map((task, index) => (
                    <Box className={classes.taskItem} key={index} display="flex" columnGap={2} flex={1}>
                      {!isTaskReadOnly && (
                        <Box className={classes.taskItemButtonBox}>
                          <span
                            className={classes.taskItemButton}
                            onClick={() => removePlanExecTask(groupIndex, index)}
                          >
                            <DeleteIcon fontSize="small" />
                          </span>
                        </Box>
                      )}
                      {isTaskReadOnly && isTaskExecuting(task) && (
                        <Box
                          className={classNames(
                            classes.taskItemButtonBox,
                            'progressIndicator',
                          )}
                        >
                          <span
                            className={classes.taskItemButton}
                          >
                            <CircularProgress color="inherit" size={20} />
                          </span>
                        </Box>
                      )}
                      {isTaskReadOnly && isTaskFinished(task) && (
                        <Box
                          className={classNames(
                            classes.taskItemButtonBox,
                            'finishedIndicator',
                          )}
                        >
                          {!isTaskFinishedWithException(task) ? (
                            <span
                              className={classes.taskItemButton}
                            >
                              <CheckIcon color="inherit" fontSize="small" />
                            </span>
                          ) : (
                            <span
                              className={classes.taskItemButton}
                            >
                              <CloseIcon color="inherit" fontSize="small" />
                            </span>
                          )}
                        </Box>
                      )}
                      <Box display="flex" flexDirection="column" alignItems="flex-start">
                        {task.tool && (
                          <>
                            {!isTaskReadOnly ? (
                              <Button
                                classes={commonCss.buttons.roundButton}
                                className={classes.taskTool}
                                size="small"
                                variant="outlined"
                                color="info"
                                onClick={(event) => (
                                  toggleToolSelectorPopup(event.currentTarget, task)
                                )}
                              >
                                {renderToolIcon(task.tool)}
                                {task.tool}
                                <CaretDownIcon />
                              </Button>
                            ) : (
                              <div
                                className={classes.taskTool}
                              >
                                {renderToolIcon(task.tool)}
                                {task.tool}
                              </div>
                            )}
                          </>
                        )}
                        {(index < taskGroup.tasks.length - 1 || !isTaskReadOnly) && (
                          <div className={classes.taskTail} />
                        )}
                      </Box>
                      <Box paddingTop={0.25} flex={1} marginBottom={2}>
                        <ContentEditable
                          className={
                            classNames(
                              classes.taskInputArea,
                              !isTaskReadOnly && 'editMode',
                            )
                          }
                          placeholder="Type to edit task input"
                          tagName="span"
                          disabled={isTaskReadOnly}
                          onChange={(event) => {
                            updatePlanExecTask({
                              ...task,
                              chatItemIndex,
                              taskIndex: task.originalTaskIndex,
                              toolInput: event.target.value || '',
                            });
                          }}
                          html={formatTaskInputDisplay(task.tool_input || '', isTaskReadOnly)}
                          onPaste={(event) => {
                            event.preventDefault();
                            event.stopPropagation();

                            const pasteContent = event.clipboardData.getData('text');

                            const selection = window.getSelection();
                            if (selection) {
                              const range = selection.getRangeAt(0);
                              selection.deleteFromDocument();
                              range.insertNode(document.createTextNode(pasteContent));
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
                {isTaskReadOnly && taskGroup.tasks.length > MAX_TASKS && (
                  <Box marginLeft={2}>
                    <Link
                      className={classes.addItemLink}
                      onClick={() => {
                        setStepHierarchyExpand({
                          ...stepHierarchyExpand,
                          [taskGroup.step]: !stepHierarchyExpand[taskGroup.step],
                        });
                      }}
                    >
                      {stepHierarchyExpand[taskGroup.step] ? 'Show Less' : 'Show More'}
                    </Link>
                  </Box>
                )}
                {!isTaskReadOnly ? (
                  <Box marginLeft={2}>
                    <Link
                      className={classes.addItemLink}
                      onClick={() => addPlanExecTask(groupIndex)}
                    >
                      + Add a task
                    </Link>
                  </Box>
                ) : (
                  <Box marginBottom={groupIndex < taskGroups.length - 1 ? 2 : 0} />
                )}
              </Box>
            </Box>
          ))}
          {!isTaskReadOnly && (
            <Box marginLeft={6}>
              <Link
                className={classes.addItemLink}
                onClick={() => addPlanExecStep()}
              >
                + Add a step
              </Link>
            </Box>
          )}
          {chatItem.structuredContent
            && !chatItem.isProcessing
            && (chatItemIndex === chats.length - 1)
            && !chatResponseRequest.isRequesting
            && !chatItem.exception
            && chatItem.needPlan
            ? (
              <Box marginTop={2} marginLeft={5.5}>
                <Button
                  classes={commonCss.buttons.roundButton}
                  className={
                    classNames(
                      classes.executeButton,
                    )
                  }
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    const noneHtmlStructure = getCleanPlanExecuteStructure(
                      chatItem.structuredContent?.structured as PlanAndExecuteInfo,
                    );

                    requestChatResponse({
                      input: JSON.stringify(noneHtmlStructure),
                      isExecutePlan: true,
                      llm: chatItem.llmModel,
                      // isTest: true,
                    });
                  }}
                  disabled={chatResponseRequest.isRequesting}
                >
                  {!!chatResponseRequest.isRequesting && (
                    <Box marginRight={1}>
                      <CircularProgress color="inherit" size={12} />
                    </Box>
                  )}
                  Execute
                </Button>
              </Box>
            ) : undefined}
          {!isTaskReadOnly && (
            <Divider className={classes.executeOnlyDivider} />
          )}
        </Box>
      );
    }

    return (
      <div />
    );
  };

  const renderChatAnswer = () => (
    <>
      {chatItem.structuredContent && chatItem.isShowingSteps && (
        <>
          <Box width="100%" marginTop={1} marginBottom={2}>
            {renderStructuredContent()}
          </Box>
          {(chatItem.content || chats.at(chatItemIndex + 1)?.content) && (
            <Box width="100%" marginBottom={2} />
          )}
        </>
      )}
      <GptAnswerView
        chatIndex={chatItemIndex}
        answerViewId={`answerView-${chatItemIndex}`}
        answerMarkdown={chatItem.content}
        answerSources={chatItem.sources}
        relationEntities={relationData?.nodes}
        answerMode={isSearchOnline() ? 'search' : undefined}
        omitEmbededSources
        inlineCharts={chatItem.inlineCharts}
        isProcessing={chatItem.isProcessing}
        tableSources={getTableSources(chatItem.steps)}
      />
    </>
  );

  return (
    <>
      {(hasChatContent()) ? (
        <Box
          className={
            classNames(
              classes.gptChatItem,
              chatItem.type,
              chatItem.type === 'user' ? 'secondary' : 'tertiary',
              chatItem.error && 'error',
            )
          }
        >
          {chatItem.error
            ? (
              `${chatItem.error.message}`
            )
            : (
              <>
                {chatItem.type === 'bot' && (
                  <>
                    {chatItem.isProcessing || !isTaskMaster() ? (
                      <>
                        {chatItem.mode === 'task' && (
                          <div className={classes.taskInput}>
                            <div>
                              {Math.round((chatItemIndex - 1) / 2)}
                            </div>
                            <div>
                              {chats[chatItemIndex - 1].content}
                            </div>
                          </div>
                        )}
                        {chatItem.mode === 'summary' ? (
                          <Box marginTop={1}>
                            <GptSummaryView
                              answerViewId={`answerView-${chatItemIndex}`}
                              chatItem={chatItem}
                              objective={lastTaskObjective}
                              lastExecuted={lastTaskExecuted}
                              evaluationSeconds={lastTaskExecDurationInSeconds}
                            />
                          </Box>
                        ) : (
                          <>
                            {renderChatAnswer()}
                          </>
                        )}
                        {chatItem.isProcessing && (
                          <Box display="flex" height={40} alignItems="center">
                            <TypingIndicator />
                          </Box>
                        )}
                        {!chatItem.isProcessing && (
                          <>
                            {renderGeneratedChartImages()}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Box marginTop={4} display="flex" columnGap={1} justifyContent="center">
                          <Typography className={classes.objective} variant="body1">
                            Objective:
                          </Typography>
                          <Box maxWidth="calc(100% - 100px)">
                            <Input
                              ref={objectiveInputRef}
                              className={classes.objectiveInput}
                              inputProps={{
                                className: classes.objectiveInputElement,
                                size: ((getLastObjective(chatItem) || '').length || 12) + 1,
                              }}
                              value={getLastObjective(chatItem) || ''}
                              onChange={(event) => updateLastObjective({
                                index: chatItemIndex,
                                objective: event.target.value,
                              })}
                              placeholder={
                                (getLastObjective(chatItem) || '').length > 0
                                  ? ''
                                  : 'Enter objective'
                              }
                            />
                          </Box>
                        </Box>
                        {isTasksVisible && (
                          <GptTasksView
                            chatIndex={chatItemIndex}
                            chatItem={chatItem}
                            objective={getLastObjective(chatItem)}
                            mode={chatItem.mode === 'customTask' ? 'deepSearch' : undefined}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
                {chatItem.type === 'user' && (
                  <Box display="flex" justifyContent="space-between" justifyItems="center" height="100%">
                    <Box className={classes.gptChatItemUserQuestion} dir="auto">
                      <Typography variant="body2" className={classes.userQuestion}>
                        {chatItem.content}
                      </Typography>
                    </Box>
                    <Box
                      justifySelf="flex-end"
                      className={classes.gptChatItemUserToolsBox}
                    >
                      {userToolBox}
                    </Box>
                  </Box>
                )}
              </>
            )
          }
        </Box>
      ) : (
        <>
          {chatItem.isProcessing && (
            <Box marginLeft={2} display="flex" height={40} alignItems="center">
              <TypingIndicator />
            </Box>
          )}
        </>
      )}
      <Popover
        open={!!toolSelectorPopupTarget}
        anchorEl={toolSelectorPopupTarget}
        onClose={() => setToolSelectorPopupTarget(undefined)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        classes={{
          paper: classes.compareOutputPopup,
        }}
      >
        <MenuList>
          {(availableTools || [])
            .map((tool) => (
              <MenuItem
                key={tool.name}
                onClick={() => {
                  setToolSelectorPopupTarget(undefined);
                  if (taskToolUpdateTask?.originalTaskIndex !== undefined) {
                    updatePlanExecTask({
                      chatItemIndex,
                      tool: tool.name,
                      taskIndex: taskToolUpdateTask.originalTaskIndex,
                      task: taskToolUpdateTask.task,
                      toolInput: taskToolUpdateTask.tool_input,
                    });
                  }
                }}
              >
                <ListItemIcon>
                  {renderToolIcon(tool.name)}
                </ListItemIcon>
                <ListItemText>
                  {tool.name}
                </ListItemText>
              </MenuItem>
            ))}
        </MenuList>
      </Popover>
    </>
  );
};

export default GptChatContentView;
