import sessionReducer from './features/session/reducer';
import reportReducer from './features/report/reducer';
import notifierReducer from './features/notifier/reducer';
import autocompleteReducer from './features/autocomplete/reducer';
import searchReducer from './features/search/reducer';
import manualFormReducer from './features/manualForm/reducer';
import mainReducer from './features/main/reducer';
import gptReducer from './features/gpt/reducer';
import relationChartReducer from './features/relationChart/reducer';

export default {
  session: sessionReducer,
  notifier: notifierReducer,
  main: mainReducer,
  autocomplete: autocompleteReducer,
  search: searchReducer,
  manualForm: manualFormReducer,
  report: reportReducer,
  gpt: gptReducer,
  relationChart: relationChartReducer,
};
