import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Container,
  Divider,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { motion } from 'framer-motion';

import { ReactComponent as DefaultCompanyIcon } from '../../../../assets/svg/defaultCompanyIcon.svg';
import { ReactComponent as CaretRightIcon } from '../../../../assets/svg/caretRightIcon.svg';
import { ReactComponent as CaretLeftIcon } from '../../../../assets/svg/caretLeftIcon.svg';
import { isNullOrUndefined } from '../../../../core/utils/dataUtils';
// import { forLast } from '../../../../core/utils/performanceUtils';
import { useCommonClasses } from '../../../../theme/commonStyles';
import { getGlossaryLinkMarkdown } from '../../../../core/utils/stringUtils';
import { CompanyItem, GptSearchResult } from '../types';
import MarketItemCard from '../marketItemCard';
import CompanyList from '../companyList';
import MarketList from '../marketList';
import GlossaryList from '../glossaryList';
import { getGptResultAnswer } from '../searchUtils';
import { PropsFromRedux } from './container';
import styles from './GptResults.styles';

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
require('highcharts/modules/wordcloud')(Highcharts);

interface Props extends PropsFromRedux {
  onCompanyItemClick?: (companyItem: CompanyItem) => void;
  onCompanySubReportClick?: (companyItem: CompanyItem, report: string) => void;
}

interface WordWeight {
  weight: number;
  name: string;
}

const GLOSSARY_BUBBLE_TIMEOUT = 1000;
const MARKET_CHART_HEIGHT = 200;
const useStyles = makeStyles(styles);

const GptResults: React.FC<Props> = ({
  gptSearchResults,
  onCompanyItemClick,
  onCompanySubReportClick,
  openGlossaryBubble,
  setSearchKeyword,
  toggleGptSearch,
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const theme = useTheme();
  const [showMore, setShowMore] = useState<string>();
  const [relatedCompanyHighlight, setRelatedCompanyHighlight] = useState<string>();
  const [gptTypedAnswer, setGptTypedAnswer] = useState('');
  const [gptAnswerTime, setGptAnswerTime] = useState<Date>();
  const glossaryBubbleTimeoutRef = useRef<NodeJS.Timeout | undefined>();
  const gptTypingIntervalRef = useRef<NodeJS.Timeout | undefined>();

  const wordCloudChartWidth = 850;
  const wordCloudChartHeight = 300;

  const answer = getGptResultAnswer(gptSearchResults);
  const answers = answer.split('');

  const isGptAnswerCompleted = gptTypedAnswer.length >= answers.length;

  useEffect(() => {
    setGptTypedAnswer('');
    setGptAnswerTime(new Date());
  }, [gptSearchResults]);

  useEffect(() => {
    if (gptTypingIntervalRef.current) {
      clearInterval(gptTypingIntervalRef.current);
      gptTypingIntervalRef.current = undefined;
    }

    const intervalRef = setTimeout(() => {
      if (gptTypedAnswer.length > answers.length) {
        clearTimeout(gptTypingIntervalRef.current);
        return;
      }

      const addText = answers.slice(
        gptTypedAnswer.length,
        gptTypedAnswer.length + 5,
      ).join('');

      setGptTypedAnswer(`${gptTypedAnswer}${addText}`);
    }, 1);

    gptTypingIntervalRef.current = intervalRef;

    return () => {
      clearTimeout(gptTypingIntervalRef.current);
    };
  }, [gptTypedAnswer, gptAnswerTime]);

  const determineOpenGlossaryBubble = (rect: DOMRect, phrase: string) => {
    if (glossaryBubbleTimeoutRef.current) {
      clearTimeout(glossaryBubbleTimeoutRef.current);
      glossaryBubbleTimeoutRef.current = undefined;
    }

    const timeoutRef = setTimeout(() => {
      openGlossaryBubble({
        originX: rect.left + (rect.width / 2),
        originY: rect.top,
        phrase,
      });

      glossaryBubbleTimeoutRef.current = undefined;
    }, GLOSSARY_BUBBLE_TIMEOUT);

    glossaryBubbleTimeoutRef.current = timeoutRef;
  };

  const cancelGlossaryBubble = () => {
    if (glossaryBubbleTimeoutRef.current) {
      clearTimeout(glossaryBubbleTimeoutRef.current);
      glossaryBubbleTimeoutRef.current = undefined;
    }
  };

  const renderAnswer = (result: GptSearchResult) => {
    const companyHighlights = (result?.companies || [])
      .filter((c) => isNullOrUndefined(c.score))
      .flatMap((c) => [c.name]);

    const highlights: string[] = Array.from(new Set([
      ...(result?.exactWords || []).flatMap((w) => [w.term]),
      ...(result?.similiarWords || []).flatMap((w) => [w.term]),
      ...companyHighlights,
    ]));

    const processedAnswer = getGlossaryLinkMarkdown(gptTypedAnswer, highlights);

    return !showMore && (
      <Box className={classes.answerContainer}>
        <ReactMarkdown
          children={processedAnswer}
          remarkPlugins={[remarkGfm]}
          components={{
            a: (props) => {
              if (props?.href?.startsWith('/glossary#')) {
                const target = props?.href?.replace('/glossary#', '');
                const text = decodeURIComponent(target);

                if (companyHighlights.includes(text)) {
                  return (
                    <Link
                      type="button"
                      className={classes.companyHighlight}
                      onMouseEnter={() => setRelatedCompanyHighlight(text)}
                      onMouseLeave={() => setRelatedCompanyHighlight(undefined)}
                      onMouseUp={() => {
                        const companyItem = result.companies?.find(
                          (c) => c.name === text,
                        );

                        if (companyItem && onCompanyItemClick) {
                          onCompanyItemClick(companyItem);
                        }

                        setRelatedCompanyHighlight(undefined);
                      }}
                    >
                      {text}
                    </Link>
                  );
                }

                return (
                  <Link
                    type="button"
                    className={classes.answerHighlight}
                    onMouseUp={() => {
                      toggleGptSearch({ isGptSearch: false });
                      setSearchKeyword({
                        keyword: `"${props?.children as string}"`,
                      });
                      cancelGlossaryBubble();
                    }}
                    onMouseEnter={(event) => {
                      return;

                      const rect = event.currentTarget.getBoundingClientRect();
                      determineOpenGlossaryBubble(rect, props?.children as string);
                    }}
                    onMouseLeave={() => cancelGlossaryBubble()}
                  >
                    {props?.children}
                  </Link>
                );
              }

              return (
                <a href={props.href}>{props.children}</a>
              );
            },
          }}
        />
      </Box>
    );
  };

  const renderRelatedCompanies = (result: GptSearchResult) => {
    const companies = (result.companies || []).filter((company) => !!company.id);

    if (showMore && showMore !== 'companies') {
      return <div />;
    }

    return companies.length > 0 && (
      <Box marginTop={showMore ? 0 : 4}>
        <Box className={classes.sectionHeader}>
          <Typography
            variant="h6"
            className={
              classNames(
                classes.sectionHeaderText,
                'secondary',
              )
            }
          >
            Related Companies
          </Typography>
          <Divider
            className={
              classNames(
                classes.sectionHeaderDivider,
                'secondary',
              )
            }
          />
          <Button
            variant="outlined"
            size="small"
            className={
              classNames(
                classes.sectionHeaderButton,
                'secondary',
              )
            }
            onClick={() => setShowMore(!showMore ? 'companies' : undefined)}
          >
            {showMore && (
              <Box marginRight={1} display="flex" alignItems="center">
                <CaretLeftIcon />
              </Box>
            )}
            Show {!showMore ? 'more' : 'less'}
            {!showMore && (
              <Box marginLeft={1} display="flex" alignItems="center">
                <CaretRightIcon />
              </Box>
            )}
          </Button>
        </Box>
        {!showMore && (
          <Box className={classes.companyItemsContainer}>
            {companies.slice(0, 8).map((company) => (
              <div
                key={company.id}
                className={
                  classNames(
                    classes.companyItem,
                    !company.score && 'exactMatched',
                    relatedCompanyHighlight === company.name && 'active',
                  )
                }
                onClick={() => onCompanyItemClick && onCompanyItemClick(company)}
              >
                {company.logo?.at(0) ? (
                  <Box
                    className={
                      classNames(
                        commonCss.boxes.imageBox,
                        'logo',
                      )
                    }
                    component="object"
                    data={company.logo?.at(0)}
                    type="image/png"
                    width={75}
                    height={75}
                  >
                    <DefaultCompanyIcon />
                  </Box>
                ) : (
                  <Box
                    className={
                      classNames(
                        commonCss.boxes.imageBox,
                        'logo',
                      )
                    }
                    width={75}
                    height={75}
                  >
                    <DefaultCompanyIcon />
                  </Box>
                )}
                <Box marginTop={1} maxWidth={80}>
                  <Typography variant="body2" textAlign="center" className="name">
                    {company.name}
                  </Typography>
                </Box>
              </div>
            ))}
          </Box>
        )}
        {showMore && (
          <CompanyList
            companyItems={companies}
            onDataClick={onCompanyItemClick}
            onSubReportClick={onCompanySubReportClick}
          />
        )}
      </Box>
    );
  };

  const renderMarketInquiries = (result: GptSearchResult) => {
    const firstMarket = result.markets?.at(0);

    if (showMore && showMore !== 'markets') {
      return <div />;
    }

    return firstMarket && (
      <Box marginTop={showMore ? 0 : 4}>
        <Box className={classes.sectionHeader}>
          <Typography
            variant="h6"
            className={
              classNames(
                classes.sectionHeaderText,
                'primary',
              )
            }
          >
            Industry Statistics
          </Typography>
          <Divider
            className={
              classNames(
                classes.sectionHeaderDivider,
                'primary',
              )
            }
          />
          <Button
            variant="outlined"
            size="small"
            className={
              classNames(
                classes.sectionHeaderButton,
                'primary',
              )
            }
            onClick={() => setShowMore(!showMore ? 'markets' : undefined)}
          >
            {showMore && (
              <Box marginRight={1} display="flex" alignItems="center">
                <CaretLeftIcon />
              </Box>
            )}
            Show {!showMore ? 'more' : 'less'}
            {!showMore && (
              <Box marginLeft={1} display="flex" alignItems="center">
                <CaretRightIcon />
              </Box>
            )}
          </Button>
        </Box>
        {!showMore && (
          <Box marginTop={2} className={classes.marketChartContainer}>
            <MarketItemCard
              marketItem={firstMarket}
              expanded
              showHighlights={false}
              chartHeight={MARKET_CHART_HEIGHT}
            />
          </Box>
        )}
        {showMore && (
          <MarketList
            marketItems={result.markets}
            totalItemsInPage={3}
          />
        )}
      </Box>
    );
  };

  const renderGlossaries = (result: GptSearchResult) => {
    const wordCloud = result?.wordCloud || '';
    const glossaryItems = [
      ...result?.exactWords || [],
      ...result?.similiarWords || [],
    ];

    if (showMore && showMore !== 'glossaries') {
      return <div />;
    }

    const lines = wordCloud
      .replace(/[():'?0-9]+/g, '')
      .split(/[,. ]+/g)
      .filter((l) => l.length <= 50);

    const data = lines.reduce((arr, word) => {
      let obj = Highcharts.find(arr, (w: WordWeight) => w.name === word);
      if (obj) {
        obj.weight += 1;
      } else {
        obj = {
          name: word,
          weight: 1,
        };
        arr.push(obj);
      }
      return arr;
    }, [] as WordWeight[]);

    const wordCloudChartOptions = {
      chart: {
        width: wordCloudChartWidth,
        height: wordCloudChartHeight,
        backgroundColor: theme.palette.background.paper,
      },
      series: [{
        type: 'wordcloud',
        data,
        name: 'Occurrences',
        colors: ['#a2d8e8', '#4e95aa', '#393939'],
      }],
      title: {
        text: '',
        align: 'left',
      },
      subtitle: {
        text: '',
        align: 'left',
      },
      tooltip: {
        headerFormat: '<span style="font-size: 16px"><b>{point.key}</b></span><br>',
      },
      accessibility: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    } as Highcharts.Options;

    return wordCloud && (
      <Box marginTop={showMore ? 0 : 4}>
        <Box className={classes.sectionHeader}>
          <Typography
            variant="h6"
            className={
              classNames(
                classes.sectionHeaderText,
                'quaternary',
              )
            }
          >
            Glossary
          </Typography>
          <Divider
            className={
              classNames(
                classes.sectionHeaderDivider,
                'quaternary',
              )
            }
          />
          <Button
            variant="outlined"
            size="small"
            className={
              classNames(
                classes.sectionHeaderButton,
                'quaternary',
              )
            }
            onClick={() => setShowMore(!showMore ? 'glossaries' : undefined)}
          >
            {showMore && (
              <Box marginRight={1} display="flex" alignItems="center">
                <CaretLeftIcon />
              </Box>
            )}
            Show {!showMore ? 'more' : 'less'}
            {!showMore && (
              <Box marginLeft={1} display="flex" alignItems="center">
                <CaretRightIcon />
              </Box>
            )}
          </Button>
        </Box>
        {!showMore && (
          <Box
            marginTop={2}
            className={classes.wordCloudChartArea}
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={wordCloudChartOptions}
            />
          </Box>
        )}
        {showMore && (
          <GlossaryList
            items={glossaryItems}
            totalItemsInPage={4}
          />
        )}
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <Container>
        <Box
          className={
            classNames(
              classes.resultPanel,
            )
          }
          marginTop={0}
        >
          <Box className={classes.resultBody}>
            {gptSearchResults && Array.isArray(gptSearchResults)
              && (gptSearchResults || []).map((result, index) => (
                <React.Fragment key={index}>
                  {renderAnswer(result)}
                  {isGptAnswerCompleted && (
                    <>
                      <motion.div
                        initial={{
                          x: -100,
                          opacity: 0,
                        }}
                        animate={{
                          x: 0,
                          opacity: 1,
                        }}
                      >
                        {renderRelatedCompanies(result)}
                      </motion.div>
                      <motion.div
                        initial={{
                          x: -100,
                          opacity: 0,
                        }}
                        animate={{
                          x: 0,
                          opacity: 1,
                          transition: {
                            delay: 0.5,
                          },
                        }}
                      >
                        {renderMarketInquiries(result)}
                      </motion.div>
                      <motion.div
                        initial={{
                          x: -100,
                          opacity: 0,
                        }}
                        animate={{
                          x: 0,
                          opacity: 1,
                          transition: {
                            delay: 1,
                          },
                        }}
                      >
                        {renderGlossaries(result)}
                      </motion.div>
                    </>
                  )}
                </React.Fragment>
              ))}

            {typeof gptSearchResults === 'string' && (
              <Typography variant="body2" textAlign="center">
                An error occurs. Please try again later.
              </Typography>
            )}
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default GptResults;
