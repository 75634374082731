import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Popover,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  ThemeProvider,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { Base64 } from 'js-base64';
import { v4 } from 'uuid';
import { motion } from 'framer-motion';

import {
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';

import { useResizeDetector } from 'react-resize-detector';

import { ReactComponent as PvalyouBotIcon } from '../../../../assets/svg/pvalyouBotIcon.svg';
import { ReactComponent as ChatUserIcon } from '../../../../assets/svg/chatUserIcon.svg';
import { ReactComponent as CaretUpIcon } from '../../../../assets/svg/caretUpIcon.svg';
import { ReactComponent as DefaultCompanyIcon } from '../../../../assets/svg/defaultCompanyIcon.svg';
import { ReactComponent as CompareOutputsIcon } from '../../../../assets/svg/compareOutputsIcon.svg';
import { ReactComponent as BookmarkIcon } from '../../../../assets/svg/bookmarkIcon.svg';
import { ReactComponent as ReportProblemIcon } from '../../../../assets/svg/reportProblemIcon.svg';
import { ReactComponent as ProceedIcon } from '../../../../assets/svg/proceedIcon.svg';
import { ReactComponent as ShowGraphIcon } from '../../../../assets/svg/showGraphIcon.svg';

import { useCommonClasses } from '../../../../theme/commonStyles';

import {
  ChatFileInfo,
  ChatResponseException,
  GptChatItem,
  GptModel,
  SourceInfo,
} from '../types';

import { PropsFromRedux } from './container';

import styles from './GptChatItemView.styles';
import { createDataItems, extractSourcesFromContent } from '../gptUtils';

import { DataTableHeader, DataTableRow } from '../../../../core/types/dataTable';
import { dataGridTheme } from '../../../../theme/mui';
import DataTable from '../../../../components/dataTable/DataTable';
import QuickChart from '../../../../components/quickChart';
import { QuickChartUpdateInfo } from '../../../../core/types/quickChart';
import ContentView from '../contentView';
import { CompanyItem } from '../../search/types';
import { CompanyData } from '../../report/types';
import { getRelatedCompanyLogo } from '../../../../core/utils/uxUtils';
import CompanyList from '../../search/companyList';
import GptChatStepsView from '../gptChatStepsView';
import GptChatContentView from '../gptChatContentView';
import { createTableMarkdown } from '../../../../core/utils/stringUtils';

interface Props extends PropsFromRedux {
  chatItem: GptChatItem;
  chatItemIndex: number;
  rootWidth?: number;
  hideIcon?: boolean;
}

interface FaqRepInfo {
  sourceInfo: SourceInfo;
  question: string;
  answers: FaqAnswerInfo[];
}

interface FaqAnswerInfo {
  index: number;
  answerType: string;
  content: string;
}

const useStyles = makeStyles(styles);

const MAX_RELATED_COMPANITES_COLLAPSED = 14;
const SOURCE_BUBBLE_TIMEOUT = 0;
const MAX_FAQS = 5;

const GptChatItemView: React.FC<Props> = ({
  chats,
  chatItem,
  chatItemIndex,
  rootWidth,
  llmChatItemsByIndexes,
  gptModels,
  toggleChatItemStep,
  userExtraInfo,
  openedReports,
  user,
  toggleChatItemTable,
  duplicateChatItemChart,
  requestUpdateChatItemChart,
  toggleChatItemSource,
  toggleChatItemFaqs,
  prepareDeepSearchTasks,
  toggleChatItemOrgs,
  openMessageBox,
  openCompanyReport,
  requestCreateChatPrompt,
  applyChatOutputsCompare,
  selectChatResponseModel,
  openSourceBubble,
  requestChatResponse,
  requestTableToChart,
  removeChatItemChart,
  undoChatItemChartChange,
  networkStates: {
    chatItemChartUpdateRequest,
    chatResponseRequest,
    tableToChartRequest,
  },
  hideIcon,
}: Props) => {
  const answerViewId = `answerView-${chatItemIndex}`;

  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();

  const [isTasksVisible, setTaskVisible] = useState(true);
  const [menuButton, setMenuButton] = useState<HTMLButtonElement>();
  const [menuChatItem, setMenuChatItem] = useState<GptChatItem>();
  const [showAllSources, setShowAllSources] = useState(false);
  const [compareOutputPopupTarget, setCompareOutputPopupTarget] = useState<HTMLSpanElement>();
  const [expandingFaqs, setExpandingFaqs] = useState<string[]>();
  const [isShowMoreFaqs, setShowMoreFaqs] = useState(false);

  const sourceBubbleTimeoutRef = useRef<NodeJS.Timeout | undefined>();

  const chartUpdateRequestArgs = chatItemChartUpdateRequest.extra as Record<string, number>;

  const { width: attachedChartWidth, ref: attachedChartRef } = useResizeDetector();

  const chatOutputSelectionInfo = (llmChatItemsByIndexes || {})[chatItemIndex];

  const userPermLevel = userExtraInfo?.results?.at(0)?.permLevel || 0;
  const actualSources = (chatItem.sources || []).length > 0
    ? chatItem.sources
    : extractSourcesFromContent(chatItem.content);

  const faqSources = chatItem.sources?.filter(
    (s) => s.metadata?.question && s.metadata?.answerType,
  );

  useEffect(() => {
    setShowAllSources(false);
    setExpandingFaqs([]);
    setShowMoreFaqs(false);
  }, [chats]);

  const getGptModelName = (modelInput: string) => {
    const targetModel = gptModels?.find((model) => model.modelInput === modelInput);
    return targetModel?.modelName || modelInput;
  };

  const hideAndShowTasks = () => {
    // Workaround to prevent translate animation of task list.
    setTaskVisible(false);
    setTimeout(() => {
      setTaskVisible(true);
    }, 0);
  };

  const hasChatOutputsSelection = (chatOutputSelectionInfo?.chatByEngines || []).length > 0;

  const openReport = (companyItem: CompanyItem, report?: string) => {
    const companyData = {
      orgLogo: companyItem.logo,
      orgName: companyItem.name,
      orgKeywords: (companyItem.keywords || '').split(','),
      orgCountry: companyItem.countryIso,
      orgDesc: companyItem.description,
      orgFoundingYear: companyItem.foundingYear,
      orgIndustry: companyItem.industry,
      orgVertical: companyItem.vertical,
      orgState: companyItem.city,
    } as CompanyData;

    openCompanyReport({
      companyData,
      companyId: String(companyItem.id),
      hasScore: companyItem.report,
      report,
    });
  };

  const isSearchOnline = () => (
    (chatItem.steps || [])
      .some((step) => step.items.some((item) => (
        item.name === 'Action'
        && item.value === 'Search Online'
      )))
  );

  const hasOtherSections = () => !!(
    (chatItem.files || []).length > 0
    || (actualSources || []).length > 0
    || (faqSources || []).length > 0
    || (chatItem.orgs || []).length > 0
  );

  const onQuickChartCommand = (
    chatIndex: number,
    command: string,
    chartType: 'inline' | 'attached',
    chartIndex: number,
    chartSubIndex: number,
    args: unknown,
  ) => {
    if (!chatIndex) {
      return;
    }

    switch (command) {
      case 'duplicate':
        duplicateChatItemChart({
          chatItemIndex: chatIndex,
          chartIndex,
          chartSubIndex,
          chartType,
        });
        break;
      case 'update': {
        const prevSettings = chatItem.attachedCharts?.[chartIndex]?.[chartSubIndex];
        const currentSettings = args as QuickChartUpdateInfo;
        const chartModifyInfo = {
          ...prevSettings,
          category: (
            prevSettings?.selectedCategorialCol === currentSettings.selectedCategorialCol
          ) ? undefined : currentSettings.selectedCategorialCol,
          xValue: (
            prevSettings?.selectedTextCol === currentSettings.selectedTextCol
          ) ? undefined : currentSettings.selectedTextCol,
          yValue: (
            prevSettings?.selectedNumericCol === currentSettings.selectedNumericCol
          ) ? undefined : currentSettings.selectedNumericCol,
          graphType: (
            prevSettings?.graphType === currentSettings.graphType
          ) ? undefined : currentSettings.graphType,
          text: currentSettings.text || undefined,
        } as QuickChartUpdateInfo;

        requestUpdateChatItemChart({
          chatItemIndex: chatIndex,
          chartIndex,
          chartSubIndex,
          chartType,
          chartModifyInfo,
        });

        break;
      }
      case 'remove':
        removeChatItemChart({
          chatItemIndex: chatIndex,
          chartIndex,
          chartSubIndex,
          chartType,
        });
        break;

      case 'undo':
        undoChatItemChartChange({
          chatItemIndex: chatIndex,
          chartIndex,
          chartSubIndex,
          chartType,
        });
        break;
      default:
        break;
    }
  };

  const isSourceMentioned = (index: number) => {
    const sourceIndexes = (chatItem.content || '').match(/\[[0-9]+\]/g);
    return sourceIndexes?.find((sourceIndex) => sourceIndex === `[${index}]`);
  };

  const isAllSourcesMentioned = () => {
    const sourceIndexes = (chatItem.content || '').match(/\[[0-9]+\]/g);
    const uniqueSources = Array.from(new Set(sourceIndexes || []));

    return uniqueSources.length === (chatItem.sources || []).length;
  };

  const prepareDeepSearch = () => {
    prepareDeepSearchTasks({
      objectives: chatItem.objectives,
      tasks: (chatItem.sources || []).map((source) => ({
        id: v4(),
        value: source.metadata.source,
      })),
    });
  };

  const toggleCompareOutputPopup = (target: HTMLSpanElement) => {
    setCompareOutputPopupTarget(target);
  };

  const startChatOutputCompare = (chatInput?: string, model?: GptModel) => {
    if (!chatInput || !model?.modelName) {
      return;
    }

    applyChatOutputsCompare({
      input: chatInput,
      llmModel: model?.modelName,
    });

    setCompareOutputPopupTarget(undefined);
  };

  const renderException = (exception: string | ChatResponseException) => {
    if (typeof exception === 'string') {
      return exception;
    }

    return exception.msg;
  };

  const validateAndOpenReport = (companyItem: CompanyItem, report?: string) => {
    const creditLeft = (
      (userExtraInfo?.results.at(0)?.credits || 0)
      - (userExtraInfo?.results.at(0)?.creditsUsed || 0)
    );

    const isOpenedReport = !!openedReports[String(companyItem.id)];

    if (!isOpenedReport && creditLeft <= 0) {
      openMessageBox({
        content: 'Insufficient credits, please purchase a credit package to open the report.',
        dismissText: 'OK',
        colorSet: 'primary',
      });

      return;
    }

    if (!isOpenedReport) {
      const creditToBeDeducted = 1;

      openMessageBox({
        content: (
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="body1" className={classes.creditDialogHeader}>
              Credits left: {creditLeft}
            </Typography>
            <Typography variant="body1" className={classes.creditDialogContent}>
              Opening this report will deduct<br/>
              <span className="credit">{creditToBeDeducted}</span>
              {' credit. Please confirm.'}
            </Typography>
          </Box>
        ),
        dismissText: 'Cancel',
        colorSet: 'secondary',
        commands: [{
          title: 'OK',
          action: () => {
            openReport(companyItem, report);
          },
        }],
      });

      return;
    }

    openReport(companyItem, report);
  };

  const showTableGraph = (
    tableIndex: number,
    tableMarkdown: string,
  ) => {
    requestTableToChart({
      chatItemIndex,
      chartIndex: tableIndex,
      tableMarkdown,
      isAttachment: true,
    });
  };

  const isRequestingAttachedChart = (chartIndex: number) => {
    const chartRequestExtra = tableToChartRequest.extra as Record<string, unknown>;
    if (tableToChartRequest.isRequesting
      && tableToChartRequest.index === chatItemIndex
      && chartRequestExtra?.chartIndex === chartIndex
      && chartRequestExtra?.isAttachment
    ) {
      return true;
    }

    return false;
  };

  const determineOpenSourceBubble = (
    rect: DOMRect,
    sourceIndex: number,
  ) => {
    if (sourceBubbleTimeoutRef.current) {
      clearTimeout(sourceBubbleTimeoutRef.current);
      sourceBubbleTimeoutRef.current = undefined;
    }

    const timeoutRef = setTimeout(() => {
      const sourceInfo = actualSources?.find((s) => s.index === sourceIndex);
      if (!sourceInfo) {
        return;
      }

      openSourceBubble({
        id: answerViewId || '',
        originX: rect.left + (rect.width / 2),
        originY: rect.top,
        sourceIndex,
        sourceInfo,
      });

      sourceBubbleTimeoutRef.current = undefined;
    }, SOURCE_BUBBLE_TIMEOUT);

    sourceBubbleTimeoutRef.current = timeoutRef;
  };

  const cancelSourceBubble = () => {
    if (sourceBubbleTimeoutRef.current) {
      clearTimeout(sourceBubbleTimeoutRef.current);
      sourceBubbleTimeoutRef.current = undefined;
    }
  };

  const renderDataTable = (chatFileInfo: ChatFileInfo, chatIndex: number, fileIndex: number) => {
    // Non: This is a fallback to decode CSV data from href.
    //      But the right way is to make sure it is available originally from the reducer
    //      when we call extractStepInfosFiles().
    const data = chatFileInfo.data
      ?? (chatFileInfo.href ? createDataItems(
        Base64.decode(
          chatFileInfo.href.replace('data:text/csv;base64,', ''),
        ),
      ) : undefined);

    const headers = (data?.headers || []).map((header, index) => ({
      id: index,
      name: header,
      nodes: header,
    }) as DataTableHeader);

    const rows = (data?.items || []).map((item) => ({
      ...(item as object),
    }) as DataTableRow);

    const tableMarkdown = createTableMarkdown(headers, rows);

    return (
      <ThemeProvider theme={dataGridTheme}>
        {rootWidth && (
          <Box width={rootWidth - 112}>
            {chatFileInfo.isSourceMetaTable && (
              <div className={`sourceMetaTableView-${chatItemIndex}`} />
            )}
            <DataTable
              dataHeaders={headers}
              dataRows={rows}
              exportFileName={chatFileInfo.fileName}
              width={rootWidth}
              customTools={[{
                key: 'showGraph',
                tooltip: 'Show Graph',
                tableIndex: fileIndex,
                icon: <ShowGraphIcon />,
                onClick: () => {
                  if (!chatIndex) {
                    return;
                  }

                  showTableGraph(fileIndex, tableMarkdown);
                },
              }]}
            />
          </Box>
        )}
      </ThemeProvider>
    );
  };

  const renderSourceLink = (ansInfo: FaqAnswerInfo) => (
    <Link
      type="button"
      className={classes.sourceHighlight}
      style={{ marginBottom: 5 }}
      onMouseEnter={(event) => {
        const index = ansInfo.index || 0;
        const rect = event.currentTarget.getBoundingClientRect();

        determineOpenSourceBubble(rect, index);
      }}
      onMouseLeave={() => cancelSourceBubble()}
    >
      {ansInfo.index}
    </Link>
  );

  const renderAskValBox = (faq: SourceInfo) => (
    <Box display="flex" flex={0} marginTop={0.5} marginBottom={0.5} justifyContent="flex-end">
      <Button
        classes={commonCss.buttons.transparentButton}
        className={
          classNames(
            'tertiary',
            classes.proceedButton,
          )
        }
        onClick={() => {
          if (!faq.metadata.question) {
            return;
          }

          requestChatResponse({
            input: faq.metadata.question,
            // isTest: true,
          });
        }}
      >
        Ask VAL
        <Box display="flex" alignItems="center" marginLeft={0.5}>
          <ProceedIcon scale={0.5} />
        </Box>
      </Button>
    </Box>
  );

  const renderFaqAnswer = (faq: FaqRepInfo) => {
    const { answers } = faq;

    return answers.map((ans, answerIndex) => {
      if (ans.answerType === 'table') {
        const allRows = (ans.content || '').split('\n');
        const headerRow = allRows.at(0);
        const dataRows = allRows.slice(1);

        const headers = headerRow?.split(',');
        return (
          <div className={classes.faqAnswerBox}>
            <Table size="small" className={classes.faqTableAnswer}>
              <TableHead>
                <TableRow>
                  {headers?.map((header, index) => (
                    <TableCell key={index} component="th">
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataRows.filter((row) => !!row).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.split(',').map((val, cellIndex) => (
                      <TableCell key={cellIndex}>
                        {val}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box display="flex" marginLeft={1.5} paddingTop={0.5} paddingBottom={0.5}>
              <Box flex={1}>
                {renderSourceLink(ans)}
              </Box>
              {answerIndex === answers.length - 1 && (
                renderAskValBox(faq.sourceInfo)
              )}
            </Box>
          </div>
        );
      }

      if (ans.answerType === 'list') {
        const allItems = (ans.content || '')
          .split('\n')
          .filter((item) => !!item.trim());

        return (
          <div className={classes.faqAnswerBox}>
            <ul className={classes.faqListAnswer}>
              {allItems.map((item) => (
                <li key={item}>
                  {item}
                </li>
              ))}
            </ul>
            <Box display="flex" marginLeft={1.5} paddingTop={0.5} paddingBottom={0.5}>
              <Box flex={1}>
                {renderSourceLink(ans)}
              </Box>
              {answerIndex === answers.length - 1 && (
                renderAskValBox(faq.sourceInfo)
              )}
            </Box>
          </div>
        );
      }

      return (
        <div className={classes.faqAnswerBox}>
          <Typography variant="body2" flex={1} className={classes.faqAnswer}>
            {ans.content ? `${ans.content} ` : ''}
            {renderSourceLink(ans)}
          </Typography>
          {answerIndex === answers.length - 1 && (
            renderAskValBox(faq.sourceInfo)
          )}
        </div>
      );
    });
  };

  const renderFaqs = () => {
    const faqs = faqSources?.reduce((combinations, current) => {
      const existingQuestion = combinations
        .find((f) => f.question === current.metadata.question);

      if (!existingQuestion) {
        return [
          ...combinations,
          {
            sourceInfo: current,
            question: current.metadata.question,
            answers: [{
              index: current.index,
              answerType: current.metadata.answerType,
              content: current.pageContent,
            }],
          },
        ] as FaqRepInfo[];
      }

      return combinations.map((f) => {
        if (f.question === current.metadata.question) {
          return {
            ...f,
            answers: [
              ...f.answers,
              {
                index: current.index,
                answerType: current.metadata.answerType,
                content: current.pageContent,
              },
            ],
          } as FaqRepInfo;
        }

        return f;
      });
    }, [] as FaqRepInfo[]);

    return (faqs || []).length > 0 && (
      <Box>
        <Box className={classes.expander} marginBottom={1}>
          <Box
            className={classNames(
              classes.expanderContent,
              chatItem.isShowingFaqs && 'highlight',
            )}
          >
            <Button
              variant="text"
              color="info"
              onClick={() => {
                toggleChatItemFaqs({
                  chatItemIndex,
                  isShowing: !chatItem.isShowingFaqs,
                });

                hideAndShowTasks();
              }}
              fullWidth
            >
              <Box marginLeft={0.5} display="flex" alignItems="center" className={classes.sectionHeader}>
                FAQ
                {faqs?.length && (
                  <Box className={classes.subBarCount}>
                    ({faqs?.length})
                  </Box>
                )}
              </Box>
              <CaretUpIcon
                className={
                  classNames(
                    classes.gptCaretUpIcon,
                    !chatItem.isShowingFaqs && 'rotate',
                  )
                }
              />
            </Button>
          </Box>
        </Box>
        {chatItem.isShowingFaqs && (
          <Box className={classes.faqBox}>
            {(faqs || [])
              .filter((faq) => !!faq.question)
              .slice(0, isShowMoreFaqs ? undefined : MAX_FAQS)
              .map((faq, faqIndex) => (
                <motion.div
                  key={faqIndex}
                  initial={{
                    x: -20,
                    y: 0,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    y: 0,
                    opacity: 1,
                  }}
                  transition={{
                    delay: 0.3 * (faqIndex + 1),
                  }}
                  exit={{
                    x: -20,
                    y: 0,
                    opacity: 0,
                  }}
                >
                  <Button
                    variant="text"
                    color="info"
                    className={classes.faqToggleButton}
                    fullWidth
                    onClick={() => {
                      const faqQuestion = faq.question || '';
                      if (expandingFaqs?.includes(faqQuestion)) {
                        setExpandingFaqs(
                          expandingFaqs.filter((q) => q !== faqQuestion),
                        );
                        return;
                      }

                      setExpandingFaqs(Array.from(new Set([
                        ...(expandingFaqs || []),
                        faqQuestion,
                      ])));
                    }}
                  >
                    <Typography variant="body2" flex={1} className={classes.faqQuestion}>
                      {faq.question}
                    </Typography>
                    <Box flex={0}>
                      <CaretUpIcon
                        className={
                          classNames(
                            classes.gptCaretUpIcon,
                            !expandingFaqs?.includes(faq.question || '') && 'rotate',
                          )
                        }
                      />
                    </Box>
                  </Button>
                  {expandingFaqs?.includes(faq.question || '') && (
                    <>
                      {renderFaqAnswer(faq)}
                    </>
                  )}
                  <Divider />
                </motion.div>
              ))
            }
            {(faqs || []).length > MAX_FAQS && (
              <Link
                className={classes.showMoreFaqsLink}
                onClick={() => {
                  setShowMoreFaqs(!isShowMoreFaqs);
                }}
              >
                {isShowMoreFaqs ? 'Show Less' : 'Show More'}
              </Link>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const renderSources = () => {
    const sources = actualSources?.filter((s) => s.index !== 0);

    return (sources || []).length > 0 && (
      <Box>
        <Box className={classes.expander} marginBottom={1}>
          <Box
            className={classNames(
              classes.expanderContent,
              chatItem.isShowingSources && 'highlight',
            )}
          >
            <Button
              variant="text"
              color="info"
              onClick={() => {
                toggleChatItemSource({
                  chatItemIndex,
                  isShowing: !chatItem.isShowingSources,
                });

                hideAndShowTasks();
              }}
              fullWidth
            >
              <Box marginLeft={0.5} display="flex" alignItems="center" className={classes.sectionHeader}>
                Sources
                {sources?.length && (
                  <Box className={classes.subBarCount}>
                    ({sources?.length})
                  </Box>
                )}
              </Box>
              <CaretUpIcon
                className={
                  classNames(
                    classes.gptCaretUpIcon,
                    !chatItem.isShowingSources && 'rotate',
                  )
                }
              />
            </Button>
          </Box>
        </Box>
        {chatItem.isShowingSources && (
          <Box className={classes.sourcesBox}>
            {!isAllSourcesMentioned() && !showAllSources && (
              <Link
                type="button"
                className={classes.showAllSourcesLink}
                onClick={() => setShowAllSources(true)}
              >
                Show All Sources
              </Link>
            )}
            <ol className={classes.sourceList}>
              {(sources || [])
                .filter((source) => (
                  !!source.metadata
                  && source.index !== undefined
                  && (isSourceMentioned(source.index) || showAllSources)
                ))
                .map((source, sourceIndex) => (
                  <motion.li
                    key={sourceIndex}
                    initial={{
                      x: -20,
                      y: 0,
                      opacity: 0,
                    }}
                    animate={{
                      x: 0,
                      y: 0,
                      opacity: 1,
                    }}
                    transition={{
                      delay: 0.3 * (sourceIndex + 1),
                    }}
                    exit={{
                      x: -20,
                      y: 0,
                      opacity: 0,
                    }}
                  >
                    <span className={classes.sourceHighlight}>
                      {source.index}
                    </span>
                    <Link
                      type="button"
                      href={source.metadata.source}
                      className={classes.sourceTitle}
                      target="_blank"
                    >
                      {source.metadata.title || source.metadata.source}
                    </Link>
                    {source.pageContent && (
                      <Box className={classes.sourceContentBox}>
                        <ContentView content={source.pageContent} />
                        {isSearchOnline() && (
                          <Box marginTop={1} marginLeft={1.5} display="flex" alignItems="center">
                            {source.favIconUrl && (
                              <img
                                src={source.favIconUrl}
                                className={classes.sourceFavIcon}
                              />
                            )}
                            <Typography variant="body2" className={classes.sourceDomain}>
                              {source.domain}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                  </motion.li>
                ))}
            </ol>
            {isSearchOnline() && (
              <Box className={classes.executeBox} style={{ display: 'none' }}>
                <Button
                  classes={commonCss.buttons.leafButton}
                  className={
                    classNames(
                      classes.executeButton,
                    )
                  }
                  variant="outlined"
                  size="small"
                  onClick={() => prepareDeepSearch()}
                >
                  Deep Search
                </Button>
                <Box marginTop={0.5}>
                  <Typography variant="caption" className={classes.executeEstimate}>
                    {`Estimated Duration: ${(sources || []).length} Minute(s)`}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const renderRelatedCompanies = () => {
    const orgs = (chatItem.orgs || []).length > 0
      ? chatItem.orgs
      : [];

    return (orgs || []).length > 0 && (
      <Box marginTop={1}>
        <Box className={classes.expander} marginBottom={1}>
          <Box
            className={classNames(
              classes.expanderContent,
              chatItem.isShowingOrgs && 'highlight',
            )}
          >
            <Button
              variant="text"
              color="info"
              onClick={() => {
                toggleChatItemOrgs({
                  chatItemIndex,
                  isShowing: !chatItem.isShowingOrgs,
                });

                hideAndShowTasks();
              }}
              fullWidth
            >
              <Box marginLeft={0.5} className={classes.sectionHeader}>
                Related Companies
              </Box>
              <CaretUpIcon
                className={
                  classNames(
                    classes.gptCaretUpIcon,
                    !chatItem.isShowingOrgs && 'rotate',
                  )
                }
              />
            </Button>
          </Box>
        </Box>
        {!chatItem.isShowingOrgs && (
          <Box display="flex" flexWrap="wrap" marginLeft={2} marginRight={7}>
            {(chatItem.orgs || []).slice(0, MAX_RELATED_COMPANITES_COLLAPSED).map((org) => (
              <Box
                key={org.id}
                className={classNames(
                  classes.relatedCompanyItem,
                )}
                onClick={() => {
                  validateAndOpenReport(org);
                }}
              >
                <div className={classes.relatedCompanyLogoContainer}>
                  {org.logo ? (
                    <Box
                      className={commonCss.boxes.imageBox}
                      component="object"
                      data={getRelatedCompanyLogo(org.logo, 60)}
                      type="image/png"
                      width={60}
                      height={60}
                    >
                      <DefaultCompanyIcon />
                    </Box>
                  ) : (
                    <Box
                      className={commonCss.boxes.imageBox}
                      width={60}
                      height={60}
                    >
                      <DefaultCompanyIcon />
                    </Box>
                  )}
                </div>
                <Typography variant="body2" className={classes.relatedCompanyLabel}>
                  {org.name}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {chatItem.isShowingOrgs && (
          <Box marginLeft={-1} marginRight={4}>
            <CompanyList
              companyItems={orgs}
              showKeywords={false}
              isRelatedCompanies={true}
              onDataClick={(companyItem) => validateAndOpenReport(companyItem)}
              onSubReportClick={(companyItem) => validateAndOpenReport(companyItem)}
            />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <div
        style={{
          display: (
            chatItem.type === 'user'
            && chatItem.mode === 'task'
              ? 'none'
              : 'flex'
          ),
        }}
      >
        <Box
          className={
            classNames(
              classes.gptChatTypeIcon,
              chatItem.type === 'user' ? 'grey' : 'tertiary',
              hideIcon && 'hidden',
            )
          }
        >
          {chatItem.type === 'bot' && (
            <PvalyouBotIcon className={classes.gptChatTypeSvg} />
          )}
          {chatItem.type === 'user' && (
            <ChatUserIcon className={classes.gptChatTypeSvg} />
          )}
        </Box>
        <Box
          className={classes.gptChatItemBox}
          display="flex"
          flexDirection="column"
          flex={1}
        >
          {hasChatOutputsSelection && (
            <Box
              display="flex"
              marginBottom={1}
              className={classes.gptChatItem}
            >
              <Box flex={1} marginLeft={-0.5}>
                <Tabs
                  className={classes.compareOutputTabs}
                  classes={{
                    ...commonCss.tabs,
                    indicator: classNames(
                      commonCss.tabs.indicator,
                      'tertiary',
                    ),
                  }}
                  textColor="inherit"
                  value={chatOutputSelectionInfo.selectedModel}
                >
                  {(
                    chatOutputSelectionInfo.chatByEngines || []
                  ).map((item, tabIndex) => (
                    <Tab
                      key={tabIndex}
                      label={getGptModelName(item.llm)}
                      value={item.llm}
                      onClick={() => selectChatResponseModel({
                        chatItemIndex,
                        llm: item.llm,
                      })}
                    />
                  ))}
                </Tabs>
              </Box>
              <Box
                className={classes.gptChatItemUserToolsBox}
              >
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();

                    setMenuButton(event.currentTarget);
                    setMenuChatItem(chatItem);
                  }}
                  style={{
                    marginRight: theme.spacing(-6),
                  }}
                  size="medium"
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Box>
          )}
          {(
            (chatItem.steps?.filter(
              (step) => step.items.length > 0 && !step.isPlanExecuteTask,
            ) || []).length > 0
            || (
              chatItem.structuredContent
              && 'structured' in chatItem.structuredContent
              && chatItem.structuredContent.structured
              && typeof chatItem.structuredContent.structured === 'object'
              && 'tasks' in chatItem.structuredContent.structured
              && (
                !chatItem.needPlan
                || (
                  chatItem.needPlan
                  && !chats[chatItemIndex + 2]?.structuredContent
                )
              )
            )
          ) ? (
            <Box className={classes.expander} marginBottom={1}>
              <Box
                className={classNames(
                  classes.expanderContent,
                  chatItem.isShowingSteps && 'highlight',
                )}
              >
                <Button
                  variant="text"
                  color="info"
                  onClick={() => {
                    toggleChatItemStep({
                      chatItemIndex,
                      isShowing: !chatItem.isShowingSteps,
                    });

                    hideAndShowTasks();
                  }}
                  fullWidth
                >
                  <Box marginLeft={0.5} className={classes.sectionHeader}>
                    {chatItem.agent === 'plan_execute' ? 'Plan' : 'Steps'}
                  </Box>
                  <CaretUpIcon
                    className={
                      classNames(
                        classes.gptCaretUpIcon,
                        !chatItem.isShowingSteps && 'rotate',
                      )
                    }
                  />
                </Button>
                {!hasChatOutputsSelection && (
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();

                      setMenuButton(event.currentTarget);
                      setMenuChatItem(chatItem);
                    }}
                    style={{
                      marginRight: theme.spacing(-6),
                    }}
                    size="medium"
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
            ) : undefined}
          <GptChatStepsView
            chatItem={chatItem}
            chatItemIndex={chatItemIndex}
          />
          <GptChatContentView
            chatItem={chatItem}
            chatItemIndex={chatItemIndex}
            isTasksVisible={isTasksVisible}
            userToolBox={
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();

                  setMenuButton(event.currentTarget);
                  setMenuChatItem(chatItem);
                }}
                style={{
                  marginRight: theme.spacing(-6),
                  alignSelf: 'flex-start',
                }}
                size="medium"
              >
                <MoreVertIcon />
              </IconButton>
            }
          />
          {hasOtherSections() && (
            <Box className={classes.otherSectionsDivider} marginBottom={1} marginTop={3} />
          )}
          {(faqSources || []).length > 0 && (
            <>
              {renderFaqs()}
            </>
          )}
          {(actualSources || []).length > 0 && (
            <>
              {renderSources()}
            </>
          )}
          {(chatItem.files || []).length > 0 && (
            <>
              <Box className={classes.expander} marginBottom={1}>
                <Box
                  className={classNames(
                    classes.expanderContent,
                    chatItem.isShowingSteps && 'highlight',
                  )}
                  flex={1}
                >
                  <Button
                    variant="text"
                    color="info"
                    onClick={() => {
                      toggleChatItemTable({
                        chatItemIndex,
                        isShowing: !chatItem.isShowingTables,
                      });

                      hideAndShowTasks();
                    }}
                    fullWidth
                  >
                    <Box marginLeft={0.5} className={classes.sectionHeader}>
                      Tables
                    </Box>
                    <CaretUpIcon
                      className={
                        classNames(
                          classes.gptCaretUpIcon,
                          !chatItem.isShowingTables && 'rotate',
                        )
                      }
                    />
                  </Button>
                </Box>
              </Box>
              {chatItem.isShowingTables && (
                <Box ref={attachedChartRef} className={classes.attachedCharts}>
                  {(chatItem.files || []).map((chatFile, idx) => (
                    <Box
                      key={idx}
                      marginTop={1}
                      marginBottom={1}
                      className={classes.dataTable}
                    >
                      {renderDataTable(chatFile, chatItemIndex, idx)}
                      {chatFile.source && (
                        <Box marginBottom={1}>
                          <small className={classes.tableSourceTitle}>
                            Source: {chatFile.source}
                          </small>
                        </Box>
                      )}
                      {isRequestingAttachedChart(idx) && (
                        <span className={classes.requestingChart}>
                          <CircularProgress color="inherit" size={40} />
                        </span>
                      )}
                      {chatItem.attachedCharts?.at(idx) && (
                        chatItem.attachedCharts?.at(idx)?.map((chartInfo, chartSubIndex) => (
                          <React.Fragment key={chartSubIndex}>
                            {chartInfo?.graphId && rootWidth && (
                              <span className={classes.chartBox}>
                                <QuickChart
                                  key={chartInfo.graphId}
                                  chartInfo={chartInfo}
                                  colorSet="tertiary"
                                  onCommand={(command, args) => {
                                    onQuickChartCommand(
                                      chatItemIndex,
                                      command,
                                      'attached',
                                      idx,
                                      chartSubIndex,
                                      args,
                                    );
                                  }}
                                  isUpdating={
                                    chatItemChartUpdateRequest.isRequesting
                                    && chatItemChartUpdateRequest.index === chatItemIndex
                                    && chartUpdateRequestArgs.chartIndex === idx
                                    && chartUpdateRequestArgs.chartSubIndex === chartSubIndex
                                  }
                                  chartWidth={attachedChartWidth}
                                  isUndoable={!!chartInfo?.prevChartInfo}
                                />
                              </span>
                            )}
                          </React.Fragment>
                        ))
                      )}
                    </Box>
                  ))}

                  {(chatItem.attachedCharts || []).map((chartInfos, chartIndex) => (
                    <React.Fragment key={chartIndex}>
                      {chartInfos
                        .filter((chart) => !chart?.isTableChart)
                        .map((chartInfo, chartSubIndex) => (
                          <React.Fragment key={chartSubIndex}>
                            {chartInfo?.graphId && rootWidth && (
                              <span className={classes.chartBox}>
                                <QuickChart
                                  key={chartInfo.graphId}
                                  chartInfo={chartInfo}
                                  colorSet="tertiary"
                                  onCommand={(command, args) => {
                                    onQuickChartCommand(
                                      chatItemIndex,
                                      command,
                                      'attached',
                                      chartIndex,
                                      chartSubIndex,
                                      args,
                                    );
                                  }}
                                  isUpdating={
                                    chatItemChartUpdateRequest.isRequesting
                                    && chatItemChartUpdateRequest.index === chatItemIndex
                                    && chartUpdateRequestArgs.chartIndex === chartIndex
                                    && chartUpdateRequestArgs.chartSubIndex === chartSubIndex
                                  }
                                  chartWidth={attachedChartWidth}
                                  isUndoable={!!chartInfo?.prevChartInfo}
                                />
                              </span>
                            )}
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  ))}
                </Box>
              )}
            </>
          )}
          {(chatItem.orgs || []).length > 0 && (
            <>
              {renderRelatedCompanies()}
            </>
          )}
          {menuButton && menuChatItem && menuChatItem === chatItem && (
            <Menu
              open={!!menuButton}
              anchorEl={menuButton}
              onClose={() => {
                setMenuButton(undefined);
                setMenuChatItem(undefined);
              }}
            >
              {menuChatItem.type === 'user' && (
                <Box>
                  <MenuItem
                    onClick={() => {
                      setMenuButton(undefined);
                      if (chatItem.content && user) {
                        requestCreateChatPrompt({
                          prompt: {
                            prompt: chatItem.content,
                            user: user?.pk,
                          },
                        });
                      }
                    }}
                    disabled={!chatItem.content || !user}
                  >
                    <Typography variant="body2">
                      Save as Prompt
                    </Typography>
                  </MenuItem>
                </Box>
              )}
              <MenuItem
                onClick={() => {
                  setMenuButton(undefined);

                  openMessageBox({
                    content: (
                      <Box display="flex" flexDirection="column" justifyContent="center">
                        <Typography variant="body1">
                          Are you sure you want to delete this chat?
                        </Typography>
                        <Typography variant="body2">
                        </Typography>
                      </Box>
                    ),
                    dismissText: 'No',
                    colorSet: 'tertiary',
                    commands: [{
                      title: 'Yes',
                      action: () => {
                      },
                    }],
                  });
                }}
              >
                <Typography variant="body2" color="error">
                  Delete
                </Typography>
              </MenuItem>
            </Menu>
          )}
          {chatItem.type === 'bot' && !chatItem.isProcessing
            && chatItem.exception
            && (
              <Box marginLeft={2} marginTop={1}>
                <Typography variant="body2" color="red">
                  {renderException(chatItem.exception)}
                </Typography>
              </Box>
            )}
          {chatItem.type === 'bot' && !chatItem.isProcessing
            && (chatItemIndex === chats.length - 1)
            && !chatResponseRequest.isRequesting
            && (
              <Box className={classes.chatItemMoreCommandsContainer}>
                {!(chatItem.structuredContent
                  && !chatItem.content?.trim()
                  && chatItem.needPlan) && (
                  <>
                    <Box marginTop={2} marginBottom={1}>
                      <Divider />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box flex={1} display="flex" columnGap={2}>
                        <Tooltip arrow title="Compare Outputs">
                          <Link
                            type="button"
                            className={classes.moreCommandLink}
                            onClick={(event) => toggleCompareOutputPopup(event.currentTarget)}
                          >
                            <CompareOutputsIcon width={24} height={24} />
                          </Link>
                        </Tooltip>
                        {chatItem.agent === 'plan_execute' && (
                          <Tooltip arrow title="Remember Plan">
                            <Link
                              type="button"
                              className={classes.moreCommandLink}
                            >
                              <BookmarkIcon width={20} height={20} />
                            </Link>
                          </Tooltip>
                        )}
                      </Box>
                      <Box flex={0} display="none" alignItems="center">
                        <Tooltip arrow title="Report Problem">
                          <Link
                            type="button"
                            className={classes.moreCommandLink}
                            style={{ justifySelf: 'flex-end' }}
                          >
                            <ReportProblemIcon width={24} height={24} />
                          </Link>
                        </Tooltip>
                      </Box>
                    </Box>
                    {chatItem.followUp?.questions && (
                      <Box className={classes.followUpQuestionsBox}>
                        <Typography variant="body2" className={classes.followUpQuestionsHeader}>
                          You may also want to ask
                        </Typography>
                        <Box marginTop={1}>
                          {chatItem.followUp.questions.map((q, index) => (
                            <motion.div
                              key={index}
                              initial={{
                                x: -20,
                                y: 0,
                                opacity: 0,
                              }}
                              animate={{
                                x: 0,
                                y: 0,
                                opacity: 1,
                              }}
                              transition={{
                                delay: 0.3 * (index + 1),
                              }}
                              exit={{
                                x: -20,
                                y: 0,
                                opacity: 0,
                              }}
                            >
                              <div
                                className={classes.followUpQuestionItem}
                                onClick={() => {
                                  if (!q.question) {
                                    return;
                                  }

                                  requestChatResponse({
                                    input: q.question,
                                    // isTest: true,
                                  });
                                }}
                              >
                                <Typography flex={1} variant="body2" className={classes.followUpQuestionText}>
                                  {q.question}
                                </Typography>
                                <Button
                                  classes={commonCss.buttons.transparentButton}
                                  className={
                                    classNames(
                                      'tertiary',
                                      classes.proceedButton,
                                      'followUpQuestion',
                                    )
                                  }
                                >
                                  <Box display="flex" alignItems="center" marginLeft={0.5}>
                                    <ProceedIcon scale={0.5} />
                                  </Box>
                                </Button>
                              </div>
                              <Divider />
                            </motion.div>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </>
                )}
                <Popover
                  open={!!compareOutputPopupTarget}
                  anchorEl={compareOutputPopupTarget}
                  onClose={() => setCompareOutputPopupTarget(undefined)}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  transformOrigin={{ vertical: 100, horizontal: 0 }}
                  classes={{
                    paper: classes.compareOutputPopup,
                  }}
                >
                  <Box position="sticky" className={classes.compareOutputPopupTitle}>
                    <Typography variant="h5" className={classes.compareOutputPopupHeader}>
                      SELECT MODEL TO COMPARE
                    </Typography>
                  </Box>
                  {(gptModels || [])
                    .filter((model) => (
                      model.permLevel <= userPermLevel
                      && model.active
                      && !(chatOutputSelectionInfo?.chatByEngines || [])
                        .find((cb) => cb.llm === model.modelInput)
                      && model.modelInput !== chatItem.llmModel
                    )).map((model) => (
                      <div
                        key={model.modelInput}
                        className={classes.compareOutputItem}
                        onClick={() => {
                          startChatOutputCompare(chats[chatItemIndex - 1].content, model);
                        }}
                      >
                        <Grid container>
                          <Grid item container xs={8} alignItems="center">
                            <Typography variant="body2" className={classes.compareOutputItemName}>
                              {model.modelName}
                            </Typography>
                          </Grid>
                          <Grid item container xs={4} alignItems="center">
                            <Typography variant="body2" className={classes.compareOutputItemOwner}>
                              {model.owner}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box marginTop={1} />
                      </div>
                    ))}
                </Popover>
              </Box>
            )}
        </Box>
      </div>
    </>
  );
};

export default GptChatItemView;
