import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { makeStyles } from '@mui/styles';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import {
  Lock as LockIcon,
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';

import { useCommonClasses } from '../../../theme/commonStyles';
import { ReactComponent as FullLogo } from '../../../assets/svg/fullLogo.svg';
import { PropsFromRedux } from './container';
import styles from './Login.styles';

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);

const Login: React.FC<Props> = ({
  networkStates: {
    loginRequest,
  },
  requestLogin,
  token,
  isSessionInitialized,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (isSessionInitialized && token) {
      navigate('/');
    }
  }, [token, navigate]);

  return (
    <div className={classes.root}>
      <Paper
        className={classes.loginPanel}
        classes={commonCss.panels.leafPanel}
        elevation={10}
      >
        <div className={classes.content}>
          <FullLogo />
          <Avatar
            classes={{
              root: classes.lockIcon,
            }}
          >
            <LockIcon />
          </Avatar>
          <Typography variant="h5" marginTop={2}>
            Sign In
          </Typography>
          <Box marginTop={2} width="100%">
            <TextField
              color="secondary"
              variant="outlined"
              size="medium"
              label="Email Address"
              fullWidth
              autoFocus
              value={username}
              onChange={(event) => setUsername(event.currentTarget.value)}
            />
          </Box>
          <Box marginTop={2} width="100%">
            <TextField
              color="secondary"
              variant="outlined"
              size="medium"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => { event.preventDefault(); }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={password}
              onChange={(event) => setPassword(event.currentTarget.value)}
            />
          </Box>
          <Box marginTop={2} width="100%">
            <FormControlLabel
              label="Remember Me"
              control={
                <Checkbox color="secondary" />
              }
              disabled
            />
          </Box>
          <Box marginTop={2} width="100%">
            <Button
              color="secondary"
              fullWidth
              onClick={() => requestLogin({
                username,
                password,
              })}
              disabled={loginRequest.isRequesting || !username || !password}
            >
              {loginRequest.isRequesting && (
                <Box display="inline-block" marginRight={1}>
                  <CircularProgress
                    color="inherit"
                    size={12}
                  />
                </Box>
              )}
              SIGN IN
            </Button>
          </Box>
          {loginRequest.lastError && (
            <Box marginTop={2} width="100%">
              <Alert color="error" variant="standard">
                Failed to Login
              </Alert>
            </Box>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default Login;
