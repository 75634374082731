import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';
import getCommonStyles from '../../../../theme/commonStyles';
import {
  otherColorSets,
  otherContrastColorSets,
} from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    introHeader: {
      fontWeight: '500 !important',
      fontSize: '1.4285rem !important',
      color: `${theme.palette.grey[900]} !important`,
    },
    valHighlight: {
      background: 'linear-gradient(to right, #8e89ff, #b382fd 95%)',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
    gptChatBox: {
      flex: 1,
      width: '100%',
    },
    gptChatBoxTopBar: {
      position: 'sticky',
      top: 0,
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      marginBottom: theme.spacing(2),
      zIndex: 100,
    },
    gptChatItemContainer: {
      marginBottom: theme.spacing(3),
      '&.hidden': {
        display: 'none',
      },
      '&.noMargin': {
        marginBottom: 0,
      },
    },
    gptPromptInputBox: {
      flex: 0,
      position: 'sticky',
      bottom: 0,
      paddingBottom: 1,
      background: theme.palette.background.paper,
      zIndex: 10,
      '&.intro': {
        top: 0,
        marginLeft: 0,
        bottom: 'auto',
        zIndex: 100,

        '& $miniSettingsBox': {
          bottom: theme.spacing(2),
        },
      },
    },
    gptPromptInput: {
      '& .MuiInputBase-root': {
        borderRadius: `${theme.spacing(1.5)} !important`,
        border: `1px solid ${otherContrastColorSets[2]}`,
        paddingBottom: theme.spacing(7),
        outline: 'none',
        transition: 'all 0.3s ease-out',
      },
      '& .MuiOutlinedInput-root': {
        paddingTop: '14px !important',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiInputBase-root.Mui-focused, .MuiInputBase-root:hover': {
        outline: `0.5px solid ${Color(otherContrastColorSets[2]).darken(0.1).toString()}`,
        borderColor: Color(otherContrastColorSets[2]).darken(0.1).toString(),
      },
      marginTop: '14px !important',
      marginLeft: `${theme.spacing(7)} !important`,
      width: `calc(100% - ${theme.spacing(14)}) !important`,
    },
    miniSettingsBox: {
      position: 'absolute',
      bottom: theme.spacing(2),
      left: theme.spacing(9),
      display: 'flex',
      columnGap: theme.spacing(1),
    },
    miniSettingsButton: {
      borderRadius: `${theme.spacing(1)} !important`,
      backgroundColor: '#e7e7f6 !important',
      color: `${otherContrastColorSets[2]} !important`,
      '&:hover': {
        backgroundColor: `${Color('#e7e7f6').darken(0.05).toString()} !important`,
      },
      '& .carretDownIconPath': {
        stroke: `${otherContrastColorSets[2]} !important`,
      },
    },
    gptPromptIcon: {
      width: theme.spacing(3),
      alignSelf: 'flex-start',
    },
    gptHideStepsButton: {
      color: `${theme.palette.grey[500]} !important`,
      fontSize: '1.071rem !important',
      fontWeight: 'normal !important',
      textDecoration: 'underline !important',
      textDecorationColor: `${theme.palette.grey[500]} !important`,
    },
    exampleBox: {
      width: 'calc(100% - 112px)',
      margin: '0 auto',
    },
    stopGenerateButton: {
      marginTop: `${theme.spacing(-0.5)} !important`,
      marginBottom: `${theme.spacing(-0.5)} !important`,
      marginRight: `${theme.spacing(-1)} !important`,
    },
    proceedButton: {
      borderRadius: `${theme.spacing(1)} !important`,
      backgroundColor: '#e7e7f6 !important',
      color: `${otherContrastColorSets[2]} !important`,
      '&:hover': {
        backgroundColor: `${Color('#e7e7f6').darken(0.05).toString()} !important`,
      },
      minWidth: '0 !important',
      width: 22,
      height: 22,
      padding: '4px !important',
      marginLeft: '4px !important',
      marginRight: '-4px !important',
    },
    settingPopup: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderRadius: `${theme.spacing(1.5)} !important`,
      borderColor: `${otherColorSets[2]} !important`,
      '&.extended': {
        width: 420,
      },
    },
    settingItem: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      minHeight: theme.spacing(3.75),
      paddingTop: theme.spacing(1),
      transition: 'all 0.3s ease-out',
      '&:hover': {
        background: '#f5f5fe',
      },
      cursor: 'pointer',
    },
    settingItemName: {
      color: `${theme.palette.grey[900]} !important`,
      fontWeight: 'normal !important',
    },
    settingItemDescription: {
      color: `${theme.palette.grey[300]} !important`,
      fontWeight: 'normal !important',
      letterSpacing: '0.17px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    gptModelOptionLabel: {
      fontWeight: '400 !important',
    },
    guidesButton: {
      borderColor: `${otherContrastColorSets[2]} !important`,
      backgroundColor: `${theme.palette.common.white} !important`,
      color: `${theme.palette.grey[500]} !important`,
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#f1f0ff !important',
      },
      width: 140,
      whiteSpace: 'nowrap',
    },
    browseToolsButton: {
      borderColor: `${otherContrastColorSets[2]} !important`,
      backgroundColor: `${theme.palette.common.white} !important`,
      color: `${theme.palette.grey[500]} !important`,
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#f1f0ff !important',
      },
      width: 140,
      whiteSpace: 'nowrap',
    },
    guideContainer: {
      height: 'calc(100vh - 250px)',
    },
    guideFrame: {
      border: 'none',
      width: '100%',
      height: '100%',
    },
  });
};

export default styles;
