import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';
import getCommonStyles, { ROUND_PANEL_RADIUS } from '../../../../theme/commonStyles';
import { otherColorSets, otherContrastColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      borderWidth: '1px !important',
      borderColor: `${Color(otherColorSets[2]).alpha(0.5).toString()} !important`,
      position: 'relative',
      '&.crop': {
        overflow: 'hidden',
      },
    },
    founderTitle: {
      color: `${otherContrastColorSets[2]} !important`,
      marginBottom: `${theme.spacing(1)} !important`,
    },
    founderImage: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      backgroundColor: `${theme.palette.common.white} !important`,
      borderColor: `${Color(otherColorSets[2]).alpha(0.25).toString()} !important`,
      flexDirection: 'column',
      color: `${theme.palette.grey[600]} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.grey[50]} !important`,
      },
      '& > div': {
        display: 'flex',
      },
      borderWidth: '1.5px !important',
    },
    removeButton: {
      position: 'absolute',
      right: theme.spacing(-1),
      top: 0,
    },
    linkedInHeader: {
      fontWeight: 'normal !important',
      color: `${theme.palette.grey[600]} !important`,
      fontSize: '1.1071rem',
    },
    formButton: {
      width: 150,
    },
    manualFormLink: {
      color: `${theme.palette.grey[600]} !important`,
      fontSize: '1.1071rem',
      '&[disabled]': {
        opacity: 0.5,
        cursor: 'default',
      },
    },
    removeFounderBox: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: Color('#5b5a6f').alpha(0.59).toString(),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      zIndex: 1,
      '& > div': {
        '& > p': {
          fontSize: '1.285rem',
          fontWeight: '500 !important',
          color: otherContrastColorSets[2],
          lineHeight: 1.39,
          textAlign: 'center',
        },
      },
      borderRadius: `
        ${theme.spacing(ROUND_PANEL_RADIUS / 2.5)}
        ${theme.spacing(ROUND_PANEL_RADIUS / 2.5)}
        ${theme.spacing(ROUND_PANEL_RADIUS / 2.5)}
        0
      `,
    },
  });
};

export default styles;
