import { NodeHtmlMarkdown } from 'node-html-markdown';
import { jsonrepair } from 'jsonrepair';
import { Base64 } from 'js-base64';
import { format } from 'date-fns';
import { parse } from 'papaparse';
import {
  ApiSourceInfo,
  ChatFileInfo,
  ChatHistorySubItem,
  ChatStepInfo,
  ChatStepItemInfo,
  GptChatItem,
  IntermediateStep,
  IntermediateStepHeader,
  PlanAndExecuteInfo,
  PlanAndExecuteTask,
  SourceInfo,
} from './types';
import { CompanyItem } from '../search/types';
import {
  extractJsonCodePart,
  isJsonString,
  isRepairableJson,
  stripJsonCodePart,
} from '../../../core/utils/stringUtils';

const reactKeysRegex = /(Thought:|Action:|Action Input:|Observation:|<br>)/g;
const planExectureKeysRegex = /(Agent:|Objective:|<br>)/g;
const selfAskKeysRegex = /(Agent:|Objective:|Action:|Action Input:|Output:|Intermediate question:|Are intermediate questions needed here:|Intermediate answer:|<br>)/g;

export function refineJsonOutput(string: string) {
  if (string.includes('[Document(page_content=')) {
    const repairedString = string
      .replace(/Document\(/g, '{')
      .replace(/\)/g, '}')
      .replace(/page_content=/g, 'page_content:')
      .replace(/metadata=/g, 'metadata:')
      .trim();

    return repairedString;
  }

  return string;
}

export function translateHtmlTable(answer?: string) {
  if (!answer) {
    return '';
  }

  const lines = answer.split('\n\n');
  const updatedLines = lines.map((line) => {
    if (
      line.toLowerCase().startsWith('<table>')
      && line.toLowerCase().endsWith('</table>')
    ) {
      return NodeHtmlMarkdown.translate(line);
    }

    return line;
  });

  return updatedLines.join('\n\n');
}

export function translateEvaluationTable(answer?: string) {
  if (!answer) {
    return '';
  }

  const lines = answer?.split('\n') || [];
  const headers = lines.at(0)?.split(';');

  const headerMarkdown = headers && `|${headers.map((h) => ` ${h} `).join('|')}|`;
  const headerSplitter = headers && `|${headers.map(() => ' --- ').join('|')}|`;
  const contentMarkdown = (lines.slice(1) || []).map((line) => {
    const cols = line.split(';');
    return cols && `|${cols.map((col) => ` ${col} `).join('|')}|`;
  }).join('\n');

  return [headerMarkdown, headerSplitter, contentMarkdown].join('\n');
}

export function translateSourceTableToMarkdown(sourceTable?: string, maxRows?: number) {
  if (!sourceTable) {
    return undefined;
  }

  const rows = sourceTable.split('\n').map((s) => s.trim());
  const headerRow = rows.at(0);
  const bodyRows = rows.slice(1, maxRows);

  const headerCols = headerRow?.split('|') || [];
  const rowDataItems = bodyRows.map((r) => r.split('|'));

  const header = `| ${headerCols?.join(' | ')} |`;
  const separator = `| ${headerCols?.map(() => '---').join(' | ')} |`;
  const body = rowDataItems.map((r) => `| ${r.join(' | ')} |`).join('\n');

  const final = [header, separator, body].join('\n');

  return final;
}

export function translateSourceTableToCsv(sourceTable?: string) {
  if (!sourceTable) {
    return undefined;
  }

  const rows = sourceTable.split('\n').map((s) => s.trim());
  const headerRow = rows.at(0);
  const bodyRows = rows.slice(1);

  const headerCols = (headerRow?.split('|') || [])
    .map((h) => (h.includes(',') ? `"${h}"` : h));

  const rowDataItems = bodyRows.map(
    (r) => r.split('|')
      .map((d) => (d.includes(',') ? `"${d}"` : d)),
  );

  const header = `${headerCols?.join(',')}`;
  const body = rowDataItems.map((r) => `${r.join(',')}`).join('\n');

  const final = [header, body].join('\n');

  return final;
}

const hasCodeTableInLine = (line: string) => (
  /\+(-+)*/g.test(line) && line.startsWith('+-')
);

export function removeCodeTableLines(lines: string[]) {
  const updatedLines: string[] = [];

  // Remove starts and end of table created by code like this +-----+-----+
  let codeTableStarts = false;
  let foundCodeTableHeader = false;

  lines.forEach((line) => {
    if (hasCodeTableInLine(line) && !codeTableStarts) {
      codeTableStarts = true;
      return;
    }

    if (codeTableStarts && !foundCodeTableHeader && hasCodeTableInLine(line)) {
      updatedLines.push(line);
      foundCodeTableHeader = true;
      return;
    }

    if (codeTableStarts && foundCodeTableHeader && hasCodeTableInLine(line)) {
      codeTableStarts = false;
      return;
    }

    updatedLines.push(line);
  });

  return updatedLines;
}

export function removeTableBlankLines(lines: string[]) {
  const updatedLines: string[] = [];

  let isInTableLine = false;

  lines.forEach((line) => {
    const trimmedLine = line.trim();
    if (line.includes('|')) {
      isInTableLine = true;
    }

    if (isInTableLine && trimmedLine) {
      updatedLines.push(line);
    }

    if (!isInTableLine) {
      updatedLines.push(line);
    }

    if (trimmedLine && isInTableLine && !line.includes('|')) {
      isInTableLine = false;
    }
  });

  return updatedLines;
}

export default function refineTableInAnswer(answer?: string) {
  if (!answer) {
    return '';
  }

  const updatedAnswer = translateHtmlTable(`${answer}\n`)
    .replace(/```markdown/g, '')
    .replace(/```$/g, '');

  const lines = removeTableBlankLines(
    removeCodeTableLines(updatedAnswer.split(/\n|\r\n/g)),
  );

  const updatedLines: string[] = [];

  let tableStarted = false;
  let isPotentialTable = false;
  let potentialTableCols: string[] = [];
  lines.forEach((line) => {
    if (isPotentialTable && /[-]{2,}/g.test(line)) {
      updatedLines.push(
        `| ${potentialTableCols.map(() => '---').join(' | ')} |`,
      );

      return;
    }

    isPotentialTable = false;

    if (line.includes('|')) {
      isPotentialTable = true;
      potentialTableCols = line.replace(/\|/g, ' | ').split(' | ').map((col) => (
        !col ? '' : col
      )).filter((p) => !!p);
    }

    if (line.trim() || tableStarted) {
      if (line.trim().startsWith('|') || line.trim().includes(' | ')) {
        updatedLines.push(
          `| ${potentialTableCols.join(' | ')} |`,
        );
      } else {
        updatedLines.push(line);
      }
      tableStarted = true;
    }
  });

  return updatedLines
    .filter((line) => !hasCodeTableInLine(line))
    .join('\n');
}

export function isNakedAndReactStreamAnswerKey(part: string) {
  return [
    'Thought:',
    'Action:',
    'Action Input:',
    'Answer:',
  ].includes(part) || /^Action [0-9]+:$/.test(part);
}

export function isSelfAskStreamAnswerKey(part: string) {
  return [
    'Thought:',
    'Agent:',
    'Objective:',
    'Action:',
    'Action Input:',
    'Output:',
    'Intermediate question:',
    'Are intermediate questions needed here:',
    'Intermediate answer:',
    'Final Answer:',
  ].includes(part);
}

export function refineNakedAndReactStreamAnswer(streamAnswer: string) {
  const parts = streamAnswer
    .replace(/Final Answer:/g, 'Answer:')
    .replace(/Message to Answer:/g, 'Ignore:')
    .split(/(Thought:|Action:|Action [0-9]+:|Action Input:|Answer:|Ignore:|<br>)/g)
    .map((s) => s.trim())
    .filter((s) => !!s && s !== '<br>');

  const startIndex = parts.findIndex((part) => (
    ['Thought:', 'Action:', 'Action Input:'].includes(part)
  )) || 0;

  const processingParts: string[] = [];
  let expectNextKeyIndex: number | undefined;
  let skipNextIndex = false;

  parts.forEach((part, index) => {
    if (index < startIndex) {
      return;
    }

    if (part === 'Ignore:') {
      skipNextIndex = true;
      return;
    }

    if (skipNextIndex) {
      skipNextIndex = false;
      return;
    }

    if (part === 'Action Input:') {
      expectNextKeyIndex = index + 2;
      processingParts.push(part);
      return;
    }

    if (index === expectNextKeyIndex && !isNakedAndReactStreamAnswerKey(part)) {
      processingParts.push('Answer:');
      expectNextKeyIndex = undefined;
    }

    processingParts.push(part);
  });

  // Extract all Answer: from the final parts and combine it into one.
  const answerKeyIndexes: number[] = [];
  const finalParts: string[] = [];
  processingParts.forEach((part, index) => {
    if (part === 'Answer:') {
      answerKeyIndexes.push(index);
      skipNextIndex = true;
      return;
    }

    if (!processingParts[index].endsWith(':')) {
      if (!skipNextIndex) {
        finalParts.push(part);
      }

      skipNextIndex = false;
    } else {
      finalParts.push(part);
    }
  });

  const answers: string[] = [];
  answerKeyIndexes.forEach((index) => {
    if (answerKeyIndexes.includes(index)) {
      const foundAnswer = processingParts.at(index + 1);
      if (foundAnswer) {
        answers.push(foundAnswer);
      }
    }
  });

  const finalAnswer = answers.join('\n');
  if (finalAnswer.trim()) {
    finalParts.push('Answer:');
    finalParts.push(finalAnswer);
  }

  return finalParts;
}

export function refinePlanExecuteStreamAnswer(streamAnswer: string) {
  let initialParts = streamAnswer
    .replace(/Final Answer:/g, 'Answer:')
    .split(planExectureKeysRegex)
    .map((s) => s.trim()).filter((s) => !!s && s !== '<br>');

  // Non: In case of initialParts[0] immediately starts with "Analyst",
  //      we need to put "Agent:" before.
  if (initialParts.at(0)?.includes('"Analyst"')) {
    initialParts = [
      'Agent:',
      ...initialParts,
    ];
  }

  const agentIndex = initialParts.findIndex((part) => part === 'Agent:');
  const parts = initialParts.slice(agentIndex, initialParts.length);
  const beforeAgents = agentIndex > 0 ? initialParts.slice(0, agentIndex) : [];

  const processingParts: string[] = [];
  let skipNextIndex: boolean = false;

  beforeAgents.forEach((part) => {
    processingParts.push('Thought:');
    processingParts.push(part);
  });

  parts.forEach((part) => {
    // Non: If there are another "Objective", second one should be ignored.
    if (part === 'Objective:' && processingParts.find((p) => p === 'Objective:')) {
      skipNextIndex = true;
      return;
    }

    if (skipNextIndex) {
      skipNextIndex = false;
      return;
    }

    processingParts.push(part);
  });

  // Remove non-key part which is not supposed to be at that index.
  const potentialFinalAnswers: string[] = [];
  const finalParts: string[] = [];
  processingParts.forEach((part) => {
    if (!isSelfAskStreamAnswerKey(part)
      && finalParts.length % 2 === 0
    ) {
      potentialFinalAnswers.push(part);
      return;
    }

    finalParts.push(part);
  });

  if (potentialFinalAnswers.length > 0) {
    return [
      ...finalParts,
      'Answer:',
      potentialFinalAnswers.at(potentialFinalAnswers.length - 1) || '',
    ];
  }

  return finalParts;
}

export function refineSelfAskStreamAnswer(streamAnswer: string) {
  let initialParts = streamAnswer
    .replace(/Final Answer:/g, 'Answer:')
    .split(selfAskKeysRegex)
    .map((s) => s.trim()).filter((s) => !!s && s !== '<br>');

  // Non: In case of initialParts[0] immediately starts with "Analyst",
  //      we need to put "Agent:" before.
  if (initialParts.at(0)?.includes('"Analyst"')) {
    initialParts = [
      'Agent:',
      ...initialParts,
    ];
  }

  const agentIndex = initialParts.findIndex((part) => part === 'Agent:');
  const parts = initialParts.slice(agentIndex, initialParts.length);
  const beforeAgents = agentIndex > 0 ? initialParts.slice(0, agentIndex) : [];

  const processingParts: string[] = [];
  let skipNextIndex: boolean = false;

  beforeAgents.forEach((part) => {
    processingParts.push('Thought:');
    processingParts.push(part);
  });

  parts.forEach((part) => {
    if (part === 'Are intermediate questions needed here:'
      || part === 'Intermediate answer:'
    ) {
      skipNextIndex = true;
      return;
    }

    // Non: If there are another "Objective", second one should be ignored.
    if (part === 'Objective:' && processingParts.find((p) => p === 'Objective:')) {
      skipNextIndex = true;
      return;
    }

    if (skipNextIndex) {
      skipNextIndex = false;
      return;
    }

    processingParts.push(part);
  });

  // Remove non-key part which is not supposed to be at that index.
  const potentialFinalAnswers: string[] = [];
  const finalParts: string[] = [];
  processingParts.forEach((part) => {
    if (!isSelfAskStreamAnswerKey(part)
      && finalParts.length % 2 === 0
    ) {
      potentialFinalAnswers.push(part);
      return;
    }

    finalParts.push(part);
  });

  if (potentialFinalAnswers.length > 0) {
    return [
      ...finalParts,
      'Answer:',
      potentialFinalAnswers.at(potentialFinalAnswers.length - 1) || '',
    ];
  }

  return finalParts;
}

export function refineStreamAnswer(streamAnswer: string, agent?: string) {
  if (agent === 'self_ask') {
    return refineSelfAskStreamAnswer(streamAnswer);
  }

  if (agent === 'plan_execute') {
    return refinePlanExecuteStreamAnswer(streamAnswer);
  }

  return refineNakedAndReactStreamAnswer(streamAnswer);
}

export function getIntermediateStepOutput(intermediateStep: IntermediateStep) {
  const outputData = intermediateStep?.length > 0 ? intermediateStep[1] : '';

  if (typeof outputData === 'string') {
    if (outputData.startsWith('[{\'')) {
      return '';
    }

    return outputData;
  }

  if (!Array.isArray(outputData)) {
    if (outputData.result) {
      return outputData.result;
    }

    if (outputData.sources) {
      return JSON.stringify(outputData.sources);
    }

    const keys = Object.keys(outputData);
    const firstKey = keys && keys.at(0);
    const record = outputData as unknown as Record<string, string>;
    if (record && firstKey && record[firstKey]) {
      return record[firstKey];
    }
  }

  return '';
}

export function getUrlInfo(source: string) {
  try {
    const url = new URL(source);
    return {
      domain: url.hostname,
      favIconUrl: `https://www.google.com/s2/favicons?domain=${url.hostname}&sz=256`,
    };
  } catch {
    return undefined;
  }
}

export function extractSourcesFromContent(content?: string) {
  if (!content) {
    return [];
  }

  const startIndexOfSource = content.indexOf('Sources:\n');
  if (startIndexOfSource < 0) {
    return [];
  }

  const sourcePhrase = content
    .substring(startIndexOfSource)
    .replace('Sources:\n', '');

  const sources = sourcePhrase.split('\n')
    .map((source) => source.split(' ').at(1))
    .filter((source) => !!source)
    .map((source) => ({
      metadata: {
        source,
      },
      ...getUrlInfo(source as string),
    }) as SourceInfo);

  return sources;
}

export function getSourceInfo(source: ApiSourceInfo) {
  return {
    index: source.index,
    sourceId: source.source_id,
    sourcePageNo: source.page_no,
    metadata: {
      title: source.title,
      source: source.href,
      question: source.question,
      answerType: source.answer_type,
    },
    pageContent: source.body,
    table: source.table,
    ...getUrlInfo(source.href),
  } as SourceInfo;
}

export function getApiSourceInfo(source: SourceInfo, index: number) {
  return {
    index,
    title: source.metadata?.title || '',
    href: source.metadata?.source || '',
    body: source.pageContent || '',
  } as ApiSourceInfo;
}

export function parseSourceInfosFromOutputData(outputData: string, keys: string[]) {
  if (keys.length === 0) {
    return [];
  }

  const regexKeys = keys.map((key) => `${key}:`).join('|');
  const regex = new RegExp(`(${regexKeys})`, 'g');

  const parts = outputData
    .split(regex)
    .map((s) => s.trim()).filter((s) => !!s && s !== '<br>');

  const startKeyIndex = parts.findIndex((
    (s) => s.endsWith(':')
  ));

  const finalParts = parts.slice(startKeyIndex);
  const keyValues: ChatStepItemInfo[] = [];

  let keyName: string;

  finalParts.forEach(((s) => {
    const isKey = s.endsWith(':');
    const key = s.replace(':', '');

    if (isKey) {
      keyName = key;
      keyValues.push({
        name: key,
        value: '',
      });
    }

    if (!isKey) {
      if (!keyValues.length) {
        keyValues.push({
          name: keys[0],
          value: '',
        });
      }

      const keyValue = keyValues[keyValues.length - 1];

      if (keyValue && keyName) {
        keyValue.name = keyName;
        keyValue.value = `${keyValue.value || ''}${s}`;
      }
    }
  }));

  const sourceInfos: SourceInfo[] = [];
  keyValues.forEach((kv) => {
    if (kv.name === keys[0]) {
      sourceInfos.push({
        metadata: {
          title: kv.value,
        },
      });

      return;
    }

    sourceInfos[sourceInfos.length - 1].pageContent = kv.value;
  });

  return sourceInfos;
}

export function getIntermediateStepSources(intermediateStep: IntermediateStep) {
  const outputData = intermediateStep?.length > 1 ? intermediateStep[1] : '';

  if (Array.isArray(outputData)) {
    return outputData;
  }

  if (outputData && typeof outputData === 'object') {
    const outputDataObj = outputData as unknown as Record<string, unknown>;
    if ('sources' in outputDataObj && Array.isArray(outputDataObj.sources)) {
      return outputDataObj.sources.map((s) => getSourceInfo(s as ApiSourceInfo));
    }
  }

  if (typeof outputData === 'string') {
    if (outputData.startsWith('Page:')) {
      return parseSourceInfosFromOutputData(outputData, ['Page', 'Summary']);
    }

    if (outputData.startsWith('[Document(page_content=')) {
      const refinedOutput = refineJsonOutput(outputData);

      const items = JSON.parse(jsonrepair(refinedOutput)) as Record<string, unknown>[];
      return items
        .map((item) => ({
          pageContent: item.page_content,
          metadata: item.metadata,
        }) as SourceInfo);
    }

    if (isRepairableJson(outputData)) {
      const parsed = JSON.parse(jsonrepair(outputData));

      if (Array.isArray(parsed)
        && (parsed.at(0)
        && typeof parsed.at(0) === 'object'
        && 'name' in parsed.at(0)
        && 'country' in parsed.at(0))) {
        return [];
      }

      if (Array.isArray(parsed)
        && (parsed.at(0)
        && typeof parsed.at(0) === 'object'
        && 'page_content' in parsed.at(0))) {
        return parsed.map((p) => ({
          ...parsed,
          pageContent: p.page_content,
        }) as unknown as SourceInfo);
      }
    }
  }

  try {
    const nonSpecialCharOutput = (outputData as string).replace(/[\u{0080}-\u{FFFF}]/gu, '\\$&');
    const refinedOutput = jsonrepair(nonSpecialCharOutput);
    const sources = JSON.parse(refinedOutput) as ApiSourceInfo[];

    const sourceInfos = sources.map((s) => getSourceInfo(s));

    return (sourceInfos || []).filter((s) => !!s.metadata?.title || !!s.domain);
  } catch (e) {
    return [];
  }
}

export function getIntermediateStepObjectives(intermediateStep: IntermediateStep) {
  const stepData = intermediateStep?.length > 0 ? intermediateStep[0] : undefined;

  if (!stepData || typeof stepData !== 'object') {
    return undefined;
  }

  const { toolInput } = (stepData as unknown as Record<string, string>);
  if (!toolInput) {
    return undefined;
  }

  try {
    const toolInputObj = JSON.parse(toolInput) as Record<string, string>;
    return toolInputObj.objective || toolInputObj.query;
  } catch {
    if (typeof toolInput !== 'string') {
      return undefined;
    }

    return toolInput;
  }
}

export function createNakedAndReactStepInfos(parts: string[]) {
  const stepInfos: ChatStepInfo[] = [];

  const startKeyIndex = parts.findIndex((
    (s) => s.endsWith(':')
  ));

  const keys = [
    'Thought:',
    'Action:',
    'Action Input:',
    'Observation:',
    'Final Answer:',
    'Answer:',
  ];

  const finalParts = parts.slice(startKeyIndex);
  let keyName: string;

  finalParts.forEach(((s) => {
    const isKey = keys.includes(s);
    const key = s.replace(':', '');
    const lastStep = stepInfos.length > 0
      ? stepInfos[stepInfos.length - 1]
      : undefined;

    const hasInputInLastStep = (
      lastStep?.items.find((item) => item.name === 'Action Input')
    );

    if (isKey) {
      keyName = key;
    }

    if (isKey && (
      key === 'Thought'
      || (
        lastStep && (
          key.startsWith('Action')
          && hasInputInLastStep
        ))
      || (
        !lastStep && (
          key.startsWith('Action')
        )
      )
    )) {
      stepInfos.push({
        items: [],
      });
    }

    if (!isKey) {
      if (!stepInfos.length) {
        stepInfos.push({
          items: [],
        });
      }

      const stepInfo = stepInfos[stepInfos.length - 1];

      if (stepInfo && keyName) {
        const refinedStepValue = keyName.includes('Input')
          ? s.split('\n').filter((ss) => ss !== 'Observ').join('\n')
          : s;

        const existingStepItem = stepInfo.items.find((item) => item.name === keyName);
        if (!existingStepItem) {
          stepInfo.items.push(({
            name: keyName,
            value: refinedStepValue,
          }));

          return;
        }

        existingStepItem.value = `${existingStepItem.value}${refinedStepValue}`;
      }
    }
  }));

  // Remove duplicated Thought:
  let alreadyHasThought = false;
  const finalStepInfos = stepInfos.map((stepInfo) => {
    const { items } = stepInfo;
    const refinedItems = items.map((item) => {
      if (item.name === 'Thought' && !alreadyHasThought) {
        alreadyHasThought = true;
        return item;
      }

      if (item.name === 'Thought' && alreadyHasThought) {
        return undefined;
      }

      return item;
    }).filter((item): item is ChatStepItemInfo => !!item);

    return {
      ...stepInfo,
      items: refinedItems,
    };
  });

  // In case there is only one stepInfos and has no item,
  // make given part as an answer.
  if (finalStepInfos.length === 1 && parts.length === 1) {
    finalStepInfos.at(0)?.items.push({
      name: 'Answer',
      value: parts.at(0) || '',
    });
  }

  return finalStepInfos;
}

export function createPlanExecuteStepInfos(parts: string[]) {
  const stepInfos: ChatStepInfo[] = [];

  const answerPartIndex = parts.findIndex((part) => part === 'Answer:');
  const answerPart = answerPartIndex >= 0 ? parts[answerPartIndex + 1] : undefined;

  const keys = [
    'Thought:',
    'Agent:',
    'Objective:',
  ];

  if (answerPart) {
    parts.pop();
    parts.pop();
  }

  let keyName: string;

  parts.forEach(((s) => {
    const isKey = keys.includes(s);
    const key = s.replace(':', '');

    if (isKey) {
      keyName = key;
    }

    if (isKey && (
      key === 'Agent'
      || key === 'Thought'
    )) {
      stepInfos.push({
        items: [],
      });
    }

    if (!isKey && keyName) {
      const prevStepInfo = stepInfos[stepInfos.length - 1];

      if (!prevStepInfo) {
        return;
      }

      const existingStepItem = prevStepInfo.items.find((item) => item.name === keyName);

      if (!existingStepItem) {
        prevStepInfo.items.push({
          name: keyName,
          value: s,
        });

        return;
      }

      existingStepItem.value = `${existingStepItem.value}${s}`;
    }
  }));

  if (answerPart) {
    stepInfos.push({
      items: [{
        name: 'Answer',
        value: answerPart,
      }],
    });
  }

  return stepInfos;
}

export function createSelfAskChatStepInfos(parts: string[]) {
  const stepInfos: ChatStepInfo[] = [];

  const answerPartIndex = parts.findIndex((part) => part === 'Answer:');
  const answerPart = answerPartIndex >= 0 ? parts[answerPartIndex + 1] : undefined;

  const keys = [
    'Thought:',
    'Agent:',
    'Objective:',
    'Intermediate question:',
    'Intermediate answer:',
    'Action:',
    'Action Input:',
    'Output:',
  ];

  const stepNameMap = {
    'Thought:': 'Thought',
    'Agent:': 'Agent',
    'Objective:': 'Objective',
    'Intermediate question:': 'Task',
    'Action:': 'Action',
    'Action Input:': 'Action Input',
    'Output:': 'Output',
  } as Record<string, string>;

  if (answerPart) {
    parts.pop();
    parts.pop();
  }

  let keyName: string;

  parts.forEach(((s) => {
    const isKey = keys.includes(s);
    const key = s.replace(':', '');

    if (isKey) {
      keyName = stepNameMap[s.trim()];
    }

    if (isKey && (
      key === 'Agent'
      || key === 'Thought'
      || key === 'Intermediate question'
    )) {
      stepInfos.push({
        items: [],
      });
    }

    if (!isKey) {
      const prevStepInfo = stepInfos[stepInfos.length - 1];

      if (!prevStepInfo) {
        return;
      }

      const existingStepItem = prevStepInfo.items.find((item) => item.name === keyName);
      if (!existingStepItem) {
        prevStepInfo.items.push({
          name: keyName,
          value: s,
        });

        return;
      }

      existingStepItem.value = `${existingStepItem.value}${s}`;
    }
  }));

  if (answerPart) {
    stepInfos.push({
      items: [{
        name: 'Answer',
        value: answerPart,
      }],
    });
  }

  return stepInfos;
}

export function createChatStepInfos(streamAnswer: string, agent?: string) {
  const parts = refineStreamAnswer(streamAnswer, agent);

  if (agent === 'self_ask') {
    return createSelfAskChatStepInfos(parts);
  }

  if (agent === 'plan_execute') {
    return createPlanExecuteStepInfos(parts);
  }

  return createNakedAndReactStepInfos(parts);
}

export function extractIntermediateStepAttachment(intermediateStep: IntermediateStep) {
  const { tool } = (intermediateStep[0] as unknown as IntermediateStepHeader);
  const potentialData = intermediateStep[1];

  if (!potentialData || potentialData === 'No results found for the query') {
    return undefined;
  }

  if (typeof potentialData === 'object') {
    return potentialData as unknown as Record<string, string>;
  }

  const name = `${tool} ${format(new Date(), 'yyyy-MM-dd')}`;
  return {
    [name]: potentialData,
  };
}

export function extractIntermediateStepsAttachments(intermediateSteps: IntermediateStep[]) {
  const consideredSteps = intermediateSteps.filter((step) => typeof step[0] === 'object');

  return consideredSteps
    .map((stepItems) => extractIntermediateStepAttachment(stepItems))
    .filter((data): data is Record<string, string> => !!data);
}

export function parseNakedAndReactIntermediateSteps(
  intermediateSteps: IntermediateStep[],
  agent?: string,
) {
  return intermediateSteps.flatMap((intermediateStep) => {
    const attachment = extractIntermediateStepAttachment(intermediateStep);

    const stepInfo = intermediateStep[0] as unknown as Record<string, string>;
    const stepInfoCombined = `${stepInfo.log || ''}\nAction: ${stepInfo.tool}\nAction Input: ${stepInfo.toolInput || ''}`;
    const stepOutput = getIntermediateStepOutput(intermediateSteps[0]);

    const steps = createChatStepInfos(stepInfoCombined, agent);
    return steps.map((s) => ({
      ...s,
      items: [
        ...s.items,
        {
          name: 'Output',
          value: stepOutput,
        },
      ],
      attachments: attachment ? [attachment] : undefined,
    }));
  });
}

export function parseSelfAskIntermediateSteps(intermediateSteps: IntermediateStep[]) {
  const attachments = extractIntermediateStepsAttachments(intermediateSteps);

  return intermediateSteps.map((intermediateStep) => {
    const stepInfo = intermediateStep[0] as unknown as Record<string, string>;
    if (stepInfo.thought) {
      return {
        items: [{
          name: 'Thought',
          value: stepInfo.thought,
        }],
      };
    }

    if (stepInfo.agent || stepInfo.objective) {
      return {
        items: [{
          name: 'Agent',
          value: stepInfo.agent,
        }, {
          name: 'Objective',
          value: stepInfo.objective,
        }],
      };
    }

    return {
      items: [{
        name: 'Task',
        value: stepInfo.task,
      }, {
        name: 'Action',
        value: stepInfo.tool,
      }, {
        name: 'Action Input',
        value: stepInfo.toolInput,
      }, {
        name: 'Output',
        value: stepInfo.toolOutput,
      }],
      attachments,
    };
  });
}

export function parsePlanExecuteIntermediateSteps(intermediateSteps: IntermediateStep[]) {
  const attachments = extractIntermediateStepsAttachments(intermediateSteps);

  return intermediateSteps.map((intermediateStep) => {
    const stepInfo = intermediateStep[0] as unknown as Record<string, string>;
    if (stepInfo.agent || stepInfo.objective || stepInfo.thought) {
      return {
        items: [{
          name: 'Agent',
          value: stepInfo.agent,
        }, {
          name: 'Objective',
          value: stepInfo.objective,
        }],
      };
    }

    if (stepInfo.task) {
      return {
        items: [{
          name: 'Task',
          value: stepInfo.task,
        }, {
          name: 'Output',
          value: stepInfo.toolOutput,
        }, {
          name: 'Action',
          value: stepInfo.tool,
        }],
        attachments,
        isPlanExecuteTask: true,
      };
    }

    return {
      items: [],
      attachments: undefined,
    };
  });
}

export function parseIntermediateSteps(intermediateSteps: IntermediateStep[], agent?: string) {
  if (agent === 'self_ask') {
    return parseSelfAskIntermediateSteps(intermediateSteps);
  }

  if (agent === 'plan_execute') {
    return parsePlanExecuteIntermediateSteps(intermediateSteps);
  }

  return parseNakedAndReactIntermediateSteps(intermediateSteps, agent);
}

export function createCsvBase64Uri(csvValue: string) {
  const data = csvValue
    .split(/\n|\\n/)
    .map((str, i) => {
      const s = str.replace(/[a-zA-Z0-9 ]*:/g, '')
        .replace(/\s\|\s/g, ', ');

      if (i === 0) {
        return (s.startsWith(',') ? `_${s}` : s);
      }

      return (s.startsWith(',') ? s.substring(1) : s);
    })
    .join('\n');

  const base64 = Base64.encode(data);
  return `data:text/csv;base64,${base64}`;
}

export function createDataItems(csvValue: string) {
  const refined = csvValue
    .split('\n')
    .map((s, i) => {
      if (i === 0) {
        return (s.startsWith(',') ? `_${s}` : s);
      }

      return (s.startsWith(',') ? s.substring(1) : s);
    })
    .join('\n');

  const parsed = parse<string[]>(refined);
  const { data } = parsed;

  return {
    headers: data[0]
      .map((item) => (
        item.replace(/"/g, '')
      )),
    items: data
      .slice(1)
      .filter((item) => !!item),
  };
}

export function shouldExcludeFromAttachment(rawData: string) {
  if (typeof rawData !== 'string') {
    return true;
  }

  const data = rawData
    .replace(/^"/g, '')
    .replace(/"$/g, '');

  if (isJsonString(data)) {
    // Do not include company search result as attachment.
    const obj = JSON.parse(data);
    const firstItem = obj && Array.isArray(obj)
      ? obj.at(0) : obj;

    if (
      'id' in firstItem
      && 'logo' in firstItem
      && 'name' in firstItem
      && 'description' in firstItem
      && 'country' in firstItem
    ) {
      return true;
    }
  }

  return (
    data.startsWith('Page:')
    || data.startsWith('[Document(page_content=')
    || data.startsWith('{\'question\':')
    || data.startsWith('{\'exception\':')
    || data.startsWith('{"sources":')
  );
}

export function extractStepInfosOrgs(steps: ChatStepInfo[]) {
  const companyItems = steps.flatMap((step) => {
    const outputItem = step.items.find((item) => item.name === 'Output');
    const outputValue = outputItem?.value || '';

    if (typeof outputValue !== 'string') {
      return undefined;
    }

    const refinedValue = (outputValue.startsWith('"') && outputValue.endsWith('"'))
      ? outputValue.substring(1, outputValue.length - 2)
      : outputValue;

    if (
      !refinedValue
      || !refinedValue.startsWith('[{')
      || !isRepairableJson(refinedValue)
    ) {
      return undefined;
    }

    const parsed = JSON.parse(jsonrepair(refinedValue));

    if (!Array.isArray(parsed) || parsed.length === 0) {
      return undefined;
    }

    if (!('name' in parsed[0] && 'country' in parsed[0])) {
      return undefined;
    }

    return parsed as CompanyItem[];
  }).filter((item): item is CompanyItem => !!item);

  return companyItems;
}

export function getCsvFromJsonArray(items: Record<string, unknown>[]) {
  if (items.length === 0) {
    return '';
  }

  const firstRow = items.at(0);
  const isArrayData = Array.isArray(firstRow);
  const keys = Object.keys(firstRow || {});

  const headers = isArrayData ? firstRow.join(',') : keys.join(',');
  const body = (isArrayData ? items.slice(1) : items).map((item) => (
    keys.map((key) => item[key] || '').join(',')
  )).join('\n');

  return `${headers}\n${body}`;
}

export function getPotentialCsvFromJson(data: Record<string, string>) {
  if (!data) {
    return undefined;
  }

  const keys = Object.keys(data);
  const firstKey = keys && keys.at(0);

  if (!firstKey) {
    return undefined;
  }

  return data[firstKey];
}

export function parseFileContents(
  rawData: string,
  options?: {
    key?: string,
    source?: string,
  },
) {
  if (rawData.startsWith('{\'query\':')
    || rawData.startsWith('[{page_content:\'')
    || rawData.startsWith('"[{page_content:\'')
    || rawData.startsWith('[{\'id\':')
    || rawData.startsWith('"[{\'id\':')
  ) {
    return undefined;
  }

  // Non: Special case when attachment is Metadata Table.
  if (typeof rawData === 'string') {
    const jsonData = isRepairableJson(rawData)
      ? jsonrepair(rawData)
      : undefined;

    if (jsonData) {
      try {
        const potentialDataObj = JSON.parse(jsonData);
        const potentialDataItems = Array.isArray(potentialDataObj)
          ? potentialDataObj
          : [potentialDataObj];

        const metaDataTable = potentialDataItems
          .filter((t: Record<string, unknown>) => (
            t.index === 0
            && t.title === 'Metadata Table'
            && !!t.table
          ))
          .at(0);

        if (metaDataTable) {
          const csvData = translateSourceTableToCsv(metaDataTable.table as string) || '';

          return {
            fileName: options?.key || 'result',
            fileType: 'csv',
            href: createCsvBase64Uri(csvData),
            data: createDataItems(csvData),
            source: options?.source,
            isSourceMetaTable: true,
          } as ChatFileInfo;
        }
      } catch (_) {
        // just do nothing.
      }
    }
  }

  const data = rawData.replace(/[a-zA-Z0-9 ]*:/g, '')
    .replace(/^"/g, '')
    .replace(/"$/g, '')
    .trim();

  if (
    !!data
    && !data.startsWith('{')
    && !data.startsWith('[')
    && !data.startsWith('```')
    // && data.includes(',')
  ) {
    return {
      fileName: options?.key || 'result',
      fileType: 'csv',
      href: createCsvBase64Uri(data),
      data: createDataItems(data),
      source: options?.source,
    } as ChatFileInfo;
  }

  // Non: In case we got datetime.datetime in JSON.
  const refinedData = data.replace(/datetime\.datetime\([0-9, ]+\)/g, (m) => {
    const parts = m
      .replace('datetime.datetime(', '')
      .replace(')', '')
      .split(',')
      .map((p) => Number(p.trim()));

    const date = parts.reduce((final, part, i) => {
      switch (i) {
        case 0: final.setFullYear(part); break;
        case 1: final.setMonth(part - 1); break;
        case 2: final.setDate(part); break;
        case 3: final.setHours(part); break;
        case 4: final.setMinutes(part); break;
        case 5: final.setSeconds(part); break;
        default:
          return final;
      }

      return final;
    }, new Date());

    return `'${format(date, 'dd/MM/yyyy')}'`;
  });

  const parsedData = isRepairableJson(refinedData)
    ? JSON.parse(jsonrepair(refinedData))
    : undefined;

  const isParsedDataArray = Array.isArray(parsedData);

  const isParsedDataKindOfSources = isParsedDataArray
    && parsedData.at(0)
    && typeof parsedData.at(0) === 'object'
    && (
      'page_content' in parsedData.at(0)
      || (
        'index' in parsedData.at(0)
        && 'href' in parsedData.at(0)
        && 'body' in parsedData.at(0)
      )
      || (
        'title' in parsedData.at(0)
        && 'abstract' in parsedData.at(0)
      )
    );

  if (isParsedDataKindOfSources) {
    return undefined;
  }

  const csvData = (parsedData && isParsedDataArray)
    ? getCsvFromJsonArray(parsedData as [])
    : getPotentialCsvFromJson(parsedData as Record<string, string>);

  if (!csvData) {
    return undefined;
  }

  return {
    fileName: options?.key || 'result',
    fileType: 'csv',
    href: createCsvBase64Uri(csvData),
    data: createDataItems(csvData),
    source: options?.source,
  } as ChatFileInfo;
}

export function extractStepInfosFiles(steps: ChatStepInfo[]) {
  const files = steps.flatMap((step, index) => {
    const toolItem = step.items.find((item) => item.name === 'Action');
    const outputItem = step.items.find((item) => item.name === 'Output');
    const blankValues = [
      'no data found for the request',
      'No results found for the query',
    ];

    if (!toolItem && !step.attachments && !outputItem) {
      return undefined;
    }

    const fileInfos = (step.attachments || [])
      .filter((attachment) => !Array.isArray(attachment))
      .flatMap((attachment) => {
        const attachmentKeys = Object.keys(attachment);

        if (attachmentKeys.includes('result')) {
          const data = attachment.result;
          return [parseFileContents(
            data,
            {
              source: toolItem?.value,
            },
          )].filter((csv) => !!csv);
        }

        return attachmentKeys
          .filter((key) => {
            const data = attachment[key];
            if (
              !data
              || blankValues.includes(data)
              || shouldExcludeFromAttachment(data)
            ) {
              return false;
            }

            return true;
          })
          .map((key) => {
            const data = attachment[key];
            return parseFileContents(
              data,
              {
                key,
                source: toolItem?.value,
              },
            );
          })
          .filter((csv) => !!csv);
      });

    if (
      outputItem?.value
      && !blankValues.includes(outputItem?.value)
      && !shouldExcludeFromAttachment(outputItem?.value)
    ) {
      const name = [
        toolItem?.value,
        steps.length > 1 ? `${index + 1}` : '',
      ].join('');

      const fileType = 'csv';
      const fileName = [
        `${name} ${format(new Date(), 'yyyy-MM-dd')}`,
        fileType,
      ].join('.');

      const fileContent = parseFileContents(
        outputItem.value,
        {
          key: fileName,
          source: toolItem?.value,
        },
      );
      fileInfos.push(fileContent);
    }

    return fileInfos;
  }).filter((file): file is ChatFileInfo => !!file);

  // Non: This is to remove duplicated attachments.
  const refinedFiles = files.map((file) => {
    const nameParts = file.fileName.split('.');
    const partWithoutExtensions = nameParts.filter((part) => part !== file.fileType);
    return ({
      ...file,
      fileName: partWithoutExtensions.join('.'),
      data: undefined,
    });
  });

  const uniqueFiles: ChatFileInfo[] = [];
  refinedFiles.forEach((file) => {
    const foundIndex = uniqueFiles.findIndex((f) => f.href === file.href);
    if (foundIndex >= 0) {
      uniqueFiles[foundIndex].href = file.href;
      return;
    }

    uniqueFiles.push(file);
  });

  return uniqueFiles;
}

export function extractIntermediateStepsSources(
  intermediateSteps: IntermediateStep[],
  options?: {
    group: boolean,
  },
) {
  const sources = intermediateSteps.flatMap((is) => (
    getIntermediateStepSources(is)
  ));

  const noDuplicatedSources: SourceInfo[] = [];
  sources.forEach((source) => {
    const foundIndex = noDuplicatedSources.findIndex((s) => s.index === source.index);

    if (foundIndex < 0) {
      noDuplicatedSources.push(source);
    } else {
      noDuplicatedSources[foundIndex] = source;
    }
  });

  if (options?.group) {
    const grouped = noDuplicatedSources.reduce((r, source) => {
      const existingSource = r.find(
        (s) => s.metadata.source === source.metadata.source,
      );

      if (!existingSource) {
        return [
          ...r,
          source,
        ];
      }

      return r.map((s) => {
        if (s.metadata.source !== source.metadata.source) {
          return s;
        }

        return {
          ...s,
          pageContent: `${(s.pageContent || '')}\n\n\n\n${source.pageContent || ''}`.trim(),
        } as SourceInfo;
      });
    }, [] as SourceInfo[]);

    return grouped;
  }

  return noDuplicatedSources;
}

export function extractIntermediateStepsObjectives(
  intermediateSteps: IntermediateStep[],
) {
  const objectives = intermediateSteps.flatMap((is) => (
    getIntermediateStepObjectives(is)
  ));

  return objectives.filter((o): o is string => !!o);
}

export function getSiteExampleGroupIndexes() {
  return [
    'My Tools',
    'Pvalyou Tools',
    'Autonomous Agents',
    'External Tools',
    'Utilities',
  ];
}

export const getLastObjective = (chatItem: GptChatItem) => {
  const objectives = chatItem.objectives || [];
  if (!objectives || objectives.length === 0) {
    return undefined;
  }

  return objectives[objectives.length - 1];
};

export const parseDataValue = (input: string) => {
  if (!input.startsWith('[') && !input.startsWith('{')) {
    return input;
  }

  try {
    const jsonText = jsonrepair(input);
    const parsed = JSON.parse(jsonText) as unknown;

    if (Array.isArray(parsed)) {
      // Non: currently if it is array then it is sources.
      //      So we return json string of it instead because finally it will reparse from string.
      return jsonText;
    }

    return parsed;
  } catch {
    return input;
  }
};

export function extractNakedAndReactIntermediateSteps(chatAnswer: Record<string, unknown>) {
  const keys = Object.keys(chatAnswer);
  const intermediateSteps: IntermediateStep[] = [];

  const outputKeys = [
    'Thought:',
    'Action:',
    'Action Input:',
    'Observation:',
  ];

  const keyValueGroups: ChatStepItemInfo[][] = [];
  const additionalInputs: string[] = [];
  keys.forEach((key) => {
    if (key === '/logs/extract_triple_backticks/final_output') {
      const value = chatAnswer[key];
      if (typeof value === 'object' && !!value && 'output' in value) {
        const codeObj = value as { output: string };
        if (!!codeObj.output && typeof codeObj.output === 'string') {
          additionalInputs.push(`\`\`\`sql\n${String(codeObj.output)}\n\`\`\``);
        }
      }

      return;
    }

    if (key.endsWith('/final_output')) {
      const { output } = ((chatAnswer[key] || {}) as Record<string, string>);
      if (!output) {
        return;
      }

      if (typeof output === 'object') {
        return;
      }

      if (!(output.includes('Action:') || output.includes('Action Input:'))) {
        return;
      }

      const parts = output
        .split(reactKeysRegex)
        .map((s) => s.trim()).filter((s) => !!s && s !== '<br>');

      const startKeyIndex = parts.findIndex((
        (s) => s.endsWith(':')
      ));

      const finalParts = parts.slice(startKeyIndex);
      const keyValues: ChatStepItemInfo[] = [];

      finalParts.forEach(((s) => {
        const isKey = outputKeys.includes(s);
        const stepKey = s.replace(':', '');

        if (isKey) {
          keyValues.push({
            name: stepKey,
            value: '',
          });
        }

        if (!isKey) {
          if (!keyValues.length) {
            keyValues.push({
              name: 'Thought',
              value: '',
            });
          }

          const keyValue = keyValues[keyValues.length - 1];
          const prevKey = keyValue.name;

          if (keyValue) {
            keyValue.name = prevKey;
            keyValue.value = `${keyValue.value || ''}${s}`;
          }

          if (prevKey === 'Observation') {
            keyValueGroups.push([...keyValues]);
            keyValues.splice(0, keyValues.length);
          }
        }
      }));
    }
  });

  keyValueGroups.forEach((keyValues) => {
    const finalKeyValues = keyValues.filter((kv) => !!kv.value);
    const logItem = finalKeyValues.find((kv) => kv.name === 'Thought');
    const action = finalKeyValues.find((kv) => kv.name === 'Action');
    const actionInput = finalKeyValues.find((kv) => kv.name === 'Action Input');
    const data = finalKeyValues.find((kv) => kv.name === 'Observation');

    if (!data?.value) {
      return;
    }

    const toolInputs = [actionInput?.value];
    if (additionalInputs.length > 0) {
      const additionalInput = additionalInputs.at(0);
      if (additionalInput) {
        toolInputs.push(additionalInput);
      }

      additionalInputs.splice(0, 1);
    }

    const intermediateStepHeader = {
      log: logItem ? `${logItem.name}: ${logItem.value}` : '',
      tool: action?.value,
      type: 'AgentAction',
      toolInput: toolInputs.join('\n'),
    } as IntermediateStepHeader;

    const existingStepIndex = intermediateSteps.findIndex((is) => {
      const header = is[0] as unknown as IntermediateStepHeader;
      if (
        header.log === intermediateStepHeader.log
        && header.tool === intermediateStepHeader.tool
        && header.toolInput === intermediateStepHeader.toolInput
      ) {
        return true;
      }

      return false;
    });

    if (existingStepIndex >= 0) {
      intermediateSteps[existingStepIndex] = [
        intermediateStepHeader as unknown as string[],
        parseDataValue(data?.value || ''),
      ] as IntermediateStep;
    } else {
      intermediateSteps.push([
        intermediateStepHeader as unknown as string[],
        parseDataValue(data?.value || ''),
      ] as IntermediateStep);
    }
  });

  return [
    ...intermediateSteps,
  ];
}

export function parseStreamingTasks(streamingTask: object) {
  if (!streamingTask) {
    return undefined;
  }

  if ('tasks' in streamingTask) {
    const taskContainer = streamingTask as Record<string, unknown>;
    const { tasks } = taskContainer;
    if (Array.isArray(tasks)) {
      return tasks as PlanAndExecuteTask[];
    }

    return undefined;
  }

  return streamingTask as unknown as PlanAndExecuteTask[];
}

export function extractPlanExecuteIntermediateSteps(chatAnswer: Record<string, unknown>) {
  const keys = Object.keys(chatAnswer);
  const intermediateSteps: IntermediateStep[] = [];

  keys.forEach((key) => {
    if (key.endsWith('/final_output')) {
      const foundExistingAgentObjective = intermediateSteps.find((is) => {
        const isObj = is[0] as unknown as Record<string, string>;
        if (isObj.agent || isObj.objective) {
          return true;
        }

        return false;
      });

      const { output } = ((chatAnswer[key] || {}) as Record<string, string>);
      if (!output) {
        return;
      }

      if (typeof output === 'object') {
        return;
      }

      if (!(output.includes('Agent:') || output.includes('Objective:'))) {
        return;
      }

      const parts = output
        .split(planExectureKeysRegex)
        .map((s) => s.trim()).filter((s) => !!s && s !== '<br>');

      const agentIndex = parts.findIndex((p) => p === 'Agent:');
      const agent = agentIndex >= 0 && parts.at(agentIndex + 1);

      const objectiveIndex = parts.findIndex((p) => p === 'Objective:');
      const objective = objectiveIndex >= 0 && parts.at(objectiveIndex + 1);

      if (!foundExistingAgentObjective) {
        intermediateSteps.push([
          {
            agent,
            objective,
          } as unknown as string[],
          '',
        ] as IntermediateStep);
      }
    }
  });

  const rootStep = intermediateSteps.find((is) => {
    const header = (is.at(0) as unknown as Record<string, string>);
    if (header?.agent || header?.objective) {
      return true;
    }

    return false;
  });

  if (rootStep && !(rootStep[0] as unknown as Record<string, string>).agent) {
    (rootStep[0] as unknown as Record<string, string>).agent = '"Analyst"';
  }

  const generalSteps = intermediateSteps.filter((is) => is !== rootStep);
  const taskSteps: IntermediateStep[] = [];

  if (chatAnswer['/tasks']) {
    const taskWrappers = chatAnswer['/tasks'] as object[];
    const latestTaskSet = taskWrappers.at(taskWrappers.length - 1);
    const tasks = latestTaskSet
      ? parseStreamingTasks(latestTaskSet) || []
      : [];

    const steps = tasks.map((task) => ([
      {
        task: task.task || 'unknown',
        tool: task.tool,
        toolInput: task.tool_input,
        toolOutput: task.tool_output ? JSON.stringify(task.tool_output) : '',
      } as unknown as string[],
      task.tool_output ? JSON.stringify(task.tool_output) : '',
    ]) as IntermediateStep);

    steps.forEach((step) => {
      taskSteps.push(step);
    });
  }

  return [
    ...(rootStep ? [rootStep] : []),
    ...generalSteps,
    ...taskSteps,
  ];
}

export function extractSelfAskIntermediateSteps(chatAnswer: Record<string, unknown>) {
  const keys = Object.keys(chatAnswer);
  const intermediateSteps: IntermediateStep[] = [];

  keys.forEach((key) => {
    if (!key.includes('Intermediate Answer') && key.endsWith('/final_output')) {
      const foundExistingAgentObjective = intermediateSteps.find((is) => {
        const isObj = is.at(0) as unknown as Record<string, string>;
        if (isObj?.agent || isObj?.objective) {
          return true;
        }

        return false;
      });

      const foundExistingThought = intermediateSteps.find((is) => {
        const isObj = is.at(0) as unknown as Record<string, string>;
        if (isObj?.thought) {
          return true;
        }

        return false;
      });

      const { output } = ((chatAnswer[key] || {}) as Record<string, string>);
      if (!output) {
        return;
      }

      if (typeof output === 'object') {
        return;
      }

      if (!(output.includes('Agent:') || output.includes('Objective:'))) {
        return;
      }

      const parts = output
        .split(selfAskKeysRegex)
        .map((s) => s.trim()).filter((s) => !!s && s !== '<br>');

      const agentIndex = parts.findIndex((p) => p === 'Agent:');
      const agent = agentIndex >= 0 && parts.at(agentIndex + 1);

      const objectiveIndex = parts.findIndex((p) => p === 'Objective:');
      const objective = objectiveIndex >= 0 && parts.at(objectiveIndex + 1);

      if (agentIndex > 0 && !foundExistingThought) {
        const thought = parts.at(0);
        intermediateSteps.push([
          {
            thought,
          } as unknown as string[],
          '',
        ] as IntermediateStep);
      }

      if (!foundExistingAgentObjective) {
        intermediateSteps.push([
          {
            agent,
            objective,
          } as unknown as string[],
          '',
        ] as IntermediateStep);
      }
    }

    if (key.includes('Intermediate Answer') && key.endsWith('/final_output')) {
      const { output } = ((chatAnswer[key] || {}) as Record<string, string>);
      if (!output) {
        return;
      }

      const refinedOutput = refineJsonOutput(output);
      const outputItems = JSON.parse(jsonrepair(refinedOutput)) as Record<string, unknown>[];
      const outputItem = outputItems.at(0);
      const task = outputItem?.task || '';
      const tool = outputItem?.tool || '';
      const toolInput = outputItem?.tool_input || '';
      const toolOutput = JSON.stringify(outputItem?.tool_output || {});

      if (tool && toolInput && toolOutput) {
        intermediateSteps.push([
          {
            task,
            tool,
            toolInput,
            toolOutput,
          } as unknown as string[],
          parseDataValue(toolOutput),
        ] as IntermediateStep);
      }
    }
  });

  const rootStep = intermediateSteps.find((is) => {
    const header = (is.at(0) as unknown as Record<string, string>);
    if (header?.agent || header?.objective) {
      return true;
    }

    return false;
  });

  if (rootStep && !(rootStep.at(0) as unknown as Record<string, string>)?.agent) {
    (rootStep[0] as unknown as Record<string, string>).agent = '"Analyst"';
  }

  const generalSteps = intermediateSteps.filter((is) => is !== rootStep);

  return [
    ...(rootStep ? [rootStep] : []),
    ...generalSteps,
  ];
}

export function extractIntermediateSteps(chatAnswer: Record<string, unknown>, agent?: string) {
  if (agent === 'self_ask') {
    return extractSelfAskIntermediateSteps(chatAnswer);
  }

  if (agent === 'plan_execute') {
    return extractPlanExecuteIntermediateSteps(chatAnswer);
  }

  return extractNakedAndReactIntermediateSteps(chatAnswer);
}

export function extractFinalAnswer(
  chatAnswer: Record<string, unknown>,
) {
  const chatAnswerKeys = Object.keys(chatAnswer);
  let finalAnswerKey = chatAnswerKeys.find((key) => {
    const data = chatAnswer[key];
    return typeof data === 'string' && data.includes('Answer:');
  });

  if (!finalAnswerKey) {
    const potentialAnswerKeys = chatAnswerKeys.filter((key) => {
      const data = chatAnswer[key];
      return data && (
        typeof data === 'string'
        || (typeof data === 'object' && 'output' in data)
      ) && key === '/final_output' && !!data;
    });

    finalAnswerKey = potentialAnswerKeys.length > 0
      ? potentialAnswerKeys[potentialAnswerKeys.length - 1]
      : undefined;

    const potentialAnswer = finalAnswerKey && chatAnswer[finalAnswerKey];

    if (!potentialAnswer) {
      const otherAnswerKeys = chatAnswerKeys.filter((key) => {
        const data = chatAnswer[key];
        return data && (
          typeof data === 'string'
          || (typeof data === 'object' && 'output' in data)
        ) && key === '/final_output/output' && !!data;
      });

      finalAnswerKey = otherAnswerKeys.length > 0
        ? otherAnswerKeys[otherAnswerKeys.length - 1]
        : undefined;
    }
  }

  const answerOutput = finalAnswerKey && chatAnswer[finalAnswerKey];
  if (!answerOutput) {
    return undefined;
  }

  if (typeof answerOutput === 'object'
    && (
      !('output' in answerOutput)
      || typeof (answerOutput as Record<string, unknown>).output !== 'string'
    )
  ) {
    return undefined;
  }

  const valueOfFinalAnswer = (typeof answerOutput === 'object' && answerOutput && 'output' in answerOutput)
    ? (answerOutput as Record<string, string>).output
    : String(answerOutput);

  let indexOfAnswerPhrase = valueOfFinalAnswer.indexOf('Answer:');
  if (indexOfAnswerPhrase >= 0) {
    indexOfAnswerPhrase += 'Answer:'.length;
  }

  return valueOfFinalAnswer.substring(indexOfAnswerPhrase || 0);
}

export function getChatHistoryStructuredAnswer(chatAnswer: Record<string, unknown>) {
  const chatAnswerKeys = Object.keys(chatAnswer);
  const tasksKey = chatAnswerKeys.find((key) => {
    const data = chatAnswer[key];
    return data && (
      (key === '/tasks' && Array.isArray(data))
    );
  });

  if (tasksKey) {
    const data = chatAnswer[tasksKey];
    if (data && Array.isArray(data)) {
      return {
        structured: {
          tasks: data.at(0),
        },
      };
    }
  }

  let finalAnswerKey = chatAnswerKeys.find((key) => {
    const data = chatAnswer[key];
    return data && (
      (key === '/final_output' && typeof data === 'object' && ('tasks' in data))
      || (key.endsWith('/final_output') && typeof data === 'object' && ('tasks' in data))
      || (key === '/tasks' && Array.isArray(data))
    );
  });

  if (finalAnswerKey) {
    return {
      structured: chatAnswer[finalAnswerKey],
    };
  }

  const finalOutputData = chatAnswer['/final_output'] || '';

  finalAnswerKey = chatAnswerKeys.find((key) => {
    const data = chatAnswer[key];
    if (!data) {
      return false;
    }

    if (!key.endsWith('/streamed_output_str/-') || typeof data !== 'string') {
      return false;
    }

    return true;
  });

  if (!finalAnswerKey) {
    return undefined;
  }

  const data = chatAnswer[finalAnswerKey];
  if (!data || typeof data !== 'string') {
    return undefined;
  }

  const posiblyStructuredAnswer = data.replace(String(finalOutputData), '');
  const jsonStarts = posiblyStructuredAnswer.indexOf('{');
  const preUnstructured = jsonStarts >= 0
    ? (posiblyStructuredAnswer.substring(0, jsonStarts) || undefined)
    : undefined;

  const structuredString = posiblyStructuredAnswer.substring(jsonStarts);
  const structured = isJsonString(structuredString)
    ? JSON.parse(structuredString)
    : undefined;

  if (!structured) {
    return undefined;
  }

  return {
    preUnstructured,
    structured,
  };
}

export function getChatHistoryFinishedTask(chatAnswer: Record<string, unknown>) {
  const tasks = chatAnswer['/tasks'];

  if (!tasks || !Array.isArray(tasks)) {
    return undefined;
  }

  if (Array.isArray(tasks.at(0))) {
    return tasks.at(0) as PlanAndExecuteTask[];
  }

  return tasks as PlanAndExecuteTask[];
}

export function getChatHistoryFinalAnswer(chatHistorySubItem: ChatHistorySubItem) {
  const chatAnswerJson = jsonrepair(chatHistorySubItem?.outputText || '{}');
  const chatAnswer = JSON.parse(chatAnswerJson) as Record<string, unknown>;

  const finalAnswer = extractFinalAnswer(chatAnswer);

  return finalAnswer;
}

export function extractStructuredAnswer(answer: string) {
  const processedAnswer = answer
    .replace(/```json/g, '\n')
    .replace(/```$/g, '\n');

  const codePart = extractJsonCodePart(processedAnswer);

  const answerLines = processedAnswer.split('\n');
  const codePartStartIndex = answerLines.findIndex((line) => (
    line.startsWith('{')
    || line.startsWith('[')
  ));

  const preUnstructured = codePartStartIndex > 0
    ? answerLines.slice(0, codePartStartIndex).join('\n')
    : undefined;

  const jsonChunks = codePart.split(/<br>/g).filter((c) => !!c);

  const structuredContent = jsonChunks
    .filter((chunk) => (
      !chunk.trim().startsWith('{\\')
    ))
    .reduce((structured, chunk) => {
      const repairedChunk = isRepairableJson(chunk) ? jsonrepair(chunk) : undefined;
      const obj = repairedChunk ? JSON.parse(repairedChunk) : undefined;

      if (!structured) {
        return obj;
      }

      return {
        ...structured,
        ...obj,
      };
    }, {} as unknown);

  return {
    preUnstructured,
    structured: structuredContent,
  };
}

export function stripStructuredAnswer(answer: string) {
  const processedAnswer = answer
    .replace(/```json/g, '\n')
    .replace(/```$/g, '\n');

  return stripJsonCodePart(processedAnswer, { ignoreNonJsonBefore: true });
}

export function parseFinalAnswerByAgent(agent?: string, streaming?: string) {
  if (agent === 'naked') {
    return streaming;
  }

  const currentChatStreaming = streaming || '';
  const stepInfos = createChatStepInfos(currentChatStreaming, agent);

  const finalStep = stepInfos[stepInfos.length - 1];
  const finalAnswerItem = finalStep?.items?.find((item) => item.name === 'Answer');
  const finalAnswer = finalAnswerItem?.value?.trim();

  if (agent === 'plan_execute' && finalAnswer) {
    const structured = extractStructuredAnswer(finalAnswer);

    if (structured && Object.keys(structured).length > 0) {
      return stripStructuredAnswer(finalAnswer).trim();
    }
  }

  return finalAnswer?.trim();
}

export function parseStructuredAnswerByAgent(agent?: string, streaming?: string) {
  if (agent !== 'plan_execute' || !streaming) {
    return undefined;
  }

  const currentChatStreaming = streaming || '';
  const stepInfos = createChatStepInfos(currentChatStreaming, agent);

  const finalStep = stepInfos[stepInfos.length - 1];
  const finalAnswerItem = finalStep?.items?.find((item) => item.name === 'Answer');
  const finalAnswer = finalAnswerItem?.value;

  if (!finalAnswer) {
    return undefined;
  }

  return extractStructuredAnswer(finalAnswer);
}

export function getCleanPlanTaskString(input: string) {
  return input
    .replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/&nbsp/g, ' ')
    .trim();
}

export function getCleanPlanExecuteStructure(structured?: PlanAndExecuteInfo) {
  if (!structured) {
    return undefined;
  }

  return {
    ...structured,
    tasks: (structured.tasks || [])
      .map((task) => {
        const toolInput = getCleanPlanTaskString(task.tool_input);
        return ({
          ...task,
          task: task.task || toolInput,
          tool_input: toolInput,
          isCustomTask: undefined,
          originalTaskIndex: undefined,
        });
      })
      .filter((task) => !!task.tool_input.trim()),
  };
}

export function getAvailableAgents() {
  const availableAgents = [{
    name: 'Raw',
    value: 'naked',
    description: 'Chat without using tools',
  }, {
    name: 'ReAct',
    value: 'react',
    description: 'Reasoning and Action (simple task)',
  }, {
    name: 'Self-Ask',
    value: 'self_ask',
    description: 'Objective Breakdown',
  }, {
    name: 'Plan and Execute',
    value: 'plan_execute',
    description: 'Multi-step Planning (complex tasks)',
  }];

  return availableAgents;
}

export function determineContentFormat(url: string) {
  if (url.includes('/pdf')
    || url.endsWith('.pdf')
    || url.includes('?pdf')
    || url.includes('/download/')
  ) {
    return 'markdown';
  }

  return 'html';
}
