import { AnimationProps } from 'framer-motion';
import refineTableInAnswer from '../gpt/gptUtils';
import { GptSearchResult } from './types';

export interface GetItemAnimationPropsArgs {
  itemIndex: number;
  itemHeight: number;
  pageSize: number;
  isForward?: boolean;
}

export const getCarouselItemAnimationProps = ({
  isForward,
  itemHeight,
  pageSize,
  itemIndex,
}: GetItemAnimationPropsArgs): AnimationProps => ({
  initial: {
    scale: 0,
    opacity: 0,
    top: isForward
      ? itemHeight * pageSize
      : 0,
    position: 'absolute',
    width: '100%',
  },
  animate: {
    scale: [0.5, 1, 1],
    opacity: [0, 1, 1],
    top: isForward
      ? [
        itemHeight * (pageSize - 1),
        itemHeight * (pageSize - 1),
        itemHeight * itemIndex,
      ]
      : [
        0,
        0,
        itemHeight * itemIndex,
      ],
    position: 'absolute',
    transition: {
      delay: isForward
        ? 0.15 * itemIndex
        : 0.15 * (pageSize - (itemIndex + 1)),
      ease: 'easeOut',
      duration: isForward
        ? 0.15 * (pageSize - itemIndex)
        : 0.15 * (itemIndex + 1),
    },
    transitionEnd: {
      position: 'static',
    },
    zIndex: isForward
      ? itemIndex + pageSize
      : pageSize - itemIndex,
  },
  exit: {
    scale: [1, 1, 0.5],
    opacity: [1, 1, 0],
    top: isForward
      ? [
        itemHeight * itemIndex,
        0,
        0,
      ]
      : [
        itemHeight * itemIndex,
        itemHeight * (pageSize - 1),
        itemHeight * (pageSize - 1),
      ],
    position: 'absolute',
    width: '100%',
    transition: {
      ease: 'linear',
      duration: isForward
        ? 0.2 * (itemIndex + 1)
        : 0.2 * (pageSize - itemIndex),
    },
    zIndex: 0,
  },
});

export const getStackItemAnimationProps = ({
  isForward,
  itemHeight,
  pageSize,
  itemIndex,
}: GetItemAnimationPropsArgs): AnimationProps => ({
  initial: {
    scale: 0,
    opacity: 0,
    top: isForward
      ? itemHeight * pageSize
      : -itemHeight,
    position: 'absolute',
    width: '100%',
  },
  animate: {
    scale: [0.8, 1, 1],
    opacity: [0, 1, 1],
    top: isForward
      ? [
        itemHeight * (pageSize) - 32,
        itemHeight * (pageSize) - 32,
        itemHeight * itemIndex,
      ]
      : [
        -itemHeight,
        -itemHeight,
        itemHeight * itemIndex,
      ],
    position: 'absolute',
    transition: {
      delay: isForward
        ? 0.1 * itemIndex
        : 0.1 * (pageSize - (itemIndex + 1)),
      ease: 'easeOut',
      duration: isForward
        ? 0.1 * (pageSize - itemIndex)
        : 0.1 * (itemIndex + 1),
    },
    transitionEnd: {
      position: 'static',
    },
    zIndex: isForward
      ? itemIndex + pageSize
      : pageSize - itemIndex,
  },
  exit: {
    scale: [1, 1, 0.8],
    opacity: [1, 1, 0],
    top: isForward
      ? [
        itemHeight * itemIndex,
        -itemHeight,
        -itemHeight,
      ]
      : [
        itemHeight * itemIndex,
        itemHeight * (pageSize) - 32,
        itemHeight * (pageSize) - 32,
      ],
    position: 'absolute',
    width: '100%',
    transition: {
      ease: 'easeOut',
      duration: isForward
        ? 0.2 * (itemIndex + 1)
        : 0.2 * (pageSize - itemIndex),
    },
    zIndex: 0,
  },
});

export const getGptResultAnswer = (gptSearchResults?: string | GptSearchResult[]) => {
  const answer = (typeof gptSearchResults === 'string')
    ? gptSearchResults
    : (gptSearchResults?.at(0)?.queryDetails?.at(0)?.answer || '');

  return refineTableInAnswer(answer);
};
