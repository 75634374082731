import React, { useRef } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';

import {
  AddCircleRounded as AddIcon,
} from '@mui/icons-material';

import classNames from 'classnames';

import { extractSelectableMarkets } from '../reportUtils';

import styles from './MarketsSelectorPanel.styles';
import { PropsFromRedux } from './container';
import { useCommonClasses } from '../../../../theme/commonStyles';
import useCommonProps from '../../../../theme/commonProps';

interface Props extends PropsFromRedux {
  height?: number;
}

const CHART_MARGIN_TOP = 16;
const MAX_SELECTED_MARKETS = 4;
const MARKET_HIGHLIGHT_HOVER_DELAY = 2000;

const useStyles = makeStyles(styles);

const MarketsSelectorPanel: React.FC<Props> = ({
  height,
  marketReport,
  addMarketItem,
  removeMarketItem,
  selectedMarketItems,
  setMarketAggregate,
  isAggregateMarketItems,
  setMarketHighlight,
  selectedHighlightMarket,
  hoveringHighlightMarket,
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const commonProps = useCommonProps();
  const marketHighlightTimeoutRef = useRef<NodeJS.Timeout | undefined>();

  const selectableMarkets = extractSelectableMarkets(marketReport)
    .filter((market) => !selectedMarketItems.includes(market));

  const colorSetMaps = [
    'primary',
    'tertiary',
    'quaternary',
    'secondary',
  ];

  const delayedSetMarketHighlight = (market?: string) => {
    if (marketHighlightTimeoutRef.current) {
      clearTimeout(marketHighlightTimeoutRef.current);
    }

    if (!market) {
      setMarketHighlight({
        marketItem: undefined,
      });

      return;
    }

    marketHighlightTimeoutRef.current = setTimeout(() => {
      setMarketHighlight({
        marketItem: market,
      });
    }, MARKET_HIGHLIGHT_HOVER_DELAY);
  };

  return (
    <Box
      className={classes.root}
      paddingTop={CHART_MARGIN_TOP / 8}
      height={(height || 0) - CHART_MARGIN_TOP - 16}
    >
      <Grid container flex={0}>
        <Grid item xs={7}>
          <Typography
            variant="h2"
            className={classes.title}
          >
            Market Analysis
          </Typography>
        </Grid>
        {selectedMarketItems.length > 1 && (
          <Grid item container xs={5} alignItems="flex-end" justifyContent="center">
            <FormControlLabel
              label="Aggregate"
              classes={{
                label: classes.aggregateLabel,
              }}
              control={
                <Switch
                  {...commonProps.switchControl({
                    color: 'primary',
                  })}
                  color="primary"
                />
              }
              onChange={(_, checked) => setMarketAggregate({ isAggregate: checked })}
            />
          </Grid>
        )}
      </Grid>
      <Box
        marginTop={2}
        marginBottom={2}
        display="flex"
        flexDirection="column"
        flex={1}
      >
        <Box
          display="flex"
          flexDirection="column"
          flex={0}
        >
          <Typography
            variant="h6"
            className={classes.subTitle}
          >
            Markets
          </Typography>
          <Box className={classes.selectedMarketsContainer}>
            <Box className={classes.selectedMarkets}>
              {selectedMarketItems.map((market, index) => (
                <Chip
                  key={market}
                  label={(
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="body1"
                        color="info"
                        className={
                          classNames(
                            classes.selectedMarketItemText,
                            selectedHighlightMarket === market && 'highlight',
                            hoveringHighlightMarket === market && 'hover',
                          )
                        }
                        onMouseEnter={() => delayedSetMarketHighlight(market)}
                        onMouseLeave={() => delayedSetMarketHighlight(undefined)}
                      >
                        {market}
                      </Typography>
                    </Box>
                  )}
                  variant="outlined"
                  classes={{
                    root: commonCss.chips.tagItem.root,
                    deleteIcon: classNames(
                      classes.selectedMarketItemDeleteIcon,
                      commonCss.chips.tagItem.deleteIcon,
                      'contrast',
                      selectedHighlightMarket === market && 'highlight',
                      hoveringHighlightMarket === market && 'hover',
                    ),
                  }}
                  className={
                    classNames(
                      classes.selectedMarketItem,
                      'leaf',
                      'outline',
                      isAggregateMarketItems ? colorSetMaps[0] : colorSetMaps[index],
                      'bolder',
                      selectedHighlightMarket === market && 'highlight',
                      hoveringHighlightMarket === market && 'hover',
                    )
                  }
                  onDelete={() => removeMarketItem({
                    market,
                  })}
                  onClick={() => setMarketHighlight({
                    marketItem: selectedHighlightMarket === market ? undefined : market,
                    isPermanent: true,
                  })}
                />
              ))}
            </Box>
          </Box>
          <Divider className={classes.marketListDivider} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flex={2}
          marginTop={2}
        >
          <Typography
            variant="h6"
            className={classes.selectableMarketTitle}
          >
            Recommended Markets
          </Typography>
          <Box className={classes.selectableMarketsContainer}>
            <Box className={classes.selectableMarkets}>
              {selectableMarkets.map((market) => (
                <Chip
                  key={market}
                  label={(
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="body2"
                        color="inherit"
                        className={classes.selectableMarketItemText}
                      >
                        {market}
                      </Typography>
                      <AddIcon className={classes.selectableMarketItemAddIcon} />
                    </Box>
                  )}
                  variant="outlined"
                  classes={{
                    root: commonCss.chips.tagItem.root,
                  }}
                  className={
                    classNames(
                      classes.selectableMarketItem,
                      'leaf',
                      'outline',
                      'info',
                      'bolder',
                    )
                  }
                  onClick={() => addMarketItem({
                    market,
                  })}
                  disabled={selectedMarketItems.length >= MAX_SELECTED_MARKETS}
                />
              ))}
            </Box>
          </Box>
          <Divider className={classes.marketListDivider} />
        </Box>
      </Box>
    </Box>
  );
};

export default MarketsSelectorPanel;
