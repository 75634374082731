import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles, { getContrastColorModifierClasses } from '../../../../theme/commonStyles';
import { otherContrastColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    resultPanel: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    '@keyframes steamAnimation': {
      '0%': {
        backgroundPosition: '0 0',
      },
      '50%': {
        backgroundPosition: '400% 0',
      },
      '100%': {
        backgroundPosition: '0 0',
      },
    },
    resultBody: {
      flex: 1,
      background: theme.palette.background.paper,
      zIndex: 1,
    },
    companyHighlight: {
      color: `${otherContrastColorSets[1]} !important`,
      textDecorationColor: `${otherContrastColorSets[1]} !important`,
      cursor: 'pointer',
    },
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
      width: 850,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    sectionHeaderText: {
      flex: 0,
      fontSize: '1.285rem !important',
      fontWeight: 'normal !important',
      whiteSpace: 'nowrap',
      ...getContrastColorModifierClasses('color'),
    },
    sectionHeaderDivider: {
      flex: 1,
      marginLeft: `${theme.spacing(2)} !important`,
      marginRight: `${theme.spacing(2)} !important`,
      boxSizing: 'border-box',
      ...getContrastColorModifierClasses('borderColor'),
    },
    sectionHeaderButton: {
      flexBasis: theme.spacing(12),
      flex: 0,
      fontSize: '0.9285rem !important',
      fontWeight: '300 !important',
      whiteSpace: 'nowrap',
      borderRadius: '6px !important',
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      borderColor: `${theme.palette.grey[50]} !important`,
      ...getContrastColorModifierClasses('color'),
      ...getContrastColorModifierClasses('stroke', ' svg .caret'),
    },
    companyItemsContainer: {
      display: 'flex',
      marginTop: theme.spacing(2),
      columnGap: theme.spacing(3),
      width: 850,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    companyItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'relative',
      '& > *': {
        zIndex: 1,
      },
      '&.exactMatched .name': {
        color: `${theme.palette.secondary.main} !important`,
      },
      '& .name': {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineClamp: 2,
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        lineHeight: 1.14,
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        left: theme.spacing(-1),
        top: theme.spacing(-1),
        right: theme.spacing(-1),
        bottom: theme.spacing(-1),
        borderRadius: theme.spacing(1),
        backgroundColor: 'transparent',
        transition: 'all 0.3s ease-out',
        zIndex: 0,
      },
      '&:hover': {
        '& .logo': {
          borderColor: theme.palette.secondary.main,
        },
        '& .name': {
          color: `${theme.palette.secondary.main} !important`,
          textDecoration: 'underline',
        },
        '&:before': {
          backgroundColor: 'rgba(185, 213, 253, 0.37)',
        },
      },
      '&.active': {
        '& .logo': {
          borderColor: theme.palette.secondary.main,
        },
        '& .name': {
          color: `${theme.palette.secondary.main} !important`,
          textDecoration: 'underline',
        },
        '&:before': {
          backgroundColor: 'rgba(185, 213, 253, 0.37)',
        },
      },
    },
  });
};

export default styles;
