import { createReducer } from '@reduxjs/toolkit';
import {
  triggerNotification,
  removeNotification,
  setNotificationDisplayed,
} from './actions';
import { NotifierState } from './types';

const initialState: NotifierState = {
  notifications: [],
};

const notifierReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(triggerNotification, (state, action) => ({
      ...state,
      notifications: [
        ...state.notifications,
        action.payload.notification,
      ],
    }))
    .addCase(removeNotification, (state, action) => ({
      ...state,
      notifications: state.notifications.filter(
        (notification) => notification.key === action.payload.key,
      ),
    }))
    .addCase(setNotificationDisplayed, (state, action) => ({
      ...state,
      notifications: state.notifications.map(
        (notification) => {
          if (notification.key === action.payload.key) {
            return {
              ...notification,
              isDisplayed: true,
            };
          }
          return notification;
        },
      ),
    }));
});

export default notifierReducer;
