import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';

import {
  openGlossaryBubble,
} from '../../main/actions';

import {
  setSearchKeyword,
  toggleGptChat,
} from '../../search/actions';

import {
  openSourceBubble,
  closeSourceBubble,
} from '../actions';

import GptSummaryView from './GptSummaryView';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.search,
    ...state.gpt,
    networkStates: {
      ...state.search.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    openGlossaryBubble,
    setSearchKeyword,
    toggleGptChat,
    openSourceBubble,
    closeSourceBubble,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GptSummaryView);
