import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import { getCustomScrollBarCss } from '../../../../core/utils/uxUtils';
import getCommonStyles from '../../../../theme/commonStyles';
import dimensions from '../../../../theme/dimensions';
import { otherColorSets, otherLightColorSets } from '../../../../theme/palette';

const SIDE_PANEL_COLLAPED_PADDING = 32;

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignContent: 'center',
    },
    logoBox: {
      marginTop: theme.spacing(5),
      marginLeft: 'auto',
      marginRight: 'auto',
      transition: 'all ease-out 0.5s',
      '&.collapsed': {
        marginTop: theme.spacing(3),
      },
      '& svg': {
        transition: 'all ease-out 0.3s',
      },
      '&.smallLogo': {
        '& svg': {
          transform: 'scale(0.75)',
          marginTop: theme.spacing(-1),
        },
      },
      cursor: 'pointer',
    },
    feedbackBar: {
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
      height: 120,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    feedbackLink: {
      color: `${otherColorSets[1]} !important`,
      fontSize: '1.2rem !important',
      fontWeight: 'normal !important',
      cursor: 'pointer',
    },
    subscribeContainer: {
      position: 'relative',
    },
    subscribeButton: {
      color: `${theme.palette.grey[900]} !important`,
      background: `${theme.palette.grey[50]} !important`,
      width: 144,
      height: 38,
      fontWeight: '500 !important',
      '&.active': {
        color: `${theme.palette.common.white} !important`,
        background: `${theme.palette.grey[500]} !important`,
      },
    },
    subscribeInput: {
      right: 0,
      top: 0,
      width: 0,
      opacity: 0,
      transition: 'all 0.3s ease-out',
      pointerEvents: 'none',
      '&.active': {
        width: 400,
        opacity: 1,
        pointerEvents: 'auto',
      },
    },
    contactUsLink: {
      marginLeft: `${theme.spacing(0.5)} !important`,
      display: 'inline-block',
      color: `${theme.palette.grey[300]} !important`,
      textDecorationColor: `${theme.palette.grey[300]} !important`,
      fontSize: '1.1rem',
      fontWeight: 300,
      fontFamily: 'Roboto',
    },
    shaking: {
      animation: '$formShakingAnimation 0.5s',
      animationIterationCount: '1',
    },
    '@keyframes formShakingAnimation': {
      '0%': { transform: 'translate(0, 0)' },
      '15%': { transform: 'translate(-4px, 0)' },
      '30%': { transform: 'translate(6px, 0)' },
      '45%': { transform: 'translate(-4px, 0)' },
      '60%': { transform: 'translate(6px, 0)' },
      '100%': { transform: 'translate(0, 0)' },
    },
    searchContainer: {
      marginBottom: theme.spacing(-2),
      transition: 'all ease-out 0.3s',
      '&.chatMode': {
        zIndex: 200,
        width: 350,
      },
    },
    gptContainer: {
      maxWidth: 'inherit !important',
      display: 'flex !important',
      zIndex: 100,
    },
    gptMenuPanel: {
      borderRadius: theme.spacing(2),
      background: '#fff',
      border: `1px solid ${theme.palette.grey[100]}`,
      width: dimensions.gptSearchBox.width,
      height: 'calc(100vh - 200px)',
      boxSizing: 'border-box',
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      zIndex: 1,
    },
    gptTabs: {
      '& .MuiTab-root': {
        textTransform: 'capitalize !important',
        width: 100,
        minWidth: 85,
        boxSizing: 'border-box',
      },
    },
    gptChatPanel: {
      marginLeft: theme.spacing(2),
      height: `calc(100vh - 200px - ${theme.spacing(-7)})`,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      overflowY: 'auto',
      overflowX: 'hidden',
      marginTop: theme.spacing(-7),
      ...getCustomScrollBarCss('vertical', { hideByDefault: false }),
      '&.sidePanelCollapsed': {
        marginRight: SIDE_PANEL_COLLAPED_PADDING,
      },
    },
    sidePanel: {
      marginLeft: theme.spacing(2),
      height: `calc(100vh - 200px - ${theme.spacing(-7)})`,
      position: 'relative',
      marginTop: theme.spacing(-7),
      width: 500,
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: 0,
      display: 'flex',
      flexDirection: 'column',
      transition: 'all 0.3s ease-out',
      [theme.breakpoints.up(1729)]: {
        width: 650,
      },
      '&.browserView': {
        borderBottom: 'none',
      },
      '&.pdfView': {
        borderBottom: 'none',
      },
    },
    gptChatViewWrapper: {
      display: 'flex !important',
      flex: 1,
      marginRight: 'auto !important',
      paddingLeft: `${SIDE_PANEL_COLLAPED_PADDING + 24}px !important`,
      transform: 'translate(120px, 0)',
      '&.sidePanelCollapsed': {
        transform: 'translate(0, 0)',
      },
      transition: 'all 0.3s ease-out',
      [theme.breakpoints.up(1729)]: {
        maxWidth: '950px !important',
        minWidth: '950px !important',
      },
    },
    tabContentContainer: {
      flex: 1,
      position: 'relative',
    },
    tabContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(1),
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      overflow: 'auto',
      marginRight: theme.spacing(-0.5),
      ...getCustomScrollBarCss(),
    },
    tabContentItem: {
      borderRadius: '0 !important',
      boxShadow: 'none !important',
      padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
      border: 'none !important',
      marginBottom: theme.spacing(1),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: otherLightColorSets[2],
      },
      display: 'flex',
      alignItems: 'center',
      '& header': {
        fontSize: '0.9rem !important',
        color: theme.palette.grey[900],
        fontWeight: 'normal',
        marginBottom: theme.spacing(0.25),
      },
    },
    tabContentItemDivider: {
      borderColor: `${theme.palette.grey[50]} !important`,
    },
    tabContentItemText: {
      flex: 1,
      boxSizing: 'border-box',
      lineHeight: 1.33,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      fontSize: '0.9rem !important',
      fontWeight: '300 !important',
      color: `${theme.palette.grey[900]} !important`,
      letterSpacing: '0.06px !important',
    },
    tabContentControlBox: {
      flex: 0,
      flexBasis: theme.spacing(4),
      marginRight: theme.spacing(-2),
      '& svg': {
        color: theme.palette.grey[50],
      },
      '& button:hover svg': {
        color: 'inherit',
      },
    },
    sidePanelCollapseButton: {
      position: 'absolute',
      top: -15,
      left: -8,
      width: 28,
      height: 20,
      backgroundColor: theme.palette.background.paper,
      zIndex: 1,
    },
    sidePanelButtonsContainer: {
      position: 'absolute',
      top: 0,
      left: -(SIDE_PANEL_COLLAPED_PADDING + 4),
      width: 40,
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(0.5),
    },
    sidePanelCollapseIcon: {
      transition: 'all 0.3s ease-out',
      '&.collapsed': {
        transform: 'rotateY(180deg)',
      },
    },
    sidePanelButtons: {
      minWidth: '40px !important',
      height: '40px !important',
      padding: '6px !important',
      borderRadius: `${theme.spacing(1)} !important`,
      transition: 'all 0.3s ease-out',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'transparent !important',
        borderColor: `${otherColorSets[2]} !important`,
        '& .relationChartIconPath': {
          stroke: otherColorSets[2],
        },
        '& .settingsIconPath': {
          stroke: otherColorSets[2],
        },
        '& .browserIconPath': {
          fill: otherColorSets[2],
        },
      },
    },
    sidePanelTabContainer: {
      position: 'absolute',
      top: theme.spacing(-4.7),
      left: theme.spacing(3),
      zIndex: 1,
      '&.noChats': {
        left: 0,
      },
    },
    sidePanelTab: {
      '& .MuiTab-root': {
        textTransform: 'capitalize !important',
        width: 100,
        minWidth: 85,
        boxSizing: 'border-box',
        borderBottom: 'none !important',
      },
    },
    browserView: {
      marginTop: theme.spacing(2),
      width: '100%',
      height: `calc(100% - ${theme.spacing(2)})`,
    },
    pdfView: {
      marginTop: theme.spacing(2),
      width: '100%',
      height: `calc(100% - ${theme.spacing(2)})`,
    },
  });
};

export default styles;
