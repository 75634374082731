import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import Highcharts, { GradientColorStopObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import styles from './SplineChart.styles';
import { SplineChartData } from './types';

type Props = {
  data: SplineChartData;
  width: number;
  height: number;
  animationDuration?: number;
  gradientStops?: GradientColorStopObject[];
  spacing?: [number, number, number, number];
  margin?: [number, number, number, number];
  enableMouseTracking?: boolean;
  highlight?: boolean;
};

const DEFAULT_ANIMATION_DURATION = 2000;
const DEFAULT_GRADIENT_STOPS = [{
  0: 0,
  1: '#fca2c1',
}, {
  0: 1,
  1: '#8e89ff',
}, {
  0: 2,
  1: '#3c8cfd',
}] as GradientColorStopObject[];

const useStyles = makeStyles(styles);

const SplineChart: React.FC<Props> = ({
  data,
  width,
  height,
  animationDuration = DEFAULT_ANIMATION_DURATION,
  gradientStops = DEFAULT_GRADIENT_STOPS,
  spacing = [0, 0, 0, 0],
  margin = [0, 0, 0, 0],
  enableMouseTracking = false,
  highlight = false,
}) => {
  const classes = useStyles();

  const options: Highcharts.Options = {
    chart: {
      width,
      height,
      spacing,
      margin,
      type: 'spline',
      backgroundColor: 'transparent',
    },
    legend: { enabled: false },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      gridLineWidth: 0,
      title: { text: undefined },
      labels: {
        enabled: false,
      },
    },
    xAxis: {
      gridLineWidth: 0,
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      title: { text: undefined },
      labels: {
        enabled: false,
      },
      minorTickLength: 0,
      tickLength: 0,
    },
    tooltip: {
      formatter() {
        return false;
      },
    },
    plotOptions: {
      series: {
        enableMouseTracking,
        states: {
          hover: {
            enabled: true,
            lineWidth: 3,
          },
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        dashStyle: 'ShortDot',
        color: '#ff8d1e',
        pointStart: 0,
        animation: {
          duration: animationDuration,
        },
      },
    },
    accessibility: {
      enabled: false,
    },
    series: [
      {
        name: '',
        dashStyle: 'Solid',
        lineWidth: 3,
        type: 'spline',
        clip: false,
        color: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 1,
            y2: 0,
          },
          stops: gradientStops,
        },
        data,
      },
    ],
  };

  const chartRef = useRef<HighchartsReact.RefObject>(null);
  useEffect(() => {
    if (highlight && chartRef?.current?.chart?.series?.[0]) {
      chartRef.current.chart.series[0].setState('hover');
    }
  });

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
    chartRef.current?.chart.redraw();
    chartRef.current?.chart.reflow();
  }, [height]);

  return (
    <div className={classes.root}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
};

export default SplineChart;
