import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 300,

      '& div[data-highcharts-chart]': {
        overflow: 'visible !important',
        '& .highcharts-container': {
          overflow: 'visible !important',
          '& .highcharts-root': {
            overflow: 'visible !important',
          },
        },
      },
    },
  });
};

export default styles;
