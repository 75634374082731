import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';

import { PopupDialog } from '../../../../components';
import { useCommonClasses } from '../../../../theme/commonStyles';
import { otherColorSets, otherContrastColorSets } from '../../../../theme/palette';

import { PropsFromRedux } from './container';
import { ChatPromptItem } from '../types';

import styles from './PromptInfoPopup.styles';

const useStyles = makeStyles(styles);

interface Props extends PropsFromRedux {
  prompt?: ChatPromptItem;
  open?: boolean;
  onClose?: () => void;
}

const PromptInfoPopup: React.FC<Props> = ({
  prompt,
  open,
  onClose,
  requestCreateChatPrompt,
  requestUpdateChatPrompt,
  networkStates: {
    chatPromptCreateRequest,
  },
  user,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();

  const textBoxRef = useRef<HTMLInputElement>(null);
  const [promptText, setPromptText] = useState(prompt?.prompt || '');

  useEffect(() => {
    setTimeout(() => {
      textBoxRef.current?.focus();
    });
  }, []);

  return (
    <PopupDialog
      open={open}
      classes={{
        content: classes.root,
      }}
      closeAction={() => {
        if (onClose) {
          onClose();
        }
        return { type: undefined };
      }}
      renderCommandActions={() => (
        <Box
          display="flex"
          justifyContent="flex-end"
          width="100%"
          columnGap={1}
        >
          <Button
            variant="contained"
            classes={commonCss.buttons.roundButton}
            className={classNames(classes.button, 'tertiary')}
            onClick={() => {
              if (!prompt && user) {
                requestCreateChatPrompt({
                  prompt: {
                    prompt: promptText,
                    user: user?.pk,
                  },
                });
              }

              if (prompt?.id) {
                requestUpdateChatPrompt({
                  prompt: {
                    ...prompt,
                    prompt: promptText,
                  },
                });
              }

              if (onClose) {
                onClose();
              }
            }}
            disabled={promptText.trim() === '' || chatPromptCreateRequest.isRequesting}
          >
            {chatPromptCreateRequest.isRequesting && (
              <Box marginRight={1}>
                <CircularProgress size={12} />
              </Box>
            )}
            Save
          </Button>
          <Button
            variant="outlined"
            classes={commonCss.buttons.roundButton}
            className={classes.button}
            color="info"
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}
          >
            Cancel
          </Button>
        </Box>
      )}
    >
      <TextField
        label="Prompt"
        fullWidth
        variant="standard"
        inputRef={textBoxRef}
        sx={{
          '& label.Mui-focused': {
            top: 0,
            color: otherContrastColorSets[2],
          },
          '& label.MuiInputLabel-root': {
            color: theme.palette.grey[400],
          },
          '& label.MuiInputLabel-shrink': {
            color: otherContrastColorSets[2],
            '& .optional-text': {
              display: 'none',
            },
            '&+.MuiInputBase-root .optional-text': {
              display: 'none',
            },
          },
          '& .MuiInput-root:after': {
            borderBottomColor: otherColorSets[2],
          },
        }}
        value={promptText}
        onChange={(event) => setPromptText(event.target.value)}
      />
    </PopupDialog>
  );
};

export default PromptInfoPopup;
