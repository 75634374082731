import React, { useRef, useState } from 'react';

import { makeStyles } from '@mui/styles';
import {
  Box,
  Checkbox,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField,
} from '@mui/material';

import {
  FixedSizeList,
  ListChildComponentProps,
} from 'react-window';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import styles from './ItemsSelectorPopover.styles';
import useCommonProps from '../../theme/commonProps';

const ITEM_SIZE = 32;
const MAX_VISIBLE_ITEMS = 12;
const useStyles = makeStyles(styles);

type Props<T> = {
  showSearch?: boolean;
  searchPlaceholder?: string;
  anchorEl?: HTMLButtonElement;
  items?: T[];
  selectedItems?: T[];
  onChange?: (updatedItems: T[]) => void;
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  itemIconRenderer?: (item: T) => JSX.Element;
  getItemName: (item: T) => string;
  itemsComparer: (a: T, b: T) => boolean;
};

export const ItemsSelectorPopoverFactory = <T extends unknown>() => {
  const SelectorPopover: React.FC<Props<T>> = ({
    showSearch = true,
    searchPlaceholder,
    anchorEl,
    items,
    selectedItems,
    onChange,
    onClose,
    itemIconRenderer,
    getItemName,
    itemsComparer: compareItems,
  }) => {
    const classes = useStyles();
    const commonProps = useCommonProps();
    const [keyword, setKeyword] = useState<string>();
    const innerSelectedItems = selectedItems || [];

    const searchInputRef = useRef<HTMLInputElement>();

    const filteredItems = (items || [])
      .filter((item) => !keyword
        || getItemName(item).toLowerCase().includes(keyword.toLowerCase()));

    const listHeight = (
      filteredItems.length <= MAX_VISIBLE_ITEMS
        ? filteredItems.length * ITEM_SIZE
        : MAX_VISIBLE_ITEMS * ITEM_SIZE
    );

    const isItemSelected = (item: T) => (
      !!innerSelectedItems.find((i) => compareItems(i, item))
    );

    const updateSelectedItems = (item: T) => {
      const currentSelectedItems = innerSelectedItems || [];
      if (currentSelectedItems.find((i) => compareItems(i, item))) {
        return currentSelectedItems.filter((i) => !compareItems(i, item));
      }

      return [
        ...currentSelectedItems,
        item,
      ];
    };

    const toggleSelectedItems = (item: T) => {
      const updatedSelectedItems = updateSelectedItems(item);
      if (onChange) {
        onChange(updatedSelectedItems);
      }
    };

    const renderItem = (props: ListChildComponentProps) => {
      const { index, style } = props;
      const item = filteredItems[index];

      const matches: [number, number][] = match(getItemName(item) || '', keyword || '');
      const parts = parse(getItemName(item) || '', matches);

      return (
        <ListItem
          key={getItemName(item)}
          style={style}
          disablePadding
          divider
          dense
        >
          <ListItemButton
            onClick={() => toggleSelectedItems(item)}
            dense
            sx={{
              height: 32,
            }}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                color="secondary"
                disableRipple
                checked={isItemSelected(item)}
                size="small"
              />
              {itemIconRenderer && (itemIconRenderer(item))}
            </ListItemIcon>
            <ListItemText
              className={classes.itemNameText}
              primary={parts.map((part, idx) => (
                <span
                  key={idx}
                  style={{
                    fontWeight: part.highlight ? 500 : 300,
                  }}
                >
                  {part.text}
                </span>
              ))}
            />
          </ListItemButton>
        </ListItem>
      );
    };

    return (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        classes={{
          paper: classes.root,
        }}
        disableRestoreFocus
      >
        {showSearch && (
          <>
            <Box className={classes.searchKeywordBox}>
              <TextField
                {...commonProps.textField({ color: 'secondary' })}
                placeholder={searchPlaceholder}
                onChange={(event) => setKeyword(event.target.value)}
                fullWidth
                size="small"
                InputProps={{
                  ...commonProps.textField({ color: 'secondary' }).InputProps,
                  autoFocus: true,
                  ref: searchInputRef,
                }}
              />
            </Box>
            <Divider />
          </>
        )}
        <FixedSizeList
          height={listHeight + 4}
          width={320}
          itemSize={ITEM_SIZE}
          itemCount={filteredItems.length}
          overscanCount={5}
        >
          {renderItem}
        </FixedSizeList>
      </Popover>
    );
  };

  return SelectorPopover;
};

export default ItemsSelectorPopoverFactory;
