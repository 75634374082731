import { makeStyles } from '@mui/styles';

import styles from './GptLoadProgress.styles';

const useStyles = makeStyles(styles);

const GptLoadProgress = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.dotPulse} />
    </div>
  );
};

export default GptLoadProgress;
