import { createTheme } from '@mui/material/styles';
import Color from 'color';

import {
  spacing,
} from './dimensions';

import {
  primary,
  secondary,
  grey,
  black,
  white,
  otherColorSets,
  otherAltLightColorSets,
  defaultTextColor,
} from './palette';

export const defaultTheme = createTheme({
  spacing,
  palette: {
    background: {
      default: '#fff',
      paper: '#fcfcfc',
    },
    primary: {
      ...primary,
      main: primary[500],
      contrastText: primary.A100,
    },
    secondary: {
      ...secondary,
      main: secondary[500],
      contrastText: secondary[50],
    },
    grey: {
      ...grey,
    },
    info: {
      main: grey[900],
      contrastText: '#000',
    },
    common: {
      black,
      white,
    },
    text: {
      primary: defaultTextColor,
      secondary: white,
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.3)',
    },
  },
  typography: {
    h1: {
      fontSize: '3rem',
      color: grey[900],
    },
    h2: {
      fontSize: '2.857rem',
      color: grey[900],
    },
    h4: {
      fontSize: '1.785rem',
      fontWeight: 100,
      lineHeight: 1.56,
      letterSpacing: '0.75px',
    },
    h5: {
      fontSize: '1.571rem',
      fontWeight: 300,
      color: grey[900],
    },
    h6: {
      fontSize: '1.4rem',
      fontWeight: 300,
    },
    body1: {
      fontSize: '1.142rem',
      fontWeight: 300,
      fontKerning: 'normal',
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 300,
      fontKerning: 'normal',
    },
    fontSize: 16,
    allVariants: {
      color: defaultTextColor,
    },
    fontFamily: [
      'Roboto',
      'Segoe UI',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: Color(primary[500]).alpha(0.25).toString(),
        },
        colorSecondary: {
          color: Color(otherColorSets[1]).alpha(0.5).toString(),
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        sizeLarge: {
          fontSize: '1.4825rem',
          lineHeight: 1.16,
        },
        containedPrimary: {
          color: '#fff',
          backgroundColor: primary[500],
          '&:hover': {
            backgroundColor: otherColorSets[0],
          },
          '&.Mui-disabled': {
            color: '#fff',
            backgroundColor: Color(primary[500]).alpha(0.5).toString(),
          },
        },
        containedSecondary: {
          color: '#fff',
          backgroundColor: otherColorSets[1],
          '&:hover': {
            backgroundColor: secondary[500],
          },
          '&.Mui-disabled': {
            color: '#fff',
            backgroundColor: Color(otherColorSets[1]).alpha(0.5).toString(),
          },
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiInput: {
      defaultProps: {
        autoComplete: 'off',
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: '0.5 !important',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: '0.5 !important',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          border: `1px solid ${grey[100]}`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: `0.5px solid ${grey[500]}`,
        },
      },
    },
  },
});

export const dataGridTheme = createTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      ...secondary,
      main: otherColorSets[2],
      contrastText: secondary[50],
    },
    secondary: {
      ...secondary,
      main: otherColorSets[2],
      contrastText: secondary.A100,
    },
  },
  components: {
    ...defaultTheme.components,
    MuiButton: {
      ...defaultTheme.components?.MuiButton,
      styleOverrides: {
        containedPrimary: {
          color: '#fff',
          backgroundColor: otherAltLightColorSets[2],
          '&:hover': {
            backgroundColor: Color(otherAltLightColorSets[2]).alpha(0.5).toString(),
          },
          '&.Mui-disabled': {
            color: '#fff',
            backgroundColor: Color(otherAltLightColorSets[2]).alpha(0.5).toString(),
          },
        },
        containedSecondary: {
          color: '#fff',
          backgroundColor: otherAltLightColorSets[2],
          '&:hover': {
            backgroundColor: Color(otherAltLightColorSets[2]).alpha(0.5).toString(),
          },
          '&.Mui-disabled': {
            color: '#fff',
            backgroundColor: Color(otherAltLightColorSets[2]).alpha(0.5).toString(),
          },
        },
      },
    },
  },
});

export default defaultTheme;
