import NetworkState from '../../../core/types/network';

export enum AutocompleteActions {
  ITEMS_REQUEST = '@@autocomplete/ITEMS_REQUEST',
  ITEMS_SET = '@@autocomplete/ITEMS_SET',
  ITEMS_REQUEST_ABORT = '@@autocomplete/ITEMS_REQUEST_ABORT',
}

export interface AutocompleteItem {
  id: number;
  name: string;
}

export interface SearchValidationInfo {
  isSearchable: boolean;
  message?: string;
}

export interface RequestAutocompleteItemsArgs {
  storageKey: string;
  apiPath: string;
  keyword?: string;
  minKeyword?: number;
}

export interface SetAutocompleteItemsArgs {
  storageKey: string;
  items?: AutocompleteItem[];
  error?: Error;
}

export interface AbortAutocompleteItemsRequestArgs {
  storageKey: string;
}

export interface AutocompleteState {
  networkStates: {
    autocompleteRequest: Record<string, NetworkState>;
  };
  validationInfo: Record<string, SearchValidationInfo>;
  storages: Record<string, AutocompleteItem[] | undefined>;
}
