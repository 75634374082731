import { createAction } from '@reduxjs/toolkit';
import {
  GptActions,
  RequestChatResponseArgs,
  SetChatResponseArgs,
  SetChatCleanArgs,
  ToggleChatItemStepArgs,
  SetChatStreamingArgs,
  SetChatHistoryArgs,
  ApplyChatHistoryArgs,
  SetSiteExamplesArgs,
  RequestChatPromptsArgs,
  SetChatPromptsArgs,
  RequestCreateChatPromptArgs,
  SetChatPromptCreatedArgs,
  RequestUpdateChatPromptArgs,
  SetChatPromptUpdatedArgs,
  RequestDeleteChatPromptArgs,
  SetChatPromptDeletedArgs,
  OpenSourceBubbleArgs,
  StartTasksExecuteArgs,
  SetChatItemTasksArgs,
  PrepareDeepSearchTasksArgs,
  RequestTaskerExecuteArgs,
  SetTaskerExecutedArgs,
  UpdateLastObjectiveArgs,
  SetTaskTemplatesArgs,
  ApplyTaskTemplateArgs,
  RequestDeleteTaskTemplateArgs,
  SetTaskTemplateDeletedArgs,
  RequestCreateTaskTemplateArgs,
  RequestUpdateTaskTemplateArgs,
  SetTaskTemplateCreatedArgs,
  SetTaskTemplateUpdatedArgs,
  ToggleChatItemTableArgs,
  ToggleChatItemSourceArgs,
  DuplicateChatItemChartArgs,
  RequestChatItemChartUpdateArgs,
  SetChatItemChartUpdatedArgs,
  ToggleChatItemOrgsArgs,
  RemoveChatItemChartArgs,
  UndoChatItemChartChangeArgs,
  RequestTableToChartArgs,
  SetTableToChartArgs,
  RequestDeleteChatHistoryArgs,
  SetChatHistoryDeletedArgs,
  SetGptModelsArgs,
  UpdateSettingsArgs,
  SetSideBarOpenArgs,
  ApplyChatOutputsCompareArgs,
  SelectChatResponseModelArgs,
  UpdatePlanExecTaskArgs,
  UpdatePlanExecArgs,
  SetShowingToolsAtHomeArgs,
  ToggleChatItemFaqsArgs,
  RequestFollowUpQuestionsArgs,
  SetFollowUpQuestionsArgs,
  ToggleSourceCitationArgs,
  SetSelectedSideBarTabArgs,
  RequestJinaContentArgs,
  SetJinaContentArgs,
  SetShowingGuidesAtHomeArgs,
  RequestLocalSourceArgs,
  SetLocalSourceArgs,
} from './types';

export const requestChatResponse = (
  createAction<RequestChatResponseArgs>(GptActions.CHAT_RESPONSE_REQUEST)
);
export const setChatResponse = (
  createAction<SetChatResponseArgs>(GptActions.CHAT_RESPONSE_SET)
);
export const cancelChatRequest = (
  createAction(GptActions.CHAT_REQUEST_CANCEL)
);
export const requestChatClean = (
  createAction(GptActions.CHAT_CLEAN_REQUEST)
);
export const setChatClean = (
  createAction<SetChatCleanArgs>(GptActions.CHAT_CLEAN_SET)
);
export const toggleChatItemStep = (
  createAction<ToggleChatItemStepArgs>(GptActions.CHAT_ITEM_STEP_TOGGLE)
);
export const toggleChatItemTable = (
  createAction<ToggleChatItemTableArgs>(GptActions.CHAT_ITEM_TABLE_TOGGLE)
);
export const toggleChatItemSource = (
  createAction<ToggleChatItemSourceArgs>(GptActions.CHAT_ITEM_SOURCE_TOGGLE)
);
export const toggleChatItemFaqs = (
  createAction<ToggleChatItemFaqsArgs>(GptActions.CHAT_ITEM_FAQS_TOGGLE)
);
export const toggleChatItemOrgs = (
  createAction<ToggleChatItemOrgsArgs>(GptActions.CHAT_ITEM_ORGS_TOGGLE)
);
export const setChatStreaming = (
  createAction<SetChatStreamingArgs>(GptActions.CHAT_STRAMING_SET)
);
export const requestChatHistory = (
  createAction(GptActions.CHAT_HISTORY_REQUEST)
);
export const setChatHistory = (
  createAction<SetChatHistoryArgs>(GptActions.CHAT_HISTORY_SET)
);
export const applyChatHistory = (
  createAction<ApplyChatHistoryArgs>(GptActions.CHAT_HISTORY_APPLY)
);
export const requestDeleteChatHistory = (
  createAction<RequestDeleteChatHistoryArgs>(GptActions.CHAT_HISTORY_DELETE_REQUEST)
);
export const setChatHistoryDeleted = (
  createAction<SetChatHistoryDeletedArgs>(GptActions.CHAT_HISTORY_DELETED_SET)
);
export const requestChatPrompts = (
  createAction<RequestChatPromptsArgs>(GptActions.CHAT_PROMPTS_REQUEST)
);
export const setChatPrompts = (
  createAction<SetChatPromptsArgs>(GptActions.CHAT_PROMPTS_SET)
);
export const requestCreateChatPrompt = (
  createAction<RequestCreateChatPromptArgs>(GptActions.CHAT_PROMPT_CREATE_REQUEST)
);
export const setChatPromptCreated = (
  createAction<SetChatPromptCreatedArgs>(GptActions.CHAT_PROMPT_CREATED_SET)
);
export const requestUpdateChatPrompt = (
  createAction<RequestUpdateChatPromptArgs>(GptActions.CHAT_PROMPT_UPDATE_REQUEST)
);
export const setChatPromptUpdated = (
  createAction<SetChatPromptUpdatedArgs>(GptActions.CHAT_PROMPT_UPDATED_SET)
);
export const requestDeleteChatPrompt = (
  createAction<RequestDeleteChatPromptArgs>(GptActions.CHAT_PROMPT_DELETE_REQUEST)
);
export const setChatPromptDeleted = (
  createAction<SetChatPromptDeletedArgs>(GptActions.CHAT_PROMPT_DELETED_SET)
);
export const requestSiteExamples = (
  createAction(GptActions.SITE_EXAMPLES_REQUEST)
);
export const setSiteExamples = (
  createAction<SetSiteExamplesArgs>(GptActions.SITE_EXAMPLES_SET)
);
export const openSourceBubble = (
  createAction<OpenSourceBubbleArgs>(GptActions.SOURCE_BUBBLE_OPEN)
);
export const closeSourceBubble = (
  createAction(GptActions.SOURCE_BUBBLE_CLOSE)
);
export const setChatItemTasks = (
  createAction<SetChatItemTasksArgs>(GptActions.CHAT_ITEM_TASKS_SET)
);
export const startExecuteTasks = (
  createAction<StartTasksExecuteArgs>(GptActions.TASKS_EXECUTE_START)
);
export const setTasksExecuted = (
  createAction(GptActions.TASKS_EXECUTED_SET)
);
export const prepareDeepSearchTasks = (
  createAction<PrepareDeepSearchTasksArgs>(GptActions.DEEP_SEARCH_TASKS_PREPARE)
);
export const requestTaskerExecute = (
  createAction<RequestTaskerExecuteArgs>(GptActions.TASKER_EXECUTE_REQUEST)
);
export const setTaskerExecuted = (
  createAction<SetTaskerExecutedArgs>(GptActions.TASKER_EXECUTED_SET)
);
export const updateLastObjective = (
  createAction<UpdateLastObjectiveArgs>(GptActions.LAST_OBJECTIVE_UPDATE)
);
export const requestTaskTemplates = (
  createAction(GptActions.TASK_TEMPLATES_REQUEST)
);
export const setTaskTemplates = (
  createAction<SetTaskTemplatesArgs>(GptActions.TASK_TEMPLATES_SET)
);
export const applyTaskTemplate = (
  createAction<ApplyTaskTemplateArgs>(GptActions.TASK_TEMPLATE_APPLY)
);
export const requestCreateTaskTemplate = (
  createAction<RequestCreateTaskTemplateArgs>(GptActions.TASK_TEMPLATE_CREATE_REQUEST)
);
export const setTaskTemplateCreated = (
  createAction<SetTaskTemplateCreatedArgs>(GptActions.TASK_TEMPLATE_CREATED_SET)
);
export const requestUpdateTaskTemplate = (
  createAction<RequestUpdateTaskTemplateArgs>(GptActions.TASK_TEMPLATE_UPDATE_REQUEST)
);
export const setTaskTemplateUpdated = (
  createAction<SetTaskTemplateUpdatedArgs>(GptActions.TASK_TEMPLATE_UPDATED_SET)
);
export const requestDeleteTaskTemplate = (
  createAction<RequestDeleteTaskTemplateArgs>(GptActions.TASK_TEMPLATE_DELETE_REQUEST)
);
export const setTaskTemplateDeleted = (
  createAction<SetTaskTemplateDeletedArgs>(GptActions.TASK_TEMPLATE_DELETED_SET)
);
export const duplicateChatItemChart = (
  createAction<DuplicateChatItemChartArgs>(GptActions.CHAT_ITEM_CHART_DUPLICATE)
);
export const removeChatItemChart = (
  createAction<RemoveChatItemChartArgs>(GptActions.CHAT_ITEM_CHART_REMOVE)
);
export const requestUpdateChatItemChart = (
  createAction<RequestChatItemChartUpdateArgs>(GptActions.CHAT_ITEM_CHART_UPDATE_REQUEST)
);
export const setChatItemChartUpdated = (
  createAction<SetChatItemChartUpdatedArgs>(GptActions.CHAT_ITEM_CHART_UPDATED_SET)
);
export const undoChatItemChartChange = (
  createAction<UndoChatItemChartChangeArgs>(GptActions.CHAT_ITEM_CHART_CHANGE_UNDO)
);
export const requestTableToChart = (
  createAction<RequestTableToChartArgs>(GptActions.TABLE_TO_CHART_REQUEST)
);
export const setTableToChart = (
  createAction<SetTableToChartArgs>(GptActions.TABLE_TO_CHART_SET)
);
export const requestGptModels = (
  createAction(GptActions.GPT_MODELS_REQUEST)
);
export const setGptModels = (
  createAction<SetGptModelsArgs>(GptActions.GPT_MODELS_SET)
);
export const updateSettings = (
  createAction<UpdateSettingsArgs>(GptActions.SETTINGS_UPDATE)
);
export const setSideBarOpen = (
  createAction<SetSideBarOpenArgs>(GptActions.SIDE_BAR_OPEN_SET)
);
export const setSelectedSideBarTab = (
  createAction<SetSelectedSideBarTabArgs>(GptActions.SELECTED_SIDE_BAR_TAB_SET)
);
export const applyChatOutputsCompare = (
  createAction<ApplyChatOutputsCompareArgs>(GptActions.CHAT_OUTPUTS_COMPARE_APPLY)
);
export const selectChatResponseModel = (
  createAction<SelectChatResponseModelArgs>(GptActions.CHAT_RESPONSE_MODEL_SELECT)
);
export const updatePlanExecTask = (
  createAction<UpdatePlanExecTaskArgs>(GptActions.PLAN_EXEC_TASK_UPDATE)
);
export const updatePlanExec = (
  createAction<UpdatePlanExecArgs>(GptActions.PLAN_EXEC_UPDATE)
);
export const setShowingToolsAtHome = (
  createAction<SetShowingToolsAtHomeArgs>(GptActions.SHOWING_TOOLS_AT_HOME_SET)
);
export const setShowingGuidesAtHome = (
  createAction<SetShowingGuidesAtHomeArgs>(GptActions.SHOWING_GUIDES_AT_HOME_SET)
);
export const requestFollowUpQuestions = (
  createAction<RequestFollowUpQuestionsArgs>(GptActions.FOLLOW_UP_QUESTIONS_REQUEST)
);
export const setFollowUpQuestions = (
  createAction<SetFollowUpQuestionsArgs>(GptActions.FOLLOW_UP_QUESTIONS_SET)
);
export const toggleSourceCitation = (
  createAction<ToggleSourceCitationArgs>(GptActions.SOURCE_CITATION_TOGGLE)
);
export const requestJinaContent = (
  createAction<RequestJinaContentArgs>(GptActions.JINA_CONTENT_REQUEST)
);
export const setJinaContent = (
  createAction<SetJinaContentArgs>(GptActions.JINA_CONTENT_SET)
);
export const requestLocalSource = (
  createAction<RequestLocalSourceArgs>(GptActions.LOCAL_SOURCE_REQUEST)
);
export const setLocalSource = (
  createAction<SetLocalSourceArgs>(GptActions.LOCAL_SOURCE_SET)
);
