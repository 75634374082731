import { createAction } from '@reduxjs/toolkit';
import {
  AutocompleteActions,
  RequestAutocompleteItemsArgs,
  SetAutocompleteItemsArgs,
  AbortAutocompleteItemsRequestArgs,
} from './types';

export const requestAutocompleteItems = (
  createAction<RequestAutocompleteItemsArgs>(AutocompleteActions.ITEMS_REQUEST)
);
export const setAutocompleteItems = (
  createAction<SetAutocompleteItemsArgs>(AutocompleteActions.ITEMS_SET)
);
export const abortAutocompleteItemsRequest = (
  createAction<AbortAutocompleteItemsRequestArgs>(AutocompleteActions.ITEMS_REQUEST_ABORT)
);
