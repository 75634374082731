import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';
import getCommonStyles from '../../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    resultPanel: {
      marginTop: theme.spacing(2),
    },
    searchResults: {
      position: 'relative',
      minHeight: (135 + 16) * 4 - 16,
    },
    paginationContainer: {
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
      height: 80,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      background: `
        linear-gradient(0deg,
          ${theme.palette.background.paper} 0%,
          ${theme.palette.background.paper} 85%,
          ${Color(theme.palette.background.paper).alpha(0).toString()} 100%);
      `,
      zIndex: 100,
    },
  });
};

export default styles;
