import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { format, intervalToDuration } from 'date-fns';
import React, { useState } from 'react';
import GptAnswerView from '../gptAnswerView';
import { translateEvaluationTable } from '../gptUtils';
import { GptChatItem } from '../types';
import { PropsFromRedux } from './container';
import styles from './GptSummaryView.styles';

interface Props extends PropsFromRedux {
  answerViewId?: string;
  chatItem: GptChatItem;
  lastExecuted?: Date;
  evaluationSeconds?: number;
  objective?: string;
}

const useStyles = makeStyles(styles);

const getEvaluationTime = (evaluationSeconds?: number) => {
  if (!evaluationSeconds) {
    return undefined;
  }

  const duration = intervalToDuration({
    start: 0,
    end: evaluationSeconds * 1000,
  });

  return [
    duration.minutes ? `${duration.minutes}m` : undefined,
    duration.seconds ? `${duration.seconds}s` : undefined,
  ].filter((s) => !!s).join(' ');
};

const GptSummaryView:React.FC<Props> = ({
  answerViewId,
  chatItem,
  lastExecuted,
  evaluationSeconds,
  objective,
}: Props) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={classes.root}>
      <div className={classes.tabsContainer}>
        <Button
          className={classNames(classes.tab, activeTab === 0 && 'active')}
          onClick={() => setActiveTab(0)}
        >
          <div style={{ position: 'relative' }}>Evaluation</div>
        </Button>
        <Button
          className={classNames(classes.tab, activeTab === 1 && 'active')}
          onClick={() => setActiveTab(1)}
        >
          <div>Summary</div>
        </Button>
      </div>
      <Box marginTop={2}>
        {activeTab === 0 && (
          <Box className={classes.summaryView}>
            <Box marginTop={2}>
              <Typography variant="body2">
                Date: {format(lastExecuted || new Date(), 'yyyy-mm-dd')}
              </Typography>
              <Typography variant="body2">
                Agent: TaskMaster
              </Typography>
              <Typography variant="body2">
                Duration: {getEvaluationTime(evaluationSeconds)}
              </Typography>
            </Box>
            <Box marginTop={2} marginBottom={2} display="flex" columnGap={1} justifyContent="center">
              <Typography className={classes.objective} variant="body1">
                Objective:
              </Typography>
              <Typography className={classes.objectiveText} variant="body1">
                {objective}
              </Typography>
            </Box>
            <GptAnswerView
              answerMarkdown={translateEvaluationTable(chatItem.evaluation)}
            />
          </Box>
        )}
        {activeTab === 1 && (
          <GptAnswerView
            answerViewId={answerViewId}
            answerMarkdown={chatItem.summary}
            answerSources={chatItem.sources}
          />
        )}
      </Box>
    </div>
  );
};

export default GptSummaryView;
