import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../types';
import {
  triggerNotification,
  removeNotification,
  setNotificationDisplayed,
} from './actions';

import Notifier from './Notifier';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.router,
    ...state.notifier,
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    triggerNotification,
    removeNotification,
    setNotificationDisplayed,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Notifier);
