import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../types';
import {
  requestLogin,
} from '../session/actions';

import Login from './Login';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.router,
    ...state.session,
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    requestLogin,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Login);
