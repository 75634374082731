import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, Typography } from '@mui/material';
import classNames from 'classnames';

import { ReactComponent as StyledLogo } from '../../../../assets/svg/styledLogo.svg';
import { ReactComponent as StartupIconLite } from '../../../../assets/svg/startupIconLite.svg';
import { ReactComponent as FounderIconLite } from '../../../../assets/svg/founderIconLite.svg';
import { ReactComponent as TeamIconLite } from '../../../../assets/svg/teamIconLite.svg';
import { ReactComponent as HomeIcon } from '../../../../assets/svg/homeIcon.svg';
import { useCommonClasses } from '../../../../theme/commonStyles';

import { PropsFromRedux } from './container';

import styles from './StepsHeader.styles';
import { isTeamRelevant } from '../manualFormUtils';

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);

const StepsHeader: React.FC<Props> = ({
  currentStep,
  manualFormData,
  setIsCancelling,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();

  const logoVariants = [
    'company',
    'founder',
    'team',
  ];

  return (
    <div className={classes.root}>
      <IconButton
        className={
          classNames(
            classes.homeButton,
          )
        }
        classes={commonCss.navMenu.menuIconButton}
        color="inherit"
        size="small"
        onClick={() => setIsCancelling({ isCancelling: true })}
      >
        <HomeIcon fontSize="medium" color="inherit" />
      </IconButton>
      <StyledLogo className={classNames(classes.styledLogo, logoVariants[currentStep])} />
      <div className={classNames(classes.stepBanner, 'secondary', currentStep === 0 && 'active')}>
        <StartupIconLite />
        <Typography variant="inherit" color="inherit">
          The Startup
        </Typography>
      </div>
      <div className={classes.stepDivider} />
      <div className={classNames(classes.stepBanner, 'tertiary', currentStep === 1 && 'active')}>
        <FounderIconLite />
        <Typography variant="inherit" color="inherit">
          The Founders
        </Typography>
      </div>
      <div
        className={
          classNames(
            classes.stepDivider,
            !isTeamRelevant(manualFormData) && 'off',
          )
        }
      />
      <div
        className={
          classNames(
            classes.stepBanner,
            'primary',
            'contrast',
            !isTeamRelevant(manualFormData) && 'off',
            currentStep === 2 && 'active',
          )}
        >
        <TeamIconLite />
        <Typography variant="inherit" color="inherit">
          The Team
        </Typography>
      </div>
    </div>
  );
};

export default StepsHeader;
