import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { Typography } from '@mui/material';
import CountUp from 'react-countup';
import { useResizeDetector } from 'react-resize-detector';

import { useCommonClasses } from '../../theme/commonStyles';
import { easeOut } from '../../core/utils/animationUtils';

import styles from './FeatureBarChart.styles';

type Props = {
  value?: number;
  normalizedValue?: number;
  colorClass?: string;
  modifierClass?: string;
  animationDuration?: number;
};

const useStyles = makeStyles(styles);
const LABEL_WIDTH = 60;

const FeatureBarChart: React.FC<Props> = ({
  value,
  normalizedValue,
  colorClass,
  modifierClass,
  animationDuration = 1000,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const [barWidth, setBarWidth] = useState(0);
  const { width, ref } = useResizeDetector();
  const containerWidth = Math.max((width || 0));

  useEffect(() => {
    if (containerWidth > 0) {
      const allowWidth = Math.max(0, (containerWidth || 0) - LABEL_WIDTH);
      const normalizedWidth = allowWidth * ((normalizedValue || 0) / 100);
      setBarWidth(normalizedWidth);
    }
  }, [containerWidth, normalizedValue]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.chartContainer} ref={ref}>
          {containerWidth ? (
            <div className={classes.barContainer}>
              <div
                className={
                  classNames(
                    classes.bar,
                    commonCss.charts.horizontalBarChart,
                    colorClass,
                    modifierClass,
                  )
                }
                style={{
                  width: `${barWidth || 0}px`,
                  maxWidth: containerWidth,
                  transition: `all ease-out ${animationDuration}ms`,
                }}
              />
              <Typography variant='body1' className={classes.label}>
                <CountUp
                  end={value || 0}
                  duration={animationDuration / 1000}
                  decimals={1}
                  useEasing
                  delay={0.5}
                  easingFn={easeOut}
                />
                <small>%</small>
              </Typography>
            </div>
          ) : <div/>}
        </div>
      </div>
    </>
  );
};

export default FeatureBarChart;
