export function isWebUrl(value: string) {
  return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)*[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g.test(value);
}

export function isLinkedInProfileUrl(value: string) {
  return /^((https?:\/\/)?(www.)?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?)$/g.test(value);
}

export function validateEmail(value: string) {
  return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value);
}
