import React, { CSSProperties, useCallback, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';

import { jsonrepair } from 'jsonrepair';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { format } from 'date-fns';

import {
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';

import styles from './ChatHistory.styles';
import { PropsFromRedux } from './container';
import { useCommonClasses } from '../../../../theme/commonStyles';
import { ChatHistoryItem, IntermediateStepHeader } from '../../gpt/types';
import { extractIntermediateSteps } from '../../gpt/gptUtils';
import isDebugMode from '../../../../core/utils/debugUtils';

const useStyles = makeStyles(styles);

interface Props extends PropsFromRedux {
  chatFilter?: string;
}

const ChatHistory: React.FC<Props> = ({
  applyChatHistory,
  chatHistoryItems,
  chatFilter = '',
  openMessageBox,
  requestDeleteChatHistory,
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const theme = useTheme();

  const [menuButton, setMenuButton] = useState<HTMLButtonElement>();
  const [menuChatItem, setMenuChatItem] = useState<ChatHistoryItem>();

  const validChatHistoryItems = (chatHistoryItems || [])
    .filter((item) => item.subItems.length > 0)
    .filter((item) => item.subItems.some((subItem) => (
      subItem.inputText.toLowerCase().split(' ')
        .some((p) => p.startsWith(chatFilter.toLowerCase()))
    )));

  const renderItemText = (text: string) => {
    const matches: [number, number][] = match(text || '', chatFilter);
    const parts = parse(text || '', matches);

    return parts.map((part, index) => (
      <span
        key={index}
        style={{
          fontWeight: part.highlight ? 700 : undefined,
          color: part.highlight ? theme.palette.grey[900] : theme.palette.grey[700],
        }}
      >
        {part.text}
      </span>
    ));
  };

  const getChatHeader = (chatHistoryItem: ChatHistoryItem) => {
    const item = chatHistoryItem.subItems.at(0);
    const chatAnswerJson = jsonrepair(item?.outputText || '{}');
    const chatAnswer = JSON.parse(chatAnswerJson) as Record<string, unknown>;

    const chatAnswerKeys = Object.keys(chatAnswer);

    const streamOutputKey = chatAnswerKeys.find((key) => {
      const data = chatAnswer[key];
      return typeof data === 'string' && data.startsWith('Thought:');
    });

    const streamOutput = streamOutputKey
      && String(chatAnswer[streamOutputKey]);

    if (!streamOutput) {
      return undefined;
    }

    const intermediateSteps = extractIntermediateSteps(chatAnswer);
    const firstStep = intermediateSteps?.at(0)?.at(0);

    if (!firstStep) {
      return undefined;
    }

    return firstStep as unknown as IntermediateStepHeader;
  };

  const renderChatResponseTitle = (chatHistoryItem: ChatHistoryItem) => (
    <header>
      {isDebugMode() && `${chatHistoryItem.latestChatItemId} - `}
      {format(new Date(chatHistoryItem.updated!), 'EEEE, MMMM d')}
    </header>
  );

  const renderChatResponseText = (chatHistoryItem: ChatHistoryItem) => {
    const chatResponseHeader = getChatHeader(chatHistoryItem);
    const text = chatResponseHeader?.tool === 'TaskMaster'
      ? chatResponseHeader.toolInput
      : chatHistoryItem.subItems?.at(0)?.inputText;

    try {
      const textObj = (text ? JSON.parse(text) : undefined) as Record<string, string>;
      return renderItemText(textObj?.query);
    } catch {
      return renderItemText(text || '');
    }
  };

  const row = useCallback(({ index, style }: { index: number, style: CSSProperties }) => {
    const chatHistoryItem = validChatHistoryItems[index];
    if (!chatHistoryItem) {
      return <div style={style} />;
    }

    return (
      <div key={index} style={style}>
        <Paper
          classes={commonCss.panels.leafPanel}
          className={classes.tabContentItem}
          onClick={() => applyChatHistory({
            id: chatHistoryItem.id,
            netGraph: chatHistoryItem.subItems?.at(0)?.netGraph,
            items: chatHistoryItem.subItems,
          })}
        >
          <div className={classes.tabContentItemText}>
            {renderChatResponseTitle(chatHistoryItem)}
            {renderChatResponseText(chatHistoryItem)}
          </div>
          <div className={classes.tabContentControlBox}>
            <IconButton
              onClick={(event) => {
                setMenuButton(event.currentTarget);
                setMenuChatItem(chatHistoryItem);

                event.stopPropagation();
                event.preventDefault();
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        </Paper>
        <Divider className={classes.tabContentItemDivider} />
      </div>
    );
  }, [chatFilter, chatHistoryItems]);

  return (
    <div className={classes.root}>
      <AutoSizer>
        {({ height, width }: { width?: number, height?: number }) => (
          <FixedSizeList
            width={width || 0}
            height={height || 0}
            itemCount={validChatHistoryItems.length}
            itemSize={64}
            className={classes.virtualizedContainer}
          >
            {row}
          </FixedSizeList>
        )}
      </AutoSizer>
      {menuButton && menuChatItem && (
        <Menu
          open={!!menuButton}
          anchorEl={menuButton}
          onClose={() => {
            setMenuButton(undefined);
            setMenuChatItem(undefined);
          }}
        >
          <MenuItem
            onClick={() => {
              setMenuButton(undefined);
            }}
          >
            <Typography variant="body2">
              Edit
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setMenuButton(undefined);

              openMessageBox({
                content: (
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <Typography variant="body1">
                      Are you sure you want to delete the following chat history?
                    </Typography>
                    <Typography variant="body2" marginTop={1}>
                      <strong>
                        {renderChatResponseTitle(menuChatItem)}
                      </strong>
                      <span>
                        {renderChatResponseText(menuChatItem)}
                      </span>
                    </Typography>
                  </Box>
                ),
                dismissText: 'No',
                colorSet: 'tertiary',
                commands: [{
                  title: 'Yes',
                  action: () => {
                    const chatId = menuChatItem.subItems?.at(0)?.id;
                    if (!chatId) {
                      return;
                    }

                    requestDeleteChatHistory({
                      id: chatId,
                      conversationId: menuChatItem.id,
                    });
                  },
                }],
              });
            }}
          >
            <Typography variant="body2" color="error">
              Delete
            </Typography>
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default ChatHistory;
