import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
    },
    title: {
      marginLeft: `${theme.spacing(-0.5)} !important`,
      [theme.breakpoints.down('xl')]: {
        fontSize: '2.5rem',
      },
      fontWeight: '100 !important',
      whiteSpace: 'nowrap',
    },
    chartContainer: {
      display: 'flex',
      flex: 1,
    },
    barSection: {
      height: '100%',
      transition: 'all ease-out 1s',
      '&:hover $bar': {
        marginTop: theme.spacing(3.5),
        height: theme.spacing(2),
      },
      '&.active $bar': {
        marginTop: theme.spacing(3.5),
        height: theme.spacing(2),
      },
      cursor: 'pointer',
    },
    bar: {
      marginTop: theme.spacing(4),
      transition: 'all ease-out 0.3s',
      cursor: 'pointer',
    },
    percentLabel: {
      marginTop: `-${theme.spacing(3)} !important`,
      marginLeft: `-${theme.spacing(1)} !important`,
      fontWeight: '400 !important',
    },
    itemTitle: {
      marginLeft: `-${theme.spacing(0.5)} !important`,
      marginBottom: `-${theme.spacing(3)} !important`,
    },
  });
};

export default styles;
