import React, { useState, lazy, Suspense } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Chip,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import { getYear } from 'date-fns';

import { Country, ICountry } from 'country-state-city';

import { useCommonClasses } from '../../../../theme/commonStyles';
import { ItemsSelectorPopoverFactory } from '../../../../components';
import useCommonProps from '../../../../theme/commonProps';
import { getNumberFieldProps, getUpDownEndAdornment } from '../../../../core/utils/fieldUtils';
import { ReactComponent as DropDownIcon } from '../../../../assets/svg/arrowDownIcon.svg';

import { Industry, Vertical } from '../types';

import { PropsFromRedux } from './container';
import styles from './CompanySearchFilter.styles';

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);
const CountrySelectorPopover = ItemsSelectorPopoverFactory<ICountry>();
const IndustrySelectorPopover = ItemsSelectorPopoverFactory<Industry>();
const VerticalSelectorPopover = ItemsSelectorPopoverFactory<Vertical>();
const Flag = lazy(() => import('react-world-flags'));

const CompanySearchFilter: React.FC<Props> = ({
  updateCountriesFilter,
  updateIndustriesFilter,
  updateVerticalsFilter,
  updateMinFoundingYearsFilter,
  filter,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();
  const commonProps = useCommonProps();

  const [
    companySearchPopoverAnchor,
    setCompanySearchPopoverAnchor,
  ] = useState<HTMLButtonElement>();

  const [
    industrySearchPopoverAnchor,
    setIndustrySearchPopoverAnchor,
  ] = useState<HTMLButtonElement>();

  const [
    verticalSearchPopoverAnchor,
    setVerticalSearchPopoverAnchor,
  ] = useState<HTMLButtonElement>();

  const allCountries = Country.getAllCountries();
  const selectedCountries = filter.countries || [];

  const allIndustries: Industry[] = [{
    id: 1,
    name: 'Online Retail & Services',
  }, {
    id: 2,
    name: 'IT / Miscellaneous',
  }, {
    id: 3,
    name: 'Financial Services',
  }, {
    id: 4,
    name: 'Media & Entertainment',
  }, {
    id: 5,
    name: 'Healthcare',
  }, {
    id: 6,
    name: 'Energy & Utilities',
  }];
  const selectedIndustries = filter.industries || [];

  const allVerticals: Vertical[] = [{
    id: 3,
    name: 'Artificial Intelligence',
  }, {
    id: 2,
    name: 'Data & Analytic',
  }, {
    id: 1,
    name: 'Software Developer',
  }, {
    id: 4,
    name: 'Miscellaneous',
  }];
  const selectedVerticals = filter.verticals || [];

  const filterFields = [{
    label: 'Country',
    renderSelector: () => (
      <>
        <Button
          variant="outlined"
          color="secondary"
          className={classNames(classes.selectorButton, 'secondary')}
          classes={commonCss.buttons.generalButton}
          size="small"
          fullWidth
          onClick={(event) => setCompanySearchPopoverAnchor(event.currentTarget)}
        >
          <Box alignItems="center" display="flex" flexWrap="nowrap">
            <Typography variant="body2" color="secondary">
              {selectedCountries.length}
            </Typography>
            <Box component="span" display="inline-block" marginLeft={0.5}>
              Countries
            </Box>
          </Box>
          <DropDownIcon width="12.98" className={classes.selectorDropDownIcon} />
        </Button>
        <CountrySelectorPopover
          searchPlaceholder="Search Countries"
          items={allCountries}
          anchorEl={companySearchPopoverAnchor}
          onClose={() => setCompanySearchPopoverAnchor(undefined)}
          onChange={(countries) => updateCountriesFilter({ countries })}
          selectedItems={filter.countries}
          itemsComparer={(a, b) => a.isoCode === b.isoCode}
          getItemName={(item) => item.name}
          itemIconRenderer={(item) => (
            <Suspense>
              <Flag
                className={classes.selectorItemIcon}
                code={item.isoCode}
                width={theme.spacing(3)}
              />
            </Suspense>
          )}
        />
      </>
    ),
    renderSelected: () => selectedCountries.map((country) => (
      <Chip
        key={country.isoCode}
        className={classNames(classes.selectedItem, 'secondary')}
        classes={{
          root: commonCss.chips.tagItem.root,
          deleteIcon: classNames(
            classes.selectedItemDeleteIcon,
            commonCss.chips.tagItem.deleteIcon,
          ),
        }}
        variant="outlined"
        label={country.name}
        onDelete={() => updateCountriesFilter({
          countries: selectedCountries.filter((c) => c.isoCode !== country.isoCode),
        })}
      />
    )),
  }, {
    label: 'Industry',
    renderSelector: () => (
      <>
        <Button
          variant="outlined"
          color="secondary"
          className={classNames(classes.selectorButton, 'secondary')}
          classes={commonCss.buttons.generalButton}
          size="small"
          fullWidth
          onClick={(event) => setIndustrySearchPopoverAnchor(event.currentTarget)}
        >
          <Box alignItems="center" display="flex" flexWrap="nowrap">
            <Typography variant="body2" color="secondary">
              {selectedIndustries.length}
            </Typography>
            <Box component="span" display="inline-block" marginLeft={0.5}>
              Industries
            </Box>
          </Box>
          <DropDownIcon width="12.98" className={classes.selectorDropDownIcon} />
        </Button>
        <IndustrySelectorPopover
          showSearch={false}
          searchPlaceholder="Search Industries"
          items={allIndustries}
          anchorEl={industrySearchPopoverAnchor}
          onClose={() => setIndustrySearchPopoverAnchor(undefined)}
          onChange={(industries) => updateIndustriesFilter({ industries })}
          selectedItems={filter.industries}
          itemsComparer={(a, b) => a.name === b.name}
          getItemName={(item) => item.name}
        />
      </>
    ),
    renderSelected: () => selectedIndustries.map((industry) => (
      <Chip
        key={industry.name}
        className={classNames(classes.selectedItem, 'secondary')}
        classes={{
          root: commonCss.chips.tagItem.root,
          deleteIcon: classNames(
            classes.selectedItemDeleteIcon,
            commonCss.chips.tagItem.deleteIcon,
          ),
        }}
        variant="outlined"
        label={industry.name}
        onDelete={() => updateIndustriesFilter({
          industries: selectedIndustries.filter((c) => c.name !== industry.name),
        })}
      />
    )),
  }, {
    label: 'Vertical',
    renderSelector: () => (
      <>
        <Button
          variant="outlined"
          color="secondary"
          className={classNames(classes.selectorButton, 'secondary')}
          classes={commonCss.buttons.generalButton}
          size="small"
          fullWidth
          onClick={(event) => setVerticalSearchPopoverAnchor(event.currentTarget)}
        >
          <Box alignItems="center" display="flex" flexWrap="nowrap">
            <Typography variant="body2" color="secondary">
              {selectedVerticals.length}
            </Typography>
            <Box component="span" display="inline-block" marginLeft={0.5}>
              Verticals
            </Box>
          </Box>
          <DropDownIcon width="12.98" className={classes.selectorDropDownIcon} />
        </Button>
        <VerticalSelectorPopover
          showSearch={false}
          searchPlaceholder="Search Verticals"
          items={allVerticals}
          anchorEl={verticalSearchPopoverAnchor}
          onClose={() => setVerticalSearchPopoverAnchor(undefined)}
          onChange={(verticals) => updateVerticalsFilter({ verticals })}
          selectedItems={filter.verticals}
          itemsComparer={(a, b) => a.name === b.name}
          getItemName={(item) => item.name}
        />
      </>
    ),
    renderSelected: () => selectedVerticals.map((vertical) => (
      <Chip
        key={vertical.name}
        className={classNames(classes.selectedItem, 'secondary')}
        classes={{
          root: commonCss.chips.tagItem.root,
          deleteIcon: classNames(
            classes.selectedItemDeleteIcon,
            commonCss.chips.tagItem.deleteIcon,
          ),
        }}
        variant="outlined"
        label={vertical.name}
        onDelete={() => updateVerticalsFilter({
          verticals: selectedVerticals.filter((c) => c.name !== vertical.name),
        })}
      />
    )),
  }];

  const allSelectedItems = filterFields.flatMap((field) => [
    ...field.renderSelected(),
  ]);

  return (
    <div className={classes.root}>
      <Box display="flex" flex={1} columnGap={1}>
        <Box className={classes.filterItem}>
          <Box className={classes.selectorContainer}>
            <div className={classes.selector}>
              <TextField
                className={classes.minFoundingYearField}
                {...commonProps.textField({
                  endAdornment: getUpDownEndAdornment({
                    onUp: () => updateMinFoundingYearsFilter({
                      minFoundingYears: Number(filter.minFoundingYears || 1960) + 1,
                    }),
                    onDown: () => updateMinFoundingYearsFilter({
                      minFoundingYears: Number(filter.minFoundingYears || 1960) - 1,
                    }),
                    dense: true,
                  }),
                  min: 1960,
                  max: getYear(new Date()),
                })}
                {...getNumberFieldProps({ maxLength: 4 })}
                size="small"
                type="number"
                value={filter?.minFoundingYears || ''}
                onChange={(event) => updateMinFoundingYearsFilter({
                  minFoundingYears: Number(event.currentTarget.value),
                })}
                placeholder="Founding Year"
              />
            </div>
          </Box>
        </Box>
        {filterFields.map((filterField) => (
          <Box
            key={filterField.label}
            className={classes.filterItem}
          >
            <Box className={classes.selectorContainer}>
              <div className={classes.selector}>
                {filterField.renderSelector()}
              </div>
            </Box>
          </Box>
        ))}
      </Box>
      <Box marginTop={1} display="flex" flex={1} gap={1} flexWrap="wrap">
        {allSelectedItems.map((elem, index) => (
          <Box key={index}>
            {elem}
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default CompanySearchFilter;
