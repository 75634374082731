import { createReducer } from '@reduxjs/toolkit';
import {
  requestAutocompleteItems,
  setAutocompleteItems,
} from './actions';

import { AutocompleteState } from './types';

const initialState: AutocompleteState = {
  networkStates: {
    autocompleteRequest: {},
  },
  validationInfo: {},
  storages: {},
};

const autocompleteReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(requestAutocompleteItems, (state, action) => {
      const minKeyword = (action.payload.minKeyword || 0);
      const isSearchable = (action.payload.keyword?.length || 0) >= minKeyword;

      return ({
        ...state,
        validationInfo: {
          ...state.validationInfo,
          [action.payload.storageKey]: {
            isSearchable,
            message: !isSearchable
              ? `Please type at least ${minKeyword} characters`
              : undefined,
          },
        },
        networkStates: {
          ...state.networkStates,
          autocompleteRequest: {
            ...state.networkStates.autocompleteRequest,
            [action.payload.storageKey]: {
              isRequesting: isSearchable,
            },
          },
        },
      });
    })
    .addCase(setAutocompleteItems, (state, action) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        autocompleteRequest: {
          ...state.networkStates.autocompleteRequest,
          [action.payload.storageKey]: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
      },
      storages: {
        ...state.storages,
        [action.payload.storageKey]: action.payload.items,
      },
    }));
});

export default autocompleteReducer;
