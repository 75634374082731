import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../../theme/commonStyles';
import { otherLightColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      paddingTop: `${theme.spacing(1.5)} !important`,
      position: 'relative',
    },
    item: {
      textAlign: 'center',
      position: 'relative',
      '&:not(:first-child)': {
        marginLeft: 'calc((100% / 5) - 80px)',
      },
      '&.navigable': {
        cursor: 'pointer',
        paddingTop: theme.spacing(1),
        paddingBottom: `${theme.spacing(1)} !important`,
        borderRadius: theme.spacing(1),
        '&:hover': {
          backgroundColor: `${otherLightColorSets[4]} !important`,
        },
      },
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    label: {
      marginTop: `${theme.spacing(1)} !important`,
      fontWeight: 'normal !important',
      color: theme.palette.grey[900],
      width: 80,
      height: 40,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    deleteButtonBox: {
      position: 'absolute',
      top: theme.spacing(-1),
      right: theme.spacing(0.5),
      backgroundColor: theme.palette.common.white,
      borderRadius: '50%',
      border: `1px solid ${theme.palette.grey[300]}`,
      height: 20,
      width: 20,
      boxSizing: 'border-box',
    },
    deleteButton: {
      padding: `${theme.spacing(0)} !important`,
      '& .MuiSvgIcon-root': {
        fontSize: '12px !important',
        lineHeight: 1,
        marginTop: theme.spacing(-0.5),
      },
    },
  });
};

export default styles;
