import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';
import getCommonStyles, { getContrastColorModifierClasses } from '../../../../theme/commonStyles';
import { otherColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    filterItem: {
      display: 'flex',
      flex: 1,
    },
    selectorContainer: {
      display: 'flex',
      flexBasis: theme.spacing(30),
      flex: 0,
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
    },
    selector: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'nowrap',
    },
    selectedValueContainer: {
      display: 'flex',
      flex: 1,
      rowGap: theme.spacing(1),
      flexWrap: 'wrap',
    },
    selectorButton: {
      // flexBasis: theme.spacing(19),
      flex: 1,
      paddingLeft: `${theme.spacing(1.5)} !important`,
      justifyContent: 'space-between !important',
      fontSize: '1rem !important',
      fontWeight: '300 !important',
      ...getContrastColorModifierClasses('borderColor'),
    },
    selectedItem: {
      fontWeight: 300,
      borderColor: `${otherColorSets[1]} !important`,
      '& > span': {
        color: `${theme.palette.grey[900]} !important`,
      },
      marginTop: '0 !important',
      marginBottom: '0 !important',
      marginRight: '0 !important',
    },
    selectedItemDeleteIcon: {
      fontSize: '18px !important',
      marginRight: '4px !important',
      '&.MuiSvgIcon-root.contrast.highlight': {
        color: `${theme.palette.common.white} !important`,
      },
    },
    minFoundingYearField: {
      // width: theme.spacing(19),
      flex: 1,
      '& .MuiOutlinedInput-root': {
        borderRadius: `${theme.spacing(4)} !important`,
        border: `1px solid ${theme.palette.secondary.main} !important`,
      },
      '& input.MuiInputBase-inputSizeSmall': {
        paddingTop: theme.spacing(0.23),
        paddingBottom: theme.spacing(0.20),
        paddingLeft: `${theme.spacing(1.5)} !important`,
        fontSize: '1rem',
        fontWeight: 300,
        color: theme.palette.secondary.main,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        display: 'none',
      },
      '& .MuiInputBase-input::placeholder': {
        color: theme.palette.grey[500],
        opacity: 1,
      },
    },
    minFoundingYearInputContainer: {
      transition: 'all ease-out 0.3s',
      '&:hover': {
        backgroundColor: Color(otherColorSets[1]).alpha(0.08).toString(),
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.grey[400]} !important`,
        },
      },
    },
    minFoundingYearInput: {
      paddingTop: '4px !important',
      paddingBottom: '4px !important',
    },
    selectorItemIcon: {
      marginRight: theme.spacing(1),
    },
    yearPicker: {
      flexBasis: theme.spacing(18),
      justifyContent: 'space-between !important',
      fontSize: '1rem !important',
      fontWeight: '300 !important',
      '& input.MuiInputBase-input': {
        paddingTop: `${theme.spacing(0.5)} !important`,
        paddingBottom: `${theme.spacing(0.5)} !important`,
        width: 96,
      },
      '& .MuiInputAdornment-root': {
        marginRight: '0 !important',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.palette.grey[500]} !important`,
      },
    },
    selectorDropDownIcon: {
      position: 'absolute',
      height: 12,
      right: 9,
    },
  });
};

export default styles;
