import { ReportRoutes } from './types';

const reportRoutes: ReportRoutes = {
  main: '',
  onePager: '/onePager',
  profileScore: '/profileScore',
  successScore: '/successScore',
  market: '/market',
  ranking: '/ranking',
  landscape: '/landscape',
  webTraffic: '/webTraffic',
  technologies: '/technologies',
};

export default reportRoutes;
