import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../../theme/commonStyles';
import { otherColorSets, otherContrastColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    tabsContainer: {
      height: theme.spacing(4.5),
      borderBottom: `1px solid ${otherColorSets[2]}`,
      display: 'flex',
      flexDirection: 'row',
      columnGap: theme.spacing(2),
      padding: `0 ${theme.spacing(3)}`,
    },
    tab: {
      marginLeft: 20,
      borderTop: `1px solid ${theme.palette.grey[100]} !important`,
      borderRight: `1px solid ${theme.palette.grey[100]} !important`,
      height: theme.spacing(4.5),
      display: 'inline-block',
      background: 'transparent !important',
      color: `${theme.palette.grey[100]} !important`,
      borderRadius: '0 !important',
      fontWeight: '400 !important',
      width: 'auto',
      padding: `0 ${theme.spacing(2)}`,
      lineHeight: theme.spacing(4.5),
      position: 'relative',
      '&:before': {
        position: 'absolute',
        top: 2,
        left: -4,
        content: '""',
        width: `calc(${theme.spacing(4.5)} - 1px)`,
        height: `calc(${theme.spacing(4.5)} - 1px)`,
        transform: 'rotate(10deg)',
        background: 'transparent',
        borderLeft: `1px solid ${theme.palette.grey[100]} !important`,
      },
      '&.active': {
        borderTop: `1px solid ${otherColorSets[2]} !important`,
        borderRight: `1px solid ${otherColorSets[2]} !important`,
        color: `${otherContrastColorSets[2]} !important`,
        background: '#fff !important',
        '&:before': {
          position: 'absolute',
          top: 2,
          left: -4,
          content: '""',
          width: `calc(${theme.spacing(4.5)} - 1px)`,
          height: `calc(${theme.spacing(4.5)})`,
          transform: 'rotate(10deg)',
          background: '#fff !important',
          borderLeft: `1px solid ${otherColorSets[2]} !important`,
          zIndex: 0,
        },
        '& > *': {
          zIndex: 1,
        },
      },
    },
    summaryView: {
      '& table': {
        width: '100%',
      },
    },
    objective: {
      fontSize: '1.214rem !important',
      fontWeight: '500 !important',
      color: `${theme.palette.grey[900]} !important`,
      flex: 0,
    },
    objectiveText: {
      fontSize: '1.214rem !important',
      fontWeight: '500 !important',
      color: `${otherColorSets[2]} !important`,
    },
  });
};

export default styles;
