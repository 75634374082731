import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Autocomplete,
  Grid,
  Typography,
  useTheme,
  Button,
  useMediaQuery,
  Menu,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  Language as GlobeIcon,
  CheckBox as CheckBoxIcon,
} from '@mui/icons-material';
import { useLocation } from 'react-router';
import { Buffer } from 'buffer';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import CountUp from 'react-countup';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import getSymbolFromCurrency from 'currency-symbol-map';

// import hcRound from 'highcharts-rounded-corners';
import { useResizeDetector } from 'react-resize-detector';

import { useCommonClasses } from '../../../../theme/commonStyles';
import useCommonProps from '../../../../theme/commonProps';
import { otherColorSets, otherContrastColorSets } from '../../../../theme/palette';
import { easeOut } from '../../../../core/utils/animationUtils';

import { ReactComponent as DropDownIcon } from '../../../../assets/svg/arrowDownIcon.svg';
import { ReactComponent as TrendDownIcon } from '../../../../assets/svg/trendDownIcon.svg';
import { ReactComponent as TrendUpIcon } from '../../../../assets/svg/trendUpIcon.svg';
import { ReactComponent as TrendSwingIcon } from '../../../../assets/svg/trendSwingIcon.svg';
import { isNullOrUndefined } from '../../../../core/utils/dataUtils';
import {
  AMOUNT_1M,
  extractSelectableMarkets,
  getAmountDisplayWithSymbol,
} from '../reportUtils';

import { MarketReportView } from '../types';
import { PropsFromRedux } from './container';
import styles from './MarketReportDetails.styles';

interface TrendSetUiDef {
  title?: string;
  values?: (number | JSX.Element | undefined)[];
  mode?: 'trend' | 'value';
  isSpace?: boolean;
  isDivider: boolean;
  xs?: number;
  xl?: number;
  labelXs?: number;
  labelXl?: number;
  itemsXs?: number;
  itemsXl?: number;
  setClass?: string;
  setLabelClass?: string;
  setItemsClass?: string;
  itemClass?: string;
}

interface ChartDef {
  name: string;
  enable: boolean;
  dataKey: string;
  dashStyle: string;
  lineWidth: number;
  color: string;
}

interface Props extends PropsFromRedux {}

const Flag = lazy(() => import('react-world-flags'));
const useStyles = makeStyles(styles);

const CHART_YEARS_BACK = 10;

// hcRound(Highcharts);

const HARDCODED_YEAR_END = 2021;

const MarketReportDetails: React.FC<Props> = ({
  selectedMarketItems,
  isAggregateMarketItems,
  setMarketView,
  selectedMarketView,
  marketCountries,
  marketReport,
  selectMarketCountry,
  companyReport,
  selectedMarketCountry,
  setMarketItems,
  marketEconomicIndicators,
  setMarketEcoIndicator,
  selectedHighlightMarket,
  hoveringHighlightMarket,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();
  const commonProps = useCommonProps();
  const location = useLocation();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.xl));
  const [sizeChartOptions, setSizeChartOptions] = useState<Highcharts.Options>();
  const [growthChartOptions, setGrowthChartOptions] = useState<Highcharts.Options>();
  const [indicatorMenuAnchor, setIndicatorsMenuAnchor] = useState<HTMLButtonElement>();
  const [currencySymbol, setCurrencySymbol] = useState<string>();
  const [prevYearStats, setPrevYearStats] = useState<number[]>([]);

  const yearEnd = HARDCODED_YEAR_END;
  const yearStart = yearEnd - CHART_YEARS_BACK;
  const chartLabels = Array
    .from({ length: ((yearEnd + 1) - yearStart) })
    .map((_, index) => yearStart + index);

  const {
    width: chartsWrapperWidth,
    height: chartsWrapperHeight,
    ref: chartsWrapperRef,
  } = useResizeDetector();

  const {
    height: trendBoxHeight,
    ref: trendBoxRef,
  } = useResizeDetector();

  const searchParams = new URLSearchParams(location.search);
  const base64Path = searchParams.get('desc');
  const desc = base64Path && Buffer.from(base64Path, 'base64').toString('utf8');
  const defaultMarket = searchParams.get('market');

  const selectableCountries = [
    ...(marketCountries || []).filter((marketCountry) => !!marketCountry.country),
  ];

  const stat = marketReport?.stat || {};
  const availableStatKeys = Array.from(new Set(Object.values(stat)));

  const currentHighlightMarket = selectedHighlightMarket || hoveringHighlightMarket;

  const tabItems = [
    {
      view: 'marketSize',
      title: 'Market Size',
      statKey: 'Market Size Statistics',
    }, {
      view: 'businesses',
      title: 'Businesses',
      statKey: 'Number of Businesses',
    }, {
      view: 'employees',
      title: 'Employees',
      statKey: 'Employment Statistics',
    }, {
      view: 'totalWages',
      title: 'Total Wages',
      statKey: 'Wage Statistics',
    },
  ].filter((tabItem) => availableStatKeys.includes(tabItem.statKey));

  const colorSetMaps = [
    'primary',
    'tertiary',
    'quaternary',
    'secondary',
  ];

  const chartColorMaps = [
    otherColorSets[0],
    otherColorSets[2],
    otherColorSets[3],
    otherColorSets[1],
  ];

  const trendColorMaps = [
    otherContrastColorSets[0],
    otherContrastColorSets[2],
    otherContrastColorSets[3],
    otherContrastColorSets[1],
  ];

  const currentMarketView = tabItems.find((item) => item.view === selectedMarketView);
  const currentStatKey = currentMarketView?.statKey;
  const subCategoryIndexKeys = Object.keys(stat).filter((key) => stat[key] === currentStatKey);

  const subCategory = marketReport?.subCategory || {};
  const subCategoryInView = subCategoryIndexKeys.reduce((lookup, key) => ({
    ...lookup,
    [key]: subCategory[key],
  }), {} as Record<string, string>);

  const subCategoryIndexLookup = Object.keys(subCategoryInView)
    .reduce((lookup, keyIndex) => ({
      ...lookup,
      [subCategoryInView[keyIndex]]: keyIndex,
    }), {} as Record<string, string>);

  const chartData = marketReport?.chartData || {};

  const chartsHeaderHeight = 36;
  const isSizeReady = chartsWrapperWidth && chartsWrapperHeight && trendBoxHeight;
  const isDataReady = marketReport && selectedMarketCountry;

  const chartDataItems = selectedMarketItems.map((marketItem, index) => {
    const marketItemIndex = subCategoryIndexLookup[marketItem];
    const chartDataByYear = chartData[marketItemIndex];

    const keys = Object.keys(chartDataByYear || {})
      .filter((key) => Number(key) >= yearStart && Number(key) <= yearEnd);

    return {
      marketName: marketItem,
      marketIndex: index,
      labels: keys,
      values: keys.map((key) => chartDataByYear?.[key]),
    };
  });

  const aggregateDataItems = chartDataItems.map((dataItem) => dataItem.values);
  const aggregateKeys = chartDataItems.length > 0
    ? chartDataItems[0].labels : [];

  const aggregateValues = aggregateKeys.map((_, index) => (
    aggregateDataItems.reduce((sum, dataItem) => sum + dataItem[index], 0)
  ));

  const sizeChartSeries = chartDataItems.map((dataItem, index) => ({
    name: dataItem.marketName,
    type: 'column',
    color: chartColorMaps[index],
    className: colorSetMaps[index],
    data: dataItem.values,
  }));

  const aggregateChartSeries = [{
    name: 'Aggregate',
    type: 'column',
    color: chartColorMaps[0],
    data: aggregateValues,
  }];

  const countryGdp = selectedMarketCountry?.countryGdp || {};
  const gdpKeys = Object.keys(countryGdp)
    .filter((key) => Number(key) >= yearStart && Number(key) <= yearEnd);

  const gdpValues = gdpKeys.map((key) => countryGdp[key] / AMOUNT_1M);

  const gdpChartSeries = [{
    name: 'Gross Domestic Product (GDP)',
    type: 'column',
    color: theme.palette.grey[50],
    data: gdpValues,
  }];

  const isCurrencyView = (
    currentMarketView?.view === 'marketSize'
    || currentMarketView?.view === 'totalWages'
  );

  const getSizeChartSeries = () => {
    if (isAggregateMarketItems) {
      return aggregateChartSeries;
    }

    return (sizeChartSeries.length > 0 ? (
      sizeChartSeries.map((serie) => ({
        ...serie,
        type: 'column',
      }))
    ) : (
      gdpChartSeries.map((serie) => ({
        ...serie,
        type: 'column',
      }))
    ));
  };

  const getMarketCagr = (marketItem: string, yearBack: number) => {
    const marketItemIndex = subCategoryIndexLookup[marketItem];
    const chartDataByYear = chartData[marketItemIndex];

    const prevYear = HARDCODED_YEAR_END;
    const prevYearKey = String(prevYear);
    const prevYearValue = chartDataByYear?.[prevYearKey];

    const backYear = prevYear - yearBack;
    const backYearKey = String(backYear);
    const backYearValue = chartDataByYear?.[backYearKey];

    return (((prevYearValue / backYearValue) ** (1 / yearBack)) - 1) * 100;
  };

  const getAggregateCagr = (yearBack: number) => {
    const prevYearValue = aggregateValues[aggregateValues.length - 1];
    const backYearValue = aggregateValues[aggregateValues.length - (yearBack + 1)];

    return (((prevYearValue / backYearValue) ** (1 / yearBack)) - 1) * 100;
  };

  const getGdpCagr = (yearBack: number) => {
    const prevYear = HARDCODED_YEAR_END;
    const prevYearKey = String(prevYear);
    const prevYearValue = selectedMarketCountry?.countryGdp[prevYearKey] || 0;

    const backYear = prevYear - yearBack;
    const backYearKey = String(backYear);
    const backYearValue = selectedMarketCountry?.countryGdp[backYearKey] || 0;

    return (((prevYearValue / backYearValue) ** (1 / yearBack)) - 1) * 100;
  };

  const getCagrs = (yearBack: number) => {
    if (isAggregateMarketItems) {
      return [getAggregateCagr(yearBack)];
    }

    return selectedMarketItems.length > 0 ? (
      selectedMarketItems.map((marketItem) => getMarketCagr(marketItem, yearBack))
    ) : (
      [getGdpCagr(yearBack)]
    );
  };

  const getMarketGrowthTrendIcon = (marketItem: string, index: number) => {
    const y3 = getMarketCagr(marketItem, 3);
    const y5 = getMarketCagr(marketItem, 5);
    const y10 = getMarketCagr(marketItem, 10);

    if (y3 >= y5 && y5 >= y10) {
      return <TrendUpIcon style={{ fill: trendColorMaps[index] }} />;
    }

    if (y3 <= y5 && y5 <= y10) {
      return <TrendDownIcon style={{ fill: trendColorMaps[index] }} />;
    }

    return <TrendSwingIcon style={{ fill: trendColorMaps[index] }} />;
  };

  const getAggregateGrowthTrendIcon = () => {
    const y3 = getAggregateCagr(3);
    const y5 = getAggregateCagr(5);
    const y10 = getAggregateCagr(10);

    if (y3 >= y5 && y5 >= y10) {
      return <TrendUpIcon style={{ fill: trendColorMaps[0] }} />;
    }

    if (y3 <= y5 && y5 <= y10) {
      return <TrendDownIcon style={{ fill: trendColorMaps[0] }} />;
    }

    return <TrendSwingIcon style={{ fill: trendColorMaps[0] }} />;
  };

  const getGdpGrowthTrendIcon = () => {
    const y3 = getAggregateCagr(3);
    const y5 = getAggregateCagr(5);
    const y10 = getAggregateCagr(10);

    if (y3 >= y5 && y5 >= y10) {
      return <TrendUpIcon style={{ fill: theme.palette.grey[500] }} />;
    }

    if (y3 <= y5 && y5 <= y10) {
      return <TrendDownIcon style={{ fill: theme.palette.grey[500] }} />;
    }

    return <TrendSwingIcon style={{ fill: theme.palette.grey[500] }} />;
  };

  const getGrowthTrend = () => {
    if (isAggregateMarketItems) {
      return [getAggregateGrowthTrendIcon()];
    }

    return selectedMarketItems.length > 0 ? (
      selectedMarketItems.map((marketItem, index) => getMarketGrowthTrendIcon(marketItem, index))
    ) : (
      [getGdpGrowthTrendIcon()]
    );
  };

  const getMarketPrevYearStat = (marketItem: string) => {
    const marketItemIndex = subCategoryIndexLookup[marketItem];
    const chartDataByYear = chartData[marketItemIndex];

    const prevYear = HARDCODED_YEAR_END;
    const prevYearKey = String(prevYear);
    const prevYearValue = chartDataByYear?.[prevYearKey];

    return prevYearValue * (isCurrencyView ? AMOUNT_1M : 1);
  };

  const getAggregatePrevYearStat = () => {
    const prevYearValue = aggregateValues[aggregateValues.length - 1];
    return prevYearValue * (isCurrencyView ? AMOUNT_1M : 1);
  };

  const getPrevYearGdp = () => {
    const prevYear = HARDCODED_YEAR_END;
    const prevYearKey = String(prevYear);
    const gdp = selectedMarketCountry?.countryGdp[prevYearKey];

    return gdp;
  };

  useEffect(() => {
    const currencyCode = selectedMarketCountry?.country?.currency || 'USD';
    setCurrencySymbol(getSymbolFromCurrency(currencyCode));
  }, [marketReport]);

  useEffect(() => {
    if (isAggregateMarketItems) {
      const stats = [getAggregatePrevYearStat()];
      setPrevYearStats(stats);

      return;
    }

    const stats = selectedMarketItems.map((marketItem) => getMarketPrevYearStat(marketItem) || 0);
    setPrevYearStats(stats);
  }, [
    selectedMarketItems,
    selectedMarketView,
    isAggregateMarketItems,
  ]);

  useEffect(() => {
    if (desc) {
      const marketCountry = selectableCountries
        .find((marketCountryInfo) => (
          marketCountryInfo.country?.isoCode === companyReport?.companyData.orgCountry
        ));

      const usaMarketCountry = selectableCountries
        .find((marketCountryInfo) => (
          marketCountryInfo.countryCode === 'USA'
        ));

      selectMarketCountry({
        marketCountry: marketCountry || usaMarketCountry,
      });
    }
  }, [marketCountries]);

  useEffect(() => {
    const markets = defaultMarket
      ? [defaultMarket]
      : extractSelectableMarkets(marketReport).slice(0, 1);

    setMarketItems({ markets });
  }, [marketReport]);

  useEffect(() => {
    if (!chartsWrapperWidth || !chartsWrapperHeight || !isSizeReady) {
      return;
    }

    const growthChartDefs: ChartDef[] = [
      {
        name: 'GDP',
        enable: marketEconomicIndicators?.gdp || false,
        dataKey: 'countryGdpChange',
        dashStyle: 'Dash',
        lineWidth: 1,
        color: theme.palette.grey[500],
      },
      {
        name: 'GDP per capita',
        enable: marketEconomicIndicators?.gdpPerCap || false,
        dataKey: 'gdpPerCapitaChange',
        dashStyle: 'ShortDot',
        lineWidth: 1,
        color: theme.palette.grey[500],
      },
      {
        name: 'Inflation',
        enable: marketEconomicIndicators?.inflation || false,
        dataKey: 'inflation',
        dashStyle: 'ShortDot',
        lineWidth: 2,
        color: theme.palette.grey[500],
      },
      {
        name: 'Market cap of listed companies',
        enable: marketEconomicIndicators?.marketCap || false,
        dataKey: 'marketCapListedChange',
        dashStyle: 'ShortDot',
        lineWidth: 4,
        color: theme.palette.grey[500],
      },
    ];

    const growthCountryChartSeries = growthChartDefs.map((def) => {
      const marketCountry = (selectedMarketCountry || {}) as Record<string, Record<string, string>>;
      const chartDataByYear = {
        ...marketCountry[def.dataKey],
      };

      // Fill up missing year data with blank.
      const finalChartDataByYear = chartLabels.reduce((lookup, yearKey) => ({
        ...lookup,
        [yearKey]: isNullOrUndefined(chartDataByYear?.[yearKey])
          ? null
          : chartDataByYear?.[yearKey],
      }), {} as Record<string, string | null>);

      const yearKeys = Object.keys(finalChartDataByYear)
        .filter((key) => Number(key) >= yearStart && Number(key) <= yearEnd);

      return {
        name: def.name,
        type: 'line',
        color: def.enable ? def.color : 'transparent',
        data: def.enable
          ? yearKeys.map((key) => finalChartDataByYear[key])
          : yearKeys.map(() => 0),
        dashStyle: def.dashStyle,
        lineWidth: def.lineWidth,
      };
    });

    const getGrowthMarketSeries = () => {
      const growthMarketSeries = selectedMarketItems.map((marketItem, index) => {
        const marketItemIndex = subCategoryIndexLookup[marketItem];
        const chartDataByYear = chartData[marketItemIndex];

        const yearKeys = Object.keys(chartDataByYear)
          .filter((key) => Number(key) >= yearStart && Number(key) <= yearEnd);

        const mainValues = yearKeys
          .slice(1).map((key) => chartDataByYear?.[key]);
        const dividers = yearKeys
          .slice(0, yearKeys.length - 1).map((key) => chartDataByYear?.[key]);

        const data = mainValues.map((_, i) => (
          (mainValues[i] / dividers[i] - 1) * 100
        ));

        return {
          name: marketItem,
          type: 'line',
          color: chartColorMaps[index],
          data,
          dashStyle: 'Solid',
          lineWidth: 1,
          pointStart: 1,
        };
      });

      return growthMarketSeries;
    };

    const getAggregateGrowthMarketSeries = () => {
      const aggregateByYear = selectedMarketItems.reduce((aggregate, marketItem) => {
        const marketItemIndex = subCategoryIndexLookup[marketItem];
        const chartDataByYear = chartData[marketItemIndex];
        const yearKeys = Object.keys(chartDataByYear);

        const aggregateChartDataByYear = yearKeys.reduce((agg, year) => ({
          ...agg,
          [year]: (aggregate[year] || 0) + (chartDataByYear?.[year] || 0),
        }), {} as Record<string, number>);

        return {
          ...aggregateChartDataByYear,
        };
      }, {} as Record<string, number>);

      const yearKeys = Object.keys(aggregateByYear)
        .filter((key) => Number(key) >= yearStart && Number(key) <= yearEnd);

      const mainValues = yearKeys.slice(1).map((key) => aggregateByYear[key]);
      const dividers = yearKeys.slice(0, yearKeys.length - 1).map((key) => aggregateByYear[key]);
      const data = mainValues.map((_, i) => (
        (mainValues[i] / dividers[i] - 1) * 100
      ));

      const growthMarketAggregateSeries = [{
        name: 'Aggregate Growth Market by Year',
        type: 'line',
        color: otherColorSets[0],
        data,
        dashStyle: 'Solid',
        lineWidth: 1,
        pointStart: 1,
      }];

      return growthMarketAggregateSeries;
    };

    const gdpChartLabels = gdpKeys;

    const updatedSizeChartOptions = isSizeReady ? {
      chart: {
        width: chartsWrapperWidth,
        height: chartsWrapperHeight / 2 - (trendBoxHeight + 96) / 2 - chartsHeaderHeight * 2,
        type: 'column',
        backgroundColor: 'transparent',
        spacing: [10, 0, 0, 0],
      },
      legend: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: chartLabels.length > 0 ? chartLabels : gdpChartLabels,
        crosshair: true,
      },
      yAxis: {
        title: {
          useHTML: true,
          text: currentMarketView?.title,
        },
        labels: {
          formatter: ((ctx) => getAmountDisplayWithSymbol(
            Number(ctx.value || 0) * (isCurrencyView ? AMOUNT_1M : 1),
            {
              capitalized: false,
              smallUnit: false,
              symbol: isCurrencyView ? currencySymbol : '',
            },
          )),
        },
        tickAmount: 6,
      },
      tooltip: {
        formatter: (tooltip) => {
          const amount = (tooltip.chart.hoverPoint?.y || 0) * (isCurrencyView ? AMOUNT_1M : 1);
          const formattedAmount = getAmountDisplayWithSymbol(amount, {
            smallUnit: false,
            symbol: isCurrencyView ? (currencySymbol || '') : '',
            capitalized: true,
            decimals: 2,
          });

          return `${tooltip.chart.hoverSeries?.name || ''}: ${formattedAmount}`;
        },
      },
      plotOptions: {
        series: {
          // borderRadiusTopLeft: 5,
          // borderRadiusTopRight: 5,
          enableMouseTracking: true,
          states: {
            hover: {
              enabled: true,
              lineWidth: 3,
            },
          },
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
          pointStart: 0,
          animation: {
            duration: 1000,
          },
        } as unknown as Highcharts.PlotSeriesOptions,
      },
      accessibility: {
        enabled: false,
      },
      series: isDataReady && getSizeChartSeries(),
    } as Highcharts.Options : undefined;

    const updatedGrowthChartOptions = isSizeReady ? {
      chart: {
        width: chartsWrapperWidth,
        height: chartsWrapperHeight / 2 - (trendBoxHeight + 96) / 2 - chartsHeaderHeight,
        type: 'column',
        backgroundColor: 'transparent',
        spacing: [10, 0, 0, 0],
      },
      legend: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: chartLabels.length > 0 ? chartLabels : gdpChartLabels,
        crosshair: true,
      },
      yAxis: {
        title: {
          useHTML: true,
          text: '% change',
        },
        labels: {
          formatter: ((ctx) => `${ctx.value || 0} %`),
        },
        tickInterval: 10,
      },
      tooltip: {
        pointFormat: '{series.name}: {point.y:.2f}%',
      },
      plotOptions: {
        series: {
          pointStart: 0,
          animation: {
            duration: 1000,
          },
        } as unknown as Highcharts.PlotSeriesOptions,
      },
      accessibility: {
        enabled: false,
      },
      series: isDataReady && [
        ...(
          isAggregateMarketItems
            ? getAggregateGrowthMarketSeries()
            : getGrowthMarketSeries()
        ),
        ...growthCountryChartSeries,
      ],
    } as Highcharts.Options : undefined;

    setSizeChartOptions(updatedSizeChartOptions);
    setGrowthChartOptions(updatedGrowthChartOptions);
  }, [
    marketReport,
    selectedMarketCountry,
    selectedMarketItems,
    selectedMarketView,
    isSizeReady,
    chartsWrapperWidth,
    chartsWrapperHeight,
    marketEconomicIndicators,
    isAggregateMarketItems,
  ]);

  const trendSets: TrendSetUiDef[] = [{
    title: 'Growth Trend',
    values: getGrowthTrend(),
    mode: 'trend',
    isSpace: false,
    isDivider: false,
  }, {
    title: '5y-CAGR',
    values: getCagrs(5),
    mode: 'value',
    isSpace: false,
    isDivider: false,
  }, {
    title: '10y-CAGR',
    values: getCagrs(10),
    mode: 'value',
    isSpace: false,
    isDivider: false,
  }, {
    title: '3y-CAGR',
    values: getCagrs(3),
    mode: 'value',
    isSpace: false,
    isDivider: false,
  }];

  if (selectedMarketItems.length < 3) {
    const temp = trendSets[1];
    [trendSets[1]] = [trendSets[2]];
    trendSets[2] = temp;
  }

  const trendSetUiDefs = (selectedMarketItems.length < 3 || isAggregateMarketItems)
    ? (
      trendSets.reduce((list, trendSet, index) => {
        if (index < trendSets.length - 1) {
          return [
            ...list,
            {
              ...trendSet,
              xs: 2.8125,
              xl: 2.8125,
              labelXs: 7,
              labelXl: index === 0 ? 5.5 : 4.5,
              itemsXs: 5,
              itemsXl: index === 0 ? 6.5 : 7.5,
              setClass: classes.singleRowTrendSet,
              setLabelClass: classes.singleRowTrendSetLabelBox,
              setItemsClass: (selectedMarketItems.length < 2 || isAggregateMarketItems)
                ? classes.singleRowTrendSetItemsBox
                : undefined,
              itemClass: (selectedMarketItems.length === 2 && !isAggregateMarketItems)
                ? 'width50'
                : 'width100',
            },
            {
              isDivider: true,
              isSpace: true,
              xs: 0.25,
              xl: 0.25,
            },
          ];
        }

        return [
          ...list,
          {
            ...trendSet,
            xs: 2.8125,
            xl: 2.8125,
            labelXs: 7,
            labelXl: index === 0 ? 5.5 : 4.5,
            itemsXs: 5,
            itemsXl: index === 0 ? 6.5 : 7.5,
            setClass: classes.singleRowTrendSet,
            setLabelClass: classes.singleRowTrendSetLabelBox,
            setItemsClass: (selectedMarketItems.length < 2 || isAggregateMarketItems)
              ? classes.singleRowTrendSetItemsBox
              : undefined,
            itemClass: (selectedMarketItems.length === 2 && !isAggregateMarketItems)
              ? 'width50'
              : 'width100',
          },
        ];
      }, [] as TrendSetUiDef[])
    ) : (
      trendSets.reduce((list, trendSet) => {
        if (list.length === 0 || list.length === 3) {
          return [
            ...list,
            {
              ...trendSet,
              xs: 5.75,
              xl: 5.5,
              labelXs: 3.5,
              labelXl: 3,
              itemsXs: 8.5,
              itemsXl: 9,
              itemClass: 'width25',
            },
            {
              isDivider: true,
              isSpace: true,
              xs: 0.5,
              xl: 1,
            },
          ];
        }

        return [
          ...list,
          {
            ...trendSet,
            xs: 5.75,
            xl: 5.5,
            labelXs: 3.5,
            labelXl: 3,
            itemsXs: 8.5,
            itemsXl: 9,
            itemClass: 'width25',
          },
        ];
      }, [] as TrendSetUiDef[])
    );

  const getSelectedMarketHighlights = () => {
    if (!currentHighlightMarket) {
      return [];
    }

    const marketItemIndex = subCategoryIndexLookup[currentHighlightMarket];
    const highlights = (marketReport?.highlights?.[marketItemIndex] || '').split('\n\n');

    return highlights;
  };

  const getFormattedAmount = useCallback(
    (amount: number) => (
      getAmountDisplayWithSymbol(amount, { capitalized: true, smallUnit: false, symbol: '' })
    ),
    [],
  );

  return (
    <div className={classes.root}>
      <Grid container className={classes.countrySelectorPanel}>
        <Grid item container xs={3.5} alignItems="center">
          <Typography variant="h6" className={classes.countryLabel}>
            Country
          </Typography>
        </Grid>
        <Grid item container xs={8.5} alignItems="center">
          {selectedMarketCountry && (
            <Autocomplete
              {...commonProps.autocomplete({
                color: 'info',
                size: 'small',
                startAdornment: selectedMarketCountry?.countryCode !== 'WLD' ? (
                  <Suspense>
                    <Flag
                      code={selectedMarketCountry.countryCode}
                      width={theme.spacing(3)}
                      style={{ marginLeft: theme.spacing(1) }}
                    />
                  </Suspense>
                ) : (
                  <Box marginLeft={1} display="flex" alignItems="center">
                    <GlobeIcon color="secondary" />
                  </Box>
                ),
              })}
              options={selectableCountries}
              size="small"
              renderOption={(props, marketCountry) => (
                <li {...props}>
                  {marketCountry.country!.isoCode ? (
                    <Suspense>
                      <Flag
                        code={marketCountry.country!.isoCode}
                        width={theme.spacing(3)}
                        style={{ marginRight: theme.spacing(0.5) }}
                      />
                    </Suspense>
                  ) : (
                    <Box marginRight={0.5}>
                      <GlobeIcon color="secondary" />
                    </Box>
                  )}
                  {marketCountry.country!.name}
                </li>
              )}
              getOptionLabel={(option) => option.country!.name}
              fullWidth
              value={selectedMarketCountry}
              isOptionEqualToValue={(option, value) => option.countryCode === value.countryCode}
              onChange={(_, value) => selectMarketCountry({ marketCountry: value || undefined })}
              disableClearable
            />
          )}
        </Grid>
      </Grid>
      <Box className={classes.chartsPanel}>
        <Grid container columnSpacing={2} marginTop={2}>
          {tabItems.map((tabItem) => (
            <Grid key={tabItem.title} item xs={3}>
              <Box
                className={
                  classNames(
                    classes.tabItem,
                    tabItem.view === selectedMarketView && 'active',
                    isAggregateMarketItems && 'aggregate',
                  )
                }
              >
                <Button
                  className={classes.tabItemButton}
                  variant="text"
                  onClick={() => setMarketView({
                    marketView: tabItem.view as MarketReportView,
                  })}
                >
                  <Typography variant="h5" className={classes.tabItemText}>
                    {tabItem.title}
                  </Typography>
                </Button>
                <div className={classes.tabItemIndicator}>
                  <AnimatePresence>
                    {(tabItem.view === selectedMarketView) && (
                      <>
                        {selectedMarketItems.map((marketItem) => (
                          <motion.div
                            key={marketItem}
                            initial={{
                              y: 6,
                              opacity: 0,
                            }}
                            animate={{
                              y: 0,
                              opacity: 1,
                              transition: {
                                duration: 0.3,
                                ease: 'easeOut',
                              },
                            }}
                            exit={{
                              y: 6,
                              opacity: 0,
                              transition: {
                                duration: 0.3,
                                ease: 'easeOut',
                              },
                            }}
                          />
                        ))}
                      </>
                    )}
                  </AnimatePresence>
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box className={classes.chartsWrapper} ref={chartsWrapperRef}>
          {currentHighlightMarket ? (
            <Box className={classes.chartsInnerContainer}>
              <Typography variant="body2" className={classes.marketHighlightsHeader}>
                Highlights
              </Typography>
              <Typography variant="h4">
                {currentHighlightMarket}
              </Typography>
              <Box marginTop={0.5} marginBottom={0.5}>
                <Divider />
              </Box>
              {chartsWrapperWidth && chartsWrapperHeight && (
                <Box
                  className={classes.marketHighlightsBox}
                  style={{
                    height: chartsWrapperHeight - 16,
                    marginTop: theme.spacing(2),
                  }}
                >
                  <ol className={classes.marketHighlightsList}>
                    {getSelectedMarketHighlights().map((highlight) => (
                      <li
                        key={highlight}
                      >
                        <ListItemText primary={highlight} color="inherit" />
                      </li>
                    ))}
                  </ol>
                </Box>
              )}
            </Box>
          ) : (
            <Box className={classes.chartsInnerContainer}>
              {chartsWrapperWidth && chartsWrapperHeight && (
                <>
                  <Box className={classes.sizeStatSummary}>
                    <Typography variant="h5" className={classes.sizeStatSummaryTitle}>
                      {selectedMarketItems.length > 0 ? (
                        `${HARDCODED_YEAR_END} Stats:`
                      ) : (
                        `Gross Domestic Product (GDP) in ${HARDCODED_YEAR_END}:`
                      )}
                    </Typography>
                    {selectedMarketItems.length === 0 && (
                      <Box
                        className={
                          classNames(
                            commonCss.boxes.dataBox,
                            classes.sizeStatSummaryItem,
                            'leaf',
                          )
                        }
                      >
                        {(isCurrencyView || selectedMarketItems.length === 0) ? currencySymbol : ''}
                        <CountUp
                          end={getPrevYearGdp() || 0}
                          duration={1}
                          decimals={2}
                          useEasing
                          easingFn={easeOut}
                          formattingFn={getFormattedAmount}
                        />
                      </Box>
                    )}
                    {prevYearStats.map((value, index) => (
                      <Box
                        key={index}
                        className={
                          classNames(
                            commonCss.boxes.dataBox,
                            classes.sizeStatSummaryItem,
                            'leaf',
                            colorSetMaps[index],
                          )
                        }
                      >
                        {isCurrencyView ? currencySymbol : ''}
                        <CountUp
                          end={value}
                          duration={1}
                          decimals={2}
                          useEasing
                          easingFn={easeOut}
                          formattingFn={getFormattedAmount}
                        />
                      </Box>
                    ))}
                  </Box>
                  <Box className={classes.chartArea}>
                    {sizeChartOptions && (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={sizeChartOptions}
                      />
                    )}
                  </Box>
                  <Box className={classes.trendsBox} ref={trendBoxRef}>
                    <Grid container columnSpacing={1} rowSpacing={1}>
                      {trendSetUiDefs.map((trendSet, index) => (
                        trendSet.isSpace
                          ? (
                            <Grid key={index} item container xs={trendSet.xs} xl={trendSet.xl} justifyContent="center">
                              {trendSet.isDivider && (
                                <div className={classes.trendDivider} />
                              )}
                            </Grid>
                          )
                          : (
                            <Grid
                              key={index}
                              className={trendSet.setClass}
                              item
                              container
                              xs={trendSet.xs}
                              xl={trendSet.xl}
                            >
                              <Grid
                                item
                                xs={trendSet.labelXs}
                                xl={trendSet.labelXl}
                                className={trendSet.setLabelClass}
                              >
                                <Typography variant="body1">{trendSet.title}</Typography>
                              </Grid>
                              <Grid
                                item
                                container
                                xs={trendSet.itemsXs}
                                xl={trendSet.itemsXl}
                                className={trendSet.setItemsClass}
                                sx={{
                                  rowGap: theme.spacing(1),
                                }}
                              >
                                {(trendSet.values || []).map((value, valueIndex) => (
                                  <Box
                                    key={valueIndex}
                                    className={
                                      classNames(
                                        commonCss.boxes.dataBox,
                                        classes.trendItem,
                                        'leaf',
                                        selectedMarketItems.length && colorSetMaps[valueIndex],
                                        trendSet.itemClass,
                                      )
                                    }
                                  >
                                    {trendSet.mode === 'value' && (
                                      <Typography
                                        variant="body1"
                                        className={
                                          classNames(
                                            classes.trendItemText,
                                            selectedMarketItems.length && colorSetMaps[valueIndex],
                                          )
                                        }
                                      >
                                        <CountUp
                                          end={value as number || 0}
                                          duration={0.5}
                                          decimals={2}
                                          useEasing
                                          easingFn={easeOut}
                                        />
                                        %
                                      </Typography>
                                    )}
                                    {trendSet.mode === 'trend' && (
                                      value
                                    )}
                                  </Box>
                                ))}
                              </Grid>
                            </Grid>
                          )
                      ))}
                    </Grid>
                  </Box>
                  <Box className={classes.growthStatSummary}>
                    <Box>
                      <Typography variant="h5" className={classes.growthStatSummaryTitle}>
                        Growth Rate
                      </Typography>
                      <div
                        className={
                          classNames(
                            classes.growthRateIndicator,
                            selectedMarketItems.length === 1 && 'one',
                            selectedMarketItems.length === 2 && 'two',
                            selectedMarketItems.length === 3 && 'three',
                            selectedMarketItems.length === 4 && 'four',
                            isAggregateMarketItems && 'aggregate',
                          )
                        }
                      />
                    </Box>
                    <Box className={classes.growthStatIndicatorsBox}>
                      {marketEconomicIndicators.gdp && (
                        <Box>
                          <Typography variant="body2" className={classes.growthStatIndicatorTitle}>
                            GDP
                          </Typography>
                          <div className={classes.growthGdpIndicator} />
                        </Box>
                      )}
                      {marketEconomicIndicators.gdpPerCap && (
                        <Box>
                          <Typography variant="body2" className={classes.growthStatIndicatorTitle}>
                            GDP per Capita
                          </Typography>
                          <div className={classes.growthGdpPerCapIndicator} />
                        </Box>
                      )}
                      {marketEconomicIndicators.inflation && (
                        <Box>
                          <Typography variant="body2" className={classes.growthStatIndicatorTitle}>
                            Inflation
                          </Typography>
                          <div className={classes.growthInflationIndicator} />
                        </Box>
                      )}
                      {marketEconomicIndicators.marketCap && (
                        <Box>
                          <Typography variant="body2" className={classes.growthStatIndicatorTitle}>
                            Market Cap of Listed Companies
                          </Typography>
                          <div className={classes.growthMarketCapIndicator} />
                        </Box>
                      )}
                    </Box>
                    <Box flex={0}>
                      <Button
                        variant="outlined"
                        className={classNames(classes.economicIndicatorButton, 'leaf', 'opaque')}
                        classes={commonCss.buttons.generalButton}
                        size="medium"
                        fullWidth
                        onClick={(event) => setIndicatorsMenuAnchor(event.currentTarget)}
                      >
                        {isLargeScreen ? (
                          'Economic Indicators'
                        ) : (
                          <CheckBoxIcon color="primary" />
                        )}
                        <DropDownIcon width="12.98" className={classes.selectorDropDownIcon} />
                      </Button>
                      <Menu
                        className={classes.economicIndicatorMenu}
                        open={Boolean(indicatorMenuAnchor)}
                        anchorEl={indicatorMenuAnchor}
                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                        onClose={() => setIndicatorsMenuAnchor(undefined)}
                      >
                        <MenuItem
                          dense
                          onClick={() => setMarketEcoIndicator({
                            key: 'gdp',
                            value: !marketEconomicIndicators.gdp,
                          })}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              color="primary"
                              disableRipple
                              checked={marketEconomicIndicators.gdp === true}
                              size="small"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Gross domestic product (GDP)"
                          />
                        </MenuItem>
                        <MenuItem
                          dense
                          onClick={() => setMarketEcoIndicator({
                            key: 'gdpPerCap',
                            value: !marketEconomicIndicators.gdpPerCap,
                          })}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              color="primary"
                              disableRipple
                              checked={marketEconomicIndicators.gdpPerCap === true}
                              size="small"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="GDP per capita"
                          />
                        </MenuItem>
                        <MenuItem
                          dense
                          onClick={() => setMarketEcoIndicator({
                            key: 'inflation',
                            value: !marketEconomicIndicators.inflation,
                          })}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              color="primary"
                              disableRipple
                              checked={marketEconomicIndicators.inflation === true}
                              size="small"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Inflation"
                          />
                        </MenuItem>
                        <MenuItem
                          dense
                          onClick={() => setMarketEcoIndicator({
                            key: 'marketCap',
                            value: !marketEconomicIndicators.marketCap,
                          })}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              color="primary"
                              disableRipple
                              checked={marketEconomicIndicators.marketCap === true}
                              size="small"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Market cap of listed companies"
                          />
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                  <Box className={classes.chartArea}>
                    {growthChartOptions && (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={growthChartOptions}
                      />
                    )}
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default MarketReportDetails;
