import React, { useState, lazy, Suspense } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';

import classNames from 'classnames';

import FeatureBarChart from '../../../../components/featureBarChart/FeatureBarChart';
import { useCommonClasses } from '../../../../theme/commonStyles';
import { CompanyReportInfo, InvestorReportInfo } from '../types';
import styles from './CompanyScoreReport.styles';
import { getCompanyLocation, getMaxScorePercent } from '../reportUtils';
import { INTERNAL_SPACING } from '../Reports.styles';

type Props = {
  reportSection?: string;
  companyReportInfo?: CompanyReportInfo;
  investorReportInfo?: InvestorReportInfo;
  currentDataInfoKey?: string;
  selectedDataInfoKeys?: string[];
  onDataMouseEnter?: (dataInfoKey: string) => void;
  onDataMouseLeave?: () => void;
  onDataMouseClick?: (dataInfoKey: string) => void;
};

const useStyles = makeStyles(styles);
const Flag = lazy(() => import('react-world-flags'));

const normalizePercentage = (originalPercent: number, maxPercent: number) => (
  (originalPercent / maxPercent) * 100
);

const CompanyScoreReport: React.FC<Props> = ({
  reportSection,
  companyReportInfo,
  investorReportInfo,
  currentDataInfoKey,
  selectedDataInfoKeys,
  onDataMouseEnter,
  onDataMouseLeave,
  onDataMouseClick,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();
  const [hoveringDataInfoKey, setHoveringDataInfoKey] = useState<string>();

  const { companyData } = companyReportInfo || {};
  const { featureImportance } = (
    reportSection === 'profileScore'
      ? companyReportInfo
      : investorReportInfo
  ) || {};

  const tooltipPrefix = reportSection === 'profileScore' ? 'c' : 'i';
  const maxPercent = reportSection === 'profileScore'
    ? getMaxScorePercent(featureImportance)
    : investorReportInfo && getMaxScorePercent({
      ...featureImportance,
      ...investorReportInfo.groupImportance,
    });

  const renderText = (text: string | number) => (
    <Typography
      classes={{ root: classes.valueLabel }}
      variant="body2"
      fontWeight={300}
      fontSize="1.285rem"
    >
      {text}
    </Typography>
  );

  const renderLocation = () => {
    const location = getCompanyLocation(companyData);

    return (
      <>
        {companyData?.orgCountry && (
          <Suspense>
            <Box marginRight={0.5} display="flex" alignItems="center">
              <Flag
                className={classes.selectorItemIcon}
                code={companyData?.orgCountry}
                width={theme.spacing(3)}
              />
            </Box>
          </Suspense>
        )}
        {renderText(location)}
      </>
    );
  };

  const renderBarChart = (
    value?: number,
    colorClass?: string,
    shouldHighlight?: boolean,
  ) => {
    if (maxPercent === undefined) {
      return <div />;
    }

    const normalizedValue = normalizePercentage(value || 0, maxPercent);
    return (
      <FeatureBarChart
        value={value}
        normalizedValue={normalizedValue}
        colorClass={colorClass}
        modifierClass={shouldHighlight ? 'contrast' : undefined}
      />
    );
  };

  const fieldSets = [{
    key: 'Location',
    colorClass: 'secondary',
    parentDataInfoKey: `${tooltipPrefix}10Company`,
    fields: [{
      title: 'Location',
      dataInfoKey: `${tooltipPrefix}26Location`,
      render: () => (
        renderLocation()
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.stateProvince, 'secondary', shouldHighlight)
      ),
    }, {
      title: 'Founding Year',
      dataInfoKey: `${tooltipPrefix}27Foundingyear`,
      render: () => (
        renderText(companyData?.orgFoundingYear || '')
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.foundingYear, 'secondary', shouldHighlight)
      ),
    }, {
      title: 'Industry',
      dataInfoKey: `${tooltipPrefix}28Industry`,
      render: () => (
        renderText(companyData?.orgIndustry || '')
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.industry, 'secondary', shouldHighlight)
      ),
    }, {
      title: 'Vertical',
      dataInfoKey: `${tooltipPrefix}29Vertical`,
      render: () => (
        renderText(companyData?.orgVertical || '')
      ),
      renderChart: (shouldHighlight?: boolean) => (
        renderBarChart(featureImportance?.vertical, 'secondary', shouldHighlight)
      ),
    }],
  }];

  const handleMouseEnter = (dataInfoKey: string) => {
    if (onDataMouseEnter) {
      onDataMouseEnter(dataInfoKey);
    }

    setHoveringDataInfoKey(dataInfoKey);
  };

  const handleMouseLeave = () => {
    if (onDataMouseLeave) {
      onDataMouseLeave();
    }

    setHoveringDataInfoKey(undefined);
  };

  const handleMouseClick = (dataInfoKey: string) => {
    if (onDataMouseClick) {
      onDataMouseClick(dataInfoKey);
    }
  };

  return (
    <div className={classes.root}>
      {fieldSets.map((fieldSet) => (
        <React.Fragment key={fieldSet.key}>
          <div
            className={
              classNames(
                classes.dividerMarginBox,
              )
            }
          />
          <Divider className={classes.divider} />
          <div
            className={
              classNames(
                classes.dividerMarginBox,
                'opaque',
              )
            }
          />
          <Box
            className={
              classNames(
                classes.reportGroupRow,
                fieldSet.colorClass,
                commonCss.reports.row,
                currentDataInfoKey === fieldSet.parentDataInfoKey && 'active',
                selectedDataInfoKeys?.includes(fieldSet.parentDataInfoKey) && 'active',
              )
            }
          >
            {fieldSet.fields.map((field) => (
              <Box
                key={field.title}
                onClick={() => handleMouseClick(field.dataInfoKey)}
                onMouseEnter={() => handleMouseEnter(field.dataInfoKey)}
                onMouseLeave={() => handleMouseLeave()}
                className={
                  classNames(
                    classes.reportRow,
                    commonCss.reports.row,
                    commonCss.reports.rowHover,
                    fieldSet.colorClass,
                    selectedDataInfoKeys?.includes(field.dataInfoKey) && 'active',
                    classes.rowTitle,
                  )
                }
              >
                <Grid
                  classes={{
                    root: classNames(
                      classes.rowElement,
                      commonCss.reports.rowElement,
                      fieldSet.colorClass,
                      'contrast',
                    ),
                  }}
                  container
                  columnSpacing={{ xl: INTERNAL_SPACING / 8, xs: 5 }}
                >
                  <Grid item container xs={12} md={6}>
                    <Grid container>
                      <Grid item container xs={3} xl={4} alignItems="center">
                        <Typography className={classes.profileTitle} variant="body2" color="inherit">
                          {field.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={9} xl={8}>
                        <Box
                          display="flex"
                          className={classes.profileItem}
                          justifyContent="flex-start"
                        >
                          {field.render()}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={6} flexDirection="column" justifyContent="center">
                    <Box>
                      {field.renderChart(
                        field.dataInfoKey === hoveringDataInfoKey
                        || currentDataInfoKey === fieldSet.parentDataInfoKey
                        || selectedDataInfoKeys?.includes(field.dataInfoKey),
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        </React.Fragment>
      ))}
    </div>
  );
};

export default CompanyScoreReport;
