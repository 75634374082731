import React from 'react';

import { makeStyles } from '@mui/styles';
import {
  Box,
  Grid,
  Link,
  Typography,
  useTheme,
  Container,
} from '@mui/material';

import {
  Language as WebIcon,
  LinkedIn as LinkedInIcon,
} from '@mui/icons-material';

import classNames from 'classnames';

import { ReactComponent as CrunchbaseIcon } from '../../../../assets/svg/crunchbase.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/svg/searchIcon.svg';
import { ReactComponent as StartupIcon } from '../../../../assets/svg/startupIcon.svg';
import { ReactComponent as MarketSearchIcon } from '../../../../assets/svg/searchMarketIcon.svg';
import { ReactComponent as GlossarySearchIcon } from '../../../../assets/svg/searchGlossaryIcon.svg';
import { ReactComponent as HistoryIcon } from '../../../../assets/svg/historyIcon.svg';

import styles from './HelpPanel.styles';
import { PropsFromRedux } from './container';

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);

const HelpPanel: React.FC<PropsFromRedux> = ({
  setSearchKeyword,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const industryExamples = [
    'How many law firms are in the US?',
    'What is the market size of the global accounting services industry?',
    'How many people are employed in the LED manufacturing industry in the US?',
    'What are the provinces with the most number of car rental services in France?',
    'What are the positive and negative factors affecting the music industry in the US?',
  ];

  const dictExamples = [
    '"Digital imaging or an image rendering technique"',
    '"Cryptocurrency projects based on DAG technology"',
    '"A medical condition damaging the lungs and making breathing difficult"',
  ];

  const abbrExamples = [
    '"CRM"',
    '"Extendable Bond"',
    '"FastIP"',
    '"P2P"',
    '"Multiple Sclerosis"',
  ];

  return (
    <Box className={classes.root}>
      <Typography
        variant="h4"
        fontWeight={500}
        color="inherit"
        className={classes.helpHeader}
      >
        What can you search for?
      </Typography>
      <Box className={classes.helpPanelContainer}>
        <Grid container columnSpacing={2}>
          <Grid item xs={4}>
            <Box
              className={
                classNames(
                  classes.helpPanel,
                  'secondary',
                )
              }
            >
              <Box
                className={
                  classNames(
                    classes.helpPanelTitle,
                    'secondary',
                  )
                }
              >
                <StartupIcon />
                <Typography variant="body2" marginLeft={2}>
                  StartUps
                </Typography>
              </Box>
              <Typography
                className={classes.helpPanelRole}
                variant="body1"
                textAlign="center"
              >
                Default
              </Typography>
              <Box marginTop={3} display="flex">
                <Box flexBasis={theme.spacing(4)} display="flex" alignItems="center">
                  <SearchIcon />
                </Box>
                <Box flex={1} display="flex" alignItems="center">
                  <Typography variant="body2" color="inherit" fontWeight="normal" fontSize={15}>
                    Name, Description or Keywords
                  </Typography>
                </Box>
              </Box>
              <Box marginTop={3} display="flex">
                <Box flexBasis={theme.spacing(4)} display="flex" alignItems="flex-start">
                  <WebIcon />
                </Box>
                <Box flex={1} display="flex" flexDirection="column">
                  <Typography variant="body2" color="inherit" fontWeight="normal" fontSize={15}>
                    Website URL
                  </Typography>
                  <Typography variant="body2" color="inherit" fontSize={15}>
                    (yourstartup.com)
                  </Typography>
                </Box>
              </Box>
              <Box marginTop={3} display="flex">
                <Box flexBasis={theme.spacing(4)} display="flex" alignItems="flex-start">
                  <LinkedInIcon htmlColor="#0e76a8" />
                </Box>
                <Box flex={1} display="flex" flexDirection="column">
                  <Typography variant="body2" color="inherit" fontWeight="normal" fontSize={15}>
                    LinkedIn URL
                  </Typography>
                  <Typography variant="body2" color="inherit" fontSize={15}>
                    (linkedin.com/company/name/)
                  </Typography>
                </Box>
              </Box>
              <Box marginTop={3} display="flex">
                <Box flexBasis={theme.spacing(4)} display="flex" alignItems="flex-start" paddingLeft="2px">
                  <CrunchbaseIcon />
                </Box>
                <Box flex={1} display="flex" flexDirection="column">
                  <Typography variant="body2" color="inherit" fontWeight="normal" fontSize={15}>
                    Crunchbase URL
                  </Typography>
                  <Typography variant="body2" color="inherit" fontSize={15}>
                    (crunchbase.com/organization/name/)
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              className={
                classNames(
                  classes.helpPanel,
                  'primary',
                )
              }
            >
              <Box
                className={
                  classNames(
                    classes.helpPanelTitle,
                    'primary',
                  )
                }
              >
                <MarketSearchIcon />
                <Typography variant="body2" marginLeft={2}>
                  Industry Statistics
                </Typography>
              </Box>
              <Typography
                className={classes.helpPanelRole}
                variant="body1"
                textAlign="center"
              >
                End with a question mark (?)
              </Typography>
              {industryExamples.map((text, index) => (
                <Box key={text} marginTop={index === 0 ? 3 : 2} display="flex">
                  <Typography
                    variant="body2"
                    color="inherit"
                    fontWeight="normal"
                    fontSize={15}
                    className={classes.industryExample}
                    onClick={() => setSearchKeyword({ keyword: text })}
                  >
                    {text}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              className={
                classNames(
                  classes.helpPanel,
                  'quaternary',
                )
              }
            >
              <Box
                className={
                  classNames(
                    classes.helpPanelTitle,
                    'quaternary',
                  )
                }
              >
                <GlossarySearchIcon />
                <Typography variant="body2" marginLeft={2}>
                  Glossary
                </Typography>
              </Box>
              <Typography
                className={classes.helpPanelRole}
                variant="body1"
                textAlign="center"
              >
                Enclose with quotation marks (“x”)
              </Typography>
              <Box marginTop={3} display="flex">
                <Typography
                  variant="body2"
                  color="inherit"
                  fontWeight="normal"
                  fontSize={15}
                  className={classes.glossaryExampleHeader}
                >
                  By term or abbreviation
                </Typography>
              </Box>
              <Container className={classes.glossaryExampleContainer}>
              {abbrExamples.map((text, index, arr) => (
                <Box key={text} marginTop={1} display="flex">
                  <span
                    color="inherit"
                    className={classes.glossaryExample}
                    onClick={() => setSearchKeyword({ keyword: text })}
                  >
                    {text} {index !== (arr.length - 1) ? ', ' : ''}
                  </span>
                </Box>
              ))}
              </Container>
              <Box marginTop={3} display="flex">
                <Typography
                  variant="body2"
                  color="inherit"
                  fontSize={15}
                  className={classes.glossaryExampleHeader}
                >
                  By definition
                </Typography>
              </Box>
              {dictExamples.map((text) => (
                <Box key={text} marginTop={1} display="flex">
                  <Typography
                    variant="body2"
                    color="inherit"
                    fontWeight="normal"
                    fontSize={15}
                    className={classes.glossaryExample}
                    onClick={() => setSearchKeyword({ keyword: text })}
                  >
                    {text}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={3} display="flex" alignItems="center">
        <Box marginRight={1} display="flex" alignItems="center">
          <HistoryIcon />
        </Box>
        <Link
          type="button"
          className={classes.otherItemLink}
          onClick={() => setSearchKeyword({ keyword: ':history' })}
        >
          <Typography variant="body1" fontSize={15} fontWeight="normal">
            :history
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default HelpPanel;
