import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../../theme/commonStyles';
import {
  otherColorSets,
  otherContrastColorSets,
  otherLightColorSets,
  sourceRefLightColor,
} from '../../../../theme/palette';
import plusIcon from '../../../../assets/svg/plusIcon.svg';
import closeIcon from '../../../../assets/svg/closeIcon.svg';
import moveIcon from '../../../../assets/svg/moveIcon.svg';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    taskList: {
      listStyle: 'none',
      paddingInlineStart: 0,
      marginLeft: theme.spacing(5),
    },
    taskItem: {
      position: 'relative',
      fontSize: '1.0714rem',
      fontWeight: 'normal',
      '&:before': {
        content: 'counter(list-item)',
        display: 'inline-block',
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
        color: theme.palette.grey[100],
        background: theme.palette.background.paper,
        border: `1px solid ${theme.palette.grey[100]}`,
        fontSize: '0.928rem',
        borderRadius: '50%',
        textAlign: 'center',
        left: 0,
        zIndex: 1,
        position: 'relative',
      },
      '&:after': {
        content: '""',
        display: 'inline-block',
        width: 1,
        height: `calc(100% - ${theme.spacing(3.5)})`,
        background: otherColorSets[2],
        position: 'absolute',
        top: theme.spacing(3),
        left: theme.spacing(1.25),
      },
      '&.new:before': {
        content: `url("${plusIcon}")`,
      },
      '&:not(.new):hover:before': {
        content: `url("${closeIcon}")`,
        cursor: 'pointer',
      },
      '& > span': {
        display: 'inline-block',
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        width: `calc(100% - ${theme.spacing(3)})`,
      },
      '&:not(.new):hover > span': {
        display: 'inline-block',
        '&:before': {
          position: 'absolute',
          content: `url("${moveIcon}")`,
          left: theme.spacing(-2),
        },
        '&:after': {
          position: 'absolute',
          content: '""',
          left: 0,
          top: 20,
          height: `calc(100% - ${theme.spacing(3)})`,
          width: 20,
        },
      },
    },
    taskInput: {
      marginTop: theme.spacing(-0.5),
      fontWeight: '400 !important',
      fontSize: '1.0714rem !important',
      color: `${theme.palette.grey[900]} !important`,
      '&:before': {
        borderBottom: 'none !important',
      },
      '&:after': {
        borderBottom: 'none !important',
      },
    },
    executeBox: {
      marginTop: theme.spacing(2),
      marginLeft: `${theme.spacing(5)} !important`,
    },
    executeButton: {
      width: 136,
      color: `${theme.palette.grey[900]} !important`,
      border: `1px solid ${otherContrastColorSets[1]} !important`,
      backgroundColor: `${sourceRefLightColor} !important`,
      '&:hover, &:focus': {
        color: `${theme.palette.common.white} !important`,
        backgroundColor: `${otherColorSets[2]} !important`,
      },
    },
    saveTemplateButton: {
      width: 136,
      color: `${theme.palette.grey[900]} !important`,
    },
    executeEstimate: {
      color: `${theme.palette.grey[200]} !important`,
    },
    templateHeader: {
      fontWeight: 'normal !important',
      color: `${otherContrastColorSets[2]} !important`,
      display: 'flex',
      alignItems: 'center',
    },
    templateItem: {
      transition: 'all 0.3s ease-out',
      '&.tertiary.MuiButton-outlined.MuiButtonBase-root': {
        fontWeight: '300 !important',
        color: `${theme.palette.grey[900]} !important`,
      },
      '& .templateItemButtons': {
        transition: 'all 0.3s ease-out',
        transform: 'scale(0)',
        width: 0,
        height: 0,
        opacity: 0,
      },
      '&:hover .templateItemButtons': {
        transform: 'scale(1)',
        width: 50,
        height: 20,
        opacity: 1,
        backgroundColor: otherLightColorSets[1],
      },
    },
    templateItemButtonBox: {
      position: 'relative',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(-0.5),
      backgroundColor: theme.palette.common.white,
      borderRadius: '50%',
      border: `1px solid ${theme.palette.grey[300]}`,
      boxSizing: 'border-box',
      width: 20,
      height: 20,
    },
    templateItemButton: {
      padding: `${theme.spacing(0)} !important`,
      '& .MuiSvgIcon-root': {
        fontSize: '12px !important',
        lineHeight: 1,
        marginTop: theme.spacing(-0.75),
        color: otherColorSets[2],
      },
    },
    templateItemLabel: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      position: 'relative',
      '&:after': {
        display: 'block',
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        width: 40,
        content: '""',
        opacity: 0,
      },
    },
  });
};

export default styles;
