import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useParams,
  useNavigate,
  matchRoutes,
  useLocation,
} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import useMeasure from 'react-use-measure';
import { useResizeDetector } from 'react-resize-detector';

import {
  CompanyInfoPanel,
  DataInfoPanel,
  NavMenu,
  PageLoadProgress,
} from '../../../components';

import { ReactComponent as OnePagerIcon } from '../../../assets/svg/onePagerIcon.svg';
import { ReactComponent as ProfileScoreIcon } from '../../../assets/svg/profileScoreIcon.svg';
import { ReactComponent as SuccessScoreIcon } from '../../../assets/svg/successScoreIcon.svg';
import { ReactComponent as MarketAnalysisIcon } from '../../../assets/svg/marketAnalysisIcon.svg';
import { ReactComponent as CompetitorIcon } from '../../../assets/svg/competitorIcon.svg';
import { ReactComponent as LanscapeIcon } from '../../../assets/svg/landscapeIcon.svg';
import { ReactComponent as TechnologiesIcon } from '../../../assets/svg/technologiesIcon.svg';
import { ReactComponent as WebTrafficIcon } from '../../../assets/svg/webTrafficIcon.svg';
import { ReactComponent as PoweredByLogoIcon } from '../../../assets/svg/poweredByLogo.svg';
import { ReactComponent as CameraIcon } from '../../../assets/svg/cameraIcon.svg';

import { NavMenuItem } from '../../../components/navMenu/types';
import { useCommonClasses } from '../../../theme/commonStyles';

import reportRoutes from './reportRoutes';
import { PropsFromRedux } from './container';
import { CompanyData, CompetitorCompanyInfo, DistributionModelKey } from './types';
import ScoreReportDetails from './scoreReportDetails';
import ScoreReportChart from './scoreReportChart';
import MarketsSelectorPanel from './marketsSelectorPanel';
import MarketReportDetails from './marketReportDetails';
import styles, { INNER_SPACING } from './Reports.styles';
import RankingReportDetails from './rankingReportDetails';
import SelectedCompetitiorsPanel from './selectedCompetitorsPanel';
import LandscapeReportDetails from './landscapeReportDetails';
import { getCommonReportParams } from './reportUtils';
import { isNullOrUndefined } from '../../../core/utils/dataUtils';
import OnePagerDetailsPanel from './onePagerDetailsPanel';
import RelationChart from '../relationChart';

const useStyles = makeStyles(styles);

interface Props extends PropsFromRedux {}

const Reports: React.FC<Props> = ({
  initializeReport,
  companyReport,
  investorReport,
  competitorsReport,
  distributionsInfo,
  highlightingCompetitor,
  networkStates: {
    companyDataRequest,
  },
  openMessageBox,
  userExtraInfo,
  openedReports,
  openCompanyReport,
  requestRelationData,
}) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const navigate = useNavigate();
  const [companyBoxRef, companyBoxBounds] = useMeasure();
  const [dataInfoRef, dataInfoBounds] = useMeasure();
  const { height: gridHeight, ref: gridRef } = useResizeDetector();

  const [
    currentDistributionModel,
    setCurrentDistributionModel,
  ] = useState<DistributionModelKey>();
  const [
    currentDataInfoKey,
    setCurrentDataInfoKey,
  ] = useState<string>();
  const [
    currentDataInfo,
    setCurrentDataInfo,
  ] = useState<string>();

  const { id } = useParams();
  const location = useLocation();

  const routes = [{ path: '/reports/:id/:section' }];
  const matches = matchRoutes(routes, location);
  const [{ params }] = matches || [{ params: undefined }];
  const section = params?.section;

  const isPageLoading = !companyReport && companyDataRequest.isRequesting;
  const isScoreReport = (section === 'profileScore' || section === 'successScore');
  const isOnePagerReport = (section === 'onePager');
  const isMarketingReport = (section === 'market');
  const isRankingReport = (section === 'ranking');
  const isLandscapeReport = (section === 'landscape');

  const search = new URLSearchParams(window.location.search);
  const score = search.get('score');
  const manualForm = search.get('m');

  const commonReportParams = getCommonReportParams(companyReport);
  const scoreParams = new URLSearchParams();

  if (score) {
    scoreParams.set('score', 'true');
  }

  if (manualForm) {
    scoreParams.set('m', 'true');
  }

  const scoreQuery = scoreParams.toString();

  const onePagerReportUrl = `${reportRoutes.onePager}${commonReportParams ? `?${commonReportParams}` : ''}`;
  const marketReportUrl = `${reportRoutes.market}${commonReportParams ? `?${commonReportParams}` : ''}`;
  const rankingReportUrl = `${reportRoutes.ranking}${commonReportParams ? `?${commonReportParams}` : ''}`;
  const landscapeReportUrl = `${reportRoutes.landscape}${commonReportParams ? `?${commonReportParams}` : ''}`;
  const webTrafficReportUrl = `${reportRoutes.webTraffic}${commonReportParams ? `?${commonReportParams}` : ''}`;
  const technologiesReportUrl = `${reportRoutes.technologies}${commonReportParams ? `?${commonReportParams}` : ''}`;

  const menus: NavMenuItem[] = [{
    name: 'One Pager',
    icon: OnePagerIcon,
    routePath: `${onePagerReportUrl}`,
    iconHighlightClassName: 'onePager',
  }, {
    name: 'Profile Score',
    icon: ProfileScoreIcon,
    routePath: `${reportRoutes.profileScore}${scoreQuery ? `?${scoreQuery}` : ''}`,
    iconHighlightClassName: 'secondary',
    diabled: score !== 'true',
  }, {
    name: 'Success Score',
    icon: SuccessScoreIcon,
    routePath: `${reportRoutes.successScore}${scoreQuery ? `?${scoreQuery}` : ''}`,
    iconHighlightClassName: 'investing',
    diabled: score !== 'true',
  }, {
    name: 'Market Analysis',
    icon: MarketAnalysisIcon,
    routePath: `${marketReportUrl}`,
    iconHighlightClassName: 'market',
  }, {
    name: 'Competitor Ranking',
    icon: CompetitorIcon,
    routePath: `${rankingReportUrl}`,
    iconHighlightClassName: 'ranking',
  }, {
    name: 'Competitive Landscape',
    icon: LanscapeIcon,
    routePath: `${landscapeReportUrl}`,
    iconHighlightClassName: 'landscape',
  }, {
    name: 'Web Traffic Analysis',
    icon: TechnologiesIcon,
    routePath: `${webTrafficReportUrl}`,
    diabled: true,
  }, {
    name: 'Technologies',
    icon: WebTrafficIcon,
    routePath: `${technologiesReportUrl}`,
    diabled: true,
  }];

  const getScoreReportChartHeight = () => (
    companyBoxBounds && dataInfoBounds && gridHeight
      ? (
        gridHeight
          - companyBoxBounds.height
          - dataInfoBounds.height
      )
      : 0
  );

  const openReport = (companyItem: CompetitorCompanyInfo, report?: string) => {
    const companyData = {
      orgLogo: companyItem.logo,
      orgName: companyItem.name,
      orgKeywords: (companyItem.keywords || '').split(','),
      orgCountry: companyItem.countryIso,
      orgDesc: companyItem.description,
      orgFoundingYear: companyItem.foundingYear,
      orgIndustry: companyItem.industry,
      orgVertical: companyItem.vertical,
      orgState: companyItem.city,
    } as CompanyData;

    openCompanyReport({
      companyData,
      companyId: String(companyItem.id),
      hasScore: !isNullOrUndefined(companyItem.score),
      report,
    });
  };

  const validateAndOpenReport = (companyItem: CompetitorCompanyInfo, report?: string) => {
    const creditLeft = (
      (userExtraInfo?.results.at(0)?.credits || 0)
      - (userExtraInfo?.results.at(0)?.creditsUsed || 0)
    );

    const isOpenedReport = companyItem.historyReport || !!openedReports[String(companyItem.id)];

    if (!isOpenedReport && creditLeft <= 0) {
      openMessageBox({
        content: 'Insufficient credits, please purchase a credit package to open the report.',
        dismissText: 'OK',
        colorSet: 'primary',
      });

      return;
    }

    if (!isOpenedReport) {
      const creditToBeDeducted = 1;

      openMessageBox({
        content: (
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="body1" className={classes.creditDialogHeader}>
              Credits left: {creditLeft}
            </Typography>
            <Typography variant="body1" className={classes.creditDialogContent}>
              Opening this report will deduct<br/>
              <span className="credit">{creditToBeDeducted}</span>
              {' credit. Please confirm.'}
            </Typography>
          </Box>
        ),
        dismissText: 'Cancel',
        colorSet: 'landscape',
        commands: [{
          title: 'OK',
          action: () => {
            openReport(companyItem, report);
          },
        }],
      });

      return;
    }

    openReport(companyItem, report);
  };

  const handleCompetitorInfoOpen = (competitorData: CompetitorCompanyInfo) => {
    validateAndOpenReport(competitorData);
  };

  useEffect(() => {
    if (!section) {
      if (score === 'true') {
        navigate(`.${reportRoutes.onePager}${scoreQuery
          ? `?${scoreQuery}`
          : ''}${commonReportParams
          ? `?${commonReportParams}`
          : ''}`, { replace: true });
        return;
      }

      navigate(`.${reportRoutes.onePager}${commonReportParams ? `?${commonReportParams}` : ''}`, { replace: true });
    }
  }, [section]);

  useEffect(() => {
    if (!id) {
      return;
    }

    initializeReport({ companyId: id });
    requestRelationData({ companyId: id });
  }, []);

  useEffect(() => {
    const distModel = section === 'profileScore'
      ? 'Model-C1'
      : 'Model-C4';

    if (distributionsInfo && currentDistributionModel !== distModel) {
      setCurrentDistributionModel(distModel);
    }
  }, [distributionsInfo, section]);

  useEffect(() => {
    if (currentDataInfoKey) {
      const companyReportTooltips = companyReport?.tooltips || {} as Record<string, string>;
      if (companyReportTooltips[currentDataInfoKey]) {
        setCurrentDataInfo(companyReportTooltips[currentDataInfoKey]);
      }

      const investorReportTooltips = investorReport?.tooltips || {} as Record<string, string>;
      if (investorReportTooltips[currentDataInfoKey]) {
        setCurrentDataInfo(investorReportTooltips[currentDataInfoKey]);
      }
    } else {
      setCurrentDataInfo(undefined);
    }
  }, [currentDataInfoKey]);

  return (
    <div className={classes.root}>
      <NavMenu
        menus={menus}
        currentPath={section && `/${section}`}
        onHomeClick={() => navigate('/')}
        onMenuClick={(menu) => navigate(`.${menu.routePath}`)}
      />
      {companyReport && (
        <Grid container columnSpacing={{ xl: INNER_SPACING / 8, xs: 3 }}>
          <Grid item xs={12} md={4} ref={gridRef}>
            <Box
              display="flex"
              flexDirection="column"
              ref={companyBoxRef}
            >
              <Paper
                className={classes.companyInfoPanel}
                classes={commonCss.panels.bottomRightRoundPanel}
                elevation={0}
                onMouseEnter={() => setCurrentDataInfoKey('c1Description')}
                onMouseLeave={() => setCurrentDataInfoKey(undefined)}
              >
                <CompanyInfoPanel
                  companyData={companyReport?.companyData}
                  showFieldDetails={!isScoreReport}
                  competitorData={
                    (
                      (isRankingReport || isLandscapeReport)
                      && (competitorsReport?.target?.length || 0) > 0
                    )
                      ? competitorsReport?.target[0]
                      : undefined
                  }
                  highlightedDescriptionKeywords={(
                    (isRankingReport || isLandscapeReport)
                      ? highlightingCompetitor?.commonDescKeywords
                      : undefined
                  )}
                  highlightedKeywords={(
                    (isRankingReport || isLandscapeReport)
                      ? highlightingCompetitor?.commonKeywords
                      : undefined
                  )}
                />
              </Paper>
            </Box>
            {isScoreReport && (
              <>
                {companyBoxBounds && dataInfoBounds && gridHeight && (
                  <ScoreReportChart
                    setCurrentDataInfoKey={setCurrentDataInfoKey}
                    currentDistributionModel={currentDistributionModel}
                    height={getScoreReportChartHeight()}
                    section={section}
                  />
                )}
              </>
            )}
            {isMarketingReport && (
              <>
                {companyBoxBounds && dataInfoBounds && gridHeight && (
                  <MarketsSelectorPanel
                    height={getScoreReportChartHeight()}
                  />
                )}
              </>
            )}
            {(isRankingReport || isLandscapeReport) && (
              <>
                <Paper
                  className={classes.competitorInfoPanel}
                  classes={commonCss.panels.leafPanel}
                  elevation={0}
                  style={{
                    opacity: highlightingCompetitor?.competitor ? 1 : 0,
                    height: 365,
                  }}
                >
                  {highlightingCompetitor?.competitor && (
                    <CompanyInfoPanel
                      showFieldDetails={!isScoreReport}
                      competitorData={highlightingCompetitor?.competitor}
                      highlightedDescriptionKeywords={(
                        (isRankingReport || isLandscapeReport)
                          ? highlightingCompetitor?.commonDescKeywords
                          : undefined
                      )}
                      highlightedKeywords={(
                        (isRankingReport || isLandscapeReport)
                          ? highlightingCompetitor?.commonKeywords
                          : undefined
                      )}
                      onCompetitorInfoOpen={handleCompetitorInfoOpen}
                    />
                  )}
                </Paper>
                {isLandscapeReport && (
                  <Paper
                    className={
                      classNames(
                        classes.selectedCompetitorsPanel,
                      )
                    }
                    classes={commonCss.panels.topRightRoundPanel}
                    elevation={0}
                  >
                    <SelectedCompetitiorsPanel
                      allowDelete={isLandscapeReport}
                    />
                  </Paper>
                )}
              </>
            )}
            {isOnePagerReport && (
              <Box marginTop={2}>
                <OnePagerDetailsPanel
                  onCompetitorInfoOpen={handleCompetitorInfoOpen}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <div className={classes.reportDetails}>
              <div className={commonCss.decorates.reportHeader}>
                <Grid container columnSpacing={3}>
                  <Grid item xs={6} />
                  <Grid item container xs={6}>
                    <Grid item container xs={2} />
                    <Grid item container xs={6} alignItems="center" justifyContent="center">
                      <Routes>
                        <Route
                          path={reportRoutes.onePager}
                          element={
                            <>
                              {score ? (
                                <Button
                                  variant="contained"
                                  size="large"
                                  classes={commonCss.buttons.generalButton}
                                  className="secondary"
                                  onClick={() => {
                                    navigate(`.${reportRoutes.profileScore}${scoreQuery ? `?${scoreQuery}` : ''}`);
                                  }}
                                >
                                  Profile Score
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  size="large"
                                  classes={commonCss.buttons.generalButton}
                                  className={classNames(classes.marketAnalysisButton, 'market')}
                                  onClick={() => {
                                    navigate(`.${marketReportUrl}`);
                                  }}
                                >
                                  Market Analysis
                                </Button>
                              )}
                            </>
                          }
                        />
                        <Route
                          path={reportRoutes.profileScore}
                          element={
                            <Button
                              variant="contained"
                              size="large"
                              classes={commonCss.buttons.generalButton}
                              className="investing"
                              onClick={() => {
                                navigate(`.${reportRoutes.successScore}${scoreQuery ? `?${scoreQuery}` : ''}`);
                              }}
                            >
                              Start Investing
                            </Button>
                          }
                        />
                        <Route
                          path={reportRoutes.successScore}
                          element={
                            <Button
                              variant="contained"
                              size="large"
                              classes={commonCss.buttons.generalButton}
                              className={classNames(classes.marketAnalysisButton, 'market')}
                              onClick={() => {
                                navigate(`.${marketReportUrl}`);
                              }}
                            >
                              Market Analysis
                            </Button>
                          }
                        />
                        <Route
                          path={reportRoutes.market}
                          element={
                            <Button
                              variant="contained"
                              size="large"
                              classes={commonCss.buttons.generalButton}
                              className={classNames(classes.marketAnalysisButton, 'ranking', 'flip')}
                              onClick={() => {
                                navigate(`.${rankingReportUrl}`);
                              }}
                            >
                              Competitor Ranking
                            </Button>
                          }
                        />
                      </Routes>
                    </Grid>
                    <Grid item container xs={4} alignItems="center" justifyContent="center">
                      <IconButton className={classes.cameraButton} disabled>
                        <CameraIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              {isOnePagerReport && (
                <Box className={classes.networkChartContainer}>
                  <RelationChart
                    companyRelationChart
                    relatedCompanies={competitorsReport?.top5}
                    headerSize="medium"
                  />
                </Box>
              )}
              {isScoreReport && (
                <ScoreReportDetails
                  currentDataInfoKey={currentDataInfoKey}
                  setCurrentDataInfoKey={setCurrentDataInfoKey}
                />
              )}
              {isMarketingReport && (
                <MarketReportDetails />
              )}
              {isRankingReport && (
                <RankingReportDetails />
              )}
              {isLandscapeReport && (
                <LandscapeReportDetails />
              )}
            </div>
          </Grid>
        </Grid>
      )}
      {isScoreReport && !isPageLoading && (
        <div
          ref={dataInfoRef}
          className={
            classNames(
              classes.dataInfoContainer,
            )
          }
        >
          <Paper
            className={classes.dataInfoPanelLeft
            }
            classes={commonCss.panels.topRightRoundPanel}
            elevation={0}
          >
            <DataInfoPanel
              info={currentDataInfo}
            />
          </Paper>
        </div>
      )}
      <div className={classes.footerLogo}>
        <PoweredByLogoIcon />
      </div>
      {isPageLoading && (
        <PageLoadProgress />
      )}
    </div>
  );
};

export default Reports;
