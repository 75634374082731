import React, { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import {
  Autocomplete,
  Box,
  Button,
  capitalize,
  CircularProgress,
  Grid,
  IconButton,
  Input,
  Modal,
  Tooltip,
} from '@mui/material';

import {
  Close as CloseIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';

import { ReactComponent as DuplicateChart } from '../../assets/svg/duplicateChart.svg';
import { ReactComponent as OpenFullScreen } from '../../assets/svg/openFullScreen.svg';
import { ReactComponent as PromptInputIcon } from '../../assets/svg/promptInputIcon.svg';
import { ReactComponent as GptBarChartIcon } from '../../assets/svg/gptBarChartIcon.svg';
import { ReactComponent as GptHorizontalBarChartIcon } from '../../assets/svg/gptHorizontalBarChartIcon.svg';
import { ReactComponent as GptRadarChartIcon } from '../../assets/svg/gptRadarChartIcon.svg';
import { ReactComponent as GptLineChartIcon } from '../../assets/svg/gptLineChartIcon.svg';
import { ReactComponent as GptStepChartIcon } from '../../assets/svg/gptStepChartIcon.svg';
import { ReactComponent as GptPieChartIcon } from '../../assets/svg/gptPieChartIcon.svg';
import { ReactComponent as GptDonutChartIcon } from '../../assets/svg/gptDonutChartIcon.svg';
import { ReactComponent as GptScatterChartIcon } from '../../assets/svg/gptScatterChartIcon.svg';
import { ReactComponent as GptPorarAreaChartIcon } from '../../assets/svg/gptPorarAreaChartIcon.svg';

import { QuickChartColAllocation, QuickChartInfo } from '../../core/types/quickChart';
import styles from './QuickChart.styles';
import { useCommonClasses } from '../../theme/commonStyles';
import useCommonProps from '../../theme/commonProps';

type ChartOption = {
  key: string;
  icon: JSX.Element;
};

type Props = {
  chartInfo: QuickChartInfo;
  chartWidth?: number;
  hidden?: boolean;
  colorSet?: string;
  onCommand?: (command: string, args?: unknown) => void;
  isUpdating?: boolean;
  isUndoable?: boolean;
};

type ColAllocationKey = keyof QuickChartColAllocation;

const useStyles = makeStyles(styles);

const QuickChart: React.FC<Props> = React.memo(({
  chartInfo,
  hidden,
  colorSet = 'primary',
  onCommand,
  isUpdating,
  isUndoable,
  chartWidth,
}) => {
  const [isFullScreen, setFullScreen] = useState(false);
  const [innerChartInfo, setInnerChartInfo] = useState(chartInfo);
  const [promptText, setPromptText] = useState('');
  const [isHover, setHover] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const classes = useStyles();
  const commonCss = useCommonClasses();
  const commonProps = useCommonProps();

  const colAllocProps = [
    'textCols',
    'numericCols',
    'categorialCols',
  ].filter((key) => (chartInfo.colAllocation?.[key as ColAllocationKey] || []).length > 0);

  const hasCategory = (
    colAllocProps.find((prop) => prop === 'categorialCols')
    && !!chartInfo.graphInstance?.category
  );

  const colSelectProps = [
    'selectedTextCol',
    'selectedNumericCol',
    'selectedCategorialCol',
  ];

  const colPropsMap = {
    selectedTextCol: 'xValue',
    selectedNumericCol: 'yValue',
    selectedCategorialCol: 'category',
  } as Record<string, string>;

  const chartOptions = [{
    key: 'bar',
    icon: <GptBarChartIcon />,
  }, {
    key: 'horizontalBar',
    icon: <GptHorizontalBarChartIcon />,
  }, {
    key: 'line',
    icon: <GptLineChartIcon />,
  }, {
    key: 'radar',
    icon: <GptRadarChartIcon />,
  }, hasCategory && {
    key: 'pie',
    icon: <GptPieChartIcon />,
  }, {
    key: 'doughnut',
    icon: <GptDonutChartIcon />,
  }, {
    key: 'polarArea',
    icon: <GptPorarAreaChartIcon />,
  }, {
    key: 'steps',
    icon: <GptStepChartIcon />,
  }, {
    key: 'scatter',
    icon: <GptScatterChartIcon />,
  }].filter((c): c is ChartOption => !!c);

  const getSelectedCol = (index: number) => {
    const colsProp = colAllocProps[index];
    const cols = chartInfo?.colAllocation?.[colsProp as ColAllocationKey] as string[];

    const selectedColProp = colSelectProps[index];
    const targetColProp = colPropsMap[selectedColProp];

    const selectedValue = (
      chartInfo.graphInstance as unknown as Record<string, string>
    )[targetColProp];

    const finalSelectedValue = selectedValue === '' ? 'None' : selectedValue;
    return finalSelectedValue ?? cols?.at(0);
  };

  const isOptionAlreadySelected = (option: string, index: number) => {
    const optionSelectedOnOtherCols = colAllocProps
      .filter((_, propIndex) => index !== propIndex)
      .map((prop) => {
        const colProp = `selected${capitalize(prop.substring(0, prop.length - 1))}`;
        const targetColProp = colPropsMap[colProp];
        return (innerChartInfo.graphInstance as unknown as Record<string, string>)[targetColProp];
      });

    return optionSelectedOnOtherCols.includes(option);
  };

  const updateChartColAlloc = (index: number, value: string) => {
    const colSelectProp = colSelectProps[index];
    const updatedInfo = {
      ...innerChartInfo,
      [colSelectProp]: value,
    };

    setInnerChartInfo(updatedInfo);

    if (onCommand) {
      onCommand('update', {
        ...updatedInfo,
        graphType: undefined,
        category: undefined,
        xValue: undefined,
        yValue: undefined,
        text: undefined,
        [colSelectProp]: value,
      });
    }
  };

  const updateChartType = (chartType: string) => {
    const updatedInfo = {
      ...innerChartInfo,
      graphType: chartType,
    };

    setInnerChartInfo(updatedInfo);

    if (onCommand) {
      onCommand('update', {
        ...updatedInfo,
        graphType: updatedInfo.graphType || 'bar',
        text: undefined,
      });
    }
  };

  const updateChartByPrompt = (prompt: string) => {
    const updatedInfo = {
      ...innerChartInfo,
    };

    setInnerChartInfo(updatedInfo);

    if (onCommand) {
      onCommand('update', {
        ...updatedInfo,
        text: prompt,
      });
    }
  };

  const sourceUrl = hidden || !chartWidth || !chartInfo?.graphId
    ? 'about:blank'
    : `https://quickchart.io/chart-maker/view/${chartInfo.graphId}`;

  const currentGraphType = chartInfo?.graphType || 'bar';
  const finalGraphType = currentGraphType === 'pie' && !hasCategory
    ? 'doughnut'
    : currentGraphType;

  return (
    <span
      className={
        classNames(
          classes.root,
          isHover && 'hover',
        )
      }
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {chartWidth && (
        <Box
          style={{
            width: chartWidth,
            height: chartWidth
              ? (chartWidth * 0.5) + 88
              : 0,
          }}
        >
          <span
            className={
              classNames(
                classes.chartData,
                isUpdating && 'hidden',
              )
            }
          >
            <Grid component="span" container spacing={1}>
              {colAllocProps.map((prop, index) => (
                <Grid component="span" key={prop} item xs={12 / colAllocProps.length}>
                  <Autocomplete
                    className={classes.colAllocSelector}
                    {...commonProps.autocomplete({
                      color: 'tertiary',
                      size: 'small',
                    })}
                    options={index === 2
                      ? [
                        'None',
                        ...chartInfo?.colAllocation?.[prop as ColAllocationKey] || [],
                      ]
                      : chartInfo?.colAllocation?.[prop as ColAllocationKey] || []
                    }
                    getOptionLabel={(option) => option}
                    getOptionDisabled={(option) => isOptionAlreadySelected(option, index)}
                    fullWidth
                    value={getSelectedCol(index)}
                    disableClearable
                    onChange={(_, value) => {
                      updateChartColAlloc(index, value);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </span>
          <Box
            component="span"
            className={classes.chartContainer}
            marginTop={1}
          >
            {isUpdating ? (
              <span
                className={
                  classNames(
                    classes.chartFrame,
                    colorSet,
                    isHover && 'hover',
                  )
                }
                style={{
                  height: chartWidth
                    ? (chartWidth * 0.5)
                    : 0,
                }}
              >
                <CircularProgress color="inherit" size={24} />
              </span>
            ) : (
              <iframe
                ref={iframeRef}
                className={
                  classNames(
                    classes.chartFrame,
                    colorSet,
                    isHover && 'hover',
                  )
                }
                src={sourceUrl}
                width="100%"
                style={{
                  height: chartWidth * 0.5 + 2,
                }}
              />
            )}
            <span
              className={
                classNames(
                  classes.chartTools,
                  isUpdating && 'hidden',
                )
              }
            >
              <Tooltip title="Duplicate Graph" arrow>
                <Button
                  variant="outlined"
                  classes={commonCss.buttons.roundButton}
                  size="small"
                  className={
                    classNames(
                      classes.toolButton,
                      'tertiary',
                    )
                  }
                  onClick={() => onCommand && onCommand('duplicate', innerChartInfo)}
                >
                  <DuplicateChart />
                </Button>
              </Tooltip>
              <Tooltip title="Full Screen Mode" arrow>
                <Button
                  variant="outlined"
                  classes={commonCss.buttons.roundButton}
                  size="small"
                  className={
                    classNames(
                      classes.toolButton,
                      'tertiary',
                    )
                  }
                  onClick={() => setFullScreen(true)}
                >
                  <OpenFullScreen />
                </Button>
              </Tooltip>
              <Tooltip title="Undo" arrow>
                <span>
                  <Button
                    variant="outlined"
                    classes={commonCss.buttons.roundButton}
                    size="small"
                    className={
                      classNames(
                        classes.toolButton,
                        'tertiary',
                      )
                    }
                    disabled={!isUndoable}
                    onClick={() => onCommand && onCommand('undo', chartInfo)}
                  >
                    <RefreshIcon />
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Remove" arrow>
                <Button
                  variant="outlined"
                  classes={commonCss.buttons.roundButton}
                  size="small"
                  className={
                    classNames(
                      classes.toolButton,
                      'tertiary',
                    )
                  }
                  onClick={() => onCommand && onCommand('remove', innerChartInfo)}
                >
                  <CloseIcon />
                </Button>
              </Tooltip>
            </span>
          </Box>
          <span className={classes.chartModifier}>
            <Grid component="span" container>
              <Grid
                component="span"
                item
                container
                xs={6}
                alignItems="center"
                style={{ height: 30 }}
              >
                <Autocomplete
                  className={classes.promptInput}
                  classes={{
                    popper: classNames(
                      classes.promptInputPopper,
                    ),
                    paper: classes.promptInputPaper,
                  }}
                  {...commonProps.autocomplete({
                    color: 'tertiary',
                    size: 'small',
                  })}
                  options={[
                    'change the title to "My Bar Chart"',
                    'increase the title of the chart by 30%',
                    'change the colors to something funky',
                    'capitalize the x and y labels',
                    'remove the yAxis gridlines',
                  ]}
                  getOptionLabel={(option) => option}
                  fullWidth
                  value={promptText}
                  disableClearable
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && (promptText || '').trim()) {
                      updateChartByPrompt(promptText);
                      setPromptText('');
                    }
                  }}
                  onChange={(_, value) => {
                    setPromptText(value);
                  }}
                  freeSolo
                  renderInput={(props) => (
                    <Input
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props.InputProps}
                      inputProps={{
                        ...props.inputProps,
                      }}
                      className={classes.promptInputBox}
                      fullWidth
                      placeholder="What would you like to edit in this graph?"
                      onChange={(event) => {
                        setPromptText(event.currentTarget.value);
                      }}
                      endAdornment={
                        <PromptInputIcon
                          className={classes.promptInputButton}
                          onClick={() => {
                            if (!promptText) {
                              return;
                            }
                            updateChartByPrompt(promptText);
                            setPromptText('');
                          }}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                container
                xs={6}
                alignItems="center"
                justifyContent="flex-end"
                columnGap={0.5}
                paddingRight={1}
                component="span"
                style={{ height: 30 }}
              >
                {chartOptions.map((chartOption) => (
                  <Tooltip
                    key={chartOption.key}
                    title={`${chartOption.key} chart`}
                    classes={{
                      tooltip: classes.optionTooltip,
                    }}
                    arrow
                  >
                    <Button
                      variant="outlined"
                      classes={commonCss.buttons.roundButton}
                      size="small"
                      className={
                        classNames(
                          classes.chartButton,
                          'tertiary',
                          chartOption.key === finalGraphType && 'active',
                        )
                      }
                      onClick={() => {
                        updateChartType(chartOption.key);
                      }}
                    >
                      {chartOption.icon}
                    </Button>
                  </Tooltip>
                ))}
              </Grid>
            </Grid>
            {isFullScreen && (
              <Modal open={isFullScreen}>
                <div className={classes.fullScreenContainer}>
                  {isUpdating ? (
                    <span
                      className={
                        classNames(
                          classes.chartFrame,
                          colorSet,
                        )
                      }
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: 0,
                      }}
                    >
                      <CircularProgress color="inherit" size={24} />
                    </span>
                  ) : (
                    <iframe
                      src={sourceUrl}
                      width="100%"
                      height="100%"
                    />
                  )}
                  <Box component="span" position="absolute" top={1} right={1}>
                    <Tooltip arrow title="Close">
                      <IconButton onClick={() => setFullScreen(false)}>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </div>
              </Modal>
            )}
          </span>
        </Box>
      )}
    </span>
  );
});

export default QuickChart;
