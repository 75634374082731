import { createReducer } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import {
  clearAllFilters,
  closeFilterEdit,
  openFilterEdit,
  requestCompanyItems,
  requestGlossaryItems,
  requestGptSearch,
  requestHistoryItems,
  requestMarketInquiryItems,
  setCompanyItems,
  setGlossaryItems,
  setGptSearch,
  setHistoryItems,
  setMarketInquiryItems,
  setPage,
  setSearchKeyword,
  toggleGptChat,
  toggleGptSearch,
  updateCountriesFilter,
  updateIndustriesFilter,
  updateMinFoundingYearsFilter,
  updateVerticalsFilter,
} from './actions';

import { CompanyItemsGroup, SearchState } from './types';

const searchParams = new URLSearchParams(window.location.search);
const urlKeywords = searchParams.get('s');

const initialState: SearchState = {
  networkStates: {
    historyItemsRequest: { isRequesting: false },
    companySearchRequest: { isRequesting: false },
    marketInquiryRequest: { isRequesting: false },
    glossaryItemsRequest: { isRequesting: false },
    gptSearchRequest: { isRequesting: false },
  },
  filter: {
    isOpen: false,
  },
  keyword: urlKeywords || '',
  minKeyword: 2,
  isGptSearch: false,
  isGptChat: true,
};

const searchReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSearchKeyword, (state, action) => ({
      ...state,
      keyword: action.payload.keyword,
      glossaryItems: [],
      marketInquiryItems: [],
      ...(
        action.payload.keyword.length < 2
          ? {
            companyResultItems: [],
            companyResultGroup: undefined,
            totalPages: 0,
            currentPage: 0,
          }
          : undefined
      ),
    }))
    .addCase(requestCompanyItems, (state, action) => {
      const minKeyword = (action.payload.minKeyword || 0);
      const isSearchable = (action.payload.keyword?.length || 0) >= minKeyword;

      return ({
        ...state,
        validationInfo: {
          isSearchable,
          message: !isSearchable
            ? `Please type at least ${minKeyword} characters`
            : undefined,
        },
        networkStates: {
          ...state.networkStates,
          companySearchRequest: {
            isRequesting: isSearchable,
            extra: action.payload.shouldAddCompanyUrl ? 'expensive' : undefined,
          },
        },
        keyword: !action.payload.isAdditionalSearch
          ? (action.payload.keyword || '')
          : state.keyword,
        minKeyword,
        companyResultItems: action.payload.isAdditionalSearch
          ? state.companyResultItems
          : undefined,
        companyResultGroup: undefined,
        companyResultAdditionals: undefined,
      });
    })
    .addCase(setCompanyItems, (state, action) => {
      const searchItems = action.payload.items || [];
      const isArray = Array.isArray(searchItems);

      if (action.payload.isAdditionalResults) {
        return ({
          ...state,
          networkStates: {
            ...state.networkStates,
            companySearchRequest: {
              isRequesting: false,
              lastError: action.payload.error,
              extra: undefined,
            },
          },
          companyResultAdditionals: searchItems,
        });
      }

      return ({
        ...state,
        networkStates: {
          ...state.networkStates,
          companySearchRequest: {
            isRequesting: false,
            lastError: action.payload.error,
            extra: undefined,
          },
        },
        companyResultItems: isArray ? searchItems : undefined,
        companyResultGroup: (typeof searchItems === 'object') ? searchItems as CompanyItemsGroup : undefined,
        searchId: !action.payload.error
          ? v4()
          : state.searchId,
      });
    })
    .addCase(requestHistoryItems, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        historyItemsRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setHistoryItems, (state, action) => {
      const historyItems = action.payload.items || [];

      return ({
        ...state,
        networkStates: {
          ...state.networkStates,
          companySearchRequest: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
        historyItems,
      });
    })
    .addCase(requestMarketInquiryItems, (state, action) => {
      const minKeyword = (action.payload.minKeyword || 0);
      const isSearchable = (action.payload.question?.length || 0) >= minKeyword;

      return ({
        ...state,
        validationInfo: {
          isSearchable,
          message: !isSearchable
            ? `Please type at least ${minKeyword} characters`
            : undefined,
        },
        networkStates: {
          ...state.networkStates,
          marketInquiryRequest: {
            isRequesting: isSearchable,
          },
        },
      });
    })
    .addCase(setMarketInquiryItems, (state, action) => {
      const marketInquiryItems = action.payload.items || [];

      return ({
        ...state,
        networkStates: {
          ...state.networkStates,
          marketInquiryRequest: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
        marketInquiryItems,
      });
    })
    .addCase(requestGlossaryItems, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        glossaryItemsRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setGlossaryItems, (state, action) => {
      const glossaryItems = action.payload.items || [];

      return ({
        ...state,
        networkStates: {
          ...state.networkStates,
          glossaryItemsRequest: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
        glossaryItems,
      });
    })
    .addCase(requestGptSearch, (state, action) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        gptSearchRequest: {
          isRequesting: true,
        },
      },
      keyword: action.payload.question,
      gptSearchResults: undefined,
    }))
    .addCase(setGptSearch, (state, action) => {
      const gptSearchResults = action.payload.result;

      return ({
        ...state,
        networkStates: {
          ...state.networkStates,
          gptSearchRequest: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
        gptSearchResults,
      });
    })
    .addCase(openFilterEdit, (state) => ({
      ...state,
      filter: {
        ...state.filter,
        isOpen: true,
      },
    }))
    .addCase(closeFilterEdit, (state) => ({
      ...state,
      filter: {
        ...state.filter,
        isOpen: false,
      },
    }))
    .addCase(updateCountriesFilter, (state, action) => ({
      ...state,
      filter: {
        ...state.filter,
        countries: action.payload.countries,
      },
    }))
    .addCase(updateIndustriesFilter, (state, action) => ({
      ...state,
      filter: {
        ...state.filter,
        industries: action.payload.industries,
      },
    }))
    .addCase(updateVerticalsFilter, (state, action) => ({
      ...state,
      filter: {
        ...state.filter,
        verticals: action.payload.verticals,
      },
    }))
    .addCase(updateMinFoundingYearsFilter, (state, action) => ({
      ...state,
      filter: {
        ...state.filter,
        minFoundingYears: action.payload.minFoundingYears,
      },
    }))
    .addCase(clearAllFilters, (state) => ({
      ...state,
      filter: {
        ...state.filter,
        verticals: undefined,
        countries: undefined,
        industries: undefined,
        minFoundingYears: undefined,
      },
    }))
    .addCase(setPage, (state, act) => ({
      ...state,
      currentPage: act.payload.page,
    }))
    .addCase(toggleGptSearch, (state, act) => ({
      ...state,
      isGptSearch: act.payload.isGptSearch,
      keyword: !act.payload.isGptSearch ? '' : state.keyword,
      gptSearchResults: !act.payload.isGptSearch ? undefined : state.gptSearchResults,
    }))
    .addCase(toggleGptChat, (state, act) => ({
      ...state,
      isGptChat: act.payload.isGptChat,
    }));
});

export default searchReducer;
