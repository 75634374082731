import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import { getCustomScrollBarCss } from '../../core/utils/uxUtils';
import getCommonStyles from '../../theme/commonStyles';
import { otherAltLightColorSets, otherColorSets } from '../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      borderBottom: '0.5px solid rgba(224, 224, 224, 1)',
      transition: 'all 0.3 ease-out',
      '&:hover': {
        borderBottom: '0.5px solid transparent',
      },
    },
    dataGridCheckbox: {
      '&:not(.Mui-checked)': {
        opacity: 0,
      },
      transition: 'all 0.2s ease-out',
    },
    dataGridContainer: {
      borderColor: 'transparent !important',
      borderRadius: `${theme.spacing(1.5)} !important`,
      backgroundColor: 'transparent',
      transition: 'all 0.3s ease-out',

      '& .MuiSvgIcon-root > path': {
        color: '#aaa !important',
      },

      '& .MuiDataGrid-columnSeparator': {
        display: 'none !important',
      },

      '& .MuiDataGrid-columnHeader': {
        borderRight: '1px solid #aaa',
        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
          // color: `${otherContrastColorSets[2]} !important`,
          fontWeight: '500 !important',
          '& .MuiBox-root': {
            // color: `${otherContrastColorSets[2]} !important`,
            fontWeight: '500 !important',
          },
        },

        '&:hover $dataGridCheckbox:not(.Mui-checked)': {
          opacity: 1,
        },
      },

      '& .MuiDataGrid-footerContainer': {
        direction: 'ltr',
      },

      '& .MuiDataGrid-columnHeader:last-child': {
        borderRight: 'none !important',
      },

      '& .MuiDataGrid-row': {
        fontWeight: '400 !important',
        '& .MuiDataGrid-cell:not(:nth-last-child(1 of .MuiDataGrid-cell--withRenderer))': {
          borderRight: '0.5px solid rgba(224, 224, 224, 1)',
        },
        '& .MuiDataGrid-cell': {
          display: 'block',
          padding: '8px !important',
          wordBreak: 'break-all',
          '& img': {
            maxWidth: 150,
          },
        },
        '& .MuiDataGrid-cell:last-child': {
          borderRight: 'none !important',
        },

        '&:last-child .MuiDataGrid-cell': {
          borderBottom: 'none !important',
        },

        '&:hover $dataGridCheckbox': {
          opacity: 1,
        },
      },

      '& $dataGridToolbar': {
        opacity: 0,
        transition: 'all 0.3s ease-out',
      },

      '&:hover': {
        borderColor: `${otherColorSets[2]} !important`,
        backgroundColor: theme.palette.common.white,

        '& $dataGridToolbar': {
          opacity: 1,
        },

        '& $dataGridScroller': {
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#999',
          },
        },
      },

      '& .MuiDataGrid-cell:focus-within': {
        outline: 'none',
      },
    },
    dataGridScroller: {
      ...getCustomScrollBarCss('horizontal'),
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent',
      },
      overflowX: 'auto',
    },
    dataGridToolbar: {
      position: 'absolute',
      transition: 'all 0.3s ease-out',
      right: -45,
      width: theme.spacing(5),
      display: 'flex !important',
      flexDirection: 'column',
      paddingTop: '0 !important',
      '&:dir(rtl)': {
        right: -53,
      },
      '& .MuiButtonBase-root': {
        border: `1px solid ${otherColorSets[2]}`,
        borderRadius: `${theme.spacing(1)} !important`,
        minWidth: `${theme.spacing(5)} !important`,
        width: `${theme.spacing(5)} !important`,
        height: `${theme.spacing(5)} !important`,
        marginLeft: '0 !important',
        marginBottom: '0 !important',
        overflow: 'visible !important',
        backgroundColor: 'transparent',

        '& .MuiBadge-badge': {
          top: -4,
          right: -4,
          color: `${theme.palette.common.white} !important`,
        },

        '& .MuiButton-startIcon': {
          marginLeft: 1,
          marginRight: '0 !important',
        },

        '&:hover': {
          backgroundColor: otherAltLightColorSets[2],
        },
      },
    },
    dataGridToolButton: {
      borderRadius: `${theme.spacing(1)} !important`,
      minWidth: `${theme.spacing(5)} !important`,
      width: `${theme.spacing(5)} !important`,
      height: `${theme.spacing(5)} !important`,
      marginLeft: '0 !important',
      marginBottom: '0 !important',
      overflow: 'hidden',

      '& .MuiButton-startIcon': {
        marginLeft: 1,
        marginRight: '0 !important',
      },
    },
    '@global': {
      '.MuiDataGrid-panelFooter': {
        display: 'none !important',
      },
      '.MuiDataGrid-columnHeader:focus': {
        outline: 'none !important',
      },
      '.MuiDataGrid-cell:focus': {
        outline: 'none !important',
      },
      '.MuiDataGrid-cell:focus-within': {
        outline: 'none !important',
      },
    },
    toolButton: {
      '&.active': {
        border: `2px solid ${otherColorSets[2]} !important`,
      },
    },
  });
};

export default styles;
