import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import { useNavigate } from 'react-router';

import { useCommonClasses } from '../../../../theme/commonStyles';
import { ReactComponent as FundIcon } from '../../../../assets/svg/fundIcon.svg';
import { ReactComponent as FundedIcon } from '../../../../assets/svg/fundedIcon.svg';
import { ReactComponent as DefaultCompanyIcon } from '../../../../assets/svg/defaultCompanyIcon.svg';

import { CompetitorCompanyInfo } from '../types';
import { getCommonReportParams } from '../reportUtils';
import reportRoutes from '../reportRoutes';

import { PropsFromRedux } from './container';
import styles from './LandscapeReportDetails.styles';

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);
const MAX_COMPETITORS = 5;

const LandscapeReportDetails: React.FC<Props> = ({
  companyReport,
  competitorsReport,
  landscapeIsFunded,
  landscapeStatus,
  setLandscapeIsFunded,
  setLandscapeStatus,
  setCompetitorHighlight,
  highlightingCompetitor,
  selectedCompetitors,
  selectCompetitor,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const commonCss = useCommonClasses();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.xl));

  useEffect(() => {
    setCompetitorHighlight({
      competitor: undefined,
      isPersist: true,
    });
  }, [setCompetitorHighlight]);

  if (!competitorsReport) {
    return (
      <div />
    );
  }

  const selectedProp = `${landscapeStatus.toLowerCase()}${landscapeIsFunded ? 'Funded' : 'All'}`;
  const competitorsLookup = competitorsReport as unknown as Record<string, CompetitorCompanyInfo[]>;
  const competitors = competitorsLookup?.[selectedProp] || [];

  const toggleSelectCompetitor = (competitor: CompetitorCompanyInfo) => {
    if (
      selectedCompetitors.length === MAX_COMPETITORS
      && !selectedCompetitors.find((c) => c.id === competitor.id)
    ) {
      return;
    }

    selectCompetitor({
      competitor,
    });
  };

  const persistCompetitorHighlight = (competitor?: CompetitorCompanyInfo) => {
    if (
      highlightingCompetitor?.competitor?.id === competitor?.id
      && highlightingCompetitor?.isPersist
    ) {
      setCompetitorHighlight({
        competitor: undefined,
        isPersist: true,
      });

      return;
    }

    setCompetitorHighlight({
      competitor,
      isPersist: !!competitor,
    });
  };

  const openRankingReport = () => {
    const params = getCommonReportParams(companyReport);
    const landscapeReportUrl = `${reportRoutes.ranking}${params ? `?${params}` : ''}`;
    navigate(`.${landscapeReportUrl}`, { replace: true });
  };

  return (
    <div className={classes.root}>
      <div className={classes.filtersPanel}>
        <Grid container>
          <Grid item xs={6.1}>
            <Button
              variant="contained"
              size="medium"
              classes={commonCss.buttons.generalButton}
              className={
                classNames(
                  classes.compareButton,
                  'ranking',
                  'flip',
                )
              }
              onClick={() => openRankingReport()}
            >
              {`Compare ${selectedCompetitors.length}/${MAX_COMPETITORS}`}
            </Button>
            <Button
              variant="outlined"
              size="medium"
              className={classes.fundedSwitchButton}
              classes={commonCss.buttons.leafButton}
              color={landscapeIsFunded ? 'secondary' : 'info'}
              onClick={() => setLandscapeIsFunded({
                isFunded: !landscapeIsFunded,
              })}
            >
              <Box component="span" marginRight={1} display="flex" alignItems="center">
                <FundIcon />
              </Box>
              <Typography
                variant="body1"
                color={landscapeIsFunded ? 'secondary' : 'info'}
              >
                Funded
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={5.9}>
            <Grid container columnSpacing={1}>
              <Grid item container xs={2} alignItems="center">
                {isLargeScreen && (
                  <Typography variant="body1">
                    Status:
                  </Typography>
                )}
              </Grid>
              <Grid item xs={3.33}>
                <Button
                  variant="outlined"
                  size="medium"
                  className={classes.statusButton}
                  classes={commonCss.buttons.leafButton}
                  color={landscapeStatus === 'operating' ? 'secondary' : 'info'}
                  fullWidth
                  onClick={() => setLandscapeStatus({
                    status: 'operating',
                  })}
                >
                  <Typography
                    variant="body1"
                    color={landscapeStatus === 'operating' ? 'secondary' : 'info'}
                  >
                    Operating
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={3.33}>
                <Button
                  variant="outlined"
                  size="medium"
                  className={classes.statusButton}
                  classes={commonCss.buttons.leafButton}
                  color={landscapeStatus === 'exit' ? 'secondary' : 'info'}
                  fullWidth
                  onClick={() => setLandscapeStatus({
                    status: 'exit',
                  })}
                >
                  <Typography
                    variant="body1"
                    color={landscapeStatus === 'exit' ? 'secondary' : 'info'}
                  >
                    Exit
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={3.33}>
                <Button
                  variant="outlined"
                  size="medium"
                  className={classes.statusButton}
                  classes={commonCss.buttons.leafButton}
                  color={landscapeStatus === 'closed' ? 'secondary' : 'info'}
                  fullWidth
                  onClick={() => setLandscapeStatus({
                    status: 'closed',
                  })}
                >
                  <Typography
                    variant="body1"
                    color={landscapeStatus === 'closed' ? 'secondary' : 'info'}
                  >
                    Closed
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Typography variant="h2" classes={{ root: classes.title }}>
        Competitive Landscape
      </Typography>
      <Box className={classes.itemsContainer}>
        <Grid container columnSpacing={4} rowSpacing={4} columns={5}>
          {competitors.map((competitor) => (
            <Grid key={competitor.id} item xs={1}>
              <Box
                className={
                  classNames(
                    classes.competitorItem,
                    highlightingCompetitor?.competitor === competitor && 'highlight',
                    !!selectedCompetitors.find((c) => c.id === competitor.id) && 'highlight',
                  )
                }
                onClick={() => persistCompetitorHighlight(competitor)}
                onMouseEnter={() => setCompetitorHighlight({
                  competitor,
                })}
                onMouseLeave={() => setCompetitorHighlight({
                  competitor: undefined,
                })}
              >
                <div
                  className={classes.companyLogoContainer}
                  onClick={(event) => {
                    toggleSelectCompetitor(competitor);
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                >
                  {competitor.logo?.at(0) ? (
                    <Box
                      className={
                        classNames(
                          commonCss.boxes.imageBox,
                          'logo',
                        )
                      }
                      component="object"
                      data={competitor.logo?.at(0)}
                      type="image/png"
                      flexShrink={0}
                      width={75}
                      height={75}
                    >
                      <DefaultCompanyIcon />
                    </Box>
                  ) : (
                    <Box
                      className={
                        classNames(
                          commonCss.boxes.imageBox,
                          'logo',
                        )
                      }
                      width={75}
                      height={75}
                    >
                      <DefaultCompanyIcon />
                    </Box>
                  )}
                </div>
                <div className={classes.companyNameContainer}>
                  {competitor.name}
                </div>
                {competitor.hadFunding && (
                  <Box className={classes.fundedIcon}>
                    <FundedIcon width={32} />
                  </Box>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default LandscapeReportDetails;
