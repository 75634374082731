import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';

import {
  otherColorSets,
  otherContrastColorSets,
} from '../../../../theme/palette';

import getCommonStyles, {
  getColorModifierClasses,
  getContrastColorModifierClasses,
} from '../../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      ...commonStyles.smallRoundedBorder,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: `${theme.spacing(2)} ${theme.spacing(3)} !important`,
      borderRadius: `${theme.spacing(1.5)} !important`,
      transition: 'all ease-in-out 0.3s',
      '&.active': {
        borderColor: `${otherColorSets[1]} !important`,
        background: `${theme.palette.common.white} !important`,
        boxShadow: `${theme.shadows[3]} !important`,

        '& $description': {
          maxHeight: '20rem',
        },

        '& $keywords': {
          maxHeight: '20rem',
        },
      },
      textAlign: 'left',
      backgroundColor: `${theme.palette.background.paper} !important`,
      position: 'relative',
    },
    body: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    },
    logoContainer: {
      cursor: 'pointer',
    },
    moreControls: {
      display: 'flex',
      paddingTop: 0,
      marginRight: theme.spacing(-1),
      overflow: 'hidden',
      height: 0,
      transition: 'all ease-in-out 0.3s',
      '&.active': {
        height: 40,
        paddingTop: theme.spacing(2),
      },
    },
    openReportButton: {
      width: 100,
      borderRadius: '20px !important',
      paddingTop: `${theme.spacing(0)} !important`,
      paddingBottom: `${theme.spacing(0)} !important`,
      height: '20px !important',
      boxSizing: 'border-box',
      fontSize: '0.928rem !important',
      fontWeight: 'normal !important',
      ...getColorModifierClasses('background', '.MuiButton-contained:hover'),
    },
    selectableBox: {
      userSelect: 'text',
      cursor: 'default',
      maxWidth: 420,
    },
    name: {
      fontSize: '1.4285rem !important',
      lineHeight: '1.7rem !important',
      marginBottom: `${theme.spacing(0.5)} !important`,
      fontWeight: '400 !important',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: 419.5,
      '& small': {
        fontSize: '1rem',
        fontWeight: 300,
        color: theme.palette.grey[600],
      },
    },
    description: {
      fontWeight: '300 !important',
      maxHeight: '5.5rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transition: 'max-height ease-out 0.6s',
      lineHeight: 1.4,
      color: `${theme.palette.grey[900]} !important`,
    },
    keywords: {
      rowGap: theme.spacing(0.5),
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      maxHeight: theme.spacing(15.5),
      overflow: 'hidden',
      transition: 'max-height ease-out 0.6s',
    },
    tagItem: {
      height: `${theme.spacing(3)} !important`,
      fontWeight: 300,
      margin: '0 4px 0 0 !important',
      '&.active': {
        borderColor: otherContrastColorSets[4],
      },
    },
    companyInfo: {
      marginTop: theme.spacing(1.25),
      width: '100%',
      '& p': {
        fontWeight: '300 !important',
        '&:not(.label)': {
          color: `${theme.palette.grey[900]} !important`,
        },
      },
    },
    webLink: {
      textDecoration: 'none !important',
      textDecorationColor: 'transparent !important',
      cursor: 'pointer',
      '& small:hover': {
        color: theme.palette.secondary.main,
      },
    },
    scoreBox: {
      position: 'absolute',
      top: 0,
      left: 0,
      padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
      color: `${theme.palette.common.white} !important`,
      zIndex: 1,
      ...getContrastColorModifierClasses('background'),
    },
    scoreText: {
      fontSize: '0.8571rem !important',
      fontWeight: '500 !important',
      color: `${theme.palette.common.white} !important`,
    },
    reportIconContainer: {
      position: 'relative',
      cursor: 'pointer',
      width: 35,
      transition: 'all ease-in-out 0.3s',
      '&.score': {
        width: 45,
        '& .desc': {
          left: 50,
        },
      },
      '& .desc': {
        position: 'absolute',
        left: 40,
        top: 0,
        opacity: 0,
        whiteSpace: 'nowrap',
        pointerEvents: 'none',
        transform: 'translate(0, 5px)',
        transition: 'all ease-in-out 0.3s',
      },
      '&:hover': {
        '& .desc': {
          opacity: 1,
          transform: 'translate(0, 0)',
        },
      },
      '&:nth-child(1):hover': {
        width: 130,
      },
      '&:nth-child(2):hover': {
        width: 130,
      },
      '&:nth-child(3):hover': {
        width: 160,
      },
      '&:nth-child(4):hover': {
        width: 180,
      },
      '&:nth-child(5):hover': {
        width: 200,
      },
      '&:nth-child(6):hover': {
        width: 200,
      },
      '&:nth-child(7):hover': {
        width: 160,
      },
    },
    reportIconBox: {
      width: '35px !important',
      height: 20,
      borderRadius: '10px 10px 10px 0',
      borderWidth: 1,
      backgroundColor: 'inherit',

      '&.score': {
        width: '45px !important',
      },

      '&:first-child svg': {
        fill: theme.palette.grey[100],
      },
      '&:first-child.active': {
        ...getColorModifierClasses('background'),
        borderWidth: 0,
        '& svg': {
          fill: theme.palette.common.white,
        },
      },
      '& svg': {
        stroke: `${theme.palette.grey[100]} !important`,
      },
      '&.active': {
        ...getColorModifierClasses('background'),
        borderWidth: 0,
        '& svg': {
          stroke: `${theme.palette.common.white} !important`,
        },
      },
    },
  });
};

export default styles;
