import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles, {
  getColorModifierClasses,
  getContrastColorModifierClasses,
} from '../../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 0,
      flexBasis: '7rem',
      alignItems: 'center',
      padding: theme.spacing(4),
      justifyContent: 'space-between',
    },
    homeButton: {
      color: `${theme.palette.grey[300]} !important`,
      borderColor: `${theme.palette.grey[300]} !important`,
      stroke: `${theme.palette.grey[300]} !important`,
    },
    styledLogo: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      '&.company .styled-logo-path-gradient': {
        fill: 'url(#styled-logo-company)',
      },
      '&.founder .styled-logo-path-gradient': {
        fill: 'url(#styled-logo-founder)',
      },
      '&.team .styled-logo-path-gradient': {
        fill: 'url(#styled-logo-team)',
      },
    },
    stepBanner: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'normal',
      fontSize: '1.285rem',
      transition: 'all ease-out 0.3s',
      color: theme.palette.grey[700],
      '&.active': {
        fontSize: '1.714rem',
      },
      '& > p': {
        marginLeft: theme.spacing(1.5),
      },
      ...getColorModifierClasses('color', '.active'),
      ...getColorModifierClasses('stroke', '.active .svg-icon-path'),
      ...getContrastColorModifierClasses('stroke', '.active.contrast .svg-icon-path'),
      '&.active .svg-icon-path-logo': {
        stroke: 'url(#svg-icon-path-gradient)',
      },
      '&.off': {
        opacity: 0.2,
      },
    },
    stepDivider: {
      height: 0,
      width: theme.spacing(3),
      border: `1px solid ${theme.palette.grey[300]}`,
      '&.off': {
        opacity: 0.2,
      },
    },
  });
};

export default styles;
