import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
} from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import getSymbolFromCurrency from 'currency-symbol-map';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router';

import { useCommonClasses } from '../../../../theme/commonStyles';
import { easeOut } from '../../../../core/utils/animationUtils';
import {
  AMOUNT_1M,
  extractSelectableMarkets,
  getAmountDisplayWithSymbol,
  getCommonReportParams,
  getScoreAsPercentage,
  refineMarketReport,
} from '../reportUtils';

import SelectedCompetitiorsPanel from '../selectedCompetitorsPanel';

import styles from './OnePagerDetailsPanel.styles';
import { PropsFromRedux } from './container';
import reportRoutes from '../reportRoutes';
import { CompetitorCompanyInfo, FounderData } from '../types';
import FounderAvatar from '../founderAvatar';

const Flag = lazy(() => import('react-world-flags'));

interface Props extends PropsFromRedux {
  onCompetitorInfoOpen?: (competitorData: CompetitorCompanyInfo) => void;
}

const useStyles = makeStyles(styles);

const OnePagerDetailsPanel: React.FC<Props> = ({
  marketReport,
  companyReport,
  investorReport,
  marketCountries,
  selectMarketCountry,
  selectedMarketCountry,
  onCompetitorInfoOpen,
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const theme = useTheme();
  const navigate = useNavigate();

  const companyData = companyReport?.companyData;
  const country = companyData?.orgCountry;

  const search = new URLSearchParams(window.location.search);
  const score = search.get('score');

  const founders = companyReport?.founders;
  const visibleFounders = Array.from({ length: 3 })
    .fill(undefined).map((_, index) => (founders || []).at(index))
    .filter((founder): founder is FounderData => !!founder);

  const marketCountry = marketCountries?.find((m) => m.country?.isoCode === country)
    || marketCountries?.find((m) => m.country?.isoCode === 'US');

  const scoreParams = new URLSearchParams();

  if (score) {
    scoreParams.set('score', 'true');
  }

  const scoreQuery = scoreParams.toString();

  const commonReportParams = getCommonReportParams(companyReport);
  const scoreReportUrl = `${reportRoutes.profileScore}${scoreQuery ? `?${scoreQuery}` : ''}`;
  const investReportUrl = `${reportRoutes.successScore}${scoreQuery ? `?${scoreQuery}` : ''}`;
  const marketReportUrl = `${reportRoutes.market}${commonReportParams ? `?${commonReportParams}` : ''}`;

  useEffect(() => {
    if (!companyData || !country) {
      return;
    }

    if (marketCountry?.countryCode !== selectedMarketCountry?.countryCode) {
      selectMarketCountry({
        marketCountry,
        desc: companyData.orgDesc,
      });
    }
  }, [marketCountries, companyData, country]);

  const countFormatter = useCallback(
    (num: number) => `${num.toFixed(1)}%`
      .split('.')
      .map((n) => `<span>${n}</span>`)
      .join('.'),
    [],
  );

  const currencyFormatter = useCallback((amount: number) => {
    if (amount >= AMOUNT_1M) {
      return getAmountDisplayWithSymbol(amount, {
        decimals: 1,
        capitalized: false,
        smallUnit: true,
        symbol: '$',
      });
    }

    return getAmountDisplayWithSymbol(amount, {
      decimals: 0,
      capitalized: false,
      smallUnit: true,
      symbol: '$',
    });
  }, []);

  const marketItems = extractSelectableMarkets(marketReport);
  const refinedMarketReports = refineMarketReport(marketReport);

  const currencyCode = selectedMarketCountry?.country?.currency || 'USD';
  const currencySymbol = getSymbolFromCurrency(currencyCode);

  const remark = companyReport?.companyData?.remark;
  const profileScore = getScoreAsPercentage(companyReport?.companyData.overallScore) || 0;
  const vcLikeableScore = getScoreAsPercentage(companyReport?.companyData.vcLikeable) || 0;
  const investScore = getScoreAsPercentage(investorReport?.investorData.overallScore) || 0;
  const round = (investorReport?.round || 0) * AMOUNT_1M;
  const valuation = (investorReport?.valuation || 0) * AMOUNT_1M;

  const maxMarketItems = 6 - visibleFounders.length;

  return (
    <div className={classes.root}>
      {score && (
        <Box
          className={classNames(
            commonCss.boxes.dataBox,
            classes.profilePanel,
          )}
          padding={2}
          marginBottom={2}
        >
          <table width="100%" cellPadding={0} cellSpacing={0}>
            <tbody>
              <tr>
                <td>
                  <Typography
                    variant="body2"
                    className={classes.profileHeader}
                  >
                    Profile
                    <span className={classes.recommendBadge}>
                      {remark}
                    </span>
                    <br/>
                    Assetment
                  </Typography>
                </td>
                <td align="center" width="24%">
                  <Box
                    className={classNames(
                      commonCss.banners.chartPinBanner,
                      classes.scoreBanner,
                      'secondary',
                      'contrast',
                    )}
                    onClick={() => {
                      navigate(`.${scoreReportUrl}`);
                    }}
                  >
                    <CountUp
                      startVal={0}
                      end={profileScore}
                      duration={1}
                      decimals={1}
                      useEasing
                      easingFn={easeOut}
                      formattingFn={countFormatter}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    marginTop={0.5}
                    fontWeight={300}
                    fontSize="1rem"
                    color={theme.palette.grey[900]}
                  >
                    Profile Score
                  </Typography>
                </td>
                <td align="center" width="24%">
                  <Box
                    className={classNames(
                      commonCss.banners.chartPinBanner,
                      classes.vcLikeableBanner,
                    )}
                    onClick={() => {
                      navigate(`.${scoreReportUrl}`);
                    }}
                  >
                    <CountUp
                      startVal={0}
                      end={vcLikeableScore}
                      duration={1}
                      decimals={1}
                      useEasing
                      easingFn={easeOut}
                      formattingFn={countFormatter}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    marginTop={0.5}
                    fontWeight={300}
                    fontSize="1rem"
                    color={theme.palette.grey[900]}
                  >
                    VC Likeable
                  </Typography>
                </td>
                <td align="center" width="24%">
                </td>
              </tr>
              <tr>
                <td>
                  <Box marginTop={2} />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography
                    variant="body2"
                    className={classes.investHeader}
                  >
                    1st Round<br/>
                    Metrics
                  </Typography>
                </td>
                <td align="center">
                  <Box
                    className={classNames(
                      commonCss.banners.chartPinBanner,
                      classes.fundingBanner,
                      'contrast',
                    )}
                    onClick={() => {
                      navigate(`.${investReportUrl}`);
                    }}
                  >
                    <CountUp
                      startVal={0}
                      end={round}
                      duration={1}
                      decimals={1}
                      useEasing
                      easingFn={easeOut}
                      formattingFn={currencyFormatter}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    marginTop={0.5}
                    fontWeight={300}
                    fontSize="1rem"
                    color={theme.palette.grey[900]}
                  >
                    Funding
                  </Typography>
                </td>
                <td align="center">
                  <Box
                    className={classNames(
                      commonCss.banners.chartPinBanner,
                      classes.valuationBanner,
                      'contrast',
                    )}
                    onClick={() => {
                      navigate(`.${investReportUrl}`);
                    }}
                  >
                    <CountUp
                      startVal={0}
                      end={valuation}
                      duration={1}
                      decimals={1}
                      useEasing
                      easingFn={easeOut}
                      formattingFn={currencyFormatter}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    marginTop={0.5}
                    fontWeight={300}
                    fontSize="1rem"
                    color={theme.palette.grey[900]}
                  >
                    Valuation
                  </Typography>
                </td>
                <td align="center">
                  <Box
                    className={classNames(
                      commonCss.banners.chartPinBanner,
                      classes.likelyExitBanner,
                      'contrast',
                    )}
                    onClick={() => {
                      navigate(`.${investReportUrl}`);
                    }}
                  >
                    <CountUp
                      startVal={0}
                      end={investScore}
                      duration={1}
                      decimals={1}
                      useEasing
                      easingFn={easeOut}
                      formattingFn={countFormatter}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    marginTop={0.5}
                    fontWeight={300}
                    fontSize="1rem"
                    color={theme.palette.grey[900]}
                    style={{ whiteSpace: 'nowrap', marginLeft: -8, marginRight: -8 }}
                  >
                    Likelihood to Exit
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      )}

      {score && (
        <Box
          className={classNames(
            commonCss.boxes.dataBox,
            classes.founderPanel,
          )}
          marginBottom={2}
        >
          <TableContainer className={classes.table}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell component="th" style={{ whiteSpace: 'nowrap' }} valign="baseline">
                    <Typography
                      variant="body2"
                      className={classes.founderHeader}
                    >
                      Team
                    </Typography>
                  </TableCell>
                  <TableCell
                    component="th"
                    align="center"
                    className={classes.tableHeader}
                    valign="baseline"
                    width={100}
                    padding="none"
                  >
                    Founder Score
                  </TableCell>
                  <TableCell
                    component="th"
                    align="center"
                    className={classes.tableHeader}
                    valign="baseline"
                    width={100}
                    padding="none"
                  >
                    VC Likeable
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleFounders.map((founder, index) => (
                  <TableRow
                    key={index}
                    className={classes.founderItem}
                    onClick={() => {
                      navigate(`.${scoreReportUrl}`);
                    }}
                  >
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <FounderAvatar
                          founder={founder}
                          useSmallSize
                        />
                        <Typography
                          display="inline-block"
                          variant="body2"
                          className={classes.founderName}
                        >
                          {`${founder.firstName} ${founder.lastName}`}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        className={
                          classNames(
                            commonCss.banners.leafBanner,
                            classes.leafBanner,
                          )
                        }
                      >
                        <Typography variant="h6" color="inherit" textAlign="center">
                          <CountUp
                            end={founder.score * 100}
                            duration={1}
                            decimals={0}
                            useEasing
                            delay={0.5}
                            easingFn={easeOut}
                          />
                          <small>%</small>
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        className={
                          classNames(
                            commonCss.banners.leafBanner,
                            classes.leafBanner,
                            'outline',
                          )
                        }
                      >
                        <Typography variant="h6" color="inherit" textAlign="center">
                          <CountUp
                            end={founder.vcLikeable * 100}
                            duration={1}
                            decimals={0}
                            useEasing
                            delay={0.5}
                            easingFn={easeOut}
                          />
                          <small>%</small>
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      <Box
        className={classNames(
          commonCss.boxes.dataBox,
          classes.industryStatesPanel,
        )}
        marginBottom={2}
      >
        <TableContainer className={classes.table}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell component="th" style={{ whiteSpace: 'nowrap' }} valign="baseline">
                  <Typography
                    variant="body2"
                    className={classes.industryStatesHeader}
                  >
                    Industry Stats
                  </Typography>
                  <Box component="span" display="inline-block" marginLeft={1}>
                    <Suspense>
                      <Flag
                        code={marketCountry?.country?.isoCode}
                        width={theme.spacing(2.5)}
                      />
                    </Suspense>
                  </Box>
                  <Box component="span" display="inline-block" marginLeft={1}>
                    <Typography
                      variant="body2"
                      className={classes.countryValue}
                    >
                      {marketCountry?.countryCode}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell
                  component="th"
                  align="center"
                  className={classes.tableHeader}
                  valign="baseline"
                  width={100}
                  padding="none"
                >
                  Businesses
                </TableCell>
                <TableCell
                  component="th"
                  align="center"
                  className={classes.tableHeader}
                  valign="baseline"
                  width={100}
                  padding="none"
                >
                  Market Size
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {marketItems.slice(0, maxMarketItems).map((market) => (
                <TableRow
                  key={market}
                  className={classes.marketItem}
                  onClick={() => {
                    navigate(`.${marketReportUrl}&market=${market}`);
                  }}
                >
                  <TableCell component="td">
                    <Tooltip title={market} enterDelay={1000} arrow>
                      <Typography
                        variant="body2"
                        className={classes.tableValue}
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        maxWidth={190}
                      >
                        {market}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell component="td">
                    <Typography
                      variant="body2"
                      className={classes.tableValue}
                      align="center"
                    >
                      {refinedMarketReports?.find((r) => (
                        r.subCategory === market
                        && r.stat === 'Number of Businesses'
                      ))?.chartData?.['2021'].toLocaleString()}
                    </Typography>
                  </TableCell>
                  <TableCell component="td">
                    <Typography
                      variant="body2"
                      className={classes.tableValue}
                      align="center"
                    >
                      {currencySymbol}
                      {Math.round(refinedMarketReports?.find((r) => (
                        r.subCategory === market
                        && r.stat === 'Market Size Statistics'
                      ))?.chartData?.['2021'] || 0).toLocaleString()}M
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {!score && (
        <Box
          className={classNames(
            commonCss.boxes.dataBox,
            classes.mostSimilarPanel,
          )}
          flexDirection="column"
          alignItems="flex-start"
          padding={2}
        >
          <Typography
            variant="body2"
            className={classes.mostSimilarHeader}
          >
            Most Similar
          </Typography>
          <Box width="calc(100% + 24px)" marginLeft={-1}>
            <SelectedCompetitiorsPanel
              useTop5
              allowDelete={false}
              allowOpenReport
              onCompetitorInfoOpen={onCompetitorInfoOpen}
            />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default OnePagerDetailsPanel;
