import NetworkState from '../../../core/types/network';

export enum SessionActions {
  SESSION_INITIALIZE = '@@session/SESSION_INITIALIZE',
  CSRF_REQUEST = '@@session/CSRF_REQUEST',
  CSRF_SET = '@@session/CSRF_SET',
  LOGIN_REQUEST = '@@session/LOGIN_REQUEST',
  LOGIN_SET = '@@session/LOGIN_SET',
  LOGOUT_REQUEST = '@@session/LOGOUT_REQUEST',
  LOGOUT_SET = '@@session/LOGOUT_SET',
  USER_EXTRA_REQUEST = '@@session/USER_EXTRA_REQUEST',
  USER_EXTRA_SET = '@@session/USER_EXTRA_SET',
}

export interface User {
  pk: number;
  email: string;
  firstName?: string;
  lastName?: string;
  username: string;
}

export interface LoginInfo {
  key: string;
  user: User;
}

export interface ExtraInfo {
  id: number;
  credits: number;
  creditsUsed: number;
  permLevel: number;
  permLevelDisplay: string;
  user: number;
}

export interface UserExtraInfo {
  count: number;
  next?: string;
  previous?: string;
  results: ExtraInfo[];
}

export interface RequestLoginArgs {
  username: string;
  password: string;
}

export interface SetLoginArgs {
  loginInfo?: LoginInfo;
  error?: Error;
}

export interface SetLoggedOutArgs {
  error?: Error;
}

export interface RequestUserExtraArgs {
  user: string;
}

export interface SetUserExtraArgs {
  userExtraInfo?: UserExtraInfo;
  error?: Error;
}

export interface SessionState {
  networkStates: {
    loginRequest: NetworkState;
    logoutRequest: NetworkState;
    userExtraRequest: NetworkState;
  };
  isSessionInitialized?: boolean;
  token?: string;
  user?: User;
  userExtraInfo?: UserExtraInfo;
}
