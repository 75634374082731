import React from 'react';
import { makeStyles } from '@mui/styles';
import { PropsFromRedux } from './container';
import styles from './GlossaryResults.styles';
import GlossaryList from '../glossaryList';

interface Props extends PropsFromRedux {}

const PAGE_SIZE = 5;
const useStyles = makeStyles(styles);

const GlossaryResults: React.FC<Props> = ({
  glossaryItems,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GlossaryList
        items={glossaryItems}
        totalItemsInPage={PAGE_SIZE}
      />
    </div>
  );
};

export default GlossaryResults;
