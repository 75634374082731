import { createAction } from '@reduxjs/toolkit';
import {
  ManualFormActions,
  RequestCompanySaveArgs,
  RequestFounderSaveArgs,
  RequestImageUploadArgs,
  RequestLinkedInProfileArgs,
  RequestPvyParamsArgs,
  SetAvailableKeywordsArgs,
  SetCompanySavedArgs,
  SetFounderSavedArgs,
  SetImageUploadedArgs,
  SetIsCancellingArgs,
  SetLinkedInProfileArgs,
  SetManualFormSavedArgs,
  SetPvyParamsArgs,
  SetStepArgs,
  SetFocusedDataInfoKeyArgs,
  UpdateManualFormFieldArgs,
  SetHoveredDataInfoKeyArgs,
} from './types';

export const initializeManualForm = (
  createAction(ManualFormActions.MANUAL_FORM_INITIALIZE)
);
export const setIsCancelling = (
  createAction<SetIsCancellingArgs>(ManualFormActions.IS_CANCELLING_SET)
);
export const updateManualFormField = (
  createAction<UpdateManualFormFieldArgs>(ManualFormActions.MANUAL_FORM_FIELD_UPDATE)
);
export const setAvailableKeywords = (
  createAction<SetAvailableKeywordsArgs>(ManualFormActions.AVAILABLE_KEYWORDS_SET)
);
export const setStep = (
  createAction<SetStepArgs>(ManualFormActions.STEP_SET)
);
export const requestPvyParams = (
  createAction<RequestPvyParamsArgs>(ManualFormActions.PVY_PARAMS_REQUEST)
);
export const setPvyParams = (
  createAction<SetPvyParamsArgs>(ManualFormActions.PVY_PARAMS_SET)
);
export const requestImageUpload = (
  createAction<RequestImageUploadArgs>(ManualFormActions.IMAGE_UPLOAD_REQUEST)
);
export const setImageUploaded = (
  createAction<SetImageUploadedArgs>(ManualFormActions.IMAGE_UPLOADED_SET)
);
export const requestCompanySave = (
  createAction<RequestCompanySaveArgs>(ManualFormActions.COMPANY_SAVE_REQUEST)
);
export const setCompanySaved = (
  createAction<SetCompanySavedArgs>(ManualFormActions.COMPANY_SAVED_SET)
);
export const requestFounderSave = (
  createAction<RequestFounderSaveArgs>(ManualFormActions.FOUNDER_SAVE_REQUEST)
);
export const setFounderSaved = (
  createAction<SetFounderSavedArgs>(ManualFormActions.FOUNDER_SAVED_SET)
);
export const initiateManualFormSave = (
  createAction(ManualFormActions.MANUAL_FORM_SAVE_INITIATE)
);
export const setManualFormSaved = (
  createAction<SetManualFormSavedArgs>(ManualFormActions.MANUAL_FORM_SAVED_SET)
);
export const requestLinkedInProfile = (
  createAction<RequestLinkedInProfileArgs>(ManualFormActions.LINKED_IN_PROFILE_REQUEST)
);
export const setLinkedInProfile = (
  createAction<SetLinkedInProfileArgs>(ManualFormActions.LINKED_IN_PROFILE_SET)
);
export const setFocusedDataInfoKey = (
  createAction<SetFocusedDataInfoKeyArgs>(ManualFormActions.FOCUSED_DATA_KEY_SET)
);
export const setHoveredDataInfoKey = (
  createAction<SetHoveredDataInfoKeyArgs>(ManualFormActions.HOVERED_DATA_KEY_SET)
);
