import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../../theme/commonStyles';
import { otherColorSets, otherLightColorSets } from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      boxSizing: 'border-box',
      paddingLeft: theme.spacing(8),
      paddingTop: theme.spacing(4),
      [theme.breakpoints.down('xl')]: {
        paddingLeft: theme.spacing(8),
      },
    },
    filtersPanel: {
      flex: 0,
      width: 'calc(100% - 60px) !important',
      marginTop: theme.spacing(-12),
      marginBottom: theme.spacing(7),
    },
    compareButton: {
      fontWeight: '500 !important',
      fontSize: '1.142rem !important',
      width: 167,
      height: 38,
      borderRadius: `${theme.spacing(3)} !important`,
      lineHeight: '1.2 !important',
      paddingTop: `${theme.spacing(0.75)} !important`,
      paddingBottom: `${theme.spacing(0.75)} !important`,
    },
    fundedSwitchButton: {
      marginLeft: `${theme.spacing(3)} !important`,
      color: `${theme.palette.grey[700]} !important`,
      '& .MuiTypography-root': {
        fontSize: '1.142rem !important',
        fontWeight: 'normal !important',
      },
      width: 167,
      [theme.breakpoints.down('xl')]: {
        width: 100,
      },
    },
    statusButton: {
      color: `${theme.palette.grey[700]} !important`,
      '& .MuiTypography-root': {
        fontSize: '1.142rem !important',
        fontWeight: 'normal !important',
      },
    },
    title: {
      fontWeight: '100 !important',
      flex: 0,
    },
    itemsContainer: {
      flex: 1,
      overflow: 'auto',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(5),
      padding: 5,
    },
    competitorItem: {
      height: 140,
      borderRadius: 10,
      boxShadow: '0 3px 10px 0 rgba(72, 72, 72, 0.1)',
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(2),
      textAlign: 'center',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      '&.highlight': {
        backgroundColor: otherLightColorSets[4],
        boxShadow: '0 3px 10px 0 rgba(203, 149, 86, 0.42)',
      },
    },
    companyLogoContainer: {
      marginBottom: theme.spacing(1),
      '& .logo': {
        transition: 'all 0.3s ease-out',
      },
      '&:hover .logo': {
        borderColor: otherColorSets[4],
        boxShadow: '0 3px 10px 0 rgba(203, 149, 86, 0.42)',
      },
    },
    companyNameContainer: {
      height: 40,
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    fundedIcon: {
      position: 'absolute',
      top: theme.spacing(1.5),
      right: theme.spacing(1.5),
      transform: 'scale(0.8, 0.8)',
    },
  });
};

export default styles;
