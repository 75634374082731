import React, { HTMLProps, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import ReactSlider from 'react-slider';

import { ReactComponent as CaretUpIcon } from '../../../../assets/svg/caretUpIcon.svg';
import { useCommonClasses } from '../../../../theme/commonStyles';

import { PropsFromRedux } from './container';
import styles from './SettingsView.styles';
import useCommonProps from '../../../../theme/commonProps';
import { getAvailableAgents } from '../gptUtils';

interface Props extends PropsFromRedux {
  className?: string;
  isSmall?: boolean;
}

const useStyles = makeStyles(styles);

const SettingsView: React.FC<Props> = ({
  className,
  gptModels,
  userExtraInfo,
  settings,
  updateSettings,
  isSmall,
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();
  const commonProps = useCommonProps();
  const [isShowingAgents, setShowingAgents] = useState(true);
  const [isShowingModels, setShowingModels] = useState(false);

  const availableAgents = getAvailableAgents().map((agent) => {
    if (agent.value === 'plan_execute') {
      return {
        ...agent,
        options: [{
          name: 'Show Plan',
          value: settings.showPlan,
          onChange: (checked: boolean) => {
            updateSettings({
              settings: {
                ...settings,
                showPlan: checked,
              },
            });
          },
        }],
      };
    }

    return {
      ...agent,
      options: undefined,
    };
  });

  const toggleAgents = () => {
    setShowingAgents(!isShowingAgents);
  };

  const toggleModels = () => {
    setShowingModels(!isShowingModels);
  };

  const getSelectedAgentText = () => {
    const selectedAgent = (availableAgents)
      .find((agent) => agent.value === settings.agent);

    return selectedAgent?.name;
  };

  const getSelectedModelText = () => {
    const selectedModel = (gptModels || [])
      .find((model) => model.modelName === settings.gptModelName);

    return selectedModel?.modelName;
  };

  return (
    <Box
      className={classNames(
        classes.root,
        className,
      )}
    >
      <Box className={classes.expander} marginBottom={1} marginTop={2}>
        <Box
          className={classNames(
            classes.expanderContent,
            isShowingAgents && 'highlight',
          )}
        >
          <Button
            variant="text"
            color="info"
            onClick={() => {
              toggleAgents();
            }}
            fullWidth
          >
            <Box className={classes.sectionHeader} marginLeft={0.5}>
              AGENT
              <Box className={classes.sectionHeaderAdditional}>
                {getSelectedAgentText()}
              </Box>
            </Box>
            <CaretUpIcon
              className={
                classNames(
                  classes.gptCaretUpIcon,
                  !isShowingAgents && 'rotate',
                )
              }
            />
          </Button>
        </Box>
      </Box>
      {isShowingAgents && (
        <Box
          className={classes.settingSectionContainer}
          flex={0}
          flexBasis={280}
        >
          <div>
            {(availableAgents || [])
              .map((agent) => (
                <Box key={agent.name} marginTop={1}>
                  <Box display="flex" alignItems="center" columnGap={2}>
                    <Box width="100%">
                      <FormControlLabel
                        {...commonProps.formControlLabel({ color: 'tertiary' })}
                        classes={{
                          root: classes.gptModelLabel,
                          label: classNames(
                            classes.gptModelLabel,
                            agent.value === settings.agent && 'selected',
                          ),
                        }}
                        onChange={(_, checked) => {
                          if (!checked) {
                            return;
                          }

                          updateSettings({
                            settings: {
                              ...settings,
                              agent: agent.value,
                            },
                          });
                        }}
                        control={(
                          <Radio
                            checked={agent.value === settings.agent}
                            style={{ marginLeft: 16 }}
                          />
                        )}
                        label={(
                          <Box marginLeft={1} display="flex" alignItems="center" height={40}>
                            <Grid container>
                              <Grid item container xs={!isSmall ? 5 : 4.5} alignItems="center">
                                <Typography className="modelName" variant="body1">
                                  <Box component="span" marginRight={1}>
                                    {agent.name}
                                  </Box>
                                </Typography>
                              </Grid>
                              <Grid item container xs={!isSmall ? 4 : 7.5} alignItems="center">
                                <Typography className="modelOwner" variant="body1" noWrap>
                                  {agent.description}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                      />
                      {(agent.options || []).map((option) => (
                        <FormControlLabel
                          key={option.name}
                          {...commonProps.formControlLabel({ color: 'tertiary' })}
                          classes={{
                            root: classes.gptModelOptionLabel,
                            label: classNames(
                              classes.gptModelOptionLabel,
                            ),
                          }}
                          onChange={(_, checked) => option.onChange(checked)}
                          control={(
                            <Checkbox
                              size="small"
                              checked={option.value}
                              style={{ marginLeft: 56 }}
                            />
                          )}
                          label={option.name}
                        />
                      ))}
                    </Box>
                  </Box>
                  <Box marginTop={1}>
                    <Divider />
                  </Box>
                </Box>
              ))}
          </div>
        </Box>
      )}

      <Box className={classes.expander} marginBottom={1} marginTop={2}>
        <Box
          className={classNames(
            classes.expanderContent,
            isShowingModels && 'highlight',
          )}
        >
          <Button
            variant="text"
            color="info"
            onClick={() => {
              toggleModels();
            }}
            fullWidth
          >
            <Box className={classes.sectionHeader} marginLeft={0.5}>
              MODEL
              <Box className={classes.sectionHeaderAdditional}>
                {getSelectedModelText()}
              </Box>
            </Box>
            <CaretUpIcon
              className={
                classNames(
                  classes.gptCaretUpIcon,
                  !isShowingModels && 'rotate',
                )
              }
            />
          </Button>
        </Box>
      </Box>
      {isShowingModels && (
        <>
          <Box display="flex" alignItems="center" columnGap={2} marginTop={2} flex={0}>
            <Typography className={classes.label} variant="body2" flex="0">
              Temperature
            </Typography>
            <Box flex="1">
              <ReactSlider
                className={classes.temperatureSlider}
                min={0}
                max={1}
                step={0.1}
                value={settings.temperature}
                onChange={(val: number) => {
                  updateSettings({
                    settings: {
                      ...settings,
                      temperature: val,
                    },
                  });
                }}
                renderTrack={(
                  props: HTMLProps<HTMLDivElement>,
                ) => (
                  <div
                    {...props}
                    className={
                      classNames(
                        commonCss.sliders.labeledSlider.track,
                        'tertiary',
                        'thin',
                      )
                    }
                    style={{
                      ...props.style,
                    }}
                    onClick={(event) => event.preventDefault()}
                  />
                )}
                renderThumb={(
                  props: HTMLProps<HTMLDivElement>,
                  state: { valueNow: number },
                ) => (
                  <div
                    {...props}
                    className={
                      classNames(
                        commonCss.sliders.labeledSlider.thumb,
                        'tertiary',
                        'thin',
                        'outline',
                      )
                    }
                    style={{
                      ...props.style,
                    }}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {state.valueNow}
                  </div>
                )}
              />
            </Box>
          </Box>
          <Box marginTop={2} flex={0}>
            <Divider />
          </Box>
          <Box
            className={classes.settingSectionContainer}
          >
            <div>
              {(gptModels || [])
                .filter((model) => (
                  model.permLevel <= (userExtraInfo?.results.at(0)?.permLevel || 0)
                  && model.active
                ))
                .map((model) => (
                  <Box key={model.modelInput} marginTop={1}>
                    <Box display="flex" alignItems="center" columnGap={2}>
                      <FormControlLabel
                        {...commonProps.formControlLabel({ color: 'tertiary' })}
                        classes={{
                          root: classes.gptModelLabel,
                          label: classNames(
                            classes.gptModelLabel,
                            model.modelInput === settings.gptModelInput && 'selected',
                          ),
                        }}
                        onChange={(_, checked) => {
                          if (!checked) {
                            return;
                          }

                          updateSettings({
                            settings: {
                              ...settings,
                              gptModelInput: model.modelInput,
                              gptModelName: model.modelName,
                            },
                          });
                        }}
                        control={(
                          <Radio
                            checked={model.modelInput === settings.gptModelInput}
                            style={{ marginLeft: 16 }}
                          />
                        )}
                        label={(
                          <Box marginLeft={1} display="flex" alignItems="center" height={40}>
                            <Grid container>
                              <Grid item container xs={!isSmall ? 5 : 4.5} alignItems="center">
                                <Typography className="modelName" variant="body1">
                                  <Box component="span" marginRight={1}>
                                    {model.modelName}
                                  </Box>
                                  {model.suffixName && (
                                    <Chip size="small" className={classes.modelSuffixBadge} label={model.suffixName} />
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item container xs={!isSmall ? 4 : 3.5} alignItems="center">
                                <Typography className="modelOwner" variant="body1" noWrap>
                                  {model.owner}
                                </Typography>
                              </Grid>
                              <Grid item container xs={!isSmall ? 3 : 2} alignItems="center">
                                {model.tokenPerSec && (
                                  <Typography className="modelRate" variant="body1" noWrap>
                                    {`${model.tokenPerSec} t/s`}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                      />
                    </Box>
                    <Box marginTop={1}>
                      <Divider />
                    </Box>
                  </Box>
                ))}
            </div>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SettingsView;
