import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';

import {
  setSearchKeyword,
} from '../actions';

import MarketItemCard from './MarketItemCard';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.search,
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    setSearchKeyword,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MarketItemCard);
