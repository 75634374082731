import { createReducer } from '@reduxjs/toolkit';
import { differenceInSeconds, format } from 'date-fns';
import { jsonrepair } from 'jsonrepair';
import { v4 } from 'uuid';
import arrayShuffle from 'array-shuffle';
import { QuickChartInfo } from '../../../core/types/quickChart';
import { assignToIndex } from '../../../core/utils/dataUtils';
import {
  requestChatResponse,
  setChatResponse,
  requestChatClean,
  setChatClean,
  toggleChatItemStep,
  setChatStreaming,
  setChatHistory,
  requestChatHistory,
  applyChatHistory,
  requestSiteExamples,
  setSiteExamples,
  requestChatPrompts,
  setChatPrompts,
  openSourceBubble,
  closeSourceBubble,
  startExecuteTasks,
  setTasksExecuted,
  cancelChatRequest,
  setChatItemTasks,
  prepareDeepSearchTasks,
  requestTaskerExecute,
  setTaskerExecuted,
  updateLastObjective,
  requestTaskTemplates,
  setTaskTemplates,
  applyTaskTemplate,
  requestDeleteTaskTemplate,
  requestCreateTaskTemplate,
  requestUpdateTaskTemplate,
  toggleChatItemTable,
  toggleChatItemSource,
  duplicateChatItemChart,
  requestUpdateChatItemChart,
  setChatItemChartUpdated,
  undoChatItemChartChange,
  toggleChatItemOrgs,
  removeChatItemChart,
  requestTableToChart,
  setTableToChart,
  requestDeleteChatHistory,
  setChatHistoryDeleted,
  requestGptModels,
  setGptModels,
  updateSettings,
  setSideBarOpen,
  applyChatOutputsCompare,
  selectChatResponseModel,
  updatePlanExecTask,
  updatePlanExec,
  setShowingToolsAtHome,
  toggleChatItemFaqs,
  requestFollowUpQuestions,
  setFollowUpQuestions,
  toggleSourceCitation,
  setSelectedSideBarTab,
  requestJinaContent,
  setJinaContent,
  setShowingGuidesAtHome,
  requestLocalSource,
  setLocalSource,
} from './actions';

import {
  createChatStepInfos,
  extractFinalAnswer,
  extractIntermediateStepsObjectives,
  extractIntermediateStepsSources,
  extractStepInfosFiles,
  extractStepInfosOrgs,
  getIntermediateStepOutput,
  getSiteExampleGroupIndexes,
  getSourceInfo,
  parseFinalAnswerByAgent,
  parseIntermediateSteps,
  extractIntermediateSteps,
  parseStructuredAnswerByAgent,
  getChatHistoryStructuredAnswer,
  getCleanPlanTaskString,
  getChatHistoryFinishedTask,
} from './gptUtils';

import {
  ChatHistoryItem,
  ChatStepInfo,
  GptChatItem,
  GptChatModelSelection,
  GptState,
  GptTaskItem,
  GptTool,
  IntermediateStep,
  IntermediateStepHeader,
  PlanAndExecuteInfo,
  PlanAndExecuteTask,
  SiteExampleGroup,
  SiteExampleItem,
} from './types';

const initialState: GptState = {
  networkStates: {
    chatResponseRequest: { isRequesting: false },
    chatCleanRequest: { isRequesting: false },
    chatHistoryRequest: { isRequesting: false },
    chatHistoryDeleteRequest: { isRequesting: false },
    chatPromptsRequest: { isRequesting: false },
    chatPromptCreateRequest: { isRequesting: false },
    chatPromptUpdateRequest: { isRequesting: false },
    chatPromptDeleteRequest: { isRequesting: false },
    siteExamplesRequest: { isRequesting: false },
    taskerExecuteRequest: { isRequesting: false },
    taskTemplatesRequest: { isRequesting: false },
    chatItemChartUpdateRequest: { isRequesting: false },
    tableToChartRequest: { isRequesting: false },
    gptModelsRequest: { isRequesting: false },
    followUpQuestionsRequest: { isRequesting: false },
    jinaContentRequest: { isRequesting: false },
    localSourceRequest: { isRequesting: false },
  },
  chats: [],
  settings: {
    temperature: 0,
    agent: 'react',
    showPlan: false,
  },
  isSideBarOpen: false,
  isSideBarClosedOnce: false,
  isShowingToolsAtHome: false,
  isShowingGuidesAtHome: false,
  selectedSideBarTab: 'settings',
};

const getQuickChartResponse = (chatResponse: Object, key: string) => {
  const chartInfo = (chatResponse as unknown as Record<string, QuickChartInfo>)[key];

  if (typeof chartInfo === 'object') {
    return chartInfo;
  }

  return undefined;
};

const gptReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(requestChatResponse, (state, action) => {
      if (action.payload.isCompare) {
        return ({
          ...state,
          networkStates: {
            ...state.networkStates,
            chatResponseRequest: {
              isRequesting: true,
            },
          },
          chats: [
            ...state.chats.slice(0, state.chats.length - 1),
            {
              type: 'bot',
              isProcessing: true,
              mode: action.payload.mode,
              agent: state.settings.agent,
            },
          ],
          chatState: 'requesting',
        });
      }

      const updatedChats = state.chats.map((chat, index) => {
        if (index < state.chats.length - 1
          || !action.payload.isExecutePlan
          || !chat.structuredContent) {
          return chat;
        }

        return {
          ...chat,
          structuredContent: {
            ...chat.structuredContent,
            structured: JSON.parse(action.payload.input) as PlanAndExecuteInfo,
          },
        };
      }) as GptChatItem[];

      return ({
        ...state,
        networkStates: {
          ...state.networkStates,
          chatResponseRequest: {
            isRequesting: true,
          },
        },
        chats: [
          ...updatedChats,
          {
            content: action.payload.input,
            type: 'user',
            mode: action.payload.mode,
          },
          {
            type: 'bot',
            isProcessing: true,
            mode: action.payload.mode,
            agent: state.settings.agent,
          },
        ],
        chatState: 'requesting',
      });
    });

  builder
    .addCase(setChatResponse, (state, action) => {
      if (!action.payload.response) {
        return {
          ...state,
          networkStates: {
            ...state.networkStates,
            chatResponseRequest: {
              isRequesting: false,
              lastError: action.payload.error,
            },
          },
          chats: state.chats.map((chat) => ({
            ...chat,
            isProcessing: false,
          })),
        };
      }

      const chatResponse = action.payload.response;
      const output = chatResponse?.output?.includes('\n\n;')
        ? chatResponse?.output.split('\n\n;')[1]
        : chatResponse?.output;

      const mode = chatResponse?.mode;

      const chats = state.chats.length > 0
        ? state.chats.slice(0, state.chats.length - 1)
        : state.chats;

      const lastChat = state.chats.length > 0
        ? state.chats[state.chats.length - 1]
        : undefined;

      const lastChatSteps = [...(lastChat?.steps || [])];

      const updatedSteps = (lastChatSteps.length > 0 && chatResponse?.intermediateSteps)
        ? lastChatSteps.filter((s) => s.items.length > 0).map((step, index) => {
          const intermediateStep = chatResponse.intermediateSteps[index];
          const actualOutput = getIntermediateStepOutput(intermediateStep);
          const alreadyHasOutput = !!step.items.find((item) => item.name === 'Output');

          const potentialAttachments = intermediateStep?.at(1);
          const attachments = potentialAttachments && typeof potentialAttachments === 'object'
            ? [potentialAttachments as unknown as Record<string, string>]
            : undefined;

          return {
            ...step,
            items: [
              ...step.items,
              ...(!alreadyHasOutput && actualOutput) ? [{
                name: 'Output',
                value: actualOutput,
              }] : [],
            ].filter((item) => !(
              item.name === 'Action'
              && item.value.startsWith('None')
            )),
            attachments,
          } as unknown as ChatStepInfo;
        })
        : lastChatSteps;

      // We need files from tasks so extends updatedSteps with the tasks file output if presented.
      (chatResponse?.intermediateSteps || []).forEach((intermediateStep: IntermediateStep) => {
        const header = intermediateStep.at(0) as unknown as IntermediateStepHeader;
        if (!header.task) {
          return;
        }

        const existingStep = updatedSteps.find((updatedStep) => updatedStep.items
          .find((item) => item.name === 'task' && item.value === header.task));

        const potentialFile = intermediateStep.at(1);

        if (typeof potentialFile === 'string'
          && (
            potentialFile.startsWith('[')
            || potentialFile.startsWith('{')
            || potentialFile.startsWith('"[')
            || potentialFile.startsWith('"{')
          )
        ) {
          return;
        }

        const attachments = [{
          [`${header.tool} ${format(new Date(), 'yyyy-MM-dd')}`]: potentialFile,
        }] as Record<string, string>[];

        const items = [{
          name: 'task',
          value: header.task,
        }, {
          name: 'Output',
          value: header.toolOutput || '',
        }, {
          name: 'Action',
          value: header.tool,
        }];

        if (!existingStep) {
          updatedSteps.push({
            items,
            attachments,
            isPlanExecuteTask: true,
          });
        } else {
          existingStep.attachments = attachments;
          existingStep.items = items;
          existingStep.isPlanExecuteTask = true;
        }
      });

      const files = extractStepInfosFiles(updatedSteps);
      const sources = extractIntermediateStepsSources(chatResponse?.intermediateSteps || []);
      const objectives = extractIntermediateStepsObjectives(chatResponse?.intermediateSteps || []);
      const orgs = chatResponse?.orgs || extractStepInfosOrgs(updatedSteps);

      const inlineCharts = Object.keys(chatResponse || {})
        .filter((key) => key.startsWith('inlineGraph'))
        .map((key) => getQuickChartResponse(chatResponse, key))
        .filter((chart) => !!chart)
        .map((chart) => [chart]);

      const attachedCharts = Object.keys(chatResponse || {})
        .filter((key) => key.startsWith('attachedGraph'))
        .map((key) => getQuickChartResponse(chatResponse, key))
        .filter((chart) => !!chart)
        .map((chart) => [chart]);

      const lastChatItemData = {
        ...lastChat,
        steps: updatedSteps,
        files,
        content: output,
        sources,
        objectives,
        type: 'bot',
        error: action?.payload.error,
        isShowingSteps: action.payload.response.needPlan,
        isProcessing: false,
        mode,
        orgs,
        inlineCharts,
        attachedCharts,
        llmModel: action.payload.llmModel,
        agent: state.settings.agent,
        exception: action.payload.response.exception,
        needPlan: action.payload.response.needPlan,
        isShowingFaqs: true,
      } as GptChatItem;

      const llmChatItemsByIndexes = (
        state.llmChatItemsByIndexes && action.payload.llmModel
          ? {
            ...state.llmChatItemsByIndexes,
            [state.chats.length - 1]: {
              ...state.llmChatItemsByIndexes[state.chats.length - 1] || {},
              chatByEngines: (
                state.llmChatItemsByIndexes[state.chats.length - 1]?.chatByEngines || []
              ).map((chatByEngine, index) => {
                const chatByEngines = (
                  state.llmChatItemsByIndexes?.[state.chats.length - 1]?.chatByEngines || []
                );
                if (index < chatByEngines.length - 1) {
                  return chatByEngine;
                }

                return {
                  llm: action.payload.llmModel,
                  isStreaming: false,
                  chat: lastChatItemData,
                };
              }),
            },
          }
          : undefined
      ) as Record<number, GptChatModelSelection>;

      return ({
        ...state,
        networkStates: {
          ...state.networkStates,
          chatResponseRequest: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
        chats: [
          ...chats.map((chat, idx) => {
            if (idx < chats.length - 1) {
              if (action.payload.response?.isExecutePlan) {
                return {
                  ...chat,
                  isShowingSteps: false,
                };
              }

              return chat;
            }

            return {
              ...chat,
              mode,
            };
          }),
          lastChatItemData,
        ],
        chatStreaming: undefined,
        chatState: 'ends',
        llmChatItemsByIndexes,
      });
    });

  builder
    .addCase(requestChatClean, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        chatCleanRequest: {
          isRequesting: true,
        },
      },
      chatId: v4(),
      chatSessionId: undefined,
      chats: [],
      chatState: undefined,
      llmChatItemsByIndexes: undefined,
      isShowingToolsAtHome: false,
      sourceCitation: undefined,
    }))
    .addCase(setChatClean, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        chatCleanRequest: {
          isRequesting: false,
        },
      },
    }));

  builder
    .addCase(toggleChatItemStep, (state, action) => {
      const updatedChats = state.chats.map((c, i) => {
        if (i === action.payload.chatItemIndex) {
          return {
            ...c,
            isShowingSteps: action.payload.isShowing,
          };
        }

        return c;
      });

      return {
        ...state,
        chats: updatedChats,
        chatState: 'editing',
      };
    });

  builder
    .addCase(toggleChatItemTable, (state, action) => {
      const updatedChats = state.chats.map((c, i) => {
        if (i === action.payload.chatItemIndex) {
          return {
            ...c,
            isShowingTables: action.payload.isShowing,
          };
        }

        return c;
      });

      return {
        ...state,
        chats: updatedChats,
        chatState: 'editing',
      };
    });

  builder
    .addCase(toggleChatItemSource, (state, action) => {
      const updatedChats = state.chats.map((c, i) => {
        if (i === action.payload.chatItemIndex) {
          return {
            ...c,
            isShowingSources: action.payload.isShowing,
          };
        }

        return c;
      });

      return {
        ...state,
        chats: updatedChats,
        chatState: 'editing',
      };
    });

  builder
    .addCase(toggleChatItemFaqs, (state, action) => {
      const updatedChats = state.chats.map((c, i) => {
        if (i === action.payload.chatItemIndex) {
          return {
            ...c,
            isShowingFaqs: action.payload.isShowing,
          };
        }

        return c;
      });

      return {
        ...state,
        chats: updatedChats,
        chatState: 'editing',
      };
    });

  builder
    .addCase(toggleChatItemOrgs, (state, action) => {
      const updatedChats = state.chats.map((c, i) => {
        if (i === action.payload.chatItemIndex) {
          return {
            ...c,
            isShowingOrgs: action.payload.isShowing,
          };
        }

        return c;
      });

      return {
        ...state,
        chats: updatedChats,
        chatState: 'editing',
      };
    });

  builder
    .addCase(duplicateChatItemChart, (state, action) => {
      const {
        chartType,
        chatItemIndex,
        chartIndex,
        chartSubIndex,
        chartInfo,
      } = action.payload;

      const updatedChats = state.chats.map((chatItem, i) => {
        if (i === chatItemIndex && chartType === 'inline') {
          return {
            ...chatItem,
            inlineCharts: chatItem.inlineCharts?.map((chartInfos, ii) => {
              if (ii === chartIndex) {
                if (!chartInfos) {
                  return [];
                }

                const chart = chartInfo ?? {
                  ...chartInfos[chartSubIndex] as QuickChartInfo,
                  prevChartInfo: undefined,
                };

                return [
                  ...chartInfos.slice(0, chartSubIndex + 1),
                  chart,
                  ...chartInfos.slice(chartSubIndex + 1),
                ];
              }

              return chartInfos;
            }),
          };
        }

        if (i === chatItemIndex && chartType === 'attached') {
          return {
            ...chatItem,
            attachedCharts: chatItem.attachedCharts?.map((chartInfos, ii) => {
              if (ii === chartIndex) {
                if (!chartInfos) {
                  return [];
                }

                const chart = {
                  ...chartInfos[chartSubIndex] as QuickChartInfo,
                  prevChartInfo: undefined,
                };

                return [
                  ...chartInfos.slice(0, chartSubIndex + 1),
                  chart,
                  ...chartInfos.slice(chartSubIndex + 1),
                ];
              }

              return chartInfos;
            }),
          };
        }

        return chatItem;
      });

      return {
        ...state,
        networkStates: {
          ...state.networkStates,
          tableToChartRequest: {
            ...state.networkStates.tableToChartRequest,
            isRequesting: false,
          },
        },
        chats: updatedChats,
      };
    });

  builder
    .addCase(removeChatItemChart, (state, action) => {
      const {
        chartType,
        chatItemIndex,
        chartIndex,
        chartSubIndex,
      } = action.payload;

      const updatedChats = state.chats.map((chatItem, i) => {
        if (i === chatItemIndex && chartType === 'inline') {
          return {
            ...chatItem,
            inlineCharts: chatItem
              .inlineCharts?.map((chartInfos, ii) => {
                if (ii === chartIndex) {
                  if (!chartInfos) {
                    return [];
                  }

                  return chartInfos.filter((_, iii) => chartSubIndex !== iii);
                }

                return chartInfos;
              }),
          };
        }

        if (i === chatItemIndex && chartType === 'attached') {
          return {
            ...chatItem,
            attachedCharts: chatItem
              .attachedCharts?.map((chartInfos, ii) => {
                if (ii === chartIndex) {
                  if (!chartInfos) {
                    return [];
                  }

                  return chartInfos.filter((_, iii) => chartSubIndex !== iii);
                }

                return chartInfos;
              }),
          };
        }

        return chatItem;
      });

      return {
        ...state,
        chats: updatedChats,
      };
    });

  builder
    .addCase(requestChatHistory, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        chatHistoryRequest: {
          isRequesting: true,
        },
      },
    }));

  builder
    .addCase(setChatHistory, (state, action) => {
      const subItems = action.payload.items || [];
      const conversations: ChatHistoryItem[] = [];

      subItems.forEach((subItem) => {
        const rootConversation = conversations
          .find((c) => c.id === subItem.conversationId);

        if (!rootConversation) {
          const conversation = {
            id: subItem.conversationId,
            latestChatItemId: 0,
            subItems: [],
          } as ChatHistoryItem;

          conversations.push(conversation);
        }

        const finalConversation = conversations
          .find((c) => c.id === subItem.conversationId);

        finalConversation?.subItems.push(subItem);

        if (finalConversation) {
          finalConversation.latestChatItemId = subItem.id;
          finalConversation.updated = subItem.updated;
        }
      });

      return {
        ...state,
        networkStates: {
          ...state.networkStates,
          chatHistoryRequest: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
        chatHistoryItems: conversations.sort((a, b) => b.latestChatItemId - a.latestChatItemId),
      };
    });

  builder
    .addCase(requestDeleteChatHistory, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        chatHistoryDeleteRequest: {
          isRequesting: true,
        },
      },
    }));

  builder
    .addCase(setChatHistoryDeleted, (state, action) => {
      const { conversationId } = action.payload;

      return ({
        ...state,
        networkStates: {
          ...state.networkStates,
          chatHistoryDeleteRequest: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
        chatHistoryItems: !action.payload.error ? (
          state.chatHistoryItems?.filter((item) => item.id !== conversationId)
        ) : (
          state.chatHistoryItems
        ),
      });
    });

  builder
    .addCase(requestChatPrompts, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        chatPromptsRequest: {
          isRequesting: true,
        },
      },
    }));

  builder
    .addCase(setChatPrompts, (state, action) => {
      const { promptResponse } = action.payload;
      return {
        ...state,
        networkStates: {
          ...state.networkStates,
          chatPromptsRequest: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
        chatPromptItems: promptResponse?.results,
      };
    });

  builder
    .addCase(applyChatHistory, (state, action) => {
      const { items } = action.payload;
      const chats = [] as GptChatItem[];

      let llmChatItemsByIndexes = undefined as unknown as Record<number, GptChatModelSelection>;

      items.forEach((item, index) => {
        const questionChat = {
          type: 'user',
          content: item.inputText,
        } as GptChatItem;

        if (!item.compareModels) {
          chats.push(questionChat);
        }

        const chatAnswerJson = jsonrepair(item.outputText);
        const chatAnswer = JSON.parse(chatAnswerJson) as Record<string, unknown>;

        const isPlanExecute = item.agent === 'plan_execute';
        const isPlanExecutePlan = (isPlanExecute && item.needPlan);
        const finalAnswer = !isPlanExecutePlan
          ? extractFinalAnswer(chatAnswer)
          : undefined;

        const structuredAnswer = isPlanExecute
          ? getChatHistoryStructuredAnswer(chatAnswer)
          : undefined;

        const finishedTasks = isPlanExecute
          ? getChatHistoryFinishedTask(chatAnswer)
          : undefined;

        const intermediateSteps = extractIntermediateSteps(chatAnswer, item.agent);
        const steps = intermediateSteps && parseIntermediateSteps(intermediateSteps, item.agent);

        const files = steps && extractStepInfosFiles(steps);
        const sources = intermediateSteps && extractIntermediateStepsSources(intermediateSteps);

        const orgs = steps && extractStepInfosOrgs(steps);

        const answerChat = {
          type: 'bot',
          content: finalAnswer,
          structuredContent: structuredAnswer,
          llmModel: item.llmModel,
          agent: item.agent,
          steps,
          sources,
          files,
          orgs,
          exception: chatAnswer.exception,
          isShowingSteps: item.needPlan && !items.at(index + 1)?.outputText,
          needPlan: item.needPlan,
          finishedTasks,
          isShowingFaqs: true,
        } as GptChatItem;

        if (item.compareModels && chats.length > 1) {
          if (!llmChatItemsByIndexes) {
            llmChatItemsByIndexes = {} as Record<number, GptChatModelSelection>;
          }

          if (!llmChatItemsByIndexes[chats.length - 1]) {
            const prevChat = chats[chats.length - 1];

            llmChatItemsByIndexes[chats.length - 1] = {
              selectedModel: prevChat?.llmModel || 'Unknown',
              chatByEngines: [{
                isStreaming: false,
                llm: prevChat?.llmModel || 'Unknown',
                agent: item.agent,
                chat: prevChat,
              }],
            };
          }

          llmChatItemsByIndexes[chats.length - 1].chatByEngines.push({
            isStreaming: false,
            llm: item.llmModel || 'Unknown',
            agent: item.agent,
            chat: answerChat,
          });

          return;
        }

        chats.push(answerChat);
      });

      return {
        ...state,
        chatId: String(action.payload.id),
        chatSessionId: action.payload.id,
        chats,
        chatState: undefined,
        currentTasks: undefined,
        chatStreaming: undefined,
        llmChatItemsByIndexes,
        sourceCitation: undefined,
      };
    });

  builder.addCase(setChatStreaming, (state, action) => {
    const chats = state.chats.length > 0
      ? state.chats.slice(0, state.chats.length - 1)
      : state.chats;

    const lastChat = (
      state.chats.length > 0
        ? state.chats[state.chats.length - 1]
        : undefined
    ) as GptChatItem;

    if (!lastChat) {
      return state;
    }

    const currentChatStreaming = state.chatStreaming || '';
    const stepInfos = createChatStepInfos(currentChatStreaming, action.payload.agent);
    const accumulateStreaming = `${currentChatStreaming}${action.payload.data || ''}`;

    // Remove final answer from each steps:
    const finalStepInfos = stepInfos.map((stepInfo) => {
      const faItem = stepInfo.items?.find((item) => item.name === 'Answer');
      const fa = faItem?.value;

      if (fa) {
        stepInfo.items.pop();
      }

      return stepInfo;
    });

    const finalAnswer = parseFinalAnswerByAgent(action.payload.agent, currentChatStreaming);

    const structuredAnswer = parseStructuredAnswerByAgent(
      action.payload.agent,
      currentChatStreaming,
    );

    const givenFinishedTasks = (action.payload.finishedTasks || []);
    const currentFinishedTasks = [...lastChat.finishedTasks || []];
    const isPlanExecuteFinishedAllTasks = givenFinishedTasks.length > 0 && (
      givenFinishedTasks.every((finishedTask) => (
        currentFinishedTasks.find((t) => t.task === finishedTask.task)
      ))
    );

    const executingTasks = (
      JSON.parse(JSON.stringify([...lastChat.executingTasks || []]))
    ) as PlanAndExecuteTask[];

    (action.payload.executingTasks || []).forEach((task) => {
      executingTasks.push(task);
    });

    const finishedTasks = (
      JSON.parse(JSON.stringify([...lastChat.finishedTasks || []]))
    ) as PlanAndExecuteTask[];

    (action.payload.finishedTasks || []).forEach((task) => {
      const existing = finishedTasks.find(
        (t) => t.task === task.task
          && t.step_hierarchy === task.step_hierarchy
          && t.tool_input === task.tool_input,
      );

      if (!existing) {
        finishedTasks.push(task);
      } else {
        existing.originalTaskIndex = task.originalTaskIndex;
        existing.step_hierarchy = task.step_hierarchy;
        existing.task = task.task;
        existing.tool = task.tool;
        existing.tool_input = task.tool_input;
        existing.tool_output = task.tool_output;
      }
    });

    const shouldShowStep = () => {
      if (lastChat.isShowingSteps === undefined) {
        return true;
      }

      if (lastChat.agent === 'plan_execute') {
        if (lastChat.isShowingSteps) {
          return !isPlanExecuteFinishedAllTasks;
        }

        return false;
      }

      return true;
    };

    return {
      ...state,
      chatSessionId: action.payload.chatSessionId,
      chats: [
        ...chats.map((chat) => ({
          ...chat,
          isShowingSteps: shouldShowStep(),
          isShowingFaqs: true,
        })),
        {
          ...lastChat,
          steps: finalStepInfos,
          isShowingSteps: shouldShowStep(),
          content: finalAnswer,
          structuredContent: structuredAnswer,
          exception: action.payload.exception,
          needPlan: action.payload.needPlan,
          executingTasks,
          finishedTasks,
          isShowingFaqs: true,
        },
      ],
      chatStreaming: accumulateStreaming,
      chatState: 'streaming',
    };
  });

  builder
    .addCase(requestSiteExamples, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        siteExamplesRequest: {
          isRequesting: true,
        },
      },
    }));

  builder
    .addCase(setSiteExamples, (state, action) => {
      const { siteExamplesResponse } = action.payload;
      const siteExampleGroups: SiteExampleGroup[] = [];

      const siteExamples: SiteExampleItem[] = siteExamplesResponse?.results || [];
      const availableTools: GptTool[] = [];

      siteExamples
        .filter((siteExample) => siteExample.sitetoolName !== 'Homepage')
        .forEach((siteExample) => {
          if (siteExample.category === 'Autonomous Agents') {
            return;
          }

          let group = siteExampleGroups
            .find((g) => g.groupName === siteExample.category);

          if (!group) {
            group = {
              groupName: siteExample.category,
              dataSets: [],
              layout: siteExample.category === 'Autonomous Agents' ? 12 : 6,
            };

            siteExampleGroups.push(group);
          }

          const siteToolName = siteExample.sitetoolName.replace('(unavailable)', '').trim();
          const siteIcon = siteExample.icon ? `https://www.pvalyou.com/media/imgs/${siteExample.icon}` : undefined;

          let ds = group.dataSets
            .find((d) => d.dataSetName === siteToolName);

          if (!ds) {
            ds = {
              dataSetName: siteToolName,
              description: siteExample.sitetoolDesc,
              examples: [],
              icon: siteIcon,
              active: siteExample.active,
            };

            group.dataSets.push(ds);
          }

          ds.examples.push(siteExample.example);

          // Non: temporary construct availableTools to be chosen on plan and execute.
          if ((
            siteExample.category === 'Autonomous Agents'
            || siteExample.category === 'My Tools'
            || siteToolName === 'Founder Dataset'
            || siteToolName === 'Industry Statistics'
            || siteToolName === 'Post Mortem'
            || siteToolName === 'Plot Graph'
            || siteToolName === 'Email'
            || siteToolName === 'Homepage'
          )) {
            return;
          }

          if (!availableTools.find((tool) => tool.name === siteToolName)) {
            let sortOrder = 0;
            if (siteToolName === 'Search Online') {
              sortOrder = -100;
            }

            if (siteToolName === 'Crawl URL') {
              sortOrder = -99;
            }

            availableTools.push({
              name: siteToolName,
              description: siteExample.sitetoolDesc,
              icon: siteIcon,
              sortOrder,
            });
          }
        });

      const sortedAvailableTools = availableTools.sort((a, b) => {
        if (a.sortOrder || b.sortOrder) {
          return (a.sortOrder || 0) - (b.sortOrder || 0);
        }

        return a.name.localeCompare(b.name);
      });

      const siteExGroupIndexes = getSiteExampleGroupIndexes();

      return {
        ...state,
        networkStates: {
          ...state.networkStates,
          chatHistoryRequest: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
        siteExampleItems: siteExamplesResponse?.results,
        siteExampleGroups: siteExampleGroups.sort((a, b) => (
          siteExGroupIndexes.findIndex((t) => t === a.groupName)
            - siteExGroupIndexes.findIndex((t) => t === b.groupName)
        )),
        availableTools: sortedAvailableTools,
        homePageExamples: arrayShuffle(siteExamples
          .filter((siteExample) => siteExample.sitetoolName === 'Homepage')),
      };
    });

  builder
    .addCase(openSourceBubble, (state, action) => ({
      ...state,
      sourceBubble: {
        id: action.payload.id,
        isOpen: true,
        sourceIndex: action.payload.sourceIndex,
        sourceInfo: action.payload.sourceInfo,
        originX: action.payload.originX,
        originY: action.payload.originY,
      },
    }));

  builder
    .addCase(closeSourceBubble, (state) => ({
      ...state,
      sourceBubble: undefined,
    }));

  builder
    .addCase(startExecuteTasks, (state, action) => ({
      ...state,
      tasksExecution: {
        isExecuting: true,
        currentIndex: 0,
        tasks: action.payload.tasks,
        startTime: new Date(),
      },
    }));

  builder
    .addCase(setChatItemTasks, (state, action) => ({
      ...state,
      chats: state.chats.map((chat, index) => (
        index === action.payload.chatIndex
          ? {
            ...chat,
            tasks: action.payload.tasks,
          }
          : chat
      )),
    }));

  builder
    .addCase(setTasksExecuted, (state) => ({
      ...state,
      tasksExecution: undefined,
      lastTaskExecuted: new Date(),
      lastTaskExecDurationInSeconds: (
        differenceInSeconds(
          new Date(),
          state.tasksExecution!.startTime,
        )
      ),
    }));

  builder
    .addCase(cancelChatRequest, (state) => ({
      ...state,
      tasksExecution: undefined,
      networkStates: {
        ...state.networkStates,
        chatResponseRequest: {
          isRequesting: false,
        },
      },
      chats: state.chats.map((chat) => ({
        ...chat,
        isProcessing: false,
      })),
    }));

  builder
    .addCase(prepareDeepSearchTasks, (state, action) => ({
      ...state,
      chats: [
        ...state.chats,
        {
          type: 'user',
          mode: 'task',
        }, {
          type: 'bot',
          objectives: action.payload.objectives,
          tasks: [
            ...action.payload.tasks,
            {
              id: v4(),
              value: undefined,
            },
          ],
          mode: 'customTask',
        },
      ],
      chatState: 'ends',
    }));

  builder
    .addCase(requestTaskerExecute, (state, act) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        taskerExecuteRequest: {
          isRequesting: true,
        },
      },
      lastTaskObjective: act.payload.objective,
    }));

  builder
    .addCase(setTaskerExecuted, (state, action) => {
      const { result } = action.payload;
      const evaluation = result?.evaluation;
      const summary = result?.finalSummary;
      const tasks = result?.newTasks;
      const sources = result?.summarySources.map((source) => getSourceInfo(source));
      const chats: GptChatItem[] = [
        ...state.chats,
        {
          type: 'bot',
          mode: 'summary',
          evaluation,
          summary,
          sources,
        }, tasks?.length && {
          type: 'bot',
          mode: 'task',
          tasks,
        },
      ].filter((chat) => !!chat) as GptChatItem[];

      return ({
        ...state,
        networkStates: {
          ...state.networkStates,
          taskerExecuteRequest: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
        chats,
      });
    });

  builder
    .addCase(updateLastObjective, (state, action) => ({
      ...state,
      chats: state.chats.map((chatItem, index) => {
        if (index === action.payload.index) {
          const objectives = chatItem.objectives || [];
          return {
            ...chatItem,
            objectives: [
              ...objectives.slice(0, objectives.length - 1),
              action.payload.objective,
            ],
          };
        }

        return chatItem;
      }),
      chatState: 'editing',
    }));

  builder
    .addCase(requestTaskTemplates, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        taskTemplatesRequest: {
          isRequesting: true,
        },
      },
    }));

  builder
    .addCase(setTaskTemplates, (state, action) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        taskTemplatesRequest: {
          isRequesting: false,
          lastError: action.payload.error,
        },
      },
      taskTemplates: action.payload.templates,
    }));

  builder
    .addCase(applyTaskTemplate, (state, action) => {
      if ((state.chats || []).length === 0) {
        return {
          ...state,
          chats: [{
            type: 'bot',
            mode: 'customTask',
            objectives: [action.payload.template.objective],
            tasks: [
              ...action.payload.template.tasks.map((task) => ({
                id: v4(),
                value: task.replace(/^[0-9]+.\s/g, ''),
              }) as GptTaskItem),
              {
                id: v4(),
              },
            ],
          }],
        };
      }

      return ({
        ...state,
        chats: state.chats.map((chat, index) => {
          if (index === action.payload.chatIndex) {
            return {
              ...chat,
              objectives: [action.payload.template.objective],
              tasks: action.payload.template.tasks.map((task) => ({
                id: v4(),
                value: task.replace(/^[0-9]+.\s/g, ''),
              }) as GptTaskItem),
            };
          }

          return chat;
        }),
      });
    });

  builder
    .addCase(requestCreateTaskTemplate, (state, action) => ({
      ...state,
      taskTemplates: [
        ...state.taskTemplates || [],
        {
          ...action.payload.template,
          id: v4(),
        },
      ],
    }));

  builder
    .addCase(requestUpdateTaskTemplate, (state, action) => ({
      ...state,
      taskTemplates: (state.taskTemplates || []).map((template) => {
        if (template.id === action.payload.template.id) {
          return action.payload.template;
        }

        return template;
      }),
    }));

  builder
    .addCase(requestDeleteTaskTemplate, (state, action) => ({
      ...state,
      taskTemplates: state.taskTemplates?.filter(
        (taskTemplate) => taskTemplate.tag !== action.payload.template.tag,
      ),
    }));

  builder
    .addCase(requestUpdateChatItemChart, (state, action) => {
      const {
        chatItemIndex,
        chartType,
        chartIndex,
        chartSubIndex,
        chartModifyInfo,
      } = action.payload;

      const updatedChats = state.chats.map((chatItem, i) => {
        if (i === chatItemIndex && chartType === 'inline') {
          return {
            ...chatItem,
            inlineCharts: chatItem.inlineCharts?.map((chartInfos, ii) => {
              if (ii === chartIndex) {
                return chartInfos.map((chartInfo, iii) => {
                  if (chartInfo && iii === chartSubIndex) {
                    return {
                      ...chartInfo,
                      graphType: chartModifyInfo.graphType ?? chartInfo.graphType,
                      selectedCategorialCol: chartModifyInfo.selectedCategorialCol,
                      selectedNumericCol: chartModifyInfo.selectedNumericCol,
                      selectedTextCol: chartModifyInfo.selectedTextCol,
                    };
                  }

                  return chartInfo;
                });
              }

              return chartInfos;
            }),
          };
        }

        if (i === chatItemIndex && chartType === 'attached') {
          return {
            ...chatItem,
            attachedCharts: chatItem.attachedCharts?.map((chartInfos, ii) => {
              if (ii === chartIndex) {
                return chartInfos.map((chartInfo, iii) => {
                  if (chartInfo && iii === chartSubIndex) {
                    return {
                      ...chartInfo,
                      selectedCategorialCol: chartModifyInfo.selectedCategorialCol,
                      selectedNumericCol: chartModifyInfo.selectedNumericCol,
                      selectedTextCol: chartModifyInfo.selectedTextCol,
                    };
                  }

                  return chartInfo;
                });
              }

              return chartInfos;
            }),
          };
        }

        return chatItem;
      });

      return ({
        ...state,
        networkStates: {
          ...state.networkStates,
          chatItemChartUpdateRequest: {
            isRequesting: true,
            index: action.payload.chatItemIndex,
            extra: {
              chartIndex: action.payload.chartIndex,
              chartSubIndex: action.payload.chartSubIndex,
            },
          },
        },
        chats: updatedChats,
      });
    });

  builder
    .addCase(setChatItemChartUpdated, (state, action) => {
      const {
        chatItemIndex,
        chartType,
        chartIndex,
        chartSubIndex,
        chartUpdateResult,
        chartModifyInfo,
      } = action.payload;

      if (!chartUpdateResult || typeof chartUpdateResult !== 'object') {
        return {
          ...state,
          networkStates: {
            ...state.networkStates,
            chatItemChartUpdateRequest: {
              isRequesting: false,
              index: action.payload.chatItemIndex,
            },
          },
        };
      }

      const updatedChats = state.chats.map((chatItem, i) => {
        if (i === chatItemIndex && chartType === 'inline') {
          return {
            ...chatItem,
            inlineCharts: chatItem.inlineCharts?.map((chartInfos, ii) => {
              if (ii === chartIndex) {
                return chartInfos.map((chartInfo, iii) => {
                  if (chartInfo && iii === chartSubIndex) {
                    return {
                      ...chartModifyInfo,
                      ...chartInfo,
                      graphId: chartUpdateResult.graphId,
                      graphInstance: chartUpdateResult.graphInstance,
                      prevChartInfo: {
                        ...chartInfo,
                      },
                    };
                  }

                  return chartInfo;
                });
              }

              return chartInfos;
            }),
          };
        }

        if (i === chatItemIndex && chartType === 'attached') {
          return {
            ...chatItem,
            attachedCharts: chatItem.attachedCharts?.map((chartInfos, ii) => {
              if (ii === chartIndex) {
                return chartInfos.map((chartInfo, iii) => {
                  if (chartInfo && iii === chartSubIndex) {
                    return {
                      ...chartModifyInfo,
                      ...chartInfo,
                      graphId: chartUpdateResult.graphId,
                      graphInstance: chartUpdateResult.graphInstance,
                      prevChartInfo: {
                        ...chartInfo,
                      },
                    };
                  }

                  return chartInfo;
                });
              }

              return chartInfos;
            }),
          };
        }

        return chatItem;
      });

      return ({
        ...state,
        networkStates: {
          ...state.networkStates,
          chatItemChartUpdateRequest: {
            isRequesting: false,
            index: action.payload.chatItemIndex,
          },
        },
        chats: updatedChats,
        chatState: 'editing',
      });
    });

  builder
    .addCase(undoChatItemChartChange, (state, action) => {
      const {
        chartType,
        chatItemIndex,
        chartIndex,
        chartSubIndex,
      } = action.payload;

      const updatedChats = state.chats.map((chatItem, i) => {
        if (i === chatItemIndex && chartType === 'inline') {
          return {
            ...chatItem,
            inlineCharts: chatItem.inlineCharts?.map((chartInfos, ii) => {
              if (ii === chartIndex) {
                return chartInfos.map((chartInfo, iii) => {
                  if (chartInfo && iii === chartSubIndex && chartInfo.prevChartInfo) {
                    return {
                      ...chartInfo.prevChartInfo,
                    };
                  }

                  return chartInfo;
                });
              }

              return chartInfos;
            }),
          };
        }

        if (i === chatItemIndex && chartType === 'attached') {
          return {
            ...chatItem,
            attachedCharts: chatItem.attachedCharts?.map((chartInfos, ii) => {
              if (ii === chartIndex) {
                return chartInfos.map((chartInfo, iii) => {
                  if (chartInfo && iii === chartSubIndex && chartInfo.prevChartInfo) {
                    return {
                      ...chartInfo.prevChartInfo,
                    };
                  }

                  return chartInfo;
                });
              }

              return chartInfos;
            }),
          };
        }

        return chatItem;
      });

      return ({
        ...state,
        chats: updatedChats,
        chatState: 'editing',
      });
    });

  builder
    .addCase(requestTableToChart, (state, action) => {
      const {
        chatItemIndex,
        chartIndex,
        isAttachment,
      } = action.payload;

      return {
        ...state,
        networkStates: {
          ...state.networkStates,
          tableToChartRequest: {
            isRequesting: true,
            index: chatItemIndex,
            extra: {
              chartIndex,
              isAttachment,
            },
          },
        },
        chatState: 'editing',
      };
    });

  builder
    .addCase(setTableToChart, (state, action) => {
      const {
        chatItemIndex,
        chartIndex,
        isAttachment,
      } = action.payload;

      return {
        ...state,
        networkStates: {
          ...state.networkStates,
          tableToChartRequest: {
            isRequesting: false,
            index: chatItemIndex,
            extra: {
              chartIndex,
            },
          },
        },
        chats: state.chats.map((chat, index) => {
          const { chartItems } = action.payload;

          if (!chartItems || chartItems.length === 0) {
            return chat;
          }

          if (index === chatItemIndex && !isAttachment) {
            let inlineCharts: (QuickChartInfo | undefined)[][] = chat.inlineCharts || [];

            chartItems.forEach((chartItem) => {
              const charts = Object.keys(chartItem || {})
                .filter((key) => key.startsWith('inlineGraph'))
                .map((key) => getQuickChartResponse(chartItem, key))
                .filter((chart) => !!chart)
                .map((chart) => ({
                  ...chart,
                  isTableChart: true,
                }));

              inlineCharts = assignToIndex(inlineCharts, chartIndex, charts);
            });

            return {
              ...chat,
              inlineCharts,
            };
          }

          if (index === chatItemIndex && isAttachment) {
            let attachedCharts: (QuickChartInfo | undefined)[][] = chat.attachedCharts || [];

            // Non: The key response from table-to-graph API is always starts with inlineGraph.
            chartItems.forEach((chartItem) => {
              const charts = Object.keys(chartItem || {})
                .filter((key) => key.startsWith('inlineGraph'))
                .map((key) => getQuickChartResponse(chartItem, key))
                .filter((chart) => !!chart)
                .map((chart) => ({
                  ...chart,
                  isTableChart: true,
                }));

              attachedCharts = assignToIndex(attachedCharts, chartIndex, charts);
            });

            return {
              ...chat,
              attachedCharts,
            };
          }

          return chat;
        }),
        chatState: 'editing',
      };
    });

  builder
    .addCase(requestGptModels, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        gptModelsRequest: {
          isRequesting: true,
        },
      },
    }));

  builder
    .addCase(setGptModels, (state, action) => {
      const { response, permLevel } = action.payload;
      const defaultModel = (response?.results || [])
        .find((model) => model.default && model.permLevel === permLevel);

      const defaultPremiumModel = (response?.results || [])
        .find((model) => model.default && model.permLevel === 3);

      // 'microsoft/wizardlm-2-8x22b:nitro'
      // 'gpt-4-turbo-preview',
      // 'anthropic/claude-3-sonnet'
      // 'anthropic/claude-3-haiku'
      // 'google/gemini-pro-1.5'
      // 'gpt-4o'
      const defaultSelectedModel = defaultModel?.modelName
        || (permLevel === 4 && defaultPremiumModel?.modelName)
        || 'GPT-4o';

      return {
        ...state,
        networkStates: {
          ...state.networkStates,
          gptModelsRequest: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
        gptModels: response?.results
          ? response.results.sort((a, b) => a.displayOrder - b.displayOrder)
          : undefined,
        settings: defaultSelectedModel
          ? {
            ...state.settings,
            gptModelName: defaultSelectedModel,
          }
          : state.settings,
      };
    });

  builder
    .addCase(updateSettings, (state, action) => {
      const { settings } = action.payload;
      return {
        ...state,
        settings,
      };
    });

  builder
    .addCase(setSideBarOpen, (state, action) => ({
      ...state,
      isSideBarOpen: action.payload.isOpen,
      isSideBarClosedOnce: (action.payload.isOpen === false)
        ? true
        : state.isSideBarClosedOnce,
    }));

  builder
    .addCase(setSelectedSideBarTab, (state, action) => ({
      ...state,
      selectedSideBarTab: action.payload.tabName,
    }));

  builder
    .addCase(applyChatOutputsCompare, (state, action) => {
      const lastChatIndex = state.chats.length - 1;
      const lastChat = state.chats[lastChatIndex];
      const lastChatModel = lastChat.llmModel || 'Unknown';

      const prevChatItemsByIndex = state.llmChatItemsByIndexes || {};
      const prevChatByEngines = (prevChatItemsByIndex[lastChatIndex] || {}).chatByEngines || [];

      return ({
        ...state,
        llmChatItemsByIndexes: {
          ...prevChatItemsByIndex,
          [lastChatIndex]: {
            ...(prevChatItemsByIndex[lastChatIndex] || {}),
            selectedModel: action.payload.llmModel,
            chatByEngines: [
              ...(prevChatByEngines.length === 0 ? [{
                llm: lastChatModel,
                chat: lastChat,
                isStreaming: false,
              }] : prevChatByEngines),
              {
                llm: action.payload.llmModel,
                isStreaming: true,
              },
            ],
          },
        },
      });
    });

  builder
    .addCase(selectChatResponseModel, (state, action) => {
      const { chatItemIndex, llm } = action.payload;
      if (!state.llmChatItemsByIndexes || !(chatItemIndex in (state.llmChatItemsByIndexes || {}))) {
        return state;
      }

      const llmChatItemsByIndexes = {
        ...state.llmChatItemsByIndexes,
        [chatItemIndex]: {
          ...state.llmChatItemsByIndexes[chatItemIndex],
          selectedModel: llm,
        },
      };

      const selectedChatByEngine = llmChatItemsByIndexes[chatItemIndex]
        .chatByEngines.find((chatByEngine) => chatByEngine.llm === llm);

      if (!selectedChatByEngine) {
        return state;
      }

      return {
        ...state,
        chats: state.chats.map((chat, chatIndex) => {
          if (chatIndex === chatItemIndex) {
            return selectedChatByEngine.chat!;
          }

          return chat;
        }),
        llmChatItemsByIndexes: {
          ...llmChatItemsByIndexes,
          [chatItemIndex]: {
            ...llmChatItemsByIndexes[chatItemIndex],
            chatByEngines: llmChatItemsByIndexes[chatItemIndex].chatByEngines.map((cb) => {
              if (cb.llm === state.chats[chatItemIndex].llmModel) {
                return {
                  ...cb,
                  chat: state.chats[chatItemIndex],
                };
              }
              return cb;
            }),
          },
        },
      };
    });

  builder
    .addCase(updatePlanExecTask, (state, action) => {
      const {
        chatItemIndex,
        taskIndex,
        task,
        toolInput,
        tool,
      } = action.payload;

      return {
        ...state,
        chats: state.chats.map((chat, chatIndex) => {
          if (chatIndex === chatItemIndex) {
            const { structuredContent } = chat;
            const planExecInfo = (structuredContent?.structured || {}) as PlanAndExecuteInfo;
            const updatedPlanExecInfo = {
              ...planExecInfo,
              tasks: (planExecInfo.tasks || []).map((t, index) => {
                if (index === taskIndex) {
                  return {
                    ...t,
                    tool,
                    task: t.isCustomTask ? getCleanPlanTaskString(toolInput) : task,
                    tool_input: toolInput,
                  };
                }

                return t;
              }),
            } as PlanAndExecuteInfo;

            return {
              ...chat,
              structuredContent: {
                ...structuredContent,
                structured: updatedPlanExecInfo,
              },
            };
          }

          return chat;
        }),
      };
    });

  builder
    .addCase(updatePlanExec, (state, action) => {
      const {
        chatItemIndex,
        planAndExec,
      } = action.payload;

      return {
        ...state,
        chats: state.chats.map((chat, chatIndex) => {
          if (chatIndex === chatItemIndex) {
            return {
              ...chat,
              structuredContent: {
                ...chat.structuredContent,
                structured: planAndExec,
              },
            };
          }

          return chat;
        }),
      };
    });

  builder
    .addCase(setShowingToolsAtHome, (state, action) => ({
      ...state,
      isShowingToolsAtHome: action.payload.isShowing,
    }));

  builder
    .addCase(setShowingGuidesAtHome, (state, action) => ({
      ...state,
      isShowingGuidesAtHome: action.payload.isShowing,
    }));

  builder
    .addCase(requestFollowUpQuestions, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        followUpQuestionsRequest: {
          isRequesting: true,
        },
      },
    }));

  builder
    .addCase(setFollowUpQuestions, (state, action) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        followUpQuestionsRequest: {
          isRequesting: false,
          lastError: action.payload.error,
        },
      },
      chats: (state.chats || []).map((chat) => {
        if (chat.type === 'bot') {
          return {
            ...chat,
            followUp: action.payload.result,
          };
        }

        return chat;
      }),
      chatState: action.payload.isStreaming ? 'followUp' : state.chatState,
    }));

  builder
    .addCase(toggleSourceCitation, (state, action) => ({
      ...state,
      sourceCitation: {
        isOpen: action.payload.isOpen,
        url: action.payload.url,
        phrase: action.payload.phrase,
        sourceId: action.payload.sourceId,
        title: action.payload.title,
        timestamp: action.payload.timestamp,
      },
      isSideBarOpen: true,
    }));

  builder
    .addCase(requestJinaContent, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        jinaContentRequest: {
          isRequesting: true,
        },
      },
    }));

  builder
    .addCase(setJinaContent, (state, action) => {
      const { url, result, format: jinaFormat } = action.payload;

      return ({
        ...state,
        networkStates: {
          ...state.networkStates,
          jinaContentRequest: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
        jinaContent: result,
        jinaContentFormat: jinaFormat,
        jinaContentCache: (result && jinaFormat) ? {
          ...state.jinaContentCache,
          [url]: {
            ...state.jinaContentCache?.[url],
            [jinaFormat === 'html' ? 'html' : 'markdown']: result,
          },
        } : state.jinaContentCache,
      });
    });

  builder
    .addCase(requestLocalSource, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        localSourceRequest: {
          isRequesting: true,
        },
      },
    }));

  builder
    .addCase(setLocalSource, (state, action) => {
      const { result } = action.payload;

      return ({
        ...state,
        networkStates: {
          ...state.networkStates,
          localSourceRequest: {
            isRequesting: false,
            lastError: action.payload.error,
          },
        },
        localSource: result,
      });
    });
});

export default gptReducer;
