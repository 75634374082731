import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';

import {
  requestCreateTaskTemplate,
  requestUpdateTaskTemplate,
} from '../actions';

import TemplateInfoPopup from './TemplateInfoPopup';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.router,
    ...state.session,
    ...state.autocomplete,
    ...state.search,
    ...state.gpt,
    networkStates: {
      ...state.gpt.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    requestCreateTaskTemplate,
    requestUpdateTaskTemplate,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TemplateInfoPopup);
