import { createReducer } from '@reduxjs/toolkit';
import {
  closeEntityBubble,
  openEntityBubble,
  requestRelationData,
  setRelationData,
  toggleEntityBubbleShowMore,
  updateInvisibleEntityIds,
} from './actions';

import { RelationChartState } from './types';

const initialState: RelationChartState = {
  entityBubble: {
    isOpen: false,
  },
  networkStates: {
    relationDataRequest: { isRequesting: false },
  },
  invisibleEntityIds: [],
};

const relationChartReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(requestRelationData, (state) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        relationDataRequest: {
          isRequesting: true,
        },
      },
    }))
    .addCase(setRelationData, (state, action) => ({
      ...state,
      networkStates: {
        ...state.networkStates,
        relationDataRequest: {
          ...state.networkStates.relationDataRequest,
          isRequesting: false,
          lastError: action.payload.error,
        },
      },
      relationData: action.payload.data,
    }))
    .addCase(openEntityBubble, (state, action) => ({
      ...state,
      entityBubble: {
        isOpen: true,
        entityName: action.payload.entityName,
        entityType: action.payload.entityType,
        originX: action.payload.originX,
        originY: action.payload.originY,
        isShowingInfoBox: false,
      },
    }))
    .addCase(closeEntityBubble, (state) => ({
      ...state,
      entityBubble: {
        isOpen: false,
      },
    }))
    .addCase(toggleEntityBubbleShowMore, (state) => ({
      ...state,
      entityBubble: {
        ...state.entityBubble,
        isShowingMore: !(state.entityBubble.isShowingMore || false),
      },
    }))
    .addCase(updateInvisibleEntityIds, (state, action) => ({
      ...state,
      invisibleEntityIds: action.payload.entityIds,
    }));
});

export default relationChartReducer;
