import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';
import getCommonStyles, { getColorModifierClasses } from '../../theme/commonStyles';
import { otherAltLightColorSets, otherColorSets, otherContrastColorSets } from '../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '&.hover $chartData, &.hover $chartModifier, &.hover $chartTools': {
        opacity: 1,
      },
    },
    chartData: {
      display: 'block',
      '& .MuiInputBase-input': {
        fontSize: '1rem !important',
      },
      opacity: 0,
      transition: 'all ease-out 0.3s',
      '&.hidden': {
        opacity: '0 !important',
      },
    },
    chartContainer: {
      display: 'block',
      position: 'relative',
      width: '100%',
    },
    chartModifier: {
      display: 'block',
      width: '100%',
      height: 32,
      borderRadius: theme.spacing(1.5),
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: otherColorSets[2],
      marginTop: theme.spacing(1),
      backgroundColor: otherAltLightColorSets[2],
      opacity: 0,
      transition: 'all ease-out 0.3s',
      overflow: 'hidden',
    },
    chartFrame: {
      display: 'flex',
      border: 'none',
      width: '100%',
      height: '100%',
      borderRadius: theme.spacing(1.5),
      borderWidth: 1,
      borderStyle: 'solid',
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: 'rgba(224, 224, 224, 1)',
      transition: 'all ease-out 0.3s',
      '&.hover': {
        ...getColorModifierClasses('borderColor'),
      },
    },
    chartTools: {
      display: 'block',
      position: 'absolute',
      width: theme.spacing(7),
      height: 100,
      top: 0,
      right: theme.spacing(-7),
      opacity: 0,
      transition: 'all ease-out 0.3s',
      '&.hidden': {
        opacity: '0 !important',
      },
    },
    colAllocSelector: {
      '& .MuiOutlinedInput-root': {
        borderRadius: theme.spacing(3.5),
      },
    },
    toolButton: {
      borderRadius: `${theme.spacing(1)} !important`,
      minWidth: `${theme.spacing(5)} !important`,
      width: `${theme.spacing(5)} !important`,
      height: `${theme.spacing(5)} !important`,
      marginLeft: `${theme.spacing(1)} !important`,
      marginBottom: `${theme.spacing(1)} !important`,
    },
    promptInput: {
      marginLeft: -1,
      fontWeight: '400 !important',
      fontSize: '0.95rem !important',
      color: `${theme.palette.grey[900]} !important`,
      '&:before': {
        borderBottom: 'none !important',
      },
      '&:after': {
        borderBottom: 'none !important',
      },
      paddingLeft: theme.spacing(1),
      borderRadius: theme.spacing(1.5),
      borderWidth: 1,
      borderStyle: 'solid',
      borderTop: '0px !important',
      borderBottom: 0,
      borderColor: otherColorSets[2],
      backgroundColor: theme.palette.common.white,
      position: 'relative',
      height: '100%',
      '& .MuiInput-input': {
        padding: 0,
      },
    },
    promptInputBox: {
      fontWeight: '400 !important',
      fontSize: '0.95rem !important',
      paddingTop: 1,
      '&:before': {
        borderBottom: 'none !important',
      },
      '&:after': {
        borderBottom: 'none !important',
      },
    },
    promptInputPopper: {
      '& ul': {
        fontWeight: '400 !important',
        fontSize: '0.95rem !important',
      },
    },
    promptInputPaper: {
      '&:empty': {
        display: 'none',
      },
    },
    promptInputButton: {
      cursor: 'pointer',
      '&:hover .promptButton': {
        fill: otherContrastColorSets[2],
      },
    },
    chartButton: {
      borderRadius: `${theme.spacing(1)} !important`,
      minWidth: `${theme.spacing(3)} !important`,
      width: `${theme.spacing(3)} !important`,
      height: `${theme.spacing(3)} !important`,
      '& .gptChartIcon': {
        stroke: theme.palette.grey[500],
      },
      padding: '0 !important',
      backgroundColor: `${theme.palette.common.white} !important`,
      border: 'none !important',
      '&.active': {
        backgroundColor: `${otherColorSets[2]} !important`,
        '& .gptChartIcon': {
          stroke: theme.palette.common.white,
        },
      },
      '&.tertiary.MuiButton-outlined:hover': {
        backgroundColor: `${Color(otherColorSets[2]).alpha(0.8).toString()} !important`,
        '& .gptChartIcon': {
          stroke: theme.palette.common.white,
        },
      },
    },
    optionTooltip: {
      textTransform: 'capitalize',
    },
    fullScreenContainer: {
      width: '100vw',
      height: '100vh',
      background: theme.palette.common.white,
    },
  });
};

export default styles;
