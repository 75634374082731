import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../types';
import {
  requestLogout,
  requestUserExtra,
} from '../session/actions';

import {
  requestAutocompleteItems,
} from '../autocomplete/actions';

import {
  openFeedbackForm,
  openContactForm,
  requestSubscribe,
} from '../main/actions';

import {
  triggerNotification,
} from '../notifier/actions';

import {
  setSearchKeyword,
  toggleGptSearch,
} from '../search/actions';

import Portal from './Portal';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.router,
    ...state.session,
    ...state.autocomplete,
    ...state.search,
    networkStates: {
      ...state.session.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    requestLogout,
    requestAutocompleteItems,
    requestUserExtra,
    openFeedbackForm,
    openContactForm,
    requestSubscribe,
    triggerNotification,
    setSearchKeyword,
    toggleGptSearch,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Portal);
