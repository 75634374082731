import { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { v4 } from 'uuid';
import { Buffer } from 'buffer';

import config from '../../../config';

import { GlobalActions } from '../../../core/types/redux';
import { performApiGet, performApiPost } from '../../../core/utils/sagaUtils';
import { triggerNotification } from '../notifier/actions';
import { LOGIN_STORAGE } from '../session/saga';

import { User } from '../session/types';

import {
  openMessageBox,
  requestGlossaryItems,
  setContactFormSubmitted,
  setGlossaryItems,
  setSubscribed,
} from './actions';

import routes from './routes';

import {
  MainActions,
  OpenGlossaryBubbleArgs,
  OpenReportArgs,
  RequestGlossaryItemsArgs,
  RequestSubmitContactFormArgs,
  RequestSubscribeArgs,
  SetContactFormSubmittedArgs,
  SetSubsribedArgs,
} from './types';

import { requestUserExtra } from '../session/actions';
import { ApplicationState } from '../../types';
import { GlossaryItem } from '../search/types';

const abortControllers: AbortController[] = [];

function* httpUnauthorizedFlow() {
  yield takeLatest(
    GlobalActions.HTTP_UNAUTHORIZED_NOTIFY,
    function* _() {
      yield put(openMessageBox({
        content: 'Your login has expired. Please login again.',
        blurBackdrop: true,
        dismissText: 'OK',
        colorSet: 'primary',
        onDismiss: () => {
          localStorage.removeItem(LOGIN_STORAGE);
          window.authToken = undefined;

          window.location.href = '/';
        },
      }));
    },
  );
}

function* noCreditFlow() {
  yield takeLatest(
    GlobalActions.NO_CREDIT_NOTIFY,
    function* _() {
      yield put(openMessageBox({
        content: 'Insufficient credits, please purchase a credit package to open the report.',
        dismissText: 'OK',
        colorSet: 'primary',
        onDismiss: () => {
          window.location.href = '/';
        },
      }));
    },
  );
}

function* requestSubscribeFlow() {
  yield takeEvery(
    MainActions.SUBSCRIBE_REQUEST,
    function* _(act: PayloadAction<RequestSubscribeArgs>) {
      yield fork(() => performApiPost<unknown>(
        config.api.subscribe,
        {
          payload: {
            email: act.payload.email,
          },
          onResult: ({ error }) => put(setSubscribed({
            error,
          })),
        },
      ));
    },
  );
}

function* subscribeSetFlow() {
  yield takeEvery(
    MainActions.SUBSCRIBED_SET,
    function* _(act: PayloadAction<SetSubsribedArgs>) {
      if (act.payload.error) {
        yield put(triggerNotification({
          notification: {
            key: v4(),
            type: 'error',
            payload: 'An error occurs, please try again later.',
            isDisplayed: false,
            delay: 5000,
          },
        }));

        return;
      }

      yield put(triggerNotification({
        notification: {
          key: v4(),
          type: 'general',
          payload: 'Email subscribed, thank you!',
          isDisplayed: false,
          delay: 5000,
        },
      }));
    },
  );
}

function* requestContactFormSubmitFlow() {
  yield takeEvery(
    MainActions.CONTACT_FORM_SUBMIT_REQUEST,
    function* _(act: PayloadAction<RequestSubmitContactFormArgs>) {
      yield fork(() => performApiPost<unknown>(
        config.api.contactus,
        {
          payload: {
            email: act.payload.formInfo.email,
            user: act.payload.formInfo.user?.pk,
            msg: act.payload.formInfo.description,
          },
          onResult: ({ error }) => put(setContactFormSubmitted({
            error,
          })),
        },
      ));
    },
  );
}

function* contactFormSubmittedFlow() {
  yield takeEvery(
    MainActions.CONTACT_FORM_SUBMITTED_SET,
    function* _(act: PayloadAction<SetContactFormSubmittedArgs>) {
      if (act.payload.error) {
        yield put(triggerNotification({
          notification: {
            key: v4(),
            type: 'error',
            payload: 'An error occurs, please try again later.',
            isDisplayed: false,
            delay: 5000,
          },
        }));

        return;
      }

      yield put(triggerNotification({
        notification: {
          key: v4(),
          type: 'general',
          payload: 'Message sent, thank you!',
          isDisplayed: false,
          delay: 5000,
        },
      }));
    },
  );
}

function* reportOpenFlow() {
  yield takeEvery(
    MainActions.COMPANY_REPORT_OPEN,
    function* _(act: PayloadAction<OpenReportArgs>) {
      const user: User = (
        yield select((state: ApplicationState) => state.session.user)
      );

      yield put(requestUserExtra({
        user: String(user.pk),
      }));

      const {
        companyId,
        companyData,
        report,
        hasScore,
      } = act.payload;

      const companyDataJson = JSON.stringify(companyData);

      const params = {
        cinfo: Buffer.from(companyDataJson).toString('base64'),
        desc: Buffer.from(companyData.orgDesc || '').toString('base64'),
      } as Record<string, string>;

      if (hasScore) {
        params.score = 'true';
      }

      const companyParams = companyDataJson && new URLSearchParams(params).toString();

      if (!report) {
        if (act.payload.hasScore) {
          window.open(
            `${window.location.origin}${routes.reports}/${companyId}?score=true`,
            `report-${companyId}`,
          );

          return;
        }

        window.open(
          `${window.location.origin}${routes.reports}/${companyId}${companyParams ? `?${companyParams}` : ''}`,
          `report-${companyId}`,
        );

        return;
      }

      if (hasScore && (
        report === 'profileScore' || report === 'successScore'
      )) {
        window.open(
          `${window.location.origin}${routes.reports}/${companyId}/${report}?score=true`,
          `report-${companyId}`,
        );

        return;
      }

      window.open(
        `${window.location.origin}${routes.reports}/${companyId}/${report}${companyParams ? `?${companyParams}` : ''}`,
        `report-${companyId}`,
      );
    },
  );
}

function* glossaryBubbleOpenFlow() {
  yield takeEvery(
    MainActions.GLOSSARY_BUBBLE_OPEN,
    function* _(act: PayloadAction<OpenGlossaryBubbleArgs>) {
      yield put(requestGlossaryItems({
        phrase: act.payload.phrase,
      }));
    },
  );
}

function* requestGlossaryItemsFlow() {
  yield takeEvery(
    MainActions.GLOSSARY_ITEMS_REQUEST,
    function* _(act: PayloadAction<RequestGlossaryItemsArgs>) {
      if (abortControllers.length) {
        const controller = abortControllers.pop();
        controller?.abort();

        yield take([
          MainActions.GLOSSARY_ITEMS_SET,
        ]);

        yield put(act);
        return;
      }

      const abortController = new AbortController();
      abortControllers.push(abortController);

      yield fork(() => performApiGet<GlossaryItem[]>(
        config.api.getDictionary,
        {
          params: {
            desc: act.payload.phrase,
          },
          onResult: function* __({ result, error }) {
            yield put(setGlossaryItems({
              items: result,
              error,
            }));

            abortControllers.pop();
          },
        },
        {
          signal: abortController.signal,
        },
      ));
    },
  );
}

export default function* mainSaga() {
  yield all([
    httpUnauthorizedFlow(),
    noCreditFlow(),
    requestSubscribeFlow(),
    subscribeSetFlow(),
    requestContactFormSubmitFlow(),
    contactFormSubmittedFlow(),
    reportOpenFlow(),
    glossaryBubbleOpenFlow(),
    requestGlossaryItemsFlow(),
  ]);
}
