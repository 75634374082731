import { Action } from 'redux';

export enum GlobalActions {
  HTTP_UNAUTHORIZED_NOTIFY = '@@global/HTTP_UNAUTHORIZED_NOTIFY',
  HTTP_UNAUTHORIZED_DISMISS = '@@global/HTTP_UNAUTHORIZED_DISMISS',
  NO_CREDIT_NOTIFY = '@@global/NO_CREDIT_NOTIFY',
  NO_CREDIT_DISMISS = '@@global/NO_CREDIT_DISMISS',
}

export type DispatchFunc<TArgs, TConstant = unknown> = (args?: TArgs) => TArgs & Action<TConstant>;
