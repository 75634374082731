import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { history } from '../core';
import { ReactComponent as SvgDefs } from '../assets/svg/defs.svg';
import mui from '../theme';
import store from './store';
import Main from './features/main';

const App: React.FC = (): JSX.Element => (
  <>
    <div style={{ overflow: 'hidden', height: 0.1 }}>
      <SvgDefs />
    </div>
    <CssBaseline />
    <ThemeProvider theme={mui}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router history={history}>
            <SnackbarProvider maxSnack={2}>
              <Main />
            </SnackbarProvider>
          </Router>
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  </>
);

export default App;
