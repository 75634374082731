import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';

import {
  otherContrastColorSets,
} from '../../../../theme/palette';

import getCommonStyles from '../../../../theme/commonStyles';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      ...commonStyles.smallRoundedBorder,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: `${theme.spacing(2)} ${theme.spacing(3)} !important`,
      borderRadius: `${theme.spacing(1.5)} !important`,
      transition: 'all ease-in-out 0.3s',
      cursor: 'pointer',
      '&.active': {
        cursor: 'default',
        borderWidth: '1px !important',
        borderColor: `${otherContrastColorSets[0]} !important`,
        background: `${theme.palette.common.white} !important`,
        boxShadow: `${theme.shadows[3]} !important`,
      },
      '&:hover': {
        borderColor: `${otherContrastColorSets[0]} !important`,
        background: `${theme.palette.common.white} !important`,
        boxShadow: `${theme.shadows[3]} !important`,
      },
      textAlign: 'left',
      backgroundColor: `${theme.palette.background.paper} !important`,
      position: 'relative',
    },
    title: {
      color: `${theme.palette.grey[900]} !important`,
      fontWeight: 'normal !important',
      lineHeight: '1.125 !important',
      fontSize: '1.5rem !important',
    },
    details: {
      overflow: 'hidden',
      height: 0,
      transition: 'all 0.3s ease-out',
      '&.active': {
        height: theme.spacing(36),
      },
      marginTop: theme.spacing(1),
    },
    highlightTitle: {
      marginTop: `${theme.spacing(1.5)} !important`,
      color: `${theme.palette.grey[300]} !important`,
      fontWeight: '400 !important',
      fontSize: '1.1rem !important',
    },
    highlightItem: {
      marginTop: `${theme.spacing(1.5)} !important`,
      color: `${theme.palette.grey[900]} !important`,
    },
    chartWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column-reverse',
      position: 'relative',
      boxSizing: 'border-box',
    },
    chartHeader: {
      flex: 0,
      flexBasis: theme.spacing(5),
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1.5),
    },
    trendItem: {
      height: 21.5,
      width: '80%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      flex: 1,
      boxSizing: 'border-box',
      padding: `0 ${theme.spacing(1)}`,
      borderColor: otherContrastColorSets[0],
    },
    trendItemText: {
      fontWeight: '400 !important',
      fontSize: '1.07rem',
      color: `${otherContrastColorSets[0]} !important`,
    },
    chartContainer: {
      flex: 1,
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    chartArea: {
      flex: 1,
    },
  });
};

export default styles;
