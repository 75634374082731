import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useResizeDetector } from 'react-resize-detector';
import { motion, AnimatePresence } from 'framer-motion';

import styles from './DataInfoPanel.styles';

const useStyles = makeStyles(styles);

type Props = {
  info?: string;
  onHeightDetected?: (height: number) => {};
};

const DataInfoPanel: React.FC<Props> = ({
  info,
  onHeightDetected,
}) => {
  const classes = useStyles();
  const { height, ref } = useResizeDetector();

  // eslint-disable-next-line no-control-regex
  const cleanedHtml = info && info.replace(/[^\x00-\x7F]/g, ' ');

  useEffect(() => {
    if (onHeightDetected && height) {
      onHeightDetected(height);
    }
  }, [height, onHeightDetected]);

  return (
    <div className={classes.root} ref={ref}>
      <AnimatePresence>
        {cleanedHtml && (
          <motion.div
            className={classes.innerContent}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: cleanedHtml || '' }} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DataInfoPanel;
