import { PayloadAction } from '@reduxjs/toolkit';
import { all, put, takeEvery } from 'redux-saga/effects';
import { requestCompanyItems } from './actions';
import { SearchActions, SetCompanyItemsArgs } from './types';

export function* companyItemsSetFlow() {
  yield takeEvery(
    SearchActions.COMPANY_ITEMS_SET,
    function* _(act: PayloadAction<SetCompanyItemsArgs>) {
      if (!Array.isArray(act.payload.items)) {
        return;
      }

      if (act.payload.items.length > 1) {
        return;
      }

      const companyItem = act.payload.items.at(0);
      const desc = companyItem?.description;

      if (!desc || act.payload.isAdditionalResults) {
        return;
      }

      yield put(requestCompanyItems({
        keyword: desc,
        shouldAddCompanyUrl: false,
        isAdditionalSearch: true,
      }));
    },
  );
}

export function* searchSubSaga() {
  yield all([
    companyItemsSetFlow(),
  ]);
}
