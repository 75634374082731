import { createAction } from '@reduxjs/toolkit';
import {
  SearchActions,
  RequestCompanyItemsArgs,
  SetCompanyItemsArgs,
  SetHistoryItemsArgs,
  SetPageArgs,
  SetSearchKeywordArgs,
  UpdateCountriesFilterArgs,
  UpdateIndustriesFilterArgs,
  UpdateMinFoundingYearsFilterArgs,
  UpdateVerticalsFilterArgs,
  RequestMarketInquiryItemsArgs,
  SetMarketInquiryItemsArgs,
  RequestGlossaryItemsArgs,
  SetGlossaryItemsArgs,
  ToggleGptSearchArgs,
  RequestGptSearchArgs,
  SetGptSearchArgs,
  ToggleGptChatArgs,
} from './types';

export const setSearchKeyword = (
  createAction<SetSearchKeywordArgs>(SearchActions.SEARCH_KEYWORD_SET)
);
export const requestCompanyItems = (
  createAction<RequestCompanyItemsArgs>(SearchActions.COMPANY_ITEMS_REQUEST)
);
export const setCompanyItems = (
  createAction<SetCompanyItemsArgs>(SearchActions.COMPANY_ITEMS_SET)
);
export const requestHistoryItems = (
  createAction(SearchActions.HISTORY_ITEMS_REQUEST)
);
export const setHistoryItems = (
  createAction<SetHistoryItemsArgs>(SearchActions.HISTORY_ITEMS_SET)
);
export const requestMarketInquiryItems = (
  createAction<RequestMarketInquiryItemsArgs>(SearchActions.MARKET_INQUIRY_ITEMS_REQUEST)
);
export const setMarketInquiryItems = (
  createAction<SetMarketInquiryItemsArgs>(SearchActions.MARKET_INQUIRY_ITEMS_SET)
);
export const requestGlossaryItems = (
  createAction<RequestGlossaryItemsArgs>(SearchActions.GLOSSARY_ITEMS_REQUEST)
);
export const setGlossaryItems = (
  createAction<SetGlossaryItemsArgs>(SearchActions.GLOSSARY_ITEMS_SET)
);
export const abortSearchRequest = (
  createAction(SearchActions.SEARCH_REQUEST_ABORT)
);
export const openFilterEdit = (
  createAction(SearchActions.FILTER_EDIT_OPEN)
);
export const closeFilterEdit = (
  createAction(SearchActions.FILTER_EDIT_CLOSE)
);
export const updateCountriesFilter = (
  createAction<UpdateCountriesFilterArgs>(SearchActions.COUNTRIES_FILTER_UPDATE)
);
export const updateIndustriesFilter = (
  createAction<UpdateIndustriesFilterArgs>(SearchActions.INDUSTRIES_FILTER_UPDATE)
);
export const updateVerticalsFilter = (
  createAction<UpdateVerticalsFilterArgs>(SearchActions.VERTICALS_FILTER_UPDATE)
);
export const updateMinFoundingYearsFilter = (
  createAction<UpdateMinFoundingYearsFilterArgs>(
    SearchActions.MIN_FOUNDING_YEARS_FILTER_UPDATE,
  )
);
export const clearAllFilters = (
  createAction(SearchActions.FILTERS_CLEAR)
);
export const setPage = (
  createAction<SetPageArgs>(SearchActions.PAGE_SET)
);
export const toggleGptSearch = (
  createAction<ToggleGptSearchArgs>(SearchActions.GPT_SEARCH_TOGGLE)
);
export const requestGptSearch = (
  createAction<RequestGptSearchArgs>(SearchActions.GPT_SEARCH_REQUEST)
);
export const setGptSearch = (
  createAction<SetGptSearchArgs>(SearchActions.GPT_SEARCH_SET)
);
export const toggleGptChat = (
  createAction<ToggleGptChatArgs>(SearchActions.GPT_CHAT_TOGGLE)
);
