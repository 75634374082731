import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';

import {
  requestChatResponse,
  requestChatClean,
  toggleChatItemStep,
  requestChatHistory,
  applyChatHistory,
  requestChatPrompts,
  requestSiteExamples,
  requestDeleteChatPrompt,
  requestDeleteChatHistory,
  setSideBarOpen,
} from '../../gpt/actions';

import {
  requestLogout,
  requestUserExtra,
} from '../../session/actions';

import {
  requestAutocompleteItems,
} from '../../autocomplete/actions';

import {
  openFeedbackForm,
  openContactForm,
  requestSubscribe,
  openMessageBox,
} from '../../main/actions';

import {
  triggerNotification,
} from '../../notifier/actions';

import {
  setSearchKeyword,
  toggleGptSearch,
} from '../../search/actions';

import PromptList from './PromptList';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.router,
    ...state.session,
    ...state.autocomplete,
    ...state.search,
    ...state.gpt,
    ...state.relationChart,
    networkStates: {
      ...state.session.networkStates,
      ...state.gpt.networkStates,
    },
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    openMessageBox,
    requestLogout,
    requestAutocompleteItems,
    requestUserExtra,
    openFeedbackForm,
    openContactForm,
    requestSubscribe,
    triggerNotification,
    setSearchKeyword,
    toggleGptSearch,
    requestChatResponse,
    requestChatClean,
    toggleChatItemStep,
    requestChatHistory,
    applyChatHistory,
    requestChatPrompts,
    requestSiteExamples,
    requestDeleteChatPrompt,
    requestDeleteChatHistory,
    setSideBarOpen,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PromptList);
