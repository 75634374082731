import React from 'react';
import { makeStyles } from '@mui/styles';

import { PropsFromRedux } from './container';
import styles from './MarketResults.styles';
import MarketList from '../marketList';

interface Props extends PropsFromRedux {}

const useStyles = makeStyles(styles);

const MarketResults: React.FC<Props> = ({
  marketInquiryItems,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MarketList
        marketItems={marketInquiryItems}
        totalItemsInPage={4}
      />
    </div>
  );
};

export default MarketResults;
