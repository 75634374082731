import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Link, Typography } from '@mui/material';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';

import { GlossaryItem } from '../types';
import { PropsFromRedux } from './container';
import styles from './GlossaryItemCard.styles';
import { elementContainsSelection, isMouseOnElement } from '../../../../core/utils/uxUtils';

interface Props extends PropsFromRedux {
  glossaryItem: GlossaryItem;
  onDataClick?: (phrase: string) => void;
}

const GLOSSARY_BUBBLE_TIMEOUT = 1000;

const useStyles = makeStyles(styles);

const GlossaryItemCard: React.FC<Props> = ({
  glossaryItem,
  onDataClick,
  openGlossaryBubble,
  glossaryBuble,
}) => {
  const classes = useStyles();
  const [isHover, setHover] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const glossaryBubbleTimeoutRef = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    const listener = () => {
      const selection = window.getSelection();
      const selectedText = selection?.toString();

      if (!selectedText && rootRef.current && !isMouseOnElement(rootRef.current)) {
        setHover(false);
      }
    };

    document.addEventListener('selectionchange', listener);

    return () => {
      document.removeEventListener('selectionchange', listener);
    };
  }, []);

  const determineOpenGlossaryBubble = (rect: DOMRect, phrase: string) => {
    if (glossaryBubbleTimeoutRef.current) {
      clearTimeout(glossaryBubbleTimeoutRef.current);
      glossaryBubbleTimeoutRef.current = undefined;
    }

    const timeoutRef = setTimeout(() => {
      openGlossaryBubble({
        originX: rect.left + (rect.width / 2),
        originY: rect.top,
        phrase,
      });

      glossaryBubbleTimeoutRef.current = undefined;
    }, GLOSSARY_BUBBLE_TIMEOUT);

    glossaryBubbleTimeoutRef.current = timeoutRef;
  };

  const cancelGlossaryBubble = () => {
    if (glossaryBubbleTimeoutRef.current) {
      clearTimeout(glossaryBubbleTimeoutRef.current);
      glossaryBubbleTimeoutRef.current = undefined;
    }
  };

  return (
    <div
      className={
        classNames(
          classes.root,
          isHover && 'active',
        )
      }
      onMouseEnter={() => setHover(true)}
      onMouseLeave={(event) => {
        if (
          elementContainsSelection(event.currentTarget)
          || glossaryBubbleTimeoutRef.current
          || glossaryBuble.isOpen
        ) {
          return;
        }

        setHover(false);
      }}
      ref={rootRef}
    >
      <Typography variant="h4" className={classes.title}>
        {glossaryItem.term}
        {glossaryItem.abbreviation && (
          <span className={classes.abbrev}>
            {`(${glossaryItem.abbreviation})`}
          </span>
        )}
      </Typography>
      <Grid container marginTop={0.75}>
        <Grid item xs={1.5}>
          <Typography variant="body2" className={classes.field}>
            {`in ${glossaryItem.field}`}
          </Typography>
        </Grid>
        <Grid item xs={10.5}>
          <Highlighter
            className={classes.definition}
            searchWords={glossaryItem.highlights}
            autoEscape={true}
            textToHighlight={glossaryItem.definition}
            highlightTag={(node) => (
              <Link
                type="button"
                className={classes.highlight}
                onMouseDown={() => {
                  if (onDataClick) {
                    onDataClick(String(node.children));
                  }
                  cancelGlossaryBubble();
                }}
                onMouseEnter={(event) => {
                  const rect = event.currentTarget.getBoundingClientRect();
                  determineOpenGlossaryBubble(rect, node.children as string);
                }}
                onMouseLeave={() => cancelGlossaryBubble()}
              >
                {node.children}
              </Link>
            )}
          />
        </Grid>
        {glossaryItem.example && (
          <Grid container marginTop={1}>
            <Grid item xs={1.5}>
            </Grid>
            <Grid item xs={10.5}>
              <Highlighter
                className={classes.example}
                searchWords={glossaryItem.highlights}
                autoEscape={true}
                textToHighlight={glossaryItem.example}
                highlightTag={(node) => (
                  <Link
                    type="button"
                    className={classes.highlight}
                    onMouseDown={() => {
                      if (onDataClick) {
                        onDataClick(String(node.children));
                      }
                      cancelGlossaryBubble();
                    }}
                    onMouseEnter={(event) => {
                      const rect = event.currentTarget.getBoundingClientRect();
                      determineOpenGlossaryBubble(rect, node.children as string);
                    }}
                    onMouseLeave={() => cancelGlossaryBubble()}
                  >
                    {node.children}
                  </Link>
                )}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default GlossaryItemCard;
