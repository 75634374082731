import { createAction } from '@reduxjs/toolkit';
import {
  GlobalActions,
} from './types/redux';

export const notifyHttpUnauthorized = createAction(GlobalActions.HTTP_UNAUTHORIZED_NOTIFY);
export const dismissHttpUnauthorized = createAction(GlobalActions.HTTP_UNAUTHORIZED_DISMISS);

export const notifyNoCredit = createAction(GlobalActions.NO_CREDIT_NOTIFY);
export const dismissNoCredit = createAction(GlobalActions.NO_CREDIT_DISMISS);
