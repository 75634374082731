import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import { getCustomScrollBarCss } from '../../../../core/utils/uxUtils';
import getCommonStyles from '../../../../theme/commonStyles';
import { otherContrastColorSets, otherLightColorSets } from '../../../../theme/palette';

const investingTextStyle = {
  backgroundImage: `linear-gradient(
    to right,
    #be95e3 -4%,
    #748aff 50%,
    #438bfd 104%
  );`,
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
};

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      boxSizing: 'border-box',
      paddingRight: theme.spacing(8),
    },
    profilePanel: {
      borderColor: '#3d8bf7',
      borderRadius: 10,
    },
    profileHeader: {
      color: '#295fab !important',
      fontSize: '1.4rem !important',
      fontWeight: 'normal !important',
      display: 'inline-block',
      lineHeight: '21px !important',
    },
    founderPanel: {
      borderColor: '#9c8bff',
      borderRadius: 10,
    },
    founderHeader: {
      color: '#605dad !important',
      fontSize: '1.4rem !important',
      fontWeight: 'normal !important',
      display: 'inline-block',
      lineHeight: '21px !important',
      paddingTop: 2,
      width: 100,
    },
    investHeader: {
      ...investingTextStyle,
      fontSize: '1.4rem !important',
      fontWeight: 'normal !important',
      display: 'inline-block',
      lineHeight: '21px !important',
      width: 100,
    },
    industryStatesPanel: {
      borderColor: '#bf6684',
      borderRadius: 10,
    },
    industryStatesHeader: {
      color: `${otherContrastColorSets[0]} !important`,
      fontSize: '1.4rem !important',
      fontWeight: 'normal !important',
      display: 'inline-block',
    },
    countryValue: {
      fontSize: '0.95rem !important',
    },
    table: {
      maxHeight: 350,
      overflow: 'auto',
      ...getCustomScrollBarCss(),

      '& thead': {
        borderBottom: '1px solid #ccc',
        position: 'sticky',
        background: '#fff',
        top: 0,
      },
    },
    tableHeader: {
      color: `${theme.palette.grey[900]} !important`,
      fontSize: '1rem !important',
      fontWeight: 'normal !important',
      whiteSpace: 'nowrap',
    },
    tableValue: {
      fontSize: '1rem !important',
    },
    mostSimilarPanel: {
      borderColor: '#fa8e10',
      borderRadius: 10,
    },
    mostSimilarHeader: {
      color: `${otherContrastColorSets[4]} !important`,
      fontSize: '1.4rem !important',
      fontWeight: 'normal !important',
    },
    recommendBadge: {
      borderRadius: '10px 10px 0 10px',
      border: `1px solid ${theme.palette.secondary.dark}`,
      padding: '1px 5px',
      fontSize: '0.6rem !important',
      verticalAlign: 'middle',
      display: 'inline-block',
      marginLeft: theme.spacing(1),
      lineHeight: 1,
    },
    scoreBanner: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      cursor: 'pointer',
      border: `1px solid ${theme.palette.secondary.main}`,
      display: 'inline-block',
      alignItems: 'center',
      fontSize: '1.585rem',
      color: theme.palette.common.white,
      width: 85,
      '& > span > span:not(:first-child)': {
        fontSize: '1.2285rem !important',
      },
      '&:hover': {
        boxShadow: '0 3px 5px 0 rgba(0, 0, 0, 0.16)',
      },
    },
    vcLikeableBanner: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      cursor: 'pointer',
      display: 'inline-block',
      background: theme.palette.common.white,
      border: `1px dashed ${theme.palette.secondary.main}`,
      boxSizing: 'border-box',
      textAlign: 'center',
      fontSize: '1.585rem',
      color: theme.palette.secondary.main,
      width: 85,
      transition: 'all ease-out 0.2s',
      '& > span > span:not(:first-child)': {
        fontSize: '1.2285rem !important',
      },
      '&:hover': {
        boxShadow: '0 3px 5px 0 rgba(0, 0, 0, 0.16)',
      },
    },
    fundingBanner: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      cursor: 'pointer',
      border: '1px solid transparent',
      boxSizing: 'border-box',
      textAlign: 'center',
      fontSize: '1.585rem',
      color: theme.palette.secondary.main,
      width: 85,
      background: `
        url("data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII="), 
        linear-gradient(to right, #be95e3, #438bfd);
      `,
      backgroundClip: 'padding-box, border-box',
      backgroundOrigin: 'border-box',
      '& > *': {
        color: '#625ebf',
      },
      '& > span > small': {
        fontSize: '1.2285rem !important',
      },
      '&:hover': {
        boxShadow: '0 3px 5px 0 rgba(0, 0, 0, 0.16)',
      },
    },
    valuationBanner: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      cursor: 'pointer',
      border: `1px dotted ${theme.palette.common.white}`,
      boxSizing: 'border-box',
      textAlign: 'center',
      fontSize: '1.585rem',
      color: theme.palette.secondary.main,
      width: 85,
      background: `
        linear-gradient(to right, ${theme.palette.common.white}, ${theme.palette.common.white}), 
        linear-gradient(to right, #be95e3, #438bfd);
      `,
      backgroundClip: 'padding-box, border-box',
      backgroundOrigin: 'padding-box, border-box',
      '& > *': investingTextStyle,
      '& > span > small': {
        fontSize: '1.2285rem !important',
      },
      '&:hover': {
        boxShadow: '0 3px 5px 0 rgba(0, 0, 0, 0.16)',
      },
    },
    likelyExitBanner: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      cursor: 'pointer',
      boxSizing: 'border-box',
      textAlign: 'center',
      fontSize: '1.585rem',
      color: theme.palette.secondary.main,
      width: 85,
      borderTop: '1px solid transparent',
      borderBottom: '1px solid transparent',
      background: `
        linear-gradient(to right, #be95e3, #438bfd);
      `,
      '& > *': {
        color: theme.palette.common.white,
      },
      '& > span > span:not(:first-child)': {
        fontSize: '1.2285rem !important',
      },
      '&:hover': {
        boxShadow: '0 3px 5px 0 rgba(0, 0, 0, 0.16)',
      },
    },
    marketItem: {
      '&:hover': {
        backgroundColor: otherLightColorSets[0],
        cursor: 'pointer',
      },
    },
    founderItem: {
      '&:hover': {
        backgroundColor: otherLightColorSets[2],
        cursor: 'pointer',
      },
    },
    founderName: {
      color: `${theme.palette.grey[900]} !important`,
    },
    leafBanner: {
      width: theme.spacing(7.5),
      padding: `0 ${theme.spacing(1)}`,
      background: theme.palette.common.white,
      height: 31.36,
    },
  });
};

export default styles;
